import React, { lazy } from 'react';

import {
  Navigate,
  RouteObject
} from 'react-router-dom';

import {
  Application,
  Gating
} from '../../TrustPortal/registerApplicationMenu';
import { Overview } from '../pages/GatingRecordDetails/Overview';

const Home = lazy(() => import('../pages/Home'));
const GatingRecord = lazy(() => import('../pages/GatingRecord'));
const GatingRecordDetails = lazy(() => import('../pages/GatingRecordDetails'));

export const routes: RouteObject[] = [
  {
    path: `${Application.GatingRecord}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${Gating.GatingRecord}/*`,
        element: <GatingRecord />,
      },
      {
        path: `${Gating.GatingRecordDetails}/:gatingId/*`,
        element: <GatingRecordDetails />,
        children: [
          {
            path: '',
            element: <Navigate replace={true} to={Gating.GatingRecordDetailsOverview}/>
          },
          {
            path: Gating.GatingRecordDetailsOverview,
            element: <Overview />
          },
          {
            path: Gating.GatingRecordDetailsHistory,
            element: <div />
          }
        ]
      }
    ],
  },
];
