import React, { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import {
  Application,
  Scorecard,
} from '../../TrustPortal/registerApplicationMenu';

const Home = lazy(() => import('../pages/Home'));
const ScoreCardReport = lazy(() => import('../pages/Scorecard'));
const ScoringDetailReports = lazy(() => import('../pages/ScoringDetails'));

export const routes: RouteObject[] = [
  {
    path: `${Application.Scorecard}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${Scorecard.ScoreCardReport}/*`,
        element: <ScoreCardReport/>
      },
      {
        path: `menu/${Scorecard.ScoringDetailReports}/*`,
        element: <ScoringDetailReports />,
      },
    ],
  },
];
