import React from 'react';

import {
  Link, Stack
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { FileTile } from './FileTile';
import { FileTileItem } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';

interface FileTileGroupProps {
  items: FileTileItem[];
  itemTruncated?: number;
  showAll?: boolean;
  toggleShowAll?: () => void;
}

export const FileTileGroup: React.FC<FileTileGroupProps> = (props) => {
  const { items, itemTruncated = items.length, showAll, toggleShowAll } = props;

  const hiddenItemCount = items.length - itemTruncated;
  const visibleItems = showAll || hiddenItemCount <= 0 ? items : items.slice(0, itemTruncated);

  return (
    <Stack horizontal
      tokens={{
        childrenGap: 12
      }}
      wrap>
      { visibleItems.map((item) => (
        <FileTile key={item.id} {...item} />
      )) }
      { hiddenItemCount > 0 && toggleShowAll && (
        <Stack.Item align='center'>
          <Link onClick={toggleShowAll}>
            { showAll ? intl.get(LocIds.Action.ShowLess) : intl.get(LocIds.Label.PlusMore, {
              number: hiddenItemCount
            }) }
          </Link>
        </Stack.Item>
      ) }
    </Stack>
  );
};
