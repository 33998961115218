import React from 'react';

import {
  ITooltipHostProps,
  TooltipHost
} from '@fluentui/react';

const calloutProps = {
  gapSpace: 0
};

export const ToolTip: React.FunctionComponent<ITooltipHostProps> = (
  props: ITooltipHostProps,
) => {
  return (
    <TooltipHost
      calloutProps={calloutProps}
      className='trustPortal_toolTip'
      content={props.content}>
      { props.children }
    </TooltipHost>
  );
};
