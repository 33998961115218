import { IBreadcrumbItem } from '@fluentui/react';
import _ from 'lodash';
import intl from 'react-intl-universal';
import { useNavigate } from 'react-router-dom';

import { LocIds } from '../../../common/Globalization/IntlEnum';
import { ServiceDetail } from '../../../models/ServiceWorkspaceTypes';
import {
  Application,
  ServiceWorkspace
} from '../../TrustPortal/registerApplicationMenu';

export const useBreadcrumbItems = (service: ServiceDetail | undefined): IBreadcrumbItem[] => {
  const navigate = useNavigate();

  const items: IBreadcrumbItem[] = [
    {
      text: intl.get(LocIds.ServiceWorkspace.ServiceWorkspace),
      key: 'serviceWorkspace',
      onClick: () =>
        navigate(`/application/${Application.ServiceWorkspace}/menu/${ServiceWorkspace.ServiceBoard}`),
    }
  ];

  // Only valid when service has non-null ServiceTreeId
  if (!_.isNil(service?.ServiceTreeId) && !_.isNil(service?.ServiceName)) {
    items.push({
      text: `${service?.ServiceName}`,
      key: 'serviceName',
    });
  }

  return items;
};
