import { CSSProperties } from 'react';

export const nonCompliantCircle: Partial<CSSProperties> = {
  lineHeight: '16px',
  borderRadius: '50%',
  width: '16px',
  height: '16px',
  background: 'rgba(205,74,69,1)',
  marginRight: '8px',
  marginLeft: '8px',
  display: 'inline-block'
};

export const icon: Partial<CSSProperties> = {
  fontSize: 12,
  height: 12,
  width: 12,
  color: 'white',
  paddingLeft: 1.7
};

export const pullRequestTableStyle: Partial<CSSProperties> = {
  padding: 16,
  border: '1px',
  overflow: 'auto',
  maxHeight: 400,
  borderRadius: '4',
  boxShadow: '0px 3.2px 7.2px 0, rgba(0,0,0,.132)'
};
