/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props}
      fill='none'
      height='200'
      viewBox='0 0 200 200'
      width='200'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M101.353 101.232L101.042 199L16 149.961L16.3104 52.1935L101.353 101.232Z' fill='#0078D4'/>
      <path d='M101.352 101.232L184.222 52.1935L183.911 149.806L101.042 199L101.352 101.232Z' fill='#243A5E'/>
      <path d='M16.3105 52.1941L99.1802 3L184.222 52.1941L101.353 101.233L16.3105 52.1941Z' fill='#185A97'/>
      <path d='M99.1802 100.768L99.3354 22.8639L167.152 62.1261L166.842 139.875L99.1802 100.768Z' fill='#0078D4'/>
      <path d='M166.841 139.875L100.887 179.137L33.0703 140.03L99.1798 100.768L166.841 139.875Z' fill='#69E2FF'/>
      <path d='M99.18 100.768L33.0706 140.03L33.3809 62.1261L99.3352 22.8639L99.18 100.768Z' fill='#2BA3E0'/>
      <g clipPath='url(#clip0_39841_88353)'>
        <path d='M139.96 140.572L104.117 160.595C101.342 162.152 96.833 162.152 94.1737 160.595L57.984 140.572C56.5966 139.794 55.9028 138.792 55.9028 137.791V141.017C55.9028 142.018 56.5966 143.019 57.984 143.798L94.0581 163.821C96.833 165.378 101.342 165.378 104.002 163.821L139.844 143.798C141.232 143.019 141.926 142.018 141.926 141.017V137.791C142.041 138.792 141.348 139.794 139.96 140.572Z' fill='#C05300'/>
        <path d='M139.859 135.153C142.631 136.734 142.631 139.218 139.859 140.799L104.059 161.128C101.288 162.709 96.784 162.709 94.1279 161.128L57.9815 140.799C55.2099 139.218 55.2099 136.734 57.9815 135.153L93.7814 114.824C96.553 113.243 101.057 113.243 103.713 114.824L139.859 135.153Z' fill='#FF8222'/>
        <path d='M104.867 68.5205L124.615 135.831C124.615 135.831 125.77 141.252 121.035 144.866C121.035 144.866 112.489 150.964 98.1693 150.513C83.8493 150.061 76.8048 144.866 76.8048 144.866C71.9545 141.365 73.2248 135.831 73.2248 135.831L92.857 68.5205C92.857 68.5205 93.2035 66.2617 98.8622 66.2617C104.405 66.1488 104.867 68.5205 104.867 68.5205Z' fill='#F56A00'/>
        <path d='M104.725 68.52C104.725 69.7623 102.069 70.7787 98.9509 70.7787C95.7174 70.7787 93.1767 69.7623 93.1767 68.52C93.0612 67.2777 95.7174 66.2612 98.9509 66.2612C102.069 66.2612 104.725 67.2777 104.725 68.52Z' fill='#FF8222'/>
        <path d='M103.274 68.53C103.274 69.2239 101.305 69.7444 98.9204 69.7444C96.5361 69.7444 94.5664 69.2239 94.5664 68.53C94.5664 67.836 96.5361 67.3156 98.9204 67.3156C101.408 67.2288 103.274 67.7493 103.274 68.53Z' fill='#C05300'/>
        <path d='M98.9204 115.026H99.0537C99.0537 115.026 114.245 114.895 116.511 108.234L110.415 87.3346C108.673 90.992 99.0537 90.992 99.0537 90.992H98.9204C98.9204 90.992 89.3415 90.6872 87.4257 86.8556L81.3301 108.06C83.7287 114.721 98.9204 115.026 98.9204 115.026Z' fill='#FAFAFA'/>
      </g>
      <defs>
        <clipPath id='clip0_39841_88353'>
          <rect fill='white' height='99.2714' transform='translate(55.9028 66.2612)' width='86.0352'/>
        </clipPath>
      </defs>
    </svg>
  );
};
