export const normalizeMultiSelectDropdownValue = (value: string | string[] | undefined): string[] => {
  if (value === undefined) {
    return [];
  }

  if (typeof value === 'string') {
    return value.split(',');
  }

  return value;
};
