import './style.less';
import React from 'react';

import {
  ICommandBarItemProps,
  IContextualMenuProps,
  Stack,
  Toggle
} from '@fluentui/react';
import { Label } from '@fluentui/react/lib/Label';
import intl from 'react-intl-universal';

import { LocIds } from '../../../../../../common/Globalization/IntlEnum';
import { FilterableCommandBar } from '../../../../../../components/FilterableCommandBar';
import { InlineMultiFilter } from '../../../../../../components/FilterFieldWrapper';
import { FilterLayout } from '../../../../../../components/templates/FilterLayout';
import { ConMonAuditEvent } from '../../../../models/ContinuousMonitoringTypes';
import { useAllAuditEvents } from '../../redux/serviceSlice';

type IProps = {
  filterProps: {
    selectedGuids: Set<string>;
    setSelectedGuids: (guids: Set<string>) => void;
  };
  menuProps: {
    showEnabledOnly: boolean;
    setShowEnabledOnly: (checked: boolean) => void;
  }
  keywordProps: {
    keyword: string;
    onKeywordChange: (keyword: string) => void;
  };
  numTotal: number;
}

export const OnboardPanelCommandBar: React.FC<IProps> = (props) => {
  const { filterProps, keywordProps, menuProps, numTotal } = props;

  const auditEvents = useAllAuditEvents();

  const auditEventFilter = (
    <InlineMultiFilter<ConMonAuditEvent>
      calloutClassname={'onboardPanel-dropdownCallout'}
      items={auditEvents ?? []}
      keyOf={item => item.guid}
      label={intl.get(LocIds.Label.AuditEvent)}
      maxCount={2}
      optionOf={item => ({
        key: item.guid,
        text: item.name
      })}
      searchable
      selectedItems={auditEvents?.filter(a => filterProps.selectedGuids.has(a.guid)) ?? []}
      onChange={items => {
        filterProps.setSelectedGuids(new Set(items.map(item => item.guid)));
      }}
    />
  );

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'total',
      text: intl.get(LocIds.Label.NumberItems, {
        num: numTotal
      })
    }
  ];

  const onRenderOptionMenu = () => (
    <Stack className='optionToggle' role='menuitem'>
      <Label>{ intl.get(LocIds.ServiceWorkspace.ShowEnabledOnly) }</Label>
      <Toggle
        checked={menuProps.showEnabledOnly}
        offText={intl.get(LocIds.Label.Off)}
        onChange={(_, checked) => {
          menuProps.setShowEnabledOnly(checked ?? false);
        }}
        onText={intl.get(LocIds.Label.On)}
      />
    </Stack>
  );

  const optionProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: [
      {
        key: 'showActiveOnly',
        onRender: onRenderOptionMenu,
      },
    ],
  };

  return (
    <FilterLayout onRenderFilters={() => auditEventFilter} >
      <FilterableCommandBar items={commandBarItems} keywordProps={keywordProps} optionProps={optionProps} />
    </FilterLayout>
  );
};
