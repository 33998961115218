import './style.less';

import React from 'react';

import { Icon } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classnames from 'classnames';

import { FCC } from '../../models';

interface CollapsibleMenuProps {
  /**
   * Title text of component
   */
  title: string | React.ReactElement;

  /**
   * Wrapper classname, can be used to apply some custom styles
   */
  wrapperClassName?: string;

  /**
   * Callback which will be called when click on the title text
   */
  onTitleClick?: () => void;

  /**
   * Callback which will be called when collapse state change
   */
  onCollapseChange?: () => void;

  /**
   * If you want to control collapse state in parent component, pass isCollapsed as a props
   */
  isCollapsed?: boolean;

  height?: number;
}

/**
 * CollapsibleSection component can collapse its content by clicking on the control Icon next to title element
 */
export const CollapsibleMenu: FCC<CollapsibleMenuProps> = (props) => {
  const [isCollapsed, { toggle: toggleIsCollapsed }] = useBoolean(false);
  const {
    title,
    children,
    onTitleClick,
    wrapperClassName,
    isCollapsed: isCollapsedControlledByParent,
    height = 32,
  } = props;

  const isParentControlled = typeof isCollapsedControlledByParent === 'boolean';

  const wrapperCls = classnames('collapsibleSection', wrapperClassName, {
    'collapsibleSection--collapsed': isParentControlled ? isCollapsedControlledByParent : isCollapsed,
  });

  const onCollapseIconClick = (ev: React.MouseEvent) => {
    ev.stopPropagation();

    if (!isParentControlled) {
      toggleIsCollapsed();
    }

    if (typeof props.onCollapseChange === 'function') {
      props.onCollapseChange();
    }
  };
  const titleComponent =
    typeof title === 'string' ? (
      <span className='collapsibleSection__titleText' role='column'>{ title }</span>
    ) : (
      title
    );

  return (
    <div className={wrapperCls}>
      <div
        className='collapsibleSection__titleRow'
        style={{
          height
        }}
        onClick={onTitleClick}>
        <Icon
          className='collapsibleSection__chevronIcon'
          iconName='ChevronDown'
          role='cell'
          onClick={onCollapseIconClick}
        />
        { titleComponent }
      </div>
      <div className='collapsibleSection__childrenWrapper'>{ children }</div>
    </div>
  );
};
