import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';

import classNames from 'classnames';

import './TwoColumnLayout.less';
import { useLayoutAsideHeight } from './useLayoutAsideHeight';

import { FCC } from '../../../models';

import { useAppDispatch } from '../../../redux/reducer';
import { setMouseDragInProgress } from '../../../redux/userInteractionSlice/slice';
import {
  LocalStorageKeys, useLocalStorage
} from '../../../utils';

interface IProps {
  onRenderHeader?: () => React.ReactElement;
  onRenderSidebar?: () => React.ReactElement | null;
  className?: string;
  /**
   * If you are using TwoColumnLayout inside a Pivot, an additional top: 44px will be given to the sticky header
   * @defaultValue undefined
   */
  hasPivot?: boolean;
  /**
   * If true, an additional justify: end will be added to the header
   * @defaultValue undefined
   */
  isHeaderAlignRight?: boolean;
  /**
   * If true, the header will not be flex: this is now only used to let the annotation bar be on a signle line and left-aligned
   * @defaultValue undefined
  */
  disableHeaderFlex?: boolean;

  asideTop?: number;

  querySelectorsForItemsAboveElement?: string[];

  //Properties related to size options of sidebar vs main content:
  /**
   * Indicates whatever resizer will appear and allow panels to be resizabale
   */
  resizable?: boolean;
  /**
   * Width of sidebar when not resizable - will be used as starting point if resizable
   * if not provided, default css value will be used
   */
  asideWidth?: number;
  /**
 * Minimum width of sidebar, not needed if not resizable
 * if not provided, default css value will be used
 */
  asideMinWidth?: number;
  /**
  * Maximum width of sidebar, not needed if not resizable
  * if not provided, no max value will be used
  */
  asideMaxWidth?: number;
  /**
  * Local storage key to persist sidebar width - please use format appName/pageName
  * if not provided, shared value across different pages will be used - not recomendeed
  */
  asideWidthLocalStorageKey?: string;
}

export const TwoColumnLayout: FCC<IProps> = (props) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const asideRef = useRef<HTMLDivElement>(null);

  const localStorageKey = `asideWidth/${(props?.asideWidthLocalStorageKey ?? LocalStorageKeys.AsideWidthSharedKeyword)}`;

  const [top, setTop] = useState<number>(0);
  const [isResizing, setIsResizing] = useState(false);

  const [storedValue, setStoredValue] = useLocalStorage<number>(localStorageKey);

  const [asideWidth, setAsideWidth] = props.resizable ?
    //if component is resizable asideWidth value will be dynamic and stored in local storag
    [storedValue, setStoredValue] :
    //otherwise, we'll use value provided,
    [props.asideWidth, undefined];

  const dispatch: any = useAppDispatch();

  const startResizing = useCallback(() => {
    setIsResizing(true);
    dispatch(setMouseDragInProgress(true));
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
    dispatch(setMouseDragInProgress(false));
  }, [asideWidth]);

  const resize = useCallback(
    (mouseMoveEvent: { clientX: number; }) => {
      if (isResizing) {
        if (asideRef.current && typeof setAsideWidth === 'function') {
          setAsideWidth(
            mouseMoveEvent.clientX -
            asideRef.current.getBoundingClientRect().left
          );
        }
      }
    },
    [isResizing]
  );

  useLayoutAsideHeight({
    hasHeader: !!props.onRenderHeader,
    asideRef,
    headerRef,
    asideTop: props.asideTop
  });

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize]);

  useLayoutEffect(() => {
    let top = 0;
    // eslint-disable-next-line i18next/no-literal-string
    const tabsSelector = 'div[role=tablist]';
    const elsAbove = ['h2[class*="tp-page__title"]', '.ms-FocusZone', tabsSelector];
    elsAbove.map(elQuerySel => {
      top += getElementHeightNullCheck(elQuerySel);
    });
    top -= 1;
    setTop(top);

  }, []);

  const headerInnerClassNames = classNames(
    'two-column-layout__header-inner',
    {
      'two-column-layout__header-inner--alignRight': props.isHeaderAlignRight,
    },
    {
      'two-column-layout__header-inner--disableHeaderFlex': props.disableHeaderFlex,
    }
  );

  const containerClassNames = classNames(
    'tp__two-column-layout',
    props.onRenderSidebar ? '' : 'tp__two-column-layout--single',
    props.className,
  );

  const asideStyles = getAsideStyles(asideWidth, props.asideWidth, props.asideMinWidth, props.asideMaxWidth);

  return (
    <div className={containerClassNames}>
      { props.onRenderHeader && (
        <div className='two-column-layout__header'
          ref={headerRef}
          style={{
            top: `${top}px`
          }}>
          <div className={headerInnerClassNames}>
            { props.onRenderHeader() }
          </div>
          <div className='two-column-layout__header-divider' />
        </div>
      ) }

      <div className='tp__layout-row'>
        { props.onRenderSidebar && (
          <aside className='tp__layout-left'
            ref={asideRef}
            style={asideStyles}
            onMouseDown={(e) => e.preventDefault()}>
            <div className='tp__layout-left-content'>
              { props.onRenderSidebar() }
            </div>
            { props.resizable && (
              <div className={`tp__layout-resizer ${isResizing ? 'active' : ''}`}
                onMouseDown={startResizing}
              />
            ) }
          </aside>
        ) }

        <div className='tp__layout-main'>
          { props.children }
        </div>

      </div>
    </div>
  );
};

const getElementHeightNullCheck = (elementQuerySelector: string) => {
  let top = 0;
  const el = document.querySelector(elementQuerySelector);

  if (el) {
    top += el.getBoundingClientRect().height;
  }

  return top;
};

const getAsideStyles = (currentAsideWidth: number | null | undefined,
  defaultAsideWidth: number | undefined,
  minAsideWidth: number | undefined,
  maxAsideWidth: number | undefined,
): CSSProperties | undefined => {

  const styles: CSSProperties = {};

  if (currentAsideWidth) {
    styles.width = `${currentAsideWidth}px`;
  } else if (defaultAsideWidth) {
    styles.width = `${defaultAsideWidth}px`;
  }

  if (minAsideWidth) {
    styles.minWidth = `${minAsideWidth}px`;
  }

  if (maxAsideWidth) {
    styles.maxWidth = `${maxAsideWidth}px`;
  }

  return Object.keys(styles).length > 0 ? styles : undefined;
};
