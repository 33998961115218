import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

export const bytesToSize = (fileSizeInBytes: number): string => {
  const units = [
    'bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB'
  ];

  let l = 0,
    n = fileSizeInBytes || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  // Should not accept too large files
  if (l >= units.length) {
    return intl.get(LocIds.Error.FileTooLarge);
  }

  return n.toFixed(l > 0 ? 1 : 0) + ' ' + units[l];
};
