import './style.less';

import React, {
  ReactElement,
  useEffect,
  useRef,
  useState
} from 'react';

import {
  Separator,
  Stack,
  Text
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';


import {
  CardActionBar,
  CardActionBarProps
} from './CardActionBar';

import { CardShowMoreButton } from './CardShowMoreButton';
import {
  CardTag,
  CardTagProps
} from './CardTag';
import { CardTitle } from './CardTitle';

import { getSanitizedRichTextJsx } from '../../utils/getSanitizedRichTextJsx';

export interface SplitCardViewProps {
  title: string;
  titleLabel?: JSX.Element;
  description?: string;
  status?: string | ReactElement;
  tagProps?: CardTagProps;
  onRenderLeftBody?: (truncated: boolean) => ReactElement;
  onRenderRightBody?: (truncated: boolean) => ReactElement;
  onRenderFooter?: () => ReactElement;
  actionBarProps?: CardActionBarProps;
  // optional prop: whether "Show more" is displayed (e.g. too many files, too long indexing tree)
  overflow?: boolean;
  className?: string;
}

export const SplitCardView: React.FC<SplitCardViewProps> = (props) => {
  const { onRenderLeftBody, onRenderRightBody, onRenderFooter } = props;
  /**
   * Hooks and states
   */

  // Used to check whether the description text is overflow. "Show more" button is only displayed when the overflow happens.
  const descriptionRef = useRef<HTMLSpanElement>(null);

  const [overflow, setOverflow] = useState<boolean>(false);
  const [truncated, { toggle: toggleTruncated }] = useBoolean(true);

  useEffect(() => {
    const resetOverflow = () => {
      // If props.overflow = false, CardView displays "Show more" based on description length.
      setOverflow(props.overflow || checkOverflow(descriptionRef.current));
    };

    resetOverflow();
    window.addEventListener('resize', resetOverflow);
    return () => {
      window.removeEventListener('resize', resetOverflow);
    };
  }, [props.overflow, descriptionRef.current]);

  /**
   * UI properties
   */

  const showMoreButton = overflow && (
    <CardShowMoreButton showFullCard={!truncated} toggleShowFullCard={toggleTruncated}/>
  );

  const descriptionContent = getSanitizedRichTextJsx(truncated, props.description ?? '');

  const description = props.description && (
    <span
      className={classNames('cardView__description text-14', {
        'cardView__description-truncated': truncated,
      })}
      ref={descriptionRef}>
      { descriptionContent }
    </span>
  );

  return (
    <Stack className={classNames('cardView', props.className)} >
      <Stack horizontal>
        <Stack className='cardView__leftContainer'>
          <Stack horizontal horizontalAlign='space-between'>
            <CardTitle title={props.title} titleLabel={props.titleLabel}/>
            { props.status && <Text>{ props.status }</Text> }
          </Stack>
          <CardTag items={props.tagProps?.items || []}/>
          { onRenderLeftBody && onRenderLeftBody(truncated) }
          { description }
        </Stack>
        <Separator vertical />
        <Stack
          className='cardView__rightContainer'
          horizontal
          horizontalAlign='space-between'
          tokens={{
            childrenGap: 4
          }}>
          <Stack.Item className='cardView__rightContainer-mainBody' grow>
            { onRenderRightBody && onRenderRightBody(truncated) }
          </Stack.Item>
          <Stack verticalAlign='space-between'>
            <CardActionBar {...props.actionBarProps} />
            <Stack horizontal horizontalAlign='end'>
              { showMoreButton }
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack.Item>
        { onRenderFooter && onRenderFooter() }
      </Stack.Item>
    </Stack>

  );
};

// Check overflow status of description text box
const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
  if (textContainer) {
    return (
      textContainer.offsetHeight < textContainer.scrollHeight ||
      textContainer.offsetWidth < textContainer.scrollWidth
    );
  }

  return false;
};
