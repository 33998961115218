/* istanbul ignore file */

import { IRawStyle } from '@fluentui/react';

/** TS Styles for styling Fluent UI Components */

const Text: IRawStyle = {
  fontWeight: 400,
  letterSpacing: '0',
  textAlign: 'left',
  color: 'var(--gray160)'
};

export const Text10: IRawStyle = {
  ...Text,
  fontSize: '10px',
  lineHeight: '12px'
};

const Title: IRawStyle = {
  fontWeight: 600
};

export const Text12: IRawStyle = {
  ...Text,
  fontSize: '12px',
  lineHeight: '16px',
};

export const Text14: IRawStyle = {
  ...Text,
  fontSize: '14px',
  lineHeight: '20px',
};

export const Text16: IRawStyle = {
  ...Text,
  fontSize: '16px',
  lineHeight: '22px',
};

export const Text18: IRawStyle = {
  ...Text,
  fontSize: '18px',
  lineHeight: '24px',
};

export const Text20: IRawStyle = {
  ...Text,
  fontSize: '20px',
  lineHeight: '28px',
};

export const Text24: IRawStyle = {
  ...Text,
  fontSize: '24px',
  lineHeight: '32px',
};

export const Text28: IRawStyle = {
  ...Text,
  fontSize: '28px',
  lineHeight: '36px',
};

export const Text32: IRawStyle = {
  ...Text,
  fontSize: '32px',
  lineHeight: '40px',
};

export const Text42: IRawStyle = {
  ...Text,
  fontSize: '42px',
  lineHeight: '52px',
};

export const Text68: IRawStyle = {
  ...Text,
  fontSize: '68px',
  lineHeight: '76px',
};

export const Title10: IRawStyle = {
  ...Text10,
  ...Title
};

export const Title12: IRawStyle = {
  ...Text12,
  ...Title,
};

export const Title14: IRawStyle = {
  ...Text14,
  ...Title,
};

export const Title16: IRawStyle = {
  ...Text16,
  ...Title,
};

export const Title18: IRawStyle = {
  ...Text18,
  ...Title,
};

export const Title20: IRawStyle = {
  ...Text20,
  ...Title,
};

export const Title24: IRawStyle = {
  ...Text24,
  ...Title,
};

export const Title28: IRawStyle = {
  ...Text28,
  ...Title,
};

export const Title32: IRawStyle = {
  ...Text32,
  ...Title,
};

export const Title42: IRawStyle = {
  ...Text42,
  ...Title,
};

export const Title68: IRawStyle = {
  ...Text68,
  ...Title,
};
