import React, {
  useEffect,
  useState
} from 'react';

import { isNil } from 'lodash';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  Certification,
  Nullable
} from '../../models';
import { EntityApi } from '../../services';
import { SingleSelectDropdown } from '../Dropdown';

type CertificationDropdownProps = {
  complianceAuditId: number | undefined;
  onChange: (cert: Nullable<Certification>) => void;
  selected?: Nullable<Certification>;
  // FIXME: String comparison? Buggy and should be removed.
  defaultValue?: string;
};

export const CertificationDropdown: React.FC<CertificationDropdownProps> = (
  props,
) => {
  const { complianceAuditId, onChange, selected } = props;

  const [allOptions, setAllOptions] = useState<Certification[]>();

  useEffect(() => {
    if (!isNil(complianceAuditId)) {
      EntityApi.getCertificationsByComplianceAuditIdAsync(
        complianceAuditId,
      ).then((certs) => {
        setAllOptions(certs);

        if (isNil(selected)) {
          onChange(
            certs.find((c) => c.ShortName === props.defaultValue) ?? null,
          );
        } else {
          onChange(
            certs.find(
              (c) => c.CertificationFamilyId === selected.CertificationFamilyId,
            ) ?? null,
          );
        }
      });
    }
  }, [complianceAuditId]);

  const isLoadingOptions = isNil(allOptions);

  const placeholder = isLoadingOptions ?
    intl.get(LocIds.Placeholder.LoadingOptions) :
    intl.get(LocIds.Placeholder.SelectAnOption);

  const errorMessage =
    !isLoadingOptions && isNil(selected) ?
      intl.get(LocIds.Error.KeyRequired, {
        key: intl.get(LocIds.Label.Certification),
      }) :
      undefined;

  return (
    <SingleSelectDropdown
      disabled={isLoadingOptions}
      errorMessage={errorMessage}
      items={allOptions ?? []}
      keyOf={(item) => `${item.CertificationFamilyId}`}
      label={intl.get(LocIds.Label.Certification)}
      optionOf={(item) => ({
        key: `${item.CertificationFamilyId}`,
        text: item.ShortName,
      })}
      placeholder={placeholder}
      required
      selectedItem={selected ?? undefined}
      onChange={onChange}
    />
  );
};
