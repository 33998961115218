import React, { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import {
  Application, RegulationManagement
} from '../../TrustPortal/registerApplicationMenu';

const Home = lazy(() => import('../pages/Home'));
const RegulationHub = lazy(() => import('../pages/RegulationHub'));

export const routes: RouteObject[] = [
  {
    path: `${Application.RegulationManagement}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${RegulationManagement.RegulationHub}/*`,
        element: <RegulationHub />
      }
    ],
  },
];
