import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';

import { IApplication } from '../../models';
import { UseAsyncHookStatus } from '../../utils';
import { RootReduxState } from '../reducer';

export const useMyAccountGuidSelector = () => {
  return useSelector<RootReduxState, string>(state =>
    state.portal.config.MyAccountGuid);
};

export const useConfigApplicationsSelector = () => {
  return useSelector<RootReduxState, IApplication[]>(state =>
    state.portal.config.Applications, isEqual);
};

export const useConfigApiStatus = () => {
  return useSelector<RootReduxState, UseAsyncHookStatus | undefined>(state =>
    state.portal.config.apiStatus);
};
