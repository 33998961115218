import _ from 'lodash';

import {
  AddAttachment,
  UpdateAuditEventPayload,
  EvidenceRequestAttachment,
  UpdateAttachment
} from '../models';

type AttachmentsDiffResult = Pick<
  UpdateAuditEventPayload,
  'addAttachments' | 'removeAttachments' | 'updateAttachments'
>;

type Attachment = {
  AttachmentGuid: string;
  BlobGuid?: string;
};

const isUpdatedOriginAttachment = (attachment: Attachment): boolean => {
  return (
    _.isString(attachment.AttachmentGuid) &&
    attachment.AttachmentGuid.trim() !== '' &&
    !attachment.BlobGuid
  );
};

const isNewAttachment = (attachment: Attachment): boolean => {
  return (
    attachment.AttachmentGuid.trim() === '' &&
    _.isString(attachment.BlobGuid) &&
    attachment.BlobGuid.trim() !== ''
  );
};

/**
 *
 * @param origin list of origin attachments, origin attachments have no BlobGuid
 * @param updated list of updated attachments, including new attachments, new attachments have BlobGuid and empty AttachmentGuid
 * @param compareAttachment returns true means an origin attachment is updated
 * @param getUpdateAttachment construct UpdateAttachment in the request body
 * @param getNewAttachment construct AddAttachment in the request body
 * @returns
 */
export const diffAttachments = <T extends Attachment>(
  origin: T[],
  updated: T[],
  compareAttachment: (a: T, b: T) => boolean,
  getUpdateAttachment: (a: T, b: T) => UpdateAttachment,
  getNewAttachment: (b: T) => AddAttachment,
): AttachmentsDiffResult => {
  const editAttachmentsDic: Record<string, T> = updated
    .filter((a) => isUpdatedOriginAttachment(a))
    .reduce((acc, cur) => {
      acc[cur.AttachmentGuid] = cur;
      return acc;
    }, {} as Record<string, T>);

  const updateAttachments: UpdateAttachment[] = [];
  const removeAttachments: string[] = [];

  for (const originAttachment of origin) {
    const editAttachment = editAttachmentsDic[originAttachment.AttachmentGuid];

    if (originAttachment && !editAttachment) {
      removeAttachments.push(originAttachment.AttachmentGuid);
    }

    if (originAttachment && editAttachment) {
      const isUpdated = compareAttachment(originAttachment, editAttachment);

      if (isUpdated) {
        updateAttachments.push(
          getUpdateAttachment(originAttachment, editAttachment),
        );
      }
    }
  }

  const addAttachments: AddAttachment[] = updated
    .filter((a) => isNewAttachment(a))
    .map((a) => getNewAttachment(a));

  return {
    addAttachments: _.isEmpty(addAttachments) ? null : addAttachments,
    updateAttachments: _.isEmpty(updateAttachments) ? null : updateAttachments,
    removeAttachments: _.isEmpty(removeAttachments) ? null : removeAttachments,
  };
};

export const compareAttachment = (
  originAttachment: EvidenceRequestAttachment,
  editAttachment: EvidenceRequestAttachment,
) => {
  return (
    originAttachment.DisplayName !== editAttachment.DisplayName ||
    originAttachment.DownloadName !== editAttachment.DownloadName
  );
};

export const getUpdateAttachment = (
  originAttachment: EvidenceRequestAttachment,
  editAttachment: EvidenceRequestAttachment,
) => {
  return {
    AttachmentGuid: originAttachment.AttachmentGuid,
    SetTitle: null,
    SetFileName:
      originAttachment.DisplayName !== editAttachment.DisplayName ?
        {
          Value: editAttachment.DisplayName
        } :
        null,
    SetBlobGuid: null,
  };
};

export const getAddAttachment = (
  editAttachment: EvidenceRequestAttachment,
): AddAttachment => {
  return {
    BlobGuid: editAttachment.BlobGuid as string,
    FileName: editAttachment.DisplayName,
    Title: '',
  };
};
