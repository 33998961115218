import React from 'react';

import { Label } from '@fluentui/react';
import _ from 'lodash';
import intl from 'react-intl-universal';


import { CommonFilterProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { AzureAdMember } from '../../models';
import { EntityApi } from '../../services';
import { FilterFieldWrapper } from '../FilterFieldWrapper';
import { ThrottledPeoplePicker } from '../PeoplePicker';

export const AssignedToFilter: React.FC<
  CommonFilterProps<AzureAdMember[], AzureAdMember[]>
> = (props) => {
  const { selected, onChange } = props;

  return (
    <FilterFieldWrapper
      ariaLabel={intl.get(LocIds.Label.AssignedTo)}
      isClearActive={!_.isEmpty(selected)}
      onClear={() => onChange([])}>
      <div className='auditorPicker'>
        <Label>{ intl.get(LocIds.Label.AssignedTo) }</Label>
        <ThrottledPeoplePicker
          selectedEntities={selected ?? []}
          onChange={onChange}
          onSearch={EntityApi.searchUsersStringAsync}
        />
      </div>
    </FilterFieldWrapper>
  );
};
