import React from 'react';

import classNames from 'classnames';
import DOMPurify from 'dompurify';
import _ from 'lodash';


export const getSanitizedRichTextJsx = (renderHtmlAsPlainText: boolean, content: string, className?:string): JSX.Element => {
  const sanitizedContent = DOMPurify.sanitize(content);

  if (renderHtmlAsPlainText) {
    return (<span>{ htmlToPlainText(sanitizedContent) }</span>);
  }

  return (
    <div className={classNames(className, 'richTextContent')}
      dangerouslySetInnerHTML={{
        __html: sanitizedContent
      }}
    />
  );
};

export const htmlToPlainText = (html: string): string => {
  return _.unescape(html.replace(/<[^>]+>/g, '')).replace(/&(?:[a-z\d]+|#\d+|#x[a-f\d]+)/g, '');
};
