import { useSelector } from 'react-redux';

import { MyNetworkStatus } from '../../models';
import { RootReduxState } from '../reducer';

export const useMyNetworkStatusSelector = () => {
  return useSelector<RootReduxState, MyNetworkStatus | undefined>(
    state => state.portal.networkStatus.MyNetworkStatus
  );
};

export const useHasCorpNetAccessSelector = () => {
  return useSelector<RootReduxState, boolean>(state => {
    const networkStatus = state.portal.networkStatus.MyNetworkStatus;

    if (!networkStatus) {
      return true;
    }

    if (networkStatus.HasCorpNetAccess || networkStatus.IsLocal || networkStatus.IsConnectingWithIPv6) {
      return true;
    }

    return false;
  });
};
