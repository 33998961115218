import {
  CreateProposedEvidenceCommentRequestPayload,
  CreateProposedEvidenceRequestPayload,
  ProposedEvidence,
  UpdateProposedEvidenceRequestPayload
} from '../../models';
import {
  get, post
} from '../OdataApiService';
import { buildODataQuery } from '../utils/odataQueryBuilder';

export const GET_PROPOSED_EVIDENCE_API = 'data/ProposedEvidenceRequest';
export const CREATE_PROPOSED_EVIDENCE_API = 'data/CreateProposedEvidenceRequest';
export const UPDATE_PROPOSED_EVIDENCE_API = 'data/UpdateProposedEvidenceRequest';
export const CREATE_PROPOSED_EVIDENCE_COMMENT_API = 'data/CreateProposedEvidenceCommentRequest';

export namespace ProposedEvidenceApi {
  export const getProposedEvidencesAsync = async (auditEventGuid: string): Promise<ProposedEvidence[]> => {
    const odataQuery = buildODataQuery({
      filter: {
        AuditEventGuid: {
          eq: {
            type: 'guid',
            value: auditEventGuid
          }
        }
      },
    });

    return await get<ProposedEvidence[]>(GET_PROPOSED_EVIDENCE_API, {
      odataQuery
    });
  };

  export const getProposedEvidenceAsync = async (
    auditEventGuid: string,
    proposedEvidenceGuid: string
  ): Promise<ProposedEvidence | undefined> => {
    const odataQuery = buildODataQuery({
      filter: {
        AuditEventGuid: {
          eq: {
            type: 'guid',
            value: auditEventGuid
          }
        },
        ProposedEvidenceGuid: {
          eq: {
            type: 'guid',
            value: proposedEvidenceGuid
          }
        }
      },
    });

    const proposedEvidences = await get<ProposedEvidence[]>(GET_PROPOSED_EVIDENCE_API, {
      odataQuery
    });

    return proposedEvidences?.[0];
  };

  export const createProposedEvidenceAsync = async (payload: CreateProposedEvidenceRequestPayload): Promise<void> => {
    return await post(CREATE_PROPOSED_EVIDENCE_API, payload);
  };

  export const updateProposedEvidenceAsync = async (payload: UpdateProposedEvidenceRequestPayload): Promise<void> => {
    return await post(UPDATE_PROPOSED_EVIDENCE_API, payload);
  };

  export const createProposedEvidenceCommentAsync = async (payload: CreateProposedEvidenceCommentRequestPayload): Promise<void> => {
    return await post(CREATE_PROPOSED_EVIDENCE_COMMENT_API, payload);
  };
}
