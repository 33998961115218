export enum ServiceContactTypes {
    PrimaryChamp = 1,
    SME = 2,
    ServiceDevOwner = 3,
    ServicePMOwner = 4,
    ServiceAdmin = 5,
    ServicePointOfContact = 6,
    Champ = 7,
    CustomRecipient = 8,
  }

export interface ServiceContactDetails {
    ServiceTreeId: string;
    ServiceContactTypeId: ServiceContactTypes;
    DisplayName: string;
    ObjectId: string;
}
