import React from 'react';

import { Icon } from '@fluentui/react';

export interface CircularProgressBarProps {
  progress: number;
}

export const CircularProgressBar = ({
  progress,
  ringWidth = 2,
  size = 20,
}: {
  progress: number;
  ringWidth?: number;
  size?: number;
}): JSX.Element => {
  const radius = size / 2 - ringWidth - 1 > 1 ? size / 2 - ringWidth - 1 : 1;
  const angle = progress * Math.PI * 2 - Math.PI * 0.5;
  const cx = size / 2;
  const cy = size / 2;
  const sx = size / 2;
  const sy = size / 2 - radius;
  const tx = size / 2 + radius * Math.cos(angle);
  const ty = size / 2 + radius * Math.sin(angle);
  const largeArc = progress < 0.5 ? 0 : 1;
  const sweep = 1;

  return (
    <>
      { progress < 1 && (
        <svg
          className='circularProgressBar'
          height={size}
          width={size}
          xmlns='http://www.w3.org/2000/svg'>
          <ellipse
            className='background'
            cx={cx}
            cy={cy}
            rx={radius}
            ry={radius}
            style={{
              strokeWidth: ringWidth,
            }}
          />
          <path
            className='progress'
            d={`M ${sx} ${sy} A ${radius} ${radius} 0 ${largeArc} ${sweep} ${tx} ${ty}`}
            style={{
              strokeWidth: ringWidth
            }}
          />
        </svg>
      ) }
      { progress === 1 && (
        <Icon
          className='completeIcon'
          iconName='SkypeCircleCheck'
          style={{
            fontSize: `${size}px`
          }}
        />
      ) }
    </>
  );
};
