import './style.less';
import React from 'react';

import {
  IColumn, Persona, PersonaSize
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  EvidenceProposalState, ProposedEvidence
} from '../../models';
import { dateToLocalString } from '../../utils';
import {
  IndicatorLabel, LabelColor
} from '../StyledLabel';

export const getColumns = (): IColumn[] => {
  return [
    {
      key: 'title',
      name: intl.get(LocIds.Label.Title),
      minWidth: 200,
      onRender: (item: ProposedEvidence) => (
        <div className='proposalList__cell title-14'>
          <span>{ item.Title }</span>
        </div>
      )
    },
    {
      key: 'status',
      name: intl.get(LocIds.Label.Status),
      minWidth: 100,
      onRender: (item: ProposedEvidence) => {
        return (
          <div className='proposalList__cell text-14'>
            <IndicatorLabel
              color={getIndicatorColor(item.State)}
              text={item.State}
            />
          </div>
        );
      },
    },
    {
      key: 'proposedBy',
      name: intl.get(LocIds.Label.CreatedBy),
      minWidth: 150,
      isResizable: true,
      onRender: (item: ProposedEvidence) => (
        <div className='proposalList__cell'>
          <Persona size={PersonaSize.size24} text={item.CreatedByDisplayName} />
        </div>
      )
    },
    {
      key: 'proposedDate',
      name: intl.get(LocIds.Label.CreatedDate),
      fieldName: 'proposedDate',
      minWidth: 200,
      onRender: (item: ProposedEvidence) => (
        <div className='proposalList__cell text-14'>
          <span>{ dateToLocalString(new Date(item.CreatedDate)) }</span>
        </div>
      )
    },
  ];
};

const getIndicatorColor = (status: EvidenceProposalState) => {
  switch (status) {
  case EvidenceProposalState.InReview:
    return LabelColor.CyanBlue;
  case EvidenceProposalState.Rejected:
    return LabelColor.Red;
  case EvidenceProposalState.Accepted:
    return LabelColor.Green;
  default:
    return LabelColor.Gray;
  }
};
