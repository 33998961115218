import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import {
  Application,
  MockAudit,
} from '../../TrustPortal/registerApplicationMenu';
import NotFound from "../../TrustPortal/pages/NotFound";

const Home = lazy(() => import('../pages/Home'));
const AdminDashboard = lazy(() => import('../pages/AdminDashboard'));
const ManageAdminPortal = lazy(() => import('../pages/ManageAdminPortal'));
const Reports = lazy(() => import('../pages/Reports'));
const NewServiceEntry = lazy(() => import('../pages/NewServiceEntry'));
const FAQ = lazy(() => import('../pages/FAQ'));
const QuarterlyServices = lazy(() => import('../pages/QuarterlyServices'));
const Services = lazy(() => import('../pages/Services'));
const AdhocDataEntry = lazy(() => import('../pages/AdhocDataEntry'));

export const routes: RouteObject[] = [
  {
    path: `${Application.MockAudit}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${MockAudit.Dashboard}/*`,
        element: <AdminDashboard />,
      },
      {
        path: `menu/${MockAudit.Admin}/*`,
          element: <Reports />,
      },
      {
        path: `menu/${MockAudit.Reports}/*`,
        element: <Reports />,
      },
      {
        path: `menu/${MockAudit.FAQ}/*`,
        element: <Reports />,
      },
      {
        path: `menu/${MockAudit.QuarterlyServices}/*`,
        element: <Reports />,
      },
      {
          path: `menu/${MockAudit.Services}/*`,
          element: <Services />,
      },
      {
          path: `menu/${MockAudit.NewServiceEntry}/*`,
          element: <NewServiceEntry />,
      },
      {
          path: `menu/${MockAudit.AdhocDataEntry}/*`,
          element: <AdhocDataEntry />,
      },
      {
        path: '*',
        element: <NotFound />,
      }
    ],
  },
];
