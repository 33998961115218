import React, { useMemo } from 'react';

import {
  DetailsList,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Stack,
  Text
} from '@fluentui/react';

import { isEmpty } from 'lodash';
import intl from 'react-intl-universal';

import { getBuildEvidenceColumns } from './BuildEvidenceTableColumns';

import { LocIds } from '../../../../../common/Globalization/IntlEnum';

import { Banner } from '../../../../../components';
import {
  BuildEvidenceTrace,
  CompliantStatus,
} from '../../../../../models';

import { ComplianceStatusBadge } from '../../../components/ComplianceStatusBadge';

import {
  gatingDetailsTables,
  gatingDetailsTableHeading,
  tableStackTokens,
  tabContentStyle
} from '../styles';

interface BuildEvidenceTableProps {
  BuildEvidenceTraces: BuildEvidenceTrace[]
  BuildEvidenceCompliantStatus: CompliantStatus
}

export const BuildEvidenceTable: React.FC<BuildEvidenceTableProps> = (props) => {
  const getSdlEvidenceResult = useMemo<string | undefined>(() => {
    if (props.BuildEvidenceTraces.length > 0) {
      if (props.BuildEvidenceCompliantStatus === CompliantStatus.Compliant) {
        return undefined;
      }

      const sdlBuildEvidence = props.BuildEvidenceTraces.filter(e => e.Type === intl.get(LocIds.Gating.SDLBuildEvidence));

      if (isEmpty(sdlBuildEvidence)) {
        return intl.get(LocIds.Gating.SDLBuildEvidenceMissing);
      }

      if (!sdlBuildEvidence[0].Exists) {
        return intl.get(LocIds.Gating.SDLBuildEvidenceMissing);
      }

      if (!sdlBuildEvidence[0].IsRun) {
        return intl.get(LocIds.Gating.SDLBuildEvidenceNotRun);
      }

      return undefined;
    }

    return intl.get(LocIds.Gating.SDLBuildEvidenceMissing);
  }, [props.BuildEvidenceTraces]);

  const tableHeading: JSX.Element = (
    <div style={gatingDetailsTableHeading}>
      <Stack
        horizontal
        horizontalAlign='start'
        tokens={tableStackTokens}
        verticalAlign='center'>
        <Text variant={'xLarge'}>{ intl.get(LocIds.Gating.BuildEvidence) }</Text>
        <ComplianceStatusBadge complianceStatus={props.BuildEvidenceCompliantStatus} />
      </Stack>
    </div>
  );

  const buildEvidenceTable: JSX.Element = (
    <div style={gatingDetailsTables}>
      <DetailsList
        columns={getBuildEvidenceColumns()}
        items={props.BuildEvidenceTraces}
        selectionMode={SelectionMode.none}
      />
    </div>
  );

  const errorMessage: JSX.Element = (
    <MessageBar messageBarType={MessageBarType.error}>
      { getSdlEvidenceResult }
    </MessageBar>
  );

  const noBuildEvidenceMessage: JSX.Element = (
    <div style={tabContentStyle}>
      <Banner
        heading={intl.get(LocIds.Gating.NoBuildEvidence)}
        iconName='empty'
        iconSize='large'
      />
    </div>
  );

  return (
    <>
      { tableHeading }
      { getSdlEvidenceResult && errorMessage }
      { !isEmpty(props.BuildEvidenceTraces) && !getSdlEvidenceResult ?
        buildEvidenceTable :
        noBuildEvidenceMessage }
    </>
  );
};
