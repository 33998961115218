import {
  IStaticPortalConfiguration,
  IPortalFlightings,
} from '../models';

/**
 * Service class for managing portal configuration.
 */
class PortalConfigurationService {
  private static instance: PortalConfigurationService;

  private staticConfiguration: IStaticPortalConfiguration = {} as IStaticPortalConfiguration;

  /**
   * Returns the singleton instance of the `PortalConfigurationService` class.
   * @returns The singleton instance of `PortalConfigurationService`.
   */
  public static getInstance(): PortalConfigurationService {
    if (!PortalConfigurationService.instance) {
      PortalConfigurationService.instance = new PortalConfigurationService();
    }

    return PortalConfigurationService.instance;
  }

  public configureService(configuration: IStaticPortalConfiguration): void {
    this.staticConfiguration = configuration;
  }

  public async getConfigurationsAsync(): Promise<IStaticPortalConfiguration> {
    const response = await fetch('/api/Configuration');
    return await response.json();
  }

  public getPortalFlightings(): Readonly<IPortalFlightings> {
    return this.staticConfiguration;
  }

  public getAthenaBaseAddress(): string {
    return this.staticConfiguration.athenaApiDomain;
  }

  public getAthenaApiScopes(): string[] {
    return [this.staticConfiguration.athenaIdUrl + '/user_impersonation'];
  }

  public getOCVAppID(): number {
    return this.staticConfiguration.ocvAppId;
  }

  public getVersion(): string {
    return this.staticConfiguration.version;
  }
}

export const portalConfigurationService = PortalConfigurationService.getInstance();
