import React from 'react';

import { Stack } from '@fluentui/react';

import { FCC } from '../../models';

interface FormSectionProps {
    className?: string;
    compact?: boolean;
}

export const FormSection: FCC<FormSectionProps> = (props) => {
  return (
    <Stack
      className={props.className}
      tokens={{
        childrenGap: props.compact ? 6 : 12,
        padding: '4px 0'
      }}>
      { props.children }
    </Stack>
  );
};
