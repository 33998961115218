import React, {
  useEffect, useState
} from 'react';

import {
  FontIcon, Label, Stack, TooltipHost
} from '@fluentui/react';
import {
  isEmpty, isNil, isUndefined
} from 'lodash';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { Service } from '../../models';
import { EntityApi } from '../../services';
import {
  MultiSelectDropdown, SharedDropdownProps, SingleSelectDropdown
} from '../Dropdown';
import { TagGroup } from '../TagGroup';

type ServiceDropdownProps = {
  label?: string;
  complianceAuditId?: number;
  complianceAuditGuid?: string;
  // undefined: certification not specified. null: certification specified as 'None' or N/A.
  certificationFamilyId: number | undefined | null;
  environmentId: number | undefined | null;
  onChange: (items: Service[]) => void;
  selected?: Service[];
  // defaultValues is used to initialize the selected items when the component is first rendered. Our entities tend to save either service tree IDs or service names, so we need to support both.
  defaultValues?: { guid?: string; name?: string }[];
  errorMessage?: string;
  tooltip?: string;
  disabled?: boolean;
  isMulti?: boolean;
};

export const ServiceDropdown: React.FC<ServiceDropdownProps> = (props) => {
  const {
    label = intl.get(LocIds.Label.Service),
    complianceAuditId,
    complianceAuditGuid,
    certificationFamilyId,
    environmentId,
    onChange,
    selected,
    defaultValues,
    disabled,
    isMulti,
  } = props;

  const [allOptions, setAllOptions] = useState<Service[]>();

  useEffect(() => {
    if ((!isUndefined(complianceAuditId) || !isUndefined(complianceAuditGuid)) && !isUndefined(certificationFamilyId) && !isUndefined(environmentId)) {
      EntityApi.getServiceForAuditAsync(
        complianceAuditId,
        complianceAuditGuid,
        certificationFamilyId ?? undefined,
        environmentId ?? undefined
      ).then((svs) => {
        setAllOptions(svs);

        if (isUndefined(selected)) {
          onChange(svs.filter((s) => defaultValues?.findIndex((d) => d.guid === s.ServiceTreeId || d.name === s.ShortName) !== -1));
        } else {
          onChange(svs.filter(s => selected.some(s0 => s0.ResponsiblePartyId === s.ResponsiblePartyId)));
        }
      });
    }
  }, [
    complianceAuditId,
    complianceAuditGuid,
    certificationFamilyId,
    environmentId
  ]);

  const isLoadingOptions = isUndefined(allOptions) && isUndefined(certificationFamilyId) && isUndefined(environmentId);
  const isDropdownDisabled = disabled || isUndefined(allOptions);

  const placeholder = isLoadingOptions ?
    intl.get(LocIds.Placeholder.LoadingOptions) :
    (isMulti ? intl.get(LocIds.Placeholder.SelectOptions) : intl.get(LocIds.Placeholder.SelectAnOption) );

  const errorMessage = props.errorMessage ??
    (!isLoadingOptions && isEmpty(selected) ? intl.get(LocIds.AuditManager.PleaseSelectAtLeastOneService) : undefined);

  const onRenderLabel = () => (
    <Stack horizontal verticalAlign='center'>
      <Label disabled={isDropdownDisabled} required>{ label }</Label>
      <TooltipHost content={props.tooltip} >
        <FontIcon iconName='Info'/>
      </TooltipHost>
    </Stack>
  );

  const dropdownProps: SharedDropdownProps<Service> = {
    items: allOptions ?? [],
    keyOf: (item) => `${item.ResponsiblePartyId}`,
    label,
    optionOf: (item) => ({
      key: `${item.ResponsiblePartyId}`,
      text: item.ShortName,
    }),
    placeholder,
    required: true,
    searchable: true,
    onRenderLabel: !isNil(props.tooltip) ? onRenderLabel : undefined,
    errorMessage,
    disabled: isDropdownDisabled,
  };

  const dropdown = (isMulti ? (
    <MultiSelectDropdown
      {...dropdownProps}
      selectedItems={selected}
      onChange={onChange}
    />
  ) : (
    <SingleSelectDropdown
      {...dropdownProps}
      selectedItem={selected?.[0]}
      onChange={(item) => onChange(item ? [item] : [])}
    />
  ));

  const tagGroup = (
    <TagGroup
      disabled={disabled}
      tags={selected?.map((sv) => ({
        key: sv.ResponsiblePartyId,
        name: sv.ShortName,
      })) ?? []}
      onDelete={(id) =>
        onChange(selected?.filter((s) => s.ResponsiblePartyId !== id) ?? [])}
    />
  );

  return (
    <Stack tokens={{
      childrenGap: 6
    }}>
      { dropdown }
      { isMulti && tagGroup }
    </Stack>
  );
};
