import React from 'react';
import './UserIndicator.less';

import {
  ActionButton,
  Callout,
  Stack
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import intl from 'react-intl-universal';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import {
  SizeEnum,
  UserIcon
} from '../../../../components';
import { signOut } from '../../../../redux/authSlice/thunk';
import { useAppDispatch } from '../../../../redux/reducer';
import { useUserAccount } from '../../../../utils';

export const UserIndicator = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const onSignOut = () => dispatch(signOut());

  const account = useUserAccount();
  const [isUserInfoCalloutVisible, { toggle: toggleVisible }] =
    useBoolean(false);
  const { name = '', username = '' } = account || {};

  // eslint-disable-next-line i18next/no-literal-string
  const userIconButtonId = 'userIconButton';

  return (
    <div className='tp-header__user-indicator'>
      <div className='text-12 tp-header__toggle-callout'>
        <UserIcon id={userIconButtonId} name={name} onClick={toggleVisible} />
      </div>

      { isUserInfoCalloutVisible && (
        <Callout
          className='tp-header__callout'
          gapSpace={10}
          isBeakVisible={false}
          target={`#${userIconButtonId}`}
          onDismiss={toggleVisible}>

          <Stack horizontal verticalAlign='center'>
            { /*<span className='text-14'>{ intl.get(LocIds.Application.Microsoft) }</span>*/ }
            <ActionButton className='tp-header__sign-out' onClick={onSignOut}>
              { intl.get(LocIds.Application.SignOut) }
            </ActionButton>
          </Stack>

          <Stack horizontal>
            <UserIcon
              id={userIconButtonId}
              name={name}
              size={SizeEnum.Large}
            />
            <div>
              <div className='title-18 user-indicator__name'>{ name }</div>
              <div className='text-14'>{ username }</div>
            </div>
          </Stack>

        </Callout>
      ) }
    </div>
  );
};
