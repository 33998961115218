import './CollapsedFilter.style.less';

import React from 'react';

import {
  IconButton,
  Stack
} from '@fluentui/react';
import intl from 'react-intl-universal';


import { CollapsedFilterProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { InlineLabel } from '../FilterFieldWrapper/InlineLabel';


export const CollapsedFilter: React.FC<CollapsedFilterProps> = (props) => {
  const { onClear, items = [], maxCount = 2 } = props;

  let itemsText = items.slice(0, maxCount).join(', ');

  if (items.length > maxCount) {
    itemsText += ` +${items.length - maxCount}`;
  }

  return (
    <Stack
      className='collapsedFilterWrapper'
      horizontal
      tabIndex={0}
      tokens={{
        childrenGap: 2
      }}
      onClick={props.onClick}>
      <InlineLabel label={props.label}>
        { itemsText ? itemsText : intl.get(LocIds.Label.None) }
      </InlineLabel>
      { onClear && (
        <IconButton
          className='collapsedFilterWrapper-clearIcon'
          iconProps={{
            iconName: 'Cancel'
          }}
          title={intl.get(LocIds.Components.RemoveFilter, {
            label: `${props.label}`,
          })}
          onClick={(e) => {
            e.stopPropagation();
            onClear();
          }}
        />
      ) }
    </Stack>
  );
};
