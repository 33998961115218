import React from 'react';

import {
  Coachmark,
  DirectionalHint,
  IButtonProps,
  ICoachmarkProps,
  IconButton,
  Stack,
  TeachingBubbleContent
} from '@fluentui/react';

import { useBoolean } from '@fluentui/react-hooks';

import intl from 'react-intl-universal';

import { CoachmarkIds } from './CoachmarkIds';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { useCoachmarksIndexSelector } from '../../redux/coachmarkSlice/selectors';
import { setActiveCoachmarkIndex } from '../../redux/coachmarkSlice/slice';
import { useAppDispatch } from '../../redux/reducer';
import { useActivateCoachmarksSettingSelector } from '../../redux/settingsPanelSlice/selector';
import { openSettingsPanel } from '../../redux/settingsPanelSlice/slice';
import { getSanitizedRichTextJsx } from '../../utils/getSanitizedRichTextJsx';

export interface IClientCoachmarksProps {
  description: string;
  identifier: string;
}

export interface ICoachmarksProps extends ICoachmarkProps {
  description: string;
  direction?: DirectionalHint;
  identifier: string;
}

export const TeachingBubbleCoachmark: React.FC<ICoachmarksProps> = (props: ICoachmarksProps) => {
  const dispatch = useAppDispatch();
  const { description, direction, target, identifier } = props;
  const [isCoachmarkVisible, { setFalse: hideCoachmark, setTrue: showCoachmark }] = useBoolean(true);
  const portalSetting = useActivateCoachmarksSettingSelector();
  const currentActiveIndex = useCoachmarksIndexSelector();
  const positioningContainerProps = {
    directionalHint: direction ?? DirectionalHint.bottomLeftEdge,
    doNotLayer: false,
  };

  let index = 1;
  let totalCount = 1;

  // Split the identifier into parts and remove the first part ("CoachmarkIds")
  //"CoachmarkIds.UAR.ClassificationException.FiltersLabel"
  const parts = identifier.split('.');

  parts.shift();

  if (parts?.length === 3) {
    const enumValues: any = CoachmarkIds;
    index = enumValues[parts[0]][parts[1]][parts[2]];
    const totalEnumLength: any = CoachmarkIds;
    totalCount = Object.keys(totalEnumLength[parts[0]][parts[1]]).length / 2;
  }

  const primaryButtonProps: IButtonProps = {
    children: index === totalCount ? intl.get(LocIds.Label.Close) : intl.get(LocIds.Action.Next),
    onClick: () => {
      hideCoachmark();
      dispatch( index === totalCount ? setActiveCoachmarkIndex(1) : setActiveCoachmarkIndex(index + 1));
    }
  };

  const settingsIcon = (
    <IconButton
      iconProps={{
        iconName: 'settings',
        styles: {
          root: {
            color: 'var(--white)'
          }
        }
      }}
      onClick={() => dispatch(openSettingsPanel())}
    />
  );

  const footerJsx = (
    <Stack horizontal
      style={{
        alignItems: 'center'
      }}>
      <Stack.Item>{ settingsIcon }</Stack.Item>
      <Stack.Item>{ `${index} of ${totalCount}` }</Stack.Item>
    </Stack>
  );

  const isOpen = index === currentActiveIndex;
  const isActive = isOpen || (currentActiveIndex === 0 && index === 1);

  return (
    <div>
      { portalSetting && isCoachmarkVisible && isActive && (
        <Coachmark
          isCollapsed = {!isOpen}
          positioningContainerProps={positioningContainerProps}
          target={target}>
          <TeachingBubbleContent
            closeButtonAriaLabel={intl.get(LocIds.Label.Close)}
            footerContent={footerJsx}
            hasCloseButton
            primaryButtonProps={primaryButtonProps}
            onDismiss={hideCoachmark}>
            { getSanitizedRichTextJsx(false, description) }
          </TeachingBubbleContent>
        </Coachmark>
      ) }
    </div>
  );
};
