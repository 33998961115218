import React, { FC } from 'react';

import { IDatePickerProps } from '@fluentui/react';
import _ from 'lodash';
import {
  Controller, UseControllerProps, useFormContext
} from 'react-hook-form';

import intl from 'react-intl-universal';


import { LocIds } from '../../../common/Globalization/IntlEnum';
import { DateRangePicker } from '../../DatePicker';

interface IProps extends IDatePickerProps, Pick<UseControllerProps, 'rules'> {
  name: string;
  label: string;
  readOnly?: boolean;
}

export const DateRangeControl: FC<IProps> = (props: IProps) => {
  const { control } = useFormContext();

  const normalizeDateRangeValue = (value: (Date | string | null | undefined)[] | undefined | string) :
  (Date | null| undefined)[] => {
    if (value === undefined) {
      return [undefined, undefined];
    }

    if (_.isArray(value) || _.isString(value)) {
      const res = _.isArray(value) ? value : value.split(',');

      if (res.length === 2) {
        return res.map(date => {
          if (_.isNil(date) || date === '') {
            return undefined;
          }

          if (_.isDate(date)) {
            return date;
          }

          return new Date(date);
        });
      }
    }

    return [undefined, undefined];
  };

  return (
    <Controller
      control={control}
      defaultValue={props.defaultValue}
      name={props.name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const dateRangeValue = normalizeDateRangeValue(value);
        const startDate = dateRangeValue[0];
        const endDate = dateRangeValue[1];

        return (
          <DateRangePicker
            {...props}
            disabled={props.readOnly}
            endDate={endDate ?? undefined}
            endDateLabel={props.label + ' - ' + intl.get(LocIds.Label.EndDate)}
            horizontal
            startDate={startDate ?? undefined}
            startDateLabel={props.label + ' - ' + intl.get(LocIds.Label.StartDate)}
            onSelectEndDate={(date) => {
              onChange([startDate, date]);
            }}
            onSelectStartDate={(date) => {
              onChange([date, endDate]);
            }}
          />
        );
      }}
      rules={props.rules}
    />
  );
};
