import React from 'react';

import { useNavigate } from 'react-router';

import { useParams } from 'react-router-dom';

import { ProposedEvidenceListPanel } from '../../../../../../../components';
import { useAuditEvent } from '../../../../../../../utils/react-hooks/useAuditEvent';

export const EvidenceProposalPanel: React.FC = () => {
  const navigate = useNavigate();
  const { auditEventGuid } = useParams();

  const { auditEvent } = useAuditEvent(auditEventGuid);

  const onDismiss = () => {
    navigate('..');
  };

  return (
    <ProposedEvidenceListPanel
      auditEvent={auditEvent}
      isAuditManager={false}
      onDismiss={onDismiss}
    />
  );
};
