import React from 'react';

import {
  Stack, Text
} from '@fluentui/react';
import _ from 'lodash';

export type CardTagProps = {
  items: { label?: string; value: string | JSX.Element }[];
};

// label-text pairs displayed between title and description
export const CardTag: React.FC<CardTagProps> = (props) => {
  const { items } = props;

  if (_.isEmpty(items)) {
    return null;
  }

  return (
    <Stack horizontal
      tokens={{
        childrenGap: 8
      }}
      wrap>
      { items.map((tag, index) => (
        <Stack className='cardView__tag' key={index} verticalAlign='start'>
          { tag.label && <Text className='cardView__label'>{ tag.label }</Text> }
          <Stack className='cardView__tagValue' verticalAlign='center'>
            { tag.value }
          </Stack>
        </Stack>
      )) }
    </Stack>
  );
};
