import React, { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import {
  Application,
  RightToAudit
} from '../../TrustPortal/registerApplicationMenu';

const Home = lazy(() => import('../pages/Home'));
const AuditIngestion = lazy(() => import('../pages/AuditIngestion'));

export const routes: RouteObject[] = [
  {
    path: `${Application.RightToAudit}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${RightToAudit.AuditIngestion}/*`,
        element: <AuditIngestion />,
      }
    ],
  },
];
