import { AxiosRequestConfig } from 'axios';
import { OdataQuery } from 'odata';

import { ApiService } from './ApiService';

// TODO: Remove OdataApiRequestConfig and use only AxiosRequestConfig
interface OdataApiRequestConfig extends AxiosRequestConfig {
  odataQuery?: OdataQuery;
}

type OdataApiResponse<T> = T & { value?: T };

export const get = async <TResponse>(
  resource: string, odataConfig?: OdataApiRequestConfig, axiosConfig?: AxiosRequestConfig
): Promise<TResponse> => {
  const config = getAxiosRequestConfigFromOdataApiRequestConfig(odataConfig, axiosConfig);
  const response = await ApiService.get<OdataApiResponse<TResponse>>(resource, config);
  return response.value || response;
};

export const post = async <TRequest, TResponse>(
  resource: string, data: TRequest, odataConfig?: OdataApiRequestConfig, axiosConfig?: AxiosRequestConfig
): Promise<TResponse> => {
  const config = getAxiosRequestConfigFromOdataApiRequestConfig(odataConfig, axiosConfig);
  const response = await ApiService.post<OdataApiResponse<TResponse>>(resource, {
    ...config,
    data
  });
  return response.value || response;
};

export const put = async <TRequest, TResponse>(
  resource: string, data: TRequest, odataConfig?: OdataApiRequestConfig, axiosConfig?: AxiosRequestConfig
): Promise<TResponse> => {
  const config = getAxiosRequestConfigFromOdataApiRequestConfig(odataConfig, axiosConfig);
  const response = await ApiService.put<OdataApiResponse<TResponse>>(resource, {
    ...config,
    data
  });
  return response.value || response;
};

// TODO: We cannot use delete as function name, need to refactor OdataApiService to a class/object
export const remove = async <TResponse>(
  resource: string, odataConfig?: OdataApiRequestConfig, axiosConfig?: AxiosRequestConfig
): Promise<TResponse> => {
  const config = getAxiosRequestConfigFromOdataApiRequestConfig(odataConfig, axiosConfig);
  const response = await ApiService.delete<OdataApiResponse<TResponse>>(resource, config);
  return response.value || response;
};

const getAxiosRequestConfigFromOdataApiRequestConfig = (
  config?: OdataApiRequestConfig, axiosConfig?: AxiosRequestConfig
): AxiosRequestConfig => {
  const result: AxiosRequestConfig = axiosConfig || {};

  if (config?.odataQuery) {
    result.params = config.odataQuery;
  }

  return result;
};
