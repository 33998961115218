import React, {
  useEffect, useState
} from 'react';

import { Stack } from '@fluentui/react';
import {
  isEmpty, isNil, isNull, isUndefined
} from 'lodash';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { CertificationControl } from '../../models';
import { EntityApi } from '../../services';
import { MultiSelectDropdown } from '../Dropdown';
import { TagGroup } from '../TagGroup';

type CertificationControlDropdownProps = {
    // null: dependencies (e.g. audit event) not selected, undefined: loading options from server to find a match
    certificationFamilyId: number | undefined | null;
    onChange: (items: CertificationControl[]) => void;
    selected?: CertificationControl[];
    // FIXME: String comparison? Buggy and should be removed.
    defaultValue?: string[];
    required?: boolean;
    disabled?: boolean;
};

export const CertificationControlDropdown: React.FC<CertificationControlDropdownProps> =
    (props) => {
      const { certificationFamilyId, onChange, selected, defaultValue, required, disabled } = props;

      const [allOptions, setAllOptions] = useState<CertificationControl[]>();

      useEffect(() => {
        if (!isNil(certificationFamilyId)) {
          EntityApi.getCertificationControlListAsync(certificationFamilyId).then((controls) => {
            setAllOptions(controls);

            if (isNil(selected)) {
              onChange(controls.filter((c) => defaultValue?.includes(c.Code)));
            } else {
              const newControls = controls.filter((c) => selected.some((c0) => c0.CertificationControlId === c.CertificationControlId));
              onChange(newControls);
            }
          },
          );
        }
      }, [certificationFamilyId]);

      const isDisabled = disabled || isUndefined(allOptions);
      const isLoadingOptions = isUndefined(allOptions) && !isNull(certificationFamilyId);

      const placeholder = isLoadingOptions ? intl.get(LocIds.Placeholder.LoadingOptions) : intl.get(LocIds.Placeholder.SelectOptions);
      const errorMessage = required && !isLoadingOptions && isEmpty(selected) ?
        intl.get(LocIds.AuditManager.PleaseSelectAtLeastOneCertificationControl) : undefined;

      const dropdown = (
        <MultiSelectDropdown
          disabled={isDisabled}
          errorMessage={errorMessage}
          items={allOptions ?? []}
          keyOf={(item) => `${item.CertificationControlId}`}
          label={intl.get(LocIds.Label.CertificationControl)}
          optionOf={(item) => ({
            key: `${item.CertificationControlId}`,
            text: item.Code,
          })}
          placeholder={placeholder}
          required={required}
          searchable
          selectedItems={selected}
          onChange={onChange}
        />
      );

      const tagGroup = (
        <TagGroup
          disabled={disabled}
          tags={selected?.map((ctrl) => ({
            key: ctrl.CertificationControlId,
            name: ctrl.Code,
          })) ?? []}
          onDelete={(id) =>
            onChange(selected?.filter((c) => c.CertificationControlId !== id) ?? [])}
        />
      );

      return (
        <Stack tokens={{
          childrenGap: 6
        }}>
          { dropdown }
          { tagGroup }
        </Stack>
      );
    };
