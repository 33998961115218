import {
  EvidenceTreeInternalNode,
  EvidenceTreeLeafNode,
} from './EvidenceTreeNodeBase';

import { EvidenceTreeNodeVisitor } from '../EvidenceTreeVisitor/EvidenceTreeNodeVisitor';

/**
 * Audit event node of EvidenceTree
 */
export class AuditEventNode extends EvidenceTreeInternalNode {
  constructor({
    name,
    AuditEventGuid,
    children,
  }: {
    name: string;
    AuditEventGuid: string;
    children: (EvidenceTreeLeafNode | EvidenceTreeInternalNode)[];
  }) {
    super();
    this.name = name;
    this.children = children;
    this.identifierName = 'AuditEventGuid';
    this.identifierValue = AuditEventGuid;
  }

  public accept(visitor: EvidenceTreeNodeVisitor): void {
    visitor.visitAuditEventNode(this);
  }
}
