const isGuidRegExp = new RegExp(
  '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$',
);

/**
 *
 * @param re Regular expression
 * @param str String to be tested
 *
 * Return positions of all matches, if no match is found, return -1
 * e.g. given re: /ab/, string: "abc", return [[0, 2]]
 */
export const getMatchPositions = (
  re: RegExp,
  str: string,
): Array<number[]> | number => {
  if (!re || !str) {
    return -1;
  }

  const result = [];
  let match: RegExpExecArray | null = null;
  while ((match = re.exec(str)) !== null) {
    const start = match.index;
    const end = match.index + match[0].length;
    result.push([start, end]);
  }

  if (result.length === 0) {
    return -1;
  }

  return result.sort((a: number[], b: number[]) => a[0] - b[0]);
};

/**
 * Split a string by all white spaces, and generate a regular expression to match some
 * of the tokens in the string
 */
export const getRegExpMatchMultipleWord = (str: string): RegExp | null => {
  if (!str || typeof str !== 'string' || str.trim() === '') {
    return null;
  }

  const regLiteral = str
    .split(/\s+/)
    .filter((v) => !!v)
    .map((v) => escapeStringRegExp(v.toLowerCase()))
    .join('|');
  return new RegExp(regLiteral, 'ig');
};

/**
 * Validate if the input string is valid GUID
 */
export const isGUID = (str: string): boolean => {
  return isGuidRegExp.test(str);
};

export const validateRegExpEmailName = (name: string): boolean => {
  const reg = /(^[A-z]+[A-z0-9_-]*$)|^$/i;
  return reg.test(name);
};

export const escapeStringRegExp = (str: string): string => {
  return str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
};
