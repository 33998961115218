import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { combineReducers } from 'redux';

import { evidenceSlice } from './evidenceSlice';
import { filterSlice } from './filterSlice';

export const reducer = combineReducers({
  [evidenceSlice.name]: evidenceSlice.reducer,
  [filterSlice.name]: filterSlice.reducer,
});

export type RootReduxState = { taskBoard: ReturnType<typeof reducer> };

// ---------------------------------------------------------------------------------------------------------------------

const store = configureStore({
  reducer: {
    taskBoard: reducer
  }
});

type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
