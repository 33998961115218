import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

enum dateProperties {
  dayStyleType = '2-digit',
  monthStyleType = '2-digit',
  yearStyleType = 'numeric',
}

export class FormattedDate {
  Date: string | Date | undefined;

  constructor(date: string | Date | undefined) {
    this.Date = date;
  }

  public ToUtcDateFormat() {
    if (!this.Date) {
      return '';
    }

    return new Date(this.Date).toLocaleString(undefined, {
      day: dateProperties.dayStyleType,
      month: dateProperties.monthStyleType,
      year: dateProperties.yearStyleType,
      timeZone: 'UTC'
    });
  }

  public GetYear() {
    if (!this.Date) {
      return null;
    }

    return new Date(this.Date).getFullYear();
  }
}

export const getRegionMap = (): Record<string, string> => {
  return {
    'Mt': intl.get(LocIds.Label.Mt),
    'GccMt': intl.get(LocIds.Label.GccMt),
    'DoDMt': intl.get(LocIds.Label.DoDMt),
    'DoD': intl.get(LocIds.Label.DoD),
    'Gcc': intl.get(LocIds.Label.Gcc),
    'GccM': intl.get(LocIds.Label.GccM),
    'GccHigh': intl.get(LocIds.Label.GccHigh),
    'None': intl.get(LocIds.Label.None)
  };
};

export const getGovTestStrings = () => {
  return {
    gcc: intl.get(LocIds.Label.Gcc),
    gccM: intl.get(LocIds.Label.GccM),
    gccHigh: intl.get(LocIds.Label.GccHigh),
    dod: intl.get(LocIds.Label.DoD)
  };
};

export interface FiscalYearTestCounts {
  FiscalYear: number;
  TestCount: number;
}
