import React, { ReactElement } from 'react';

import {
  Alignment,
  IStackItemStyles,
  IStackStyles,
  Stack
} from '@fluentui/react';

interface IProps
{
    /**
     * Whether columns can wrap to 2 rows, default to true.
     */
    wrap? : boolean;

    childrenGap?: number;

    rootStyle?: IStackStyles;
    leftStyle?: IStackItemStyles;
    rightStyle?: IStackItemStyles;

    className?: string;

    onRenderLeftBody: () => ReactElement | null;
    onRenderRightBody: () => ReactElement| null;

    horizontalAlign?: Alignment;
}

export const TwoColumnCardBodyLayout: React.FC<IProps> = (props: IProps) => {
  const {
    className = '',
    rootStyle,
    leftStyle,
    rightStyle,
    onRenderLeftBody,
    onRenderRightBody,
    wrap = true,
    childrenGap,
    horizontalAlign
  } = props;

  const leftBodyStyle = Object.assign({
    root: {
      flex: 1,
    }
  }, leftStyle);

  const rightBodyStyle = Object.assign({
    root: {
      flex: 1,
    }
  }, rightStyle);

  return (
    <Stack className={className}
      horizontal
      horizontalAlign={horizontalAlign}
      styles={rootStyle}
      tokens={{
        childrenGap
      }}
      wrap={wrap}>
      <Stack.Item styles={leftBodyStyle}>
        { onRenderLeftBody() }
      </Stack.Item>
      <Stack.Item styles={rightBodyStyle}>
        { onRenderRightBody() }
      </Stack.Item>
    </Stack>
  );
};
