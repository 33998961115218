import { AuditEventEnvironment } from '../AuditEvent';
import { DiscussionThread } from '../Discussions';
import { IETagged } from '../IEtagged';

export enum EvidenceProposalState {
  InReview = 'InReview',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

interface EvidenceCertification {
  Name: string;
  Controls: string[];
}

export interface ProposedEvidence extends IETagged {
  AuditEventGuid: string;
  ProposedEvidenceGuid: string;
  VersionGuid: string;
  Title: string;
  Description: string;
  State: EvidenceProposalState;
  Certification: EvidenceCertification | null;
  Environments: AuditEventEnvironment[];
  DiscussionThreads: DiscussionThread[];
  EvidenceGuids: string[];
  CreatedByAccountGuid: string;
  CreatedByDisplayName: string;
  CreatedDate: string;
}
