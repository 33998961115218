import './style.less';

import React from 'react';

import intl from 'react-intl-universal';

import { DiscussionSection } from './DiscussionSection';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { FCC } from '../../models';
import { UserComment } from '../Comment';
import {
  FormSection, ViewLayout
} from '../TrustLayout';
import { TrustPanel } from '../TrustPanel';

type IProps = {
  title: string;
  isOpen: boolean;
  isLoading: boolean;
  isError: boolean;
  isDisabled?: boolean; // Hide all content because the discussion is not enabled.
  comments: UserComment[];
  onSendComment: (content: string) => Promise<void> | undefined;
  onDismiss: () => void;
  onRefresh: () => void;
}

export const DiscussionPanel: FCC<IProps> = (props) => {
  const { title, isOpen, isLoading, isError, isDisabled = false, comments, onSendComment, onDismiss, onRefresh } = props;

  return (
    <TrustPanel
      closeLabel={intl.get(LocIds.Label.Close)}
      headerText={intl.get(LocIds.Label.DiscussionsWithTitle, {
        title
      })}
      isOpen={isOpen}
      onDismiss={onDismiss}>
      <ViewLayout isError={isError} isLoaded={true} isNotFound={isDisabled} notFoundMessage={intl.get(LocIds.Error.DiscussionDisabled)}>
        <FormSection>
          { props.children }
        </FormSection>
        <DiscussionSection
          comments={comments}
          isLoading={isLoading}
          onRefresh={onRefresh}
          onSendComment={onSendComment}
        />
      </ViewLayout>
    </TrustPanel>
  );
};
