import React from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';

import { LocIds } from '../../../common/Globalization/IntlEnum';
import {
  IApplication, IApplicationMenu, IPNetworkType
} from '../../../models';
import { useHasCorpNetAccessSelector } from '../../../redux/networkStatusSlice/selector';
import { portalConfigurationService } from '../../../services';
import { useNotification } from '../../../utils';

import { NetworkRestrictionDocLink } from '../components/NetworkRestrictionDocLink';

export const useNetworkRestrictionNotification = (
  currentApplication: IApplication | undefined,
  menuId: string
) => {
  const hasCorpNetAccess = useHasCorpNetAccessSelector();

  const currentApplicationMenus = currentApplication?.Menus ?? [];
  const currentMenu = currentApplicationMenus.find(m => m.MenuId === menuId);

  const result = getNotificationMessage(currentApplication, currentMenu);
  const messageContent = getMessageContent(result.message);
  const showNotification = shouldShowNotification(currentApplication, currentMenu, hasCorpNetAccess);

  useNotification(result.key, messageContent, showNotification);
};

const getNotificationMessage = (
  currentApplication: IApplication | undefined,
  currentMenu: IApplicationMenu | undefined
): {
  key: string,
  message: string
} => {
  const isInternalMenu = currentMenu?.AllowedIPNetworkTypes?.includes(IPNetworkType.CorpNetPublic) ?? false;
  const messageKey = `${currentApplication?.ApplicationId}`;

  if (isInternalMenu) {
    const isInternalApplication = currentApplication?.Menus?.every(
      m => m.AllowedIPNetworkTypes?.includes(IPNetworkType.CorpNetPublic)) ?? false;

    const menuNameSuffix = isInternalApplication ? '' : currentMenu?.DisplayName ? `-${currentMenu.DisplayName}` : '';

    return {
      key: isInternalApplication ? messageKey : `${messageKey}-${currentMenu?.MenuId}`,
      message: intl.get(LocIds.Platform.InternalApplicationCorpNetIpRestriction, {
        name: `${currentApplication?.DisplayName}${menuNameSuffix}`
      })
    };
  }

  return {
    key: messageKey,
    message: intl.get(LocIds.Platform.ExternalApplicationCorpNetIpRestriction)
  };
};

const getMessageContent = (message: string) => {
  return (
    <>
      { message }
      <NetworkRestrictionDocLink/>
    </>
  );
};

const shouldShowNotification = (
  currentApplication: IApplication | undefined,
  currentMenu: IApplicationMenu | undefined,
  hasCorpNetAccess: boolean
) => {
  if (!portalConfigurationService.getPortalFlightings().enableCorpNetIpRestriction) {
    return false;
  }

  if (_.isNil(currentApplication) || _.isNil(currentMenu)) {
    return false;
  }

  if (hasCorpNetAccess) {
    return false;
  }

  return true;
};
