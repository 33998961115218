import './style.less';

import React from 'react';

import {
  Stack,
  Text
} from '@fluentui/react';

import { FCC } from '../../models';

type InlineLabelProps = {
    label?: string;
};

export const InlineLabel: FCC<InlineLabelProps> = (props) => {
  return (
    <Stack horizontal
      tokens={{
        childrenGap: 6
      }}>
      { props.label && <Text title={props.label}>{ `${props.label}:` }</Text> }
      <Stack.Item className='inlineLabel-text'>{ props.children }</Stack.Item>
    </Stack>
  );
};
