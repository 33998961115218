/* eslint-disable @typescript-eslint/no-namespace */
import { OdataQuery } from 'odata';


import {
  DocItem,
  DocumentMenuItem
} from '../../models';
import {
  get,
  post
} from '../OdataApiService';
import { fieldMatchOnly } from '../utils/odataQueryBuilder';

export const GET_DOC_MENU_API = '/data/Documentation/Menu';
export const GET_DOCUMENTATION_API = '/data/Documentation/Content';

export const GET_DOCUMENT_CONTENT_API = '/data/GetDocumentContent';
export const GET_DOCUMENT_MENU_ITEMS_API = '/data/DocumentMetaData';

export namespace DocumentApi {
  export const getDocMenuAsync = async (appID: string): Promise<DocItem[]> => {
    const odataQuery: OdataQuery = {
      filter: fieldMatchOnly(appID, 'ApplicationID'),
    };
    const config = {
      odataQuery,
    };
    const data = await get<DocItem[]>(GET_DOC_MENU_API, config);
    return data;
  };

  export const getDocumentationAsync = async (
    appID: string,
    docPath: string,
  ): Promise<string> => {
    const odataQuery: OdataQuery = {
      filter: `${fieldMatchOnly(appID, 'ApplicationID')} and ${fieldMatchOnly(
        docPath,
        'DocumentPath',
      )}`,
    };
    const config = {
      odataQuery,
    };
    const data = await get<string>(GET_DOCUMENTATION_API, config);
    return data;
  };


  export const getDocumentMenuItems = async (): Promise<DocumentMenuItem[]> => {
    const data = await get<DocumentMenuItem[]>(GET_DOCUMENT_MENU_ITEMS_API);
    return data;
  };

  type DocumentContentParams = {uid: string};

  export const getDocumentContent = async (uid = ''): Promise<string> => {

    const data = await post<DocumentContentParams, string>(GET_DOCUMENT_CONTENT_API, {
      uid
    }).catch(() => '');

    if (typeof data === 'string') {
      return data;
    }

    return '';
  };
}
