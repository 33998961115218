import React, { useState } from 'react';

import {
  Label, MessageBar, MessageBarType, Separator, Stack, Text
} from '@fluentui/react';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { isEmpty } from 'lodash';
import intl from 'react-intl-universal';
import { IEditor } from 'roosterjs';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { UseAsyncHookStatus } from '../../utils';
import {
  CommentBox, CommentView, UserComment
} from '../Comment';
import { FormSection } from '../TrustLayout';

type IProps = {
  isLoading: boolean;
  comments: UserComment[];
  onSendComment: (content: string) => Promise<void> | undefined;
  onRefresh: () => void;
}

export const DiscussionSection: React.FC<IProps> = (props) => {
  const { isLoading, comments, onSendComment, onRefresh } = props;

  const [editor, setEditor] = useState<IEditor>();
  const [content, setContent] = useState<string>('');

  const [sendingStatus, setSendingStatus] = useState<UseAsyncHookStatus>(UseAsyncHookStatus.Success);

  const sortedComments = comments.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);

  const onSend = () => {
    setSendingStatus(UseAsyncHookStatus.Pending);
    onSendComment(editor?.getContent() ?? '')
      ?.then(() => {
        setSendingStatus(UseAsyncHookStatus.Success);
        editor?.setContent('');
        setContent('');
        onRefresh();
      })
      .catch(() => {
        setSendingStatus(UseAsyncHookStatus.Fail);
      });
  };

  const addCommentArea = (
    <Stack>
      <Label className={'discussion-panel__label'}>
        { intl.get(LocIds.Label.AddComment) }
      </Label>
      <CommentBox
        content={content}
        isLoading={isLoading || sendingStatus === UseAsyncHookStatus.Pending}
        setEditor={setEditor}
        onChangeContent={setContent}
        onSend={onSend}
      />
    </Stack>
  );

  const commentsAreaJsx = (
    <Stack>
      <Label className={'discussion-panel__label'}>
        { `${intl.get(LocIds.Label.Discussions)} (${comments.length})` }
      </Label>
      <FormSection compact>
        { isEmpty(comments) ? (
          <Text>{ intl.get(LocIds.Placeholder.NoCommentsYet) }</Text>
        ) : (sortedComments.map((comment, index) => (
          <div key={index}>
            { index > 0 && <Separator /> }
            <CommentView comment={comment} />
          </div>
        ))) }
      </FormSection>
    </Stack>
  );

  return (
    <>
      { sendingStatus === UseAsyncHookStatus.Fail && (
        <FormSection>
          <MessageBar messageBarType={MessageBarType.error}>
            { intl.get(LocIds.Error.SomethingWentWrong) }
          </MessageBar>
        </FormSection>
      ) }
      <FormSection className='discussion-panel'>
        { addCommentArea }
        { isLoading ? <Spinner className='rootSpinner' /> : commentsAreaJsx }
      </FormSection>
    </>
  );
};
