import React, {
  useEffect,
  useLayoutEffect,
  useState
} from 'react';

import DOMPurify from 'dompurify';
import _ from 'lodash';
import * as intl from 'react-intl-universal';
import {
  Outlet,
  useOutletContext
} from 'react-router-dom';

import { DocMenu } from './DocMenu';
import { buildDocumentMenuTree } from './helper';
import { useBreadcrumbItems } from './hooks/useBreadcrumbItems';

import { useDocUid } from './hooks/useDocUid';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { PageLayout } from '../../../../components/templates/PageLayout';
import { TwoColumnLayout } from '../../../../components/templates/TwoColumnLayout';
import { ViewLayout } from '../../../../components/TrustLayout';
import { DocTreeRootNode } from '../../../../models/DocTreeNode/DocTreeRootNode';
import { CollapseVisitor } from '../../../../models/DocTreeVisitor/CollapseVisitor';
import { FilterVisitor } from '../../../../models/DocTreeVisitor/FilterVisitor';
import { SortVisitor } from '../../../../models/DocTreeVisitor/SortVisitor';
import { DocumentMenuItem } from '../../../../models/Document';
import { DocumentApi } from '../../../../services/AthenaRestApiService/DocumentApiService';
import { useTitle } from '../../../../utils/react-hooks/useTitle';


export const Docs = () => {
  const [menuItems, setMenuItems] = useState<DocumentMenuItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);

  const docUid = useDocUid();
  const docItem = menuItems.filter(item => item.Uid === docUid)[0] ?? {
    DisplayName: '',
  };
  const docName = docItem.DisplayName;
  useTitle(`${intl.get(LocIds.Title.Documentation)} - ${(docName ?? '').replaceAll('-', ' ')}`);

  useEffect(() => {
    setIsLoading(true);
    DocumentApi.getDocumentMenuItems()
      .then((menus) => {
        setIsLoading(false);
        setMenuItems(menus);
      })
      .catch(() => {
        setIsLoading(false);
        setIsFailed(true);
      });
  }, []);

  useEffect(() => {
    DOMPurify.addHook('afterSanitizeAttributes', (node) => {
      // set all elements owning target to target=_blank
      if ('target' in node && new URL((node as HTMLAnchorElement).href).hostname !== window.location.hostname) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
      }
    });
    return () => {
      DOMPurify.removeHook('afterSanitizeAttributes');
    };
  }, []);

  const validateDocUid = new Set(menuItems.map(item => item.Uid));

  const menuTree = buildDocumentMenuTree(menuItems);
  menuTree.accept(new FilterVisitor(''));
  menuTree.accept(new CollapseVisitor(docUid));
  menuTree.accept(new SortVisitor());

  const pathItems = menuTree.findPathByUid(docUid);

  const breadCrumbItems = useBreadcrumbItems(pathItems);

  const [asideTop, setAsideTop] = useState(0);

  useLayoutEffect(() => {
    const header = document.querySelector('.tp-header');

    let top = 0;

    if (header) {
      top += header.getBoundingClientRect().height;
    }

    setAsideTop(top);
  }, [setAsideTop]);

  const bodyJsx = (
    <ViewLayout
      emptyMessage={intl.get(LocIds.Documentation.NoAvailableDocs)}
      isEmpty={_.isEmpty(menuItems)}
      isError={isFailed}
      isLoaded={!isLoading}>
      <TwoColumnLayout
        asideTop={asideTop}
        asideWidth={320}
        asideWidthLocalStorageKey='documentation/docs'
        className='docPageWrapper'
        resizable={true}
        onRenderSidebar={() => (
          <DocMenu menuTree={menuTree} validateDocUid={validateDocUid} />
        )}>
        <Outlet context={{
          menuTree,
          validateDocUid
        }}
        />
      </TwoColumnLayout>
    </ViewLayout>
  );

  return (
    <PageLayout breadCrumbItems={breadCrumbItems} pageTitle={intl.get(LocIds.Documentation.Title)}>
      { bodyJsx }
    </PageLayout>
  );
};

export function useDocData() {
  return useOutletContext<{ menuTree: DocTreeRootNode, validateDocUid: Set<string> }>();
}
