import { FilterOptions } from '../models/ComplianceViewer';

export function createUniqueOptionsList(filterName: string, filteredList: FilterOptions[]): string[] {
  const filterSet = new Set<string>();
  filteredList.forEach((filterOption) => {
    filterSet.add(filterOption[filterName as keyof FilterOptions]);
  });
  return Array.from(filterSet).sort();
}

export function checkShouldDisableFilterDropdown(filterList: string[]): boolean {
  if (filterList.length === 0) {
    return true;
  }

  if (filterList[0].toLocaleLowerCase() === 'notdefined' || filterList[0].toLocaleLowerCase() === '') {
    return true;
  }

  return false;
}
