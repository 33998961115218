import {
  MarkNotificationAsReadPayload, UserNotification
} from '../../models';
import { post } from '../OdataApiService';

export const GET_USER_NOTIFICATIONS = '/data/GetUserNotifications';
export const MARK_NOTIFICATION_AS_READ = '/data/MarkNotificationAsRead';

export namespace NotificationApi {
  export const getUserNotifications = async (): Promise<UserNotification[]> => {
    return await post<any, UserNotification[]>(GET_USER_NOTIFICATIONS, {});
  };

  export const markNotificationAsRead = async (payload: MarkNotificationAsReadPayload): Promise<void> => {
    return await post<MarkNotificationAsReadPayload, void>(MARK_NOTIFICATION_AS_READ, payload);
  };
}
