/* eslint-disable i18next/no-literal-string */
import React, { useRef } from 'react';

import {
  ITooltipHost,
  ITooltipHostStyles,
  TooltipHost
} from '@fluentui/react';
import {
  useBoolean,
  useId
} from '@fluentui/react-hooks';
import classnames from 'classnames';
import { isFinite } from 'lodash';

import { getLinkText } from './helper';
import {
  SideNavLink,
  SideNavLinkProps
} from './types';

/**
 * Leaf component of SideNav
 */
export const Link = <T extends SideNavLink<T>>(
  props: SideNavLinkProps<T>,
): ReturnType<React.FC<SideNavLinkProps<T>>> => {
  const { link, searchRegExp, onLinkClick, isLinkActive, getLinkClassName } =
    props;
  const tooltipId = useId('tooltip');
  const linkTextRef = useRef<HTMLSpanElement>(null);
  const toolTipRef = useRef<ITooltipHost>(null);

  const [showToolTip, { setTrue: showTooltip, setFalse: hideTooltip }] =
    useBoolean(false);

  if (!link || !link.isVisible) {
    return null;
  }

  const linkText = getLinkText(link.name, searchRegExp);
  const linkCls = classnames(
    {
      'sideNav__link': true,
      'sideNav__link--active': isLinkActive ? isLinkActive(link) : false,
    },
    getLinkClassName ? getLinkClassName(link as T) : '',
  );
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: {
      'display': 'inline-block',
      'text-overflow': 'ellipsis',
      'white-space': 'nowrap',
      'overflow': 'hidden',
    },
  };
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    if (onLinkClick) {
      onLinkClick(link, e.ctrlKey);
    }
  };
  const onMouseOver = () => {
    if (linkTextRef && linkTextRef.current) {
      const { clientWidth, scrollWidth } = linkTextRef.current;

      if (isFinite(clientWidth) && isFinite(scrollWidth)) {
        if (clientWidth < scrollWidth) {
          showTooltip();

          if (toolTipRef && toolTipRef.current) {
            toolTipRef.current.show();
          }
        } else {
          hideTooltip();
        }
      }
    }
  };
  const onMouseOut = () => {
    hideTooltip();
  };
  const linkTextContent = (
    <span
      className='sideNav__linkText'
      ref={linkTextRef}
      onMouseOut={onMouseOut}>
      { linkText }
    </span>
  );
  return (
    <div className={linkCls} onClick={onClick} onMouseOver={onMouseOver}>
      { showToolTip ? (
        <TooltipHost
          componentRef={toolTipRef}
          // This id is used on the tooltip itself, not the host
          // (so an element with this id only exists when the tooltip is shown)
          content={linkText}
          id={tooltipId}
          styles={hostStyles}>
          { linkTextContent }
        </TooltipHost>
      ) : (
        linkTextContent
      ) }
    </div>
  );
};
