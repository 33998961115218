import React from 'react';

import intl from 'react-intl-universal';

import { Dialog } from './Dialog';

import { LocIds } from '../../common/Globalization/IntlEnum';


interface DialogRemoveItemProps {
  itemName: string;
  itemAlias: string;
  onDismiss: () => void;
  onConfirm: () => void;
  subText?: string;
}

export const DialogRemoveItem: React.FC<DialogRemoveItemProps> = (props) => {
  const { itemName, itemAlias, onDismiss, onConfirm, subText } = props;

  return (
    <Dialog
      primaryButtonProps={{
        text: intl.get(LocIds.Action.Remove),
        onClick: onConfirm,
      }}
      subText={subText ??
        intl.get(LocIds.AuditManager.RemoveItemHint, {
          itemName: `${itemName}`,
          itemAlias: `${itemAlias}`,
        })}
      title={intl.get(LocIds.Label.RemoveItemConfirm, {
        itemName: `${itemName}`,
      })}
      onDismiss={onDismiss}
    />
  );
};
