import './FilterLayout.less';

import React from 'react';

import {
  IShimmerElement,
  IStackProps,
  Shimmer,
  ShimmerElementType,
  Stack
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../../common/Globalization/IntlEnum';
import { FCC } from '../../../models';
import {
  IClientCoachmarksProps, TeachingBubbleCoachmark
} from '../../TeachingBubbleCoachmark';


interface IProps {
  /** Returns a list of filters */
  onRenderFilters: () => React.ReactElement;
  enableShimmer?: boolean;
  hideFilterLabel?: boolean;
  filtersCoachmark?: IClientCoachmarksProps;
}

const stackCommonProps: IStackProps = {
  horizontal: true,
  wrap: true,
  verticalAlign: 'center',
};

export const FilterLayout: FCC<IProps> = ({ onRenderFilters, enableShimmer, children, hideFilterLabel, filtersCoachmark }) => {
  const targetDiv = React.useRef<HTMLDivElement>(null);

  const shimmerElements: IShimmerElement[] = [
    {
      type: ShimmerElementType.line,
      height: 30,
      width: 120
    },
    {
      type: ShimmerElementType.gap,
      width: 8
    },
    {
      type: ShimmerElementType.line,
      height: 30,
      width: 120
    }
  ];

  return (
    <>
      <div ref={targetDiv}
        style={{
          width: '100%'
        }}>
        <Stack
          className='tp-filterLayout'
          tokens={{
            childrenGap: 8
          }}
          {...stackCommonProps}>
          { hideFilterLabel ? undefined : <p className='text-14'>{ `${intl.get(LocIds.Label.Filters)}:` }</p> }
          { enableShimmer ? <Shimmer shimmerElements={shimmerElements} /> : onRenderFilters() }
          <Stack {...stackCommonProps}
            style={{
              marginLeft: 'auto'
            }}>
            { children }
          </Stack>
        </Stack>
      </div>
      { filtersCoachmark !== undefined && (
        <TeachingBubbleCoachmark
          description={filtersCoachmark.description}
          identifier={filtersCoachmark.identifier}
          target={targetDiv.current}
        />
      ) }
    </>
  );
};
