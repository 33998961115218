import React from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';

import { CommonFilterProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  AuditEventEnvironment,
  Environment
} from '../../models';
import { MultiSelectDropdown } from '../Dropdown';
import { FilterFieldWrapper } from '../FilterFieldWrapper';

export function EnvironmentFilter<
  T extends Environment | AuditEventEnvironment,
>(
  props: CommonFilterProps<T[], T[]>,
): ReturnType<React.FC<CommonFilterProps<T[], T[]>>> {
  const { selected, allOptions, onChange } = props;

  return (
    <FilterFieldWrapper
      ariaLabel={intl.get(LocIds.Label.Environment)}
      isClearActive={!_.isEmpty(selected)}
      onClear={() => onChange([])}>
      <MultiSelectDropdown<T>
        disabled={_.isEmpty(allOptions)}
        items={allOptions ?? []}
        keyOf={(item) => String(item.EnvironmentId)}
        label={intl.get(LocIds.Label.Environment)}
        optionOf={(item) => ({
          key: String(item.EnvironmentId),
          text: item.Name,
        })}
        placeholder={intl.get(LocIds.Placeholder.SelectToFilter)}
        selectedItems={selected}
        onChange={onChange}
      />
    </FilterFieldWrapper>
  );
}
