import _ from 'lodash';
import { useSelector } from 'react-redux';

import {
  EvidenceFilterAllState,
  EvidenceFilterState,
  EvidenceRequestsSortState
} from './types';

import { AuditEvent } from '../../../../../../models/AuditEvent';
import { RootReduxState } from '../reducer';

export const useStartDate = (): Date | undefined | null =>
  useSelector<RootReduxState, Date | undefined | null>(
    (state) => state.evidence.evidenceFilter.selected.startDate,
  );

export const useEndDate = (): Date | undefined | null =>
  useSelector<RootReduxState, Date | undefined | null>(
    (state) => state.evidence.evidenceFilter.selected.endDate,
  );

export const useAllPublishedAuditEvents = (): AuditEvent[] => {
  return useSelector<RootReduxState, AuditEvent[]>(
    (state) => state.evidence.evidenceFilter.all.auditEvents,
    _.isEqual,
  );
};

export const useEvidenceFilterAllOptions = (): EvidenceFilterAllState =>
  useSelector<RootReduxState, EvidenceFilterAllState>(
    (state) => state.evidence.evidenceFilter.all,
    _.isEqual,
  );

export const useEvidenceFilterSelectedOptions = (): EvidenceFilterState =>
  useSelector<RootReduxState, EvidenceFilterState>(
    (state) => state.evidence.evidenceFilter.selected,
    _.isEqual,
  );

export const useIsLoaded = (): boolean =>
  useSelector<RootReduxState, boolean>(
    (state) => state.evidence.evidenceFilter.isLoaded,
    _.isEqual,
  );

export const useEvidenceSortBy = (): EvidenceRequestsSortState =>
  useSelector<RootReduxState, EvidenceRequestsSortState>(
    (state) => state.evidence.evidenceFilter.sortBy,
  );
