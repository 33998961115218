import React from 'react';

import { ServiceConfigPanel } from './ServiceConfigPanel';

import {
  ComplianceAuditWorkload, Service
} from '../../models';

interface ServicesPanelForFilterProps {
  isOpen: boolean;
  onDismiss: () => void;
  allServices: Service[];
  allWorkloads?: ComplianceAuditWorkload[];
  initialSelectedServices?: Service[];
  onSave: (services: Service[]) => void;
}

export const ServicesPanelForFilter: React.FC<ServicesPanelForFilterProps> = (
  props,
) => {
  const { allServices, initialSelectedServices } = props;

  const onSave = (services: Service[]) => {
    const serviceIds = new Set(services.map((s) => s.ResponsiblePartyId));
    const servicesToSave = props.allServices.filter((s) =>
      serviceIds.has(s.ResponsiblePartyId),
    );
    props.onSave(servicesToSave);
  };

  return (
    <ServiceConfigPanel
      allowEmpty
      initialSelectedServices={initialSelectedServices?.map((serv) => ({
        Name: serv.ShortName,
        Id: serv.ResponsiblePartyId,
        ServiceTreeId: serv.ServiceTreeId,
      }))}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      onResolveServices={() => allServices}
      onResolveWorkloads={() => props.allWorkloads || []}
      onSave={onSave}
    />
  );
};
