import React from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';

import { CommonFilterProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  AuditEvent,
  AuditEventInfo
} from '../../models';
import {
  SingleSelectDropdown,
  SingleSelectDropdownProps
} from '../Dropdown';
import {
  FilterFieldWrapper,
  InlineSingleFilter
} from '../FilterFieldWrapper';

interface AuditEventFilterProps<T>
  extends CommonFilterProps<T[], T | undefined> {
  inline?: boolean;
  searchable?: boolean;
}

export function AuditEventFilter<T extends AuditEvent | AuditEventInfo>(
  props: AuditEventFilterProps<T>,
): ReturnType<React.FC<CommonFilterProps<T[], T | undefined>>> {
  const { onChange, allOptions, selected, searchable, inline = false } = props;

  const dropdownProps: SingleSelectDropdownProps<T> = {
    label: intl.get(LocIds.Label.AuditEvent),
    placeholder: intl.get(LocIds.Placeholder.SelectToFilter),
    items: allOptions,
    selectedItem: selected,
    optionOf: (item: T) => ({
      key: String(item.AuditEventGuid),
      text:
        (item as AuditEvent).Name || (item as AuditEventInfo).AuditEventName,
    }),
    keyOf: (item: T) => String(item.AuditEventGuid),
    onChange,
    searchable,
    styles: {
      callout: {
        minWidth: 250
      }
    },
  };

  return inline ? (
    <InlineSingleFilter {...dropdownProps} />
  ) : (
    <FilterFieldWrapper
      ariaLabel={intl.get(LocIds.Label.AuditEvent)}
      isClearActive={!_.isNil(selected)}
      onClear={() => onChange(undefined)}>
      <SingleSelectDropdown {...dropdownProps} />
    </FilterFieldWrapper>
  );
}
