import React from 'react';

import { IconButton } from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { IApplication } from '../../../../models';
import {
  useAppDisplayName,
  useAppId, useUserAccount
} from '../../../../utils';
import { OpenFeedbackService } from '../../services/OpenFeedbackService';

interface IProps {
    applications: IApplication[]
}

export const FeedbackButton: React.FC<IProps> = ({ applications }) => {
  const account = useUserAccount();

  const { applicationId } = useAppId();

  const appName = useAppDisplayName(applications, applicationId);

  const toggleHideContextualMenu = () => {
    OpenFeedbackService.toogleHideContextualMenu(appName, account!);
  };

  return (
    <div>
      <IconButton
        className='feedbackEmojiIcon'
        iconProps={{
          iconName: 'Emoji2'
        }}
        title={intl.get(LocIds.Application.Feedback)}
        onClick={toggleHideContextualMenu}
      />
    </div>
  );
};
