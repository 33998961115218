import React, {
  useEffect, useState
} from 'react';

import {
  CommandBar,
  ContextualMenuItemType,
  ICommandBarItemProps,
  IContextualMenuItem,
  IContextualMenuProps,
  Stack,
} from '@fluentui/react';
import _ from 'lodash';
import intl from 'react-intl-universal';

import {
  ThrottleKeywordBox, ThrottleKeywordBoxProps
} from './ThrottleKeywordBox';

import { LocIds } from '../../common/Globalization/IntlEnum';

interface IProps {
  ariaLabel?: string;
  items?: ICommandBarItemProps[];
  totalText?: string;
  numSelected?: number;
  onUnselectAll?: () => void;
  sortProps?: IContextualMenuProps;
  optionProps?: IContextualMenuProps;
  keywordProps?: ThrottleKeywordBoxProps;
}

export const FilterableCommandBar: React.FC<IProps> = (props) => {
  const {
    items: originItems = [], totalText, numSelected, onUnselectAll, sortProps, optionProps, keywordProps
  } = props;

  const selectionPropsItem = numSelected ? getSelectionItemProps(numSelected) : undefined;
  const unselectPropsItem = onUnselectAll ? getUnselectItemProps(onUnselectAll) : undefined;
  const totalNumberPropsItem = totalText ? getTotalItemProps(totalText) : undefined;
  const sortPropsItem = sortProps ? getSortItemProps(sortProps) : undefined;
  const optionPropsItem = optionProps ? getOptionItemProps(optionProps) : undefined;

  const [items, setItems] = useState<ICommandBarItemProps[]>([...originItems]);

  useEffect(() => {
    const dynamicItems = [];

    if (selectionPropsItem) {
      dynamicItems.push(selectionPropsItem);

      if (unselectPropsItem) {
        dynamicItems.push(unselectPropsItem);
      }
    }

    if (totalNumberPropsItem) {
      dynamicItems.push(totalNumberPropsItem);
    }

    if (sortPropsItem) {
      dynamicItems.push(sortPropsItem);
    }

    if (optionPropsItem) {
      dynamicItems.push(optionPropsItem);
    }

    const newItems = [...originItems, ...dynamicItems];

    if (!_.isEqual(items, newItems)) {
      setItems(newItems);
    }
  }, [
    originItems,
    numSelected,
    onUnselectAll,
    totalText,
    sortProps,
    optionProps
  ]);

  return (
    <Stack horizontal verticalAlign='center'>
      <CommandBar
        ariaLabel={props.ariaLabel}
        items={items}
        onReduceData={() => undefined}
      />
      { keywordProps && <ThrottleKeywordBox {...keywordProps} /> }
    </Stack>
  );
};

const getTotalItemProps = (totalText: string): ICommandBarItemProps => {
  return {
    key: 'numTotal',
    text: totalText
  };
};

const getSelectionItemProps = (num: number): ICommandBarItemProps => {
  return {
    key: 'numSelected',
    text: intl.get(LocIds.Label.NumberSelected, {
      num
    }),
  };
};

const getUnselectItemProps = (onUnselectAll: () => void): ICommandBarItemProps => {
  return {
    key: 'unselectAll',
    iconProps: {
      iconName: 'Cancel'
    },
    iconOnly: true,
    onClick: onUnselectAll,
    title: intl.get(LocIds.Action.ClearSelection),
  };
};

const getSortItemProps = (sortProps: IContextualMenuProps): ICommandBarItemProps => {
  return {
    key: 'sortingOptions',
    text: intl.get(LocIds.Label.SortingOptions),
    ariaLabel: intl.get(LocIds.Label.SortingOptions),
    iconProps: {
      iconName: 'Sort'
    },
    iconOnly: true,
    buttonStyles: {
      menuIcon: {
        display: 'none'
      }
    },
    subMenuProps: sortProps,
  };
};

const getOptionItemProps = (optionProps: IContextualMenuProps): ICommandBarItemProps => {
  return {
    key: 'viewOptions',
    text: intl.get(LocIds.Label.ViewOptions),
    ariaLabel: intl.get(LocIds.Label.ViewOptions),
    iconProps: {
      iconName: 'Equalizer'
    },
    iconOnly: true,
    buttonStyles: {
      menuIcon: {
        display: 'none'
      }
    },
    subMenuProps: optionProps,
  };
};

export const buildSortMenuItem = (
  key: string,
  title: string,
  onClickSortOption: (key: string, isAsc: boolean) => void,
  isChecked: (key: string, isAsc: boolean) => boolean,
  ascLabel = intl.get(LocIds.Label.Ascending),
  descLabel = intl.get(LocIds.Label.Descending),
): IContextualMenuItem => {
  return {
    key,
    itemType: ContextualMenuItemType.Section,
    sectionProps: {
      topDivider: true,
      bottomDivider: true,
      title: `${intl.get(LocIds.Label.SortBy)} ${title}`,
      items: [
        {
          key: `${key}Asc`,
          text: `${title} ${ascLabel}`,
          canCheck: true,
          isChecked: isChecked(key, true),
          onClick: () => onClickSortOption(key, true),
        },
        {
          key: `${key}Desc`,
          text: `${title} ${descLabel}`,
          canCheck: true,
          isChecked: isChecked(key, false),
          onClick: () => onClickSortOption(key, false),
        },
      ],
    },
  };
};

export interface SortListSubItem {
  uniqueKey: string,
  subitemText: string,
}

export interface SortListItem<T extends SortListSubItem> {
  subitems: T[],
  title?: string,
}

export interface SortListWithOrderOption<T extends SortListSubItem> {
  onClickSortOrderOption: (isAsc: boolean) => void,
  isAscending: boolean,
  items: SortListItem<T>[],
  onClickSortKeyOption: (subitem: T) => void,
  sortKey: string,
}

export const buildSortList = <T extends SortListSubItem>(
  sortList: SortListWithOrderOption<T>,
): IContextualMenuItem[] => {
  const sortItems = [
    buildSortOrderItem(
      sortList.onClickSortOrderOption,
      sortList.isAscending,
    ),
  ];

  for (const item of sortList.items) {
    sortItems.push(
      buildSortListItem(
        item.subitems,
        sortList.onClickSortKeyOption,
        sortList.sortKey,
        item.title,
      ),
    );
  }

  return sortItems;
};

const buildSortOrderItem = (
  onClickSortOption: (isAsc: boolean) => void,
  isAscending: boolean,
): IContextualMenuItem => {
  return {
    key: `${intl.get(LocIds.Label.SortOrder)}`,
    itemType: ContextualMenuItemType.Section,
    sectionProps: {
      topDivider: true,
      bottomDivider: true,
      title: `${intl.get(LocIds.Label.SortOrder)}`,
      items: [
        {
          key: `${intl.get(LocIds.Label.Ascending)}`,
          text: `${intl.get(LocIds.Label.Ascending)}`,
          canCheck: true,
          isChecked: isAscending,
          onClick: () => onClickSortOption(true),
        },
        {
          key: `${intl.get(LocIds.Label.Descending)}`,
          text: `${intl.get(LocIds.Label.Descending)}`,
          canCheck: true,
          isChecked: !isAscending,
          onClick: () => onClickSortOption(false),
        },
      ],
    },
  };
};

const buildSortListItem = <T extends SortListSubItem>(
  subitems: T[],
  onClickSortOption: (subitem: T) => void,
  checkedKey: string,
  title?: string,
): IContextualMenuItem => ({
    key: title ? title : `${intl.get(LocIds.Label.SortBy)}`,
    itemType: ContextualMenuItemType.Section,
    sectionProps: {
      topDivider: true,
      bottomDivider: true,
      title: title ? title : `${intl.get(LocIds.Label.SortBy)}`,
      items: subitems.map(subitem => ({
        key: subitem.uniqueKey,
        text: subitem.subitemText,
        canCheck: true,
        isChecked: subitem.uniqueKey === checkedKey,
        onClick: () => onClickSortOption(subitem),
      })),
    },
  });
