import {
  RefObject,
  useState,
  useCallback,
  useRef,
  useLayoutEffect
} from 'react';


import { breakpoints } from '../../../../common/css/breakpoints';
import { IApplicationMenu } from '../../../../models';

interface IUseMenuSplitIndex {
  showMenu: boolean;
  splitIndex: number;
}

export const useMenuSplitIndex = (
  menus: IApplicationMenu[],
  navRef: RefObject<HTMLDivElement>
): IUseMenuSplitIndex => {

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [splitIndex, setSplitIndex] = useState<number>(menus.length);

  const contentWidth = useRef<number>(0);
  const elementsWidths = useRef<number[]>([]);

  const calculateSplitIndex = useCallback((navRef: RefObject<HTMLDivElement>, menus: IApplicationMenu[]) => {
    if (!navRef.current || window.innerWidth < breakpoints.l) {
      return;
    }

    const MORE_BUTTON_WIDTH = 100;

    const navWidth = navRef.current.offsetWidth;

    if (elementsWidths.current.length < navRef.current.children.length) {
      let widthSum = 0;
      elementsWidths.current = [];

      Array.from(navRef.current.children).forEach((element: Element) => {
        const elementWidth = element.getBoundingClientRect().width;
        widthSum += elementWidth;
        elementsWidths.current.push(elementWidth);
      });

      contentWidth.current = widthSum;
    }

    let splitIndex = menus.length;
    let widthDelta = navWidth - contentWidth.current;

    if (widthDelta < 0) {
      widthDelta -= MORE_BUTTON_WIDTH;

      for (let i = elementsWidths.current.length - 1; i >= 0; i--) {
        widthDelta += elementsWidths.current[i];

        if (widthDelta >= 0 || i === 0) {
          splitIndex = i;
          break;
        }
      }
    }

    setShowMenu(true);
    setSplitIndex(splitIndex);
  }, []);

  useLayoutEffect(() => {


    const onResize = () => {
      calculateSplitIndex(navRef, menus);
    };

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [navRef, menus]);


  return {
    showMenu,
    splitIndex
  };
};
