import { AipConfiguration } from './AipConfiguration';
import { AzureAdObject } from './AzureAdObject';
import { DiscussionThread } from './Discussions';
import { Evidence } from './Evidence';
import { IETagged } from './IEtagged';
import { AuditFlags } from './TrustTools';
import { ValueType } from './ValueType';

export interface AuditEventAttachment {
  /** @mockType {random.uuid} */
  AttachmentGuid: string;

  /** @mockType {random.word} */
  Title: string;

  /** @mockType {random.word} */
  FileName: string;

  DownloadUrl: string;

  /** @mockType {date.recent} */
  DateAttached: string;

  RetainedUntil?: string;

  // File size in bytes
  Size: number;

  // Temp attachments have blob guid
  BlobGuid?: string;

  DisplayName?: string;
  DownloadName?: string;
}

export interface AuditEvent {
  /** @mockType {random.uuid} */
  AuditEventGuid: string;

  ComplianceAuditId: number;

  /** @mockType {random.word} */
  Name: string;

  Attachments: AuditEventAttachment[];

  Description: string;

  /** @mockType {date.recent} */
  StartDate: string;

  /** @mockType {date.recent} */
  EndDate: string;

  CreatedDate: string;

  Certifications: AuditEventCertification[];

  Owners: AzureAdObject[];

  Auditors: AzureAdObject[];

  IsAutoPublishEnabled: boolean;

  AuditTeamEmail?: string;

  AuditorCompany?: string;

  ReuseConfigurations: ReuseConfiguration[];

  IsReuseFromAllEnabled: boolean;

  CanBypassPublishScopeCheck: boolean;

  AdoConfiguration: AdoConfiguration | null;

  SocIntros: SocIntro[];

  AipConfiguration: AipConfiguration | null;

  AuditFlags: AuditFlags[];

  IsPublishedEvidenceReusable: boolean;

  IsEvidenceConclusionDisabled: boolean;

  IsMessagingEnabled: boolean;
}

export interface PublishedAuditEvent extends AuditEvent, IETagged {
  DiscussionThreads?: DiscussionThread[];
}

export interface ReuseConfiguration {
  ComplianceAuditId: number;
  // used as UUID in Redux
  AuditEventGuid: string;
  AuditEventName: string;
  Certifications: AuditEventCertification[];
}

export type AdoConfiguration = {
  AreaPath: string;
  IterationPath: string;
  Template: string;
};

// To be consistent with the backend, we use string as AdoConfiguration.Template but use the following enum for easy check.
export enum AdoConfigurationTemplate {
  EvidenceRequest = 'Evidence Request',
  EvidenceRequestSOC = 'Evidence Request SOC v2',
  EvidenceRequestSOCOld = 'Evidence Request SOC',
  Exception = 'Exception',
}

export interface AuditEventCertification {
  Name: string;
  CertificationFamilyId: number;
  Environments: AuditEventEnvironment[];
}

export interface AuditEventEnvironment {
  Name: string;
  EnvironmentId: number;
  Services: AuditEventService[];
}

export interface AuditEventService {
  Name: string;
  Id: number;
  ServiceTreeId: string | null;
}

export enum SocAuditStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export interface SocWorkload {
  SocWorkloadId: number;
  Name: string;
  ResponsiblePartyId: number;
}

export interface SocIntro {
  Id?: number;
  GroupingType: string;
  NeedsBulkCreation: boolean;
  AuditStatus: string | undefined;
  SocWorkload: SocWorkloadDetails;
}

export interface SocWorkloadDetails {
  Id: number;
  Name: string;
  ResponsiblePartyId: number;
  SocServiceDetails: SocService[];
}

export interface SocService {
  Id: number;
  Name: string;
  CorrespondingService: AuditEventService;
}

export interface SocServiceDetailsView {
  SocWorkloadId?: number;
  SocServiceId: number;
  SocServiceName: string;
  CorrespondingServiceId: number;
  CorrespondingServiceName: string;
  ServiceTreeId: string | null;
}

export interface AddUpdateSocService {
  socServiceId?: number
  name: string;
  socWorkloadId: number;
  responsiblePartyId: number;
  responsiblePartyName?: string;
}

export interface UpdateSocService {
  socServiceId: number
  setName?: ValueType<string>;
  setSocWorkloadId?: ValueType<number>;
  setResponsiblePartyId?: ValueType<number>;
}

export interface AddSocService {
  name: string;
  socWorkloadId:number;
  responsiblePartyId: number;
}

export interface DeleteSocService {
  socServiceId: number
}

export interface UpdateSocWorkload {
    socWorkloadId: number;
    setResponsiblePartyId?: ValueType<number>;
    setName?: ValueType<string>;
}

export interface AddSocWorkload {
    name: string;
    responsiblePartyId: number;
}

export interface DeleteSocWorkload {
  socWorkloadId: number;
}

// From projects

export type AuditEventInfo = Pick<ReuseConfiguration, 'AuditEventGuid' | 'AuditEventName'>;

export enum AttachmentState {
  WithAttachments,
  WithoutAttachments,
}

export enum PublishingState {
  NotPublished = 'NotPublished',
  Published = 'Published',
  PublishedButModified = 'PublishedButModified',
}

export enum EvidenceRequestType {
  Original = 'Original',
  Reuse = 'Reuse',
  Draft = 'Draft',
  Published = 'Published',
}

export enum EvidenceRequestSourceType {
  PlatformOnly = 'Platform Only',
  ADOSynced = 'ADO Synced',
}

export interface EvidenceGroup {
  id: number;
  evidenceGuid: string;
  [EvidenceRequestType.Original]?: Evidence;
  [EvidenceRequestType.Draft]?: Evidence;
  [EvidenceRequestType.Published]?: Evidence;
  [EvidenceRequestType.Reuse]?: Evidence;
}
