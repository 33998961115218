import React, { useState } from 'react';

import {
  IButtonProps, IContextualMenuProps, Stack, Text
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import _, { isEmpty } from 'lodash';
import intl from 'react-intl-universal';

import { PersonPicker } from './PersonPicker';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  AzureAdMember, EvidenceRequestAttachment, OriginalEvidence
} from '../../models';
import { AuditEventApi } from '../../services';
import {
  EvidenceAttachments, EvidenceAttachmentsEditor
} from '../AttachmentGroup';
import { BaseEvidenceCard } from '../BaseEvidenceCard';
import {
  CardActionBarProps, CardTagProps
} from '../CardView';
import { Dialog } from '../Dialog';
import { TooltipIconButton } from '../TooltipIconButton';

interface IProps {
  evidence: OriginalEvidence;
  actionProps?: IButtonProps[];
  menuProps?: IContextualMenuProps;
  onSaveAssignee?: (alias?: AzureAdMember) => void;
  attachments?: EvidenceRequestAttachment[];
  onChangeAttachments?: (value: EvidenceRequestAttachment[]) => void;
  isAssigneeHighlighted?: boolean;
}

export const TaskCard: React.FC<IProps> = (props) => {
  const { evidence, actionProps, menuProps, onSaveAssignee, attachments = [], onChangeAttachments, isAssigneeHighlighted = false } = props;

  const [assignedTo, setAssignedTo] = useState<AzureAdMember>();

  const [editingAssignee, { toggle: toggleEditingAssignee }] = useBoolean(false);

  const editAssigneeIcon = (
    <TooltipIconButton
      className='cardView__tagValue'
      iconProps={{
        iconName: 'EditContact',
      }}
      text={intl.get(LocIds.ServiceWorkspace.ChangeAssignee)}
      onClick={toggleEditingAssignee}
    />
  );

  const assignedToJsx = (
    <Stack horizontal>
      <Stack className={classNames({
        'taskCard__assignee--highlighted': isAssigneeHighlighted
      })}
      verticalAlign='center'>
        { evidence.AssignedTo }
      </Stack>
      { !_.isNil(onSaveAssignee) && editAssigneeIcon }
    </Stack>
  );

  const tagProps: CardTagProps = {
    items: [
      {
        label: intl.get(LocIds.Label.AssignedTo),
        value: assignedToJsx,
      },
      {
        label: intl.get(LocIds.Label.DueBy),
        value: new Date(evidence.DueDate).toLocaleDateString(),
      },
      {
        label: intl.get(LocIds.Label.CreatedBy),
        value: evidence.CreatedBy,
      },
      {
        label: intl.get(LocIds.Label.CreatedOn),
        value: new Date(evidence.CreatedDate).toLocaleDateString(),
      },
    ],
  };

  const onRemoveAttachment = (attachment: EvidenceRequestAttachment) => {
    onChangeAttachments?.(
      attachments.filter(
        (a) =>
          (isEmpty(a.BlobGuid) || a.BlobGuid !== attachment.BlobGuid) &&
          (isEmpty(a.AttachmentGuid) || a.AttachmentGuid !== attachment.AttachmentGuid),
      ),
    );
  };

  const onRenderFileSection = () =>
    onChangeAttachments ? (
      <EvidenceAttachmentsEditor
        attachments={attachments ?? []}
        onRemove={onRemoveAttachment}
        onUpload={(attachment) => onChangeAttachments([...attachments, attachment])}
      />
    ) : (
      <EvidenceAttachments Attachments={evidence.Attachments} />
    );

  const actionBarProps: CardActionBarProps = {
    buttonGroupProps: actionProps ? {
      items: actionProps,
    } : undefined,
    menuProps,
  };

  const typeLabel = (
    <Text className={`taskCard__stateLabel--${evidence.State.toLowerCase()}`}>
      { evidence.State }
    </Text>
  );

  const cardJsx = (
    <BaseEvidenceCard
      actionBarProps={actionBarProps}
      adoUrl={evidence.ViewUrl}
      className={`taskCard--${evidence.State.toLowerCase()}`}
      displayId
      evidence={evidence}
      getAuditEventDetailsApi={AuditEventApi.getPublicComplianceAuditInfoByGuidAsync}
      tagProps={tagProps}
      titleLabel={typeLabel}
      onRenderLeftBody={onRenderFileSection}
    />
  );

  const onSave = () => {
    onSaveAssignee?.(assignedTo);
    toggleEditingAssignee();
  };

  const confirmationDialogJsx = (
    <Dialog
      hidden={!editingAssignee}
      primaryButtonProps={{
        text: intl.get(LocIds.Action.Update),
        onClick: onSave,
        disabled: _.isNil(assignedTo) || _.isEqual(assignedTo.Alias, evidence.AssignedTo),
      }}
      subText={intl.get(LocIds.ServiceWorkspace.ChangeAssigneeDescription)}
      title={intl.get(LocIds.ServiceWorkspace.ChangeAssignee)}
      onDismiss={toggleEditingAssignee}>
      <PersonPicker
        label={intl.get(LocIds.ServiceWorkspace.NewAssignee)}
        selected={assignedTo}
        onChange={setAssignedTo}
      />
    </Dialog>
  );

  return (
    <>
      { cardJsx }
      { confirmationDialogJsx }
    </>
  );
};
