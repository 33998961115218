import React from 'react';

import {
  DetailsList,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Stack,
  Text
} from '@fluentui/react';

import { isEmpty } from 'lodash';
import intl from 'react-intl-universal';

import { getPullRequestColumns } from './PullRequestTableColumns';
import { pullRequestTableStyle } from './PullRequestTableStyles';

import { LocIds } from '../../../../../common/Globalization/IntlEnum';

import {
  CompliantStatus,
  PullRequestTrace
} from '../../../../../models';
import { ComplianceStatusBadge } from '../../../components/ComplianceStatusBadge';

import {
  gatingDetailsTableHeading,
  tableStackTokens,
} from '../styles';

interface PullRequestTableProps {
  PullRequestTraces: PullRequestTrace[],
  PullRequestCompliantStatus: CompliantStatus
}

export const PullRequestTable: React.FC<PullRequestTableProps> = (props) => {
  const nonCompliantPullRequests = props.PullRequestTraces.filter(
    (pullRequestTrace) => {
      return pullRequestTrace.Status === CompliantStatus.NotCompliant;
    }
  );

  const notCompliantGatingRecord: boolean = nonCompliantPullRequests?.length > 0;
  const relatedPullRequestsNotFound: boolean = isEmpty(props.PullRequestTraces);

  const tableHeading: JSX.Element = (
    <div style={gatingDetailsTableHeading}>
      <Stack
        horizontal
        horizontalAlign='start'
        tokens={tableStackTokens}
        verticalAlign='center'>
        <Text variant={'xLarge'}>
          { intl.get(LocIds.Gating.PullRequests) }
        </Text>
        <ComplianceStatusBadge complianceStatus={props.PullRequestCompliantStatus} />
      </Stack>
    </div>
  );

  const pullRequestTable: JSX.Element = (
    <div style={pullRequestTableStyle}>
      <DetailsList
        columns={getPullRequestColumns()}
        items={nonCompliantPullRequests}
        selectionMode={SelectionMode.none}
      />
    </div>
  );

  const relatedPullRequestsNotFoundMessage: JSX.Element = (
    <MessageBar messageBarType={MessageBarType.error}>
      { intl.get(LocIds.Gating.RelatedPRsNotFound) }
    </MessageBar>
  );

  const compliantGatingRecordMessage: JSX.Element = (
    <MessageBar messageBarType={MessageBarType.success}>
      { intl.get(LocIds.Gating.AllPRsCompliant) }
    </MessageBar>
  );


  return (
    <>
      { tableHeading }
      { relatedPullRequestsNotFound && relatedPullRequestsNotFoundMessage }
      { !relatedPullRequestsNotFound && !notCompliantGatingRecord && compliantGatingRecordMessage }
      { notCompliantGatingRecord && pullRequestTable }
    </>
  );
};
