import './styles.less';

import React from 'react';

import { FontIcon } from '@fluentui/react';
import classNames from 'classnames';

import {
  StatusIconProps,
  StatusType
} from './types';

import { ToolTip } from '../ToolTip';

export const StatusIcon: React.FC<StatusIconProps> = (props) => {
  const { type, size = 'normal', tooltipContent } = props;

  let iconElement;
  switch (type) {
  case StatusType.Info:
    iconElement = (
      <FontIcon className={classNames('info', size)} iconName='Info' />
    );
    break;
  case StatusType.Warning:
    iconElement = (
      <FontIcon className={classNames('warning', size)} iconName='Warning' />
    );
    break;
  case StatusType.Error:
    iconElement = (
      <FontIcon className={classNames('error', size)} iconName='Error' />
    );
    break;
  case StatusType.ErrorBadge:
    iconElement = (
      <FontIcon className={classNames('errorBadge', size)} iconName='ErrorBadge' />
    );
    break;
  case StatusType.Completed:
    iconElement = (
      <FontIcon
        className={classNames('completed', size)}
        iconName='CompletedSolid'
      />
    );
    break;
  case StatusType.Revoked:
    iconElement = (
      <FontIcon
        className={classNames('revoked', size)}
        iconName='StatusErrorFull'
      />
    );
    break;
  case StatusType.Pending:
    iconElement = (
      <FontIcon
        className={classNames('pending', size)}
        iconName='Clock'
      />
    );
    break;
  default:
    iconElement = null;
  }

  return tooltipContent ? (
    <ToolTip content={tooltipContent}>{ iconElement }</ToolTip>
  ) : (
    iconElement
  );
};
