import React from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';

import { CommonFilterProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { PublishingState } from '../../models';
import { MultiSelectDropdown } from '../Dropdown';
import { FilterFieldWrapper } from '../FilterFieldWrapper';

export const PublishingStateFilter: React.FC<
  CommonFilterProps<PublishingState[], PublishingState[]>
> = (props) => {
  const { allOptions, selected, onChange } = props;

  return (
    <FilterFieldWrapper
      ariaLabel={intl.get(LocIds.Evidence.PublishingState)}
      isClearActive={!_.isEmpty(selected)}
      onClear={() => onChange([])}>
      <MultiSelectDropdown<PublishingState>
        items={allOptions ?? []}
        keyOf={(item) => String(item)}
        label={intl.get(LocIds.Evidence.PublishingState)}
        optionOf={(item) => ({
          key: String(item),
          text: getPublishingStateText(item),
        })}
        placeholder={intl.get(LocIds.Placeholder.SelectToFilter)}
        selectedItems={selected}
        onChange={onChange}
      />
    </FilterFieldWrapper>
  );
};

export const getPublishingStateText = (option: PublishingState): string => {
  const map: Record<PublishingState, string> = {
    [PublishingState.NotPublished]: intl.get(LocIds.Evidence.NotPublished),
    [PublishingState.Published]: intl.get(LocIds.Evidence.Published),
    [PublishingState.PublishedButModified]: intl.get(LocIds.Evidence.PublishedButModified)
  };

  return map[option];
};
