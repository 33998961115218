import React from 'react';

import {
  IPanelProps,
  IPanelStyleProps,
  IPanelStyles,
  IRenderFunction,
  IStyleFunctionOrObject,
  Panel,
  PanelType,
  Stack,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { DefaultButton } from '@fluentui/react/lib/Button';
import classNames from 'classnames';
import { isArray } from 'lodash';
import intl from 'react-intl-universal';

import { TrustPanelMessage } from './TrustPanelMessage';
import { TrustPanelProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { FCC } from '../../models';
import { Dialog } from '../Dialog';
import { DocumentationCommandBar } from '../DocumentationCommandBar';
import { M365PrimaryButton } from '../M365Button';
import './TrustPanel.less';

export const TrustPanel: FCC<TrustPanelProps> = (props) => {
  const {
    focusTrapZoneProps,
    primaryActionProps,
    headerText,
    isOpen,
    isHiddenOnDismiss = false,
    type = PanelType.medium,
    onRenderFooterContent,
    hasCloseButton,
    closeLabel = intl.get(LocIds.Action.Cancel),
    preventClose = false,
    showSpinnerInPrimaryButton = false,
    showProcessingMessage = false,
    processingMessage = intl.get(LocIds.Action.StartProcessingRequest),
    showErrorMessage = false,
    errorMessage = intl.get(LocIds.Error.ContactOncall),
    showErrorDismiss,
    helperDocumentLink
  } = props;

  const [isPromptCloseVisible, { setTrue: showDialog, setFalse: hideDialog }] = useBoolean(false);

  const onTryDismiss = () => {
    if (preventClose) {
      showDialog();
    } else {
      props.onDismiss();
    }
  };

  const onDismissConfirmed = () => {
    props.onDismiss();
    hideDialog();
  };

  const preventCloseDialog = preventClose && (
    <Dialog
      hidden={!isPromptCloseVisible}
      primaryButtonProps={{
        text: intl.get(LocIds.Action.Discard),
        onClick: onDismissConfirmed
      }}
      subText={intl.get(LocIds.Components.DiscardChangesDescription)}
      title={intl.get(LocIds.Components.DiscardChangesTitle)}
      onDismiss={hideDialog}
    />
  );

  const defaultOnRenderFooterContent: IRenderFunction<IPanelProps> = (props, defaultRender) => (
    <>
      <Stack horizontal
        tokens={{
          childrenGap: 16
        }}>
        { primaryActionProps && (isArray(primaryActionProps) ?
          primaryActionProps.map((action, index) =>
            <M365PrimaryButton loading={showSpinnerInPrimaryButton} {...action} key={action.text}/>) :
          <M365PrimaryButton loading={showSpinnerInPrimaryButton} {...primaryActionProps} />) }
        <DefaultButton text={closeLabel}
          onClick={onTryDismiss}
        />
      </Stack>
      { defaultRender && defaultRender(props) }
    </>
  );

  const className = classNames('tp-panel', props.className);
  const headerClassName = classNames('tp-panelHeader', props.headerClassName);

  const panelStyles: IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles> = {
    commands: {
      height: hasCloseButton === false ? 0 : 48,
    },
    header: {
      paddingLeft: 24,
      paddingRight: 36,
      paddingTop: 5,
      display: 'inline-block'
    },
    scrollableContent: {
      marginTop: 24,
      paddingTop: 4,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      boxSizing: 'border-box',
      overflow: 'scroll',
    },
    contentInner: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    main: {
      overflow: 'hidden',
    },
  };

  const showMessageBar = (
    <TrustPanelMessage errorMessage={errorMessage}
      processingMessage={processingMessage}
      showErrorDismiss={showErrorDismiss}
      showErrorMessage={showErrorMessage}
      showProcessingMessage={showProcessingMessage}
    />
  );

  const helperDocumentJsx = helperDocumentLink && (
    <div className='documentLink'>
      <DocumentationCommandBar helpUrl={helperDocumentLink}/>
    </div>
  );

  // TODO: currently we need to remove the close button for helper doc link, need better design
  const onRenderCustomHeader = (
    props?: IPanelProps,
    defaultRender?: IRenderFunction<IPanelProps>
  ) => {
    return (
      <div>
        { typeof defaultRender === 'function' && defaultRender(props) }
        { helperDocumentJsx }
      </div>
    );
  };

  return (
    <Panel
      className={className}
      closeButtonAriaLabel={intl.get(LocIds.Label.Close)}
      focusTrapZoneProps={focusTrapZoneProps}
      hasCloseButton={hasCloseButton}
      headerClassName={headerClassName}
      headerText={headerText}
      isFooterAtBottom
      isHiddenOnDismiss={isHiddenOnDismiss}
      isLightDismiss
      isOpen={isOpen}
      styles={panelStyles}
      type={type}
      onDismiss={onTryDismiss}
      onRenderFooterContent={onRenderFooterContent || defaultOnRenderFooterContent}
      onRenderHeader={helperDocumentJsx ? onRenderCustomHeader : undefined}>
      { showMessageBar }
      { props.children }
      { preventCloseDialog }
    </Panel>
  );
};
