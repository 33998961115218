import React from 'react';

import { IconButton } from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { SettingsPanel } from '../../../../components/SettingsPanel';
import { useAppDispatch } from '../../../../redux/reducer';
import { openSettingsPanel } from '../../../../redux/settingsPanelSlice/slice';
export const SettingsButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const settingsIcon = (
    <IconButton
      ariaLabel={intl.get(LocIds.Label.Settings)}
      iconProps={{
        iconName: 'settings'
      }}
      name={intl.get(LocIds.Label.Settings)}
      title={intl.get(LocIds.Label.Settings)}
      onClick={() => dispatch(openSettingsPanel())}
    />
  );
  return (
    <>
      { settingsIcon }
      <SettingsPanel />
    </>
  );
};
