import _ from 'lodash';

import { getEvidenceId } from './evidenceId';

import { Evidence } from '../models/Evidence';
import { EvidencePath } from '../models/EvidencePath';
import { AuditEventNode } from '../models/EvidenceTreeNode/AuditEventNode';
import { CertificationNode } from '../models/EvidenceTreeNode/CertificationNode';
import { EvidenceNode } from '../models/EvidenceTreeNode/EvidenceNode';
import { EvidenceTreeNodeBase } from '../models/EvidenceTreeNode/EvidenceTreeNodeBase';
import { EvidenceTreeRootNode } from '../models/EvidenceTreeNode/EvidenceTreeRootNode';
import { TeamNode } from '../models/EvidenceTreeNode/TeamNode';


/**
 * This function will get all values of certain meta data from a list of Evidences
 * e.g. get all team names from Evidence.Teams
 *
 * @param evidences A list of Evidence
 * @param metaData meta data of Evidence that is of type array
 */
export const getAllEvidenceMetadata = (
  evidences: Evidence[],
  metaData: 'CertificationControl' | 'Teams',
): string[] => {
  return evidences.reduce((accumulator: string[], currentValue: Evidence) => {
    return _.uniq(
      _.concat(
        accumulator,
        Array.isArray(currentValue[metaData]) ? currentValue[metaData] : [],
      ),
    );
  }, []);
};

export const groupEvidenceByMetadata = (
  evidences: Evidence[],
  metaData: 'CertificationControl' | 'Teams',
): Record<string, Evidence[]> => {
  const metaDataArr = getAllEvidenceMetadata(evidences, metaData);
  const group = metaDataArr.reduce(
    (acc: { [key: string]: Evidence[] }, cur: string) => {
      acc[cur] = evidences.filter((v) => v[metaData].includes(cur));
      return acc;
    },
    {},
  );
  return group;
};

/**
 * Found cases in test where some field of first evidence is null returned from backend,
 * search entire evidence list to find a not nullish value
 */
export const getMetaDataOfEvidence = <T extends keyof Evidence>(
  evidences: Evidence[],
  metaData: T,
): Evidence[T] => {
  for (let i = 0; i < evidences.length; i++) {
    if (!_.isNil(evidences[i][metaData])) {
      return evidences[i][metaData];
    }
  }

  // To make ts happy
  return evidences[0][metaData];
};

/**
 * Get path from root to current node
 */
export const getEvidencePathFromEvidenceTreeNode = (
  node: EvidenceTreeNodeBase,
): EvidencePath => {
  return node.pathToCurrentNode;
};

export const createAuditEventNodes = (
  evidences: Evidence[],
): AuditEventNode[] => {
  const auditNodes: AuditEventNode[] = [];
  const evidencesDicGroupByAuditEventGuid = _.groupBy(
    evidences,
    'AuditEventGuid',
  );

  _.forOwn(
    evidencesDicGroupByAuditEventGuid,
    (evidencesOfSameAuditEvent: Evidence[], AuditEventGuid: string) => {
      const teamNodes: TeamNode[] = createTeamNodes(evidencesOfSameAuditEvent);
      auditNodes.push(
        new AuditEventNode({
          AuditEventGuid,
          name: getMetaDataOfEvidence(
            evidencesOfSameAuditEvent,
            'AuditEventTitle',
          ),
          children: teamNodes,
        }),
      );
    },
  );
  return _.sortBy(auditNodes, 'name');
};

export const createTeamNodes = (evidences: Evidence[]): TeamNode[] => {
  const teamNodes: TeamNode[] = [];
  const evidencesDicGroupByTeamName = groupEvidenceByMetadata(
    evidences,
    'Teams',
  );
  _.forOwn(
    evidencesDicGroupByTeamName,
    (evidencesOfSameTeam: Evidence[], TeamName: string) => {
      const certificationNodes: CertificationNode[] =
        createCertificationNodes(evidencesOfSameTeam);
      teamNodes.push(
        new TeamNode({
          name: TeamName,
          children:
            certificationNodes.length > 0 ?
              certificationNodes :
              createEvidenceNodes(evidencesOfSameTeam),
        }),
      );
    },
  );
  return _.sortBy(teamNodes, 'name');
};

export const createCertificationNodes = (
  evidences: Evidence[],
): CertificationNode[] => {
  const certificationNodes: CertificationNode[] = [];
  const evidencesDicGroupByCertControl = groupEvidenceByMetadata(
    evidences,
    'CertificationControl',
  );
  _.forOwn(
    evidencesDicGroupByCertControl,
    (evidencesOfSameCertControl: Evidence[], certificationControl: string) => {
      certificationNodes.push(
        new CertificationNode({
          name: certificationControl,
          children: createEvidenceNodes(evidencesOfSameCertControl),
        }),
      );
    },
  );
  return _.sortBy(certificationNodes, 'name');
};

export const createEvidenceNodes = (evidences: Evidence[]): EvidenceNode[] => {
  return evidences
    .sort((a, b) => {
      return a.Title.localeCompare(b.Title);
    })
    .map((v: Evidence) => new EvidenceNode(getEvidenceId(v)));
};

/**
 *
 * @param evidences list of Evidence
 * create an EvidenceTree from a list of Evidence
 */
export const createEvidenceTree = (
  evidences: Evidence[],
): EvidenceTreeRootNode => {
  const auditNodes: AuditEventNode[] = createAuditEventNodes(evidences);
  const evidenceTree = new EvidenceTreeRootNode(auditNodes);
  return evidenceTree;
};
