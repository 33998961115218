import React, { ReactElement } from 'react';

import {
  IStackStyles,
  ITextProps,
  Stack,
  Text
} from '@fluentui/react';
import _ from 'lodash';

export interface MetadataListItemProps {
    label: string;
    value?: string | number;

    /**
     * Custom props when value is rendered as <Text/> component.
     */
    valueProps?: ITextProps;

    styles?: IStackStyles;

    /**
     * Whether to put label and value horizontally, default to true
     */
    horizontal?: boolean;

    /**
     * Gap between label and value, default to 5
     */
    gap?: number;

    /**
     * If onRenderValue is provided, value of MetadataListItem will be rendered use onRenderText method.
     * */
    onRenderValue?: (item: MetadataListItemProps) => ReactElement;
    onClick?: (item: MetadataListItemProps) => void;
}

export const MetadataListItem: React.FC<MetadataListItemProps> = (props: MetadataListItemProps) => {
  const {
    styles = {},
    onRenderValue,
    value,
    label,
    horizontal = true,
    gap = 5,
    valueProps = {}
  } = props;

  const valueJsx = _.isFunction(onRenderValue) ? onRenderValue(props) : (
    <Text
      block
      styles={{
        root: {
          whiteSpace: 'break-spaces'
        }
      }}
      variant='small'
      {...valueProps}>
      { value }
    </Text>
  );

  return (
    <Stack horizontal={horizontal}
      styles={styles}
      tokens={{
        childrenGap: gap
      }}>
      <Text style={{
        color: 'var(--gray100)',
      }}
      variant='small'>
        { `${label}:` }
      </Text>
      { valueJsx }
    </Stack>
  );
};
