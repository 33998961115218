import React, {
  ReactNode,
  useCallback,
  useState
} from 'react';

import {
  IButtonStyles,
  IconButton,
  IIconProps,
  TooltipHost
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

interface IProps {
    text: ReactNode;
}

export const CopyButton: React.FC<IProps> = ({ text }: IProps) => {

  const copyIconProps: IIconProps = {
    iconName: 'Copy',
    styles: {
      root: {
        color: 'var(--gray160)'
      }
    }
  };

  const buttonStyles: IButtonStyles = {
    root: {
      width: '16px',
      height: '16px'
    }
  };

  const [tooltipText, setTooltipText] = useState(intl.get(LocIds.Action.CopyToClipboard));

  const onCopy = useCallback(() => {
    if (typeof text === 'string') {
      navigator.clipboard.writeText(text)
        .then(() => {
          setTooltipText(intl.get(LocIds.Label.Copied));
          setTimeout(() => {
            setTooltipText(intl.get(LocIds.Action.CopyToClipboard));
          }, 1000);
        });
    }
  }, [text]);

  return (
    <TooltipHost content={tooltipText}>
      <IconButton ariaLabel={tooltipText}
        iconProps={copyIconProps}
        styles={buttonStyles}
        onClick={onCopy}
      />
    </TooltipHost>
  );
};
