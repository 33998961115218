/* eslint-disable i18next/no-literal-string */
import {
  EvidenceTreeInternalNode,
  EvidenceTreeLeafNode,
} from './EvidenceTreeNodeBase';

import { EvidenceTreeNodeVisitor } from '../EvidenceTreeVisitor/EvidenceTreeNodeVisitor';

/**
 * Certification node of EvidenceTree
 */
export class CertificationNode extends EvidenceTreeInternalNode {
  constructor({
    name,
    children,
  }: {
    name: string;
    children: (EvidenceTreeLeafNode | EvidenceTreeInternalNode)[];
  }) {
    super();
    this.name = name;
    this.children = children;
    this.identifierName = 'CertificationControl';
    this.identifierValue = name;
  }

  public accept(visitor: EvidenceTreeNodeVisitor): void {
    visitor.visitCertificationNode(this);
  }
}
