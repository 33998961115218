import React from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';

import { CommonFilterProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { SingleSelectDropdown } from '../Dropdown';
import { FilterFieldWrapper } from '../FilterFieldWrapper';

interface IProps<T>
  extends CommonFilterProps<T[], T | undefined> {
  label: string;
  getOptionText: (item: T) => string;
}

export function DateOptionFilter<T>(
  props: IProps<T>
): ReturnType<React.FC<CommonFilterProps<T[], T | undefined>>> {
  const { allOptions, selected, onChange, label, getOptionText } = props;

  return (
    <FilterFieldWrapper
      ariaLabel={label}
      isClearActive={!_.isNil(selected)}
      onClear={() => onChange(undefined)}>
      <SingleSelectDropdown<T>
        items={allOptions ?? []}
        keyOf={(item) => String(item)}
        label={label}
        optionOf={(item) => ({
          key: String(item),
          text: getOptionText(item),
        })}
        placeholder={intl.get(LocIds.Placeholder.SelectToFilter)}
        selectedItem={selected}
        onChange={onChange}
      />
    </FilterFieldWrapper>
  );
}
