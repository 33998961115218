import React, { useMemo } from 'react';

import './styles.less';
import {
  CompliantStatus,
  StatusMessage
} from '../../../../models/Gating';

enum StatusClass {
  Compliant = 'compliant',
  NotCompliant = 'notcompliant',
  NotDefined= 'undefined',
  MissingData = 'missdata',
}

interface ComplianceStatusBadgeProps {
  complianceStatus: CompliantStatus,
  inline?: boolean
}

interface ComplianceStatusMessage {
  statusClass: string,
  message: string
}

export const ComplianceStatusBadge: React.FC<ComplianceStatusBadgeProps> = (props:ComplianceStatusBadgeProps) => {
  const complianceStatusMessage = useMemo<ComplianceStatusMessage>(() => {
    switch (props.complianceStatus) {
    case CompliantStatus.Compliant:
      return {
        statusClass: StatusClass.Compliant,
        message: StatusMessage.Compliant
      };
    case CompliantStatus.CompliantWithRemediation:
      return {
        statusClass: StatusClass.Compliant,
        message: StatusMessage.CompliantWithRemediation
      };
    case CompliantStatus.DBNotAvailable:
      return {
        statusClass: StatusClass.NotCompliant,
        message: StatusMessage.DBNotAvailable
      };
    case CompliantStatus.MissingData:
      return {
        statusClass: StatusClass.MissingData,
        message: StatusMessage.MissingData
      };
    case CompliantStatus.NotCompliant:
      return {
        statusClass: StatusClass.NotCompliant,
        message: StatusMessage.NotCompliant
      };
    case CompliantStatus.NotDefined:
    default:
      return {
        statusClass: StatusClass.NotDefined,
        message: StatusMessage.MissingData
      };
    }
  }, [props.complianceStatus]);

  return (
    <span className={`none-selection bolt-pill ${complianceStatusMessage.statusClass} ${props.inline ? 'inlined' : 'outlined'}`}>
      { complianceStatusMessage.message }
    </span>
  );
};

export default ComplianceStatusBadge;
