import { mergeStyleSets } from '@fluentui/react';
import { ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';

export const tooltipHostStyles: Partial<ITooltipHostStyles> = {
  root: {
    display: 'inline-block'
  }
};

export const classNames = mergeStyleSets({
  tooltipContainer: {
    // eslint-disable-next-line i18next/no-literal-string
    boxShadow: '0 0 5px gray'
  }
});
