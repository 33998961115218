import { AxiosError } from 'axios';
import * as _ from 'lodash';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { notify } from './notify';

export const defaultErrorMsg =
  'Something went wrong. Please try again later or contact trusttoolsoncall@microsoft.com for support';

export const showErrorMsg = async (
  error: Error | Response | unknown,
  dispatch?: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<void> => {
  const errorMsg = await getErrorMsg(error);

  if (!_.isEmpty(errorMsg)) {
    notify.error(errorMsg);
  } else {
    notify.error(defaultErrorMsg);
  }
};

export const getErrorMsg = async (
  error: Error | Response | unknown,
): Promise<string> => {
  let message;

  try {
    if (error instanceof Error && error.message) {
      message = error.message;

      if (error instanceof AxiosError) {
        const json = error.response?.data;

        if (json?.error?.message) {
          message = json?.error?.message;
        }
      }
    } else if (error instanceof Response) {
      const body = await error.json();

      if (body?.error?.message) {
        message = body?.error?.message;
      } else {
        message = String(body);
      }
    } else {
      message = String(error);
    }
  } catch (_) {
    message = defaultErrorMsg;
  }

  return message;
};
