import React, { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import NotFound from '../../TrustPortal/pages/NotFound';
import {
  Application,
  AuditManager
} from '../../TrustPortal/registerApplicationMenu';

const Home = lazy(() => import('../pages/Home'));
const AuditEvent = lazy(() => import('../pages/AuditEventPage'));
const EvidenceRequests = lazy(() => import('../pages/EvidenceRequests'));

export const routes: RouteObject[] = [
  {
    path: `${Application.AuditManager}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${AuditManager.AuditEvents}/*`,
        element: <AuditEvent />,
      },
      {
        path: `menu/${AuditManager.EvidenceRequests}/*`,
        element: <EvidenceRequests />,
      },
      {
        path: '*',
        element: <NotFound />,
      }
    ],
  },
];
