import { createAsyncThunk } from '@reduxjs/toolkit';

import { IServices } from '../../services';
import { getErrorMsg } from '../../utils';

export const signIn = createAsyncThunk<void>(
  'trust-portal/signIn',
  async (_, thunkAPI): Promise<void> => {
    const services = thunkAPI.extra as IServices;

    try {
      await services.oAuthService.signIn();
    } catch (e) {
      // Dispatch a sign in error action to prevent always trying to sign in when an error occurred
      // TODO: retry sign in several times before finally fail
      services.applicationInsightsService.trackTrace(
        `Failed to sign in for reason: ${await getErrorMsg(e)}`,
      );
    }
  },
);

export const signOut = createAsyncThunk<void>(
  'trust-portal/signOut',
  async (_, thunkAPI): Promise<void> => {
    const services = thunkAPI.extra as IServices;
    await services.oAuthService.signOut();
  },
);
