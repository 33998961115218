import { useSelector } from 'react-redux';

import { portalConfigurationService } from '../../services';
import { RootReduxState } from '../reducer';


export const useSettingsPanelSelector = () => {
  return useSelector<RootReduxState, boolean>((store: RootReduxState) =>
    store.portal.settingsPanel.openPanel);
};

export const useActivateCoachmarksSettingSelector = () => {
  return useSelector<RootReduxState, boolean>((store: RootReduxState) =>
    store.portal.settingsPanel.activateCoachmarks && portalConfigurationService.getPortalFlightings().enableCoachmarks);
};
