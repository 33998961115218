import React from 'react';

import { CommandButton } from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  EvidenceProposalState, ProposedEvidence
} from '../../models';
import { ProposedEvidenceApi } from '../../services';
import {
  notify, UseAsyncHookStatus
} from '../../utils';

type IProps = {
  proposedEvidence: ProposedEvidence;
  onSuccess: (evidence: ProposedEvidence, state: EvidenceProposalState) => void;
  setUpdatingStatus: (value: UseAsyncHookStatus) => void;
}

export const ProposalApprovalActionGroup: React.FC<IProps> = (props) => {
  const { proposedEvidence, onSuccess, setUpdatingStatus } = props;

  const onUpdate = (state: EvidenceProposalState) => {
    if (proposedEvidence) {
      notify.info(intl.get(LocIds.Action.StartProcessingRequest));
      setUpdatingStatus(UseAsyncHookStatus.Pending);

      ProposedEvidenceApi.updateProposedEvidenceAsync({
        auditEventGuid: proposedEvidence.AuditEventGuid,
        proposedEvidenceGuid: proposedEvidence.ProposedEvidenceGuid,
        versionGuid: proposedEvidence.VersionGuid,
        state,
      })
        .then(() => {
          notify.success(intl.get(LocIds.Evidence.ProposedEvidenceUpdated));
          setUpdatingStatus(UseAsyncHookStatus.Success);

          onSuccess(proposedEvidence, state);
        })
        .catch(() => {
          notify.error(intl.get(LocIds.Error.SomethingWentWrong));
          setUpdatingStatus(UseAsyncHookStatus.Fail);
        });
    }
  };

  return (
    <CommandButton
      iconProps={{
        iconName: 'CheckMark'
      }}
      menuProps={{
        items: [
          {
            key: 'approve',
            iconProps: {
              iconName: 'CheckMark'
            },
            text: intl.get(LocIds.Action.Approve),
            onClick: () => onUpdate(EvidenceProposalState.Accepted),
            disabled: proposedEvidence.State === EvidenceProposalState.Accepted,
          },
          {
            key: 'reject',
            iconProps: {
              iconName: 'Cancel'
            },
            text: intl.get(LocIds.Action.Reject),
            onClick: () => onUpdate(EvidenceProposalState.Rejected),
            disabled: proposedEvidence.State === EvidenceProposalState.Rejected,
          }
        ]
      }}
      text={intl.get(LocIds.Action.Approve)}
    />
  );
};
