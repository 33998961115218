import './Home.less';

import React, {
  ReactNode, Suspense, useCallback, useEffect
} from 'react';

import { Spinner } from '@fluentui/react/lib/Spinner';
import intl from 'react-intl-universal';
import { Outlet } from 'react-router-dom';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import {
  NoPermission, NotificationBar
} from '../../../../components';
import { getPortalConfig } from '../../../../redux/configSlice/thunk';
import { useAppDispatch } from '../../../../redux/reducer';
import { useTitle } from '../../../../utils';
import { ApplicationHeader } from '../../components/ApplicationHeader';
import {
  usePortalApplications, useCheckMyNetworkStatus
} from '../../hooks';

export const Home: React.FC = () => {
  useTitle(intl.get(LocIds.Title.TrustPortal));

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getPortalConfig());
  }, [dispatch]);

  useCheckMyNetworkStatus();

  const { applications, applicationsLoaded, currentApplication, noPermissionMessage, noPermissionSubMessage } = usePortalApplications();

  const getContent = useCallback((): ReactNode => {
    if (!applicationsLoaded) {
      return (
        <Spinner className='rootSpinner' />
      );
    }

    if (!currentApplication) {
      return (
        <NoPermission headingStyle={{
          color: 'var(--red10)'
        }}
        message={noPermissionMessage}
        subheading={noPermissionSubMessage}
        />
      );
    }

    return (
      <Suspense fallback={<Spinner className='rootSpinner' />}>
        <Outlet/>
      </Suspense>
    );
  }, [
    applicationsLoaded,
    currentApplication,
    noPermissionMessage,
    noPermissionSubMessage
  ]);

  return (
    <>
      <ApplicationHeader applications={applications} />

      <div className='tp-portal__content'>
        { getContent () }
      </div>

      <NotificationBar/>
    </>
  );
};
