import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  CreatedDateOption, DueDateOption
} from '../../models/FilterTypes';


export const getDueDateOptionText = (option: DueDateOption): string => {
  switch (option) {
  case DueDateOption.Overdue12Months: return intl.get(LocIds.Time.Overdue12Months);
  case DueDateOption.Overdue6Months: return intl.get(LocIds.Time.Overdue6Months);
  case DueDateOption.Overdue3Months: return intl.get(LocIds.Time.Overdue3Months);
  case DueDateOption.Overdue30Days: return intl.get(LocIds.Time.Overdue30Days);
  case DueDateOption.Upcoming30Days: return intl.get(LocIds.Time.Upcoming30Days);
  case DueDateOption.Upcoming3Months: return intl.get(LocIds.Time.Upcoming3Months);
  case DueDateOption.Upcoming6Months: return intl.get(LocIds.Time.Upcoming6Months);
  case DueDateOption.Upcoming12Months: return intl.get(LocIds.Time.Upcoming12Months);
  default: return '';
  }
};

export const getCreatedDateOptionText = (option: CreatedDateOption): string => {
  switch (option) {
  case CreatedDateOption.Last30Days: return intl.get(LocIds.Time.Last30Days);
  case CreatedDateOption.Last3Months: return intl.get(LocIds.Time.Last3Months);
  case CreatedDateOption.Last6Months: return intl.get(LocIds.Time.Last6Months);
  case CreatedDateOption.Last12Months: return intl.get(LocIds.Time.Last12Months);
  case CreatedDateOption.Last18Months: return intl.get(LocIds.Time.Last18Months);
  case CreatedDateOption.Last2Years: return intl.get(LocIds.Time.Last2Years);
  case CreatedDateOption.Last3Years: return intl.get(LocIds.Time.Last3Years);
  default: return '';
  }
};

export const getDueDateFilterOdataValue = (option: DueDateOption | undefined) => {
  const now = new Date(Date.now());

  let lowerBound: Date | undefined = new Date(now);
  let upperBound: Date | undefined = new Date(now);

  switch (option) {
  case DueDateOption.Overdue12Months:
    lowerBound.setMonth(now.getMonth() - 12);
    break;
  case DueDateOption.Overdue6Months:
    lowerBound.setMonth(now.getMonth() - 6);
    break;
  case DueDateOption.Overdue3Months:
    lowerBound.setMonth(now.getMonth() - 3);
    break;
  case DueDateOption.Overdue30Days:
    lowerBound.setDate(now.getDate() + 30);
    break;
  case DueDateOption.Upcoming30Days:
    upperBound.setDate(now.getDate() + 30);
    break;
  case DueDateOption.Upcoming3Months:
    upperBound.setMonth(now.getMonth() + 3);
    break;
  case DueDateOption.Upcoming6Months:
    upperBound.setMonth(now.getMonth() + 6);
    break;
  case DueDateOption.Upcoming12Months:
    upperBound.setMonth(now.getMonth() + 12);
    break;
  default:
    lowerBound = undefined;
    upperBound = undefined;
    break;
  }

  if (lowerBound && upperBound) {
    return {
      ge: lowerBound,
      le: upperBound,
    };
  }

  return undefined;
};

export const getCreatedDateFilterOdataValue = (option: CreatedDateOption | undefined) => {
  const now = new Date(Date.now());

  let lowerBound: Date | undefined = new Date(now);
  let upperBound: Date | undefined = new Date(now);

  switch (option) {
  case CreatedDateOption.Last3Years:
    lowerBound.setMonth(now.getMonth() - 36);
    break;
  case CreatedDateOption.Last2Years:
    lowerBound.setMonth(now.getMonth() - 24);
    break;
  case CreatedDateOption.Last18Months:
    lowerBound.setMonth(now.getMonth() - 18);
    break;
  case CreatedDateOption.Last12Months:
    lowerBound.setMonth(now.getMonth() - 12);
    break;
  case CreatedDateOption.Last6Months:
    lowerBound.setMonth(now.getMonth() - 6);
    break;
  case CreatedDateOption.Last3Months:
    lowerBound.setMonth(now.getMonth() - 3);
    break;
  case CreatedDateOption.Last30Days:
    lowerBound.setDate(now.getDate() - 30);
    break;
  default:
    lowerBound = undefined;
    upperBound = undefined;
    break;
  }

  if (lowerBound && upperBound) {
    return {
      ge: lowerBound,
      le: upperBound,
    };
  }

  return undefined;
};
