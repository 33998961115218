import './style.less';

import React from 'react';

import {
  IconButton, ITag, Stack
} from '@fluentui/react';
import intl from 'react-intl-universal';


import { LocIds } from '../../common/Globalization/IntlEnum';
import { ToolTip } from '../ToolTip';

export interface TagGroupProps {
    tags: ITag[];
    onDelete?: (id: string | number) => void;
    disabled?: boolean;
}

export const TagGroup = (props: TagGroupProps): JSX.Element => {
  const { tags, onDelete, disabled } = props;

  return (
    <Stack className='tagGroupWrapper' horizontal>
      { tags.map((tag) => {
        return (
          <div className='tag' key={`${tag.key}`}>
            <span className='tagText'>{ tag.name }</span>
            <ToolTip content={intl.get(LocIds.Action.Delete)}>
              <IconButton
                disabled={disabled}
                iconProps={{
                  iconName: 'cancel'
                }}
                onClick={() => {
                  if (onDelete) {
                    onDelete(tag.key);
                  }
                }}
              />
            </ToolTip>
          </div>
        );
      }) }
    </Stack>
  );
};
