import './style.less';

import React, {
  Dispatch,
  SetStateAction,
  useState
} from 'react';


import {
  Checkbox,
  Dropdown,
  DropdownMenuItemType,
  ICalloutProps,
  IDropdownOption,
  IDropdownProps,
  SearchBox,
} from '@fluentui/react';
import classNames from 'classnames';
import _, { isEmpty } from 'lodash';
import intl from 'react-intl-universal';

import { SharedDropdownProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';

export enum SelectAllCheckBoxState {
  Checked = 0,
  Unchecked = 1,
  Indeterminate = 2
}

export interface SelectAllBaseDropdownProps {
  onSelectAll: () => void;
  dropdownOptions: IDropdownOption[];
  comboBoxState: SelectAllCheckBoxState;
}

type BaseDropdownProps<T> = SharedDropdownProps<T> &
  Pick<
    IDropdownProps,
    'onChange' | 'selectedKey' | 'selectedKeys' | 'multiSelect' | 'calloutProps' | 'aria-label'
  > & {
    selectAllProps?: SelectAllBaseDropdownProps,
  }

export function BaseDropdown<T>(
  props: BaseDropdownProps<T>,
): ReturnType<React.FC<BaseDropdownProps<T>>> {
  const { items, keyOf, optionOf, searchable = false, searchPlaceHolder, searchRequired = false } = props;
  const allOptions = items.map((item) => optionOf(item));
  const [searchText, setSearchText, options] = useSearchUtils(allOptions, searchable);

  const searchBox = (
    <SearchBox
      placeholder={searchPlaceHolder ?? intl.get(LocIds.Placeholder.TypeToFilter)}
      showIcon
      underlined
      onChange={(_, newValue) =>
        setSearchText(newValue ?? '')}
    />
  );

  const isSelectAllCheckBoxIndeterminate = () => {
    if (props.selectAllProps) {
      return props.selectAllProps.comboBoxState === SelectAllCheckBoxState.Indeterminate;
    }

    return false;
  };

  const isSelectAllCheckBoxChecked = () => {
    if (props.selectAllProps) {
      return props.selectAllProps.comboBoxState === SelectAllCheckBoxState.Checked;
    }

    return false;
  };

  const selectAllCheckBox = (
    <div style={{
      padding: '0px 7px',
      paddingTop: '8px'
    }}>
      <Checkbox
        checked={isSelectAllCheckBoxChecked()}
        indeterminate={isSelectAllCheckBoxIndeterminate()}
        label={intl.get(LocIds.Action.SelectAll)}
        onChange={() => {
          if (props.selectAllProps) {
            props.selectAllProps.onSelectAll();
          }
        }}
      />
    </div>
  );

  const onRenderOption = (option?: IDropdownOption) => {
    if (option?.itemType === DropdownMenuItemType.Header) {
      if (option.key === 'FilterHeader') {
        return searchBox;
      } else if (option.key === intl.get(LocIds.Action.SelectAll)) {
        return selectAllCheckBox;
      }

      return null;
    }

    const item = items.find((i) => keyOf(i) === option?.key);
    return item && props.onRenderOption ? (
      props.onRenderOption(item)
    ) : (
      <>{ option?.text }</>
    );
  };

  const calloutProps: ICalloutProps = _.merge(props.calloutProps, {
    calloutMaxHeight: 350,
    className: classNames(props.calloutClassname, 'tp-dropdownCallout', {
      'tp-dropdownCallout--searchable': searchable,
    }),
  });

  const styles = {
    dropdownItemHeader: {
      padding: '4px 2px 0px 2px'
    },
    dropdownDivider: {
      height: 0
    },
    dropDownOptionText: {
      overflow: 'visible',
      whiteSpace: 'normal'
    },
    dropdownItem: {
      height: 'auto'
    },
    dropdownItemSelected: {
      height: 'auto'
    },
    ...(props.styles ?? {}),
  };

  const assembleOptionsWithCustomOptions = () => {
    const customOptions = searchable ? [
      {
        key: 'FilterHeader',
        text: '-',
        itemType: DropdownMenuItemType.Header,
      },
      {
        key: 'FilterHeaderDivider',
        text: '-',
        itemType: DropdownMenuItemType.Divider,
      },
    ] : [] as IDropdownOption[];


    if (searchable && searchRequired && isEmpty(searchText)) {
      return [
        ...customOptions,
        ...props.selectedKeys?.map((key) => {
          return options.find((o) => o.key === key);
        }).filter((o) => o !== undefined) ?? [],
      ] as IDropdownOption[];
    }

    if (props.selectAllProps) {
      customOptions.push(...props.selectAllProps.dropdownOptions);
    }

    return [...customOptions, ...options];
  };

  return (
    <Dropdown
      {...props}
      calloutProps={calloutProps}
      options={assembleOptionsWithCustomOptions()}
      styles={styles}
      onChange={props.onChange}
      onDismiss={() => setSearchText('')}
      onRenderOption={onRenderOption}
    />
  );
}

const useSearchUtils = (
  allOptions: IDropdownOption[],
  searchable: boolean,
): [string, Dispatch<SetStateAction<string>>, IDropdownOption[]] => {
  const [searchText, setSearchText] = useState('');
  let options = [] as IDropdownOption[];

  if (searchable) {
    options =
      allOptions.map((option) =>
        option.text.toLowerCase().includes(searchText.toLowerCase()) || option.id?.includes(searchText) ?
          option :
          {
            ...option,
            hidden: true
          },
      );
  } else {
    options = allOptions;
  }

  return [searchText, setSearchText, options];
};
