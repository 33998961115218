/* eslint-disable i18next/no-literal-string */
import {
  Evidence, OriginalEvidence
} from '../../models';

export const searchableMetaDataInEvidence: Array<
  keyof Evidence | keyof OriginalEvidence
> = [
  'Title',
  'AuditEventTitle',
  'Certification',
  'CertificationControl',
  'EnvironmentName',
  'Teams',
  'Description',
  'EvidenceId',
  'AssignedTo',
  'BugId',
];
