import React, { PropsWithChildren } from 'react';

import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { Banner } from '../Banner';

interface IErrorBoundaryProps {
    onError: (error: Error) => void;
}
interface IErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<
    PropsWithChildren<IErrorBoundaryProps>,
    IErrorBoundaryState
    > {
  readonly state = {
    hasError: false,
  };

  static getDerivedStateFromError(): { hasError: boolean } {
    return {
      hasError: true
    };
  }

  componentDidCatch(error: Error): void {
    this.props.onError(error);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Banner heading={intl.get(LocIds.Error.SomethingWentWrong)}
          iconName='crash'
          iconSize='large'
        />
      );
    }

    return <>{ this.props.children }</>;
  }
}
