import React from 'react';

import {
  DetailsList,
  SelectionMode,
  Text
} from '@fluentui/react';

import intl from 'react-intl-universal';

import { getParameterColumns } from './ParameterTableColumns';

import { LocIds } from '../../../../../common/Globalization/IntlEnum';

import {
  gatingDetailsTables,
  gatingDetailsTableHeading
} from '../styles';

interface ParameterTableProps {
  deployableService: string,
  organization: string,
  project: string,
  buildId: string,
  deploymentEnvironment: string,
  deploymentRing: string
}

export const ParameterTable: React.FC<ParameterTableProps> = (props) => {
  const parameterItems = [
    {
      ...props
    }
  ];

  const tableHeading: JSX.Element = (
    <div style={gatingDetailsTableHeading}>
      <Text variant='xLarge'>
        { intl.get(LocIds.Gating.Parameters) }
      </Text>
    </div>
  );

  const parameterTable: JSX.Element = (
    <div style={gatingDetailsTables}>
      <DetailsList
        columns={getParameterColumns()}
        items={parameterItems}
        selectionMode={SelectionMode.none}
      />
    </div>
  );

  return (
    <>
      { tableHeading }
      { parameterTable }
    </>
  );
};
