/* eslint-disable i18next/no-literal-string */
export interface FedRampManualCloudOverrideSearchParams {
  quickSearch?: string;
  pageIndex?: number;
  pageSize?: number;
  sortOrder: SortOrderValues;
  sortBy: string;
}

export interface TestRenewal {
  ServiceTreeId: string;
  TestDate: Date;
  TestRegion: string;
  IsRequestForFirstTest: boolean;
}

export interface FedRampForceRenewTestsRequest {
  testRenewals: TestRenewal[];
}

export interface FedRampValidateTestCommandParams {
  serviceTreeId: string;
  testDate: Date;
  testRegion: string;
}

export interface FedRampMarkTestCompleteCommandParams {
  serviceTreeId: string;
  testDate: Date;
  testRegion: string;
  isForceRenewTriggered: boolean;
  renewalNotes: string;
}

export interface FedRampApproveTestRenewalCommandParams {
  serviceTreeId: string;
  testDate: string;
  testRegion: string;
  isForceRenewTriggered: boolean;
  renewalNotes: string;
}

export interface FedRampSearchManualGovSubscriptionMappingSearchParams {
  quickSearch?: string;
}

export interface FedRampUpdateManualGovSubscriptionMappingCommandParams {
  serviceTreeId: string;
  serviceName: string;
  division: string;
  organization: string;
  serviceGroup: string;
  gccMt: boolean;
  gccM: boolean;
  gccHigh: boolean;
  doD: boolean;
}

export interface CreateOrUpdateAnomalyAcknowledgement {
  serviceTreeId: string;
  isAcknowledged: boolean;
  notes: string;
}

export enum DefaultGuid {
  DefaultGuid = '00000000-0000-0000-0000-000000000000'
}

export enum SortOrderValues {
  Ascending = 'asc',
  Descending = 'desc'
}

export enum FedRampColumnKeys {
  ServiceTreeId = 'serviceTreeIdColumn',
  Service = 'serviceColumn',
  Division = 'divisionColumn',
  Organization = 'organizationColumn',
  ServiceGroup = 'serviceGroupColumn',
  Classification = 'classification',
  TestTitle = 'testTitleColumn',
  TestLevel = 'testLevelColumn',
  TestType = 'testTypeColumn',
  TestDate = 'lastTestDateColumn',
  TestResult = 'testResultColumn',
  TestRegion = 'testRegionColumn',
  ProjectedGovCloudTestDate = 'projectedGovCloudTestDateColumn',
  NextTestRenewalDate = 'nextTestRenewalDateColumn',
  NumberOfTests = 'numberOfTestsColumn',
  NumberOfGovCloudTests = 'numberOfGovCloudTestsColumn',
  PassingTests = 'passingTestsColumn',
  NumberOfGovCloudTestsInCloud = 'numberOfGovCloudTestsInCloudColumn',
  NumberOfPassingGovCloudTestsInCloud = 'numberOfPassingGovCloudTestsInCloudColumn',
  BcdrChamp = 'bcdrChampColumn',
  CurrentCycleStatus = 'currentCycleStatusColumn',
  DueDateAging = 'dueDateAgingColumn',
  RenewalStatus = 'renewalStatusColumn',
  AssessmentStatus = 'assessmentStatusColumn',
  EnvironmentStatus = 'environmentStatusColumn',
  BcdrLink = 'bcdrLinkColumn',
  S360Link = 's360LinkColumn',
  Blank = 'blankColumn',
  AdminActions = 'adminActionsColumn',
  FairfaxLifecycleStage = 'fairfaxLifecycleStageColumn',
  GovTestsAvailable = 'govTestsAvailableColumn',
  KnownGovSubscriptions = 'knownGovSubscriptionsColumn',
  Certifications = 'CertificationsColumn',
  MissingGovTestList = 'MissingGovTestListColumn',
  IsAnomalyAcknowledged = 'isAnomalyAcknowledged',
  AnomalyAcknowledgedDate = 'anomalyAcknowledgmentDate',
  AnomalyNotes = 'anomalyNotes',
  AnomalyAcknowledgedBy = 'anomalyAcknowledgedBy',
  ValidForAudit = 'validForAudit'
}

export enum FedRampColumnNames {
  ServiceTreeId = 'Service Tree ID (Link to BCDR)',
  Service = 'Service',
  Division = 'Division',
  Organization = 'Organization',
  ServiceGroup = 'Service Group',
  Classification = 'Classification',
  TestTitle = 'Test Title',
  TestLevel = 'Test Level',
  TestType = 'Test Type',
  TestDate = 'Last Test Date',
  TestResult = 'Test Result',
  TestRegion = 'Gov Cloud',
  ProjectedGovCloudTestDate = 'Projected Gov Cloud Test Date',
  NextTestRenewalDate = 'Next Test Renewal Date',
  NumberOfTests = '# of Total Tests',
  NumberOfGovCloudTests = '# of Gov Cloud Tests',
  PassingTests = '# of Passing Tests',
  NumberOfGovCloudTestsInCloud = '# of Tests in Cloud',
  NumberOfPassingGovCloudTestsInCloud = '# of Passing Tests in Cloud',
  BcdrChamp = 'BCDR Champ',
  CurrentCycleStatus = 'Current Cycle-Status',
  DueDateAging = 'Due Date Aging',
  RenewalStatus = 'Renewal Status',
  AssessmentStatus = 'Assessment Status',
  EnvironmentStatus = 'Environment Status',
  ServiceStatus = 'Service Status',
  BcdrLink = 'BCDR Link',
  S360Link = 'S360 Link',
  Blank = '',
  AdminActions = 'Admin Actions',
  FairfaxLifecycleStage = 'Fairfax Lifecycle',
  GovTestsAvailable = 'Gov Tests Available',
  KnownGovSubscriptions = 'Known Gov Subscriptions',
  Certifications = 'Certifications',
  MissingGovTestList = 'Missing Gov Tests',
  IsAnomalyAcknowledged = 'Anomaly Acknowledged',
  AnomalyAcknowledgedDate = 'Acknowledged Date',
  AnomalyNotes = 'Anomaly Acknowledgment Notes',
  AnomalyAcknowledgedBy = 'Updated By',
  ValidForAudit = 'Good Sample for Audit'
}

export enum FedRampColumnFieldNames {
  ServiceTreeId = 'ServiceTreeId',
  Service = 'ServiceName',
  Division = 'DivisionName',
  Organization = 'OrganizationName',
  ServiceGroup = 'ServiceGroupName',
  Classification = 'Classification',
  TestTitle = 'TestTitle',
  TestLevel = 'TestLevel',
  TestType = 'TestType',
  TestDate = 'TestDate',
  TestResult = 'TestResult',
  TestRegion = 'TestRegion',
  ProjectedGovCloudTestDate = 'ProjectedGovCloudTestDate',
  NextTestRenewalDate = 'NextTestRenewalDate',
  NumberOfTests = 'NumberOfTests',
  NumberOfGovCloudTests = 'NumberOfGovCloudTests',
  PassingTests = 'PassingTests',
  NumberOfGovCloudTestsInCloud = 'NumberOfGovCloudTestsInCloud',
  NumberOfPassingGovCloudTestsInCloud = 'NumberOfPassingGovCloudTestsInCloud',
  BcdrChamp = 'BcdrChamp',
  CurrentCycleStatus = 'CurrentCycleStatus',
  DueDateAging = 'DueDateAging',
  RenewalStatus = 'RenewalStatus',
  AssessmentStatus = 'AssessmentStatus',
  EnvironmentStatus = 'EnvironmentStatus',
  BcdrLink = 'BcdrLink',
  S360Link = 'S360Link',
  Blank = '',
  Action = '',
  FairfaxLifecycleStage = 'FairfaxLifecycleStage',
  GovTestsAvailable = 'govTestList',
  KnownGovSubscriptions = 'knownGovSubscriptions',
  Certifications = 'certificationList',
  MissingGovTestList = 'missingGovTestList',
  IsAnomalyAcknowledged = 'IsAnomalyAcknowledged',
  IsAnomalyAcknowledgedString = 'IsAnomalyAcknowledgedString',
  AnomalyNotes = 'AnomalyNotes',
  AnomalyAcknowledgedDate = 'AnomalyAcknowledgedDate',
  AnomalyAcknowledgedBy = 'AnomalyAcknowledgedByUserPrincipalName',
}
