export interface Pipeline {
  Name: string;
  EvidenceAutomationId: string;
  Path: string;
  CronSchedule?: string;
}

export interface PipelineSchedule {
  ScheduleId: string;
  Enabled: boolean;
  LastOccurrenceDate: string;
}

export interface PipelineFilters {
  keyword?: string,
  state?: StateFilter
  sortKey?: string,
  isAsc?: boolean,
}

export enum PipelineState {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export enum StateFilter {
  All = 0,
  Enabled = 1,
  Disabled = 2
}

export enum SortKey {
  Name = 'Name'
}

export interface PipelinePayload {
  evidenceRequestId: number;
  evidenceAutomationId: string;
}

export interface TogglePipelineStateThunkPayload {
  pipeline: Pipeline,
  enabled: boolean,
}

export interface TogglePipelineStatePayload {
  evidenceAutomationId: string;
  enabled: boolean,
}
