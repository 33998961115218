import React, {
  useEffect, useState
} from 'react';

import { PanelType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import _ from 'lodash';
import intl from 'react-intl-universal';
import {
  useNavigate, useParams
} from 'react-router-dom';

import { HideDoubleConfirmKeyEnum } from './HideDoubleConfirmKeyEnum';

import { LocIds } from '../../../../../../common/Globalization/IntlEnum';
import {
  IPrimaryButtonProps, TaskCard, TrustPanel, ViewLayout
} from '../../../../../../components';
import { DoubleConfirmDialog } from '../../../../../../components/ConfirmDialog';
import {
  AzureAdMember,
  EvidenceRequestAttachment,
  EvidenceStateEnum,
  OriginalEvidence,
} from '../../../../../../models';
import {
  EvidenceApi, portalConfigurationService
} from '../../../../../../services';
import {
  constructSubmitOriginalEvidencePayload,
  getErrorMsg,
  notify,
  SubmitOriginalEvidenceEditableFields,
  useLocalStorage,
} from '../../../../../../utils';
import {
  Application, ServiceWorkspace
} from '../../../../../TrustPortal/registerApplicationMenu';
import { fetchOriginalEvidenceById } from '../../redux/evidenceSlice';
import { useAppDispatch } from '../../redux/reducer';

export const EditEvidencePanel: React.FC = () => {
  const { taskId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // use null to indicate no access or no found, while undefined represents loading
  const [evidence, setEvidence] = useState<OriginalEvidence | null>();

  const [attachments, setAttachments] = useState<EvidenceRequestAttachment[]>([]);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [showConfirmation, { setTrue: setShowConfirmation, setFalse: setHideConfirmation }] = useBoolean(false);

  const [hideConfirm] = useLocalStorage<boolean>(HideDoubleConfirmKeyEnum.SubmitEvidenceRequest);

  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    if (!_.isNil(taskId)) {
      // TaskId consists of auditEventGuid and evidenceGuid. Used to request a single Original Evidence.
      const [auditEventGuid, evidenceGuid] = taskId.split('_');

      if (!_.isNil(auditEventGuid) && !_.isNil(evidenceGuid)) {
        EvidenceApi.getSingleOriginalEvidenceRequestAsync(auditEventGuid, evidenceGuid).then((response) => {
          setEvidence(response ?? null);
        });
      }
    }
  }, [taskId]);

  useEffect(() => {
    if (evidence) {
      setAttachments(evidence.Attachments);
    }
  }, [evidence]);

  const onDismiss = () => navigate(`/application/${Application.ServiceWorkspace}/menu/${ServiceWorkspace.TaskBoard}`);

  const onSubmit = (edited: SubmitOriginalEvidenceEditableFields, isSubmit: boolean) => {
    if (evidence) {
      setIsProcessing(true);
      const payload = constructSubmitOriginalEvidencePayload(evidence, edited, isSubmit);
      EvidenceApi.submitOriginalEvidenceAsync(payload)
        .then(() => {
          notify.success(intl.get(isSubmit ?
            LocIds.AuditManager.OriginalEvidenceSubmittedSuccessfully :
            LocIds.AuditManager.OriginalEvidenceSavedSuccessfully, {
            title: evidence.Title
          }));

          // Refresh the updated evidence.
          dispatch(fetchOriginalEvidenceById({
            auditEventGuid: evidence.AuditEventGuid,
            evidenceGuid: evidence.EvidenceGuid
          }));
          onDismiss();
        })
        .catch(e => {
          getErrorMsg(e).then(setErrorMessage);
          setShowErrorMessage(true);
        })
        .finally(() => setIsProcessing(false));
    }
  };

  const onConfirmSubmit = () => {
    onSubmit({
      attachments
    }, true);
    setHideConfirmation();
  };

  const onTrySubmit = () => {
    if (!hideConfirm) {
      setShowConfirmation();
    } else {
      onConfirmSubmit();
    }
  };

  const onSave = () => {
    onSubmit({
      attachments
    }, false);
  };

  const primaryActionProps: IPrimaryButtonProps = {
    split: true,
    text: intl.get(LocIds.Action.SaveAndSubmit),
    onClick: onTrySubmit,
    disabled: isProcessing,
    menuProps: {
      items: [
        {
          key: 'save',
          text: intl.get(LocIds.Action.Save),
          onClick: onSave,
          disabled: isProcessing || !isEdited
        },
        {
          key: 'submit',
          text: intl.get(LocIds.Action.SaveAndSubmit),
          onClick: onTrySubmit,
          disabled: isProcessing,
        }
      ]
    }
  };

  const isAdoManagedEvidence = !_.isNil(evidence?.ViewUrl);
  const isEditAdoManagedEvidenceEnabled = portalConfigurationService.getPortalFlightings().allowEditAdoManagedEvidence;
  const requested = evidence?.State === EvidenceStateEnum.Requested;
  const editable = requested && (!isAdoManagedEvidence || isEditAdoManagedEvidenceEnabled);

  // Show a link to ADO for editing if the evidence is created on ADO and has a view url.
  const actionProps = requested && isAdoManagedEvidence ? [
    {
      text: isEditAdoManagedEvidenceEnabled ? intl.get(LocIds.Action.EditInAzureDevOps) : intl.get(LocIds.Action.Edit),
      onClick: () => {
        window.open(evidence.ViewUrl!, '_blank');
      }
    }
  ] : undefined;

  const onEditAttachments = (attachments: EvidenceRequestAttachment[]) => {
    setAttachments(attachments);
    setIsEdited(true);
  };

  const onSaveAssignee = (alias?: AzureAdMember) => {
    if (alias) {
      onSubmit({
        assignedTo: alias
      }, false);
    }
  };

  const bodyJsx = (
    <ViewLayout isError={false} isLoaded={!_.isUndefined(evidence)} isNotFound={_.isNull(evidence)} >
      { evidence && (
        editable ? (
          <TaskCard
            actionProps={actionProps}
            attachments={attachments}
            evidence={evidence}
            onChangeAttachments={onEditAttachments}
            onSaveAssignee={onSaveAssignee}
          />
        ) : (
          <TaskCard actionProps={actionProps} evidence={evidence} />
        )
      ) }
    </ViewLayout>
  );

  return (
    <TrustPanel
      errorMessage={errorMessage}
      headerText={intl.get(LocIds.Evidence.EditEvidenceRequest, {
        id: evidence ? `#${evidence.EvidenceId}` : undefined
      })}
      isOpen={true}
      primaryActionProps={editable ? primaryActionProps : undefined}
      showErrorDismiss={() => setShowErrorMessage(false)}
      showErrorMessage={showErrorMessage}
      showProcessingMessage={isProcessing}
      showSpinnerInPrimaryButton={isProcessing}
      type={PanelType.large}
      onDismiss={onDismiss}>
      { bodyJsx }
      <DoubleConfirmDialog
        actionText={intl.get(LocIds.Action.Submit)}
        doubleConfirmDialogIdentifier={HideDoubleConfirmKeyEnum.SubmitEvidenceRequest}
        showConfirmation={showConfirmation}
        subText={intl.get(LocIds.ServiceWorkspace.SubmitEvidenceRequestDoubleConfirmText)}
        title={intl.get(LocIds.Label.SubmitEvidenceRequest)}
        onAction={onConfirmSubmit}
        onDismiss={setHideConfirmation}
      />
    </TrustPanel>
  );
};
