import React, {
  useEffect,
  useMemo,
  useState
} from 'react';

import {
  IShimmerElement,
  Shimmer,
  ShimmerElementType,
  Spinner
} from '@fluentui/react';

import intl from 'react-intl-universal';
import {
  useLocation,
  useNavigate
} from 'react-router';


import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  createDashboardTree,
  DashboardTreeNode,
  PowerBiReportMetadata
} from '../../models';
import { PowerBiApi } from '../../services';
import { notify } from '../../utils/notify';
import { Banner } from '../Banner';
import { EmbeddedReport } from '../EmbeddedReport';
import {
  SideNav,
  SideNavLink
} from '../SideNav';
import './style.less';


interface IDashboardViewProps {
  reports: PowerBiReportMetadata[];
  applicationId: string;
  menuId: string;
  showSearchBox?: boolean;
  groupByScope?: boolean;
  filterSideNav?: boolean;
}

export const DashboardView: React.FC<IDashboardViewProps> = (
  props: IDashboardViewProps,
) => {
  const {
    reports,
    applicationId,
    menuId,
    showSearchBox = false,
    groupByScope = false,
    filterSideNav = false,
  } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [searchQuery, setSearchQuery] = useState('');

  const dashboardTree = useMemo(() => createDashboardTree(reports, groupByScope), [reports, groupByScope]);

  const menuReports = useMemo(() =>
    filterSideNavLinks(filterSideNav, searchQuery, dashboardTree), [filterSideNav, searchQuery, dashboardTree]);

  const reportId = useMemo(() => getReportIdFromPath(pathname), [pathname]);

  const reportName = useMemo(() => {
    const currentReport = reports.find((report) => report.ReportId.toString() === reportId);
    return currentReport?.Title ?? '';
  }, [reports, reportId]);

  const [reportToken, setReportToken] = useState('');

  useEffect(() => {
    if (!reportId) {
      return;
    }

    const fetchToken = async () => {
      try {
        const res = await PowerBiApi.generatePowerBiTokenAsync(reportId);
        setReportToken(res);
      } catch (error) {
        notify.error(intl.get(LocIds.Error.TryAgainLater));
      }
    };
    fetchToken();
  }, [reportId]);

  const isLinkActive = (link: SideNavLink<DashboardTreeNode>) => {
    const node = link as DashboardTreeNode;
    return getReportIdFromPath(node.reportPath) === reportId;
  };

  const onLinkClick = (link: SideNavLink<DashboardTreeNode>) => {
    const node = link as DashboardTreeNode;

    // If a node has children, it is considered a scope, not a report, and should not trigger navigation
    if (node.children.length > 0) {
      return;
    }

    const path = node.reportPath;
    navigate(`/application/${applicationId}/menu/${menuId}${path}`);
  };

  const onSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  const shimmerElements: IShimmerElement[] = [
    {
      type: ShimmerElementType.line,
      height: 24,
      width: '80%',
    }
  ];

  return (
    <div className='dashboard__content-wrapper'>
      <aside className='dashboard__aside'>
        <h3 className='dashboard__aside-title title-16'>{ intl.get(LocIds.Label.Reports) }</h3>
        { menuReports ? (
          <SideNav<DashboardTreeNode>
            isLinkActive={isLinkActive}
            links={[...menuReports]}
            searchQuery={searchQuery}
            showSearchBox={showSearchBox}
            wrapperClassName='dashboard__page-nav'
            onLinkClick={onLinkClick}
            onSearch={onSearch}
          />
        ) : (
          <div className='dashboard__aside-shimmers'>
            <Shimmer shimmerElements={shimmerElements}/>
            <Shimmer shimmerElements={shimmerElements}/>
          </div>
        ) }
      </aside>

      <div className='report__content-scroll'>
        <div className='report__content'>
          { reportId ? (
            reportToken ? (
              <EmbeddedReport
                reportId={reportId}
                reportTitle={reportName}
                token={reportToken}
              />
            ) : <Spinner className='rootSpinner'/>
          ) : (
            <Banner
              heading={intl.get(LocIds.EmptyState.DashboardHeading)}
              iconName='action-required'
              iconSize='large'
              subheading={intl.get(LocIds.EmptyState.DashboardSubheading)}
            />
          ) }
        </div>
      </div>
    </div>
  );
};

const getReportIdFromPath = (path: string) => {
  let reportId = '';
  const reportSegment = 'report/';

  if (path.includes(reportSegment)) {
    reportId = path.split(reportSegment)[1];
  }

  return reportId.trim();
};

const filterSideNavLinks = (filterSideNav: boolean, searchQuery: string, reports: DashboardTreeNode[]): DashboardTreeNode[] => {
  if (!filterSideNav || searchQuery === '') {
    return reports;
  }

  const res: DashboardTreeNode[] = [];
  const target = searchQuery.toLowerCase();
  reports.forEach(report => {
    if (report.children.length > 0) {
      report.children.forEach(child => {
        filterReport(target, child, res);
      });
    } else {
      filterReport(target, report, res);
    }
  });
  return res;
};

const filterReport = (searchQuery: string, report: DashboardTreeNode, res: DashboardTreeNode[]) => {
  if (report.name.toLowerCase().includes(searchQuery)) {
    res.push(report);
  }
};
