export interface GatingTraceInput extends GatingQueryFilters {
  top: number,
  skip: number,
}

export interface GatingQueryFilters {
  deployableService: string | null,
  deployableServiceType: string | null,
  organization: string| null,
  project: string | null,
  repository: string | null,
  overallCompliantStatus: string | null,
  buildId: string | null,
}

export interface GatingTraceResponse {
  Items: GatingTrace[]
}

export interface GatingTrace {
  CorrelationId: string,
  CreatedTime: Date,
  BuildExistence: boolean,
  DeploymentUnitName: string,
  DeploymentUnitType: DeploymentUnitType,
  Organization: string,
  Project: string,
  BuildId: string,
  VstsDropLocation: string,
  Repository: string,
  BuildHeraId: string,
  DeploymentEnvironment: string,
  Workload: string,
  DeploymentRing: string,
  DeploymentPhase: string,
  ReferenceDeploymentId: string,
  ReferenceDeploymentHeraId: string,
  OverallCompliantStatus: CompliantStatus,
  BuildEvidenceCompliantStatus: CompliantStatus,
  PullRequestCompliantStatus: CompliantStatus,
  ProgressionCompliantStatus: CompliantStatus,
  BuildEvidenceTraces: BuildEvidenceTrace[],
  PullRequestTraces: PullRequestTrace[],
  Exception: GatingException[],
  BuildOwnerIcmTeam: string,
  ServiceOwnerIcmTeam: string,
  TeamToAlert: string,
  GatingRule: GatingRuleEntity
}

export interface BuildEvidenceTrace {
  Type: string,
  Value: string,
  IsRun: boolean,
  Exists: boolean
}

export interface PullRequestTrace {
  ExternalId: string,
  Status: CompliantStatus,
  RemediatedByWorkItemId: string,
  RemediatedByWorkItemUrl: string,
}

export enum BuildEvidenceRequirement {
  SDL,
  Roslyn,
  PreFast,
  BinSkim,
  Eslint,
  Fortify,
  Bandit,
  CredScan,
  ComponentGovernment,
  CodeSign,
  SpotBugs,
  UnitTest,
  MicrosoftDefenderAntivirus,
}

export enum CompliantStatus {
  NotDefined = 'NotDefined',
  Compliant = 'Compliant',
  NotCompliant = 'NotCompliant',
  MissingData = 'MissingData',
  DBNotAvailable = 'DBNotAvailable',
  CompliantWithRemediation = 'CompliantWithRemediation'
}

export enum StatusMessage {
  Compliant = 'Compliant',
  CompliantWithRemediation = 'Compliant With Remediation',
  DBNotAvailable = 'DB Not Available',
  NotCompliant = 'Not Compliant',
  MissingData = 'Missing Data',
  NotDefined = 'Not Defined'
}

export enum DeploymentUnitType {
  NotDefined = 'NotDefined',
  NotEligible = 'NotEligible',
  NotFound = 'NotFound',
  MicroService = 'MicroService',
  Image = 'Image'
}

export enum DeploymentUnitTypeMessage {
  NotDefined = 'Not Defined',
  NotEligible = 'Not Eligible',
  NotFound = 'Not Found',
  MicroService = 'MicroService',
  Image = 'Image'
}

export enum GatingException {
  None,
  NullReferenceException,
  OtherExceptions,
}

export interface GatingRuleEntity {
  GatingRuleId: string,
  DeploymentUnitType: DeploymentUnitType,
  EnableGating: boolean,
  EnableProgressionGating: boolean,
  EnableAlerting: boolean,
  BuildEvidenceRequirements: BuildEvidenceRequirement[],
  ProgressionRules: Map<string, []>[],
  ServiceTreeRequirements: ServiceTreeRequirement[],
  IsRuleEffective: boolean,
  EffectiveDateTime: Date,
  CreatedBy: string,
  CreatedDateTime: Date,
  ModifiedBy: string,
  ModifiedDateTime: Date,
}

export enum ServiceTreeRequirement {
  None,
  IcmTeamMustExist,
  GccHighComplianceOnboardingDateMustExistForGccHighDeployment,
}
