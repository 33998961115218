import React from 'react';

import {
  IconButton, Stack,
  Callout,
  DefaultButton,
  IButtonStyles,
  IStackStyles,
  Label,
} from '@fluentui/react';
import {
  useBoolean, useId
} from '@fluentui/react-hooks';

import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { getSanitizedRichTextJsx } from '../../utils/getSanitizedRichTextJsx';


const iconProps = {
  iconName: 'Info'
};

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    marginBottom: -3
  }
};

const labelCalloutStackStyles: Partial<IStackStyles> = {
  root: {
    padding: 20
  }
};

interface IProps {
  label?: string;
  description?: string;
  showLabelTooltip?: boolean;
  required?: boolean;
}

export const LabelWithIcon = (props: IProps): JSX.Element => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const descriptionId: string = useId('description');
  const iconButtonId: string = useId('iconButton');
  return (
    <>
      <Stack horizontal verticalAlign='center'>
        <Label required={props.required}>{ props.label }</Label>
        { props.showLabelTooltip ? (
          <IconButton
            ariaLabel={props.label}
            iconProps={iconProps}
            id={iconButtonId}
            styles={iconButtonStyles}
            onClick={toggleIsCalloutVisible}
          />
        ) : null }
      </Stack>
      { isCalloutVisible && (
        <Callout
          ariaDescribedBy={descriptionId}
          role='alertdialog'
          setInitialFocus
          target={'#' + iconButtonId}
          onDismiss={toggleIsCalloutVisible}>
          <Stack horizontalAlign='start' styles={labelCalloutStackStyles}>
            <span id={descriptionId}>{ getSanitizedRichTextJsx(false, props.description ? props.description : '') }</span>
            <DefaultButton onClick={toggleIsCalloutVisible}>{ intl.get(LocIds.Label.Close) }</DefaultButton>
          </Stack>
        </Callout>
      ) }

    </>
  );
};
