import React from 'react';

import {
  TooltipHost,
  TooltipOverflowMode
} from '@fluentui/react';

import {
  classNames,
  tooltipHostStyles
} from './TableContentTooltipStyles';

interface TableContentTooltipProps {
    content: JSX.Element;
    tooltipText: string;
}

const TableContentTooltip: React.FC<TableContentTooltipProps> = (props:TableContentTooltipProps) => {
  return (
    <TooltipHost
      className={classNames.tooltipContainer}
      content={props.tooltipText}
      overflowMode={TooltipOverflowMode.Parent}
      styles={tooltipHostStyles}>
      { props.content }
    </TooltipHost>
  );
};

export default TableContentTooltip;
