import React, {
  useCallback, useState
} from 'react';

import { IconButton } from '@fluentui/react';

import { IButtonProps } from '@fluentui/react/lib/components/Button/Button.types';
import classnames from 'classnames';

import { OnRenderNode, } from './PathTree';
import { FlatTreeItem } from './types';

interface IProps {
    node: FlatTreeItem;
    activeNode: FlatTreeItem | undefined;
    collapseNode: (node: FlatTreeItem, collapsed: boolean) => void;
    onNodeClick?: (node: FlatTreeItem) => void;
    onRenderNode?: OnRenderNode;
}

export const PathTreeNode: React.FC<IProps> = React.memo(({ node, activeNode, collapseNode, onNodeClick, onRenderNode }: IProps) => {

  const [collapsedTree, setCollapsedTree] = useState<boolean>(false);

  const iconButtonProperties: IButtonProps = {
    className: classnames(
      'path-tree__node-icon',
      node.children === 0 ? 'path-tree__node-icon--dot' : 'path-tree__node-icon--chevron',
      collapsedTree ? '' : 'active'
    ),
    iconProps: {
      iconName: node.children === 0 ? 'LocationDot' : 'ChevronRight',
    },
    styles: {
      root: {
        width: '24px',
        height: '24px'
      }
    }
  };

  const resourceCatalogClassnames = classnames(
    'path-tree__node',
    activeNode?.path === node.path ? 'active' : ''
  );

  const onClick = useCallback(() => {
    onNodeClick && onNodeClick(node);
  }, [node]);

  const onIconClick = () => {
    setCollapsedTree((collapsed: boolean) => {
      collapseNode(node, !collapsed);
      return !collapsed;
    });
  };

  return (
    <>
      <div className={resourceCatalogClassnames}
        style={{
          paddingLeft: node.depth * 26
        }}>
        <IconButton {...iconButtonProperties} onClick={onIconClick}/>
        <button aria-label={node.name} className='text-14 path-tree__node-label' onClick={onClick}>
          { onRenderNode ? onRenderNode(node) : node.name }
        </button>
      </div>
    </>
  );
});
