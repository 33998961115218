import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  Reducer,
  ReducersMapObject,
  Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';


import { IServices } from '../../services';
import { isDevelopment } from '../../utils';
import { errorMiddleware } from '../middlewares/errorMiddleware';
import {
  initialRootState,
  reducer,
  RootReduxState
} from '../reducer';

const staticReducers = {
  portal: reducer
};

function createReducer(asyncReducers: ReducersMapObject) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

export interface DynamicReducerStore extends Store<RootReduxState, AnyAction> {
  asyncReducers: ReducersMapObject;
  injectReducer: (namespace: string, reducer: Reducer) => void;
}

/**
 * Create store for the entire portal
 * @param services inject service
 */
export const getStore = (services: IServices): DynamicReducerStore => {

  const middlewares = isDevelopment ?
    composeWithDevTools(
      applyMiddleware(errorMiddleware, thunk.withExtraArgument<IServices>(services)),
    ) :
    applyMiddleware(errorMiddleware, thunk.withExtraArgument<IServices>(services));

  const store = createStore(
    combineReducers(staticReducers),
    initialRootState,
    middlewares) as DynamicReducerStore;

  store.asyncReducers = {};
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  return store;
};
