import React, { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import {
  Application,
  ComplianceViewer
} from '../../TrustPortal/registerApplicationMenu';

const Home = lazy(() => import('../pages/Home'));
const Deployment = lazy(() => import('../pages/Deployment'));
const Build = lazy(() => import('../pages/Builds'));
const Service = lazy(() => import('../pages/Service'));

export const routes: RouteObject[] = [
  {
    path: `${Application.ComplianceViewer}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${ComplianceViewer.Deployment}/*`,
        element: <Deployment />,
      },
      {
        path: `menu/${ComplianceViewer.Build}`,
        element: <Build />,
      },
      {
        path: `menu/${ComplianceViewer.Service}/*`,
        element: <Service />,
      },
    ],
  },
];
