import React, { FC } from 'react';

import './PersonaValue.less';

import {
  Persona, PersonaSize
} from '@fluentui/react';

import { CopyButton } from '../CopyButton';


interface IProps {
    displayName: string;
    value?: string;
    canCopy?: boolean;
    size?: PersonaSize;
}

export const PersonaValue: FC<IProps> = ({ displayName, value = displayName,
  canCopy = false, size = PersonaSize.size24 }: IProps) => {

  return (
    <div className='persona-value__value-wrapper'>
      <Persona
        size={size}
        text={displayName}
      />
      { canCopy && (
        <span className='persona-value__copyIcon'>
          <CopyButton text={value}/>
        </span>
      ) }
    </div>
  );
};
