import './style.less';

import React from 'react';

import {
  Stack, Text
} from '@fluentui/react';
import { isNil } from 'lodash';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

interface MetaTreeGroup {
  text: string;
  children: string[];
  trailingJsx?: JSX.Element;
}

interface MetaTreeProps {
  groups: MetaTreeGroup[];
  // If defined, the items of EACH group will be truncated to the limit number.
  itemTruncated?: number;
}

export const MetaTree: React.FC<MetaTreeProps> = (props) => {
  const { itemTruncated } = props;

  const buildNestedTexts = (group: MetaTreeGroup) => {
    const texts = group.children;
    const visibleTexts = itemTruncated ? texts.slice(0, itemTruncated) : texts;
    const hiddenCount = texts.length - visibleTexts.length;
    return (
      <>
        { visibleTexts.map((item, index) => (
          <Text className='metaTree-group-item' key={index}>
            { item }
          </Text>
        )) }
        { hiddenCount > 0 && (
          <Text className='metaTree-group-item'>
            { intl.get(LocIds.Label.PlusMore, {
              number: hiddenCount
            }) }
          </Text>
        ) }
        { !isNil(group.trailingJsx) && (
          <div className='metaTree-group-item'>
            { group.trailingJsx }
          </div>
        ) }
      </>
    );
  };

  return (
    <Stack className={'metaTree'}>
      { props.groups.map((group, index) => (
        <Stack className='metaTree-group' key={index}>
          <Text>{ group.text }</Text>
          { buildNestedTexts(group) }
        </Stack>
      )) }
    </Stack>
  );
};
