import { useLocation } from 'react-router-dom';

import { Documentation } from '../../../../TrustPortal/registerApplicationMenu';

export const useDocPath = (): string => {
  const { pathname } = useLocation();
  const re = new RegExp(`(?<=${Documentation.Docs}).+`);
  const match = pathname.match(re);

  if (match) {
    return decodeURI(match[0]);
  }

  return '';
};
