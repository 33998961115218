import React, {
  FC,
  useCallback
} from 'react';

import { IDatePickerProps } from '@fluentui/react';
import {
  Controller,
  FieldError,
  UseControllerProps,
  useFormContext
} from 'react-hook-form';

import intl from 'react-intl-universal';

import { LocIds } from '../../../common/Globalization/IntlEnum';
import { DatePicker } from '../../DatePicker';

interface IProps extends IDatePickerProps, Pick<UseControllerProps, 'rules'> {
  name: string;
}

export const DateControl: FC<IProps> = (props: IProps) => {

  const { control, watch, setError } = useFormContext();
  const fieldValue = watch(props.name);

  const onSelectDate = (onChange: (...event: any[]) => void) => {
    return (date: Date | null | undefined) => {
      onChange(date);
    };
  };

  const getErrorMessage = useCallback((error: FieldError | undefined): string | undefined => {
    if (!error) {
      return undefined;
    }

    // Don't show required message if the value is present. This can happen when value was changed programmatically.
    if (error.message === intl.get(LocIds.Error.RequiredField) && !!fieldValue) {
      return undefined;
    }

    return error.message;
  }, [fieldValue]);

  return (
    <Controller
      control={control}
      defaultValue={props.defaultValue}
      name={props.name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DatePicker
          {...props}
          ariaLabel={props.label || props.ariaLabel}
          placeholder={intl.get(LocIds.Placeholder.SelectADate)}
          textField={{
            errorMessage: getErrorMessage(error)
          }}
          value={value}
          onSelectDate={onSelectDate(onChange)}
        />
      )}
      rules={props.rules}
    />
  );
};
