import React from 'react';

import {
  FontIcon,
  IColumn
} from '@fluentui/react';

import intl from 'react-intl-universal';

import {
  icon,
  nonCompliantCircle
} from './PullRequestTableStyles';

import { LocIds } from '../../../../../common/Globalization/IntlEnum';

import {
  PullRequestTrace,
  CompliantStatus,
  StatusMessage
} from '../../../../../models';

export const getPullRequestColumns = (): IColumn[] => [
  {
    key: 'ExternalId',
    name: intl.get(LocIds.Gating.PullRequestID),
    minWidth: 200,
    maxWidth: 250,
    fieldName: 'ExternalId'
  },
  {
    key: 'result',
    name: intl.get(LocIds.Gating.Result),
    minWidth: 200,
    maxWidth: 250,
    fieldName: 'Status',
    onRender(item: PullRequestTrace) {
      if (item.Status === CompliantStatus.NotCompliant) {
        return (
          <>
            <div style={nonCompliantCircle}>
              <FontIcon aria-label={intl.get(LocIds.Gating.Compass)} iconName='Cancel' style={icon} />
            </div>
            <span>
              { StatusMessage.NotCompliant }
            </span>
          </>
        );
      }

      return <></>;
    }
  },
  {
    key: 'remediationWorkItemUrl',
    name: intl.get(LocIds.Gating.RemediationWorkItem),
    minWidth: 200,
    maxWidth: 250,
    fieldName: 'RemediateByWorkItemUrl',
    onRender(item: PullRequestTrace) {
      if (item.RemediatedByWorkItemUrl && item.RemediatedByWorkItemId) {
        return (
          <>
            <a href={item.RemediatedByWorkItemUrl} target='_blank'>{ item.RemediatedByWorkItemId }</a>
          </>
        );
      }

      return <></>;
    }
  },
  {
    key: 'note',
    name: intl.get(LocIds.Gating.Note),
    minWidth: 400,
    maxWidth: 500,
    fieldName: 'note',
    isMultiline: true,
    onRender(item: PullRequestTrace) {
      if (item.Status !== CompliantStatus.Compliant) {
        return (
          <div style={{
            wordBreak: 'break-word',
            lineBreak: 'normal'
          }}>
            <p>{ intl.get(LocIds.Gating.PRNeedsToBeApprovedCorrectly) }</p>
            <p>
              1. This means that the PR has either a valid ApprovedBy OR AcceptAndCompletedBy relationship,
              has a valid LastPushedBy relationship, and the UPN value for ApprovedBy OR AcceptAndCompletedBy
              is not the same as the one in the LastPushedBy relationship.
            </p>
            <p>{ intl.get(LocIds.Gating.NonCompliantPRMessage) }</p>
          </div>
        );
      }
    }
  },
];
