import _ from 'lodash';

/**
 *
 * @param fileName path to the file or just name of the file
 * Try to get extension from fileName, for example, "test.jpg" will return "jpg"
 * File extension is normalized to lower case
 * This function will not check whether the extension is correct, for example, "foo.b.a.r" will return "r",
 * so it is recommended to have a fallback/default extension when the result is invalid
 */
export const getExtensionFromFileName = (
  fileName: string,
): string | undefined => {
  if (!_.isString(fileName)) {
    return undefined;
  }

  const name = fileName.trim();

  if (_.isNil(name) || _.isEmpty(name)) {
    return undefined;
  }

  const ext = name.split('.').pop();

  if (_.isNil(ext) || _.isEmpty(ext)) {
    return undefined;
  }

  return ext.toLowerCase();
};
