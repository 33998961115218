import _ from 'lodash';

import { EvidenceTreeNodeVisitor } from './EvidenceTreeNodeVisitor';

import { EvidencePath } from '../EvidencePath';
import { AuditEventNode } from '../EvidenceTreeNode/AuditEventNode';
import { CertificationNode } from '../EvidenceTreeNode/CertificationNode';
import { EvidenceNode } from '../EvidenceTreeNode/EvidenceNode';
import {
  EvidenceTreeInternalNode,
  EvidenceTreeNodeBase,
} from '../EvidenceTreeNode/EvidenceTreeNodeBase';
import { EvidenceTreeRootNode } from '../EvidenceTreeNode/EvidenceTreeRootNode';
import { TeamNode } from '../EvidenceTreeNode/TeamNode';

/**
 * Given an EvidenceTreeRootNode, check whether required path exists in visible nodes of current tree
 *
 * Scenario: When user changes his/her search query and he/she has a selected node before,
 * there are chances that in the next filtered EvidenceTree, the former selected node is not filtered,
 * to check whether we have to clear the selected node state, we check whether the former selected path exists in current tree
 */
export class FindVisiblePathVisitor implements EvidenceTreeNodeVisitor {
  constructor(path: EvidencePath) {
    this.path = path;
    this._isPathFound = Object.values(this.path).length === 0;
  }

  public visitEvidenceTreeRootNode(node: EvidenceTreeRootNode): void {
    node.children.forEach((child: EvidenceTreeNodeBase) => child.accept(this));
  }

  public visitAuditEventNode(node: AuditEventNode): void {
    this.findPath(node);
  }

  public visitTeamNode(node: TeamNode): void {
    this.findPath(node);
  }

  public visitCertificationNode(node: CertificationNode): void {
    this.findPath(node);
  }

  public visitEvidenceNode(node: EvidenceNode): void {
    this.findPath(node);
  }

  public get isPathFound(): boolean {
    return this._isPathFound;
  }

  private findPath(node: EvidenceTreeNodeBase) {
    if (this._isPathFound) {
      return;
    }

    if (!node.isVisible) {
      return;
    }

    this._isPathFound = _.isEqual(node.pathToCurrentNode, this.path);

    // If path to current node is not the required path, continue search in child nodes
    if (!this._isPathFound) {
      if (node instanceof EvidenceTreeInternalNode) {
        // use Array.prototype.some for shortcut if path is found in any child
        node.children.some((child) => {
          child.accept(this);
          return this._isPathFound;
        });
      }
    }
  }

  private path: EvidencePath = {};
  private _isPathFound = false;
}
