import { createSlice } from '@reduxjs/toolkit';

import { UserInteractionState } from './type';

export const initialState: UserInteractionState = {
  mouseDragInProgress: false,
};

export const userInteractionSlice = createSlice({
  name: 'userInteraction',
  initialState,
  reducers: {
    setMouseDragInProgress: (state, action) => {
      state.mouseDragInProgress = action.payload;
    },
  },
});

export const { setMouseDragInProgress } = userInteractionSlice.actions;

export default userInteractionSlice.reducer;
