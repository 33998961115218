import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { combineReducers } from 'redux';

import { filterSlice } from './filterSlice';
import { notificationSlice } from './notificationSlice';
import { serviceSlice } from './serviceSlice';

export const reducer = combineReducers({
  [serviceSlice.name]: serviceSlice.reducer,
  [notificationSlice.name]: notificationSlice.reducer,
  [filterSlice.name]: filterSlice.reducer,
});

export type RootReduxState = { serviceBoard: ReturnType<typeof reducer> };

// ---------------------------------------------------------------------------------------------------------------------

const store = configureStore({
  reducer: {
    serviceBoard: reducer
  }
});

type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
