import './style.less';

import React from 'react';

import {
  Stack,
  Text
} from '@fluentui/react';
import classNames from 'classnames';

import {
  CardActionBar,
  CardActionBarProps
} from './CardActionBar';

import {
  CardTag,
  CardTagProps
} from './CardTag';

import { CardTitle } from './CardTitle';

import { FCC } from '../../models';

interface IProps {
  className?: string;
  title?: string;
  titleLabel?: JSX.Element;
  titleHorizontal?: boolean;
  description?: string;
  actionBarProps?: CardActionBarProps;
  tagProps?: CardTagProps;
}

export const BaseCardView: FCC<IProps> = (props) => {
  const { className, title, titleLabel, description, actionBarProps, titleHorizontal } = props;

  return (
    <Stack className={classNames('cardView', className)}>
      <Stack horizontal horizontalAlign='space-between'>
        <CardTitle
          horizontal={titleHorizontal}
          title={title}
          titleLabel={titleLabel}
        />
        { actionBarProps && <CardActionBar {...actionBarProps} /> }
      </Stack>
      <CardTag items={props.tagProps?.items || []}/>
      { description && (
        <Text className='cardView__description'>{ description }</Text>
      ) }
      { props.children }
    </Stack>
  );
};
