import React from 'react';

import {
  CommandBar,
  ICommandBarItemProps
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../../../../../common/Globalization/IntlEnum';

type IProps = {
  selectedCount: number;
  unselectAll: () => void;
  unloadAll: () => void;
  openOnboardPanel: () => void;
}

export const ScheduleListCommandBar: React.FC<IProps> = (props) => {
  const { selectedCount, unselectAll } = props;

  const items: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: intl.get(LocIds.AuditManager.AddNewSchedules),
      iconProps: {
        iconName: 'Add'
      },
      onClick: props.openOnboardPanel,
    }
  ];

  const farItems: ICommandBarItemProps[] = [];

  if (selectedCount) {
    farItems.push({
      key: 'unload',
      text: intl.get(LocIds.AuditManager.RemoveSchedules),
      iconProps: {
        iconName: 'DependencyRemove'
      },
      onClick: props.unloadAll,
    });

    farItems.push({
      key: 'number',
      text: intl.get(LocIds.Label.NumberSelected, {
        num: selectedCount
      }),
    });

    if (unselectAll) {
      farItems.push({
        key: 'unselectAll',
        iconProps: {
          iconName: 'Cancel'
        },
        iconOnly: true,
        onClick: unselectAll,
        title: intl.get(LocIds.Action.ClearSelection),
      });
    }
  }

  return (
    <CommandBar
      farItems={farItems}
      items={items}
      style={{
        width: '100%'
      }}
      onReduceData={() => undefined}
    />
  );
};
