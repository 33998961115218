import React, {
  useEffect,
  useRef
} from 'react';

import { IButtonStyles } from '@fluentui/react';

import { DefaultButton } from '@fluentui/react/lib/Button';

import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';

import intl from 'react-intl-universal';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import { useMenuSplitIndex } from './useMenuSplitIndex';

import { Text14 } from '../../../../common/css/fonts';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { IApplicationMenu } from '../../../../models';
import { useAppId } from '../../../../utils/react-hooks/useAppId';
import './Menus.less';
import { useNavMenu } from '../../../../utils/react-hooks/useNavMenu/useNavMenu';

interface IProps {
  menus: IApplicationMenu[];
}

export const Menus = ({ menus }: IProps): JSX.Element | null => {
  const { applicationId, pathname } = useAppId();
  const navigate = useNavigate();

  useEffect(() => {
    if (menus.length === 0 || !applicationId) {
      return;
    }

    if (pathname === `/application/${applicationId}` || pathname === `/application/${applicationId}/menu`) {
      navigate(`${pathname}/menu/${menus[0].MenuId}`);
    }
  }, [applicationId, pathname, menus]);

  // -------------------------------------------------------------------------------------------------------------------

  const navRef = useRef<HTMLDivElement>(null);
  const { showMenu, splitIndex } = useMenuSplitIndex(menus, navRef);

  const visibleMenu = menus.slice(0, splitIndex);

  const visibleMenuJSX = visibleMenu.map((menu: IApplicationMenu) => {
    const url = `/application/${applicationId}/menu/${menu.MenuId}`;

    const className = ({ isActive }: { isActive: boolean; }): string =>
      `text-14 tp-header__nav-link ${isActive ? 'tp-header__nav-link--active' : ''}`;

    return (
      <div className='tp-header__nav-item' key={menu.MenuId}>
        <NavLink
          className={className}
          title={menu.DisplayName}
          to={url}>
          { menu.DisplayName }
        </NavLink>
      </div>
    );
  });

  // -------------------------------------------------------------------------------------------------------------------

  const hiddenMenu = menus.slice(splitIndex);

  const list: IContextualMenuItem[] = hiddenMenu.map((item: IApplicationMenu) => {
    return {
      key: item.MenuId,
      text: item.DisplayName,
      href: `/application/${applicationId}/menu/${item.MenuId}`
    };
  });

  const menuProps = useNavMenu(list);

  const moreButtonStyles: IButtonStyles = {
    root: {
      border: 0,
      padding: 0,
      height: 'auto',
      minWidth: 'auto',
    },
    rootHovered: {
      backgroundColor: 'transparent'
    },
    rootFocused: {
      border: '1px solid var(--black)',
    },
    label: {
      ...Text14,
      color: 'var(--gray160)'
    },
    labelHovered: {
      color: 'var(--gray160)'
    },
  };

  // -------------------------------------------------------------------------------------------------------------------

  const showMenuClass = showMenu ? 'tp-header__nav-wrapper--visible' : '';

  return (
    <nav className='tp-header__nav'>
      <div className={`tp-header__nav-wrapper ${showMenuClass}`} ref={navRef}>
        { visibleMenuJSX }

        { hiddenMenu.length > 0 && (
          <div className='tp-header__nav-item' key='nav-more'>
            <DefaultButton
              className='tp-header__nav-link'
              menuProps={menuProps}
              styles={moreButtonStyles}
              text={intl.get(LocIds.Label.More)}
              title={intl.get(LocIds.Label.More)}
            />
          </div>
        ) }
      </div>
    </nav>
  );
};
