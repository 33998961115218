import { MyNetworkStatus } from '../../models';
import { post } from '../OdataApiService';

export const GET_MY_NETWORK_STATUS_API = '/data/GetMyNetworkStatus';

export const getMyNetworkStatusAsync = async (): Promise<MyNetworkStatus> => {
  return await post<Record<string, unknown>, MyNetworkStatus>(
    GET_MY_NETWORK_STATUS_API,
    {}
  );
};
