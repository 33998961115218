import React, { ReactNode } from 'react';

import {
  IMessageBarProps,
  MessageBar,
  MessageBarType
} from '@fluentui/react';
import intl from 'react-intl-universal';
import {
  Id,
  toast
} from 'react-toastify';

import { ToastOptions } from 'react-toastify/dist/types';

import { LocIds } from '../common/Globalization/IntlEnum';

type NotifierCall = (message: ReactNode, toastOptions?: ToastOptions, messageBarProps?: IMessageBarProps) => Id;

interface Notifier {
  info: NotifierCall;
  success: NotifierCall;
  warning: NotifierCall;
  error: NotifierCall;
  dismiss: (id?: Id | undefined) => void;
}

export const notify: Notifier = {
  info(message: ReactNode, toastOptions?: ToastOptions, messageBarProps?: IMessageBarProps): Id {
    return sendNotification(message,
      {
        ...toastOptions
      },
      {
        ...messageBarProps,
        messageBarType: MessageBarType.info
      });
  },
  success(message: ReactNode, toastOptions?: ToastOptions, messageBarProps?: IMessageBarProps): Id {
    return sendNotification(message,
      {
        ...toastOptions
      },
      {
        ...messageBarProps,
        messageBarType: MessageBarType.success
      });
  },
  warning(message: ReactNode, toastOptions?: ToastOptions, messageBarProps?: IMessageBarProps): Id {
    return sendNotification(message,
      {
        ...toastOptions
      },
      {
        ...messageBarProps,
        messageBarType: MessageBarType.warning
      });
  },
  error(message: ReactNode, toastOptions?: ToastOptions, messageBarProps?: IMessageBarProps): Id {
    return sendNotification(
      message,
      {
        ...toastOptions,
        autoClose: false
      },
      {
        ...messageBarProps,
        messageBarType: MessageBarType.error
      });
  },

  dismiss: toast.dismiss
};

function sendNotification(message: ReactNode, toastOptions?: ToastOptions, messageBarProps?: IMessageBarProps): Id {
  return toast(({ closeToast }) => {
    return (
      <MessageBar
        {...messageBarProps}
        dismissButtonAriaLabel={intl.get(LocIds.Label.Close)}
        onDismiss={closeToast}>
        { message }
      </MessageBar>
    );
  }, toastOptions);
}
