export class DashboardTreeNode {
  constructor(name: string, path: string) {
    this.name = name;
    this.reportPath = path;
  }

  public name = '';
  public reportPath = '';
  public isVisible = true;
  public isFiltered = true;
  public children: DashboardTreeNode[] = [];
  public get key(): string {
    return `${this.reportPath}`;
  }
}
