import React from 'react';

import { SideNavLink } from './types';

import { getMatchPositions } from '../../utils';

/**
 * Highlight matched keywords inside link name
 */
export const getLinkText = (
  text: string,
  re: RegExp | null,
): React.ReactElement => {
  const linkText = <>{ text }</>;

  if (typeof text !== 'string' || text.trim() === '') {
    return linkText;
  }

  const linkTextLowerCase = text.toLowerCase();

  if (re) {
    const positions = getMatchPositions(re, linkTextLowerCase);

    if (Array.isArray(positions)) {
      const linkTextElements: Array<JSX.Element | string> = [];
      let flag = 0;

      for (let i = 0; i < positions.length; i++) {
        if (positions[i][0] > flag) {
          linkTextElements.push(text.slice(flag, positions[i][0]));
        }

        linkTextElements.push(
          <span className='sideNav__linkHightlightText' key={positions[i][0]}>
            { text.slice(positions[i][0], positions[i][1]) }
          </span>,
        );
        flag = positions[i][1];
      }

      if (flag < linkTextLowerCase.length) {
        linkTextElements.push(text.slice(flag));
      }

      return <>{ linkTextElements.map((v: string | JSX.Element) => v) }</>;
    }
  }

  return linkText;
};

export const isLinkWithVisibleChildren = <T extends SideNavLink<T>>(
  link: SideNavLink<T>,
): boolean => {
  return (
    Array.isArray(link.children) &&
    link.children.length > 0 &&
    link.children.some((v: SideNavLink<T>) => v.isVisible)
  );
};

export const getExpandedLinkCount = <T extends SideNavLink<T>>(
  link: T,
  collapseLinksMap: Record<string, boolean>,
): number => {
  const identifier = getLinkIdentifier(link);

  if (!identifier) {
    return 0;
  }

  let count = 1;

  if (
    !collapseLinksMap[identifier] &&
    Array.isArray(link.children) &&
    link.isVisible
  ) {
    count += link.children
      .filter((v: SideNavLink<T>) => v.isVisible && v.name)
      .map((childLink: SideNavLink<T>) =>
        getExpandedLinkCount(childLink, collapseLinksMap),
      )
      .reduce((total: number, count: number) => {
        return total + count;
      }, 0);
  }

  return count;
};

/**
 * Return unique identifier for link
 */
export const getLinkIdentifier = <T extends SideNavLink<T>>(
  link: T,
): string | null => {
  if (!link) {
    return null;
  }

  return link.pathIdentifier || link.key || null;
};
