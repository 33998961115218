import { IBreadcrumbItem } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

import { DocumentMenuItem } from '../../../../../models/Document';

import {
  Application,
  Documentation
} from '../../../../TrustPortal/registerApplicationMenu';
import { toShownText } from '../helper';

export const useBreadcrumbItems = (menuItems: DocumentMenuItem[]):IBreadcrumbItem[] => {
  const navigate = useNavigate();
  return menuItems.map(item => ({
    text: toShownText(item.DisplayName),
    key: item.Uid,
    onClick: (_, item) => {
      if (item) {
        navigate(`/application/${Application.Documentation}/menu/${Documentation.Docs}/uid/${item.key}`);
      }
    }
  }));
};
