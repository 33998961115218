import { createAsyncThunk } from '@reduxjs/toolkit';

import { IDynamicPortalConfiguration } from '../../models';
import { getTrustPortalConfigurationAsync } from '../../services';


export const getPortalConfig = createAsyncThunk<IDynamicPortalConfiguration>(
  'config/getPortalConfig',
  async () => {
    return await getTrustPortalConfigurationAsync();
  });
