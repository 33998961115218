import React, { FC } from 'react';

import {
  ISpinButtonProps, SpinButton
} from '@fluentui/react';
import {
  Controller,
  UseControllerProps,
  useFormContext
} from 'react-hook-form';

import { ReadOnlyTextField } from '../../ReadOnlyControls';

interface IProps extends ISpinButtonProps, Pick<UseControllerProps, 'rules'> {
  name: string;
  readOnly?: boolean;
}

export const NumberFieldControl: FC<IProps> = (props: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => {
        return (props.readOnly ? <ReadOnlyTextField {...props} {...fieldProps}/> : (
          <SpinButton
            {...props}
            {...fieldProps}
            value={fieldProps.value ?? 0}
            onChange={(_e, value) => {
              fieldProps.onChange(value);

              if (props.onChange) {
                props.onChange(_e, value);
              }
            }}
          />
        )
        );
      }}
      rules={props.rules}
    />
  );
};
