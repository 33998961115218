import _ from 'lodash';

import { searchableMetaDataInEvidence } from './config';

import {
  Evidence,
  OriginalEvidence,
  EvidenceNode
} from '../../models';
import { escapeStringRegExp } from '../../utils/regexp';

export class FilterEvidenceService {
  static getSearchTextForEvidence(
    evidence: Evidence | OriginalEvidence,
  ): string {
    const texts = [
      ...searchableMetaDataInEvidence.map(
        (key) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (evidence as any)[key],
      ),
      ...evidence.Attachments.map((a) => a.DisplayName),
    ]
      .flat(1)
      .filter((v) => typeof v === 'string' || typeof v === 'number')
      .map((v) => _.toString(v).trim());

    return texts.map((v) => v.replace(/\s/g, ' ').trim()).join(' ');
  }

  static getSearchQueryReg(userQuery: string): RegExp | null {
    if (!userQuery || userQuery.trim() === '') {
      return null;
    }

    const regLiteral = userQuery
      .split(/\s/)
      .reduce((acc: string, query: string) => {
        return acc + `(?=.*${escapeStringRegExp(query)})`;
      }, '');
    return new RegExp(regLiteral, 'i');
  }

  static isEvidenceNodeMatched(
    evidenceNode: EvidenceNode,
    searchQueryReg: RegExp | null,
  ): boolean {
    if (!(searchQueryReg instanceof RegExp)) {
      return true;
    }

    if (
      !evidenceNode ||
      !evidenceNode.searchText ||
      evidenceNode.searchText.trim() === ''
    ) {
      return true;
    }

    return searchQueryReg.exec(evidenceNode.searchText) !== null;
  }

  static isEvidenceMatched(
    evidence: Evidence,
    searchQueryReg: RegExp | null,
  ): boolean {
    if (!(searchQueryReg instanceof RegExp)) {
      return true;
    }

    const searchText = FilterEvidenceService.getSearchTextForEvidence(evidence);
    return searchQueryReg.exec(searchText) !== null;
  }
}
