import React, { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import {
  Application,
  Documentation,
} from '../../TrustPortal/registerApplicationMenu';
import { PathRedirector } from '../pages/Docs/PathRedirector';

const Home = lazy(() => import('../pages/Home'));
const Docs = lazy(() => import('../pages/Docs'));
const DocContent = lazy(() => import('../pages/Docs/DocContent'));

export const routes: RouteObject[] = [
  {
    path: `${Application.Documentation}/`,
    element: <Home />,
    children: [
      {
        path: `menu/${Documentation.Docs}/`,
        element: <Docs />,
        children: [
          {
            path: 'uid/:docUid',
            element: <DocContent />
          },
          {
            path: '*',
            element: <PathRedirector />
          }
        ]
      }
    ],
  },
];
