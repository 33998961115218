import React from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';

import { CommonFilterProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  AuditEventCertification,
  Certification
} from '../../models';
import { MultiSelectDropdown } from '../Dropdown';
import { FilterFieldWrapper } from '../FilterFieldWrapper';

export function MultiSelectCertificationFilter<
  T extends Certification | AuditEventCertification,
>(
  props: CommonFilterProps<T[], T[]>,
): ReturnType<React.FC<CommonFilterProps<T[], T[]>>> {
  const { onChange, allOptions, selected } = props;

  return (
    <FilterFieldWrapper
      ariaLabel={intl.get(LocIds.Label.Certification)}
      isClearActive={!_.isEmpty(selected)}
      onClear={() => onChange([])}>
      <MultiSelectDropdown<T>
        disabled={_.isEmpty(allOptions)}
        items={allOptions}
        keyOf={(item: T) => String(item.CertificationFamilyId)}
        label={intl.get(LocIds.Label.Certification)}
        optionOf={(item: T) => ({
          key: String(item.CertificationFamilyId),
          text: (item as AuditEventCertification).Name,
        })}
        placeholder={intl.get(LocIds.Placeholder.SelectToFilter)}
        selectedItems={selected}
        onChange={onChange}
      />
    </FilterFieldWrapper>
  );
}
