import React, {
  useEffect, useState
} from 'react';

import {
  IRenderFunction, PanelType, Stack, TextField
} from '@fluentui/react';
import classNames from 'classnames';
import _ from 'lodash';
import intl from 'react-intl-universal';

import { BeneficiaryConfigurationGroup } from './BeneficiaryConfigurationGroup';
import { PersonPicker } from './PersonPicker';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  AzureAdMember,
  Certification,
  CertificationControl,
  Environment,
  Evidence,
  EvidenceRequestAttachment,
  EvidenceStateEnum,
  Nullable,
  OriginalEvidence,
  Service,
} from '../../models';
import {
  EntityApi, EvidenceApi, portalConfigurationService, uploadToUserTempStorageAndGetDownloadUrlAsync
} from '../../services';
import {
  constructEditOriginalEvidencePayload,
  decorateAttachmentsWithProtectionInfo,
  EditOriginalEvidenceEditableFields,
  getErrorMsg,
  isOriginalEvidenceUpdated,
  notify,
  validateOriginalEvidenceRequiredFields,
} from '../../utils';
import { EvidenceAttachmentsEditor } from '../AttachmentGroup';
import { SplitCardView } from '../CardView';
import { DatePicker } from '../DatePicker';
import { SingleSelectDropdown } from '../Dropdown';
import {
  CertificationControlDropdown, CertificationDropdown, EnvironmentDropdown, ServiceDropdown,
} from '../EntitySelection';
import {
  IRichTextFieldProps, RichTextField
} from '../RichText/RichTextField';
import {
  FormRow, FormSection
} from '../TrustLayout';
import { TrustPanel } from '../TrustPanel';

interface IProps {
  isOpen: boolean;
  /** Callback to be executed after the updating async action is requested successfully. */
  onUpdateSuccess: (evidence: Evidence) => void;
  onDismiss: () => void;
  evidence: OriginalEvidence;
  /** Title labels are different in Evidence Review page and Service page. */
  titleLabel?: JSX.Element;
  className?: string;
  isProtectionEnabled: boolean;
  protectionFileTypes: Set<string>;
}

export const TaskFullEditorPanel: React.FC<IProps> = (props) => {
  const { onUpdateSuccess, onDismiss, evidence, isProtectionEnabled, protectionFileTypes } = props;

  const enableBenefitingParty = portalConfigurationService.getPortalFlightings().enableBenefitingParty;

  // TODO: This value might be removed if we either
  //  1) return ComplianceAuditId with OriginalEvidenceRequest response, or
  //  2) make the Environment DB table indexed with ComplianceAuditId
  const [complianceAuditId, setComplianceAuditId] = useState<number>();

  useEffect(() => {
    if (evidence?.AuditEventGuid) {
      EntityApi.getComplianceAuditListAsync(evidence.AuditEventGuid).then((audits) => {
        setComplianceAuditId(audits[0]?.ComplianceAuditId);
      });
    }
  }, [evidence]);

  const [submitting, setSubmitting] = useState(false);

  const allStates = Object.values(EvidenceStateEnum);
  const [selectedState, setSelectedState] = useState<EvidenceStateEnum | undefined>(allStates.find((s) => s === evidence.State));

  const [title, setTitle] = useState<string>(evidence.Title);
  const [description, setDescription] = useState<string>(evidence.Description);

  const [attachments, setAttachments] = useState<EvidenceRequestAttachment[]>(evidence.Attachments);

  const [dueDate, setDueDate] = useState<Date | undefined>(new Date(evidence.DueDate));
  const [assignedTo, setAssignedTo] = useState<AzureAdMember | undefined>(evidence.AssignedTo ? {
    Id: evidence.AssignedTo,
    Alias: evidence.AssignedTo,
    DisplayName: evidence.AssignedTo,
    Mail: evidence.AssignedTo,
  } : undefined);

  // The GET API only includes the string name, we get the complete information until all certification options are fetched.
  // NB: Empty list [] and undefined represent different semantics.
  // Undefined: the initial state (all options have not been fetched).
  // Empty list: cleared by user, or cleared by the linked parent dropdown.
  const [selectedCert, setSelectedCert] = useState<Nullable<Certification>>();
  const [selectedControls, setSelectedControls] = useState<CertificationControl[]>();
  const [selectedEnv, setSelectedEnv] = useState<Nullable<Environment>>();
  const [selectedServices, setSelectedServices] = useState<Service[]>();

  const [selectedProviderService, setSelectedProviderService] = useState<Service>();
  const [selectedConsumerServices, setSelectedConsumerServices] = useState<Service[]>([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const isAdoManagedEvidence = !_.isNil(evidence?.ViewUrl);

  const getRequiredError = (label: string) => (value: string) => {
    if (value === '') {
      return intl.get(LocIds.Error.KeyRequired, {
        key: label
      });
    }
  };

  const statusDropdown = (
    <SingleSelectDropdown
      disabled={_.isUndefined(allStates)}
      items={allStates ?? []}
      keyOf={(item) => item}
      optionOf={(item) => ({
        key: item,
        text: item
      })}
      placeholder={_.isUndefined(allStates) ?
        intl.get(LocIds.Placeholder.LoadingOptions) :
        undefined}
      selectedItem={selectedState}
      styles={{
        root: {
          minWidth: 120
        }
      }}
      onChange={setSelectedState}
    />
  );

  const titleTextField = (
    <TextField
      label={intl.get(LocIds.Label.Title)}
      required
      value={title}
      onChange={(_, v) => setTitle(v ?? '')}
      onGetErrorMessage={getRequiredError(intl.get(LocIds.Label.Title))}
    />
  );

  const onRenderLabel: IRenderFunction<IRichTextFieldProps> = (props, defaultRenderer) => {
    return (
      <Stack styles={{
        root: {
          marginBottom: 10
        }
      }}>
        { defaultRenderer && defaultRenderer(props) }
      </Stack>
    );
  };

  const descTextField = (
    <RichTextField
      content={description}
      isRequired={false}
      label={intl.get(LocIds.Label.Description)}
      onChange={(text) => setDescription(
        text ?? ''
      )}
      onImageUpload={uploadToUserTempStorageAndGetDownloadUrlAsync}
      onRenderLabel={onRenderLabel}
    />
  );

  const attachmentsEditor = (
    <EvidenceAttachmentsEditor
      attachments={decorateAttachmentsWithProtectionInfo(attachments, isProtectionEnabled, protectionFileTypes)}
      onRemove={(attachment) =>
        setAttachments(
          attachments.filter(
            (a) =>
              (_.isEmpty(a.BlobGuid) || a.BlobGuid !== attachment.BlobGuid) &&
              (_.isEmpty(a.AttachmentGuid) || a.AttachmentGuid !== attachment.AttachmentGuid),
          ),
        )}
      onUpload={(attachment) => setAttachments([...attachments, attachment])}
    />
  );

  const fields = (
    <FormSection>
      { titleTextField }
      { descTextField }
      { attachmentsEditor }
    </FormSection>
  );

  const assignedToPicker = (
    <PersonPicker
      label={intl.get(LocIds.Label.AssignedTo)}
      labelTooltip={isAdoManagedEvidence ? intl.get(LocIds.AuditManager.OnlyAzureDevOpsAddedUsersSupported) : ''}
      required
      selected={assignedTo}
      onChange={setAssignedTo}
    />
  );

  const createdByPicker = (
    <PersonPicker
      disabled
      label={intl.get(LocIds.Label.CreatedBy)}
      required
      selected={evidence.CreatedBy ? {
        Id: evidence.CreatedBy,
        Alias: evidence.CreatedBy,
        DisplayName: evidence.CreatedBy,
        Mail: evidence.CreatedBy,
      } : undefined}
    />
  );

  const dueDatePicker = (
    <DatePicker
      isRequired
      label={intl.get(LocIds.Label.DueDate)}
      value={dueDate}
      onSelectDate={(date) => setDueDate(date ?? undefined)}
    />
  );

  const createdDatePicker = (
    <DatePicker
      disabled
      label={intl.get(LocIds.Label.CreatedDate)}
      value={new Date(evidence.CreatedDate)}
    />
  );

  const propertyFields = (
    <FormSection>
      <FormRow>
        { assignedToPicker }
        { dueDatePicker }
      </FormRow>
      <FormRow>
        { createdByPicker }
        { createdDatePicker }
      </FormRow>
    </FormSection>
  );

  const certDropdown = (
    <CertificationDropdown
      complianceAuditId={complianceAuditId}
      defaultValue={evidence.Certification}
      selected={selectedCert}
      onChange={(cert) => {
        setSelectedCert(cert);
        setSelectedControls(undefined);
      }}
    />
  );

  const certFamilyId = _.isNull(selectedCert) ? null : selectedCert?.CertificationFamilyId;

  const certControlDropdown = (
    <CertificationControlDropdown
      certificationFamilyId={certFamilyId}
      defaultValue={evidence.CertificationControl}
      required={true}
      selected={selectedControls}
      onChange={setSelectedControls}
    />
  );

  const envDropdown = (
    <EnvironmentDropdown
      certificationFamilyId={certFamilyId}
      complianceAuditId={complianceAuditId}
      defaultValue={evidence.EnvironmentName}
      selected={selectedEnv}
      onChange={setSelectedEnv}
    />
  );

  const serviceDropdown = (
    <ServiceDropdown
      certificationFamilyId={certFamilyId}
      complianceAuditId={complianceAuditId}
      defaultValues={evidence.Teams.map(t => ({
        name: t
      }))}
      environmentId={_.isNull(selectedEnv) ? null : selectedEnv?.EnvironmentId}
      errorMessage={!_.isNil(selectedServices) && selectedServices.length > 1 ?
        intl.get(LocIds.AuditManager.PleaseSelectAtMostOneService) : undefined}
      isMulti={true}
      selected={selectedServices}
      tooltip={intl.get(LocIds.AuditManager.SingleServiceSelectionTooltip)}
      onChange={setSelectedServices}
    />
  );

  const metaFields = (
    <FormSection>
      { propertyFields }
      { certDropdown }
      { certControlDropdown }
      { envDropdown }
      { serviceDropdown }
      { enableBenefitingParty && (
        <BeneficiaryConfigurationGroup
          certFamilyId={certFamilyId}
          complianceAuditId={complianceAuditId}
          defaultConsumers={evidence.ConsumerServices}
          defaultProvider={evidence.ProviderService}
          disabled={submitting}
          environmentId={_.isNull(selectedEnv) ? null : selectedEnv?.EnvironmentId}
          selectedConsumers={selectedConsumerServices}
          selectedProvider={selectedProviderService}
          onChangeConsumers={setSelectedConsumerServices}
          onChangeProvider={setSelectedProviderService}
        />
      ) }
    </FormSection>
  );

  const className = classNames('evidenceCard',
    props.className ?? (selectedState ? `evidenceCard--${selectedState.toLowerCase()}` : undefined));

  const editedFields: EditOriginalEvidenceEditableFields = {
    title,
    description,
    state: selectedState,
    attachments,
    dueDate,
    assignedTo,
    certification: selectedCert ?? undefined,
    certificationControls: selectedControls,
    environment: selectedEnv ?? undefined,
    services: selectedServices,
    providerService: selectedProviderService,
    consumerServices: selectedConsumerServices,
  };

  const canSave = validateOriginalEvidenceRequiredFields(editedFields);
  const hasChanges = isOriginalEvidenceUpdated(evidence, editedFields);

  const onSave = () => {
    if (canSave && hasChanges) {
      setSubmitting(true);
      EvidenceApi.editOriginalEvidenceAsync(constructEditOriginalEvidencePayload(evidence, editedFields, enableBenefitingParty))
        .then(() => {
          notify.success(intl.get(LocIds.AuditManager.OriginalEvidenceUpdatedSuccessfully, {
            title
          }));
          onUpdateSuccess(evidence);
          onDismiss();
        })
        .catch((e) => {
          getErrorMsg(e).then(setErrorMessage);
          setShowErrorMessage(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <TrustPanel
      errorMessage={errorMessage}
      headerText={intl.get(LocIds.Evidence.EditEvidenceRequest, {
        id: evidence ? `#${evidence.EvidenceId}` : undefined
      })}
      isOpen={props.isOpen}
      preventClose={hasChanges}
      primaryActionProps={{
        text: intl.get(LocIds.Action.Save),
        disabled: !(canSave && hasChanges) || submitting,
        onClick: onSave,
        loading: submitting
      }}
      showErrorDismiss={() => setShowErrorMessage(false)}
      showErrorMessage={showErrorMessage}
      showProcessingMessage={submitting}
      type={PanelType.large}
      onDismiss={onDismiss}>
      <FormSection>
        { evidence && (
          <SplitCardView
            className={className}
            status={statusDropdown}
            title={title}
            titleLabel={props.titleLabel}
            onRenderLeftBody={() => fields}
            onRenderRightBody={() => metaFields}
          />
        ) }
      </FormSection>
    </TrustPanel>
  );
};
