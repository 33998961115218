import { saveAs } from 'file-saver';


import { IDynamicPortalConfiguration } from '../../models';
import { parseFilenameFromContentDispositionHeader } from '../../utils/file/parseFilenameFromContentDispositionHeader';
import { post } from '../OdataApiService';
import { RestApiService } from '../RestApiService';
import { getCommonConfig } from '../utils/getCommonConfig';

export const GET_TRUST_PORTAL_CONFIGURATION_API = '/data/GetPortalConfiguration';

export const getTrustPortalConfigurationAsync = async (): Promise<IDynamicPortalConfiguration> => {
  const data = await post<Record<string, unknown>, IDynamicPortalConfiguration>(
    GET_TRUST_PORTAL_CONFIGURATION_API,
    {}
  );
  return data || {} as IDynamicPortalConfiguration;
};

export const uploadToUserTempStorageAsync = async (
  fileBlob: Blob,
): Promise<string> => {
  const config = {
    ...getCommonConfig(),
    contentType: 'application/octet-stream',
  };
  const data = await RestApiService.fetchPost(
    '/me/storage/upload',
    fileBlob,
    config,
  );
  const res = await data.json();
  return res.value;
};

export const uploadToUserTempStorageAndGetDownloadUrlAsync = async (
  fileBlob: Blob,
  fileName: string,
): Promise<string> => {
  const blobGuid = await uploadToUserTempStorageAsync(fileBlob);
  return `/me/storage/download/${blobGuid}/${fileName}`;
};

export const downloadBlobAsync = async (
  blobUrl: string,
  fileName: string,
): Promise<void> => {
  const config = {
    ...getCommonConfig()
  };
  const res = await RestApiService.fetchGet(blobUrl, config);

  const downloadName = parseFilenameFromContentDispositionHeader(res.headers.get('Content-Disposition')) || fileName;

  const blob = await res.blob();
  saveAs(blob, downloadName);
};

export const getBlobAsync = async (
  blobUrl: string
): Promise<Blob> => {
  const config = {
    ...getCommonConfig()
  };
  const res = await RestApiService.fetchGet(blobUrl, config);
  const blob = await res.blob();
  return blob;
};
