import React, { FC } from 'react';

import {
  Dropdown, IDropdownProps
} from '@fluentui/react';
import {
  Controller,
  UseControllerProps,
  useFormContext
} from 'react-hook-form';

import { normalizeMultiSelectDropdownValue } from './helper';

import { ReadOnlyDropdown } from '../../ReadOnlyControls';

interface IProps extends IDropdownProps, Pick<UseControllerProps, 'rules'> {
  name: string;
  readOnly?: boolean;
}

export const MultiSelectDropdownControl: FC<IProps> = (props: IProps) => {

  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        const normalizedValue = normalizeMultiSelectDropdownValue(value);
        return props.readOnly ? <ReadOnlyDropdown {...props} selectedKeys={normalizedValue}/> : (
          <Dropdown
            {...props}
            ariaLabel={props.label || props.ariaLabel}
            errorMessage={error && error.message}
            multiSelect
            selectedKeys={normalizedValue}
            onBlur={onBlur}
            onChange={(_e, option) => {
              if (option) {
                onChange(option.selected ?
                  [option.key, ...normalizedValue] :
                  normalizedValue.filter((v) => v !== option.key));
              }
            }}
          />
        );
      }}
      rules={props.rules}
    />
  );
};
