export enum CentroEnvType {
  Prod,
  CI,
  Local
}

export const CentroUrl = {
  Prod: 'https://admin.microsoft.com',
  CI: 'https://admin-ignite.microsoft.com',
  Local: 'https://localhost:443'
};

export function getCentroURl(env?: CentroEnvType): string {
  switch (env) {
  case CentroEnvType.Local: {
    return CentroUrl.Local;
  }
  case CentroEnvType.CI: {
    return CentroUrl.CI;
  }
  case CentroEnvType.Prod:
  default: {
    return CentroUrl.Prod;
  }
  }
}
