/**
 * This file contains Athena APIs for virtual groups.
 */
import {
  CustomRole,
  CustomRoleDetails,
  Nullable,
  ValueType
} from '../../models';
import { diffCustomRole, } from '../../utils';
import {
  get,
  post
} from '../OdataApiService';
import { buildODataQuery } from '../utils/odataQueryBuilder';

export const CREATE_CUSTOM_ROLE_API = '/data/CreateCustomRoleRequest';
export const UPDATE_CUSTOM_ROLE_API = '/data/UpdateCustomRoleRequest';
export const DELETE_CUSTOM_ROLE_API = '/data/DeleteCustomRoleRequest';

export const GET_CUSTOM_ROLES_API = '/data/CustomRole';
export const GET_CUSTOM_ROLE_DETAILS_API = '/data/GetCustomRoleDetailsRequest';

export namespace CustomRoleApi {
  export const getCustomRolesAsync = async (): Promise<CustomRole[]> => {
    const odataQuery = buildODataQuery({
      orderBy: 'Name asc',
    });

    const result = await get<CustomRole[]>(GET_CUSTOM_ROLES_API, {
      odataQuery,
    });

    return result;
  };

  export type GetCustomRoleDetailsRequestPayload = {
    virtualObjectId: string;
  };

  export const getCustomRoleDetailsAsync = async (
    virtualObjectId: string,
  ): Promise<CustomRoleDetails> => {
    const result = await post<
      GetCustomRoleDetailsRequestPayload,
      CustomRoleDetails
    >(
      GET_CUSTOM_ROLE_DETAILS_API,
      {
        virtualObjectId,
      }
    );

    return result;
  };

  export type CreateCustomRoleRequestPayload = {
    name: string;
    objectIds: string[];
  };

  export const createCustomRoleAsync = async (
    name: string,
    objectIds: string[],
  ): Promise<void> => {
    const payload: CreateCustomRoleRequestPayload = {
      name,
      objectIds,
    };

    await post<CreateCustomRoleRequestPayload, void>(
      CREATE_CUSTOM_ROLE_API,
      payload
    );
  };

  export type UpdateCustomRoleRequestPayload = {
    virtualObjectId: string;
    setName?: ValueType<string>;
    addObjectIds: Nullable<string[]>;
    removeObjectIds: Nullable<string[]>;
  };

  export const updateCustomRoleAsync = async (
    original: CustomRoleDetails,
    modified: CustomRoleDetails,
  ): Promise<void> => {
    const diffResult = diffCustomRole(original, modified);

    await post<UpdateCustomRoleRequestPayload, void>(
      UPDATE_CUSTOM_ROLE_API,
      {
        virtualObjectId: original.VirtualObjectId,
        ...diffResult,
      }
    );
  };

  export type DeleteCustomRoleRequestPayload = {
    virtualObjectId: string;
  };

  export const deleteCustomRoleAsync = async (
    virtualObjectId: string,
  ): Promise<void> => {
    await post<DeleteCustomRoleRequestPayload, void>(
      DELETE_CUSTOM_ROLE_API,
      {
        virtualObjectId,
      }
    );
  };
}
