import { AccountInfo } from '@azure/msal-browser';

import { portalConfigurationService } from '../../../services';
import {
  InAppFeedbackInit, InitialFeedbackData as defaultFeedbackData, getOcvTelemetryInfo, isGuestAccount
} from '../../../utils';

const feedbackData = defaultFeedbackData;

export const OpenFeedbackService = ( () => {
  const toogleHideContextualMenu = (featureArea: string, account: AccountInfo) => {
    if (shouldShowFeedback(account)) {
      updateFeedbackData(featureArea, account);
    }
  };

  const shouldShowFeedback = (account: AccountInfo) => {
    return !isGuestAccount(account) &&
      portalConfigurationService.getPortalFlightings().useOcvFeedback;
  };

  const updateFeedbackData = (featureArea: string, account: AccountInfo) => {
    updateFeedbackProps(account);
    updateFeedbackTelemetry(featureArea);
    InAppFeedbackInit.updateFeedbackObject(feedbackData);
  };

  const updateFeedbackProps = (account: AccountInfo) => {
    feedbackData.appId = portalConfigurationService.getOCVAppID();
    feedbackData.feedbackConfig!.isDisplayed = true;
    feedbackData.tenantId = account?.tenantId;
    feedbackData.userId = `a:${(account?.idTokenClaims as { oid: string }).oid}`;
    feedbackData.isProduction = portalConfigurationService.getPortalFlightings().isOcvProd;
  };

  const updateFeedbackTelemetry = (featureArea: string) => {
    feedbackData.telemetry = {
      ...feedbackData.telemetry,
      featureArea,
      ...getOcvTelemetryInfo()
    };
  };

  const configObject = {
    toogleHideContextualMenu,
    shouldShowFeedback,
    updateFeedbackData
  };
  return configObject;
})();
