import React from 'react';

import {
  Spinner, SpinnerSize
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { useSelector } from 'react-redux';

import { OverviewInformationRibbon } from './OverviewInformationRibbon';

import { LocIds } from '../../../../../common/Globalization/IntlEnum';
import { Banner } from '../../../../../components';
import {
  BuildEvidenceTrace,
  CompliantStatus,
  GatingTrace,
  PullRequestTrace
} from '../../../../../models/Gating';
import { GatingRecordState } from '../../../redux/gatingRecordState/type';
import { RootReduxState } from '../../../redux/reducer';
import { BuildEvidenceTable } from '../BuildEvidenceTable';
import { ParameterTable } from '../ParameterTable';
import { PullRequestTable } from '../PullRequestTable';
import {
  spinnerStyle,
  tabContentStyle
} from '../styles';

export const Overview: React.FC = () => {
  const gatingRecordState: GatingRecordState = useSelector<RootReduxState, GatingRecordState>(
    (state) => state.gatingTrace.gatingRecordState);

  const gatingRecord: GatingTrace | null = gatingRecordState.gatingRecord;

  const loading: boolean = gatingRecordState.loading;

  const loadingSpinner:JSX.Element = (
    <div className='center'>
      <Spinner
        label={intl.get(LocIds.Placeholder.Loading)}
        size={SpinnerSize.large}
        styles={spinnerStyle}
      />
    </div>
  );

  const noBuildExistenceMessage: JSX.Element = (
    <div style={tabContentStyle}>
      <Banner
        heading={intl.get(LocIds.Gating.NoRelatedBuilds)}
        iconName='empty'
        iconSize='large'
      />
    </div>
  );

  const buildExistenceTables = (
    pullRequestCompliantStatus: CompliantStatus,
    pullRequestTraces: PullRequestTrace[],
    buildEvidenceCompliantStatus: CompliantStatus,
    buildEvidenceTraces: BuildEvidenceTrace[]): JSX.Element => {
    return (
      <>
        <PullRequestTable
          PullRequestCompliantStatus={pullRequestCompliantStatus}
          PullRequestTraces={pullRequestTraces}
        />
        <BuildEvidenceTable
          BuildEvidenceCompliantStatus={buildEvidenceCompliantStatus}
          BuildEvidenceTraces={buildEvidenceTraces}
        />
      </>
    );
  };

  return (
    <div style={tabContentStyle}>
      { loading === true && loadingSpinner }
      { !loading && gatingRecord && (
        <>
          <OverviewInformationRibbon
            complianceStatus={gatingRecord.OverallCompliantStatus}
            gatingRecordId={gatingRecord.CorrelationId}
            triggeredAtTime={gatingRecord.CreatedTime}
          />
          <ParameterTable
            buildId={gatingRecord.BuildId}
            deployableService={gatingRecord.DeploymentUnitName}
            deploymentEnvironment={gatingRecord.DeploymentEnvironment}
            deploymentRing={gatingRecord.DeploymentRing}
            organization={gatingRecord.Organization}
            project={gatingRecord.Project}
          />
          { gatingRecord.BuildExistence ?
            buildExistenceTables(
              gatingRecord.PullRequestCompliantStatus,
              gatingRecord.PullRequestTraces,
              gatingRecord.BuildEvidenceCompliantStatus,
              gatingRecord.BuildEvidenceTraces) :
            noBuildExistenceMessage }
        </>
      ) }
    </div>
  );
};
