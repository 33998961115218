import { EvidenceTreeLeafNode } from './EvidenceTreeNodeBase';

import { EvidenceTreeNodeVisitor } from '../EvidenceTreeVisitor/EvidenceTreeNodeVisitor';

export class EvidenceNode extends EvidenceTreeLeafNode {
  constructor(evidenceId: string) {
    super();
    this.EvidenceId = evidenceId;
    this.identifierName = 'EvidenceGuid';
    this.identifierValue = evidenceId;
  }

  public EvidenceId: string;

  public get isVisible(): boolean {
    return false;
  }

  // searchText is set by SearchTextConstructorVisitor
  public searchText = '';

  public accept(visitor: EvidenceTreeNodeVisitor): void {
    visitor.visitEvidenceNode(this);
  }
}
