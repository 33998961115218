import React from 'react';

import { IButtonStyles } from '@fluentui/react';


import { DefaultButton } from '@fluentui/react/lib/Button';
import { IContextualMenuItem, } from '@fluentui/react/lib/ContextualMenu';

import intl from 'react-intl-universal';

import { Title14 } from '../../../../common/css/fonts';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { IApplication } from '../../../../models';
import { useNavMenu } from '../../../../utils/react-hooks/useNavMenu/useNavMenu';

interface IProps {
  applications: IApplication[];
}

export const ApplicationMenu: React.FC<IProps> = ({ applications = [] }: IProps) => {
  const list: IContextualMenuItem[] = applications.filter(item => item.ShowInMenu).map((item: IApplication) => {
    return {
      key: item.ApplicationId,
      text: item.DisplayName,
      href: `/application/${item.ApplicationId}`
    };
  });

  const menuProps = useNavMenu(list);

  const styles: IButtonStyles = {
    root: {
      border: 0
    },
    label: {
      ...Title14,
      whiteSpace: 'nowrap'
    }
  };

  return (
    <>
      { applications.length > 0 && (
        <DefaultButton
          menuProps={menuProps}
          styles={styles}
          text={intl.get(LocIds.Label.AllTrustPortal)}
          title={intl.get(LocIds.Label.AllTrustPortal)}
        />
      ) }
    </>
  );
};
