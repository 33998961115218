import { EvidenceRequestDestination } from './EvidenceRequestDestination';
import { ValueType } from './ValueType';

export interface ContinuousMonitoringSchedule {
  ContinuousMonitoringTaskId: number;
  AuditEventGuid: string;
  ShouldCreateTaskForEachControl: boolean;
  Title: string;
  Description: string;
  MonitoringStartDate: string;
  IsMonitoringEnabled: boolean;
  Environments: ContinuousMonitoringTaskEnvironment[];
  Certifications: ContinuousMonitoringCertificationFamily[];
  EvidenceRequestDestinations: EvidenceRequestDestination[];
}

export interface ContinuousMonitoringTaskEnvironment {
  EnvironmentId: number;
  EnvironmentName: string;
  MonitoringFrequency: MonitoringFrequency;
  LastTriggerDate: string | null;
  NextTriggerDate: string | null;
  Workloads: ContinuousMonitoringWorkload[];
}

interface ContinuousMonitoringWorkload {
  WorkloadId: number;
  WorkloadName: string;
  Services: ContinuousMonitoringService[];
}

interface ContinuousMonitoringService {
  ServiceId: number;
  ServiceName: string;
  ServiceTreeId: string;
  TaskGuid: string;
  AssignedTo: string;
}

interface ContinuousMonitoringCertificationFamily {
  CertificationFamilyId: number;
  Name: string;
  CertificationControls: ContinuousMonitoringCertificationControl[];
}

interface ContinuousMonitoringCertificationControl {
  CertificationControlId: number;
  Name: string;
}

export enum MonitoringFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  SemiAnnually = 'Semi-Annually',
  Annually = 'Annually',
  OneTime = 'OneTime',
}

export interface AddContinuousMonitoringSchedulePayload {
  auditEventGuid: string;
  title: string;
  description?: ValueType<string>;
  shouldCreateTaskForEachControl: boolean;
  monitoringStartDate: Date;
  isMonitoringEnabled: boolean;
  certificationControls: number[];
  environments: AddContinuousMonitoringEnvironment[];
  services: AddContinuousMonitoringService[];
  evidenceRequestDestinations: EvidenceRequestDestination[];
}

export interface UpdateContinuousMonitoringSchedulePayload {
  taskId: number;
  setTitle?: ValueType<string>;
  setDescription?: ValueType<string>;
  setShouldCreateTaskForEachControl?: ValueType<boolean>;
  setMonitoringStartDate?: ValueType<Date>;
  setMonitoringEnabled?: ValueType<boolean>;
  setEvidenceRequestDestinations?: ValueType<EvidenceRequestDestination[]>;
  addCertificationControls: number[];
  removeCertificationControls: number[];
  updateEnvironments: UpdateContinuousMonitoringEnvironment[];
  addServices: AddContinuousMonitoringService[];
  updateServices: UpdateContinuousMonitoringService[];
  removeServices: RemoveContinuousMonitoringService[];
}

export interface UpdateContinuousMonitoringEnvironment {
  EnvironmentId: number;
  SetMonitoringFrequency?: ValueType<MonitoringFrequency>;
}

export interface AddContinuousMonitoringEnvironment {
  EnvironmentId: number;
  MonitoringFrequency: MonitoringFrequency;
}

export interface AddContinuousMonitoringService {
  ServiceTreeId: string;
  EnvironmentId: number;
  AssignedTo: string;
  AssignedToAccountGuid: string;
}

export interface UpdateContinuousMonitoringService {
  ServiceTreeId: string;
  EnvironmentId: number;
  SetAssignedTo: ValueType<string>;
  SetAssignedToAccountGuid: ValueType<string>;
}

export interface RemoveContinuousMonitoringService {
  ServiceTreeId: string;
  EnvironmentId: number;
}

export interface OnboardServiceToConMonSchedulePayload {
  serviceTreeId: string;
  taskId: number;
  environmentId: number;
  assignedTo: string;
  assignedToAccountGuid: string;
}

export interface OffboardServiceFromConMonSchedulePayload {
  serviceTreeId: string;
  taskId: number;
  environmentId: number;
}
