import '../style.less';
import './CompactEvidenceCard.less';

import React, {
  useEffect,
  useRef,
  useState
} from 'react';

import {
  Stack,
  Text
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import _ from 'lodash';
import intl from 'react-intl-universal';

import { CompactEvidenceCardMetaInfo } from './CompactEvidenceCardMetaInfo';

import { LocIds } from '../../../common/Globalization/IntlEnum';
import { Evidence } from '../../../models';
import { EvidenceAttachments } from '../../AttachmentGroup';
import { AuditEventDetailPanelProps } from '../../AuditEventDetailPanel';
import {
  BaseCardView,
  CardActionBar,
  SplitCardViewProps,
  TwoColumnCardBodyLayout
} from '../../CardView';
import { CardShowMoreButton } from '../../CardView/CardShowMoreButton';
import {
  MetadataList,
  MetadataListItemProps
} from '../../MetadataList';
import { EvidenceAdoLink } from '../EvidenceAdoLink';

interface IProps
  extends Pick<
    SplitCardViewProps,
     'actionBarProps' | 'titleLabel'
  >, Pick<AuditEventDetailPanelProps, 'getAuditEventDetailsApi'> {
  evidence: Evidence;
  displayId?: boolean;
  adoUrl?: string | null;
  className?: string;
  auditTeamEmail?: string;
  shouldContactAuditEventPOC?: boolean;
}

const commonLeftFullWidthColumnStyle = {
  minWidth: 0,
  width: '100%'
};

export const CompactEvidenceCard: React.FC<IProps> = (props) => {
  const { evidence, displayId = false, getAuditEventDetailsApi, adoUrl, titleLabel } = props;
  const [showFullCard, { toggle: toggleShowFullCard }] = useBoolean(false);

  const [descriptionOverflow, setDescriptionOverflow] = useState<boolean>(false);

  const descriptionRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const resetOverflow = () => {
      setDescriptionOverflow(checkOverflow(descriptionRef.current));
    };

    resetOverflow();
    window.addEventListener('resize', resetOverflow);
    return () => {
      window.removeEventListener('resize', resetOverflow);
    };
  }, [descriptionRef.current]);


  const needShowMoreBtn = descriptionOverflow || !_.isEmpty(evidence.Attachments);
  const adoLinkJsx = (!_.isNil(adoUrl) && !_.isNil(evidence.BugId)) ? <EvidenceAdoLink adoUrl={adoUrl} bugId={evidence.BugId}/> : undefined;

  const metadataListJsx = (
    <CompactEvidenceCardMetaInfo
      adoLink={adoLinkJsx}
      evidence={evidence}
      getAuditEventDetailsApi={getAuditEventDetailsApi}
    />
  );

  const onRenderAttachmentList = () => {
    if (!showFullCard) {
      return (
        <Text styles={{
          root: {
            fontWeight: 600,
            color: 'var(--cyanBlue30)'
          }
        }}
        variant='small'>
          { intl.get(LocIds.Label.AttachmentsCount, {
            num: evidence.Attachments?.length || 0
          }) }
        </Text>
      );
    }

    return (
      <EvidenceAttachments
        Attachments={evidence.Attachments}
        auditTeamEmail={props.auditTeamEmail}
        shouldContactAuditEventPOC={props.shouldContactAuditEventPOC}
      />
    );
  };

  const attachmentMetadataItems: MetadataListItemProps[] = [
    {
      horizontal: !showFullCard,
      label: intl.get(LocIds.Label.Attachments),
      onRenderValue: onRenderAttachmentList
    }
  ];

  const attachmentJsx = !_.isEmpty(evidence.Attachments) ? <MetadataList items={attachmentMetadataItems}/> : null;

  const leftBodyJsx = (
    <TwoColumnCardBodyLayout
      leftStyle={{
        root: commonLeftFullWidthColumnStyle
      }}
      rootStyle={{
        root: {
          marginTop: 10
        }
      }}
      onRenderLeftBody={() => metadataListJsx}
      onRenderRightBody={() => attachmentJsx}
    />
  );

  const onRenderDescriptionValue = () => (
    <span
      ref={descriptionRef}
      style={{
        whiteSpace: showFullCard ? 'break-spaces' : 'nowrap',
        fontSize: 12,
        overflow: 'hidden'
      }}>
      { evidence.Description }
    </span>
  );

  const descriptionMetadataItems: MetadataListItemProps[] = [
    {
      horizontal: !showFullCard,
      label: intl.get(LocIds.Label.Description),
      onRenderValue: onRenderDescriptionValue
    }
  ];

  const rightBodyJsx = !_.isEmpty(evidence.Description) ? (
    <MetadataList
      items={descriptionMetadataItems}
    />
  ) : null;

  const bodyJsx = (
    <TwoColumnCardBodyLayout
      childrenGap={10}
      leftStyle={{
        root: commonLeftFullWidthColumnStyle
      }}
      onRenderLeftBody={() => leftBodyJsx}
      onRenderRightBody={() => rightBodyJsx}
    />
  );

  const actionJsx = (
    <Stack styles={{
      root: {
        height: '100%'
      }
    }}
    verticalAlign='space-between'>
      <CardActionBar {...props.actionBarProps} />
      { needShowMoreBtn && (
        <Stack horizontal horizontalAlign='end'>
          <CardShowMoreButton
            showFullCard={showFullCard}
            styles={{
              root: {
                fontSize: 12,
                color: 'var(--gray100)'
              }
            }}
            toggleShowFullCard={toggleShowFullCard}
          />
        </Stack>
      ) }
    </Stack>
  );

  return (
    <BaseCardView
      className={classNames('evidenceCard evidenceCard--compact', props.className)}
      title={displayId ? `${evidence.EvidenceId}: ${evidence.Title}` : evidence.Title}
      titleHorizontal={true}
      titleLabel={titleLabel}>
      <TwoColumnCardBodyLayout
        childrenGap={10}
        horizontalAlign={'space-between'}
        leftStyle={{
          root: {
            minWidth: 0,
            width: 'calc(100% - 110px)'
          }
        }}
        rightStyle={{
          root: {
            flex: '0 1 100px',
            marginLeft: 'auto'
          }
        }}
        wrap={false}
        onRenderLeftBody={() => bodyJsx}
        onRenderRightBody={() => actionJsx}
      />
    </BaseCardView>
  );
};

const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
  if (textContainer) {
    return (
      textContainer.offsetHeight < textContainer.scrollHeight ||
      textContainer.offsetWidth < textContainer.scrollWidth
    );
  }

  return false;
};
