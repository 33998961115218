import {
  useEffect, useState
} from 'react';

import { AuditEvent } from '../../models';
import { AuditEventApi } from '../../services/AthenaRestApiService/AuditEventApiService';

import { UseAsyncHookStatus } from './index';

export const useAuditEvent = (auditEventGuid: string | undefined): {
  auditEvent: AuditEvent | undefined,
  apiStatus: UseAsyncHookStatus
} => {
  const [auditEvent, setAuditEvent] = useState<AuditEvent>();
  const [apiStatus, setApiStatus] = useState<UseAsyncHookStatus>(UseAsyncHookStatus.Success);

  useEffect(() => {
    if (auditEventGuid) {
      setApiStatus(UseAsyncHookStatus.Pending);
      AuditEventApi.getAuditEventByGuidAsync(auditEventGuid)
        .then((response) => {
          setAuditEvent(response);
          setApiStatus(UseAsyncHookStatus.Success);
        })
        .catch(() => {
          setApiStatus(UseAsyncHookStatus.Fail);
        });
    }
  }, [auditEventGuid]);

  return {
    auditEvent,
    apiStatus
  };
};
