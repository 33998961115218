import { createSlice } from '@reduxjs/toolkit';

import {
  signIn,
  signOut
} from './thunk';

export interface IAuthState {
  isSignIn: boolean;
  isSignInFailed: boolean;
}

export const initialState: IAuthState = {
  isSignIn: false,
  isSignInFailed: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state: IAuthState) => {
      state.isSignIn = true;
      state.isSignInFailed = false;
    });
    builder.addCase(signIn.rejected, (state: IAuthState) => {
      state.isSignIn = false;
      state.isSignInFailed = true;
    });
    builder.addCase(signOut.fulfilled, (state: IAuthState) => {
      state.isSignIn = false;
    });
  },
});
