import './style.less';

import React, { useState } from 'react';

import {
  Link, Stack
} from '@fluentui/react';
import {
  isEmpty, isEqual
} from 'lodash';

import intl from 'react-intl-universal';

import { WrapTextArray } from './WrapTextArray';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { Evidence } from '../../models';
import {
  AuditEventDetailPanel, AuditEventDetailPanelProps
} from '../AuditEventDetailPanel';

import { MetaTree } from '../MetaTree';

interface EvidenceMetaInfoProps extends Pick<AuditEventDetailPanelProps, 'getAuditEventDetailsApi'> {
  evidence: Evidence;
  adoLink?: React.ReactElement;
  itemTruncated?: number;
}

export const EvidenceMetaInfo: React.FC<EvidenceMetaInfoProps> = ({
  evidence,
  adoLink,
  itemTruncated,
  getAuditEventDetailsApi
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const onDismiss = () => {
    setIsPanelOpen(false);
  };

  const consumerServices = evidence.ConsumerServices;
  const showConsumerServices = !isEmpty(consumerServices) && !isEqual(consumerServices, [evidence.ProviderService]);

  const consumerServiceJsx = showConsumerServices ? (
    <WrapTextArray
      texts={consumerServices.map(s => s.ServiceName)}
      tooltipText={intl.get(LocIds.Label.ConsumerServices)}
    />
  ) : undefined;

  const metaGroups = [
    {
      text: evidence.Certification,
      children: evidence.CertificationControl,
    },
    {
      text: evidence.EnvironmentName,
      children: evidence.Teams,
      trailingJsx: consumerServiceJsx,
    },
  ];

  return (
    <Stack className='evidenceMetaInfo'>
      <Link
        className='evidenceMetaInfo-linkButton'
        title={evidence.AuditEventTitle}
        onClick={() => setIsPanelOpen(true)}>
        { evidence.AuditEventTitle }
      </Link>
      { adoLink }
      <MetaTree
        groups={metaGroups}
        itemTruncated={itemTruncated}
      />
      { isPanelOpen && (
        <AuditEventDetailPanel
          auditEventGuid={evidence.AuditEventGuid}
          getAuditEventDetailsApi={getAuditEventDetailsApi}
          isOpen={isPanelOpen}
          onDismiss={onDismiss}
        />
      ) }
    </Stack>
  );
};
