import { FiscalYearTestCounts } from './FedRampCommon';

export enum DetailCycleStatus {
  Compliant = 'Compliant',
  NonCompliant = 'NonCompliant'
}

export enum DetailCycleStatusCssClass {
  Compliant = 'cycle-status-compliant',
  NonCompliant = 'cycle-status-not-compliant'
}

export enum DetailRenewalStatus {
  Initiated = 'Initiated',
  NotInitiated = 'Not Initiated',
  Renewed = 'Renewed',
  Completed = 'Completed'
}

export enum DetailRenewalStatusCssClass {
  Initiated = 'renewal-status-initiated',
  NotInitiated = 'renewal-status-not-initiated',
  Renewed = 'renewal-status-renewed'
}

export enum DetailTestResult {
  Pass = 'Pass',
  Fail = 'Fail'
}

export enum DetailAvailableAction {
  ReviewApprove = 'Review & Approve',
  ForceRenew = 'Force Renew',
  MarkComplete = 'Mark Complete',
  None = ''
}

export interface FedRampRenewalDetailListingItem extends Record<string, unknown> {
  ServiceTreeId: string;
  TestDate: string;
  TestTitle: string;
  TestResult: boolean;
  TestRegionMt: boolean;
  TestRegionGccMt: boolean;
  TestRegionGcc: boolean;
  TestRegionGccM: boolean;
  TestRegionGccHigh: boolean;
  TestRegionDoD: boolean;
  TestType: string;
  ProjectedGovCloudTestDate: string;
  TestLevel: number;
  BcdrLink?: string;
  RenewalStatus: DetailRenewalStatus;
  AdoLink?: string;
  S360Link?: string;
  Action?: DetailAvailableAction;
  IsForceRenewalTriggered: boolean;
  IsCompleted: boolean;
}

export type DetailFiscalYearTestCounts = FiscalYearTestCounts;
