/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props}
      fill='none'
      height='172'
      viewBox='0 0 196 172'
      width='196'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M194.128 122.027L190.451 124.165C191.515 123.545 192.177 122.23 192.183 120.358L195.859 118.22C195.854 120.092 195.197 121.406 194.128 122.027Z' fill='#D2D2D2'/>
      <path d='M192.323 71.5579L192.183 120.358C192.172 124.129 189.507 125.646 186.227 123.753L105.616 77.2056C102.341 75.3126 99.6866 70.7235 99.6971 66.9584L99.8379 18.1577L192.323 71.5579Z' fill='#FAFAFA'/>
      <path d='M192.323 71.5575L196 69.4194L195.859 118.22L192.183 120.353L192.323 71.5575Z' fill='#D2D2D2'/>
      <path d='M99.8379 18.1578L103.514 16.0249L196 69.4198L192.323 71.5579L99.8379 18.1578Z' fill='#B4B4B4'/>
      <path d='M101.569 2.53928L105.246 0.401192C106.325 -0.224591 107.822 -0.141153 109.47 0.813166L105.793 2.95126C104.145 2.00215 102.649 1.9135 101.569 2.53928Z' fill='#185A97'/>
      <path d='M192.324 59.7465L196 57.6084L195.964 69.2271L192.292 71.36L192.324 59.7465Z' fill='#185A97'/>
      <path d='M105.793 2.95645L109.47 0.818359L190.081 47.361L186.405 49.4991L105.793 2.95645Z' fill='#185A97'/>
      <path d='M186.405 49.4987C189.685 51.3917 192.334 55.9808 192.324 59.7459L192.292 71.3594L99.8015 17.9645L99.8328 6.35097C99.8432 2.58584 102.513 1.0631 105.788 2.95609L186.405 49.4987Z' fill='#0078D4'/>
      <path d='M186.405 49.4992L190.081 47.3611C193.361 49.2541 196.011 53.8432 196 57.6083L192.324 59.7464C192.334 55.9812 189.685 51.3922 186.405 49.4992Z' fill='#185A97'/>
      <path d='M144.279 138.644L140.603 140.782C141.667 140.161 142.329 138.847 142.334 136.975L146.011 134.837C146.005 136.709 145.348 138.023 144.279 138.644Z' fill='#D2D2D2'/>
      <path d='M142.475 88.1743L142.334 136.975C142.324 140.745 139.659 142.263 136.379 140.37L55.7675 93.822C52.4926 91.929 49.8382 87.3399 49.8487 83.5748L49.9895 34.7742L142.475 88.1743Z' fill='#FAFAFA'/>
      <path d='M142.475 88.174L146.152 86.0359L146.011 134.837L142.334 136.969L142.475 88.174Z' fill='#D2D2D2'/>
      <path d='M49.9895 34.7742L53.666 32.6414L146.151 86.0363L142.475 88.1744L49.9895 34.7742Z' fill='#D2D2D2'/>
      <path d='M51.7209 19.1555L55.3974 17.0174C56.4769 16.3916 57.9736 16.4751 59.6215 17.4294L55.945 19.5675C54.2971 18.6184 52.8004 18.5297 51.7209 19.1555Z' fill='#0078D4'/>
      <path d='M142.475 76.3627L146.152 74.2246L146.115 85.8433L142.444 87.9762L142.475 76.3627Z' fill='#0078D4'/>
      <path d='M55.9451 19.5727L59.6215 17.4346L140.233 63.9772L136.556 66.1153L55.9451 19.5727Z' fill='#0078D4'/>
      <path d='M136.556 66.1149C139.836 68.0079 142.486 72.597 142.475 76.3621L142.444 87.9756L49.9531 34.5807L49.9844 22.9672C49.9948 19.202 52.6649 17.6793 55.9398 19.5723L136.556 66.1149Z' fill='#139FE5'/>
      <path d='M136.556 66.1154L140.233 63.9773C143.513 65.8703 146.162 70.4594 146.152 74.2245L142.475 76.3626C142.486 72.5975 139.837 68.0084 136.556 66.1154Z' fill='#0078D4'/>
      <path d='M94.4306 155.26L90.7542 157.398C91.818 156.777 92.4803 155.463 92.4855 153.591L96.162 151.453C96.1567 153.325 95.4997 154.639 94.4306 155.26Z' fill='#D2D2D2'/>
      <path d='M92.6263 104.791L92.4855 153.591C92.4751 157.362 89.8103 158.879 86.5302 156.986L5.91889 110.438C2.64396 108.545 -0.0103991 103.956 3.0631e-05 100.191L0.14083 51.3904L92.6263 104.791Z' fill='#FAFAFA'/>
      <path d='M92.6264 104.79L96.3029 102.652L96.1621 151.453L92.4856 153.586L92.6264 104.79Z' fill='#D2D2D2'/>
      <path d='M0.140869 51.3904L3.81735 49.2576L96.3028 102.653L92.6264 104.791L0.140869 51.3904Z' fill='#E7E7E7'/>
      <path d='M1.87183 35.7717L5.5483 33.6336C6.62778 33.0078 8.12444 33.0913 9.77234 34.0456L6.09586 36.1837C4.44797 35.2346 2.9513 35.1459 1.87183 35.7717Z' fill='#2FA7DB'/>
      <path d='M92.6263 92.9789L96.3027 90.8408L96.2662 102.46L92.595 104.592L92.6263 92.9789Z' fill='#2FA7DB'/>
      <path d='M6.09595 36.1889L9.77242 34.0508L90.3837 80.5934L86.7072 82.7315L6.09595 36.1889Z' fill='#2FA7DB'/>
      <path d='M86.7072 82.7311C89.9873 84.6241 92.6364 89.2132 92.626 92.9783L92.5947 104.592L0.104004 51.1969L0.135294 39.5834C0.145723 35.8183 2.81573 34.2955 6.09066 36.1885L86.7072 82.7311Z' fill='#45CAF2'/>
      <path d='M86.7073 82.7316L90.3837 80.5935C93.6639 82.4865 96.313 87.0756 96.3026 90.8407L92.6261 92.9788C92.6366 89.2137 89.9874 84.6246 86.7073 82.7316Z' fill='#2FA7DB'/>
      <path d='M25.429 124.748C26.297 123.725 27.4475 122.75 28.8194 121.946C30.1914 121.139 31.6015 120.607 32.9174 120.347C35.4221 119.853 37.5831 120.35 38.479 121.846L56.2786 152.127L54.1405 151.896C52.341 151.7 50.7374 150.664 49.8211 149.104L49.62 148.763C50.8367 150.829 50.1443 153.492 48.0775 154.706C46.0107 155.92 43.3483 155.23 42.1341 153.163L42.3276 153.492C43.249 155.06 43.3763 156.971 42.6687 158.648L41.8363 160.618L24.0342 130.337C23.1637 128.828 23.7797 126.698 25.429 124.748Z' fill='#FBA919'/>
      <path d='M14.9445 114.875C13.5929 112.576 15.731 108.812 19.7195 106.465C23.7081 104.121 28.0377 104.083 29.3893 106.384C29.3944 106.391 29.3969 106.401 29.402 106.409L33.9327 114.116L19.4879 122.607L14.9445 114.875Z' fill='#F48789'/>
      <path d='M19.4906 122.605C18.139 120.306 20.2771 116.542 24.2657 114.195C28.2542 111.851 32.5839 111.813 33.9354 114.114C33.9405 114.121 33.9431 114.132 33.9482 114.139L38.4789 121.846L24.0341 130.337L19.4906 122.605Z' fill='#C8C8C8'/>
      <path d='M48.5027 148.936C50.7477 148.144 54.9272 149.826 56.2787 152.127L57.7423 171.148L41.8339 160.616C40.8896 159.007 40.2558 155.266 42.1623 153.212C42.9819 152.328 47.1893 149.399 48.5027 148.936Z' fill='#FFF5CF'/>
      <path d='M54.7996 166.142C55.8534 165.521 56.9988 165.513 57.3552 166.119L57.742 171.146L53.5371 168.361C53.1808 167.758 53.7459 166.763 54.7996 166.142Z' fill='black'/>
      <path d='M49.8211 149.101L32.9175 120.347C35.4221 119.853 37.5831 120.35 38.4791 121.846L56.2786 152.127L54.1406 151.896C52.341 151.7 50.7374 150.664 49.8211 149.101Z' fill='#E29626'/>
      <path d='M25.429 124.748L42.325 153.492C43.2464 155.06 43.3737 156.971 42.6661 158.649L41.8338 160.619L24.0342 130.337C23.1637 128.828 23.7797 126.698 25.429 124.748Z' fill='#D08829'/>
      <path d='M48.0774 154.706C46.0106 155.92 43.3482 155.23 42.134 153.163L25.429 124.748C26.2969 123.725 27.4474 122.75 28.8194 121.946C30.1913 121.139 31.6014 120.607 32.9174 120.347L49.6199 148.76C50.8366 150.829 50.1442 153.489 48.0774 154.706Z' fill='#FBA919'/>
    </svg>
  );
};
