import React from 'react';

import {
  ActionButton, DetailsList, SelectionMode, Stack, Text
} from '@fluentui/react';
import { isEmpty } from 'lodash';
import intl from 'react-intl-universal';

import {
  Outlet, useNavigate
} from 'react-router-dom';

import { getColumns } from './listBuilder';

import { ProposalApprovalActionGroup } from './ProposalApprovalActionGroup';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  Evidence, EvidenceProposalState, ProposedEvidence
} from '../../models';
import { UseAsyncHookStatus } from '../../utils';

type IProps = {
  items: ProposedEvidence[];
  producedEvidences: Evidence[];
  isAuditManager: boolean;
  setUpdateApiStatus: (value: UseAsyncHookStatus) => void;
  onReload: (evidence: ProposedEvidence) => void;
}

export const ProposedEvidenceDetailList: React.FC<IProps> = (props) => {
  const { items, producedEvidences, isAuditManager, setUpdateApiStatus, onReload } = props;

  const navigate = useNavigate();

  const openDetailPanel = (evidence: ProposedEvidence) => {
    navigate(`${evidence.ProposedEvidenceGuid}/discussions`);
  };

  const openGenerateEvidencePanel = (evidence: ProposedEvidence) => {
    navigate(`${evidence.ProposedEvidenceGuid}/generate`);
  };

  const onUpdateSuccess = (evidence: ProposedEvidence, state: EvidenceProposalState) => {
    onReload(evidence);

    if (state === EvidenceProposalState.Accepted) {
      openGenerateEvidencePanel(evidence);
    }
  };

  const columns = getColumns();

  const onRenderEvidenceProduced = (item: ProposedEvidence) => {
    const evidences = producedEvidences.filter((evidence) => evidence.SourceProposedEvidenceGuid === item.ProposedEvidenceGuid);
    return (!isEmpty(evidences) && (
      <Stack className='proposalList__cell'
        horizontal
        tokens={{
          // eslint-disable-next-line i18next/no-literal-string
          childrenGap: '2 12'
        }}
        verticalAlign='center'
        wrap>
        { isAuditManager ? evidences.map((evidence) => (
          <Text>{ evidence.EvidenceId }</Text>
        )) : (
          <Text>{ intl.get(LocIds.Label.Completed) }</Text>
        ) }
      </Stack>
    ));
  };

  const onRenderAction = (item: ProposedEvidence) => {
    return (
      <Stack>
        <Stack horizontal>
          <ActionButton
            iconProps={{
              iconName: 'PageRight'
            }}
            onClick={() => openDetailPanel(item)}>
            { intl.get(LocIds.Label.Detail) }
          </ActionButton>
          { isAuditManager && (
            <ProposalApprovalActionGroup
              proposedEvidence={item}
              setUpdatingStatus={setUpdateApiStatus}
              onSuccess={onUpdateSuccess}
            />
          ) }
        </Stack>
        { isAuditManager && item.State === EvidenceProposalState.Accepted &&
          isEmpty(producedEvidences.filter((evidence) => evidence.SourceProposedEvidenceGuid === item.ProposedEvidenceGuid)) &&
          (
            <ActionButton
              iconProps={{
                iconName: 'WebAppBuilderFragmentCreate'
              }}
              // TODO: API request is required to fetch all certification controls to get the ID of each control.
              onClick={() => openGenerateEvidencePanel(item)}>
              { intl.get(LocIds.Action.Create) }
            </ActionButton>
          ) }
      </Stack>

    );
  };

  columns.push({
    key: 'evidenceLink',
    name: isAuditManager ? intl.get(LocIds.Evidence.EvidenceProduced) : intl.get(LocIds.Label.Completed),
    minWidth: 150,
    onRender: onRenderEvidenceProduced
  }, {
    key: 'action',
    name: intl.get(LocIds.Label.Action),
    minWidth: 250,
    onRender: onRenderAction
  });

  return (
    <>
      <DetailsList
        columns={columns}
        items={items}
        selectionMode={SelectionMode.none}
      />
      <Outlet />
    </>
  );
};
