import { createAsyncThunk } from '@reduxjs/toolkit';

import { GetAccountSettingsResponse } from '../../models/AccountSetting';
import { AccountSettingApi } from '../../services/AthenaRestApiService/AccountSettingApiService';

export const getGlobalCoachmarkSetting = createAsyncThunk<GetAccountSettingsResponse[]>(
  'coachmarks/getGlobalCoachmarkSetting',
  async () => {
    return await AccountSettingApi.getAccountSettings(true);
  });
