import './style.less';

import React, {
  useEffect, useState
} from 'react';

import { IconButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import intl from 'react-intl-universal';


import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { NotificationPanel } from '../../../../components';
import { UserNotification } from '../../../../models';
import { NotificationApi } from '../../../../services';

export const NotificationBell: React.FC = () => {
  const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false);

  // Fetch notifications from server
  const [userNotifications, setUserNotifications] = useState<UserNotification[]>();
  const [isError, setIsError] = useState<boolean>(false);

  const fetchNotifications = () => {
    NotificationApi.getUserNotifications()
      .then(setUserNotifications)
      .catch(() => setIsError(true));
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const count = userNotifications?.length ?? 0;

  const bellIcon = (
    <div className='bell-icon'>
      { count > 0 && (
        <div className='bell-icon-badge'>
          { count }
        </div>
      ) }
      <IconButton
        ariaLabel={intl.get(LocIds.Application.Notification)}
        iconProps={{
          iconName: 'Ringer'
        }}
        name={intl.get(LocIds.Application.Notification)}
        title={intl.get(LocIds.Application.Notification)}
        onClick={showPanel}
      />
    </div>
  );

  return (
    <>
      { bellIcon }
      <NotificationPanel
        isError={isError}
        isOpen={isPanelOpen}
        notifications={userNotifications}
        onDismiss={hidePanel}
        onReload={fetchNotifications}
      />
    </>
  );
};
