import './styles.less';

import React from 'react';

import {
  DefaultButton,
  Dialog as FluentDialog,
  DialogFooter,
  DialogType,
  IButtonProps,
  PrimaryButton,
  IDialogProps,
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { FCC } from '../../models';

export interface DialogProps extends Pick<IDialogProps, 'styles'> {
  title: string;
  subText?: string;
  cancelText?: string;
  onDismiss: () => void;
  hidden?: boolean;
  primaryButtonProps?: IButtonProps;
}

export const Dialog: FCC<DialogProps> = (props) => {
  const {
    title,
    subText,
    onDismiss,
    hidden = false,
    primaryButtonProps,
    cancelText = intl.get(LocIds.Action.Cancel),
  } = props;

  const dialogContentProps = {
    type: DialogType.normal,
    title,
    subText,
  };

  return (
    <FluentDialog
      dialogContentProps={dialogContentProps}
      hidden={hidden}
      styles={props.styles}
      onDismiss={onDismiss}>
      <div className='dialog-content'>{ props.children }</div>
      <DialogFooter>
        { primaryButtonProps && <PrimaryButton {...primaryButtonProps} /> }
        <DefaultButton text={cancelText} onClick={onDismiss} />
      </DialogFooter>
    </FluentDialog>
  );
};
