/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg fill='none'
      height='100'
      viewBox='0 0 100 100'
      width='100'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M28.757 18.6759C28.4868 18.5631 28.2617 18.5744 28.0816 18.6759L25.5601 20.1433C25.7402 20.0417 25.9653 20.0304 26.2355 20.1433L49.7285 33.7447L52.25 32.2773L28.757 18.6759Z' fill='#0078D4'/>
      <path d='M56.9331 39.8735L53.0608 33.1575C52.8356 32.785 52.5542 32.4802 52.2503 32.2771L49.7288 33.7444C50.0327 33.9363 50.3254 34.2524 50.5393 34.6249L54.4116 41.3409C54.6255 41.7246 54.9182 42.0407 55.2221 42.2213L57.7436 40.7539C57.4397 40.5733 57.147 40.2573 56.9331 39.8735Z' fill='#0078D4'/>
      <path d='M74.2461 54.5363L74.1561 87.7778C74.1448 88.1277 74.021 88.3647 73.8184 88.4889L76.3399 87.0215C76.5425 86.9086 76.6663 86.6603 76.6776 86.3104L76.7676 53.0689L74.2461 54.5363Z' fill='#0078D4'/>
      <path d='M75.6081 51.0933L57.7435 40.754L55.2219 42.2213L73.0865 52.5606C73.7169 52.9218 74.2347 53.8022 74.246 54.5359L76.7675 53.0686C76.7562 52.3236 76.2384 51.4545 75.6081 51.0933Z' fill='#0078D4'/>
      <path d='M49.7288 33.7449C50.0328 33.9368 50.3254 34.2529 50.5393 34.6254L54.4117 41.3414C54.6255 41.7251 54.9182 42.0412 55.2222 42.2218L73.0868 52.5611C73.7171 52.9223 74.235 53.8027 74.2462 54.5364L74.1562 87.7779C74.1337 88.489 73.6158 88.7712 72.9855 88.3987L26.3934 61.4217C26.3371 61.3991 26.2921 61.3766 26.2358 61.3427C25.6842 61.0267 25.2002 60.2704 25.1214 59.5819C25.1101 59.5141 25.1101 59.4464 25.1101 59.39L25.2227 21.0014C25.1776 20.2677 25.6167 19.8953 26.2358 20.1549L49.7288 33.7449Z' fill='#243A5E'/>
      <path d='M68.6147 23.5802L68.4643 76.4451L31.0835 54.8623L31.234 2L68.6147 23.5802Z' fill='#F5F5F5'/>
      <path d='M55.8261 31.6296L55.8157 34.9319L37.8879 24.5815L37.8957 21.2766L55.8261 31.6296Z' fill='#D9D9D9'/>
      <path d='M58.0258 39.6143L58.0155 42.9191L37.8879 31.2976L37.8957 27.9954L58.0258 39.6143Z' fill='#D9D9D9'/>
      <path d='M50.4796 41.9541L50.4693 45.259L37.8879 37.9929L37.8957 34.6906L50.4796 41.9541Z' fill='#D9D9D9'/>
      <path d='M18.1299 26.7238C17.8147 26.5883 17.5896 26.5883 17.4657 26.7012L15.1018 28.97C15.2256 28.8571 15.4508 28.8571 15.766 28.9925L39.7768 42.3343L42.1407 40.0542L18.1299 26.7238Z' fill='#0078D4'/>
      <path d='M48.0961 46.7029L43.0756 40.8673C42.7942 40.54 42.4677 40.2691 42.1413 40.0772L39.7773 42.3572C40.0925 42.5491 40.4302 42.8313 40.7117 43.1474L45.7322 48.983C46.0136 49.3103 46.3401 49.5925 46.6553 49.7731L49.0192 47.5043C48.704 47.3124 48.3776 47.0302 48.0961 46.7029Z' fill='#32B0E7'/>
      <path d='M76.6091 86.3104L68.7743 59.3448L66.4104 61.6135L74.2452 88.5792C74.3014 88.8162 74.2789 88.9742 74.1776 89.0758L76.5416 86.8071C76.6429 86.7055 76.6766 86.5475 76.6091 86.3104Z' fill='#0078D4'/>
      <path d='M67.2776 57.6403L49.019 47.4929L46.655 49.7616L64.9136 59.909C65.5553 60.2702 66.2194 61.0265 66.4108 61.6247L68.7747 59.3559C68.5834 58.7464 67.9192 57.9902 67.2776 57.6403Z' fill='#0078D4'/>
      <path d='M39.777 42.3454C40.0922 42.5372 40.4299 42.8194 40.7114 43.1355L45.7319 48.9711C46.0133 49.2984 46.3398 49.5806 46.655 49.7612L64.9136 59.9086C65.5552 60.2698 66.2194 61.0261 66.4107 61.6243L74.2455 88.5899C74.3918 89.1656 74.0091 89.3349 73.3562 88.985L25.7398 62.5047C25.6947 62.4821 25.6384 62.4596 25.5822 62.4257C25.0193 62.1096 24.4115 61.455 24.1751 60.8793C24.1525 60.8229 24.13 60.7664 24.1188 60.7213L15.0683 29.5906C14.8544 28.981 15.1583 28.7327 15.7662 29.0036L39.777 42.3454Z' fill='#0078D4'/>
      <path d='M39.777 42.3454C40.0922 42.5372 40.4299 42.8194 40.7114 43.1355L45.7319 48.9711C46.0133 49.2984 46.3398 49.5806 46.655 49.7612L64.9136 59.9086C65.5552 60.2698 66.2194 61.0261 66.4107 61.6243L74.2455 88.5899C74.3918 89.1656 74.0091 89.3349 73.3562 88.985L25.7398 62.5047C25.6947 62.4821 25.6384 62.4596 25.5822 62.4257C25.0193 62.1096 24.4115 61.455 24.1751 60.8793C24.1525 60.8229 24.13 60.7664 24.1188 60.7213L15.0683 29.5906C14.8544 28.981 15.1583 28.7327 15.7662 29.0036L39.777 42.3454Z' fill='url(#paint0_linear_39841_88350)'/>
      <path d='M79.751 95.5187C79.6918 95.7227 78.2725 98.8981 77.6353 99.2686L80.6127 97.5379C81.2499 97.1674 81.7979 96.6689 82.2589 96.0437C82.3374 95.9363 82.4134 95.8253 82.487 95.7094C82.627 95.4873 82.7562 95.252 82.8744 95.0034C82.9686 94.8042 83.0555 94.5966 83.1363 94.3806C83.2075 94.1899 83.2727 93.992 83.3318 93.788L82.9903 93.4537L80.9386 94.6461L79.751 95.5187Z' fill='#323232'/>
      <path d='M68.7559 70.7603C68.5882 70.7482 68.4228 70.7434 68.2599 70.7434C68.0801 70.7434 67.9039 70.7519 67.7301 70.7675C67.7095 70.77 67.689 70.7712 67.6685 70.7736C67.4802 70.7929 67.2968 70.8194 67.117 70.8557C66.8599 70.9063 66.6101 70.9739 66.3687 71.0584C66.0669 71.1634 65.7773 71.295 65.4997 71.4531C65.4864 71.4603 65.4744 71.4676 65.4611 71.4748L62.4836 73.2055C62.4969 73.1983 66.0959 73.0015 66.2576 73.0233L67.3595 72.3715L69.4282 71.1694L69.2351 70.811C69.0733 70.7881 68.914 70.7712 68.7559 70.7603Z' fill='#323232'/>
      <path d='M77.3276 75.6833C77.1839 75.5288 77.0391 75.3767 76.8919 75.2258C76.7386 75.0689 76.5817 74.9157 76.4248 74.7636C76.2607 74.6067 76.0953 74.4522 75.9276 74.3014C75.9227 74.2965 75.9179 74.2929 75.9131 74.2881C75.7695 74.1589 75.6234 74.0322 75.4786 73.9091C75.3241 73.7788 75.1696 73.6532 75.0139 73.5301C74.8607 73.4095 74.7074 73.2924 74.5529 73.1789C74.4008 73.0667 74.2488 72.9593 74.0955 72.8531C73.9446 72.7493 73.7913 72.6491 73.6393 72.5513C73.4872 72.4548 73.3339 72.3606 73.1806 72.2701C73.1456 72.2496 73.1094 72.2279 73.0732 72.2074C72.9562 72.1398 72.8391 72.0746 72.722 72.0106C72.5736 71.931 72.4263 71.8537 72.2803 71.7813C72.1367 71.7101 71.993 71.6425 71.8506 71.5786C71.7094 71.5146 71.5682 71.4555 71.4282 71.3999C71.2882 71.3432 71.1482 71.2913 71.0094 71.2418C70.8682 71.1924 70.7294 71.1465 70.5894 71.1043C70.4687 71.0681 70.348 71.0343 70.2285 71.0029C70.208 70.9981 70.1875 70.992 70.167 70.9872C70.0125 70.9486 69.8592 70.9148 69.7072 70.8846C69.5479 70.8532 69.391 70.8279 69.2365 70.8062L66.259 72.5369C66.4147 72.5574 74.0243 77.7109 74.1594 77.8641L75.5619 78.0235L77.5508 76.8684L77.7403 76.1346C77.6027 75.9838 77.4664 75.8317 77.3276 75.6833Z' fill='#323232'/>
      <path d='M83.7326 90.189C83.7277 90.0165 83.7193 89.8439 83.7084 89.6701C83.6976 89.4987 83.6843 89.3273 83.6674 89.1547C83.6505 88.9846 83.6324 88.8132 83.6107 88.6406C83.589 88.468 83.5636 88.2954 83.5371 88.1216C83.5093 87.9466 83.4791 87.7704 83.4465 87.5942C83.4212 87.4626 83.3958 87.3299 83.3681 87.1983C83.3572 87.1476 83.3464 87.097 83.3355 87.0463C83.2921 86.8495 83.2462 86.6528 83.1979 86.4561C83.1496 86.263 83.0989 86.0687 83.0458 85.8756C82.9927 85.6861 82.9384 85.4978 82.8805 85.3083C82.8238 85.1249 82.7658 84.9426 82.7043 84.7592C82.6451 84.583 82.5836 84.4068 82.5196 84.2305C82.4581 84.0604 82.3941 83.8902 82.3277 83.7212C82.3169 83.6935 82.306 83.6657 82.2952 83.638C82.2384 83.4955 82.1817 83.3531 82.1226 83.2107C82.0514 83.0381 81.9777 82.8667 81.9017 82.6966L81.3332 82.5445L79.4927 83.6138L78.6841 84.4261C78.7601 84.5974 80.3617 95.4994 80.3569 95.5187L83.3343 93.788C83.3403 93.7687 83.3452 93.7494 83.3512 93.7301C83.3946 93.5768 83.4357 93.4199 83.4719 93.2594C83.5093 93.0941 83.5431 92.9251 83.5733 92.7537C83.601 92.5896 83.6264 92.423 83.6481 92.2529C83.6686 92.0887 83.6855 91.921 83.7 91.7508C83.7133 91.583 83.7241 91.4128 83.7314 91.2403C83.7386 91.0689 83.7422 90.8939 83.7434 90.7165C83.7434 90.7056 83.7434 90.6959 83.7434 90.6851C83.7398 90.5209 83.7374 90.3556 83.7326 90.189Z' fill='#323232'/>
      <path d='M81.6626 82.1752C81.5769 81.9918 81.4888 81.8095 81.3983 81.6273C81.2933 81.4173 81.1858 81.2085 81.076 80.9997C80.93 80.7257 80.7791 80.4542 80.6234 80.1826C80.6053 80.1524 80.5884 80.1211 80.5703 80.0909C80.2626 79.5623 79.9379 79.0481 79.5988 78.5485C79.4008 78.2564 79.1969 77.9691 78.9881 77.6867C78.8384 77.4852 78.6863 77.286 78.5319 77.0905C78.5101 77.0628 78.4872 77.0338 78.4655 77.006C78.3568 76.8696 78.247 76.7345 78.136 76.6005C78.0056 76.4436 77.8729 76.2891 77.7389 76.1371L74.7615 77.8678C74.8954 78.0198 78.8457 84.2523 78.9229 84.4261L81.9003 82.6954C81.8231 82.5216 81.7434 82.3478 81.6626 82.1752Z' fill='#323232'/>
      <path d='M80.3893 88.9289C80.6403 90.125 80.7659 91.2872 80.7622 92.4157C80.7586 93.5441 80.6283 94.5603 80.3712 95.4619C80.1141 96.3647 79.7509 97.1359 79.2814 97.7743C78.8119 98.4128 78.2495 98.9185 77.5965 99.2914C76.9424 99.6643 76.2194 99.89 75.4277 99.9697C74.636 100.049 73.7827 99.9721 72.8691 99.738C71.9554 99.5026 71.008 99.1019 70.0256 98.5347C69.0432 97.9674 68.0958 97.2747 67.1833 96.4552C66.2721 95.6357 65.4213 94.7293 64.6319 93.7372C63.8426 92.7452 63.1233 91.6855 62.474 90.5606C61.8247 89.4358 61.2671 88.2844 60.8036 87.1065C60.339 85.9285 59.9817 84.7409 59.7307 83.5449C59.4797 82.3501 59.3553 81.1878 59.359 80.0582C59.3626 78.9297 59.4929 77.9147 59.75 77.0119C60.0071 76.1104 60.3704 75.3391 60.8398 74.7007C61.3081 74.061 61.8705 73.5553 62.5247 73.1824C63.1776 72.8107 63.9006 72.5838 64.6935 72.5041C65.4852 72.4245 66.3385 72.5029 67.2521 72.7358C68.1658 72.9712 69.1144 73.3719 70.0968 73.9391C71.0792 74.5064 72.0254 75.2004 72.9379 76.0186C73.8491 76.8381 74.7 77.7445 75.4893 78.7366C76.2786 79.7299 76.9979 80.7883 77.6472 81.9132C78.2965 83.038 78.8529 84.1894 79.3176 85.3685C79.7798 86.5465 80.1383 87.7341 80.3893 88.9289Z' fill='#4B4B4B'/>
      <path d='M79.069 88.5898C79.2862 89.623 79.3948 90.6452 79.3924 91.6252C79.39 92.6052 79.2754 93.4983 79.0533 94.278C78.83 95.0625 78.5102 95.7396 78.1047 96.2935C77.6967 96.8499 77.2007 97.2953 76.6322 97.6199C76.0638 97.9446 75.4253 98.1437 74.7362 98.2137C74.0507 98.2825 73.3 98.2137 72.5058 98.011C71.7177 97.8082 70.8837 97.4558 70.0305 96.9622C69.176 96.4685 68.3444 95.8603 67.5563 95.153C66.7634 94.4397 66.0151 93.6432 65.332 92.7838C64.6452 91.9197 64.0104 90.9856 63.4456 90.0068C62.8807 89.028 62.3883 88.0117 61.984 86.9871C61.5821 85.9672 61.2683 84.9245 61.0499 83.8841C60.8326 82.851 60.724 81.83 60.7276 80.8487C60.73 79.8675 60.8435 78.9744 61.0668 78.196C61.29 77.4127 61.6099 76.7344 62.0166 76.1816C62.4245 75.6253 62.9206 75.1799 63.489 74.8552C64.0575 74.5318 64.6959 74.3315 65.3851 74.2615C66.0706 74.1927 66.8201 74.2614 67.6142 74.4642C68.4036 74.667 69.2375 75.0194 70.0908 75.513C70.9441 76.0054 71.7769 76.6149 72.5638 77.3222C73.3567 78.0342 74.105 78.8308 74.7881 79.6901C75.4748 80.5543 76.1096 81.4896 76.6745 82.4672C77.2393 83.446 77.7317 84.4634 78.136 85.4869C78.5355 86.5067 78.8505 87.5507 79.069 88.5898Z' fill='#E6E7E8'/>
      <path d='M70.0848 77.3994C70.3985 77.5805 70.6532 78.021 70.652 78.383L71.2627 86.5345L74.0712 92.6945C74.2836 93.082 74.2679 93.4887 74.0362 93.5997C73.9263 93.6528 73.7875 93.6299 73.6488 93.5502C73.4955 93.4621 73.3434 93.3028 73.2312 93.0989L69.3739 87.1174C69.293 87.01 69.2158 86.8929 69.1482 86.7674C68.9165 86.3438 68.8091 85.9117 68.8247 85.5545C68.8235 85.4362 68.832 85.3566 68.8441 85.2841L69.5103 77.7265C69.5139 77.3632 69.7698 77.2172 70.0848 77.3994Z' fill='#373637'/>
      <defs>
        <linearGradient gradientUnits='userSpaceOnUse'
          id='paint0_linear_39841_88350'
          x1='44.6383'
          x2='44.6383'
          y1='28.8946'
          y2='89.1664'>
          <stop stopColor='#69E2FF'/>
          <stop offset='1' stopColor='white' stopOpacity='0'/>
        </linearGradient>
      </defs>
    </svg>

  );
};
