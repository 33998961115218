import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';

import { OAuthService } from './OAuthService';

import { portalConfigurationService } from './PortalConfigurationService';

class ApiService {
  private oAuthService: typeof OAuthService | null = null;
  private portalConfigurationService: typeof portalConfigurationService | null = null;
  private axiosInstance: AxiosInstance | null = null;

  public configureService(_oAuthService: typeof OAuthService, _portalConfigurationService: typeof portalConfigurationService) {
    this.oAuthService = _oAuthService;
    this.portalConfigurationService = _portalConfigurationService;

    this.initialize();
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return await this.request({
      ...(config || {}),
      url,
      method: 'get'
    });
  }

  public async post<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return await this.request({
      ...(config || {}),
      url,
      method: 'post'
    });
  }

  public async put<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return await this.request({
      ...(config || {}),
      url,
      method: 'put'
    });
  }

  public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return await this.request({
      ...(config || {}),
      url,
      method: 'delete'
    });
  }

  private async request<T>(config: AxiosRequestConfig): Promise<T> {
    if (!this.axiosInstance) {
      this.initialize();
    }

    return await this.axiosInstance!.request(config);
  }

  private initialize() {
    this.axiosInstance = axios.create({
      baseURL: this.portalConfigurationService?.getAthenaBaseAddress()
    });

    this.axiosInstance.interceptors.request.use(async config => {
      await this.configAuth(config);
      return config;
    });

    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response.data;
      }
    );
  }

  private async configAuth(config: AxiosRequestConfig) {
    if (!this.oAuthService || !this.portalConfigurationService) {
      return;
    }

    const result = await this.oAuthService.acquireTokenSilent({
      scopes: this.portalConfigurationService.getAthenaApiScopes(),
    });

    if (result?.accessToken) {
      config.headers = {
        ...(config.headers || {}),
        'Authorization': `Bearer ${result.accessToken}`
      };
    }
  }
}

const instance = new ApiService();
export { instance as ApiService };
