import 'react-virtualized/styles.css';

import React from 'react';

import { Index } from 'react-virtualized';

import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import List, { ListRowRenderer } from 'react-virtualized/dist/es/List';

import {
  getExpandedLinkCount,
  getLinkIdentifier
} from './helper';
import { LinkItem } from './LinkItem';
import {
  SideNavLink,
  SideNavLinkListProps
} from './types';

export class LinkList<T extends SideNavLink<T>> extends React.Component<
  SideNavLinkListProps<T>
> {
  private _list: List | null = null;

  public componentDidUpdate(prevProps: SideNavLinkListProps<T>): void {
    if (this._list) {
      this._list.recomputeRowHeights();

      const searchQueryRemainSame =
        (!this.props.searchRegExp && !prevProps.searchRegExp) ||
        (this.props.searchRegExp &&
          prevProps.searchRegExp &&
          this.props.searchRegExp.toString() ===
            prevProps.searchRegExp.toString());

      if (!searchQueryRemainSame) {
        this._list.scrollToPosition(0);
      }
    }
  }

  public render(): React.ReactNode {
    const renderList = ({
      height,
      width,
    }: {
      height: number;
      width: number;
    }) => {
      return (
        // TODO Class Component -> Functional Component
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <List
          height={height}
          overscanRowCount={0}
          ref={(r) => (this._list = r)}
          rowCount={this.props.links.length}
          rowHeight={this.rowHeight}
          rowRenderer={this.rowRenderer}
          width={width}
        />
      );
    };
    // TODO
    // @ts-ignore
    return <AutoSizer>{ renderList }</AutoSizer>;
  }

  private rowHeight = ({ index }: Index) => {
    const { links, collapseLinksMap, linkHeight = 32 } = this.props;
    const count = getExpandedLinkCount(links[index], collapseLinksMap);
    return count * linkHeight;
  };

  private rowRenderer: ListRowRenderer = ({ index, style }) => {
    const link = this.props.links[index];

    if (!link) {
      return null;
    }

    const identifier = getLinkIdentifier(link);

    if (!identifier) {
      return null;
    }

    return (
      <div
        className='sideNav__listItemStyleWrapper'
        key={identifier}
        role='row'
        style={style}>
        <LinkItem
          canLinkWithChildrenCollapse={this.props.canLinkWithChildrenCollapse}
          collapseLinksMap={this.props.collapseLinksMap}
          getLinkClassName={this.props.getLinkClassName}
          isLinkActive={this.props.isLinkActive}
          link={link}
          searchRegExp={this.props.searchRegExp}
          toggleLinkCollapse={this.props.toggleLinkCollapse}
          onLinkClick={this.props.onLinkClick}
        />
      </div>
    );
  };
}
