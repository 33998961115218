export enum ServiceSummaryPropertyName {
    Name = 'Name',
    ServiceTreeId = 'ServiceTreeId',
    Division = 'Division',
    Organization = 'Organization',
    ServiceGroup = 'ServiceGroup',
    Classification = 'Classification',
    Cloud = 'Cloud',
    NumberOfPassingGovCloudTestsInCloud = 'NumberOfPassingGovCloudTestsInCloud',
    BcdrChampDisplayName = 'BcdrChampDisplayName',
    ComplianceStatus = 'ComplianceStatus',
    TestDate = 'TestDate',
    ProjectedGovCloudTestDate = 'ProjectedGovCloudTestDate',
    AssessmentDueDate = 'AssessmentDueDate',
    DueDateAging = 'DueDateAging',
    AssessmentStatus = 'AssessmentStatus',
    LifecycleStatus = 'LifecycleStatus',
    RenewalStatus = 'RenewalStatus',
    ValidForAudit = 'ValidForAudit',
    AuditScopes = 'AuditScopes',
}

export enum DiscoverySummaryPropertyName {
    Name = 'Name',
    ServiceTreeId = 'ServiceTreeId',
    Division = 'Division',
    Organization = 'Organization',
    ServiceGroup = 'ServiceGroup',
    LifecycleStatus = 'LifecycleStatus',
    FairfaxLifecycleStage = 'FairfaxLifecycleStage',
    GovTestsAvailable = 'GovTestsAvailable',
    KnownGovSubscriptions = 'KnownGovSubscriptions',
    Certifications = 'Certifications',
    MissingTests = 'MissingTests',
    IsAcknowledged = 'IsAcknowledged',
    AcknowledgedDate = 'AcknowledgedDate',
    Notes = 'Notes',
    AcknowledgedByDisplayName = 'AcknowledgedByDisplayName',
}

export enum FedHoundServiceTestSummaryPropertyName {
    ServiceTreeId = 'ServiceTreeId',
    ServiceTestGuid = 'ServiceTestGuid',
    IsCurrentCycleTest = 'IsCurrentCycleTest',
    TestDate = 'TestDate',
    Title = 'Title',
    IsSuccessful = 'IsSuccessful',
    Cloud = 'Cloud',
    Type = 'Type',
    ProjectedCloudTestDate = 'ProjectedCloudTestDate',
    Level = 'Level',
    RenewalStatus = 'RenewalStatus'
}

export enum GovSubscriptionMappingSummaryPropertyName {
    ServiceTreeId = 'ServiceTreeId',
    Name = 'Name',
    Division = 'Division',
    Organization = 'Organization',
    ServiceGroup = 'ServiceGroup',
    UpdatedDate = 'UpdatedDate',
    UpdatedByDisplayName = 'UpdatedByDisplayName',
    GovSubscriptions = 'GovSubscriptions',
}

export enum ServiceTestForGccMtReviewPropertyName {
    ServiceTestGuid = 'ServiceTestGuid',
    ServiceTreeId = 'ServiceTreeId',
    Name = 'Name',
    Division = 'Division',
    Organization = 'Organization',
    ServiceGroup = 'ServiceGroup',
    Classification = 'Classification',
    GovSubscriptions = 'GovSubscriptions',
    Certifications = 'Certifications',
    Cloud = 'Cloud',
    FailoverFromRegionName = 'FailoverFromRegionName',
    FailoverToRegionName = 'FailoverToRegionName',
    UpdatedDate = 'UpdatedDate',
    UpdatedByDisplayName = 'UpdatedByDisplayName',
}

export enum FedHoundServiceAuditScopesDetailsPropertyName {
    ServiceTreeId = 'ServiceTreeId',
    Name = 'Name',
    Division = 'Division',
    Organization = 'Organization',
    ServiceGroup = 'ServiceGroup',
    Certifications = 'Certifications',
    AuditScopes = 'AuditScopes',
    IsInISO = 'IsInISO',
    IsInSOC = 'IsInSOC',
    IsInFedRamp = 'IsInFedRamp',
    UpdatedDate = 'UpdatedDate',
    UpdatedByDisplayName = 'UpdatedByDisplayName',
}
