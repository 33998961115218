import React from 'react';

import { Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';

import { EvidenceAdoLink } from './EvidenceAdoLink';
import { EvidenceMetaInfo } from './EvidenceMetaInfo';

import { Evidence } from '../../models';
import { EvidenceAttachments } from '../AttachmentGroup';
import { AuditEventDetailPanelProps } from '../AuditEventDetailPanel';
import {
  SplitCardView, SplitCardViewProps
} from '../CardView';

export interface EvidenceBaseCardProps
  extends Pick<
    SplitCardViewProps,
    'tagProps' | 'status' | 'actionBarProps' | 'onRenderLeftBody' | 'titleLabel'
  >, Pick<AuditEventDetailPanelProps, 'getAuditEventDetailsApi'> {
  evidence: Evidence;
  displayId?: boolean;
  // original evidence displays an optional ADO link as part of meta info
  adoUrl?: string | null;
  className?: string;
  auditTeamEmail?: string;
  shouldContactAuditEventPOC?: boolean;
}

const ATTACHMENTS_TRUNCATE_COUNT = 3;
const META_INFO_TRUNCATE_COUNT = 3;

/**
 * This component is trying to extract general/common logic as much as possible between the four different types of evidence cards.
 */
export const BaseEvidenceCard: React.FC<EvidenceBaseCardProps> = (props) => {
  const { evidence, displayId = false, getAuditEventDetailsApi } = props;

  const [showAllFiles, { toggle: toggleShowAllFiles }] = useBoolean(false);

  const adoLink =
    props.adoUrl && evidence.BugId ? (
      <Stack.Item align='start'>
        <EvidenceAdoLink adoUrl={props.adoUrl} bugId={evidence.BugId}/>
      </Stack.Item>
    ) : undefined;

  // Default to show attachment group
  const onRenderLeftBody =
    props.onRenderLeftBody ??
    ((truncated: boolean) => (
      <EvidenceAttachments
        Attachments={evidence.Attachments}
        auditTeamEmail={props.auditTeamEmail}
        itemTruncated={truncated ? ATTACHMENTS_TRUNCATE_COUNT : undefined}
        shouldContactAuditEventPOC={props.shouldContactAuditEventPOC}
        showAll={showAllFiles}
        toggleShowAll={toggleShowAllFiles}
      />
    ));

  const onRenderMetaInfo = (truncated: boolean) => (
    <EvidenceMetaInfo
      adoLink={adoLink}
      evidence={evidence}
      getAuditEventDetailsApi={getAuditEventDetailsApi}
      itemTruncated={truncated ? META_INFO_TRUNCATE_COUNT : undefined}
    />
  );

  const cardOverflow =
    (!showAllFiles && evidence.Attachments.length > ATTACHMENTS_TRUNCATE_COUNT) ||
    evidence.CertificationControl.length > META_INFO_TRUNCATE_COUNT ||
    evidence.Teams.length > META_INFO_TRUNCATE_COUNT;

  return (
    <SplitCardView
      actionBarProps={props.actionBarProps}
      className={classNames('evidenceCard', props.className)}
      description={evidence.Description}
      overflow={cardOverflow}
      status={props.status}
      tagProps={props.tagProps}
      title={displayId ? `${evidence.EvidenceId}: ${evidence.Title}` : evidence.Title}
      titleLabel={props.titleLabel}
      onRenderLeftBody={onRenderLeftBody}
      onRenderRightBody={onRenderMetaInfo}
    />
  );
};
