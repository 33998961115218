import './style.less';

import React, { useState } from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';

import { MAX_FILE_SIZE } from './constant';
import { FileDropZone } from './FileDropZone';
import { UploadedFile } from './type';

import { LocIds } from '../../common/Globalization/IntlEnum';

export type FileUploaderProps = {
    allowedFileExtensions?: string[];
    maxFileSize?: number;
    sendUploadFileRequestAsync: (file: File) => Promise<string>;
    onFileUploaded: (file: UploadedFile) => void;
};

export const FileUploader: React.FC<FileUploaderProps> = (
  props: FileUploaderProps,
) => {
  const { maxFileSize = MAX_FILE_SIZE } = props;
  const [fileUploadError, setFileUploadError] = useState<string>('');
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const onUploadFile = async (file: File) => {

    if (!(_.isEmpty(props.allowedFileExtensions))) {
      const fileExtension = file.name.slice(file.name.lastIndexOf('.'));

      if (!props.allowedFileExtensions?.includes(fileExtension)) {
        setFileUploadError(intl.get(LocIds.Components.InvalidFileType, {
          allowedFileExtensions: `${props.allowedFileExtensions}`,
        }));
        setIsFileUploading(false);
        return;
      }
    }

    try {
      setIsFileUploading(true);

      const blobGuid = await props.sendUploadFileRequestAsync(file);
      props.onFileUploaded({
        file,
        blobGuid
      });

      setIsFileUploading(false);
    } catch (e) {
      setIsFileUploading(false);
      setFileUploadError(intl.get(LocIds.Components.FailedToUploaFile));
    }
  };

  return (
    <div className='fileUploader'>
      <FileDropZone
        fileUploadError={fileUploadError}
        isFileUploading={isFileUploading}
        maxFileSize={maxFileSize}
        setFileUploadError={setFileUploadError}
        onUploadFile={onUploadFile}
      />
    </div>
  );
};
