import { useAppId } from './useAppId';

import { Application } from '../../applications/TrustPortal/registerApplicationMenu';

export enum CommentCreatedByRole {
  Auditor = 'Auditor',
  AuditEventOwner = 'AuditEventOwner',
}

export const useUserCommentRole = () => {
  const { applicationId } = useAppId();

  if (applicationId === Application.AuditReview) {
    return CommentCreatedByRole.Auditor;
  } else if (applicationId === Application.AuditManager) {
    return CommentCreatedByRole.AuditEventOwner;
  }

  return null;
};
