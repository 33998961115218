import { createSlice } from '@reduxjs/toolkit';

import { getPortalConfig } from './thunk';

import { IDynamicPortalConfiguration } from '../../models';
import { applicationInsightsService } from '../../services';
import { UseAsyncHookStatus } from '../../utils';


export interface IConfigState extends IDynamicPortalConfiguration {
    apiStatus: UseAsyncHookStatus | undefined;
}

export const initialState: IConfigState = {
  Applications: [],
  MyAccountGuid: '',
  apiStatus: undefined,
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPortalConfig.pending, (state) => {
      state.apiStatus = UseAsyncHookStatus.Pending;
    });

    builder.addCase(getPortalConfig.fulfilled, (state, action) => {
      state.Applications = action.payload.Applications;
      state.MyAccountGuid = action.payload.MyAccountGuid;
      state.apiStatus = UseAsyncHookStatus.Success;
    });

    builder.addCase(getPortalConfig.rejected, (state, action) => {
      state.apiStatus = UseAsyncHookStatus.Fail;

      applicationInsightsService.trackException({
        message: action.error.message!,
        name: action.error.name!
      });
    });
  },
});
