/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props}
      fill='none'
      height='86'
      viewBox='0 0 98 86'
      width='98'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M37.7155 49.2371L36.2471 50.0911C36.672 49.8432 36.9365 49.3184 36.9386 48.5706L38.4069 47.7167C38.4049 48.4644 38.1424 48.9893 37.7155 49.2371Z' fill='#D2D2D2'/>
      <path d='M36.9949 29.0799L36.9386 48.5708C36.9344 50.0767 35.8701 50.6828 34.56 49.9267L2.364 31.3356C1.056 30.5795 -0.00415338 28.7467 1.2234e-05 27.2429L0.0562474 7.75195L36.9949 29.0799Z' fill='#FAFAFA'/>
      <path d='M36.995 29.0799L38.4633 28.226L38.4071 47.7169L36.9387 48.5688L36.995 29.0799Z' fill='#D2D2D2'/>
      <path d='M0.0563965 7.75205L1.52478 6.90018L38.4634 28.226L36.995 29.08L0.0563965 7.75205Z' fill='#D2D2D2'/>
      <path d='M0.747803 1.51419L2.21618 0.660236C2.64732 0.410299 3.24509 0.443624 3.90326 0.824778L2.43488 1.67873C1.77671 1.29966 1.17894 1.26425 0.747803 1.51419Z' fill='#0078D4'/>
      <path d='M36.9949 24.3627L38.4633 23.5088L38.4487 28.1493L36.9824 29.0011L36.9949 24.3627Z' fill='#005DB7'/>
      <path d='M2.43481 1.68076L3.90319 0.826813L36.0993 19.4159L34.6309 20.2698L2.43481 1.68076Z' fill='#0078D4'/>
      <path d='M34.6309 20.2697C35.941 21.0257 36.9991 22.8586 36.9949 24.3624L36.9824 29.0008L0.041748 7.67493L0.0542452 3.03651C0.0584108 1.53273 1.12481 0.924546 2.43281 1.68061L34.6309 20.2697Z' fill='#0078D4'/>
      <path d='M34.6309 20.2697C35.941 21.0257 36.9991 22.8586 36.9949 24.3624L36.9824 29.0008L0.041748 7.67493L0.0542452 3.03651C0.0584108 1.53273 1.12481 0.924546 2.43281 1.68061L34.6309 20.2697Z' fill='url(#paint0_linear_129467_180)'/>
      <path d='M34.6309 20.2699L36.0992 19.416C37.4093 20.172 38.4674 22.0049 38.4632 23.5087L36.9948 24.3627C36.999 22.8589 35.9409 21.026 34.6309 20.2699Z' fill='#0078D4'/>
      <path d='M97.2521 58.8558L95.7837 59.7098C96.2086 59.4619 96.4731 58.937 96.4752 58.1893L97.9436 57.3354C97.9415 58.0831 97.6791 58.608 97.2521 58.8558Z' fill='#D2D2D2'/>
      <path d='M96.5315 38.6986L96.4752 58.1895C96.4711 59.6954 95.4067 60.3014 94.0967 59.5454L61.9006 40.9542C60.5926 40.1982 59.5325 38.3653 59.5366 36.8615L59.5929 17.3706L96.5315 38.6986Z' fill='#FAFAFA'/>
      <path d='M96.5316 38.6986L98 37.8447L97.9437 57.3356L96.4753 58.1875L96.5316 38.6986Z' fill='#D2D2D2'/>
      <path d='M59.593 17.3707L61.0614 16.5189L98 37.8447L96.5316 38.6987L59.593 17.3707Z' fill='#D2D2D2'/>
      <path d='M60.2844 11.1329L61.7528 10.2789C62.1839 10.029 62.7817 10.0623 63.4399 10.4435L61.9715 11.2974C61.3133 10.9183 60.7156 10.8829 60.2844 11.1329Z' fill='#0078D4'/>
      <path d='M96.5315 33.9814L97.9999 33.1274L97.9853 37.7679L96.519 38.6198L96.5315 33.9814Z' fill='#005DB7'/>
      <path d='M61.9714 11.2994L63.4398 10.4455L95.6359 29.0346L94.1675 29.8885L61.9714 11.2994Z' fill='#0078D4'/>
      <path d='M94.1676 29.8884C95.4777 30.6444 96.5357 32.4773 96.5316 33.9811L96.5191 38.6195L59.5784 17.2936L59.5909 12.6552C59.595 11.1514 60.6614 10.5432 61.9694 11.2993L94.1676 29.8884Z' fill='#0078D4'/>
      <path d='M94.1676 29.8884C95.4777 30.6444 96.5357 32.4773 96.5316 33.9811L96.5191 38.6195L59.5784 17.2936L59.5909 12.6552C59.595 11.1514 60.6614 10.5432 61.9694 11.2993L94.1676 29.8884Z' fill='url(#paint1_linear_129467_180)'/>
      <path d='M94.1675 29.8886L95.6359 29.0347C96.9459 29.7907 98.004 31.6236 97.9999 33.1274L96.5315 33.9813C96.5356 32.4775 95.4776 30.6447 94.1675 29.8886Z' fill='#0078D4'/>
      <path d='M56.1237 84.7268L54.6553 85.5807C55.0802 85.3329 55.3447 84.808 55.3468 84.0603L56.8151 83.2064C56.8131 83.9541 56.5506 84.479 56.1237 84.7268Z' fill='#D2D2D2'/>
      <path d='M55.4031 64.5696L55.3468 84.0605C55.3427 85.5664 54.2783 86.1725 52.9683 85.4164L20.7722 66.8253C19.4642 66.0692 18.404 64.2363 18.4082 62.7326L18.4645 43.2416L55.4031 64.5696Z' fill='#FAFAFA'/>
      <path d='M55.4032 64.5696L56.8715 63.7157L56.8153 83.2066L55.3469 84.0585L55.4032 64.5696Z' fill='#D2D2D2'/>
      <path d='M18.4646 43.2417L19.933 42.3899L56.8716 63.7157L55.4032 64.5697L18.4646 43.2417Z' fill='#D2D2D2'/>
      <path d='M19.156 37.0039L20.6244 36.1499C21.0555 35.9 21.6533 35.9333 22.3115 36.3145L20.8431 37.1684C20.1849 36.7893 19.5871 36.7539 19.156 37.0039Z' fill='#0078D4'/>
      <path d='M55.4031 59.8524L56.8715 58.9984L56.8569 63.6389L55.3906 64.4908L55.4031 59.8524Z' fill='#005DB7'/>
      <path d='M20.843 37.1704L22.3114 36.3165L54.5075 54.9056L53.0391 55.7595L20.843 37.1704Z' fill='#0078D4'/>
      <path d='M53.0392 55.7594C54.3492 56.5154 55.4073 58.3483 55.4031 59.8521L55.3906 64.4905L18.45 43.1646L18.4624 38.5262C18.4666 37.0224 19.533 36.4142 20.841 37.1703L53.0392 55.7594Z' fill='#0078D4'/>
      <path d='M53.0392 55.7594C54.3492 56.5154 55.4073 58.3483 55.4031 59.8521L55.3906 64.4905L18.45 43.1646L18.4624 38.5262C18.4666 37.0224 19.533 36.4142 20.841 37.1703L53.0392 55.7594Z' fill='url(#paint2_linear_129467_180)'/>
      <path d='M53.0391 55.7596L54.5074 54.9057C55.8175 55.6617 56.8756 57.4946 56.8714 58.9984L55.403 59.8523C55.4072 58.3486 54.3491 56.5157 53.0391 55.7596Z' fill='#0078D4'/>
      <defs>
        <linearGradient gradientUnits='userSpaceOnUse'
          id='paint0_linear_129467_180'
          x1='18.5183'
          x2='18.5183'
          y1='1.35559'
          y2='29.0008'>
          <stop stopColor='#69E2FF'/>
          <stop offset='1' stopColor='white' stopOpacity='0'/>
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse'
          id='paint1_linear_129467_180'
          x1='78.055'
          x2='78.055'
          y1='10.9743'
          y2='38.6195'>
          <stop stopColor='#69E2FF'/>
          <stop offset='1' stopColor='white' stopOpacity='0'/>
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse'
          id='paint2_linear_129467_180'
          x1='36.9265'
          x2='36.9265'
          y1='36.8453'
          y2='64.4905'>
          <stop stopColor='#69E2FF'/>
          <stop offset='1' stopColor='white' stopOpacity='0'/>
        </linearGradient>
      </defs>
    </svg>
  );
};
