import {
  ReactPlugin, withAITracking
} from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights, ICustomProperties, IMetricTelemetry, SeverityLevel,
} from '@microsoft/applicationinsights-web';

import _ from 'lodash';

import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  IStaticPortalConfiguration, ProjectIdentifier
} from '../../models/PortalConfiguration';
import { isDevelopment } from '../../utils/env';

/**
 * Represents a service for interacting with Application Insights.
 * @see https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript
 */
class ApplicationInsightsService {
  private static instance: ApplicationInsightsService;

  private instrumentationKey: string | undefined = undefined;
  private appInsights: ApplicationInsights | null = null;
  private reactPlugin = new ReactPlugin();

  /**
   * Gets the singleton instance of the ApplicationInsightsService class.
   * @returns The singleton instance of the ApplicationInsightsService class.
   */
  public static getInstance(): ApplicationInsightsService {
    if (!ApplicationInsightsService.instance) {
      ApplicationInsightsService.instance = new ApplicationInsightsService();
    }

    return ApplicationInsightsService.instance;
  }

  public configureService(config: IStaticPortalConfiguration): void {
    this.instrumentationKey = config.instrumentationKey;
    this.initializeService();
  }

  /**
   * Tracks an exception in Application Insights.
   * @param error - The error object to track.
   * @param productIdentifier - The product identifier associated with the exception (default: ProjectIdentifier.Platform).
   */
  public trackException(error: Error, productIdentifier = ProjectIdentifier.Platform): void {
    if (!this.checkInitialize(this.appInsights)) {
      return;
    }

    this.appInsights.trackException({
      error,
      severityLevel: SeverityLevel.Error,
      properties: {
        productIdentifier,
      }
    });
  }

  public trackTrace(message: string): void {
    if (!this.checkInitialize(this.appInsights)) {
      return;
    }

    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Information,
    });
  }

  public trackEvent(eventName: string, customProperties?: ICustomProperties): void {
    if (!this.checkInitialize(this.appInsights)) {
      return;
    }

    this.appInsights.trackEvent({
      name: eventName
    }, customProperties);
  }

  public trackMetric(metric: IMetricTelemetry, customProperties?: ICustomProperties): void {
    if (!this.checkInitialize(this.appInsights)) {
      return;
    }

    this.appInsights.trackMetric(metric, customProperties);
  }

  private initializeService() {
    if (this.appInsights) {
      return;
    }

    if (_.isNil(this.instrumentationKey)) {
      if (isDevelopment) {
        throw new Error(intl.get(LocIds.Error.InitAppInsWithNullInstrumentationKey));
      }

      return;
    }

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.instrumentationKey,
        extensions: [this.reactPlugin],
        disableFetchTracking: false,
        enableUnhandledPromiseRejectionTracking: true,
        enableDebug: true,
        maxBatchInterval: 0,
        disableCookiesUsage: true,
        enableAutoRouteTracking: true,
      },
    });
    this.appInsights.loadAppInsights();
  }

  public withAITracking<P>(Component: React.ComponentType<P>) : React.ComponentClass<P> {
    return withAITracking(this.reactPlugin, Component, Component.name);
  }

  private checkInitialize(appInsights: ApplicationInsights | null) : appInsights is ApplicationInsights {
    if (_.isNil(appInsights)) {
      if (isDevelopment) {
        throw new Error(intl.get(LocIds.Error.AppInsNotInitialized));
      }

      // Application Insights initialization error won't block rendering in production
      return false;
    }

    return true;
  }
}

export const applicationInsightsService = ApplicationInsightsService.getInstance();
