/* eslint-disable @typescript-eslint/no-namespace */
/**
 * This file contains Athena Rest Apis for evidences
 */

import _ from 'lodash';
import { OdataQuery } from 'odata';
import buildQuery, { Filter } from 'odata-query';

import {
  AssignedToMeFilterSelectedState,
  AttachmentState,
  CommonFilterState,
  ConclusionStateEnum,
  CreateEvidenceRequestsPayload,
  CreatePublishedEvidenceCommentRequestPayload,
  DraftEvidence,
  DraftEvidenceRequestsFilterState,
  EditOriginalEvidenceRequestPayload,
  Evidence,
  EvidenceCreationDetail,
  EvidenceCreationProcess,
  EvidenceRequestCreationStatus,
  EvidenceRequestSourceType,
  EvidenceRequestType,
  EvidenceStateEnum,
  GetEvidenceCreationStatusRequestPayload,
  ImportStateEnum,
  ModificationStateEnum,
  OriginalEvidence,
  OriginalEvidenceAssignedOptions,
  OriginalEvidenceRequestsFilterState,
  PublishDraftEvidenceResponse,
  PublishedEvidence,
  PublishedEvidenceRequestsFilterState,
  ReuseEvidenceRequestsFilterState,
  ServiceWorkspaceEvidenceSortKey,
  SortOption,
  SubmitOriginalEvidenceRequestPayload,
  UpdateDraftEvidenceRequestDetails
} from '../../models';
import {
  filterEvidenceGroup, getCreatedDateFilterOdataValue, getDueDateFilterOdataValue
} from '../../utils';

import {
  get, post
} from '../OdataApiService';
import {
  buildAnyInArrayQuery, buildFilterString, buildODataQuery, convertToGuidObject
} from '../utils/odataQueryBuilder';

export const GET_PUBLISHED_EVIDENCE_REQUESTS_API = '/data/PublishedEvidenceRequest';
export const GET_PUBLISHED_EVIDENCE_REQUESTS_FOR_REUSE_API = '/data/PublishedEvidenceRequestForReuse';
export const GET_ORIGINAL_EVIDENCE_REQUEST_API = '/data/OriginalEvidenceRequest';
export const DRAFT_EVIDENCE_REQUEST_URI = '/data/DraftEvidenceRequest';
export const UPDATE_DRAFT_EVIDENCE_REQUEST_URI = '/data/UpdateDraftEvidenceRequestDetails';

export const EDIT_ORIGINAL_EVIDENCE_REQUEST_URI = 'data/EditOriginalEvidenceRequest';
export const SUBMIT_ORIGINAL_EVIDENCE_REQUEST_URI = 'data/SubmitOriginalEvidenceRequest';

export const RESOLVE_DRAFT_EVIDENCE_CONFLICT_REQUEST_URI = '/data/ResolveDraftEvidenceConflictRequest';

export const REUSE_EVIDENCE_REQUEST_URI = '/data/ReuseEvidenceRequest';

export const CONCLUDE_PUBLISHED_EVIDENCE_REQUEST_API = '/data/ConcludePublishedEvidenceRequest';

export const POST_RESYNC_EVIDENCE_REQUESTS_API = '/data/ResyncPublishedEvidenceRequests';
export const POST_RESYNC_ADO_WORK_ITEMS_REQUEST_API = '/data/ResyncAdoWorkItemsRequest';
export const POST_PUBLISH_DRAFT_EVIDENCE_REQUEST_API = '/data/PublishDraftEvidenceRequest';
export const POST_UNPUBLISH_PUBLISHED_EVIDENCE_REQUEST_API = '/data/UnpublishPublishedEvidenceRequest';
export const POST_IMPORT_REUSE_EVIDENCE_REQUEST_API = '/data/ImportReuseEvidenceRequests';
export const POST_IMPORT_PUBLISHED_EVIDENCE_REQUEST_API = '/data/ImportPublishedEvidenceRequests';

export const GET_EVIDENCE_REQUEST_CREATION_STATUS_API = '/data/GetEvidenceCreationStatusRequest';

export const CREATE_EVIDENCE_REQUESTS_API = 'data/CreateEvidenceRequests';

export const GET_EVIDENCE_CREATION_DETAIL_API = 'data/EvidenceCreationDetail';

export const CREATE_PUBLISHED_EVIDENCE_COMMENT_API = 'data/CreatePublishedEvidenceCommentRequest';

export namespace EvidenceApi {
  export const getOriginalEvidencesByAssigneeAsync = async (
    filter: AssignedToMeFilterSelectedState,
    sortBy: SortOption<ServiceWorkspaceEvidenceSortKey>,
    extraOptions: OriginalEvidenceAssignedOptions
  ): Promise<OriginalEvidence[]> => {
    const { services, workloads } = extraOptions;

    // If the user owns no services or workloads, always return an empty array
    if ((!_.isNil(services) && _.isEmpty(services)) ||
      (!_.isNil(workloads) && _.isEmpty(workloads))) {
      return [];
    }

    const odataQuery = getOdataQueryOfOriginalEvidenceRequestsByAssignedToMeFilter(filter, sortBy, extraOptions);

    return await get(GET_ORIGINAL_EVIDENCE_REQUEST_API + odataQuery);
  };

  export interface PublishedEvidencesFilter {
    auditEventGuid?: string;
    auditEventGuids?: string[];
    conclusionStates?: ConclusionStateEnum[];
    startDate?: Date | null;
    endDate?: Date | null;
    environments?: string[];
    certifications?: string[];
    certificationControls?: string[]
    services?: string[],
    attachmentState?: AttachmentState,
    isProposalOnly?: boolean;
  }

  export const getPublishedEvidencesForReuseAsync = async (filters?: PublishedEvidencesFilter): Promise<Evidence[]> => {
    return getEvidencesAsync(GET_PUBLISHED_EVIDENCE_REQUESTS_FOR_REUSE_API, filters);
  };

   export const getPublishedEvidencesAsync = async (filters?: PublishedEvidencesFilter): Promise<Evidence[]> => {
     return getEvidencesAsync(GET_PUBLISHED_EVIDENCE_REQUESTS_API, filters);
   };

  const getEvidencesAsync = async (publishUrl: string, filters?: PublishedEvidencesFilter): Promise<Evidence[]> => {
    let filter: Filter | undefined = undefined;

    if (!_.isEmpty(filters)) {
      filter = {};

      const ReviewedDate: { ge?: Date; le?: Date } = {};

      if (filters?.startDate) {
        ReviewedDate.ge = filters.startDate;
      }

      if (filters?.endDate) {
        ReviewedDate.le = filters.endDate;
      }

      filter.ReviewedDate = ReviewedDate;

      if (filters?.certifications && !_.isEmpty(filters.certifications)) {
        filter.Certification = {
          in: [...filters.certifications]
        };
      }

      if (filters?.environments && !_.isEmpty(filters.environments)) {
        filter.EnvironmentName = {
          in: [...filters.environments]
        };
      }

      if (filters?.certificationControls && !_.isEmpty(filters.certificationControls)) {
        filter.CertificationControl = buildAnyInArrayQuery(
          filters?.certificationControls,
        );
      }

      if (filters?.services && !_.isEmpty(filters.services)) {
        filter.Teams = buildAnyInArrayQuery(
          filters?.services,
        );
      }

      if (!_.isNil(filters?.attachmentState)) {
        if (filters?.attachmentState === AttachmentState.WithAttachments) {
          filter.AttachmentsCount = {
            ne: 0
          };
        } else {
          filter.AttachmentsCount = {
            eq: 0
          };
        }
      }

      if (!_.isUndefined(filters?.auditEventGuid)) {
        filter.AuditEventGuid = {
          value: filters?.auditEventGuid,
          type: 'guid'
        };
      } else if (filters?.auditEventGuids && !_.isEmpty(filters.auditEventGuids)) {
        filter.AuditEventGuid = {
          in: [...filters.auditEventGuids]
        };
      }

      if (_.isArray(filters?.conclusionStates)) {
        filter.ConclusionState = {
          in: [...filters?.conclusionStates as ConclusionStateEnum[]]
        };
      }

      if (filters?.isProposalOnly) {
        filter.SourceProposedEvidenceGuid = {
          ne: null
        };
      }
    }

    const odataQuery = buildODataQuery({
      filter
    });

    const config = {
      odataQuery,
    };
    const data = await get<Evidence[]>(
      publishUrl,
      config,
    ).then((evidences) => {
      evidences.forEach((e) => {
        e.EvidenceRequestType = EvidenceRequestType.Published;
      });
      return evidences;
    });
    return data || [];
  };

  interface ConcludePublishedEvidenceRequestData {
    auditEventGuid: string;
    publishedEvidenceGuid: string;
    publishedEvidenceVersionGuid: string;
    state: ConclusionStateEnum;
  }

  /**
   * OData schema
   *   Entity: Evidence
   *   Action: ConcludePublishedEvidenceRequest
   *   Parameters: { auditEventGuid: string, evidenceGuid: string, publishedEvidenceVersionGuid: string, conclusionState: ConclusionStateEnum }
   *
   * POST http://{athenaDomain}/data/ConcludePublishedEvidenceRequest
   * Content-Type: application/json
   * {"auditEventGuid":"b570ea33-48ec-4980-8a41-d82db56a7fdd",
   *  "publishedEvidenceGuid":"10940501-88ba-4fb3-a5c2-9ff722f83dfe",
   *  "publishedEvidenceVersionGuid":"43773377-ae86-45f5-afe0-7ca344d01a79",
   *  "state":"Accepted"}
   * */
  export const acceptEvidencesAsync = async (
    evidences: Evidence[],
  ): Promise<Evidence[]> => {
    const promises = evidences.map(async (evidence) => {
      const requestData = {
        auditEventGuid: evidence.AuditEventGuid,
        publishedEvidenceGuid: evidence.EvidenceGuid,
        publishedEvidenceVersionGuid: evidence.VersionGuid ?? '',
        state: ConclusionStateEnum.Accepted,
      };
      return await post<ConcludePublishedEvidenceRequestData, Evidence>(
        CONCLUDE_PUBLISHED_EVIDENCE_REQUEST_API,
        requestData
      );
    });

    const result = Promise.all(promises);
    return result;
  };

  /**
   * OData schema
   *   Entity: Evidence
   *   Action: ConcludePublishedEvidenceRequest
   *   Parameters: { auditEventGuid: string, evidenceGuid: string, publishedEvidenceVersionGuid: string, conclusionState: ConclusionStateEnum }
   *
   * POST http://{athenaDomain}/data/ConcludePublishedEvidenceRequest
   * Content-Type: application/json
   * {"auditEventGuid":"b570ea33-48ec-4980-8a41-d82db56a7fdd",
   *  "publishedEvidenceGuid":"10940501-88ba-4fb3-a5c2-9ff722f83dfe",
   *  "publishedEvidenceVersionGuid":"43773377-ae86-45f5-afe0-7ca344d01a79",
   *  "state":"Rejected"}
   * */
  export const rejectEvidencesAsync = async (
    evidences: Evidence[],
  ): Promise<Evidence[]> => {
    const promises = evidences.map(async (evidence) => {
      const requestData = {
        auditEventGuid: evidence.AuditEventGuid,
        publishedEvidenceGuid: evidence.EvidenceGuid,
        publishedEvidenceVersionGuid: evidence.VersionGuid ?? '',
        state: ConclusionStateEnum.Rejected,
      };
      return await post<ConcludePublishedEvidenceRequestData, Evidence>(
        CONCLUDE_PUBLISHED_EVIDENCE_REQUEST_API,
        requestData
      );
    });

    const result = Promise.all(promises);
    return result;
  };

  export const updateEvidenceAsync = async (payload: UpdateDraftEvidenceRequestDetails): Promise<void> => {
    return await post<UpdateDraftEvidenceRequestDetails, void>(
      UPDATE_DRAFT_EVIDENCE_REQUEST_URI,
      payload
    );
  };

  export const editOriginalEvidenceAsync = async (payload: EditOriginalEvidenceRequestPayload): Promise<void> => {
    return await post<EditOriginalEvidenceRequestPayload, void>(
      EDIT_ORIGINAL_EVIDENCE_REQUEST_URI,
      payload
    );
  };

  export const submitOriginalEvidenceAsync = async (payload: SubmitOriginalEvidenceRequestPayload): Promise<void> => {
    return await post<SubmitOriginalEvidenceRequestPayload, void>(
      SUBMIT_ORIGINAL_EVIDENCE_REQUEST_URI,
      payload
    );
  };

  export const updateOriginalEvidenceStateAsync = async (
    auditEventGuid: string,
    evidenceRequestGuid: string,
    state: EvidenceStateEnum
  ): Promise<void> => {
    return await post<EditOriginalEvidenceRequestPayload, void>(
      EDIT_ORIGINAL_EVIDENCE_REQUEST_URI,
      {
        auditEventGuid,
        evidenceRequestGuid,
        setEvidenceStateType: {
          Value: state
        },
        setCertificationControlIds: null,
        setBenefitingTeamsIds: null,
        addAttachments: null,
        updateAttachments: null,
        removeAttachments: null,
        updateConsumerServiceGuidsActions: null,
      },
    );
  };

  export enum ResolveMode {
    Ignore = 'Ignore',
    Overwrite = 'Overwrite',
  }

  export const resolveDraftEvidenceConflict = async (
    Id: string,
    resolveMode: ResolveMode,
  ): Promise<void> => {
    return await post<
      { draftEvidenceId: string; resolveMode: ResolveMode },
      void
    >(
      RESOLVE_DRAFT_EVIDENCE_CONFLICT_REQUEST_URI,
      {
        draftEvidenceId: Id,
        resolveMode
      },
    );
  };

  export const getEvidenceRequestAsync = async (
    auditEventGuid: string,
    queryTypes: EvidenceRequestType[] = [EvidenceRequestType.Original, EvidenceRequestType.Draft, EvidenceRequestType.Published],
    commonFilters: CommonFilterState,
    originalFilters?: OriginalEvidenceRequestsFilterState,
    draftFilters?: DraftEvidenceRequestsFilterState,
    publishedFilters?: PublishedEvidenceRequestsFilterState,
  ): Promise<Evidence[]> => {
    const promises = queryTypes.map((type) => {
      // TODO [Sheffield]: This API will be replaced by a single API instead of three in the near future, so these filter builders might be moved to the backend.
      //  All the filter values will be used as params of that unified API, so we pass the filter state as a whole. Will add OData filter builders for draft/published evidence requests here.
      if (type === EvidenceRequestType.Original) {
        const originalAndReuseEvidencePromises = [];

        const originalOdataQuery =
          getOdataQueryOfOriginalEvidenceRequestsByFilter(auditEventGuid, commonFilters, originalFilters);

        const originalConfig = {
          odataQuery: originalOdataQuery,
        };

        const originalRequest = get<Evidence[]>(GET_ORIGINAL_EVIDENCE_REQUEST_API, originalConfig).then((evidences) => {
          evidences.forEach((e) => {
            e.EvidenceRequestType = EvidenceRequestType.Original;
          });
          return evidences;
        });

        originalAndReuseEvidencePromises.push(originalRequest);

        // If the user selects "hide reuse evidence" or filters out "approved evidence", we should hide.
        const requireReuse = !originalFilters?.hideReuseEvidence && (_.isEmpty(originalFilters?.workItemStates) ||
          originalFilters?.workItemStates?.some(s => s.ShortName === EvidenceStateEnum.Approved));

        if (requireReuse) {
          const reuseFilters = originalFilters && {
            sourceTypes: originalFilters.sourceTypes,
          };
          const reuseOdataQuery = getOdataQueryOfOriginalEvidenceRequestsByFilter(auditEventGuid, commonFilters, reuseFilters);
          const reuseFilter = `${reuseOdataQuery.$filter?.replace(
            'AuditEventGuid', 'ReusingAuditEventGuid'
          )} and ImportState eq 'Imported'`;
          const reuseConfig = {
            odataQuery: {
              $filter: reuseFilter,
            },
          };
          const reuseRequest = get<Evidence[]>(REUSE_EVIDENCE_REQUEST_URI, reuseConfig).then((evidences) => {
            evidences.forEach((e) => {
              e.EvidenceRequestType = EvidenceRequestType.Reuse;
            });
            return evidences;
          });

          originalAndReuseEvidencePromises.push(reuseRequest);
        }

        return Promise.all(originalAndReuseEvidencePromises).then(_.flatten);
      } else if (type === EvidenceRequestType.Draft) {
        const draftOdataQuery = getOdataQueryOfDraftEvidenceRequestsByFilter(auditEventGuid, commonFilters, draftFilters);
        const draftConfig = {
          odataQuery: draftOdataQuery,
        };
        return get<Evidence[]>(DRAFT_EVIDENCE_REQUEST_URI, draftConfig).then(
          (evidences) => {
            evidences.forEach((e) => {
              e.EvidenceRequestType = EvidenceRequestType.Draft;
            });
            return evidences;
          },
        );
      } else if (type === EvidenceRequestType.Published) {
        const publishedOdataQuery =
          getOdataQueryOfPublishedEvidenceRequestsByFilter(auditEventGuid, commonFilters, publishedFilters);

        const publishedConfig = {
          odataQuery: publishedOdataQuery,
        };

        return get<Evidence[]>(GET_PUBLISHED_EVIDENCE_REQUESTS_API, publishedConfig).then((evidences) => {
          evidences.forEach((e) => {
            e.EvidenceRequestType = EvidenceRequestType.Published;
          });
          return evidences;
        });
      }

      return [];
    });

    return Promise.all(promises)
      .then(_.flatten)
      .then((res) => filterEvidenceGroup(res, queryTypes, originalFilters, draftFilters, publishedFilters));
  };

  export const getReuseEvidenceRequestAsync = async (
    auditEventGuid: string,
    reuseFilters: ReuseEvidenceRequestsFilterState,
  ): Promise<Evidence[]> => {
    const odataQuery = buildODataQuery({
      filter: {
        ReusingAuditEventGuid: {
          eq: {
            type: 'guid',
            value: auditEventGuid
          },
        },
        AuditEventGuid: _.isNil(reuseFilters.auditEventGuid) ? undefined : {
          eq: {
            type: 'guid',
            value: reuseFilters.auditEventGuid,
          },
        },
        Certification: reuseFilters.certification?.ShortName,
        EnvironmentName:
          !_.isEmpty(reuseFilters.environments) ? {
            in: [...reuseFilters.environments.map((e) => e.Name)]
          } : undefined,
        CertificationControl:
          !_.isEmpty(reuseFilters.certificationControls) ?
            buildAnyInArrayQuery(reuseFilters.certificationControls.map((cert) => cert.Code)) :
            undefined,
        Teams:
          !_.isEmpty(reuseFilters.services) ? buildAnyInArrayQuery(reuseFilters.services) : undefined,
        ImportState: (reuseFilters.importedState ?? ImportStateEnum.NotImported).toString(),
      },
    });

    const config = {
      odataQuery
    };
    return await get<Evidence[]>(REUSE_EVIDENCE_REQUEST_URI, config);
  };

  export const getEvidenceRequestGroupByEvidenceGuidAsync = async (
    auditEventGuid: string,
    evidenceGuid: string,
    activeTypes: EvidenceRequestType[] = [EvidenceRequestType.Original, EvidenceRequestType.Draft, EvidenceRequestType.Published],
  ): Promise<Evidence[]> => {
    const result: Evidence[] = [];

    // Fetch original or reuse evidence.
    if (activeTypes.includes(EvidenceRequestType.Original)) {
      const original = await getSingleOriginalEvidenceRequestAsync(auditEventGuid, evidenceGuid);

      if (original) {
        result.push({
          ...original,
          EvidenceRequestType: EvidenceRequestType.Original
        });
      }

      const reuse = await getSingleReuseEvidenceRequestAsync(auditEventGuid, evidenceGuid);

      if (reuse) {
        result.push({
          ...reuse,
          EvidenceRequestType: EvidenceRequestType.Reuse
        });
      }
    }

    // Fetch draft evidence.
    if (activeTypes.includes(EvidenceRequestType.Draft)) {
      const draft = await getSingleDraftEvidenceRequestAsync(auditEventGuid, evidenceGuid);

      if (draft) {
        result.push({
          ...draft,
          EvidenceRequestType: EvidenceRequestType.Draft
        });
      }
    }

    // Fetch published evidence.
    if (activeTypes.includes(EvidenceRequestType.Published)) {
      const published = await getSinglePublishedEvidenceRequestAsync(auditEventGuid, evidenceGuid);

      if (published) {
        result.push({
          ...published,
          EvidenceRequestType: EvidenceRequestType.Published
        });
      }
    }

    return result;
  };

  export const getSingleDraftEvidenceRequestAsync = async (
    auditEventGuid: string,
    evidenceGuid: string,
  ): Promise<DraftEvidence> => {
    const filter = buildFilterString({
      filter: {
        AuditEventGuid: {
          eq: {
            type: 'guid',
            value: auditEventGuid
          },
        },
        EvidenceGuid: {
          eq: {
            type: 'guid',
            value: evidenceGuid
          }
        },
      },
    });
    const odataQuery: OdataQuery = {
      $filter: filter,
    };
    const config = {
      odataQuery,
    };
    const data = await get<DraftEvidence[]>(DRAFT_EVIDENCE_REQUEST_URI, config);

    return data[0];
  };

  export const getSinglePublishedEvidenceRequestAsync = async (
    auditEventGuid: string,
    evidenceGuid: string,
  ): Promise<PublishedEvidence> => {
    const filter = buildFilterString({
      filter: {
        AuditEventGuid: {
          eq: {
            type: 'guid',
            value: auditEventGuid
          },
        },
        EvidenceGuid: {
          eq: {
            type: 'guid',
            value: evidenceGuid
          }
        },
      },
    });
    const odataQuery: OdataQuery = {
      $filter: filter,
    };
    const config = {
      odataQuery,
    };
    const data = await get<PublishedEvidence[]>(
      GET_PUBLISHED_EVIDENCE_REQUESTS_API,
      config,
    );

    return data[0];
  };

  export const getSingleOriginalEvidenceRequestAsync = async (
    auditEventGuid: string,
    evidenceGuid: string,
  ): Promise<OriginalEvidence | undefined> => {
    const filter = buildFilterString({
      filter: {
        AuditEventGuid: {
          eq: {
            type: 'guid',
            value: auditEventGuid
          },
        },
        EvidenceGuid: {
          eq: {
            type: 'guid',
            value: evidenceGuid
          }
        },
      },
    });
    const odataQuery: OdataQuery = {
      $filter: filter,
    };
    const config = {
      odataQuery,
    };
    const data = await get<OriginalEvidence[]>(
      GET_ORIGINAL_EVIDENCE_REQUEST_API,
      config,
    );

    return data[0];
  };

  export const getOriginalEvidenceByProposalGuidAsync = async (proposedEvidenceGuids: string[]): Promise<OriginalEvidence[]> => {
    const filter = buildFilterString({
      filter: {
        SourceProposedEvidenceGuid: {
          in: proposedEvidenceGuids
        },
      },
    });

    const odataQuery: OdataQuery = {
      $filter: filter,
    };

    return await get<OriginalEvidence[]>(GET_ORIGINAL_EVIDENCE_REQUEST_API, {
      odataQuery,
    });
  };

  export const getPublishedEvidenceByProposalGuidAsync = async (proposedEvidenceGuids: string[]): Promise<PublishedEvidence[]> => {
    const filter = buildFilterString({
      filter: {
        SourceProposedEvidenceGuid: {
          in: proposedEvidenceGuids
        },
      },
    });

    const odataQuery: OdataQuery = {
      $filter: filter,
    };

    return await get<PublishedEvidence[]>(GET_PUBLISHED_EVIDENCE_REQUESTS_API, {
      odataQuery,
    });
  };

  export const getSingleReuseEvidenceRequestAsync = async (
    auditEventGuid: string,
    evidenceGuid: string,
  ): Promise<Evidence> => {
    const filter = buildFilterString({
      filter: {
        ReusingAuditEventGuid: {
          eq: {
            type: 'guid',
            value: auditEventGuid
          },
        },
        EvidenceGuid: {
          eq: {
            type: 'guid',
            value: evidenceGuid
          }
        },
      },
    });
    const odataQuery: OdataQuery = {
      $filter: filter,
    };
    const config = {
      odataQuery,
    };
    const data = await get<Evidence[]>(REUSE_EVIDENCE_REQUEST_URI, config);

    return data[0];
  };

  export const postResyncPublishedEvidenceRequests =
    async (): Promise<void> => {
      await post<Record<string, unknown>, void>(
        POST_RESYNC_EVIDENCE_REQUESTS_API,
        {},
      );
    };

  interface ResyncAdoWorkItemsRequestData {
    auditEventIds: number[];
  }

  export const postResyncAdoWorkItemsRequest = async (
    auditEventIds: number[],
  ): Promise<void> => {
    const resyncAdoWorkItems: ResyncAdoWorkItemsRequestData = {
      auditEventIds,
    };

    await post<ResyncAdoWorkItemsRequestData, void>(
      POST_RESYNC_ADO_WORK_ITEMS_REQUEST_API,
      resyncAdoWorkItems,
    );
  };

  interface PublishDraftEvidenceRequestData {
    auditEventGuid: string;
    draftEvidenceGuids: string[];
  }

  export const postPublishDraftEvidenceRequest = async (
    auditEventGuid: string,
    evidenceGuids: string[],
  ): Promise<PublishDraftEvidenceResponse> => {
    const publishPublishedDraftEvidence: PublishDraftEvidenceRequestData = {
      auditEventGuid,
      draftEvidenceGuids: evidenceGuids,
    };

    return await post<PublishDraftEvidenceRequestData, PublishDraftEvidenceResponse>(
      POST_PUBLISH_DRAFT_EVIDENCE_REQUEST_API,
      publishPublishedDraftEvidence,
    );
  };

  interface UnpublishPublishedEvidenceRequestData {
    auditEventGuid: string;
    publishedEvidenceGuid: string;
    publishedEvidenceVersionGuid: string;
    notes: string;
  }

  export const postUnpublishPublishedEvidenceRequest = async (
    auditEventGuid: string,
    evidenceGuid: string,
    evidenceVersionGuid: string,
    notes: string,
  ): Promise<void> => {
    const UnpublishPublishedEvidenceRequest: UnpublishPublishedEvidenceRequestData =
    {
      auditEventGuid,
      publishedEvidenceGuid: evidenceGuid,
      publishedEvidenceVersionGuid: evidenceVersionGuid,
      notes,
    };

    await post<UnpublishPublishedEvidenceRequestData, void>(
      POST_UNPUBLISH_PUBLISHED_EVIDENCE_REQUEST_API,
      UnpublishPublishedEvidenceRequest,
    );
  };

  export type ImportReuseEvidencePayloadType = {
    reusingAuditEventGuid: string;
    evidenceRequestIds: string[];
  };

  export type ImportPublishedEvidencePayloadType = {
    reusingAuditEventGuid: string;
    evidenceRequestIds: string[];
  };

  export const postImportReuseEvidenceAsync = async (payload: ImportReuseEvidencePayloadType): Promise<string[]> => {
    return await post<ImportReuseEvidencePayloadType, string[]>(
      `${POST_IMPORT_REUSE_EVIDENCE_REQUEST_API}`, payload);
  };

  export const postImportPublishedEvidenceAsync = async (payload: ImportPublishedEvidencePayloadType): Promise<string[]> => {
    return await post<ImportPublishedEvidencePayloadType, string[]>(
      `${POST_IMPORT_PUBLISHED_EVIDENCE_REQUEST_API}`, payload);
  };

  export const getEvidenceRequestCreationStatusAsync = async (processGuid: string): Promise<EvidenceRequestCreationStatus> => {
    return await post<GetEvidenceCreationStatusRequestPayload, EvidenceRequestCreationStatus>(GET_EVIDENCE_REQUEST_CREATION_STATUS_API, {
      processGuid,
    });
  };

  export const createEvidenceRequestsAsync = async (payload: CreateEvidenceRequestsPayload): Promise<EvidenceCreationProcess> => {
    return await post<CreateEvidenceRequestsPayload, EvidenceCreationProcess>(CREATE_EVIDENCE_REQUESTS_API, payload);
  };

  export const getEvidenceCreationDetailsAsync = async (complianceAuditGuid: string): Promise<EvidenceCreationDetail[]> => {
    const odataQuery = buildODataQuery({
      filter: {
        ComplianceAuditGuid: convertToGuidObject(complianceAuditGuid),
      }
    });
    return await get<EvidenceCreationDetail[]>(GET_EVIDENCE_CREATION_DETAIL_API, {
      odataQuery
    });
  };

  const getOdataObjectOfAllEvidenceRequestsByCommonFilter = (
    auditEventGuid: string,
    commonFilters: CommonFilterState,
  ) => {
    const serviceFilterValue = !_.isEmpty(commonFilters.services) ?
      buildAnyInArrayQuery(commonFilters.services.map((s) => s.ShortName)) : undefined;

    const providerServiceFilterValue = !_.isEmpty(commonFilters.providerServices) ? {
      in: commonFilters.providerServices.filter(s => !_.isNil(s.ServiceTreeId)).map(s => convertToGuidObject(s.ServiceTreeId as string))
    } : undefined;

    const consumerServiceFilterValue = !_.isEmpty(commonFilters.consumerServices) ?
      buildAnyInArrayQuery(commonFilters.consumerServices.filter(s => !_.isNil(s.ServiceTreeId)).map(s => convertToGuidObject(s.ServiceTreeId as string)), 'ServiceTreeId') :
      undefined;

    const environmentFilterValue = !_.isEmpty(commonFilters.environments) ? {
      in: [...commonFilters.environments.map((e) => e.Name)]
    } : undefined;

    const certificationFilterValue = commonFilters.certification ? commonFilters.certification.ShortName : undefined;

    // CertificationControl is an array of CertificationCode, to match any code in the filter selection.
    // Example: CertificationControl/any(certificationcontrol:certificationcontrol in ('AC-02(13)','AC-02(3)'))
    const certificationControlFilterValue = commonFilters.certificationControls && commonFilters.certificationControls.length > 0 ?
      buildAnyInArrayQuery(
        commonFilters.certificationControls.map((c) => c.Code),
      ) : undefined;

    let attachmentFilterValue;

    if (commonFilters.withAttachmentsOrNot === AttachmentState.WithAttachments) {
      attachmentFilterValue = {
        Attachments: {
          any: {}
        },
      };
    } else if (commonFilters.withAttachmentsOrNot === AttachmentState.WithoutAttachments) {
      attachmentFilterValue = {
        not: {
          Attachments: {
            any: {}
          },
        },
      };
    }

    return {
      'AuditEventGuid': convertToGuidObject(auditEventGuid),
      'Teams': serviceFilterValue,
      'ProviderService/ServiceTreeId': providerServiceFilterValue,
      'ConsumerServices': consumerServiceFilterValue,
      'EnvironmentName': environmentFilterValue,
      'Certification': certificationFilterValue,
      'CertificationControl': certificationControlFilterValue,
      ...attachmentFilterValue,
    };
  };

  const getOdataQueryOfOriginalEvidenceRequestsByFilter = (
    auditEventGuid: string,
    commonFilters: CommonFilterState,
    originalFilters?: Partial<OriginalEvidenceRequestsFilterState>,
  ): OdataQuery => {
    const stateFilterValue = originalFilters?.workItemStates && originalFilters.workItemStates?.length > 0 ?
      {
        in: [...originalFilters.workItemStates.map((s) => s.ShortName)]
      } : undefined;

    const auditorFilterValue = originalFilters?.assignedTos && originalFilters.assignedTos?.length > 0 ?
      {
        in: [...originalFilters.assignedTos.map((s) => s.Alias)]
      } : undefined;

    // if the BugId is null, it means the evidence is not synced with ado
    let evidenceSourceTypeFilterValue;

    if (originalFilters?.sourceTypes) {
      evidenceSourceTypeFilterValue = originalFilters.sourceTypes === EvidenceRequestSourceType.ADOSynced ? {
        ne: null
      } : {
        eq: null
      };
    }

    const proposedEvidenceGuidValue = originalFilters?.isProposalOnly ? {
      ne: null
    } : undefined;

    const commonFilterObject = getOdataObjectOfAllEvidenceRequestsByCommonFilter(auditEventGuid, commonFilters);

    return buildODataQuery({
      filter: {
        ...commonFilterObject,
        State: stateFilterValue,
        DueDate: getDueDateFilterOdataValue(originalFilters?.dueDate),
        AssignedTo: auditorFilterValue,
        BugId: evidenceSourceTypeFilterValue,
        SourceProposedEvidenceGuid: proposedEvidenceGuidValue,
      },
    });
  };

  const getOdataQueryOfDraftEvidenceRequestsByFilter = (
    auditEventGuid: string,
    commonFilters: CommonFilterState,
    draftFilters?: DraftEvidenceRequestsFilterState,
  ): OdataQuery => {
    const commonFilterObject = getOdataObjectOfAllEvidenceRequestsByCommonFilter(auditEventGuid, commonFilters);

    const publishingStateValue = !_.isEmpty(draftFilters?.publishingStates) ?
      {
        in: Object.values(draftFilters?.publishingStates ?? [])
      } : undefined;

    return buildODataQuery({
      filter: {
        ...commonFilterObject,
        'PublishingState': publishingStateValue,
        'ModificationState': draftFilters?.displayEvidencesInConflictOnly ? ModificationStateEnum.InConflict : undefined,
      },
    });
  };

  const getOdataQueryOfPublishedEvidenceRequestsByFilter = (
    auditEventGuid: string,
    commonFilters: CommonFilterState,
    publishedFilters?: PublishedEvidenceRequestsFilterState,
  ): OdataQuery => {
    const commonFilterObject = getOdataObjectOfAllEvidenceRequestsByCommonFilter(auditEventGuid, commonFilters);

    const conclusionStateValue = publishedFilters?.auditorsDecisions && publishedFilters?.auditorsDecisions.length > 0 ?
      {
        in: Object.values(publishedFilters.auditorsDecisions),
      } : undefined;

    return buildODataQuery({
      filter: {
        ...commonFilterObject,
        ConclusionState: conclusionStateValue,
      },
    });
  };

  const getOdataQueryOfOriginalEvidenceRequestsByAssignedToMeFilter = (
    filter: AssignedToMeFilterSelectedState,
    sortBy: SortOption<ServiceWorkspaceEvidenceSortKey>,
    extraOptions: OriginalEvidenceAssignedOptions
  ): string => {
    const { state, source, auditEvent, environment, service, dueDate, createdDate } = filter;
    const { alias, services, workloads } = extraOptions;

    return buildQuery({
      filter: {
        AssignedTo: alias,
        State: !_.isEmpty(state) ? {
          in: state.map(s => s.ShortName)
        } : undefined,
        Source: source,
        AuditEventGuid: !_.isUndefined(auditEvent) ? {
          value: auditEvent.AuditEventGuid,
          type: 'guid'
        } : undefined,
        EnvironmentName: !_.isEmpty(environment) ? {
          in: environment.map(e => e.Name)
        } : undefined,
        AssignedToServiceGuid: !_.isEmpty(services) ? {
          in: services
        } : undefined,
        AssignedToWorkloadGuid: !_.isEmpty(workloads) ? {
          in: workloads
        } : undefined,
        Teams: !_.isEmpty(service) ? buildAnyInArrayQuery(service.map(s => s.ShortName)) : undefined,
        DueDate: getDueDateFilterOdataValue(dueDate),
        CreatedDate: getCreatedDateFilterOdataValue(createdDate),
      },
      orderBy: `${sortBy.key} ${sortBy.order}`
    });
  };

  export const createPublishedEvidenceComment = async (payload: CreatePublishedEvidenceCommentRequestPayload): Promise<void> => {
    return await post<CreatePublishedEvidenceCommentRequestPayload, void>(
      CREATE_PUBLISHED_EVIDENCE_COMMENT_API,
      {
        ...payload
      }
    );
  };
}
