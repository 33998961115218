import React, { useState } from 'react';

import {
  IShimmerElement,
  Shimmer,
  ShimmerElementType,
  Text,
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { ServiceSelectionHeader } from './ServiceSelectionHeader';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { FormSection } from '../TrustLayout';

const SHIMMER_COUNT = 10;

const getCheckboxShimmerElement = (lineWidth: number): IShimmerElement[] => {
  return [
    {
      type: ShimmerElementType.gap,
      width: '1%'
    },
    {
      type: ShimmerElementType.line,
      height: 18,
      width: 18
    },
    {
      type: ShimmerElementType.gap,
      width: '2%'
    },
    {
      type: ShimmerElementType.line,
      height: 16,
      width: `${lineWidth}%`
    },
    {
      type: ShimmerElementType.gap,
      width: `${95 - lineWidth}%`
    },
  ];
};

export const ServiceSelectionShimmer: React.FC = () => {
  const [lineWidths, _] = useState(
    Array.from(
      {
        length: SHIMMER_COUNT
      },
      () => Math.floor(Math.random() * 20) + 20,
    ),
  );
  return (
    <FormSection>
      <ServiceSelectionHeader disabled={true} />
      <Text>{ intl.get(LocIds.Placeholder.LoadingServices) }</Text>
      <FormSection>
        { Array.from({
          length: SHIMMER_COUNT
        }, (_, index) => (
          <Shimmer
            key={`shimmer-${index}`}
            shimmerElements={getCheckboxShimmerElement(lineWidths[index])}
          />
        )) }
      </FormSection>
    </FormSection>
  );
};
