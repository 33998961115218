import './style.less';
import React from 'react';

import {
  Callout,
  DirectionalHint,
  Stack,
  Text
} from '@fluentui/react';

import {
  StatusIcon,
  StatusType
} from '../StatusIcon';

interface IProps {
  targetId: string;
  text: string;
  onDismiss: () => void;
}

export const CopyLinkCallout: React.FC<IProps> = (props) => {
  return (
    <Callout
      className='linkCopyCallout'
      coverTarget
      directionalHint={DirectionalHint.topRightEdge}
      isBeakVisible={false}
      role='dialog'
      setInitialFocus
      target={`#${props.targetId}`}
      onDismiss={props.onDismiss}>
      <Stack horizontalAlign='center'
        tokens={{
          childrenGap: 12
        }}>
        <StatusIcon size='xLarge' type={StatusType.Completed} />
        <Text>{ props.text }</Text>
      </Stack>
    </Callout>
  );
};
