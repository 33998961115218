export module CoachmarkIds {
    export module UAR{
        export enum ClassificationException {
            FiltersLabel = 1,
            AssignedToFilter = 2,
            DocumentationButton = 3
        }

        export enum ClassificationReview {
            DueDateLabel = 1,
            FiltersLabel = 2,
            AssignedToFilter = 3,
            BulkActionsButtonGroup = 4,
            DocumentationButton = 5
        }

        export enum ClassificationExceptionReview {
            FiltersLabel = 1,
            AssignedToFilter = 2,
            DocumentationButton = 3
        }

        export enum ClassificationReviewService {
        DueDateLabel = 1,
        FiltersLabel = 2
        }

        export enum ClassificationReviewTriage {
            FiltersLabel = 1,
            DocumentationButton = 2
        }

        export enum GroupReview {
            DueDateLabel = 1,
            DocumentationButton = 2
        }

        export enum ManagerReviews {
            DueDateLabel = 1,
            FiltersLabel = 2,
            PreviousDecisionLabel = 3,
            BulkActionsButtonGroup = 4,
            DocumentationButton = 5,
        }

        export enum ManagerReviewTriage {
            DueDateLabel = 1,
            FiltersLabel = 2,
            AssignedToFilter = 3,
            DocumentationButton = 4
        }

        export enum ManualReviews {
            DueDateLabel = 1,
            FiltersLabel = 2,
            BulkActionsButtonGroup = 3,
            DocumentationButton = 4
        }

        export enum ManualReviewsTriage {
            DueDateLabel = 1
        }

        export enum MyReviews {
            FiltersLabel = 1,
            DocumentationButton = 2
        }

        export enum ReviewManagement{
            DocumentationButton = 1
        }

        export enum RevocationException{
            FiltersLabel = 1,
            AssignedToFilter = 2,
            DocumentationButton = 3
        }

        export enum RevocationReview{
            DueDateLabel = 1,
            AssignedToFilter = 2,
            DocumentationButton = 3
        }

        export enum RevocationReviewTriage{
            DueDateLabel = 1,
            DocumentationButton = 2
        }
    }
}
