import React from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';

import { CommonFilterProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { AttachmentState } from '../../models';
import { SingleSelectDropdown } from '../Dropdown';
import { FilterFieldWrapper } from '../FilterFieldWrapper';

export const AttachmentFilter: React.FC<
  CommonFilterProps<AttachmentState[], AttachmentState | undefined>
> = (props) => {
  const { selected, allOptions, onChange } = props;

  return (
    <FilterFieldWrapper
      ariaLabel={intl.get(LocIds.Label.Attachments)}
      isClearActive={!_.isNil(selected)}
      onClear={() => onChange(undefined)}>
      <SingleSelectDropdown<AttachmentState>
        items={allOptions ?? []}
        keyOf={(item) => String(item)}
        label={intl.get(LocIds.Label.Attachments)}
        optionOf={(item) => ({
          key: String(item),
          text: getAttachmentStateText(item),
        })}
        placeholder={intl.get(LocIds.Placeholder.SelectToFilter)}
        selectedItem={selected}
        onChange={onChange}
      />
    </FilterFieldWrapper>
  );
};

export const getAttachmentStateText = (option: AttachmentState): string => {
  switch (option) {
  case AttachmentState.WithAttachments:
    return intl.get(LocIds.AuditManager.WithAttachments);
  case AttachmentState.WithoutAttachments:
    return intl.get(LocIds.AuditManager.WithoutAttachments);
  default:
    return intl.get(LocIds.AuditManager.WithoutAttachments);
  }
};
