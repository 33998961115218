import React, {
  useEffect,
  useMemo,
  useState
} from 'react';

import { Spinner } from '@fluentui/react';
import intl from 'react-intl-universal';
import {
  useNavigate,
  useLocation
} from 'react-router';


import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  createDashboardTree,
  PowerBiReportMetadata
} from '../../models';
import { PowerBiApi } from '../../services';
import { notify } from '../../utils/notify';
import { EmbeddedReport } from '../EmbeddedReport';
import './style.less';


interface IReportViewProps {
    reports: PowerBiReportMetadata[];
    applicationId: string;
    menuId: string;
}

export const ReportView: React.FC<IReportViewProps> = (
  props: IReportViewProps,
) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const reportId = useMemo(() => getReportIdFromPath(pathname), [pathname]);

  const [reportToken, setReportToken] = useState('');

  const menuReports = useMemo(() => createDashboardTree(props.reports, false), [props.reports]);

  useEffect(() => {
    if (menuReports.length > 0) {
      navigate(`/application/${props.applicationId}/menu/${props.menuId}${menuReports[0].reportPath}`);
    }
  }, [
    menuReports,
    navigate,
    props.applicationId,
    props.menuId,
    props.reports
  ]);

  useEffect(() => {
    if (!reportId) {
      return;
    }

    const fetchToken = async () => {
      try {
        const res = await PowerBiApi.generatePowerBiTokenAsync(reportId);
        setReportToken(res);
      } catch (error) {
        notify.error(intl.get(LocIds.Error.TryAgainLater));
      }
    };
    fetchToken();
  }, [reportId]);

  return menuReports ? (
    <div className='report__content-wrapper'>
      <div className='report__content-scroll'>
        <div className='report__content'>
          { reportId ? (
            reportToken ? (
              <EmbeddedReport
                reportId={reportId}
                reportTitle={''}
                token={reportToken}
              />
            ) : <Spinner/>
          ) : <p className='text-16 report__content-message'>{ intl.get(LocIds.Components.NoReportAvailable) }</p> }
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

const getReportIdFromPath = (path: string) => {
  let reportId = '';
  const reportSegment = 'report/';

  if (path.includes(reportSegment)) {
    reportId = path.split(reportSegment)[1];
  }

  return reportId.trim();
};
