import React, { ReactNode } from 'react';

import './Tag.less';
import {
  IButtonProps,
  Icon
} from '@fluentui/react';
import classnames from 'classnames';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';


export type TagVariant = 'default' | 'red' | 'orange' | 'yellow' | 'green' | 'blue'

interface IProps {
    variant?: TagVariant;
    size?: 'm' | 's';
    onClick?: () => void;
    onRemove?: () => void;
    disabled?: boolean;
    maxLength?: number;
    children: ReactNode | ReactNode[];
}

export const Tag: React.FC<IProps> = (props: IProps) => {
  const { children, onClick, onRemove, disabled, variant = 'default', size = 'm', maxLength = 32 } = props;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClick && onClick();
  };

  // -------------------------------------------------------------------------------------------------------------------

  const overMaxLength = typeof children === 'string' && children.length > maxLength;
  const content = overMaxLength ? children.slice(0, maxLength) + '...' : children;

  // -------------------------------------------------------------------------------------------------------------------

  const iconButtonProperties: IButtonProps = {
    iconProps: {
      iconName: 'Cancel',
    },
    styles: {
      root: {
        marginLeft: '8px',
        color: (variant === 'yellow' || variant === 'default') ? 'var(--gray160)' : 'var(--white)',
        width: '16px',
        height: '16px'
      },
      icon: {
        width: size === 'm' ? '12px' : '10px',
        height: size === 'm' ? '12px' : '10px'
      }
    }
  };

  // -------------------------------------------------------------------------------------------------------------------

  const tagClass = classnames(
    'tp-tag',
    onClick && !disabled ? 'tp-tag--clickable' : '',
    `tp-tag--${variant}`,
    `tp-tag--${size}`,
    size === 'm' ? 'text-14' : 'text-12'
  );

  return (
    <div className={tagClass}
      onClick={onClick ? handleClick : undefined}>
      { content }
      { !!onRemove && (
        <button aria-label={intl.get(LocIds.Action.Delete)}
          className='tp-tag__remove'
          disabled={disabled}
          type='button'
          onClick={onRemove}>
          <Icon iconName='cancel'/>
        </button>
      ) }
    </div>
  );
};
