import { AttachmentBase } from './Attachment';
import { DiscussionThread } from './Discussions';

import { IETagged } from './IEtagged';

import {
  EvidenceRequestType, Nullable, PublishingState, ReferencedService
} from './';

export interface Evidence {
  /**
   * Guid of evidence
   */
  /** @mockType {random.uuid} */
  EvidenceGuid: string;
  EvidenceId: number;
  Id: string;

  /**
   * Display name of evidence
   */
  /** @mockType {random.word} */
  Title: string;

  /**
   * Description of evidence
   */
  Description: string;

  /**
   * Attachments of evidence
   */
  Attachments: EvidenceRequestAttachment[];

  /**
   * Guid of audit event
   */
  /** @mockType {random.uuid} */
  AuditEventGuid: string;

  ReusingAuditEventGuid?: string;

  /**
   * Display name of audit event
   */
  /** @mockType {random.word} */
  AuditEventTitle: string;

  /**
   * Audit state of evidence
   */
  ConclusionState: ConclusionStateEnum;

  /**
   * Microsoft Services, e.g. EXO, SPO
   */
  Teams: string[];

  ProviderService: Nullable<ReferencedService>;
  ConsumerServices: ReferencedService[];

  /**
   * An evidence request could be created against single certification and multiple certification controls which belong to the same certification
   */
  /** @mockType {random.word} */
  Certification: string;
  CertificationControl: string[];

  EnvironmentName: string;
  ReviewedDate: string | null;
  Source: string;
  BugId: number | null;

  EvidenceRequestType: EvidenceRequestType;

  /**
   * Version Guid of PublishedEvidenceRequest
   */
  VersionGuid?: string;

  SourceProposedEvidenceGuid: string | null;
}

export interface EvidenceRequestAttachment extends AttachmentBase {
  // This value is only applied to Published Evidence Requests.
  RetainedUntil?: string;
}

// State of evidences in redux store
export type EvidenceWithId = Evidence & {
  // id of Evidence is in the format of `${AuditEventGuid}:${EvidenceGuid}:${EvidenceRequestType}`
  id: string;
};

export type NormalizedEvidencesEntities = {
  evidences: { [key: string]: EvidenceWithId };
};

export interface OriginalEvidence extends Evidence {
  CreatedBy: string;
  CreatedDate: string;
  DueDate: string;
  AssignedTo: string;
  State: string;
  ViewUrl: string | null;
}
export interface DraftEvidence extends Evidence {
  DraftSource: {
    AuditEventGuid: string;
    Type: DraftSourceType;
  };
  ModificationState: ModificationStateEnum;
  PublishingState?: PublishingState;
}

export interface PublishedEvidence extends Evidence, IETagged {
  VersionGuid: string;
  DiscussionThreads: DiscussionThread[];
}

export enum ConclusionStateEnum {
  InReview = 'InReview',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export enum DraftSourceType {
  Reuse = 'Reuse',
  Original = 'Original',
}

export enum ModificationStateEnum {
  Unmodified = 'Unmodified',
  Modified = 'Modified',
  InConflict = 'InConflict',
}

export enum ImportStateEnum {
  Imported = 'Imported',
  NotImported = 'NotImported',
  UnavailableForImport = 'UnavailableForImport',
}

/* eslint-disable i18next/no-literal-string */
const createOriginalEvidenceRequestOrderKeys = <T extends keyof OriginalEvidence>(keys: T[]) => keys;
export const allOriginalEvidenceRequestOrderKeys = createOriginalEvidenceRequestOrderKeys([
  'EvidenceId',
  'Title',
  'BugId',
  'CreatedDate',
  'DueDate'
]);
export type OriginalEvidenceRequestOrderKey = (typeof allOriginalEvidenceRequestOrderKeys)[number];

// Logic of handling published is the same with logic of handling draft.
const createDraftAndPublishedEvidenceRequestOrderKeys = <T extends keyof DraftEvidence>(keys: T[]) => keys;
/* eslint-disable array-element-newline */
export const allDraftAndPublishedEvidenceRequestOrderKeys = createDraftAndPublishedEvidenceRequestOrderKeys([
  'EvidenceId',
  'Title',
  'ReviewedDate',
]);
/* eslint-enable array-element-newline */
export type DraftAndPublishedEvidenceRequestOrderKey = (typeof allDraftAndPublishedEvidenceRequestOrderKeys)[number];

export type EvidenceRequestOrderKey = OriginalEvidenceRequestOrderKey | DraftAndPublishedEvidenceRequestOrderKey;
export type EvidenceRequestGroupOrderKey = [EvidenceRequestType, EvidenceRequestOrderKey];
/* eslint-enable i18next/no-literal-string */
