/* eslint-disable i18next/no-literal-string */
import React, {
  CSSProperties, ReactNode, useLayoutEffect, useState
} from 'react';

import './PageLayout.less';
import {
  IBreadcrumbItem, Stack
} from '@fluentui/react';

import { FCC } from '../../../models';
import { Breadcrumbs } from '../../Breadcrumbs'; import {
  DocumentationCommandBar, IDocumentationCommandBarProps
} from '../../DocumentationCommandBar';
import {
  ITab, Tabs
} from '../../Tabs/Tabs';

interface IProps {
    pageTitle: ReactNode;
    breadCrumbItems?: IBreadcrumbItem[];
    documentationBtn?: IDocumentationCommandBarProps;
    tabs?: ITab[];
    selectedTab?: string;
}

export const PageLayout: FCC<IProps> = ({ pageTitle, breadCrumbItems, documentationBtn, tabs, selectedTab, children }) => {
  const [breadcrumbsStyle, setBreadcrumbsStyle] = useState<CSSProperties>({});
  const [documentationStyle, setDocumentationStyle] = useState<CSSProperties>({});
  const [headerStyle, setHeaderStyle] = useState<CSSProperties>({});
  const [titleStyle, setTitleStyle] = useState<CSSProperties>({
    backgroundColor: 'white'
  });

  useLayoutEffect(() => {
    const header = document.querySelector('.tp-header');
    const breadcrumbs = document.querySelector('.tp-page__breadcrumbs');
    const documentation = document.querySelector('.tp-page__documentation');
    const title = document.querySelector('.tp-page__title');

    let top = 0;
    let paddingTop = 16;

    if (header) {
      top += header.getBoundingClientRect().height;
    }

    if (breadcrumbs) {
      setHeaderStyle({
        top: `${top}px`
      });

      setBreadcrumbsStyle({
        top: `${top}px`,
        paddingTop
      });

      paddingTop = 0;
    }

    if (documentation) {
      paddingTop = 16;

      setHeaderStyle({
        top: `${top}px`
      });

      setDocumentationStyle({
        top: `${top}px`,
        paddingTop,
        marginLeft: 'auto'
      });

      paddingTop = 0;
    }

    if (breadcrumbs) {
      top += breadcrumbs.getBoundingClientRect().height;
    }

    if (title) {
      setTitleStyle({
        ...titleStyle,
        top: `${top}px`,
        paddingTop
      });
    }
  }, []);

  return (
    <div className='tp-page'>
      <div className='tp-page__header' style={headerStyle}>
        { breadCrumbItems && (
          <div className='tp-page__breadcrumbs' style={breadcrumbsStyle}>
            <Breadcrumbs items={breadCrumbItems} onReduceData={_returnUndefined}/>
          </div>
        ) }

        <Stack className='tp-page__documentation' horizontal style={breadcrumbsStyle}>
          { documentationBtn && (
            <DocumentationCommandBar
              coachmarkIdentifier={documentationBtn.coachmarkIdentifier}
              helpUrl={documentationBtn.helpUrl}
            />
          ) }
        </Stack>
      </div>

      <h2 className='title-24 tp-page__title' style={titleStyle}>{ pageTitle }</h2>

      { tabs && (
        <div className='tp-page__tabs'>
          <Tabs list={tabs} selectedKey={selectedTab}/>
        </div>
      ) }

      <main className='tp-page__body'>
        { children }
      </main>
    </div>
  );
};

const _returnUndefined = () => undefined;
