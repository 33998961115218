import './style.less';

import React from 'react';

import {
  Link,
  Stack,
  Text
} from '@fluentui/react';
import _ from 'lodash';
import intl from 'react-intl-universal';


import { LocIds } from '../../common/Globalization/IntlEnum';

interface EvidenceSelectionPromptProps {
  onSelectAllPages: () => void;
  onUnselectAll: () => void;
  numItemsSelected: number;
  numGroupsSelected?: number;
  numTotal: number;
  isHidden?: boolean;
}

export const EvidenceSelectionPrompt: React.FC<EvidenceSelectionPromptProps> = (
  props,
) => {
  const {
    isHidden = true,
    numItemsSelected,
    numGroupsSelected,
    numTotal,
  } = props;

  if (isHidden) {
    return null;
  }

  const isSelectedAllPages = numItemsSelected === numTotal;

  let wording: string;

  if (_.isUndefined(numGroupsSelected)) {
    wording = isSelectedAllPages ?
      intl.get(LocIds.Components.AllEvidencesSelected, {
        numItems: numItemsSelected,
      }) :
      intl.get(LocIds.Components.AllEvidencesOnThisPageSelected, {
        numItems: numItemsSelected,
      });
  } else {
    wording = isSelectedAllPages ?
      intl.get(LocIds.Components.AllGroupsSelected, {
        numItems: numItemsSelected,
        numGroups: numGroupsSelected,
      }) :
      intl.get(LocIds.Components.AllGroupsOnThisPageSelected, {
        numItems: numItemsSelected,
        numGroups: numGroupsSelected,
      });
  }

  const selectAllWording = _.isUndefined(numGroupsSelected) ?
    intl.get(LocIds.Evidence.SelectAllEvidenceRequests, {
      numTotal
    }) :
    intl.get(LocIds.Evidence.SelectAllGroups, {
      numTotal
    });

  const action = isSelectedAllPages ? (
    <Link className='evidenceList__clearButton' onClick={props.onUnselectAll}>
      { intl.get(LocIds.Action.ClearSelection) }
    </Link>
  ) : (
    <Link className='evidenceList__clearButton' onClick={props.onSelectAllPages}>{ selectAllWording }</Link>
  );

  return (
    <Stack
      className='evidenceList__selectionPrompt'
      horizontal
      tokens={{
        childrenGap: 8
      }}>
      <Text>{ wording }</Text>
      { action }
    </Stack>
  );
};
