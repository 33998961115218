import React, {
  useCallback,
  useEffect,
  useState
} from 'react';

import { SearchBox } from '@fluentui/react';
import { debounce } from 'lodash';
import intl from 'react-intl-universal';


import { LocIds } from '../../common/Globalization/IntlEnum';

export type ThrottleKeywordBoxProps = {
  keyword: string;
  onKeywordChange: (text: string) => void;
  throttle?: boolean;
};

const DEBOUNCE_MILLISECONDS = 400;

export const ThrottleKeywordBox: React.FC<ThrottleKeywordBoxProps> = (
  props,
) => {
  const { keyword, onKeywordChange, throttle = false } = props;

  // used for "name filter" to throttle the dispatching event but display the value without delay
  const [tempKeyword, setTempKeyword] = useState('');

  useEffect(() => {
    setTempKeyword(keyword);
  }, [keyword]);

  const debouncedKeywordChangeHandler = useCallback(
    debounce((newValue?: string) => {
      onKeywordChange(newValue ?? '');
    }, DEBOUNCE_MILLISECONDS),
    [],
  );

  const onChange = (text: string) => {
    setTempKeyword(text);

    if (throttle) {
      debouncedKeywordChangeHandler(text);
    } else {
      onKeywordChange(text);
    }
  };

  return (
    <SearchBox
      iconProps={{
        iconName: 'Filter'
      }}
      placeholder={intl.get(LocIds.Placeholder.TypeToFilter)}
      styles={{
        root: {
          width: 250
        }
      }}
      value={tempKeyword}
      onChange={(_, newValue) => onChange(newValue ?? '')}
    />
  );
};
