import React, { FC } from 'react';


import _ from 'lodash';
import {
  Controller,
  UseControllerProps,
  useFormContext
} from 'react-hook-form';

import { EntityApi } from '../../../services';
import {
  PeoplePickerViewMode, ThrottledPeoplePicker
} from '../../PeoplePicker';

interface IProps extends Pick<UseControllerProps, 'rules'> {
  name: string;
  required: boolean;
  readOnly?: boolean;
}

export const PeoplePickerControl: FC<IProps> = (props: IProps) => {
  const { control } = useFormContext();

  const onSearch = async (searchString: string) => {
    try {
      return await EntityApi.searchUsersAsync({
        searchUserAccountText: searchString,
        topOption: 10,
        memberOnly: false,
      });
    } catch {
      return [];
    }
  };

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
        <ThrottledPeoplePicker
          disabled={props.readOnly}
          required={props.required}
          selectedEntities={fieldProps.value}
          showError={!_.isNil(error)}
          viewMode={PeoplePickerViewMode.normal}
          onChange={(value) => {
            fieldProps.onChange(value);
          }}
          onSearch={onSearch}
        />
      )}
      rules={props.rules}
    />
  );
};
