import { createSlice } from '@reduxjs/toolkit';
export interface ICoachmarksSlice {
  activeCoachmarkIndex: number;
}
export const initialState: ICoachmarksSlice = {
  activeCoachmarkIndex: 0,
};
export const coachmarksSlice = createSlice({
  name: 'coachmarks',
  initialState,
  reducers: {
    setActiveCoachmarkIndex: (state: ICoachmarksSlice, { payload }: { payload: number }) => {
      state.activeCoachmarkIndex = payload;
    }
  }
});
export const {
  setActiveCoachmarkIndex
} = coachmarksSlice.actions;
