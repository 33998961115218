import React from 'react';

import {
  Embed,
  models
} from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import './style.less';


interface IEmbeddedReportProps {
    token: string;
    reportId: string;
    reportTitle?: string;
}

export const EmbeddedReport: React.FC<IEmbeddedReportProps> = (
  props: IEmbeddedReportProps,
) => {
  const { token, reportId, reportTitle } = props;

  return (
    <div className='embedded__report'>
      <h3 className='title-16 embedded__report-title'>{ reportTitle }</h3>
      <PowerBIEmbed
        // adding token as key forces powerbiembed to refresh when token changes
        cssClassName='embedded__report-wrapper'
        embedConfig={{
          // Supported types: report, dashboard, tile, visual and qna
          type: 'report',
          id: reportId,
          embedUrl: 'https://app.powerbi.com/reportEmbed',
          accessToken: token,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        }}
        getEmbeddedComponent={(embed:Embed) => {
          embed.setComponentTitle(reportTitle!);
        }}
        key={token}
      />
    </div>
  );
};
