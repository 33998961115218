import React, { ReactNode } from 'react';

import {
  IModalProps,
  Modal
} from '@fluentui/react/lib/Modal';
import classnames from 'classnames';

type ModalWrapperProps = {
  children: ReactNode;
  modalProps: IModalProps;
};

/**
 *
 * @param children Content of Modal
 * @param modalProps Optional props for fluentui's Modal component
 */
export const ModalWrapper: React.FC<ModalWrapperProps> = (
  props: ModalWrapperProps,
) => {
  const { children, modalProps } = props;
  const className = classnames(modalProps.className, 'portalModalWrapper');
  return (
    <Modal {...modalProps} className={className} isOpen={true}>
      { children }
    </Modal>
  );
};
