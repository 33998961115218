import React from 'react';

import { Stack } from '@fluentui/react';
import intl from 'react-intl-universal';

import { DatePicker } from './DatePicker';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { FilterFieldWrapper } from '../FilterFieldWrapper';

type DateRangePickerProps = {
  horizontal?: boolean;
  required?: boolean;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  startDateLabel?: string;
  endDateLabel?: string;
  onSelectStartDate: (date: Date | null | undefined) => void;
  onSelectEndDate: (date: Date | null | undefined) => void;
  allowClear?: boolean;
  disabled?: boolean;
};

export const DateRangePicker: React.FC<DateRangePickerProps> = (props) => {
  const {
    horizontal = false,
    required = false,
    startDate,
    endDate,
    minDate,
    maxDate,
    startDateLabel = intl.get(LocIds.Label.StartDate),
    endDateLabel = intl.get(LocIds.Label.EndDate),
    onSelectStartDate,
    onSelectEndDate,
    allowClear,
    disabled = false
  } = props;

  return (
    <Stack
      horizontal={horizontal}
      tokens={{
        childrenGap: 12
      }}
      verticalAlign='start'>
      <Stack.Item grow>
        <FilterFieldWrapper
          ariaLabel={startDateLabel}
          isClearActive={allowClear && !!startDate}
          onClear={() => onSelectStartDate(null)}>
          <DatePicker
            ariaLabel={intl.get(LocIds.Components.SelectAStartDate)}
            disabled={disabled}
            initialPickerDate={startDate}
            isRequired={required}
            label={startDateLabel}
            maxDate={endDate || maxDate}
            minDate={minDate}
            placeholder={intl.get(LocIds.Placeholder.SelectADate)}
            value={startDate}
            onSelectDate={onSelectStartDate}
          />
        </FilterFieldWrapper>
      </Stack.Item>
      <Stack.Item grow>
        <FilterFieldWrapper
          ariaLabel={endDateLabel}
          isClearActive={allowClear && !!endDate}
          onClear={() => onSelectEndDate(null)}>
          <DatePicker
            ariaLabel={intl.get(LocIds.Components.SelectAnEndDate)}
            disabled={disabled}
            initialPickerDate={endDate}
            isRequired={required}
            label={endDateLabel}
            maxDate={maxDate}
            minDate={startDate || minDate}
            placeholder={intl.get(LocIds.Placeholder.SelectADate)}
            value={endDate}
            onSelectDate={onSelectEndDate}
          />
        </FilterFieldWrapper>
      </Stack.Item>
    </Stack>
  );
};
