import React from 'react';

import {
  DirectionalHint, ICommandBarItemProps, Stack
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { setActiveCoachmarkIndex } from '../../redux/coachmarkSlice/slice';
import { useAppDispatch } from '../../redux/reducer';
import { FilterableCommandBar } from '../FilterableCommandBar';
import { M365CommandBarButton } from '../M365Button/M365CommandBarButton';
import { TeachingBubbleCoachmark } from '../TeachingBubbleCoachmark';

export interface IDocumentationCommandBarProps {
  helpUrl: string;
  coachmarkIdentifier?: string;
  coachmarkText?: string;
}
export const DocumentationCommandBar : React.FC<IDocumentationCommandBarProps> = (props) => {
  const targetDiv = React.useRef<HTMLDivElement>(null);
  const { coachmarkIdentifier, coachmarkText } = props;
  const dispatch = useAppDispatch();

  // -------------------------------------------------------------------------------------------------------------------
  let commandBarItems: ICommandBarItemProps[] = [];

  if (coachmarkIdentifier) {
    commandBarItems = [
      {
        key: 'NavHelp',
        onClick: () => {
          dispatch(setActiveCoachmarkIndex(1));
        },
        text: coachmarkText ? coachmarkText : intl.get(LocIds.Application.Quickstart),
        disabled: false,
        onRender: (props) => <M365CommandBarButton {...props}/>
      }
    ];
  }

  commandBarItems = commandBarItems.concat([
    {
      key: 'Approve',
      text: intl.get(LocIds.Application.GetHelp),
      href: props.helpUrl,
      iconProps: {
        iconName: 'Documentation'
      },
      disabled: false,
      target: '_blank',
      onRender: (props) => <M365CommandBarButton {...props}/>
    }
  ]);

  return (
    <Stack.Item>
      <div ref={targetDiv}>
        <FilterableCommandBar items={commandBarItems}/>
      </div>
      { coachmarkIdentifier && (
        <TeachingBubbleCoachmark
          description={intl.get(LocIds.UserAccessReview.DocumentationCoachmark)}
          direction={DirectionalHint.bottomAutoEdge}
          identifier={coachmarkIdentifier}
          target={targetDiv.current}
        />
      ) }
    </Stack.Item>
  );
};
