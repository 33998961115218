import { Dispatch } from 'redux';

import { UarResourcesFilters } from './ClassificationReview';
import { UarResourceBase } from './UarTypes';

export interface UarClassificationExceptionResource extends UarResourceBase {
    State: UarExceptionReviewState;
    SearchText: string;
}

export enum UarExceptionReviewState {
    InReview = 'InReview',
    Approved = 'Approved',
    Rejected = 'Rejected'
}

export interface ClassificationExceptionDecisionItem {
    ServiceResourceClassificationReviewGuid: string;
    SetState: UarExceptionReviewState;
    SetJustification: string;
}

export interface MakeClassificationExceptionDecisionBody {
    userAccessReviewGuid: string;
    setItems: ClassificationExceptionDecisionItem[];
}

export interface MakeClassificationExceptionDecisionPayload {
    decisionPayload: MakeClassificationExceptionDecisionBody;
    resourcesPayload: UarClassificationExceptionPayload;
    dispatch: Dispatch<any>;

}

export interface UarClassificationExceptionPayload {
    ServiceTreeId?: string;
    ResourceGuid?: string;
    IterationGuids: string[];
}

export interface UarRevocationExceptionPayload {
    iterationGuids: string[];
}

export interface UarExceptionResourcesFilters extends UarResourcesFilters {
    UserAccessReviewGuid?: string;
}
