import React, {
  useCallback,
  useEffect,
  useRef
} from 'react';

import {
  ISearchBoxStyles,
  SearchBox
} from '@fluentui/react/lib/SearchBox';
import { throttle } from 'lodash';
import intl from 'react-intl-universal';


import { SearchControlProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';

const searchBoxStyles: ISearchBoxStyles = {
  root: {
    width: 240,
  },
};

const SEARCH_DEBOUNCE_WAIT = 200;

export const SearchControl: React.FC<SearchControlProps> = (
  props: SearchControlProps,
) => {
  const { onSearch } = props;

  const throttledOnSearch = useRef<(value: string) => void>(
    throttle(onSearch, SEARCH_DEBOUNCE_WAIT, {
      trailing: true
    }),
  );
  const onSearchQueryChanged = useCallback(
    (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
      throttledOnSearch.current(newValue || '');
    },
    [throttledOnSearch],
  );

  useEffect(() => {
    return () => {
      onSearch('');
    };
  }, []);

  return (
    <SearchBox
      className='searchBox'
      placeholder={intl.get(LocIds.Placeholder.TypeToFilter)}
      styles={searchBoxStyles}
      underlined={false}
      onChange={onSearchQueryChanged}
    />
  );
};
