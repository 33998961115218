import React from 'react';

import {
  Icon, Link, Stack, Text
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { ToolTip } from '../ToolTip';

type IProps = {
  texts: string[];
  tooltipText?: string;
  collapsible?: boolean;
}

export const WrapTextArray: React.FC<IProps> = (props) => {
  const { texts, tooltipText, collapsible = false } = props;

  const [isCollapsed, { toggle: toggleIsCollapsed }] = useBoolean(true);

  if (collapsible && isCollapsed) {
    return (
      <Link onClick={toggleIsCollapsed}>
        { intl.get(LocIds.Label.PlusMore, {
          number: texts.length
        }) }
      </Link>
    );
  }

  return (
    <Stack
      horizontal
      tokens={{
        childrenGap: 4
      }}
      wrap>
      { texts.map((s, index) => (
        <Text className='evidenceMetaInfo-italic' key={index}>
          { index === texts.length - 1 ? s : s + ', ' }
        </Text>
      )) }
      { tooltipText && (
        <div className='evidenceMetaInfo-tooltip'>
          <ToolTip content={tooltipText}>
            <Icon iconName='Info' />
          </ToolTip>
        </div>
      ) }
      { collapsible && (
        <Link onClick={toggleIsCollapsed}>
          { intl.get(LocIds.Components.ViewLess) }
        </Link>
      ) }
    </Stack>
  );
};
