import React, { ReactNode } from 'react';

import ActionRequiredLarge from './illustrations/ActionRequiredLarge';
import ActionRequiredSmall from './illustrations/ActionRequiredSmall';
import CrashLarge from './illustrations/CrashLarge';
import CrashSmall from './illustrations/CrashSmall';
import EmptyLarge from './illustrations/EmptyLarge';
import EmptySmall from './illustrations/EmptySmall';
import FileDelayLarge from './illustrations/FileDelayLarge';
import FileDelaySmall from './illustrations/FileDelaySmall';
import NotFoundLarge from './illustrations/NotFoundLarge';
import NotFoundSmall from './illustrations/NotFoundSmall';
import RestrictedLarge from './illustrations/RestrictedLarge';
import RestrictedSmall from './illustrations/RestrictedSmall';

export type BannerImageIconName = 'crash' | 'empty' | 'not-found' | 'restricted' | 'file-delay' | 'action-required';
export type BannerImageIconSize = 'small' | 'large';

export interface BannerImageProps {
  iconName: BannerImageIconName;
  iconSize: BannerImageIconSize;
}

export const BannerImageMap: Record<string, ReactNode> = {
  'crash-small': <CrashSmall/>,
  'crash-large': <CrashLarge/>,
  'empty-small': <EmptySmall/>,
  'empty-large': <EmptyLarge/>,
  'not-found-small': <NotFoundSmall/>,
  'not-found-large': <NotFoundLarge/>,
  'restricted-small': <RestrictedSmall/>,
  'restricted-large': <RestrictedLarge/>,
  'file-delay-large': <FileDelayLarge/>,
  'file-delay-small': <FileDelaySmall/>,
  'action-required-large': <ActionRequiredLarge/>,
  'action-required-small': <ActionRequiredSmall/>,
};

export const BannerImage: React.FC<BannerImageProps> = ({ iconName, iconSize }: BannerImageProps) => {
  const key = `${iconName}-${iconSize}`;
  return <>{ BannerImageMap[key] }</> || null;
};
