import _ from 'lodash';

import { DocTreeVisitor } from './DocTreeVisitor';

import { DocTreeNode } from '../DocTreeNode/DocTreeNode';
import { DocTreeRootNode } from '../DocTreeNode/DocTreeRootNode';

export class CollapseVisitor implements DocTreeVisitor {
  constructor(uid: string) {
    this._uid = uid;
  }

  public visitDocTreeRootNode(node: DocTreeRootNode): void {
    node.children.forEach((n: DocTreeNode) => n.accept(this));
  }

  public visitDocTreeNode(node: DocTreeNode): void {
    if (node.children.length !== 0) {
      node.children.forEach((n) => n.accept(this));
    }

    node.collapsed = _.every(node.children, c => c.collapsed) && !(node.uid === this._uid);
  }

  private _uid: string | null;
}
