import React from 'react';

import { Reducer } from 'redux';

import { FCC } from '../../models';
import { useInjectReducer } from '../react-hooks/useInjectReducer';

// Newer typescript compilers support trailing comma const foo = <T,>(x: T) => x; to sidestep the JSX ambiguity
export const withReducer =
  <P extends Record<string, never>, >(namespace: string, reducer: Reducer) =>
    (WrappedComponent: FCC<P>) =>
      (props: P): React.ReactElement | null => {
        const injected = useInjectReducer(namespace, reducer);
        return injected ? <WrappedComponent {...props} /> : null;
      };
