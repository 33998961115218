import {
  useEffect, useState
} from 'react';

import _ from 'lodash';

import { Id } from 'react-toastify';

import { notify } from '../notify';

/**
 * Custom React hook for displaying notifications. Duplicated notifications for a same messageKey are not shown.
 *
 * @param messageKey - The key for the notification message.
 * @param message - The content of the notification message.
 * @param showNotification - Determines whether to show the notification.
 * @param toastOptions - Optional options for the toast notification.
 */
export const useNotification = (
  messageKey: string,
  message: React.ReactNode,
  showNotification: boolean,
  autoClose?: number | false | undefined
) => {
  const [notifications, setNotifications] = useState<Record<string, Id | undefined>>({});

  const notificationId = notifications[messageKey];

  useEffect(() => {
    if (showNotification && _.isNil(notificationId)) {
      const messageId = notify.warning(message, {
        autoClose
      });

      setNotifications(prevState => ({
        ...prevState,
        [messageKey]: messageId
      }));
    }

    return () => {
      if (!_.isNil(notificationId)) {
        notify.dismiss(notificationId);

        setNotifications(prevState => ({
          ...prevState,
          [messageKey]: undefined
        }));
      }
    };
  },
  // message is not needed in the dependency array as it could be a react node and its update will cause unnecessary re-renders
  [
    messageKey,
    notificationId,
    showNotification,
    autoClose
  ]);
};
