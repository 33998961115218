export enum StatusType {
  Info,
  Warning,
  Error,
  ErrorBadge,
  Revoked,
  Completed,
  Pending
}

export interface StatusIconProps {
  type: StatusType;
  size?: 'small' | 'normal' | 'large' | 'xLarge';
  tooltipContent?: string;
}
