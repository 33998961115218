/* eslint-disable i18next/no-literal-string */
import {
  mergeStyleSets,
  IDetailsListStyles
} from '@fluentui/react';

// Grid styles
export const classNames = mergeStyleSets({
  focusZone: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  selectionZone: {
    height: '100%',
    overflow: 'hidden',
  }
});

export const focusZoneProps = {
  'className': classNames.focusZone,
  'data-is-scrollable': 'true',
} as React.HTMLAttributes<HTMLElement>;

export const gridStyles: Partial<IDetailsListStyles> = {
  root: {
    overflowX: 'scroll',
    selectors: {
      '& [role=grid]': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        height: '60vh',
        marginLeft: '-10px'
      },
      '& [data-automationid=DetailsHeader]': {
        paddingTop: '0 !important'
      },
    },
  },
  headerWrapper: {
    flex: '0 0 auto',
  },
  contentWrapper: {
    flex: '1 1 auto',
    overflow: 'hidden',
  },
};
