import { CSSProperties } from 'react';

import {
  ISpinnerStyles,
  IStackTokens
} from '@fluentui/react';

export const spinnerStyle: ISpinnerStyles = {
  root: {
    paddingTop: 10
  }
};

export const tabContentStyle: Partial<CSSProperties> = {
  paddingTop: 20
};

export const tableStackTokens: IStackTokens = {
  childrenGap: 10
};

export const gatingDetailsTables: Partial<CSSProperties> = {
  padding: 16,
  border: '1px',
  borderRadius: '4',
  boxShadow: '0 3.2px 7.2px 0 rgba(0,0,0,.132)'
};

export const gatingDetailsTableHeading: Partial<CSSProperties> = {
  padding: '15px 0px'
};
