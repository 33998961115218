import { IColumn } from '@fluentui/react';

import intl from 'react-intl-universal';

import { LocIds } from '../../../../../common/Globalization/IntlEnum';

export const getBuildEvidenceColumns = (): IColumn[] => [
  {
    key: 'type',
    name: intl.get(LocIds.Gating.Type),
    minWidth: 200,
    maxWidth: 250,
    fieldName: 'Type'
  },
  {
    key: 'value',
    name: intl.get(LocIds.Gating.Result),
    minWidth: 200,
    maxWidth: 250,
    fieldName: 'Value'
  },
  {
    key: 'isRun',
    name: intl.get(LocIds.Gating.Run),
    minWidth: 200,
    maxWidth: 250,
    fieldName: 'IsRun'
  },
  {
    key: 'exists',
    name: intl.get(LocIds.Gating.Exists),
    minWidth: 200,
    maxWidth: 250,
    fieldName: 'Exists'
  },
];
