/* eslint-disable @typescript-eslint/no-namespace */
/**
 * This file contains Athena Rest Apis
 */

import { OdataQuery } from 'odata';
import { PlainObject } from 'odata-query';

import {
  AddAuditEventPayload,
  AddContinuousMonitoringSchedulePayload,
  AuditEvent,
  AuditEventCertification,
  AuditFlags,
  ComplianceAuditAdoConfigurationOptions,
  ContinuousMonitoringSchedule,
  CreatePublishedAuditEventCommentRequestPayload,
  OffboardServiceFromConMonSchedulePayload,
  OnboardServiceToConMonSchedulePayload,
  PublishedAuditEvent,
  ReusedByInfo,
  ServicePayloadItem,
  UpdateAuditEventPayload,
  UpdateContinuousMonitoringSchedulePayload
} from '../../models';
import {
  diffAdoConfiguration,
  diffAipConfiguration,
  diffAuditEvent,
  diffReuseConfigurations,
  getAddedSocIntroPayloadItems
} from '../../utils';

import {
  get, post
} from '../OdataApiService';
import {
  buildODataQuery, fieldMatchOnly
} from '../utils/odataQueryBuilder';

export const GET_AUDIT_EVENT_API = '/data/ComplianceAuditDetails';
export const GET_PUBLISHED_AUDIT_EVENT_API = '/data/PublishedAuditEvent';
export const GET_COMPLIANCE_AUDIT_ADO_CONFIGURATION_OPTIONS_API = 'data/GetComplianceAuditAdoConfigurationOptions';
export const GET_COMPLIANCE_AUDIT_ADO_API = 'data/ComplianceAuditAdo';
export const GET_PUBLIC_COMPLIANCE_AUDIT_INFO = 'data/PublicComplianceAuditInfo';

export const UPDATE_AUDIT_EVENT_API = '/data/UpdateComplianceAuditDetails';
export const CREATE_NEW_AUDIT_EVENT_API = '/data/AddComplianceAuditDetails';

export const GET_CONTINUOUS_MONITORING_SCHEDULES_API = '/data/ContinuousMonitoringSchedule';
export const ADD_CONTINUOUS_MONITORING_SCHEDULE_API = '/data/AddContinuousMonitoringSchedule';
export const UPDATE_CONTINUOUS_MONITORING_SCHEDULE_API = '/data/UpdateContinuousMonitoringSchedule';
export const GET_AUDIT_EVENT_REUSED_BY_INFO_API = '/data/GetComplianceAuditReusedByInfo';

export const CREATE_PUBLISHED_AUDIT_EVENT_COMMENT_API = '/data/CreatePublishedAuditEventCommentRequest';

export namespace AuditEventApi {
  export const getAuditEventByGuidAsync = async (
    auditEventGuid: string,
  ): Promise<AuditEvent> => {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: auditEventGuid,
          type: 'guid'
        },
        'AuditEventGuid',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<AuditEvent[]>(GET_AUDIT_EVENT_API, config);
    return data[0];
  };

  export const getAuditEventsAsync = async (
    isActiveOnly?: boolean,
    orderKey?: string,
    isAscending?: boolean,
  ): Promise<AuditEvent[]> => {
    const odataQuery = buildODataQuery({
      filter: isActiveOnly ?
        {
          EndDate: {
            ge: new Date(Date.now())
          }
        } :
        undefined,
      orderBy: orderKey ?
        `${orderKey} ${!isAscending ? 'desc' : 'asc'}` :
        'Name asc',
    });

    return await get<AuditEvent[]>(GET_AUDIT_EVENT_API, {
      odataQuery,
    });
  };

  export const getNonReusableAuditEventsAsync = async(): Promise<AuditEvent[]> => {
    const odataQuery = buildODataQuery({
      filter:
        {
          IsPublishedEvidenceReusable: {
            eq: false
          }
        }
    });

    return await get<AuditEvent[]>(GET_AUDIT_EVENT_API, {
      odataQuery,
    });
  };

  export const getPublishedAuditEventsAsync = async (
    isActiveOnly?: boolean,
    isPublishedEvidenceReusable?: boolean
  ): Promise<AuditEvent[]> => {
    const filter = getPublishedAuditEventsFilter(isActiveOnly, isPublishedEvidenceReusable);
    const odataQuery = buildODataQuery({
      filter,
      orderBy: 'Name asc',
    });

    return await get<AuditEvent[]>(GET_PUBLISHED_AUDIT_EVENT_API, {
      odataQuery,
    });
  };

  const getPublishedAuditEventsFilter = (
    isActiveOnly: boolean | undefined,
    isPublishedEvidenceReusable: boolean | undefined
  ): PlainObject | undefined => {
    if (!isActiveOnly && !isPublishedEvidenceReusable) {
      return undefined;
    }

    const filter:PlainObject = {};

    if (isActiveOnly) {
      filter['EndData'] = {
        ge: new Date(Date.now())
      };
    }

    if (isPublishedEvidenceReusable) {
      filter['isPublishedEvidenceReusable'] = {
        eq: true
      };
    }

    return filter;
  };

  export const getPublicComplianceAuditInfoAsync = async (
    isActiveOnly?: boolean,
    orderKey?: string,
    isAscending?: boolean,
  ): Promise<AuditEvent[]> => {
    const odataQuery = buildODataQuery({
      filter: isActiveOnly ?
        {
          IsActive: {
            eq: true
          }
        } :
        undefined,
      orderBy: orderKey ?
        `${orderKey} ${!isAscending ? 'desc' : 'asc'}` :
        'Name asc',
    });

    return await get<AuditEvent[]>(GET_PUBLIC_COMPLIANCE_AUDIT_INFO, {
      odataQuery,
    });
  };

  export const getPublishedAuditEventByGuidAsync = async (
    auditEventGuid: string,
  ): Promise<PublishedAuditEvent> => {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: auditEventGuid,
          type: 'guid'
        },
        'AuditEventGuid',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<PublishedAuditEvent[]>(GET_PUBLISHED_AUDIT_EVENT_API, config);
    return data[0];
  };

  export const getPublicComplianceAuditInfoByGuidAsync = async (
    auditEventGuid: string,
  ): Promise<AuditEvent> => {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: auditEventGuid,
          type: 'guid'
        },
        'AuditEventGuid',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<AuditEvent[]>(GET_PUBLIC_COMPLIANCE_AUDIT_INFO, config);
    return data[0];
  };

  export const getAdoConfigurationOptionsAsync =
    async (): Promise<ComplianceAuditAdoConfigurationOptions> => {
      return await post<Record<string, unknown>, ComplianceAuditAdoConfigurationOptions>(
        GET_COMPLIANCE_AUDIT_ADO_CONFIGURATION_OPTIONS_API,
        {}
      );
    };

  const getUpdateServicesPayload = (
    certifications: AuditEventCertification[],
  ): ServicePayloadItem[] => {
    const addServices: ServicePayloadItem[] = [];

    for (let i = 0; i < certifications.length; i++) {
      const certificationFamilyId = certifications[i].CertificationFamilyId;
      const environments = certifications[i].Environments;

      for (let j = 0; j < environments.length; j++) {
        const environmentId = environments[j].EnvironmentId;
        const services = environments[j].Services;

        for (let k = 0; k < services.length; k++) {
          const serviceID = services[k].Id;
          addServices.push({
            CertificationFamilyId: certificationFamilyId,
            EnvironmentId: environmentId,
            ServiceId: serviceID,
          });
        }
      }
    }

    return addServices;
  };

  export const getContinuousMonitoringSchedulesAsync = async (auditEventGuid: string): Promise<ContinuousMonitoringSchedule[]> => {
    const odataQuery = buildODataQuery({
      filter: {
        AuditEventGuid: {
          type: 'guid',
          value: auditEventGuid,
        },
      }
    });
    const config = {
      odataQuery,
    };
    return await get<ContinuousMonitoringSchedule[]>(GET_CONTINUOUS_MONITORING_SCHEDULES_API, config);
  };

  export const updateAuditEventAsync = async (original: AuditEvent, modified: AuditEvent): Promise<void> => {
    const diffResult = diffAuditEvent(original, modified);
    return await post<UpdateAuditEventPayload, void>(UPDATE_AUDIT_EVENT_API, {
      ...diffResult
    });
  };

  export const createNewAuditEventAsync = async (
    audit: AuditEvent,
  ): Promise<void> => {
    const data: AddAuditEventPayload = {
      setName: {
        Value: audit.Name
      },
      setDescription: {
        Value: audit.Description
      },
      setStartDate: {
        Value: new Date(audit.StartDate)
      },
      setEndDate: {
        Value: new Date(audit.EndDate)
      },
      addAuditors: audit.Auditors.map((auditor) => auditor.ObjectId),
      addOwners: audit.Owners.map((owner) => owner.ObjectId),
      addServices: getUpdateServicesPayload(audit.Certifications),
      setIsAutoPublishEnabled: {
        Value: audit.IsAutoPublishEnabled
      },
      setCanBypassPublishScopeCheck: {
        Value: audit.CanBypassPublishScopeCheck
      },
      setAuditTeamEmail: audit.AuditTeamEmail ?
        {
          Value: audit.AuditTeamEmail
        } :
        undefined,
      setAuditorCompany: audit.AuditorCompany ?
        {
          Value: audit.AuditorCompany
        } :
        undefined,
      setAdoConfiguration: diffAdoConfiguration(null, audit.AdoConfiguration)
        .setAdoConfiguration,
      addReuseConfigurations:
        diffReuseConfigurations([], audit.ReuseConfigurations)
          .addReuseConfigurations ?? undefined,
      setIsReuseFromAllEnabled: {
        Value: audit.IsReuseFromAllEnabled
      },
      setIsPublishedEvidenceReusable: {
        Value: audit.IsPublishedEvidenceReusable
      },
      setIsEvidenceConclusionDisabled: {
        Value: audit.IsEvidenceConclusionDisabled
      },
      setIsMessagingEnabled: {
        Value: audit.IsMessagingEnabled
      },
      addSocIntros: getAddedSocIntroPayloadItems(audit.SocIntros),
      setAipConfiguration: diffAipConfiguration(null, audit.AipConfiguration).setAipConfiguration,
      setAuditFlags: audit.AuditFlags.reduce((acc, flag) => {
        acc[flag] = true;
        return acc;
      }, {} as Partial<Record<AuditFlags, boolean>>),
    };

    return await post<AddAuditEventPayload, void>(
      CREATE_NEW_AUDIT_EVENT_API,
      {
        ...data
      }
    );
  };

  export const addContinuousMonitoringScheduleAsync = async (payload: AddContinuousMonitoringSchedulePayload): Promise<void> => {
    return await post<AddContinuousMonitoringSchedulePayload, void>(
      ADD_CONTINUOUS_MONITORING_SCHEDULE_API,
      payload
    );
  };

  export const updateContinuousMonitoringScheduleAsync = async (payload: UpdateContinuousMonitoringSchedulePayload): Promise<void> => {
    return await post<UpdateContinuousMonitoringSchedulePayload, void>(
      UPDATE_CONTINUOUS_MONITORING_SCHEDULE_API,
      payload
    );
  };

  export const onboardServiceToConMonScheduleAsync = async (
    { taskId, serviceTreeId, environmentId, assignedTo, assignedToAccountGuid }: OnboardServiceToConMonSchedulePayload,
  ): Promise<void> => {
    const payload: UpdateContinuousMonitoringSchedulePayload = {
      taskId,
      addCertificationControls: [],
      updateServices: [],
      removeCertificationControls: [],
      removeServices: [],
      updateEnvironments: [],
      addServices: [
        {
          ServiceTreeId: serviceTreeId,
          EnvironmentId: environmentId,
          AssignedTo: assignedTo,
          AssignedToAccountGuid: assignedToAccountGuid
        }
      ]
    };

    return await post<UpdateContinuousMonitoringSchedulePayload, void>(
      UPDATE_CONTINUOUS_MONITORING_SCHEDULE_API,
      payload
    );
  };

  export const offboardServiceFromConMonScheduleAsync = async (
    { taskId, serviceTreeId, environmentId }: OffboardServiceFromConMonSchedulePayload,
  ): Promise<void> => {
    const payload: UpdateContinuousMonitoringSchedulePayload = {
      taskId,
      addCertificationControls: [],
      updateServices: [],
      removeCertificationControls: [],
      removeServices: [
        {
          ServiceTreeId: serviceTreeId,
          EnvironmentId: environmentId,
        }
      ],
      updateEnvironments: [],
      addServices: []
    };

    return await post<UpdateContinuousMonitoringSchedulePayload, void>(
      UPDATE_CONTINUOUS_MONITORING_SCHEDULE_API,
      payload
    );
  };

  export const getAuditEventReusedByInfoAsync = async (auditEventGuid: string): Promise<ReusedByInfo[]> => {
    const payload = {
      auditEventGuid,
    };
    return await post<{auditEventGuid: string}, ReusedByInfo[]>(GET_AUDIT_EVENT_REUSED_BY_INFO_API, payload);
  };

  export const createPublishedAuditEventComment = async (payload: CreatePublishedAuditEventCommentRequestPayload): Promise<void> => {
    return await post<CreatePublishedAuditEventCommentRequestPayload, void>(
      CREATE_PUBLISHED_AUDIT_EVENT_COMMENT_API,
      {
        ...payload
      }
    );
  };
}
