import './CurrentVersionModal.less';

import React, {
  useEffect, useState
} from 'react';

import {
  Icon, Modal
} from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';

import intl from 'react-intl-universal';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import {
  getOpenApiVersionAsync, portalConfigurationService
} from '../../../../services';
import { showErrorMsg } from '../../../../utils';


export interface CurrentVersionModalProps {
  isOpen: boolean;
  hideModal: () => void;
}

interface Versions {
  frontendVersion: string;
  backendVersion: string;
}

export const CurrentVersionModal: React.FC<CurrentVersionModalProps> = (props) => {
  const [versions, setVersions] = useState<Versions>({
    frontendVersion: '',
    backendVersion: ''
  });
  const { frontendVersion, backendVersion } = versions;

  const fetchVersions = async () => {
    try {
      const frontendVersion = portalConfigurationService.getVersion();
      const openApiVersion = await getOpenApiVersionAsync();
      setVersions({
        frontendVersion,
        backendVersion: openApiVersion.info.version
      });
    } catch (error) {
      showErrorMsg(error);
    }
  };

  useEffect(() => {
    fetchVersions();
  }, []);

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.hideModal}>
      <div className='modal-container'>
        <div className='modal-header'>
          <div className='title-18'>{ intl.get(LocIds.Application.Version) }</div>
          <IconButton
            iconProps={{
              iconName: 'Cancel'
            }}
            onClick={props.hideModal}
          />
        </div>
        <div className='modal-content'>
          <div className='info-row'>
            <Icon className='info-icon-large' iconName='TVMonitor' />
            <div>
              <div className='title-14'>{ intl.get(LocIds.Application.FrontendVersion) }</div>
              <div className='text-14'>{ frontendVersion }</div>
            </div>
          </div>
          <div className='info-row'>
            <Icon className='info-icon-large' iconName='Server' />
            <div>
              <div className='title-14'>{ intl.get(LocIds.Application.BackendVersion) }</div>
              <div className='text-14'>{ backendVersion }</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
