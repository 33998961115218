import { useState } from 'react';

export const usePagination = <T>(
  items: T[],
  // same with default value for Pagination.tsx
  itemsPerPage = 20,
) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return {
    currentItems,
    paginate,
  };
};
