import { IColumn } from '@fluentui/react';

import intl from 'react-intl-universal';

import { LocIds } from '../../../../../common/Globalization/IntlEnum';

export const getParameterColumns = (): IColumn[] => [
  {
    key: 'deployableService',
    name: intl.get(LocIds.Gating.DeployableServiceLabel),
    minWidth: 100,
    maxWidth: 150,
    fieldName: 'deployableService'
  },
  {
    key: 'organization',
    name: intl.get(LocIds.Gating.Organization),
    minWidth: 100,
    maxWidth: 150,
    fieldName: 'organization'
  },
  {
    key: 'project',
    name: intl.get(LocIds.Gating.Project),
    minWidth: 100,
    maxWidth: 150,
    fieldName: 'project'
  },
  {
    key: 'buildId',
    name: intl.get(LocIds.Gating.BuildId),
    minWidth: 100,
    maxWidth: 150,
    fieldName: 'buildId'
  },
  {
    key: 'deploymentEnvironment',
    name: intl.get(LocIds.Gating.DeploymentEnvironment),
    minWidth: 200,
    maxWidth: 250,
    fieldName: 'deploymentEnvironment'
  },
  {
    key: 'deploymentRing',
    name: intl.get(LocIds.Gating.DeploymentRing),
    minWidth: 200,
    maxWidth: 250,
    fieldName: 'deploymentRing'
  },
];
