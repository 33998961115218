
import {
  ContinuousMonitoringEnvironment,
  ContinuousMonitoringOnboardedSchedule,
  FilterOptionsForServiceWorkspace,
  ServiceContact,
  ServiceContactTypeNotification,
  ServiceDetail,
  UpdateServiceNotificationConfigPayload,
} from '../../models';
import {
  get,
  post
} from '../OdataApiService';
import { buildODataQuery, } from '../utils/odataQueryBuilder';

export const GET_FILTER_OPTIONS_FOR_SERVICE_WORKSPACE_API = '/data/GetFilterOptionsForServiceWorkspaceRequest';
export const GET_SERVICE_DETAILS_API = '/data/ServiceDetail';
export const GET_CONTINUOUS_MONITORING_ONBOARDED_SCHEDULES_API = '/data/ContinuousMonitoringOnboardedSchedules';
export const GET_CONTINUOUS_MONITORING_ENVIRONMENTS_API = '/data/ContinuousMonitoringEnvironments';
export const GET_SERVICE_CONTACTS_API = '/data/ServiceContactView';
export const GET_SERVICE_CONTACT_TYPE_NOTIFICATIONS_API = '/data/ServiceContactTypeNotification';
export const UPDATE_SERVICE_NOTIFICATION_CONFIG_API = '/data/UpdateServiceNotificationConfig';

export namespace ServiceWorkspaceApiService {
  export const getFilterOptionsForServiceWorkspaceAsync = async (): Promise<FilterOptionsForServiceWorkspace> => {
    return await post<any, FilterOptionsForServiceWorkspace>(
      GET_FILTER_OPTIONS_FOR_SERVICE_WORKSPACE_API,
      {});
  };

  export const getServiceDetailsAsync = async (): Promise<ServiceDetail[]> => {
    return await get<ServiceDetail[]>(GET_SERVICE_DETAILS_API);
  };

  export const getServiceDetailByIdAsync = async (serviceTreeId: string): Promise<ServiceDetail|undefined> => {
    const odataQuery = buildODataQuery({
      filter: {
        ServiceTreeId: {
          eq: {
            type: 'guid',
            value: serviceTreeId,
          }
        }
      }
    });
    const response = await get<ServiceDetail[]>(GET_SERVICE_DETAILS_API, {
      odataQuery
    });
    return response[0];
  };

  export const getOnboardedConMonSchedulesAsync = async (serviceTreeId: string): Promise<ContinuousMonitoringOnboardedSchedule[]> => {
    const odataQuery = buildODataQuery({
      filter: {
        ServiceTreeId: {
          value: serviceTreeId,
          type: 'guid',
        }
      }
    });
    return await get<ContinuousMonitoringOnboardedSchedule[]>(GET_CONTINUOUS_MONITORING_ONBOARDED_SCHEDULES_API, {
      odataQuery
    });
  };

  export const getContinuousMonitoringEnvironmentsAsync = async (): Promise<ContinuousMonitoringEnvironment[]> => {
    return await get<ContinuousMonitoringEnvironment[]>(GET_CONTINUOUS_MONITORING_ENVIRONMENTS_API);
  };

  export const getServiceContactsAsync = async (serviceTreeId: string): Promise<ServiceContact[]> => {
    const odataQuery = buildODataQuery({
      filter: {
        ServiceTreeId: {
          value: serviceTreeId,
          type: 'guid',
        }
      }
    });
    return await get<ServiceContact[]>(GET_SERVICE_CONTACTS_API, {
      odataQuery
    });
  };

  export const getServiceNotifiedRolesAsync = async (serviceTreeId: string): Promise<ServiceContactTypeNotification[]> => {
    const odataQuery = buildODataQuery({
      filter: {
        ServiceTreeId: {
          value: serviceTreeId,
          type: 'guid',
        }
      }
    });
    return await get<ServiceContactTypeNotification[]>(GET_SERVICE_CONTACT_TYPE_NOTIFICATIONS_API, {
      odataQuery
    });
  };

  export const updateServiceNotificationConfigAsync = async (
    payload: UpdateServiceNotificationConfigPayload
  ): Promise<void> => {
    await post<UpdateServiceNotificationConfigPayload, void>(
      UPDATE_SERVICE_NOTIFICATION_CONFIG_API,
      payload);
  };
}
