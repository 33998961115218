import React from 'react';

import {
  Icon, Label, Stack, TooltipHost
} from '@fluentui/react';

import { AzureAdMember } from '../../models';
import { EntityApi } from '../../services';
import {
  PeoplePickerViewMode, ThrottledPeoplePicker
} from '../PeoplePicker';

type PersonPickerProps = {
  label?: string;
  labelTooltip?: string;
  selected?: AzureAdMember;
  onChange?: (value?: AzureAdMember) => void;
  disabled?: boolean;
  required?: boolean;
};

export const PersonPicker: React.FC<PersonPickerProps> = (props) => {
  const { label, labelTooltip, selected, onChange } = props;

  const labelJsx = (
    <Stack horizontal
      verticalAlign='center'>
      <Label required={props.required}>{ label }</Label>
      { labelTooltip && (
        <TooltipHost
          content={labelTooltip}
          styles={{
            root: {
              display: 'flex'
            }
          }}>
          <Icon iconName='Info' />
        </TooltipHost>
      ) }
    </Stack>
  );

  return (
    <Stack tokens={{
      childrenGap: 1
    }}>
      { label && labelJsx }
      <ThrottledPeoplePicker
        disabled={props.disabled}
        itemLimit={1}
        selectedEntities={selected ? [selected] : []}
        viewMode={PeoplePickerViewMode.normal}
        onChange={(items: AzureAdMember[]) => onChange && onChange(items[0])}
        onSearch={(value) => EntityApi.searchUsersStringAsync(value, true, true)}
      />
    </Stack>
  );
};
