/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props}
      fill='none'
      height='86'
      viewBox='0 0 98 86'
      width='98'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M97.0641 61.0135L95.2258 62.0826C95.7577 61.7723 96.0889 61.1152 96.0915 60.1792L97.9297 59.1101C97.9271 60.0462 97.5986 60.7033 97.0641 61.0135Z' fill='#D2D2D2'/>
      <path d='M96.1618 35.7789L96.0914 60.1793C96.0862 62.0644 94.7538 62.8232 93.1137 61.8767L52.8081 38.6028C51.1706 37.6563 49.8434 35.3617 49.8486 33.4792L49.919 9.07886L96.1618 35.7789Z' fill='#FAFAFA'/>
      <path d='M96.162 35.7788L98.0002 34.7097L97.9298 59.11L96.0916 60.1765L96.162 35.7788Z' fill='#D2D2D2'/>
      <path d='M49.9189 9.07889L51.7572 8.01245L97.9999 34.7099L96.1617 35.779L49.9189 9.07889Z' fill='#B4B4B4'/>
      <path d='M50.7847 1.26964L52.6229 0.200596C53.1626 -0.112295 53.911 -0.0705766 54.7349 0.406583L52.8967 1.47563C52.0727 1.00108 51.3244 0.95675 50.7847 1.26964Z' fill='#185A97'/>
      <path d='M96.1619 29.8732L98.0001 28.8042L97.9819 34.6136L96.1462 35.68L96.1619 29.8732Z' fill='#185A97'/>
      <path d='M52.8967 1.47823L54.735 0.40918L95.0406 23.6805L93.2024 24.7495L52.8967 1.47823Z' fill='#185A97'/>
      <path d='M93.2024 24.7495C94.8425 25.696 96.1671 27.9905 96.1619 29.8731L96.1462 35.6798L49.9009 8.98235L49.9165 3.1756C49.9217 1.29304 51.2567 0.531672 52.8942 1.47817L93.2024 24.7495Z' fill='#0078D4'/>
      <path d='M93.2024 24.7495L95.0406 23.6804C96.6807 24.6269 98.0053 26.9215 98.0001 28.804L96.1618 29.8731C96.167 27.9905 94.8425 25.696 93.2024 24.7495Z' fill='#185A97'/>
      <path d='M72.1398 69.3219L70.3015 70.3909C70.8334 70.0806 71.1646 69.4236 71.1672 68.4875L73.0054 67.4185C73.0028 68.3545 72.6743 69.0116 72.1398 69.3219Z' fill='#D2D2D2'/>
      <path d='M71.2375 44.0873L71.1671 68.4876C71.1619 70.3728 69.8295 71.1315 68.1894 70.185L27.8838 46.9111C26.2463 45.9646 24.9191 43.6701 24.9243 41.7875L24.9947 17.3872L71.2375 44.0873Z' fill='#FAFAFA'/>
      <path d='M71.2376 44.0871L73.0759 43.0181L73.0055 67.4184L71.1672 68.4848L71.2376 44.0871Z' fill='#D2D2D2'/>
      <path d='M24.9946 17.3872L26.8329 16.3208L73.0756 43.0183L71.2374 44.0873L24.9946 17.3872Z' fill='#D2D2D2'/>
      <path d='M25.8604 9.57775L27.6986 8.5087C28.2383 8.19581 28.9867 8.23753 29.8106 8.71469L27.9724 9.78374C27.1484 9.30918 26.4001 9.26486 25.8604 9.57775Z' fill='#0078D4'/>
      <path d='M71.2376 38.1814L73.0758 37.1123L73.0576 42.9217L71.2219 43.9881L71.2376 38.1814Z' fill='#0078D4'/>
      <path d='M27.9724 9.78633L29.8106 8.71729L70.1163 31.9886L68.2781 33.0576L27.9724 9.78633Z' fill='#0078D4'/>
      <path d='M68.2781 33.0576C69.9182 34.0041 71.2428 36.2986 71.2376 38.1812L71.2219 43.9879L24.9766 17.2905L24.9922 11.4837C24.9974 9.60115 26.3324 8.83978 27.9699 9.78627L68.2781 33.0576Z' fill='#139FE5'/>
      <path d='M68.2781 33.0576L70.1163 31.9885C71.7564 32.935 73.081 35.2296 73.0757 37.1121L71.2375 38.1812C71.2427 36.2986 69.9181 34.0041 68.2781 33.0576Z' fill='#0078D4'/>
      <path d='M47.2154 77.63L45.3772 78.699C45.9091 78.3887 46.2403 77.7317 46.2429 76.7956L48.0811 75.7266C48.0785 76.6626 47.75 77.3197 47.2154 77.63Z' fill='#D2D2D2'/>
      <path d='M46.3132 52.3954L46.2428 76.7957C46.2376 78.6809 44.9052 79.4396 43.2651 78.4931L2.95945 55.2192C1.32198 54.2727 -0.00519954 51.9782 1.53155e-05 50.0956L0.070415 25.6953L46.3132 52.3954Z' fill='#FAFAFA'/>
      <path d='M46.3133 52.3952L48.1516 51.3262L48.0812 75.7265L46.2429 76.7929L46.3133 52.3952Z' fill='#D2D2D2'/>
      <path d='M0.0703125 25.6953L1.90855 24.6289L48.1513 51.3264L46.3131 52.3954L0.0703125 25.6953Z' fill='#E7E7E7'/>
      <path d='M0.935791 17.8859L2.77403 16.8168C3.31377 16.5039 4.0621 16.5456 4.88605 17.0228L3.04781 18.0918C2.22386 17.6173 1.47553 17.573 0.935791 17.8859Z' fill='#2FA7DB'/>
      <path d='M46.313 46.4895L48.1512 45.4204L48.133 51.2298L46.2974 52.2962L46.313 46.4895Z' fill='#2FA7DB'/>
      <path d='M3.04785 18.0944L4.88609 17.0254L45.1917 40.2967L43.3535 41.3657L3.04785 18.0944Z' fill='#2FA7DB'/>
      <path d='M43.3536 41.3657C44.9937 42.3122 46.3182 44.6067 46.313 46.4893L46.2974 52.296L0.052002 25.5986L0.0676469 19.7918C0.0728617 17.9093 1.40787 17.1479 3.04533 18.0944L43.3536 41.3657Z' fill='#45CAF2'/>
      <path d='M43.3535 41.3657L45.1917 40.2966C46.8318 41.2431 48.1564 43.5377 48.1512 45.4202L46.3129 46.4893C46.3182 44.6067 44.9936 42.3122 43.3535 41.3657Z' fill='#2FA7DB'/>
      <path d='M12.7145 62.374C13.1485 61.8624 13.7238 61.3749 14.4097 60.9728C15.0957 60.5694 15.8008 60.3034 16.4587 60.1736C17.711 59.9267 18.7915 60.1748 19.2395 60.9232L28.1393 76.0637L27.0703 75.9479C26.1705 75.8499 25.3687 75.332 24.9105 74.5518L24.81 74.3813C25.4183 75.4147 25.0722 76.7459 24.0388 77.3529C23.0053 77.96 21.6741 77.6151 21.0671 76.5817L21.1638 76.7459C21.6245 77.5298 21.6881 78.4856 21.3343 79.3242L20.9182 80.3092L12.0171 65.1687C11.5818 64.414 11.8898 63.3488 12.7145 62.374Z' fill='#FBA919'/>
      <path d='M7.47224 57.4373C6.79645 56.2881 7.86549 54.4059 9.85977 53.2325C11.854 52.0604 14.0189 52.0413 14.6946 53.1918C14.6972 53.1956 14.6985 53.2007 14.701 53.2045L16.9664 57.0581L9.74395 61.3036L7.47224 57.4373Z' fill='#F48789'/>
      <path d='M9.74543 61.3026C9.06964 60.1534 10.1387 58.2711 12.133 57.0978C14.1272 55.9257 16.2921 55.9066 16.9678 57.057C16.9704 57.0609 16.9717 57.066 16.9742 57.0698L19.2396 60.9233L12.0171 65.1688L9.74543 61.3026Z' fill='#C8C8C8'/>
      <path d='M24.2512 74.4678C25.3737 74.072 27.4635 74.9132 28.1392 76.0637L28.871 85.5741L20.9168 80.3079C20.4447 79.5036 20.1278 77.6329 21.081 76.6058C21.4908 76.1642 23.5945 74.6994 24.2512 74.4678Z' fill='#FFF5CF'/>
      <path d='M27.3999 83.071C27.9268 82.7605 28.4995 82.7567 28.6777 83.0595L28.8711 85.573L26.7687 84.1807C26.5905 83.8791 26.8731 83.3815 27.3999 83.071Z' fill='black'/>
      <path d='M24.9106 74.5506L16.4587 60.1736C17.711 59.9267 18.7915 60.1748 19.2395 60.9232L28.1393 76.0637L27.0703 75.9479C26.1705 75.8499 25.3687 75.332 24.9106 74.5506Z' fill='#E29626'/>
      <path d='M12.7145 62.374L21.1625 76.7459C21.6232 77.5299 21.6869 78.4856 21.3331 79.3243L20.9169 80.3093L12.0171 65.1687C11.5818 64.4141 11.8898 63.3489 12.7145 62.374Z' fill='#D08829'/>
      <path d='M24.0386 77.3529C23.0052 77.96 21.674 77.6151 21.0669 76.5817L12.7144 62.374C13.1483 61.8624 13.7236 61.375 14.4096 60.9728C15.0955 60.5694 15.8006 60.3034 16.4586 60.1736L24.8098 74.38C25.4182 75.4147 25.072 76.7446 24.0386 77.3529Z' fill='#FBA919'/>
    </svg>
  );
};
