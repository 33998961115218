import React from 'react';

import {
  ActionButton,
  IButtonStyles
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

interface IProps {
  showFullCard: boolean;
  toggleShowFullCard: () => void;
  styles?: IButtonStyles;
}

export const CardShowMoreButton: React.FC<IProps> = (props) => {
  const { showFullCard, toggleShowFullCard, styles } = props;
  return (
    <ActionButton
      className='cardView__showMoreButton'
      iconProps={{
        iconName: !showFullCard ? 'ChevronDown' : 'ChevronUp'
      }}
      styles={styles}
      text={!showFullCard ? intl.get(LocIds.Action.ShowMore) : intl.get(LocIds.Action.ShowLess)}
      onClick={() => toggleShowFullCard()}
    />
  );
};
