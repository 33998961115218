import React from 'react';

import {
  ContextualMenu,
  DirectionalHint,
  IconButton,
  IContextualMenuItemProps,
  IContextualMenuItemRenderFunctions,
  IContextualMenuProps,
  Spinner,
  Stack,
} from '@fluentui/react';
import intl from 'react-intl-universal';

import {
  CardActionButton,
  CardActionButtonStyle
} from './CardActionButton';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { ICommandBarButtonProps } from '../M365Button/M365CommandBarButton/M365CommandBarButton';

interface CardButtonGroupProps {
  style?: CardActionButtonStyle;
  items: ICommandBarButtonProps[];
}

export interface CardActionBarProps {
  status?: JSX.Element;
  buttonGroupProps?: CardButtonGroupProps;
  menuProps?: IContextualMenuProps;
}

export const CardActionBar: React.FC<CardActionBarProps> = (props) => {
  const { status, buttonGroupProps, menuProps } = props;

  const { style = CardActionButtonStyle.Default, items } =
    buttonGroupProps ?? {};

  const loadableItems = (menuProps?.items || []).map(i => ({
    ...i,
    disabled: i.disabled || i.loading,
    onRenderContent: (props: IContextualMenuItemProps, defaultRenders: IContextualMenuItemRenderFunctions) => {
      return (
        <>
          { props.item.loading ? <Spinner size={1}/> : defaultRenders.renderItemIcon(props) }
          { defaultRenders.renderItemName(props) }
          { defaultRenders.renderSubMenuIcon(props) }
          { defaultRenders.renderSecondaryText(props) }
          { defaultRenders.renderCheckMarkIcon(props) }
        </>
      );
    }
  } ));

  const menu = menuProps ? (
    <IconButton
      ariaLabel={intl.get(LocIds.Label.MoreAction)}
      iconProps={{
        iconName: 'More'
      }}
      id={menuProps.id}
      menuAs={(props) => (
        <ContextualMenu
          {...props}
          directionalHint={DirectionalHint.bottomRightEdge}
        />
      )}
      menuProps={{
        items: loadableItems
      }}
      title={intl.get(LocIds.Label.MoreAction)}
      onRenderMenuIcon={() => null}
    />
  ) : undefined;

  return (
    <Stack
      horizontal
      horizontalAlign='end'
      tokens={{
        childrenGap: 8
      }}
      verticalAlign='center'
      wrap>
      { status }
      { items?.map((bp, index) => (
        <CardActionButton {...bp} buttonStyle={style} key={index} />
      )) }
      { menu }
    </Stack>
  );
};
