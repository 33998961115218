import React from 'react';

import {
  DetailsList,
  IColumn,
  IGroup,
  IObjectWithKey,
  ISelection,
  MarqueeSelection,
  Persona,
  PersonaSize,
  Stack,
  Text,
} from '@fluentui/react';

import { GroupedListV2FC } from '@fluentui/react/lib/GroupedList';
import intl from 'react-intl-universal';

import { LocIds } from '../../../../../../common/Globalization/IntlEnum';
import {
  IndicatorLabel, LabelColor, PeoplePickerViewMode, ThrottledPeoplePicker,
} from '../../../../../../components';
import { EntityApi } from '../../../../../../services';
import { getConcatenatedUid } from '../../../../../../utils';
import {
  ConMonAuditEvent, ConMonTask, ConMonTaskEnvironment,
} from '../../../../models/ContinuousMonitoringTypes';
import { useAppDispatch } from '../../redux/reducer';
import {
  updateAssignedTo, useAssignedTos
} from '../../redux/serviceSlice';

type IProps = {
  auditEvents: ConMonAuditEvent[];
  selection: ISelection<IObjectWithKey>;
  editAssignedTo?: boolean;
};

export interface ContinuousMonitoringTaskEnvironmentWithKey
  extends ConMonTaskEnvironment {
  // AuditEventGuid + TaskId + EnvironmentId
  key: string;
}

export const ScheduleList: React.FC<IProps> = (props) => {
  const { auditEvents, selection, editAssignedTo = false } = props;

  const dispatch = useAppDispatch();
  const assignedTos = useAssignedTos();

  const [groups, environments] = createGroups(auditEvents);

  const columns: IColumn[] = [
    {
      key: 'name',
      name: intl.get(LocIds.Label.Environment),
      minWidth: 200,
      isResizable: true,
      onRender: (item: ContinuousMonitoringTaskEnvironmentWithKey) => {
        return (
          <Stack
            className='scheduleList-cell'
            horizontal
            verticalAlign='center'>
            <Text>{ item.name }</Text>
          </Stack>
        );
      },
    },
    {
      key: 'status',
      name: intl.get(LocIds.Label.Status),
      minWidth: 125,
      isResizable: true,
      onRender: (item: ContinuousMonitoringTaskEnvironmentWithKey) => {
        return (
          <Stack
            className='scheduleList-cell'
            horizontal
            verticalAlign='center'>
            <IndicatorLabel
              color={item.halted ? LabelColor.Gray : LabelColor.GreenCyan}
              text={item.halted ?
                intl.get(LocIds.AuditManager.CmTaskStatusOff) :
                intl.get(LocIds.AuditManager.CmTaskStatusOn)}
            />
          </Stack>
        );
      },
    },
    {
      key: 'assignedTo',
      name: intl.get(LocIds.Label.AssignedTo),
      minWidth: 175,
      isResizable: true,
      onRender: (item: ContinuousMonitoringTaskEnvironmentWithKey) => {
        const contact = assignedTos[item.key];
        const selected = contact ? [
          {
            DisplayName: contact.alias,
            Id: contact.alias,
            Alias: contact.alias,
            Mail: contact.alias,
            AccountGuid: contact.accountGuid,
          },
        ] : [];

        const jsx = editAssignedTo ? (
          <ThrottledPeoplePicker
            itemLimit={1}
            required={selection.isKeySelected(item.key)}
            selectedEntities={selected}
            viewMode={PeoplePickerViewMode.normal}
            onChange={(items) => {
              dispatch(
                updateAssignedTo({
                  key: item.key,
                  adMember: items[0],
                })
              );
            }}
            onSearch={(value) => EntityApi.searchUsersStringAsync(value, true, true)}
          />
        ) : (
          <Persona size={PersonaSize.size24} text={item.assignedTo} />
        );
        // data-selection-disabled: allows a branch of the DOM to be marked to ignore input events that alter selections.
        // @see https://developer.microsoft.com/en-us/fluentui#/controls/web/selection
        return <span data-selection-disabled={true}>{ jsx }</span>;
      },
    },
  ];

  return (
    <MarqueeSelection selection={props.selection}>
      <DetailsList
        ariaLabelForSelectAllCheckbox={intl.get(LocIds.Action.Detail)}
        checkboxCellClassName='scheduleList-checkboxCell'
        checkButtonAriaLabel={intl.get(LocIds.Action.Detail)}
        columns={columns}
        groupProps={{
          groupedListAs: GroupedListV2FC,
        }}
        groups={groups}
        items={environments}
        selection={selection}
        selectionPreservedOnEmptyClick
        setKey='scheduleList'
      />
    </MarqueeSelection>
  );
};

const createGroups = (
  auditEvents: ConMonAuditEvent[]
): [IGroup[], ContinuousMonitoringTaskEnvironmentWithKey[]] => {
  const groups: IGroup[] = [];
  const environments: ContinuousMonitoringTaskEnvironmentWithKey[] = [];

  let startIndex = 0;
  auditEvents.forEach((auditEvent) => {
    const [scheduleGroups, envs] = createScheduleGroups(
      auditEvent.guid,
      auditEvent.tasks,
      startIndex
    );

    groups.push({
      key: auditEvent.guid,
      name: auditEvent.name,
      startIndex,
      count: envs.length,
      level: 0,
      children: scheduleGroups,
    });

    environments.push(...envs);
    startIndex += envs.length;
  });

  return [groups, environments];
};

const createScheduleGroups = (
  prefix: string,
  schedules: ConMonTask[],
  startIndex: number
): [IGroup[], ContinuousMonitoringTaskEnvironmentWithKey[]] => {
  const groups: IGroup[] = [];
  const environments: ContinuousMonitoringTaskEnvironmentWithKey[] = [];

  let currentIndex = startIndex;
  schedules.forEach((schedule) => {
    groups.push({
      key: `${schedule.id}`,
      name: schedule.name,
      startIndex: currentIndex,
      ariaLabel: schedule.name,
      count: schedule.environments.length,
      level: 1,
    });

    environments.push(
      ...schedule.environments.map((env) => ({
        ...env,
        key: getConcatenatedUid(prefix, schedule.id, env.id),
      }))
    );

    currentIndex += schedule.environments.length;
  });

  return [groups, environments];
};
