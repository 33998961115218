import * as React from 'react';


import {
  Spinner,
  SpinnerSize
} from '@fluentui/react/lib/Spinner';
import { Stack } from '@fluentui/react/lib/Stack';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

export const SpinnerLabeled: React.FunctionComponent = () => {
  const spinnerStyles: React.CSSProperties = {
    backgroundColor: '#faf9f8',
    position: 'fixed',
    top: 5,
    bottom: 0,
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    overflow: 'auto',
    zIndex: 'auto',
    width: '100%',
    height: '100%',
    opacity: 0.8,
  };

  return (
    <Stack style={spinnerStyles}>
      <div>
        <Spinner
          label={intl.get(LocIds.Placeholder.Loading)}
          size={SpinnerSize.large}
        />
      </div>
    </Stack>
  );
};
