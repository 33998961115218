import './style.less';
import React from 'react';

import {
  Persona, PersonaSize, Stack, Text
} from '@fluentui/react';

import { getSanitizedRichTextJsx } from '../../utils/getSanitizedRichTextJsx';

export type UserComment = {
  primaryText: string;
  secondaryText: string;
  createdAt: Date;
  content: string;
}

type IProps = {
  comment: UserComment;
}

export const CommentView: React.FC<IProps> = (props) => {
  const { comment } = props;

  return (
    <Stack className='comment-view'
      tokens={{
        childrenGap: 8
      }}>
      <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
        <Persona
          secondaryText={comment.secondaryText}
          showSecondaryText={true}
          size={PersonaSize.size32}
          text={comment.primaryText}
        />
        <Text className='secondaryText-12'>{ comment.createdAt.toLocaleString() }</Text>
      </Stack>
      { getSanitizedRichTextJsx(false, comment.content) }
    </Stack>
  );
};
