import React, { useState } from 'react';

import { Checkbox } from '@fluentui/react';

import _ from 'lodash';
import intl from 'react-intl-universal';

import { ConfirmDialog } from './ConfirmDialog';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { FCC } from '../../models';
import { useLocalStorage } from '../../utils/react-hooks/useLocalStorage';

interface IProps {
    showConfirmation: boolean;
    title: string,
    subText?: string,
    /** Identifier used in cache to indicates whether to show this dialog next time. */
    doubleConfirmDialogIdentifier: string,
    /** Text shown on action button. */
    actionText: string,
    onAction: () => void;
    onDismiss: () => void;
}

/** This dialog shows a checkbox to let user choose whether they want to see it next time */
export const DoubleConfirmDialog: FCC<IProps> = (props) => {
  const { showConfirmation, title, subText, doubleConfirmDialogIdentifier, actionText, onAction, onDismiss } = props;

  const [hideConfirm, setHideConfirm] = useLocalStorage<boolean>(doubleConfirmDialogIdentifier);
  const [tempHideConfirm, setTempHideConfirm] = useState<boolean | null>(hideConfirm);

  const onChange = (ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean) => {
    if (!_.isUndefined(checked)) {
      setTempHideConfirm(checked);
    }
  };

  const _onAction = () => {
    onAction();

    setHideConfirm(tempHideConfirm);
  };

  const _onDismiss = () => {
    onDismiss();

    setTempHideConfirm(hideConfirm);
  };

  return (
    <ConfirmDialog hidden={!showConfirmation}
      subText={subText}
      textAction={actionText}
      title={title}
      onAction={_onAction}
      onDismiss={_onDismiss}>
      { props.children }
      <Checkbox
        checked={tempHideConfirm ?? false}
        label={intl.get(
          LocIds.Label.DoNotShowDoubleConfirmAgain
        )}
        onChange={onChange}
      />
    </ConfirmDialog>
  );
};
