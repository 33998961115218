/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      fill='none'
      height='200'
      viewBox='0 0 200 200'
      width='200'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M57.514 37.3519C56.9737 37.1262 56.5234 37.1487 56.1632 37.3519L51.1201 40.2866C51.4803 40.0835 51.9306 40.0609 52.4709 40.2866L99.457 67.4893L104.5 64.5546L57.514 37.3519Z' fill='#0078D4'/>
      <path d='M113.866 79.7471L106.121 66.315C105.671 65.57 105.108 64.9605 104.5 64.5542L99.4573 67.4889C100.065 67.8727 100.65 68.5048 101.078 69.2497L108.823 82.6818C109.251 83.4493 109.836 84.0814 110.444 84.4426L115.487 81.5079C114.879 81.1467 114.294 80.5146 113.866 79.7471Z' fill='#0078D4'/>
      <path d='M148.492 109.073L148.312 175.556C148.29 176.255 148.042 176.729 147.637 176.978L152.68 174.043C153.085 173.817 153.333 173.321 153.355 172.621L153.536 106.138L148.492 109.073Z' fill='#0078D4'/>
      <path d='M151.216 102.187L115.487 81.508L110.444 84.4427L146.173 105.121C147.434 105.844 148.469 107.604 148.492 109.072L153.535 106.137C153.513 104.647 152.477 102.909 151.216 102.187Z' fill='#0078D4'/>
      <path d='M99.4576 67.4899C100.066 67.8737 100.651 68.5058 101.079 69.2508L108.823 82.6828C109.251 83.4504 109.836 84.0825 110.444 84.4437L146.174 105.122C147.434 105.845 148.47 107.605 148.492 109.073L148.312 175.556C148.267 176.978 147.232 177.542 145.971 176.797L52.7868 122.843C52.6742 122.798 52.5842 122.753 52.4716 122.685C51.3684 122.053 50.4003 120.541 50.2427 119.164C50.2202 119.028 50.2202 118.893 50.2202 118.78L50.4454 42.0029C50.3553 40.5356 51.2333 39.7906 52.4716 40.3098L99.4576 67.4899Z' fill='#243A5E'/>
      <path d='M137.229 47.1604L136.928 152.89L62.1667 109.725L62.4677 4L137.229 47.1604Z' fill='#F5F5F5'/>
      <path d='M111.652 63.2593L111.631 69.8638L75.7756 49.163L75.7912 42.5533L111.652 63.2593Z' fill='#D9D9D9'/>
      <path d='M116.051 79.2286L116.031 85.8383L75.7756 62.5953L75.7912 55.9907L116.051 79.2286Z' fill='#D9D9D9'/>
      <path d='M100.959 83.9082L100.938 90.5179L75.7756 75.9858L75.7912 69.3813L100.959 83.9082Z' fill='#D9D9D9'/>
      <path d='M36.2598 53.4475C35.6294 53.1766 35.1791 53.1766 34.9315 53.4024L30.2036 57.9399C30.4513 57.7142 30.9015 57.7142 31.5319 57.9851L79.5536 84.6686L84.2815 80.1085L36.2598 53.4475Z' fill='#0078D4'/>
      <path d='M96.1925 93.4058L86.1514 81.7346C85.5886 81.08 84.9357 80.5382 84.2828 80.1544L79.5549 84.7145C80.1853 85.0983 80.8607 85.6627 81.4236 86.2947L91.4647 97.966C92.0275 98.6206 92.6804 99.185 93.3108 99.5462L98.0387 95.0086C97.4083 94.6249 96.7554 94.0605 96.1925 93.4058Z' fill='#32B0E7'/>
      <path d='M153.218 172.621L137.549 118.689L132.821 123.227L148.49 177.158C148.603 177.632 148.558 177.948 148.355 178.152L153.083 173.614C153.286 173.411 153.353 173.095 153.218 172.621Z' fill='#0078D4'/>
      <path d='M134.555 115.281L98.0382 94.9857L93.3103 99.5233L129.827 119.818C131.111 120.54 132.439 122.053 132.822 123.249L137.55 118.712C137.167 117.493 135.839 115.98 134.555 115.281Z' fill='#0078D4'/>
      <path d='M79.5541 84.6907C80.1845 85.0745 80.8599 85.6389 81.4227 86.271L91.4638 97.9422C92.0267 98.5969 92.6796 99.1612 93.3099 99.5224L129.827 119.817C131.11 120.54 132.439 122.052 132.821 123.249L148.491 177.18C148.784 178.331 148.018 178.67 146.712 177.97L51.4795 125.009C51.3895 124.964 51.2769 124.919 51.1643 124.851C50.0386 124.219 48.8229 122.91 48.3501 121.759C48.3051 121.646 48.2601 121.533 48.2375 121.443L30.1365 59.1812C29.7088 57.9621 30.3167 57.4655 31.5324 58.0073L79.5541 84.6907Z' fill='#0078D4'/>
      <path d='M79.5541 84.6907C80.1845 85.0745 80.8599 85.6389 81.4227 86.271L91.4638 97.9422C92.0267 98.5969 92.6796 99.1612 93.3099 99.5224L129.827 119.817C131.11 120.54 132.439 122.052 132.821 123.249L148.491 177.18C148.784 178.331 148.018 178.67 146.712 177.97L51.4795 125.009C51.3895 124.964 51.2769 124.919 51.1643 124.851C50.0386 124.219 48.8229 122.91 48.3501 121.759C48.3051 121.646 48.2601 121.533 48.2375 121.443L30.1365 59.1812C29.7088 57.9621 30.3167 57.4655 31.5324 58.0073L79.5541 84.6907Z' fill='url(#paint0_linear_39841_88347)'/>
      <path d='M159.502 191.037C159.384 191.445 156.545 197.796 155.271 198.537L161.226 195.076C162.5 194.335 163.596 193.338 164.518 192.087C164.675 191.873 164.827 191.65 164.974 191.419C165.254 190.975 165.513 190.504 165.749 190.007C165.937 189.608 166.111 189.193 166.273 188.761C166.415 188.38 166.546 187.984 166.664 187.576L165.981 186.907L161.877 189.292L159.502 191.037Z' fill='#323232'/>
      <path d='M137.512 141.521C137.177 141.496 136.846 141.487 136.52 141.487C136.16 141.487 135.808 141.504 135.46 141.535C135.419 141.54 135.378 141.542 135.337 141.547C134.961 141.586 134.594 141.639 134.234 141.711C133.72 141.813 133.22 141.948 132.738 142.117C132.134 142.327 131.555 142.59 131 142.906C130.973 142.921 130.949 142.935 130.922 142.95L124.968 146.411C124.994 146.396 132.192 146.003 132.516 146.046L134.719 144.743L138.857 142.339L138.47 141.622C138.147 141.576 137.828 141.542 137.512 141.521Z' fill='#323232'/>
      <path d='M154.655 151.366C154.368 151.057 154.078 150.753 153.784 150.452C153.477 150.138 153.163 149.831 152.85 149.527C152.521 149.213 152.191 148.904 151.855 148.603C151.845 148.593 151.836 148.586 151.826 148.576C151.539 148.318 151.247 148.064 150.957 147.818C150.648 147.557 150.339 147.306 150.028 147.06C149.721 146.819 149.415 146.585 149.106 146.358C148.802 146.133 148.498 145.918 148.191 145.706C147.889 145.498 147.583 145.298 147.279 145.103C146.974 144.91 146.668 144.721 146.361 144.54C146.291 144.499 146.219 144.456 146.146 144.415C145.912 144.28 145.678 144.149 145.444 144.021C145.147 143.862 144.853 143.707 144.561 143.563C144.273 143.42 143.986 143.285 143.701 143.157C143.419 143.029 143.136 142.911 142.856 142.8C142.576 142.686 142.296 142.583 142.019 142.484C141.736 142.385 141.459 142.293 141.179 142.208C140.937 142.136 140.696 142.068 140.457 142.006C140.416 141.996 140.375 141.984 140.334 141.974C140.025 141.897 139.718 141.829 139.414 141.769C139.096 141.706 138.782 141.656 138.473 141.612L132.518 145.074C132.829 145.115 148.049 155.422 148.319 155.728L151.124 156.047L155.102 153.737L155.481 152.269C155.206 151.968 154.933 151.663 154.655 151.366Z' fill='#323232'/>
      <path d='M167.465 180.378C167.455 180.033 167.439 179.688 167.417 179.34C167.395 178.997 167.369 178.655 167.335 178.309C167.301 177.969 167.265 177.626 167.221 177.281C167.178 176.936 167.127 176.591 167.074 176.243C167.019 175.893 166.958 175.541 166.893 175.188C166.842 174.925 166.792 174.66 166.736 174.397C166.714 174.295 166.693 174.194 166.671 174.092C166.584 173.699 166.492 173.306 166.396 172.912C166.299 172.526 166.198 172.137 166.092 171.751C165.985 171.372 165.877 170.996 165.761 170.617C165.648 170.25 165.532 169.885 165.409 169.518C165.29 169.166 165.167 168.813 165.039 168.461C164.916 168.121 164.788 167.78 164.655 167.442C164.634 167.387 164.612 167.331 164.59 167.276C164.477 166.991 164.363 166.706 164.245 166.421C164.103 166.076 163.955 165.733 163.803 165.393L162.666 165.089L158.985 167.228L157.368 168.852C157.52 169.195 160.723 190.999 160.714 191.037L166.669 187.576C166.681 187.537 166.69 187.499 166.702 187.46C166.789 187.154 166.871 186.84 166.944 186.519C167.019 186.188 167.086 185.85 167.147 185.507C167.202 185.179 167.253 184.846 167.296 184.506C167.337 184.177 167.371 183.842 167.4 183.502C167.427 183.166 167.448 182.826 167.463 182.48C167.477 182.138 167.484 181.788 167.487 181.433C167.487 181.411 167.487 181.392 167.487 181.37C167.48 181.042 167.475 180.711 167.465 180.378Z' fill='#323232'/>
      <path d='M163.326 164.35C163.154 163.983 162.978 163.619 162.797 163.255C162.587 162.835 162.372 162.417 162.153 161.999C161.86 161.451 161.559 160.908 161.247 160.365C161.211 160.305 161.177 160.242 161.141 160.182C160.526 159.124 159.876 158.096 159.198 157.097C158.802 156.513 158.394 155.938 157.977 155.373C157.677 154.97 157.373 154.572 157.064 154.181C157.021 154.125 156.975 154.068 156.931 154.012C156.714 153.739 156.495 153.469 156.272 153.201C156.012 152.887 155.746 152.578 155.478 152.274L149.523 155.735C149.791 156.04 157.692 168.505 157.846 168.852L163.801 165.391C163.647 165.043 163.487 164.696 163.326 164.35Z' fill='#323232'/>
      <path d='M160.779 177.858C161.281 180.25 161.532 182.574 161.524 184.831C161.517 187.088 161.257 189.121 160.742 190.924C160.228 192.729 159.502 194.272 158.563 195.549C157.624 196.826 156.499 197.837 155.193 198.583C153.885 199.329 152.439 199.78 150.855 199.939C149.272 200.099 147.565 199.944 145.738 199.476C143.911 199.005 142.016 198.204 140.051 197.069C138.086 195.935 136.192 194.549 134.367 192.91C132.544 191.271 130.843 189.459 129.264 187.474C127.685 185.49 126.247 183.371 124.948 181.121C123.649 178.872 122.534 176.569 121.607 174.213C120.678 171.857 119.963 169.482 119.461 167.09C118.959 164.7 118.711 162.376 118.718 160.116C118.725 157.859 118.986 155.829 119.5 154.024C120.014 152.221 120.741 150.678 121.68 149.401C122.616 148.122 123.741 147.111 125.049 146.365C126.355 145.621 127.801 145.168 129.387 145.008C130.97 144.849 132.677 145.006 134.504 145.472C136.332 145.942 138.229 146.744 140.194 147.878C142.158 149.013 144.051 150.401 145.876 152.037C147.698 153.676 149.4 155.489 150.979 157.473C152.557 159.46 153.996 161.577 155.294 163.826C156.593 166.076 157.706 168.379 158.635 170.737C159.56 173.093 160.277 175.468 160.779 177.858Z' fill='#4B4B4B'/>
      <path d='M158.138 177.18C158.572 179.246 158.789 181.29 158.785 183.25C158.78 185.21 158.55 186.997 158.106 188.556C157.66 190.125 157.02 191.479 156.209 192.587C155.393 193.7 154.401 194.591 153.264 195.24C152.127 195.889 150.85 196.287 149.472 196.427C148.101 196.565 146.6 196.427 145.011 196.022C143.435 195.616 141.767 194.912 140.061 193.924C138.352 192.937 136.689 191.72 135.112 190.306C133.526 188.879 132.03 187.286 130.664 185.568C129.29 183.839 128.021 181.971 126.891 180.014C125.761 178.056 124.776 176.023 123.968 173.974C123.164 171.934 122.536 169.849 122.099 167.768C121.665 165.702 121.448 163.66 121.455 161.697C121.46 159.735 121.687 157.949 122.133 156.392C122.58 154.825 123.22 153.469 124.033 152.363C124.849 151.251 125.841 150.36 126.978 149.71C128.115 149.064 129.392 148.663 130.77 148.523C132.141 148.385 133.64 148.523 135.228 148.928C136.807 149.334 138.475 150.039 140.181 151.026C141.888 152.011 143.553 153.23 145.127 154.644C146.713 156.068 148.21 157.662 149.576 159.38C150.949 161.109 152.219 162.979 153.349 164.934C154.478 166.892 155.463 168.927 156.272 170.974C157.071 173.013 157.701 175.101 158.138 177.18Z' fill='#E6E7E8'/>
      <path d='M140.17 154.799C140.797 155.161 141.306 156.042 141.304 156.766L142.525 173.069L148.142 185.389C148.567 186.164 148.536 186.977 148.072 187.199C147.853 187.306 147.575 187.26 147.297 187.1C146.991 186.924 146.687 186.606 146.462 186.198L138.748 174.235C138.586 174.02 138.432 173.786 138.296 173.535C137.833 172.688 137.618 171.823 137.649 171.109C137.647 170.872 137.664 170.713 137.688 170.568L139.021 155.453C139.028 154.726 139.539 154.434 140.17 154.799Z' fill='#373637'/>
      <defs>
        <linearGradient gradientUnits='userSpaceOnUse'
          id='paint0_linear_39841_88347'
          x1='89.2766'
          x2='89.2766'
          y1='57.7893'
          y2='178.333'>
          <stop stopColor='#69E2FF'/>
          <stop offset='1' stopColor='white' stopOpacity='0'/>
        </linearGradient>
      </defs>
    </svg>

  );
};
