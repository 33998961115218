import React, { useMemo } from 'react';

import {
  DetailsList, DetailsListLayoutMode, Selection, IDetailsListProps
} from '@fluentui/react';
import { IObjectWithKey } from '@fluentui/react/dist/react';

import {
  classNames, focusZoneProps, gridStyles
} from './TableViewStyles';

interface ITableViewProps<T> extends IDetailsListProps {
    onSelectionChanged: (rows: T[]) => void;
    groupBy?: string;
}

export const TableView = <T, >(
  props: ITableViewProps<T>,
): ReturnType<React.FC<ITableViewProps<T>>> => {

  const selection = useMemo(() => {
    return new Selection<IObjectWithKey & any>({
      onSelectionChanged: () => {
        props.onSelectionChanged(selection.getSelection());
      }
    });
  }, []);

  let groups;

  if (props.groupBy) {
    const sortedData = [...props.items].sort(
      (a, b) => a[`${props.groupBy}`] - b[`${props.groupBy}`]
    );

    groups = sortedData.reduce((acc, cur) => {
      const group = {
        key: cur[`${props.groupBy}`],
        name: `${cur[`${props.groupBy}`]}`,
        startIndex: 0,
        count: 1,
        isCollapsed: true,
      };

      if (acc.length === 0) {
        acc.push(group);
        return acc;
      } else if (acc[acc.length - 1].key !== cur[`${props.groupBy}`]) {
        const { count, startIndex } = acc[acc.length - 1];
        acc.push({
          ...group,
          startIndex: count + startIndex,
        });
        return acc;
      }

      acc[acc.length - 1].count++;
      return acc;
    }, []);
  }


  return (
    <DetailsList
      columns={props.columns}
      compact={!props.groupBy}
      focusZoneProps={focusZoneProps}
      groups={groups}
      items={props.items}
      layoutMode={DetailsListLayoutMode.fixedColumns}
      selection={selection as Selection}
      selectionMode={props.selectionMode}
      selectionZoneProps={{
        className: classNames.selectionZone,
      }}
      styles={props.styles ? props.styles : gridStyles}
      onRenderItemColumn={props.onRenderItemColumn}
    />
  );
};
