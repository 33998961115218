import _ from 'lodash';

import { EvidenceGroup } from '../../models/AuditEvent';
import { Evidence } from '../../models/Evidence';


export const groupEvidenceById = (evidences: Evidence[]): EvidenceGroup[] => {
  const groups = _.groupBy(evidences, (evidence: Evidence) => {
    return evidence.EvidenceId;
  });
  const ids = _.keys(groups);
  return _.map(ids, (id) => {
    const res: EvidenceGroup = {
      id: _.toNumber(id),
      evidenceGuid: groups[id][0].EvidenceGuid,
    };
    groups[id].forEach((evidence) => {
      const type = evidence.EvidenceRequestType;

      if (type) {
        res[type] = evidence;
      }
    });
    return res;
  });
};
