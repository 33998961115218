import React, { FC } from 'react';

import {
  ITextFieldProps,
  TextField
} from '@fluentui/react';
import {
  Controller,
  UseControllerProps,
  useFormContext
} from 'react-hook-form';

import { LabelWithIcon } from '../../LabelWithIcon';
import { ReadOnlyTextField } from '../../ReadOnlyControls';

interface IProps extends ITextFieldProps, Pick<UseControllerProps, 'rules'> {
  name: string;
  showLabelTooltip?: boolean;
}

export const TextareaControl: FC<IProps> = (props: IProps) => {

  const { control } = useFormContext();
  const onRenderLabel = (props: IProps) => <LabelWithIcon {...props} />;

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => {
        return props.readOnly ? <ReadOnlyTextField {...props} {...fieldProps}/> : (
          <TextField
            {...props}
            {...fieldProps}
            ariaLabel={props.label || props.ariaLabel}
            errorMessage={error && error.message}
            onChange={(_e, value) => {
              fieldProps.onChange(_e, value);

              if (props.onChange) {
                props.onChange(_e, value);
              }
            }}
            onRenderLabel={props?.showLabelTooltip ? () => {
              return onRenderLabel(props);
            } : undefined}
          />
        );
      }}
      rules={props.rules}
    />
  );
};
