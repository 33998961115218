/* eslint-disable i18next/no-literal-string */
import {
  bootstrapFeature,
  DataSourceSetter,
  initialize,
  preloadFeature,
  RequestFunction
} from '@ms/centro-hvc-loader';

import {
  CentroEnvType,
  getCentroURl
} from './GetCentroBaseUrl';
import { getRuntimeStaticConfig } from './GetRuntimeStaticConfig';
import { InAppFeedbackInit } from './InAppFeedbackInit';
import { centroClientName } from './Initparameters';

// decalring as variable to reuse
const hvcName = 'ocv-inapp-feedback';
// setting an unmount funtion
let unmountFunction = () => {};

/**
 * This is function you call from outside
 * You can change the signature to include appName and language dynamically
 *
 * Details of the data objects and functions can be found here
 * https://projectcentro.azurewebsites.net/docs/#/consume/adapter
 */
export function initializeCentro() {
  (async () => {
    // Name of your product registed with Centro, OCV and ODS.
    const clientName = centroClientName;
    const language = 'en';

    const dataSources = (sd: DataSourceSetter) => {
      sd('centro.hvc.feedback.initOptions', InAppFeedbackInit as any);
    };

    // Set RuntimeConfig
    const runtimeStaticConfig = await getRuntimeStaticConfig(clientName, language);

    const InitializationParameters = {
      // Use CentroEnvType.CI if you want to use latest unreleased version
      centroEnvironmentBaseUrl: getCentroURl(CentroEnvType.Prod),
      centroAdapters: {
        runtimeStaticConfig,
        request: ((() => {}) as unknown) as RequestFunction
      },
      centroHvcData: '',
      // This will sent the latest compatible version for Major release 2 with minor upgrades or bug fixes. for eg 2.3.4
      forHostVersion: 'v2'
    };

    // This is required only if centro module is running locally
    // eslint-disable-next-line no-constant-condition
    if (InitializationParameters.centroEnvironmentBaseUrl === getCentroURl(CentroEnvType.Local)) {
      const centroHvcDataString = `{
        "ocv-inapp-feedback": {
          "cdnUrl":"https://localcdn:5555/",
          "loaderSpec":{
            "type":"scriptUrl",
            "content":"https://localcdn:5555/ocv-inapp-feedback.en.bundle.js"
          },
          "version":"localdevbox"
        },
        "__hostApp_config":{
          "ariaLoggerId":"ea84b6a3285140258eaeb7caaab5884a-9d3ca75b-b3ee-42b8-a22c-ab0759ad4d38-7330",
          "hostingAppUrls": {
            "M365AdminPortal": "https://portal-sdf.office.com",
            "EXOAdminPortal": "https://admin-sdf.exchange.microsoft.com",
            "SPOAdminPortal": "https://admin.microsoft.com/sharepoint",
            "TeamsAdminPortal": "https://admin-dev.teams.microsoft.net/",
            "MSGraphEndPoint": "https://graph.microsoft.com"
          }
        }
      }`;
      InitializationParameters.centroHvcData = centroHvcDataString;
    }

    // This is required only if you want to test new unreleased version i.e for CentroEnvType.CI
    if (InitializationParameters.centroEnvironmentBaseUrl === getCentroURl(CentroEnvType.CI)) {
      InitializationParameters.forHostVersion = '';
    }

    await initialize(InitializationParameters);

    await preloadFeature({
      featureName: hvcName
    });

    const bootstrapper = await bootstrapFeature({
      featureName: hvcName,
      setAdditionalDataSource: dataSources
    });

    // use the main bootstrapper as only main is provided
    unmountFunction = await bootstrapper.main(document.getElementById('centroRef') as HTMLDivElement);

    // for angular
    // bootstrapper.main(document.getElementById('my-id'));
    console.log('Inapp Feedback Initilization Completed');
  })();
}
