import { useEffect } from 'react';

import { getMyNetworkStatus } from '../../../redux/networkStatusSlice/thunk';
import { useAppDispatch } from '../../../redux/reducer';

export const useCheckMyNetworkStatus = (intervalInMilliseconds = 60000) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMyNetworkStatus());

    const interval = setInterval(() => {
      dispatch(getMyNetworkStatus());
    }, intervalInMilliseconds);

    return () => clearInterval(interval);
  }, [dispatch, intervalInMilliseconds]);
};
