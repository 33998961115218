import { EvidenceTreeNodeBase } from './EvidenceTreeNodeBase';

import { EvidenceTreeNodeVisitor } from '../EvidenceTreeVisitor/EvidenceTreeNodeVisitor';

/**
 * An EvidenceTreeRootNode is the root of EvidenceTree
 * It has different schema with EvidenceTreeNodeBase,
 * visitor will not visit root node directly, instead, root node will call accept method on its children.
 *
 */
export class EvidenceTreeRootNode {
  constructor(children: EvidenceTreeNodeBase[]) {
    this._children = [...children];
  }

  public accept(visitor: EvidenceTreeNodeVisitor): void {
    visitor.visitEvidenceTreeRootNode(this);
  }

  public get children(): EvidenceTreeNodeBase[] {
    return [...this._children];
  }

  private _children: EvidenceTreeNodeBase[] = [];
}
