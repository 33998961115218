import {
  useCallback,
  useEffect,
  useState
} from 'react';

export enum UseAsyncHookStatus {
  Pending = '0',
  Success = '1',
  Fail = '2',
}

/**
 * Hook to do an async operation and return its status
 */
export const useAsync = <T>(
  asyncFn: () => Promise<T>,
  initialValue: T,
): {
  data: T;
  status: UseAsyncHookStatus;
  error: null | Error;
} => {
  const [data, setData] = useState<T>(initialValue);
  const [status, setStatus] = useState<UseAsyncHookStatus>(
    UseAsyncHookStatus.Pending,
  );
  const [error, setError] = useState(null);

  // useCallback ensures the useEffect is not called on every render, but only if asyncFn changes
  const memorizedAsyncFn = useCallback(() => {
    return asyncFn()
      .then((res) => {
        setData(res);
        setStatus(UseAsyncHookStatus.Success);
      })
      .catch((error) => {
        setError(error);
        setStatus(UseAsyncHookStatus.Fail);
      });
  }, [asyncFn]);

  useEffect(() => {
    memorizedAsyncFn();
  }, [memorizedAsyncFn]);

  return {
    data,
    status,
    error,
  };
};
