import React from 'react';

import {
  Label,
  Stack
} from '@fluentui/react';
import intl from 'react-intl-universal';


import { EvidenceAttachments } from './EvidenceAttachments';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { EvidenceRequestAttachment } from '../../models';
import { uploadToUserTempStorageAsync } from '../../services';
import {
  FileUploader,
  UploadedFile
} from '../FileUploader';

interface EvidenceAttachmentsEditorProps {
  attachments: EvidenceRequestAttachment[];
  onUpload: (attachment: EvidenceRequestAttachment) => void;
  onRemove?: (attachment: EvidenceRequestAttachment) => void;
}

export const EvidenceAttachmentsEditor: React.FC<EvidenceAttachmentsEditorProps> =
  (props) => {
    const onFileUploaded = (file: UploadedFile) => {
      props.onUpload({
        AttachmentGuid: '',
        DisplayName: file.file.name,
        DownloadName: `/me/storage/download/${file.blobGuid}/${file.file.name}`,
        BlobGuid: file.blobGuid,
        DateAttached: new Date().toISOString(),
      });
    };

    return (
      <Stack>
        <Label>{ intl.get(LocIds.Label.Attachments) }</Label>
        <Stack tokens={{
          childrenGap: 8
        }}>
          <FileUploader
            sendUploadFileRequestAsync={uploadToUserTempStorageAsync}
            onFileUploaded={onFileUploaded}
          />
          <EvidenceAttachments
            Attachments={props.attachments}
            onDeleteAttachment={props.onRemove}
          />
        </Stack>
      </Stack>
    );
  };
