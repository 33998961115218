import Fuse from 'fuse.js';
import _ from 'lodash';


import { groupEvidenceById } from './AuditManager/groupEvidence';
import { getEvidenceId } from './evidenceId';


import {
  EvidenceGroup, EvidenceRequestType
} from '../models/AuditEvent';
import {
  DraftEvidenceRequestsFilterState,
  OriginalEvidenceRequestsFilterState,
  PublishedEvidenceRequestsFilterState
} from '../models/AuditManager';
import { Evidence } from '../models/Evidence';
import { EvidencePath } from '../models/EvidencePath';
import { EvidenceTreeRootNode } from '../models/EvidenceTreeNode/EvidenceTreeRootNode';
import { FilterEvidencesOnPathVisitor } from '../models/EvidenceTreeVisitor/FilterEvidencesOnPathVisitor';
import { FilterEvidenceService } from '../services/FilterEvidenceService';

export enum EvidenceSearchType {
  // Search evidence one by one
  SingleEvidence = 'SingleEvidence',

  // Search evidence by group, entire group is matched if any evidence in the group is matched
  EvidenceGroup = 'EvidenceGroup',
}

export const fuseSearchEvidences = (
  evidences: Evidence[],
  query: string,
): Evidence[] => {
  const options = {
    useExtendedSearch: true,
    keys: ['searchText'],
  };

  const normalizedSearchQuery = _.isString(query) ? query.trim() : '';

  const fuse = new Fuse(
    evidences.map((e) => ({
      evidence: e,
      searchText: FilterEvidenceService.getSearchTextForEvidence(e),
    })),
    options,
  );

  const results = fuse.search(
    normalizedSearchQuery
      .split(' ')
      .map((s) => `'${s}`)
      .join(' '),
  );
  return results.map((r) => r.item.evidence);
};

/**
 * Given a list of evidences, search query and evidence search type, return a list of matched evidences
 */
export const searchEvidences = (
  evidences: Evidence[],
  query: string,
  evidenceSearchType: EvidenceSearchType,
): Evidence[] => {
  const searchQueryReg = FilterEvidenceService.getSearchQueryReg(query);

  if (evidenceSearchType === EvidenceSearchType.EvidenceGroup) {
    const groupedEvidences: _.Dictionary<Evidence[]> = _.groupBy(
      evidences,
      (evidence: Evidence) => getEvidenceId(evidence),
    );

    return _.filter(
      groupedEvidences,
      (evidences: Evidence[]) =>
        evidences.some((evidence) =>
          FilterEvidenceService.isEvidenceMatched(evidence, searchQueryReg),
        ),
    ).flat(1);
  }

  return evidences.filter((evidence) =>
    FilterEvidenceService.isEvidenceMatched(evidence, searchQueryReg),
  );
};

export const getPostTreeEvidenceStore = (
  evidenceTree: EvidenceTreeRootNode,
  selectedEvidencePaths: EvidencePath[],
): string[] => {
  const result = selectedEvidencePaths.reduce(
    (acc: string[], path: EvidencePath) => {
      const visitor = new FilterEvidencesOnPathVisitor(path);
      evidenceTree.accept(visitor);
      return [...acc, ...visitor.visibleEvidences];
    },
    [],
  );

  return Array.from(new Set(result));
};

export const getPostTreeEvidences = (
  evidenceTree: EvidenceTreeRootNode,
  PostFilterEvidences: Evidence[],
  selectedEvidencePaths: EvidencePath[],
  evidenceSearchType: EvidenceSearchType = EvidenceSearchType.SingleEvidence,
): Evidence[] => {
  if (_.isEmpty(selectedEvidencePaths)) {
    return PostFilterEvidences;
  }

  const PostTreeEvidenceStore = getPostTreeEvidenceStore(
    evidenceTree,
    selectedEvidencePaths,
  );

  let evidenceDic: _.Dictionary<Evidence[]> | _.Dictionary<Evidence>;

  if (evidenceSearchType === EvidenceSearchType.EvidenceGroup) {
    evidenceDic = _.groupBy(PostFilterEvidences, (evidence: Evidence) =>
      getEvidenceId(evidence),
    );
  } else {
    evidenceDic = _.keyBy(PostFilterEvidences, (evidence: Evidence) =>
      getEvidenceId(evidence),
    );
  }

  let postTreeEvidences: Evidence[] = [];
  PostTreeEvidenceStore.forEach((v: string) => {
    // TODO: there are two "id"s, one is returned from backend => Id: {evidence.AuditEventGuid}_{evidence.EvidenceGuid},
    // the other one is generated in Client side => id: {evidence.AuditEventGuid}:{evidence.EvidenceGuid}:{evidence.EvidenceRequestType}
    const evidences = evidenceDic[v];

    if (evidences) {
      postTreeEvidences = postTreeEvidences.concat(evidences);
    }
  });
  return postTreeEvidences;
};

/**
 * Given a list of evidences, group these evidence by id, then filter evidence group, return valid evidences in matched groups
 */
export const filterEvidenceGroup = (
  evidences: Evidence[],
  queryTypes: EvidenceRequestType[],
  originalFilters?: OriginalEvidenceRequestsFilterState,
  draftFilters?: DraftEvidenceRequestsFilterState,
  publishedFilters?: PublishedEvidenceRequestsFilterState,
): Evidence[] => {
  const groupedEvidences = groupEvidenceById(evidences);

  const predicate = (group: EvidenceGroup) => {
    // Following three groups of logic determine whether a group of evidences should be filtered out.
    // If any filter of any evidence type is set, that means users intend to focus on the specific type of evidence.
    // If it is missing, we should filter out the whole group.

    // Original (or Reuse)
    const { dueDate, workItemStates, assignedTos, hideReuseEvidence, sourceTypes, isProposalOnly } = originalFilters || {};
    const requireOriginal = (!_.isNil(dueDate) || !_.isEmpty(workItemStates) || !_.isEmpty(assignedTos) || hideReuseEvidence || !_.isEmpty(sourceTypes)) || isProposalOnly;

    if (queryTypes.includes(EvidenceRequestType.Original) && requireOriginal &&
      (_.isNil(group[EvidenceRequestType.Original]) && _.isNil(group[EvidenceRequestType.Reuse]))) {
      // No original and no reuse evidence.
      return false;
    }

    // Draft
    const { publishingStates, displayEvidencesInConflictOnly } = draftFilters || {};
    const requireDraft = (!_.isEmpty(publishingStates) || displayEvidencesInConflictOnly);

    if (queryTypes.includes(EvidenceRequestType.Draft) && _.isNil(group[EvidenceRequestType.Draft]) && requireDraft) {
      return false;
    }

    // Published
    const { auditorsDecisions } = publishedFilters || {};
    const requirePublished = (!_.isEmpty(auditorsDecisions));

    return !(queryTypes.includes(EvidenceRequestType.Published) && _.isNil(group[EvidenceRequestType.Published]) && requirePublished);
  };

  return _.filter(groupedEvidences, predicate)
    .map((evidenceGp) => {
      return Object.values(EvidenceRequestType)
        .filter((t) => !_.isNil(evidenceGp[t]))
        .map((t) => evidenceGp[t]) as Evidence[];
    })
    .flat(1);
};
