import React from 'react';

import classNames from 'classnames';

import { Pagination } from './Pagination';
import { PaginationProps } from './type';

import { FCC } from '../../models';

interface IProps extends PaginationProps {
    className?: string;
}

export const PaginationLayout: FCC<IProps> = (props) => {
  return (
    <div className={classNames('pagingLayout', props.className)}>
      <div className='pagingLayout__content'>
        { props.children }
      </div>
      <Pagination {...props} />
    </div>
  );
};
