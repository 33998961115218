import React from 'react';

import { Comment } from '../../../models/Discussions';
import { ProposedEvidenceApi } from '../../../services';
import { useUserCommentRole } from '../../../utils/react-hooks/useUserCommentRole';
import { DiscussionSection } from '../../DiscussionPanel';

type IProps = {
  auditEventGuid: string;
  proposedEvidenceGuid: string;
  comments: Comment[];
  onReload: () => void;
}

export const ProposedEvidenceDiscussionSection: React.FC<IProps> = (props) => {
  const { auditEventGuid, proposedEvidenceGuid, comments, onReload } = props;

  const createdByRole = useUserCommentRole();

  const onSendComment = (content: string) => {
    if (createdByRole) {
      return ProposedEvidenceApi.createProposedEvidenceCommentAsync({
        auditEventGuid,
        proposedEvidenceGuid,
        content,
        createdByRole
      });
    }
  };

  return (
    <DiscussionSection
      comments={comments.map(c => ({
        primaryText: c.CreatedByDisplayName,
        secondaryText: c.CreatedByRole,
        content: c.Content,
        createdAt: new Date(c.CreatedDate),
      }))}
      isLoading={false}
      onRefresh={onReload}
      onSendComment={onSendComment}
    />
  );
};
