import './style.less';

import React from 'react';

import {
  DefaultButton,
  Icon,
  IconButton,
  Stack
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import intl from 'react-intl-universal';


import { LocIds } from '../../common/Globalization/IntlEnum';
import { FCC } from '../../models';
import {
  StatusIcon,
  StatusType
} from '../StatusIcon';
import { ToolTip } from '../ToolTip';

export enum ActionType {
    none,
    icon,
    button,
}

interface CollapsibleActionableSectionProps {
    title: string;
    name: string;
    onToggleCollapse?: () => void;
    isCollapsed?: boolean;
    isCollapsible?: boolean;
    removeActionType?: ActionType;
    onRemove?: () => void;
    warningMessage?: string;
    dropdown?: React.ReactNode;
    editActionType?: ActionType;
    onEdit?: () => void;
    subtitle?: string;
}

export const CollapsibleActionableSection: FCC<CollapsibleActionableSectionProps> = (props) => {
  const {
    isCollapsible = true,
    removeActionType = ActionType.none,
    onRemove,
    warningMessage,
    dropdown,
    editActionType,
    onEdit,
    subtitle,
  } = props;

  const [isCollapsed, { toggle: toggleIsCollapsed }] = useBoolean(false);

  const isActuallyCollapsed = props.isCollapsed ?? isCollapsed;
  const className = classNames('collapseIcon', {
    expanded: !isActuallyCollapsed,
    disabled: !isCollapsible,
  });

  const onToggleCollapse = () => {
    if (isCollapsible) {
      props.onToggleCollapse ? props.onToggleCollapse() : toggleIsCollapsed();
    }
  };

  return (
    <Stack>
      <Stack
        className='collapseActionRow'
        horizontal
        horizontalAlign='space-between'
        verticalAlign='start'>
        <Stack className='collapseActionRow-leftContainer' horizontal>
          <Stack
            className={classNames('collapseActionRow-title', {
              disabled: !isCollapsible,
            })}
            horizontal
            onClick={onToggleCollapse}>
            <Icon className={className} iconName='ChevronRight'/>
            <span>{ props.name }</span>
          </Stack>
          { warningMessage && (
            <StatusIcon
              size='small'
              tooltipContent={warningMessage}
              type={StatusType.Error}
            />
          ) }
        </Stack>
        <Stack className='collapseActionRow-rightContainer' horizontal>
          { dropdown && (
            <Stack className='collapseActionRow-dropdownWrapper' horizontal>
              { dropdown }
            </Stack>
          ) }
          <Stack.Item className='collapseActionRow-subtitle'>
            { subtitle }
          </Stack.Item>
          { removeActionType === ActionType.button && onRemove && (
            <DefaultButton
              ariaLabel={intl.get(LocIds.Action.Remove)}
              text={intl.get(LocIds.Action.Remove)}
              onClick={onRemove}
            />
          ) }
          { editActionType === ActionType.icon && onEdit && (
            <Stack.Item align='center'>
              <IconButton aria-label={intl.get(LocIds.Action.Edit)}
                iconProps={{
                  iconName: 'Edit'
                }}
                onClick={onEdit}
              />
            </Stack.Item>
          ) }
          { removeActionType === ActionType.icon && onRemove && (
            <Stack.Item align='center'>
              <ToolTip
                content={intl.get(LocIds.Components.RemoveTooltip, {
                  title: `${props.title}`,
                })}>
                <IconButton
                  aria-label={intl.get(LocIds.Action.Remove)}
                  iconProps={{
                    iconName: 'Delete'
                  }}
                  onClick={onRemove}
                />
              </ToolTip>
            </Stack.Item>
          ) }
        </Stack>
      </Stack>
      { !isActuallyCollapsed && (
        <Stack tokens={{
          padding: '0 0 0 30px'
        }}>
          { props.children }
        </Stack>
      ) }
    </Stack>
  );
};
