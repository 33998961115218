import './style.less';

import React from 'react';

import { Stack } from '@fluentui/react';
import classNames from 'classnames';

import { TrustSectionProps } from './types';

import { FCC } from '../../models';

export const TrustSection: FCC<TrustSectionProps> = (props) => {
  const cls = classNames('tp-section', props.className);
  return (
    <Stack className={cls}>
      <div className='tp-sectionHeader'>
        <div className='tp-sectionTitle'>{ props.title }</div>
      </div>
      <div className='tp-sectionContentWrapper'>{ props.children }</div>
    </Stack>
  );
};
