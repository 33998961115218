import React, { lazy } from 'react';

import {
  Navigate,
  RouteObject
} from 'react-router-dom';

import NotFound from '../../TrustPortal/pages/NotFound';
import {
  Application,
  UserAccessReview,
} from '../../TrustPortal/registerApplicationMenu';

const Home = lazy(() => import('../pages/Home'));

const ClassificationReview = lazy(() => import('../pages/ClassificationReview'));
const ClassificationReviewStatus = lazy(() => import('../pages/ClassificationReviewStatus'));
const ClassificationReviewService = lazy(() => import('../pages/ClassificationReviewService'));
const ClassificationTriage = lazy(() => import('../pages/ClassificationTriage'));

const ExceptionReview = lazy(() => import('../pages/ExceptionReview'));
const ClassificationException = lazy(() => import('../pages/ClassificationException'));

const GroupReview = lazy(() => import('../pages/GroupReview'));
const ManagerReview = lazy(() => import('../pages/ManagerReview'));
const ManagerReviewTriage = lazy(() => import('../pages/ManagerReviewTriage'));

const RevocationReview = lazy(() => import('../pages/RevocationReview'));
const RevocationException = lazy(() => import('../pages/RevocationException'));
const RevocationTriage = lazy(() => import('../pages/RevocationTriage'));

const ReviewManagement = lazy(() => import('../pages/ReviewManagement'));

const Iteration = lazy(() => import('../pages/Iteration'));

const MyReviews = lazy(() => import('../pages/MyReviews'));

const ManualReview = lazy(() => import('../pages/ManualReview'));
const ManualReviewService = lazy(() => import('../pages/ManualReviewSevice'));

const ManualReviewTriage = lazy(() => import('../pages/ManualReviewTriage'));

const Dashboard = lazy(() => import('../pages/Dashboard'));

export const routes: RouteObject[] = [
  {
    path: `${Application.UserAccessReview}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${UserAccessReview.ReviewManagement}/*`,
        element: <ReviewManagement />,
      },
      {
        path: `menu/${UserAccessReview.ClassificationReview}`,
        element: <Iteration/>,
      },
      {
        path: `menu/${UserAccessReview.ClassificationReview}/:iterationId/*`,
        element: <Iteration/>,
        children: [
          {
            path: '*',
            element: <ClassificationReview />,
            children: [
              {
                path: '',
                element: <ClassificationReviewStatus />,
              },
              {
                path: ':serviceId',
                element: <ClassificationReviewService />,
              }
            ]
          }
        ]
      },
      {
        path: `menu/${UserAccessReview.ClassificationReviewTriage}/*`,
        element: <Iteration/>,
        children: [
          {
            path: ':iterationId/*',
            element: <ClassificationTriage/>,
          }
        ]
      },
      {
        path: `menu/${UserAccessReview.GroupReview}`,
        element: <Iteration/>,
      },
      {
        path: `menu/${UserAccessReview.GroupReview}/:iterationId/*`,
        element: <Iteration/>,
        children: [
          {
            path: '*',
            element: <GroupReview />,
          }
        ]
      },
      {
        path: `menu/${UserAccessReview.ManagerReview}`,
        element: <Iteration/>,
      },
      {
        path: `menu/${UserAccessReview.ManagerReview}/:iterationId/*`,
        element: <Iteration/>,
        children: [
          {
            path: '*',
            element: <ManagerReview />,
          }
        ]
      },
      {
        path: `menu/${UserAccessReview.ManagerReviewTriage}/*`,
        element: <Iteration/>,
        children: [
          {
            path: ':iterationId/*',
            element: <ManagerReviewTriage />,
          }
        ]
      },
      {
        path: `menu/${UserAccessReview.RevocationReview}`,
        element: <Iteration/>,
      },
      {
        path: `menu/${UserAccessReview.RevocationReview}/:iterationId/*`,
        element: <Iteration/>,
        children: [
          {
            path: '',
            element: <RevocationReview />,
          }
        ]
      },
      {
        path: `menu/${UserAccessReview.RevocationTriage}/*`,
        element: <Iteration/>,
        children: [
          {
            path: ':iterationId/*',
            element: <RevocationTriage />,
          }
        ]
      },
      {
        path: `menu/${UserAccessReview.ManualReview}/*`,
        element: <Iteration/>,
      },
      {
        path: `menu/${UserAccessReview.ManualReview}/:iterationId/*`,
        element: <Iteration/>,
        children: [
          {
            path: '*',
            element: <ManualReview />,
            children: [
              {
                path: ':serviceId',
                element: <ManualReviewService />,
              }
            ]
          }
        ]
      },
      {
        path: `menu/${UserAccessReview.ExceptionReview}/*`,
        element: <ExceptionReview />,
        children: [
          {
            path: '',
            element: <Navigate replace={true} to={UserAccessReview.ClassificationExceptions}/>
          },
          {
            path: UserAccessReview.ClassificationExceptions,
            element: <ClassificationException />
          },
          {
            path: UserAccessReview.RevocationExceptions,
            element: <RevocationException />,
          }
        ]
      },
      {
        path: `menu/${UserAccessReview.MyReviews}/*`,
        element: <MyReviews />,
      },
      {
        path: `menu/${UserAccessReview.Dashboard}/*`,
        element: <Dashboard />,
      },
      {
        path: `menu/${UserAccessReview.ManualReviewTriage}/*`,
        element: <Iteration />,
      },
      {
        path: `menu/${UserAccessReview.ManualReviewTriage}/:iterationId/*`,
        element: <Iteration/>,
        children: [
          {
            path: '*',
            element: <ManualReviewTriage />,
          }
        ]
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];
