import { Evidence } from '../models/Evidence';

export const getEvidenceId = (evidence: Evidence): string => {
  if (evidence.EvidenceRequestType) {
    return `${evidence.Id}_${evidence.EvidenceRequestType}`;
  }

  return evidence.Id;
};

export const getEvidenceIds = (evidences: Evidence[]): string[] => {
  const evidenceIds:string[] = [];
  evidences.forEach((evidence:Evidence) => {
    evidenceIds.push(getEvidenceId(evidence));
  });
  return evidenceIds;
};

export const getConcatenatedUid = (...args: (string | number)[]): string => args.join(':');

export const splitUid = (uid: string): string[] => uid.split(':');
