
import { DocTreeVisitor } from './DocTreeVisitor';

import { DocTreeNode } from '../DocTreeNode/DocTreeNode';
import { DocTreeRootNode } from '../DocTreeNode/DocTreeRootNode';

export class SortVisitor implements DocTreeVisitor {
  constructor() {
  }

  public visitDocTreeRootNode(node: DocTreeRootNode): void {
    node.children.forEach((n: DocTreeNode) => n.accept(this));
  }

  public visitDocTreeNode(node: DocTreeNode): void {
    if (node.children.length !== 0) {
      node.children = node.children.sort((a, b) => a.order - b.order);
      node.children.forEach((n) => n.accept(this));
    }

  }
}
