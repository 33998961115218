import {
  createSlice, PayloadAction
} from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { RootReduxState } from './reducer';

import { LocalStorageKeys } from '../../../../../utils';

interface FilterSliceState {
  keyword: string;
}

const initialState: FilterSliceState = {
  keyword: window.localStorage.getItem(LocalStorageKeys.ServiceWorkspaceServiceKeyword) ?? ''
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setKeyword: (state, action: PayloadAction<string>) => {
      state.keyword = action.payload;
    }
  }
});

export const { setKeyword } = filterSlice.actions;

export const useKeyword = (): string => useSelector<RootReduxState, string>((state) => state.serviceBoard.filter.keyword);
