import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

export const buildDescription = (date: string, retainDate?: string): string => {
  let description = new Date(date).toLocaleDateString();

  if (retainDate) {
    description +=
      intl.get(LocIds.Label.RetainedUntil) +
      new Date(retainDate).toLocaleDateString();
  }

  return description;
};
