import React, {
  useEffect, useState
} from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';
import { useNavigate } from 'react-router';


import { LocIds } from '../../../common/Globalization/IntlEnum';
import {
  IApplication, MyNetworkStatus
} from '../../../models';
import {
  useConfigApiStatus, useConfigApplicationsSelector, useMyAccountGuidSelector
} from '../../../redux/configSlice/selector';
import { useMyNetworkStatusSelector } from '../../../redux/networkStatusSlice/selector';
import { useAppId } from '../../../utils/react-hooks/useAppId';
import { UseAsyncHookStatus } from '../../../utils/react-hooks/useAsync';
import { NetworkRestrictionDocLink } from '../components/NetworkRestrictionDocLink';

interface ApplicationsState {
  noPermissionMessage: string;
  applicationsLocalLoaded: boolean;
  applicationsLoaded: boolean;
  currentApplication: IApplication | null;
  noPermissionSubMessage: React.ReactNode;
}

interface UsePortalApplications {
  applications: IApplication[];
  applicationsLoaded: boolean;
  noPermissionMessage: string;
  noPermissionSubMessage: React.ReactNode;
  currentApplication: IApplication | null;
  myAccountGuid: string;
}

export const usePortalApplications = (): UsePortalApplications => {
  const navigate = useNavigate();
  const { applicationId } = useAppId();

  const [applicationsState, setApplicationsState] = useState<ApplicationsState>({
    applicationsLocalLoaded: false,
    applicationsLoaded: false,
    noPermissionMessage: intl.get(LocIds.Error.NoPermissionTrustPortal),
    noPermissionSubMessage: null,
    currentApplication: null
  });

  const { applicationsLocalLoaded } = applicationsState;
  const applications = useConfigApplicationsSelector();
  const apiStatus = useConfigApiStatus();

  const accountGuid = useMyAccountGuidSelector();

  const networkStatus = useMyNetworkStatusSelector();

  useEffect(() => {
    if (apiStatus === UseAsyncHookStatus.Success) {
      setApplicationsState((applicationsState) => {
        return {
          ...applicationsState,
          applicationsLocalLoaded: true,
          applicationsLoaded: true,
          noPermissionMessage: getNoPermissionMessageOnApplicationRetrieved(applications, applicationId, networkStatus),
          noPermissionSubMessage: getNoPermissionSubMessageOnApplicationRetrieved(networkStatus)
        };
      });
    } else if (apiStatus === UseAsyncHookStatus.Fail) {
      setApplicationsState((applicationsState) => {
        return {
          ...applicationsState,
          applicationsLocalLoaded: true,
          applicationsLoaded: true,
          noPermissionMessage: intl.get(LocIds.Error.ContactOncall)
        };
      });
    }
  }, [
    applications,
    apiStatus,
    networkStatus,
    applicationId
  ]);

  // -------------------------------------------------------------------------------------------------------------------

  // If user doesn't specify applicationId, auto redirect to the first one.
  useEffect(() => {
    if (applications.length > 0 && !applicationId) {
      const firstApplication = applications[0];

      // Only redirect to the first application if it has at least one menu item that is not hidden.
      if (firstApplication.Menus?.some(m => m.Hide === false)) {
        navigate(`${firstApplication.ApplicationId}`);
      }
    }
  }, [
    applications,
    applicationId,
    navigate,
    applicationsState
  ]);

  // Get current application using applicationId, there are three scenes:
  // 1. applicationId exists and user have access to it -> show application content
  // 2. applicationId exists and user have no access -> render no permission message
  // 3. applicationId not exists -> render no permission message
  useEffect(() => {
    if (!applicationsLocalLoaded || !applicationId) {
      return;
    }

    const currentApplication = applications.length > 0 && !!applicationId ?
      (applications.find((a: IApplication) => a.ApplicationId === applicationId) || null) : null;

    setApplicationsState((applicationsState) => {
      return {
        ...applicationsState,
        currentApplication
      };
    });
  }, [applicationId, applications, applicationsLocalLoaded]);

  return {
    applications,
    applicationsLoaded: applicationsState.applicationsLoaded,
    noPermissionMessage: applicationsState.noPermissionMessage,
    currentApplication: applicationsState.currentApplication,
    myAccountGuid: accountGuid,
    noPermissionSubMessage: applicationsState.noPermissionSubMessage
  };
};

const getNoPermissionMessageOnApplicationRetrieved = (
  applications: IApplication[],
  applicationId: string | undefined,
  networkStatus: MyNetworkStatus | undefined): string => {
  // Network status restriction
  if (!_.isNil(networkStatus)) {
    const { HasCorpNetAccess, IsLocal } = networkStatus;

    if (!HasCorpNetAccess && !IsLocal) {
      const generalMsg = intl.get(LocIds.Error.HasNoCorpNetAccess);
      const subMsg = networkStatus.IsConnectingWithIPv6 ? intl.get(LocIds.Error.ContinueByDisableIPv6) :
        intl.get(LocIds.Error.ContinueByConnectToVPN);

      return `${generalMsg} ${subMsg}`;
    }
  }

  // RBAC restriction
  if (applications.length > 0 && applicationId) {
    return intl.get(LocIds.Error.NotExistOrNoPermission);
  }

  return intl.get(LocIds.Error.NoPermissionTrustPortal);
};

const getNoPermissionSubMessageOnApplicationRetrieved = (networkStatus: MyNetworkStatus | undefined) => {
  if (!_.isNil(networkStatus)) {
    const { HasCorpNetAccess, IsLocal, IsConnectingWithIPv6 } = networkStatus;

    if (!HasCorpNetAccess && !IsLocal) {
      return (
        <>
          <span>
            { intl.get(LocIds.Platform.ExternalApplicationCorpNetIpRestriction) }
          </span>
          <br/>
          <NetworkRestrictionDocLink/>
        </>
      );
    }
  }

  return null;
};
