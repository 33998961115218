import { useLocation } from 'react-router-dom';

import { Documentation } from '../../../../TrustPortal/registerApplicationMenu';

export const useDocUid = (): string => {
  const { pathname } = useLocation();
  const re = new RegExp(`(?<=${Documentation.Docs}/uid/).+`);
  const match = pathname.match(re);

  if (match) {
    return decodeURI(match[0]);
  }

  return '';
};
