import './style.less';

import React, {
  useEffect, useState
} from 'react';

import { Icon } from '@fluentui/react/lib/Icon';

import {
  LEFT_PAGE, RIGHT_PAGE
} from './constant';
import {
  getPage, getPageNeighbours, getPages
} from './helper';
import { PaginationProps } from './type';

export const Pagination: React.FC<PaginationProps> = (props) => {
  const {
    totalRecordCount,
    pageNeighbours,
    recordCountPerPage,
    onPageChange,
    defaultPage,
  } = props;

  const [currentPage, setCurrentPage] = useState<number>(
    getPage(totalRecordCount, recordCountPerPage, defaultPage),
  );

  useEffect(() => {
    setCurrentPage(getPage(totalRecordCount, recordCountPerPage, defaultPage));
  }, [totalRecordCount, recordCountPerPage, defaultPage]);

  useEffect(() => {
    onPageChange(currentPage);
  }, [onPageChange, currentPage]);

  if (totalRecordCount <= 0) {
    return null;
  }

  const pages = getPages({
    totalRecordCount,
    pageNeighbours,
    currentPage,
    recordCountPerPage,
  });

  if (pages.length <= 1) {
    return null;
  }

  const finalPageNeighbours = getPageNeighbours(pageNeighbours);
  const gotoPage = (page: number) => {
    setCurrentPage(getPage(totalRecordCount, recordCountPerPage, page));
  };
  return (
    <nav className='pagination'>
      <ul>
        { pages.map((page, index) => {
          if (page === LEFT_PAGE) {
            return (
              <li
                className='paginationItem paginationItemLeft'
                key={index}
                tabIndex={0}
                onClick={gotoPage.bind(null, currentPage - finalPageNeighbours)}
                onKeyDown={(e) => {
                  if (e.key === ' ' || e.key === 'Enter') {
                    e.preventDefault();
                    gotoPage(currentPage - finalPageNeighbours);
                  }
                }}>
                <Icon iconName='ChevronLeft' />
              </li>
            );
          }

          if (page === RIGHT_PAGE) {
            return (
              <li
                className='paginationItem paginationItemRight'
                key={index}
                tabIndex={0}
                onClick={gotoPage.bind(null, currentPage + finalPageNeighbours)}
                onKeyDown={(e) => {
                  if (e.key === ' ' || e.key === 'Enter') {
                    e.preventDefault();
                    gotoPage(currentPage + finalPageNeighbours);
                  }
                }}>
                <Icon iconName='ChevronRight' />
              </li>
            );
          }

          return (
            <li
              className={`paginationItem paginationItem${
                currentPage === page ? '--active' : ''
              }`}
              key={index}
              tabIndex={0}
              onClick={gotoPage.bind(null, page as number)}
              onKeyDown={(e) => {
                if (e.key === ' ' || e.key === 'Enter') {
                  e.preventDefault();
                  gotoPage(page as number);
                }
              }}>
              { page }
            </li>
          );
        }) }
      </ul>
    </nav>
  );
};
