import React, {
  useEffect, useState
} from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';
import {
  useNavigate, useParams
} from 'react-router-dom';

import { MAX_RETRY_TO_GET_LATEST_DISCUSSIONS } from './constant';
import { DiscussionPanel } from './DiscussionPanel';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { PublishedAuditEvent } from '../../models';
import { AuditEventApi } from '../../services';
import { useUserCommentRole } from '../../utils/react-hooks/useUserCommentRole';

export const AuditDiscussionPanel: React.FC = () => {
  const { auditEventGuid } = useParams();
  const role = useUserCommentRole();

  const navigate = useNavigate();

  const [auditEvent, setAuditEvent] = useState<PublishedAuditEvent>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchLatestDataWithRetry = async (auditEventGuid: string, maxRetryToEnsureLatest = 1) : Promise<PublishedAuditEvent> => {
    const response = await AuditEventApi.getPublishedAuditEventByGuidAsync(auditEventGuid);

    // Either the PublishedAuditEvent is new or discussion threads already updated.
    if (!auditEvent ||
      (response?.ETag !== auditEvent.ETag &&
        JSON.stringify(response?.DiscussionThreads) !== JSON.stringify(auditEvent.DiscussionThreads))) {
      return response;
    }

    // Or we don't want to retry anymore;
    if (maxRetryToEnsureLatest <= 1) {
      return response;
    }

    return await fetchLatestDataWithRetry(auditEventGuid, --maxRetryToEnsureLatest);
  };

  const fetchData = (maxRetryToEnsureLatest = 1) => {
    if (auditEventGuid) {
      setIsLoading(true);
      fetchLatestDataWithRetry(auditEventGuid, maxRetryToEnsureLatest)
        .then((response) => {
          setAuditEvent(response);
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [auditEventGuid]);

  const onDismiss = () => {
    navigate('..');
  };

  const onRefresh = () => {
    fetchData(MAX_RETRY_TO_GET_LATEST_DISCUSSIONS);
  };

  const onSendComment = (content: string) => {
    if (auditEvent && !_.isNil(role)) {
      return AuditEventApi.createPublishedAuditEventComment({
        auditEventGuid: auditEvent.AuditEventGuid,
        content,
        createdByRole: role
      });
    }

    setIsError(true);
  };

  // Discussions support single thread only for now.
  const threads = auditEvent?.DiscussionThreads ?? [];
  const comments = _.flatten(threads.map(t => t.Comments));

  return (
    <DiscussionPanel
      comments={comments.map(c => ({
        primaryText: c.CreatedByDisplayName,
        secondaryText: c.CreatedByRole,
        content: c.Content,
        createdAt: new Date(c.CreatedDate),
      }))}
      isDisabled={!(auditEvent?.IsMessagingEnabled ?? true)}
      isError={isError}
      isLoading={isLoading}
      isOpen={true}
      title={auditEvent?.Name ?? intl.get(LocIds.Label.AuditEvent)}
      onDismiss={onDismiss}
      onRefresh={onRefresh}
      onSendComment={onSendComment}
    />
  );
};
