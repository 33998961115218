import './style.less';

import React, {
  useEffect, useState
} from 'react';

import {
  ChoiceGroup, IChoiceGroupOption, PanelType, Separator, Stack, Text, TextField
} from '@fluentui/react';
import {
  flatten, isEmpty, isNil
} from 'lodash';
import intl from 'react-intl-universal';

import { ProposedEvidenceDiscussionSection } from './ProposedEvidenceDiscussionSection';

import { LocIds } from '../../../common/Globalization/IntlEnum';
import {
  AuditEvent,
  AuditEventCertification,
  AuditEventEnvironment,
  CertificationControl,
  CreateProposedEvidenceRequestPayload,
  ProposedEvidence,
  Service
} from '../../../models';
import { ProposedEvidenceApi } from '../../../services';
import {
  notify, UseAsyncHookStatus
} from '../../../utils';
import { SingleSelectDropdown } from '../../Dropdown';
import {
  CertificationControlDropdown, ServiceDropdown
} from '../../EntitySelection';
import {
  FormSection, ViewLayout
} from '../../TrustLayout';
import { TrustPanel } from '../../TrustPanel';

export enum EvidenceProposalPanelMode {
  Create, Detail
}

type IProps = {
  mode: EvidenceProposalPanelMode;
  isOpen: boolean;
  isAuditManager: boolean;
  auditEvent: AuditEvent | undefined;
  proposedEvidence?: ProposedEvidence;
  onDismiss: () => void;
  onReload: () => void;
  onCreate?: (evidence: ProposedEvidence) => void;
}

enum ServiceSelectionOption {
  All = 'all',
  Custom = 'custom'
}

export const ProposedEvidencePanel: React.FC<IProps> = (props) => {
  const {
    mode, isOpen, isAuditManager,
    auditEvent, proposedEvidence,
    onDismiss, onReload, onCreate,
  } = props;

  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [certification, setCertification] = useState<AuditEventCertification>();
  const [certificationControls, setCertificationControls] = useState<CertificationControl[]>();
  const [services, setServices] = useState<Service[]>();

  const [isEdited, setIsEdited] = useState<boolean>(false);

  const [apiStatus, setApiStatus] = useState<UseAsyncHookStatus>(UseAsyncHookStatus.Success);

  useEffect(() => {
    if (isOpen && !isNil(auditEvent) && !isNil(proposedEvidence)) {
      setTitle(proposedEvidence.Title);
      setDescription(proposedEvidence.Description);
      setCertification(auditEvent?.Certifications.find(c => c.Name === proposedEvidence.Certification?.Name));
      setServiceSelectionOption(isEmpty(proposedEvidence.Environments) ? ServiceSelectionOption.All : ServiceSelectionOption.Custom);
    }
  }, [isOpen, auditEvent, proposedEvidence]);

  const disabled = mode === EvidenceProposalPanelMode.Detail;

  const certDropdown = (
    <SingleSelectDropdown
      disabled={disabled}
      items={auditEvent?.Certifications ?? []}
      keyOf={(item) => `${item.CertificationFamilyId}`}
      label={intl.get(LocIds.Label.Certification)}
      optionOf={(item) => ({
        key: `${item.CertificationFamilyId}`,
        text: item.Name,
      })}
      placeholder={intl.get(LocIds.Placeholder.SelectAnOption)}
      selectedItem={certification}
      onChange={setCertification}
    />
  );

  const certControlDropdown = (
    <CertificationControlDropdown
      certificationFamilyId={certification?.CertificationFamilyId ?? null}
      defaultValue={proposedEvidence?.Certification?.Controls}
      disabled={disabled}
      selected={certificationControls}
      onChange={setCertificationControls}
    />
  );

  const titleTextField = (
    <TextField
      disabled={disabled}
      label={intl.get(LocIds.Label.Title)}
      required
      value={title}
      onChange={(e, newValue) => {
        setTitle(newValue ?? '');
        setIsEdited(true);
      }}
    />
  );

  const descTextField = (
    <TextField
      disabled={disabled}
      label={intl.get(LocIds.Label.Description)}
      multiline
      required
      value={description}
      onChange={(e, newValue) => {
        setDescription(newValue ?? '');
        setIsEdited(true);
      }}
    />
  );

  const options: IChoiceGroupOption[] = [
    {
      key: ServiceSelectionOption.All,
      text: intl.get(LocIds.Label.AllServices)
    },
    {
      key: ServiceSelectionOption.Custom,
      text: intl.get(LocIds.Label.SelectedServices),
    }
  ];

  const [serviceSelectionOption, setServiceSelectionOption] = useState<ServiceSelectionOption>(ServiceSelectionOption.All);

  const serviceChoiceJsx = (
    <ChoiceGroup
      disabled={disabled}
      label={intl.get(LocIds.Label.Service)}
      options={options}
      selectedKey={serviceSelectionOption}
      onChange={(e, option) => {
        if (option) {
          setServiceSelectionOption(option.key as ServiceSelectionOption);
          setIsEdited(true);
        }
      }}
    />
  );

  const serviceDropdownJsx = (
    <ServiceDropdown
      certificationFamilyId={certification?.CertificationFamilyId}
      complianceAuditGuid={auditEvent?.AuditEventGuid}
      defaultValues={getServiceTreeIds(proposedEvidence?.Environments ?? []).map(id => ({
        guid: id
      }))}
      disabled={disabled}
      environmentId={null}
      isMulti={true}
      selected={services}
      onChange={services => {
        setServices(services);
        setIsEdited(true);
      }}
    />
  );

  const formJsx = (
    <FormSection>
      { mode === EvidenceProposalPanelMode.Create && <Text>{ intl.get(LocIds.Evidence.ProposeEvidenceGuidance) }</Text> }
      { titleTextField }
      { descTextField }
      { certDropdown }
      { certControlDropdown }
      { serviceChoiceJsx }
      { serviceSelectionOption === ServiceSelectionOption.Custom && serviceDropdownJsx }
    </FormSection>
  );

  // Discussions support single thread only for now.
  const threads = proposedEvidence?.DiscussionThreads ?? [];
  const comments = flatten(threads.map(t => t.Comments));

  const discussionJsx = (
    <Stack.Item className='proposedEvidencePanel__segment' >
      <ViewLayout isError={false} isLoaded={!isNil(proposedEvidence) && !isNil(auditEvent)} >
        { !isNil(proposedEvidence) && !isNil(auditEvent) && (
          <ProposedEvidenceDiscussionSection
            auditEventGuid={auditEvent.AuditEventGuid}
            comments={comments}
            proposedEvidenceGuid={proposedEvidence.ProposedEvidenceGuid}
            onReload={onReload}
          />
        ) }
      </ViewLayout>
    </Stack.Item>
  );

  const twoColumnContentJsx = (
    <Stack
      className='proposedEvidencePanel'
      horizontal>
      <Stack.Item className='proposedEvidencePanel__segment'>
        { formJsx }
      </Stack.Item>
      <Separator vertical />
      { discussionJsx }
    </Stack>
  );

  // ---------------------------- Actions ----------------------------

  const dismissAndReset = () => {
    setTitle('');
    setDescription('');
    setCertification(undefined);
    setCertificationControls(undefined);
    setServices(undefined);

    onDismiss();
  };

  const onSubmit = () => {
    if (!auditEvent) {
      return;
    }

    const payload: CreateProposedEvidenceRequestPayload = {
      auditEventGuid: auditEvent.AuditEventGuid,
      title,
      description,
      setCertificationId: certification ? {
        Value: certification.CertificationFamilyId
      } : undefined,
      certificationControlIds: certificationControls?.map(c => c.CertificationControlId) ?? [],
      serviceTreeIds: serviceSelectionOption === ServiceSelectionOption.All ? [] :
          services?.map(s => s.ServiceTreeId).filter(id => !isNil(id)) as string[] ?? []
    };

    setApiStatus(UseAsyncHookStatus.Pending);
    notify.info(intl.get(LocIds.Action.StartProcessingRequest));

    ProposedEvidenceApi.createProposedEvidenceAsync(payload)
      .then(() => {
        setApiStatus(UseAsyncHookStatus.Success);
        notify.success(intl.get(LocIds.Evidence.ProposedEvidenceCreated));

        onReload();
        dismissAndReset();
      })
      .catch(() => {
        setApiStatus(UseAsyncHookStatus.Fail);
        notify.error(intl.get(LocIds.Error.SomethingWentWrong));
      });
  };

  const isSubmissionDisabled = isEmpty(title) || isEmpty(description) ||
    (serviceSelectionOption === ServiceSelectionOption.Custom && isEmpty(services));

  const submitActionProps = {
    text: intl.get(LocIds.Action.Submit),
    onClick: onSubmit,
    disabled: isSubmissionDisabled || apiStatus === UseAsyncHookStatus.Pending
  };

  return (
    <TrustPanel
      headerText={intl.get(LocIds.Evidence.ProposeEvidence)}
      isOpen={isOpen}
      preventClose={mode === EvidenceProposalPanelMode.Create && isEdited}
      primaryActionProps={mode === EvidenceProposalPanelMode.Create ? submitActionProps : undefined}
      showErrorMessage={apiStatus === UseAsyncHookStatus.Fail}
      showProcessingMessage={apiStatus === UseAsyncHookStatus.Pending}
      showSpinnerInPrimaryButton={apiStatus === UseAsyncHookStatus.Pending}
      type={mode === EvidenceProposalPanelMode.Detail ? PanelType.largeFixed : undefined}
      onDismiss={dismissAndReset}>
      { mode === EvidenceProposalPanelMode.Create && formJsx }
      { mode === EvidenceProposalPanelMode.Detail && twoColumnContentJsx }
    </TrustPanel>
  );
};

const getServiceTreeIds = (environments: AuditEventEnvironment[]) => {
  const uniqueServiceTreeIdsSet: Set<string> = new Set();

  for (const environment of environments) {
    for (const service of environment.Services) {
      if (!isNil(service.ServiceTreeId)) {
        uniqueServiceTreeIdsSet.add(service.ServiceTreeId);
      }
    }
  }

  return Array.from(uniqueServiceTreeIdsSet);
};
