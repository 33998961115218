import { useMemo } from 'react';

import { keyBy } from 'lodash';

import { IApplication } from '../../models';

export const useAppDisplayName = (applications: IApplication[], appId: string): string => {
  const appMap = useMemo(() => {
    return keyBy(applications, 'ApplicationId');
  }, [applications]);

  return appMap[appId]?.DisplayName || '';
};
