import React, {
  useCallback, useEffect
} from 'react';

import './style.less';
import { Spinner } from '@fluentui/react/lib/Spinner';
import intl from 'react-intl-universal';
import { useRoutes } from 'react-router-dom';

import { routes } from '../applications/TrustPortal/router';
import { LocIds } from '../common/Globalization/IntlEnum';
import {
  Banner, ErrorBoundary, ModalProvider
} from '../components';

import {
  useSignInFailedSelector, useSignInSelector
} from '../redux/authSlice/selectors';
import { signIn } from '../redux/authSlice/thunk';

import { useConfigApplicationsSelector } from '../redux/configSlice/selector';
import { useAppDispatch } from '../redux/reducer';
import {
  applicationInsightsService, portalConfigurationService
} from '../services';
import {
  initializeCentro, useAppId
} from '../utils';

export const App: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const isSignIn = useSignInSelector();
  const isSignInFailed = useSignInFailedSelector();

  const { applicationId } = useAppId();
  const applications = useConfigApplicationsSelector();

  const router = useRoutes(routes);

  useEffect(() => {
    if (!isSignIn) {
      dispatch(signIn());
    }
  }, [dispatch, isSignIn]);

  const onError = useCallback((e: Error) => {
    const currentProjectIdentifier = applications.find(a => a.ApplicationId === applicationId)?.ProjectIdentifier;
    applicationInsightsService.trackException(e, currentProjectIdentifier);
  },
  [applications, applicationId]);

  useEffect(() => {
    if (portalConfigurationService.getPortalFlightings().useOcvFeedback) {
      initializeCentro();
    }
  }, []);

  if (isSignInFailed) {
    return <Banner heading={intl.get(LocIds.Application.SignInFailed)} iconName='restricted' iconSize='large' />;
  }

  if (!isSignIn) {
    return (
      <Spinner className='rootSpinner initial-spinner' />
    );
  }

  return (
  // @ts-ignore
    <ErrorBoundary onError={onError}>
      <ModalProvider>
        { router }
      </ModalProvider>
    </ErrorBoundary>
  );
};
