import { EvidenceTreeNodeVisitor } from './EvidenceTreeNodeVisitor';

import { EvidencePath } from '../EvidencePath';
import { AuditEventNode } from '../EvidenceTreeNode/AuditEventNode';
import { CertificationNode } from '../EvidenceTreeNode/CertificationNode';
import { EvidenceNode } from '../EvidenceTreeNode/EvidenceNode';
import {
  EvidenceTreeInternalNode,
  EvidenceTreeLeafNode,
  EvidenceTreeNodeBase,
} from '../EvidenceTreeNode/EvidenceTreeNodeBase';
import { EvidenceTreeRootNode } from '../EvidenceTreeNode/EvidenceTreeRootNode';
import { TeamNode } from '../EvidenceTreeNode/TeamNode';
/**
 * Given an EvidencePath, get all filtered EvidenceKeys on this path
 *
 * Scenario: When user selects a node in EvidenceTree, we get all filtered EvidenceKeys belong to this node
 * to generate a PostTreeEvidenceStore
 */
export class FilterEvidencesOnPathVisitor implements EvidenceTreeNodeVisitor {
  constructor(path: EvidencePath) {
    this.path = path;
  }

  public visitEvidenceTreeRootNode(node: EvidenceTreeRootNode): void {
    node.children.forEach((child: EvidenceTreeNodeBase) => child.accept(this));
  }

  public visitAuditEventNode(node: AuditEventNode): void {
    this.getLeafNodesOnPath(node);
  }

  public visitTeamNode(node: TeamNode): void {
    this.getLeafNodesOnPath(node);
  }

  public visitCertificationNode(node: CertificationNode): void {
    this.getLeafNodesOnPath(node);
  }

  public visitEvidenceNode(node: EvidenceNode): void {
    if (!this.isNodeOnPath(node)) {
      return;
    }

    if (node.identifierValue) {
      this._visibleEvidenceIdList = [...this._visibleEvidenceIdList, node.identifierValue, ];
    }
  }

  private isNodeOnPath(node: EvidenceTreeNodeBase): boolean {
    // invisible node, skip;
    if (!node.isFiltered) {
      return false;
    }

    // invalid node, skip;
    if (!node.identifierName || !node.identifierValue) {
      return false;
    }

    const pathValues = Object.values(this.path);

    // all path above current node is matched
    if (pathValues.length === 0) {
      return true;
    }

    // leaf node can only match one identifier
    if (node instanceof EvidenceTreeLeafNode && pathValues.length > 1) {
      return false;
    }

    return this.path[node.identifierName] === node.identifierValue;
  }

  private getLeafNodesOnPath(node: EvidenceTreeInternalNode): void {
    if (!this.path) {
      return;
    }

    if (!this.isNodeOnPath(node)) {
      return;
    }

    if (!node.identifierName) {
      return;
    }

    const originPath = Object.assign({}, this.path);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [node.identifierName]: removeIdentifier, ...rest } = this.path;
    // current node is part of original path, remove matched identifier
    this.path = rest;

    if (node.children) {
      node.children.forEach((child) => {
        child.accept(this);
      });
    }

    // do backtracking
    this.path = Object.assign({}, originPath);
  }

  get visibleEvidences(): string[] {
    return Array.from(new Set(this._visibleEvidenceIdList));
  }

  private path: EvidencePath = {};
  private _visibleEvidenceIdList: string[] = [];
}
