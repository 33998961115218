import {
  useEffect,
  useState
} from 'react';

import { getProtectionFileTypesAsync } from '../../services/AthenaRestApiService/FileApiService';

export const useProtectionFileType = (): Set<string> => {
  const [protectionFileTypes, setProtectionFileTypes] = useState<Set<string>>(new Set([]));

  useEffect(() => {
    getProtectionFileTypesAsync().then(res => setProtectionFileTypes(new Set(res.map(r => r.OriginalFileExtension))));
  }, []);

  return protectionFileTypes;
};
