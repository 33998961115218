import {
  createAsyncThunk, createSlice, PayloadAction
} from '@reduxjs/toolkit';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { RootReduxState } from './reducer';

import {
  AzureAdMember,
  ContinuousMonitoringEnvironment,
  ContinuousMonitoringOnboardedSchedule,
  ServiceDetail,
} from '../../../../../models';
import { ServiceWorkspaceApiService } from '../../../../../services';
import {
  getConcatenatedUid, UseAsyncHookStatus
} from '../../../../../utils';
import { ContactInfo } from '../../../models';
import {
  ConMonAuditEvent, ConMonTask
} from '../../../models/ContinuousMonitoringTypes';

/**
 * Types
 */

type SliceState = {
  services: ServiceDetail[] | undefined;

  // Notification config panel
  editingService: ServiceDetail | undefined;

  scheduleService: ServiceDetail | undefined;
  // <key, alias> pair of onboarded schedules
  assignedTos: Record<string, ContactInfo>;

  onboardedAuditEvents: ConMonAuditEvent[] | undefined;
  allAuditEvents: ConMonAuditEvent[] | undefined;
  loadingAllAuditEventsStatus: UseAsyncHookStatus;
  loadingOnboardedAuditEventsStatus: UseAsyncHookStatus;
  loadingScheduleServiceStatus: UseAsyncHookStatus;
  loadingServicesStatus: UseAsyncHookStatus;
};

const initialState: SliceState = {
  services: undefined,
  editingService: undefined,

  scheduleService: undefined,
  assignedTos: {},

  onboardedAuditEvents: undefined,
  allAuditEvents: undefined,
  loadingAllAuditEventsStatus: UseAsyncHookStatus.Success,
  loadingOnboardedAuditEventsStatus: UseAsyncHookStatus.Success,
  loadingScheduleServiceStatus: UseAsyncHookStatus.Success,
  loadingServicesStatus: UseAsyncHookStatus.Success
};

/**
 * Thunks
 */

export const fetchServiceDetails = createAsyncThunk<ServiceDetail[]>(
  'service/fetchServiceBoardItems',
  async () => {
    return await ServiceWorkspaceApiService.getServiceDetailsAsync();
  }
);

export const fetchServiceDetailById = createAsyncThunk<ServiceDetail | undefined, { serviceTreeId: string; }>(
  'service/fetchServiceDetailById',
  async ({ serviceTreeId }) => {
    return await ServiceWorkspaceApiService.getServiceDetailByIdAsync(serviceTreeId);
  }
);

export const fetchOnboardedConMonSchedules = createAsyncThunk<ContinuousMonitoringOnboardedSchedule[], { serviceTreeId: string; }>(
  'service/fetchOnboardedConMonSchedules',
  async ({ serviceTreeId }) => {
    return await ServiceWorkspaceApiService.getOnboardedConMonSchedulesAsync(serviceTreeId);
  }
);

export const fetchAllConMonEnvironments = createAsyncThunk<
  ContinuousMonitoringEnvironment[],
  { contact?: ContactInfo; environmentIds: number[]; }
>(
  'service/fetchAllConMonEnvironments',
  async () => {
    return await ServiceWorkspaceApiService.getContinuousMonitoringEnvironmentsAsync();
  }
);


/**
 * Slice
 */

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setEditingService: (state, action: PayloadAction<ServiceDetail | undefined>) => {
      state.editingService = action.payload;
    },
    clearCachedAllAuditEvents: (state) => {
      state.allAuditEvents = undefined;
      state.assignedTos = {};
    },
    clearScheduleService: (state) => {
      state.scheduleService = undefined;
    },
    updateAssignedTo: (state, action: PayloadAction<{ key: string; adMember: AzureAdMember; }>) => {
      const { key, adMember } = action.payload;

      if (adMember && adMember.AccountGuid) {
        state.assignedTos[key] = {
          alias: adMember.Alias,
          accountGuid: adMember.AccountGuid,
        };
      } else {
        delete state.assignedTos[key];
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServiceDetails.fulfilled, (state, action) => {
      state.services = action.payload;
      state.loadingServicesStatus = UseAsyncHookStatus.Success;
    });
    builder.addCase(fetchServiceDetails.pending, (state) => {
      state.loadingServicesStatus = UseAsyncHookStatus.Pending;
    });
    builder.addCase(fetchServiceDetails.rejected, (state) => {
      state.loadingServicesStatus = UseAsyncHookStatus.Fail;
    });

    builder.addCase(fetchServiceDetailById.pending, (state) => {
      state.loadingScheduleServiceStatus = UseAsyncHookStatus.Pending;
    });
    builder.addCase(fetchServiceDetailById.fulfilled, (state, action) => {
      state.scheduleService = action.payload;
      state.loadingScheduleServiceStatus = UseAsyncHookStatus.Success;
    });
    builder.addCase(fetchServiceDetailById.rejected, (state) => {
      state.scheduleService = undefined;
      state.loadingScheduleServiceStatus = UseAsyncHookStatus.Fail;
    });
    builder.addCase(fetchOnboardedConMonSchedules.pending, (state) => {
      state.onboardedAuditEvents = undefined;
      state.assignedTos = {};
      state.loadingOnboardedAuditEventsStatus = UseAsyncHookStatus.Pending;
    });
    builder.addCase(fetchOnboardedConMonSchedules.fulfilled, (state, action) => {
      const auditEvents: ConMonAuditEvent[] = [];

      // Flatten ContinuousMonitoringOnboardedSchedule array to nested front-end model.
      const groupedSchedules = _.groupBy(action.payload, 'AuditEventGuid');
      Object.entries(groupedSchedules).forEach(([auditEventGuid, schedulesOfAuditEvent]) => {
        if (schedulesOfAuditEvent.length > 0) {
          const tasks: ConMonTask[] = [];
          const groupedTasks = _.groupBy(schedulesOfAuditEvent, 'TaskId');
          Object.entries(groupedTasks).forEach(([taskId, schedules]) => {
            if (schedules.length > 0) {
              tasks.push({
                id: Number(taskId),
                name: schedules[0].TaskName,
                environments: schedules.map(s => ({
                  id: s.EnvironmentId,
                  name: s.EnvironmentName,
                  halted: s.HaltMonitoring,
                  assignedTo: s.AssignedTo
                }))
              });

              // Prepare empty assigned to fields.
              schedules.forEach(s => {
                state.assignedTos[getConcatenatedUid(auditEventGuid, taskId, s.EnvironmentId)] = {
                  alias: '',
                  accountGuid: ''
                };
              });
            }
          });

          if (tasks.length > 0) {
            auditEvents.push({
              guid: auditEventGuid,
              name: schedulesOfAuditEvent[0].AuditEventName,
              tasks,
            });
          }
        }
      });

      state.onboardedAuditEvents = auditEvents;
      state.loadingOnboardedAuditEventsStatus = UseAsyncHookStatus.Success;
    });

    builder.addCase(fetchOnboardedConMonSchedules.rejected, (state) => {
      state.loadingOnboardedAuditEventsStatus = UseAsyncHookStatus.Fail;
    });

    builder.addCase(fetchAllConMonEnvironments.fulfilled, (state, action) => {
      const auditEvents: ConMonAuditEvent[] = [];
      const { contact, environmentIds } = action.meta.arg;

      // Flatten ContinuousMonitoringEnvironment array to nested front-end model.
      const groupedEnvironments = _.groupBy(action.payload, 'AuditEventGuid');
      Object.entries(groupedEnvironments).forEach(([auditEventGuid, environmentsOfAuditEvent]) => {
        if (environmentsOfAuditEvent.length > 0) {
          const tasks: ConMonTask[] = [];
          const groupedTasks = _.groupBy(environmentsOfAuditEvent, 'ContinuousMonitoringTaskV2Id');

          Object.entries(groupedTasks).forEach(([taskId, environments]) => {
            if (environments.length > 0) {
              const displayedEnvironments = environments
                .filter(e => !isOnboarded(e, state.onboardedAuditEvents) && environmentIds.includes(e.EnvironmentId));

              // Only add the task if there are environments that are not onboarded.
              if (displayedEnvironments.length > 0) {
                tasks.push({
                  id: Number(taskId),
                  name: environments[0].TaskName,
                  environments: displayedEnvironments.map(e => ({
                    id: e.EnvironmentId,
                    name: e.EnvironmentName,
                    halted: e.HaltMonitoring,
                  })),
                });

                // If default alias is provided, fill it in all "not onboarded environments".
                if (contact) {
                  displayedEnvironments.forEach(e => {
                    state.assignedTos[getConcatenatedUid(auditEventGuid, taskId, e.EnvironmentId)] = contact;
                  });
                }
              }
            }
          });

          if (tasks.length > 0) {
            auditEvents.push({
              guid: auditEventGuid,
              name: environmentsOfAuditEvent[0].AuditEventName,
              tasks,
            });
          }
        }
      });

      state.allAuditEvents = auditEvents;
      state.loadingAllAuditEventsStatus = UseAsyncHookStatus.Success;
    });

    builder.addCase(fetchAllConMonEnvironments.pending, (state) => {
      state.loadingAllAuditEventsStatus = UseAsyncHookStatus.Pending;
    });

    builder.addCase(fetchAllConMonEnvironments.rejected, (state) => {
      state.loadingAllAuditEventsStatus = UseAsyncHookStatus.Fail;
    });
  }
});

// Util function to check whether an environment is onboarded.
const isOnboarded = (environment: ContinuousMonitoringEnvironment, onboardedAuditEvents: ConMonAuditEvent[] | undefined): boolean => {
  const auditEvent = onboardedAuditEvents?.find(a => a.guid === environment.AuditEventGuid);

  if (auditEvent) {
    const task = auditEvent.tasks.find(t => t.id === environment.ContinuousMonitoringTaskV2Id);

    if (task) {
      return task.environments.some(e => e.id === environment.EnvironmentId);
    }
  }

  return false;
};


/**
 * Actions
 */

export const { setEditingService, clearCachedAllAuditEvents, clearScheduleService, updateAssignedTo } = serviceSlice.actions;

/**
 * Hooks
 */

export const useAllServiceDetails = (): ServiceDetail[] | undefined =>
  useSelector<RootReduxState, ServiceDetail[] | undefined>((state) => state.serviceBoard.service.services);

export const useEditingService = (): ServiceDetail | undefined =>
  useSelector<RootReduxState, ServiceDetail | undefined>((state) => state.serviceBoard.service.editingService);

export const useServiceOfScheduleBoard = (): ServiceDetail | undefined =>
  useSelector<RootReduxState, ServiceDetail | undefined>((state) => state.serviceBoard.service.scheduleService);

export const useAssignedTos = (): Record<string, ContactInfo> =>
  useSelector<RootReduxState, Record<string, ContactInfo>>((state) => state.serviceBoard.service.assignedTos);

export const useCanOnboard = (keys: string[]): boolean =>
  useSelector<RootReduxState, boolean>((state) => {
    const assignedTos = state.serviceBoard.service.assignedTos;
    return keys.length > 0 ? keys.every((key) => !_.isEmpty(assignedTos[key])) : false;
  });

export const useOnboardedAuditEvents = (): ConMonAuditEvent[] | undefined =>
  useSelector<RootReduxState, ConMonAuditEvent[] | undefined >((state) => state.serviceBoard.service.onboardedAuditEvents);

export const useAllAuditEvents = (): ConMonAuditEvent[] | undefined =>
  useSelector<RootReduxState, ConMonAuditEvent[] | undefined >((state) => state.serviceBoard.service.allAuditEvents);

export const useLoadingAllAuditEventsStatus = (): UseAsyncHookStatus =>
  useSelector<RootReduxState, UseAsyncHookStatus>(state => state.serviceBoard.service.loadingAllAuditEventsStatus);

export const useLoadingOnboardedAuditEventsStatus = (): UseAsyncHookStatus =>
  useSelector<RootReduxState, UseAsyncHookStatus>(state => state.serviceBoard.service.loadingOnboardedAuditEventsStatus);

export const useLoadingScheduleServiceStatus = (): UseAsyncHookStatus =>
  useSelector<RootReduxState, UseAsyncHookStatus>(state => state.serviceBoard.service.loadingScheduleServiceStatus);

export const useLoadingServicesStatus = (): UseAsyncHookStatus =>
  useSelector<RootReduxState, UseAsyncHookStatus>(state => state.serviceBoard.service.loadingServicesStatus);
