import React from 'react';

import {
  DropdownMenuItemType, IDropdownOption
} from '@fluentui/react';
import _, { isNil } from 'lodash';

import intl from 'react-intl-universal';

import {
  BaseDropdown, SelectAllBaseDropdownProps, SelectAllCheckBoxState
} from './BaseDropdown';
import { SharedDropdownProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';

export interface SelectAllDropdownProps {
  maxNumOfSelectableItems: number;
  numOfCurrentlySelected: number;
}

export interface MultiSelectDropdownProps<T> extends SharedDropdownProps<T> {
  selectedItems?: T[];
  onChange?: (items: T[]) => void;
  ariaLabel?: string;
  selectAllProps?: SelectAllDropdownProps;
}

export function MultiSelectDropdown<T>(
  props: MultiSelectDropdownProps<T>,
): ReturnType<React.FC<MultiSelectDropdownProps<T>>> {
  const { items, keyOf, onChange } = props;

  // exclude the invalid/out-dated selected items if any
  const validSelectedItems =
    props.selectedItems?.filter((item) =>
      items.some((item0) => keyOf(item) === keyOf(item0)),
    ) ?? [];

  const selectedKeys = validSelectedItems.map((item) => keyOf(item)) ?? [];

  const onDropdownChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
  ): void => {
    if (option && onChange) {
      const selectedItem = items.find((item) => keyOf(item) === option.key);

      if (!_.isNil(selectedItem) && onChange) {
        onChange(
          option.selected ?
            [...validSelectedItems, selectedItem] :
            validSelectedItems.filter((item) => keyOf(item) !== option.key),
        );
      }
    }
  };

  const onSelectAll = () => {
    if (onChange && props.selectAllProps) {
      if (props.selectAllProps.numOfCurrentlySelected === props.selectAllProps.maxNumOfSelectableItems) {
        onChange([]);
      } else {
        onChange(items);
      }
    }
  };

  const getStateOfSelectAllCheckBox = () => {
    if (props.selectAllProps?.maxNumOfSelectableItems === props.selectAllProps?.numOfCurrentlySelected) {
      return SelectAllCheckBoxState.Checked;
    } else if (props.selectAllProps?.numOfCurrentlySelected === 0) {
      return SelectAllCheckBoxState.Unchecked;
    }

    return SelectAllCheckBoxState.Indeterminate;
  };

  const selectAllDropdownOptions = [
    {
      key: intl.get(LocIds.Action.SelectAll),
      text: intl.get(LocIds.Action.SelectAll),
      itemType: DropdownMenuItemType.Header,
    },
    {
      key: `${intl.get(LocIds.Action.SelectAll)}Divider`,
      itemType: DropdownMenuItemType.Divider,
    },
  ] as IDropdownOption[];

  const selectAllBaseDropdownProps = isNil(props.selectAllProps) ? undefined : {
    comboBoxState: getStateOfSelectAllCheckBox(),
    dropdownOptions: selectAllDropdownOptions,
    onSelectAll
  } as SelectAllBaseDropdownProps;

  return (
    <BaseDropdown
      {...props}
      multiSelect
      selectAllProps={selectAllBaseDropdownProps}
      selectedKeys={selectedKeys}
      onChange={onDropdownChange}
    />
  );
}
