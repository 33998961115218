import React, { useEffect } from 'react';

import _ from 'lodash';
import * as intl from 'react-intl-universal';
import { useNavigate } from 'react-router-dom';


import { useDocData } from './Docs';

import { useDocPath } from './hooks/useDocPath';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { Banner } from '../../../../components/Banner';
import {
  Application,
  Documentation
} from '../../../TrustPortal/registerApplicationMenu';


export const PathRedirector = () => {
  const navigate = useNavigate();
  const path = useDocPath();
  const menuTree = useDocData().menuTree;
  const uid = menuTree.findUidByPath(path);

  useEffect(() => {
    if (!_.isEmpty(uid)) {
      navigate(`/application/${Application.Documentation}/menu/${Documentation.Docs}/uid/${uid}`);
    }
  }, [uid]);


  return <Banner heading={intl.get(LocIds.Documentation.InvalidPath)} iconName='empty' iconSize='large' />;
};
