import React from 'react';

import './useNavMenu.less';
import { Icon } from '@fluentui/react';
import {
  IContextualMenuItem,
  IContextualMenuProps
} from '@fluentui/react/lib/ContextualMenu';

import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

export const useNavMenu = (list: IContextualMenuItem[]): IContextualMenuProps => {

  const menuItems: IContextualMenuItem[] = list.map((item: IContextualMenuItem) => {
    const baseClassName = classnames(
      'text-14 tp-menu__item',
      item.disabled && 'tp-menu__item--disabled',
      item.className);

    const className = ({ isActive }: { isActive: boolean; }): string =>
      `${baseClassName} ${isActive ? 'tp-menu__item--active' : ''}`;

    const icon = (
      <Icon aria-label={item.iconProps?.iconName}
        iconName={item.iconProps?.iconName}
        styles={item.iconProps?.styles || {
          root: {
            marginRight: '8px',
            color: 'var(--primary)'
          }
        }}
      />
    );

    return {
      key: item.key,
      onRender: (_: IContextualMenuItem, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {

        if (item.href) {
          return (
            <NavLink
              aria-label={item.text}
              className={className}
              key={item.key}
              role='menuitem'
              to={item.href}
              onClick={dismissMenu}>
              { icon }
              { item.text }
            </NavLink>
          );
        }

        const onClick = () => {
          item.onClick && item.onClick();
          dismissMenu();
        };

        return (
          <span aria-label={item.text} className={baseClassName} key={item.key} onClick={onClick}>
            { icon }
            { item.text }
          </span>
        );
      }
    };
  });

  return {
    items: menuItems
  };
};
