import _ from 'lodash';
import { OdataQuery } from 'odata';
import buildQuery, {
  Guid, ITEM_ROOT, OrderBy, PlainObject, QueryOptions,
} from 'odata-query';

/**
 * Get filter string from an enum array, can be used to query data of several enum type
 * this string can be appended to query `?$filter={returnString}`
 */
export const fieldMatchAny = <T extends string | number>(
  arr: T[],
  filterField: string,
): string | undefined => {
  if (_.isEmpty(arr)) {
    return undefined;
  }

  const filter = {
    or: arr.map((v: T) => ({
      [filterField]: v,
    })),
  };
  return buildQuery({
    filter
  }).replace('?$filter=', '');
};

/**
 * Simple equality filter
 *
 * fieldMatchOnly(10, 'age') => age eq 10
 */
export const fieldMatchOnly = (
  value: string | number | Guid,
  filterField: string,
): string => {
  const filter = {
    [filterField]: value
  };
  return buildQuery({
    filter
  }).replace('?$filter=', '');
};

export const buildFilterString = (
  filter: Partial<QueryOptions<unknown | undefined>>,
): string => buildQuery(filter).replace('?$filter=', '');

export const buildSearchString = (
  search: Partial<QueryOptions<unknown | undefined>>,
): string => buildQuery(search).replace('?$search=', '');

export const buildOrderByString = (orderBy: OrderBy<unknown>): string =>
  buildQuery({
    orderBy
  }).replace('?$orderby=', '');

/**
 * Build OdataQuery used by API service.
 * @param queryOptions
 */
export const buildODataQuery = (
  queryOptions: Partial<QueryOptions<unknown | undefined>>,
): OdataQuery => {
  const odataQuery: OdataQuery = {};

  if (queryOptions.filter) {
    odataQuery.$filter = buildFilterString({
      filter: queryOptions.filter
    });
  }

  if (queryOptions.orderBy) {
    odataQuery.$orderby = buildOrderByString(queryOptions.orderBy);
  }

  return odataQuery;
};

/**
 * Construct the OData query to filter a target attribute of an array of items by the rule of
 * "count in the ones that contains any one item of the provided array".
 * Example:
 *    data attribute: [A, B, C] will be counted in, if provided items: [C, D]
 * @param items items used to filter the target array.
 * @param itemName the name of the target array attribute.
 */
export const buildAnyInArrayQuery = (items: string[] | PlainObject[], itemName: string = ITEM_ROOT): PlainObject => {
  return {
    any: {
      [itemName]: {
        in: [...items],
      },
    },
  };
};

export const convertToGuidObject = (guid: string) => {
  return {
    type: 'guid',
    value: guid,
  };
};
