export enum QuestionType {
    Text = 'Text',
    Number = 'Number',
    SingleResponseChoice = 'SingleResponseChoice',
    MultiResponseChoice = 'MultiResponseChoice',
    Date = 'Date',
    DateRange = 'DateRange',
    Email = 'Email',
    Binary = 'Binary',
    RichText = 'RichText',
    PeoplePicker = 'PeoplePicker'
}
