import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import {
  BoardOption,
  FilterSliceState
} from './filterSlice';
import { RootReduxState } from './reducer';

import {
  OriginalEvidence,
  OriginalEvidenceAssignedOptions
} from '../../../../../models';
import { EvidenceApi } from '../../../../../services';
import { UseAsyncHookStatus } from '../../../../../utils';

/**
 * Types
 */

type EvidenceSliceState = {
  evidences: OriginalEvidence[] | undefined;
  loadingEvidencesStatus: UseAsyncHookStatus;
};

/**
 * Async thunks
 */

export const fetchAllEvidenceDetails = createAsyncThunk<
  OriginalEvidence[],
  { alias: string },
  { state: RootReduxState }
>(
  'evidence/fetchAllEvidenceDetails',
  async ({ alias }, { getState }) => {
    const state: FilterSliceState = getState().taskBoard.filter;

    const filter = state.assignedToMe.filter;
    const { dropdown, options } = state.board;

    const extraOptions: OriginalEvidenceAssignedOptions = {
      alias: dropdown.selected === BoardOption.AssignedToMe ? alias : undefined,
      services: dropdown.selected === BoardOption.AssignedToMyServices ? options.services : undefined,
      workloads: dropdown.selected === BoardOption.AssignedToMyWorkloads ? options.workloads : undefined
    };

    return await EvidenceApi.getOriginalEvidencesByAssigneeAsync(filter.selected, state.sortBy, extraOptions);
  });

export const fetchOriginalEvidenceById = createAsyncThunk<OriginalEvidence | undefined, { auditEventGuid: string; evidenceGuid: string }>(
  'evidence/fetchOriginalEvidenceById',
  async ({ auditEventGuid, evidenceGuid }) => {
    return await EvidenceApi.getSingleOriginalEvidenceRequestAsync(auditEventGuid, evidenceGuid);
  }
);

/**
 * Slice
 */

const initialState: EvidenceSliceState = {
  evidences: undefined,
  loadingEvidencesStatus: UseAsyncHookStatus.Success
};

export const evidenceSlice = createSlice({
  name: 'evidence',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchAllEvidenceDetails.fulfilled, (state, action) => {
        state.evidences = action.payload;
        state.loadingEvidencesStatus = UseAsyncHookStatus.Success;
      })
      .addCase(fetchAllEvidenceDetails.pending, (state) => {
        state.evidences = undefined;
        state.loadingEvidencesStatus = UseAsyncHookStatus.Pending;
      })
      .addCase(fetchAllEvidenceDetails.rejected, (state) => {
        state.loadingEvidencesStatus = UseAsyncHookStatus.Fail;
      })
      .addCase(fetchOriginalEvidenceById.fulfilled, (state, action) => {
        const { auditEventGuid, evidenceGuid } = action.meta.arg;
        const evidence = action.payload;

        if (state.evidences && evidence) {
          const index = state.evidences.findIndex(e => e.AuditEventGuid === auditEventGuid && e.EvidenceGuid === evidenceGuid);

          if (!_.isNil(index)) {
            state.evidences[index] = evidence;
          } else {
            state.evidences.push(evidence);
          }
        }
      })
});

/**
 * Hooks
 */

export const useAllEvidenceDetails = (): OriginalEvidence[] | undefined =>
  useSelector<RootReduxState, OriginalEvidence[] | undefined>(
    (state) => state.taskBoard.evidence.evidences,
  );

export const useLoadingEvidencesStatus = (): UseAsyncHookStatus =>
  useSelector<RootReduxState, UseAsyncHookStatus>(state => state.taskBoard.evidence.loadingEvidencesStatus);
