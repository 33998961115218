
import React from 'react';

import {
  ITextFieldProps, Label, Shimmer, Stack, Text
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

type IProps = Pick<ITextFieldProps, 'label' | 'value'> & {
  isLoading?: boolean;
}

export const ReadOnlyTextField = (props: IProps) => {
  const value = props.isLoading ?
    <Shimmer/> :
    <Text>{ props.value ?? intl.get(LocIds.Label.Na) }</Text>;

  return (
    <Stack tokens={{
      childrenGap: 5
    }}>
      <Label>{ props.label }</Label>
      { value }
    </Stack>
  );
};
