import React, { FC } from 'react';


import {
  Controller,
  UseControllerProps,
  useFormContext
} from 'react-hook-form';


import { uploadToUserTempStorageAndGetDownloadUrlAsync } from '../../../services';
import { RichTextField } from '../../RichText/RichTextField';


interface IProps extends Pick<UseControllerProps, 'rules'> {
  name: string;
  required: boolean;
  label: string;
  readOnly?: boolean;
}

export const RichTextControl: FC<IProps> = (props: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { ref, ...fieldProps }, fieldState: { invalid, error } }) => (
        <RichTextField
          content={fieldProps.value}
          disableImageInsertion
          errorMsg={error?.message}
          isRequired={props.required}
          label={props.label}
          readonly={props.readOnly}
          onChange={(value: string) => {
            fieldProps.onChange(value);
          }}
          onImageUpload={uploadToUserTempStorageAndGetDownloadUrlAsync}
        />
      )}
      rules={props.rules}
    />
  );
};
