

import { DocTreeVisitor } from '../DocTreeVisitor/DocTreeVisitor';
import { DocumentMenuItem } from '../Document';

export class DocTreeNode {
  constructor(name: string, path: string, uid: string, order: number, children = []) {
    this.name = name;
    this.docPath = path;
    this.uid = uid;
    this.order = order;
    this._children = children;
    this._collapse = false;
  }
  public isFiltered = false;
  public get isVisible(): boolean {
    return this.isFiltered;
  }

  public name = '';

  public docPath = '';

  public uid = '';

  public order = 0;

  public firstChildDocPath: string | undefined = '';

  public get key(): string {
    return `${this.uid}`;
  }

  public get children(): DocTreeNode[] {
    return this._children;
  }

  public set children(children: DocTreeNode[]) {
    this._children = children;
  }

  public get collapsed(): boolean {
    return this._collapse;
  }

  public set collapsed(value: boolean) {
    this._collapse = value;
  }

  public addChildren(children: DocTreeNode[]): void {
    this._children.push(...children);
  }

  public accept(visitor: DocTreeVisitor): void {
    visitor.visitDocTreeNode(this);
  }

  public hasVisibleChildren(): boolean {
    return (
      this._children.length > 0 &&
      this._children.filter((n) => n.isFiltered).length > 0
    );
  }

  private _children: DocTreeNode[] = [];

  private _collapse: boolean;

  public findPathByUid(uid: string, res: DocumentMenuItem[]): boolean {
    const item = {
      DisplayName: this.name,
      Uid: this.uid,
      Path: this.docPath,
      Order: this.order
    };

    if (uid === this.uid) {
      res.push(item);
      return true;
    }

    return this.children.some(c => {
      if (c.findPathByUid(uid, res)) {
        res.push(item);
        return true;
      }

      return false;
    });
  }

  public findUidByPath(path: string): string | undefined {
    if (this.docPath === path) {
      return this.uid;
    }

    return this.children.map(c => c.findUidByPath(path)).filter(item => item)[0];
  }
}
