import React, {
  ReactNode, useCallback, useLayoutEffect, useState
} from 'react';

import './Tabs.less';
import {
  IPivotItemProps, IPivotStyles, IRenderFunction, Pivot, PivotItem
} from '@fluentui/react';
import { useNavigate } from 'react-router-dom';


export interface ITab {
  key?: string;
  label: string;
  component?: ReactNode;
  url?: string;
  itemCount?: number;
}

interface ITabProps {
  list: ITab[];
  styles?: Partial<IPivotStyles>;
  selectedKey?: string;
  isInNotificationPanel? : boolean;
}

export const Tabs: React.FC<ITabProps> = (
  props: ITabProps) => {
  const {
    list,
    styles,
    selectedKey
  } = props;

  const [pivotStyles, setPivotStyles] = useState<Partial<IPivotStyles>>({
    ...styles,
    itemContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 auto',
    },
    root: {
      position: 'sticky',
      zIndex: 2,
      backgroundColor: 'white'
    }
  });

  useLayoutEffect(() => {
    if (!props.isInNotificationPanel) {
      let top = 0;
      const elsAbove = ['h2[class*="tp-page__title"]', '.ms-FocusZone',];

      elsAbove.map(qs => document.querySelector(qs)).map(item => {
        if (item) {
          top += item.getBoundingClientRect().height;
        }
      });

      const newrootStyle = Object.assign({}, pivotStyles.root, {
        top: `${top}px`
      });
      const newPivotStyle = {
        ...pivotStyles,
        root: newrootStyle
      };
      setPivotStyles(newPivotStyle);
    }
  }, []);

  const showTabContent = list.every((tab: ITab) => tab.url === undefined);

  const navigate = useNavigate();

  const onRenderItemLink = useCallback<(tab: ITab) => IRenderFunction<IPivotItemProps>>((tab: ITab) => (props, defaultRender) => {
    if (!props || !defaultRender) {
      return null;
    }

    const onClick = (tab: ITab) => () => {
      if (tab.url) {
        navigate(tab.url);
      }
    };

    if (tab.url) {
      return <p className='tp-pivot__item-link' onClick={onClick(tab)}>{ props.headerText }</p>;
    }

    return defaultRender(props);
  }, [navigate]);

  const pivotItemsJSX = list.map((tab: ITab) => {
    const key = tab.key || tab.label;
    return (
      <PivotItem className='tp-pivot__item'
        headerText={tab.label}
        itemCount={tab.itemCount}
        itemKey={tab.key}
        key={key}
        onRenderItemLink={onRenderItemLink(tab)}>
        { showTabContent && tab.component }
      </PivotItem>
    );
  });

  return (
    <Pivot className='tp-pivot' defaultSelectedKey={selectedKey} styles={pivotStyles}>
      { pivotItemsJSX }
    </Pivot>
  );
};
