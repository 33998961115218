import React from 'react';

import {
  DialogFooter,
  IDialogFooterStyles, IDialogStyles,
  MessageBar,
  MessageBarType
} from '@fluentui/react';

import { Dialog } from '@fluentui/react/lib/Dialog';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

import { FCC } from '../../models';
import {
  M365PrimaryButton,
  M365SecondaryButton
} from '../M365Button';

interface IProps {
  /** Show dialog flag */
  hidden: boolean;
  /** Action for the primary action */
  onAction: () => void;
  /** Action to dismiss the dialog */
  onDismiss: () => void;
  /** Title of the dialog */
  title?: string;
  /** Statement that has to be confirmed */
  subText?: string;
  /** Text for the primary action  */
  textAction: string;
  /** Text for the dismiss action  */
  textDismiss?: string;
  /** Loading flag for the primary action */
  loading?: boolean;
  /** Error message */
  errorMessage?: string;
  /** Disable flag for the primary action */
  disabled?: boolean;
}

export const ConfirmDialog: FCC<IProps> = (props) => {
  const {
    onAction,
    onDismiss,
    title,
    subText,
    textAction,
    textDismiss = intl.get(LocIds.Action.Cancel),
    loading,
    hidden,
    errorMessage,
    disabled
  } = props;

  const dialogStyles: Partial<IDialogStyles> = {
    main: {
      minHeight: 'unset'
    }
  };

  const footerStyles: Partial<IDialogFooterStyles> = {
    actionsRight: {
      textAlign: 'left'
    }
  };

  return (
    <Dialog
      dialogContentProps={{
        title: title || intl.get(LocIds.Action.Confirm),
        subText
      }}
      hidden={hidden}
      minWidth={464}
      styles={dialogStyles}>

      { errorMessage && (
        <MessageBar
          aria-label={errorMessage}
          data-testid='confirm-dialog__message-bar'
          dismissButtonAriaLabel={intl.get(LocIds.Label.Close)}
          isMultiline={true}
          messageBarType={MessageBarType.error}>
          { errorMessage }
        </MessageBar>
      ) }

      { props.children }

      <DialogFooter styles={footerStyles}>
        <M365PrimaryButton disabled={disabled} loading={loading} text={textAction} onClick={onAction}/>
        <M365SecondaryButton text={textDismiss} onClick={onDismiss}/>
      </DialogFooter>
    </Dialog>
  );
};
