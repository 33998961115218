import _ from 'lodash';

import { CustomRoleDetails } from '../models/CustomRole';
import {
  Nullable, ValueType
} from '../models/ValueType';


export type CustomRoleDiffResult = {
  setName?: ValueType<string>;
  addObjectIds: Nullable<string[]>;
  removeObjectIds: Nullable<string[]>;
};

export const diffCustomRole = (
  original: CustomRoleDetails,
  modified: CustomRoleDetails,
): CustomRoleDiffResult => {
  const diffResult: CustomRoleDiffResult = {
    addObjectIds: null,
    removeObjectIds: null,
  };

  if (original.Name !== modified.Name) {
    diffResult.setName = {
      Value: modified.Name,
    };
  }

  const addedMappings = _.difference(modified.ObjectIds, original.ObjectIds);
  const removedMappings = _.difference(original.ObjectIds, modified.ObjectIds);

  if (addedMappings.length > 0) {
    diffResult.addObjectIds = addedMappings;
  }

  if (removedMappings.length > 0) {
    diffResult.removeObjectIds = removedMappings;
  }

  return diffResult;
};
