import React from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';

import { CommonFilterProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { CertificationControl } from '../../models';
import { MultiSelectDropdown } from '../Dropdown';
import { FilterFieldWrapper } from '../FilterFieldWrapper';

export const CertificationControlFilter: React.FC<
  CommonFilterProps<CertificationControl[], CertificationControl[]>
> = (props) => {
  const { selected, allOptions, onChange, disabled } = props;

  return (
    <FilterFieldWrapper
      ariaLabel={intl.get(LocIds.Label.CertificationControl)}
      isClearActive={!_.isEmpty(selected)}
      onClear={() => onChange([])}>
      <MultiSelectDropdown<CertificationControl>
        disabled={disabled}
        items={allOptions ?? []}
        keyOf={(item) => String(item.CertificationControlId)}
        label={intl.get(LocIds.Label.CertificationControl)}
        optionOf={(item) => ({
          key: String(item.CertificationControlId),
          text: item.Code,
        })}
        placeholder={intl.get(LocIds.Placeholder.SelectToFilter)}
        searchable
        selectedItems={selected}
        onChange={onChange}
      />
    </FilterFieldWrapper>
  );
};
