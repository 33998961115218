import _ from 'lodash';
import intl from 'react-intl-universal';

import {
  DEFAULT_PAGE_NEIGHBOURS,
  DEFAULT_RECORD_COUNT_PER_PAGE,
  LEFT_PAGE,
  RIGHT_PAGE,
} from './constant';

import { PaginationProps } from './type';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { isDevelopment } from '../../utils';

export const getRecordCountPerPage = (recordCountPerPage?: number): number => {
  if (typeof recordCountPerPage === 'undefined') {
    return DEFAULT_RECORD_COUNT_PER_PAGE;
  }

  if (recordCountPerPage <= 0) {
    if (isDevelopment) {
      throw new Error(intl.get(LocIds.Error.PositiveNumber));
    }

    return DEFAULT_RECORD_COUNT_PER_PAGE;
  }

  return recordCountPerPage;
};

export const getPageNeighbours = (pageNeighbours?: number): number => {
  return pageNeighbours || DEFAULT_PAGE_NEIGHBOURS;
};

export const getTotalPages = (
  totalRecordCount: number,
  recordCountPerPage: number | undefined,
): number => {
  return Math.ceil(
    totalRecordCount / getRecordCountPerPage(recordCountPerPage),
  );
};

export const getPage = (
  totalRecordCount: number,
  recordCountPerPage: number | undefined,
  page: number | undefined,
): number => {
  if (!page) {
    return 1;
  }

  const totalPages = getTotalPages(totalRecordCount, recordCountPerPage);
  return Math.max(1, Math.min(page, totalPages));
};

/**
 * Let's say we have 10 pages and we set pageNeighbours to 2
 * Given that the current page is 6
 * The pagination control will look like the following:
 *
 * (1) < {4 5} [6] {7 8} > (10)
 *
 * (x) => terminal pages: first and last page(always visible)
 * [x] => represents current page
 * {...x} => represents page neighbours
 */
export const getPages = (
  params: Omit<PaginationProps, 'onPageChange'> & { currentPage: number },
): Array<string | number> => {
  const { totalRecordCount, recordCountPerPage, currentPage } = params;
  const totalPages = getTotalPages(totalRecordCount, recordCountPerPage);
  const pageNeighbours = getPageNeighbours(params.pageNeighbours);

  /**
   * totalNumbers: pageNeighbours on the left and right side + current page + first and last page
   * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
   */
  const totalNumbers = pageNeighbours * 2 + 3;
  const totalBlocks = totalNumbers + 2;

  if (totalPages > totalBlocks) {
    const startPage = Math.max(2, currentPage - pageNeighbours);
    const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
    let pages: Array<string | number> = _.range(startPage, endPage + 1);

    /**
     * hasLeftSpill: has hidden pages to the left
     * hasRightSpill: has hidden pages to the right
     * spillOffset: number of hidden pages either to the left or to the right
     */
    const hasLeftSpill = startPage > 2;
    const hasRightSpill = endPage < totalPages - 1;
    const spillOffset = totalNumbers - pages.length - 1;

    switch (true) {
    // handle: (1) < {5 6} [7] {8 9} (10)
    case hasLeftSpill && !hasRightSpill: {
      const extraPages = _.range(startPage - spillOffset, startPage);
      pages = [LEFT_PAGE, ...extraPages, ...pages];
      break;
    }

    // handle: (1) {2 3} [4] {5 6} > (10)
    case !hasLeftSpill && hasRightSpill: {
      const extraPages = _.range(endPage + 1, endPage + spillOffset + 1);
      pages = [...pages, ...extraPages, RIGHT_PAGE];
      break;
    }

    // handle: (1) < {4 5} [6] {7 8} > (10)
    case hasLeftSpill && hasRightSpill:
    default: {
      pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
      break;
    }
    }

    return [1, ...pages, totalPages];
  }

  return _.range(1, totalPages + 1);
};
