import React, { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import {
  Application,
  ServiceWorkspace
} from '../../TrustPortal/registerApplicationMenu';
import { ScheduleBoardPage } from '../pages/ServiceBoard/containers/ScheduleBoard';
import { EditEvidencePanel } from '../pages/TaskBoard/containers/EditEvidencePanel';

const Home = lazy(() => import('../pages/Home'));

const TaskBoard = lazy(() => import('../pages/TaskBoard'));
const ServiceBoard = lazy(() => import('../pages/ServiceBoard'));
const ConMon = lazy(() => import('../pages/ConMon'));
const OnboardingDependency = lazy(() => import('../pages/OnboardingDependency'));
const SubprocessorForm = lazy(() => import('../pages/Subprocessor'));
const Reports = lazy(() => import('../pages/Reports'));

export const routes: RouteObject[] = [
  {
    path: `${Application.ServiceWorkspace}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${ServiceWorkspace.TaskBoard}/*`,
        element: <TaskBoard />,
        children: [
          {
            path: 'task/:taskId',
            element: <EditEvidencePanel />,
          }
        ]
      },
      {
        path: `menu/${ServiceWorkspace.ServiceBoard}/*`,
        element: <ServiceBoard />,
        children: [
          {
            path: 'service/:serviceId',
            element: <ScheduleBoardPage />
          }
        ]
      },
      {
        path: `menu/${ServiceWorkspace.ConMon}/*`,
        element: <ConMon />
      },
      {
        path: `menu/${ServiceWorkspace.OnboardingDependency}/*`,
        element: <OnboardingDependency />
      },
      {
        path: `menu/${ServiceWorkspace.Subprocessor}/*`,
        element: <SubprocessorForm />
      },
      {
        path: `menu/${ServiceWorkspace.Reports}/*`,
        element: <Reports />
      },
    ],
  },
];
