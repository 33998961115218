export interface EvidenceRequestCreationStatus {
  ProcessState: EvidenceCreationProcessState;
  Environments: CreationStatusByEnvironment[];
}

export interface CreationStatusByEnvironment {
  EnvironmentId: number;
  Services: CreationStatusByService[];
}

interface CreationStatusByService {
  ServiceId: number;
  // One or many depends on whether requests created per certification control.
  EvidenceRequests: EvidenceRequestCreationDetails[];
}

export interface EvidenceRequestCreationDetails {
  EvidenceRequestGuid: string;
  CertificationControlCodes: string[];
  AssignedTo: string;
  WorkItemId: number | null;
  WorkItemUrl: string | null;
  ErrorMessage: string | null;
}

export enum EvidenceCreationProcessState {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export interface GetEvidenceCreationStatusRequestPayload {
  processGuid: string;
}
