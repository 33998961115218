export interface EvidenceState {
  EvidenceStateId: number;
  ShortName: string;
}

// There are 6 EvidenceRequestTypes defined in backend. In UI, only 4 of them are valid for use.
// In Task scenario (e.g. action rendering), we hardcoded these states.
export enum EvidenceStateEnum {
  Requested = 'Requested',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Closed = 'Closed',
}
