import React from 'react';

import { ConclusionStateEnum } from '../../models';
import {
  IndicatorLabel, LabelColor
} from '../StyledLabel';

type IProps = {
    conclusionState: ConclusionStateEnum;
}

export const PublishedEvidenceStatusLabel:React.FC<IProps> = (props) => {
  const stateType = {
    [ConclusionStateEnum.InReview]: LabelColor.CyanBlue,
    [ConclusionStateEnum.Accepted]: LabelColor.GreenCyan,
    [ConclusionStateEnum.Rejected]: LabelColor.Red,
  };
  return (
    <IndicatorLabel
      color={stateType[props.conclusionState]}
      text={props.conclusionState}
    />
  );
};
