import React from 'react';

import {
  Stack,
  Text
} from '@fluentui/react';
import _ from 'lodash';

interface IProps
{
    title?: string;
    titleLabel?: JSX.Element;
    horizontal?: boolean;
}

export const CardTitle: React.FC<IProps> = (props) => {
  const { titleLabel, title, horizontal = false } = props;

  if (_.isEmpty(titleLabel) && _.isEmpty(title)) {
    return null;
  }

  return (
    <Stack horizontal={horizontal}
      tokens={{
        childrenGap: 6
      }}
      verticalAlign={horizontal ? 'center' : undefined}>
      { titleLabel && (
        <div className='cardView__titleLabelWrapper'>{ titleLabel }</div>
      ) }
      { title && (
        <Text className='cardView__title' title={title}>
          { title }
        </Text>
      ) }
    </Stack>
  );
};
