import { createSlice } from '@reduxjs/toolkit';

import { getGlobalCoachmarkSetting } from './thunk';

import { applicationInsightsService } from '../../services';
export interface ISettingsPanelSlice {
  openPanel: boolean;
  activateCoachmarks: boolean;
}
export const initialState: ISettingsPanelSlice = {
  openPanel: false,
  activateCoachmarks: false,
};
export const settingsPanelSlice = createSlice({
  name: 'settingsPanel',
  initialState,
  reducers: {
    openSettingsPanel: (state: ISettingsPanelSlice) => {
      state.openPanel = true;
    },
    closeSettingsPanel: (state: ISettingsPanelSlice) => {
      state.openPanel = false;
    },
    setPortalCoachmarksSetting: (state: ISettingsPanelSlice, { payload }: { payload: boolean }) => {
      state.activateCoachmarks = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGlobalCoachmarkSetting.fulfilled, (state, action) => {
      state.activateCoachmarks = action.payload[0].SettingValue === 'true';
    });
    builder.addCase(getGlobalCoachmarkSetting.rejected, (state, action) => {
      applicationInsightsService.trackException({
        message: action.error.message!,
        name: action.error.name!
      });
    });
  },
});
export const {
  openSettingsPanel,
  closeSettingsPanel,
  setPortalCoachmarksSetting
} = settingsPanelSlice.actions;
