import React from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';

import { CommonFilterProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { ConclusionStateEnum } from '../../models';
import { MultiSelectDropdown } from '../Dropdown';
import { FilterFieldWrapper } from '../FilterFieldWrapper';

export const AuditorsDecisionFilter: React.FC<
  CommonFilterProps<ConclusionStateEnum[], ConclusionStateEnum[]>
> = (props) => {
  const { allOptions, selected, onChange } = props;

  return (
    <FilterFieldWrapper
      ariaLabel={intl.get(LocIds.AuditManager.AuditorsDecision)}
      isClearActive={!_.isEmpty(selected)}
      onClear={() => onChange([])}>
      <MultiSelectDropdown<ConclusionStateEnum>
        items={allOptions ?? []}
        keyOf={(item) => String(item)}
        label={intl.get(LocIds.AuditManager.AuditorsDecision)}
        optionOf={(item) => ({
          key: String(item),
          text: getAuditOptionText(item),
        })}
        placeholder={intl.get(LocIds.Placeholder.SelectToFilter)}
        selectedItems={selected}
        onChange={onChange}
      />
    </FilterFieldWrapper>
  );
};

export const getAuditOptionText = (option: ConclusionStateEnum): string => {
  switch (option) {
  case ConclusionStateEnum.Accepted:
    return intl.get(LocIds.Label.Accepted);
  case ConclusionStateEnum.Rejected:
    return intl.get(LocIds.Label.Rejected);
  case ConclusionStateEnum.InReview:
    return intl.get(LocIds.Label.InReview);
  default:
    return intl.get(LocIds.Label.InReview);
  }
};
