import React from 'react';

import _ from 'lodash';

import { EvidenceNavProps } from './types';

import {
  EvidencePath,
  EvidenceTreeInternalNode,
  EvidenceTreeNodeBase,
  EvidenceTreeRootNode
} from '../../models';
import { getEvidencePathFromEvidenceTreeNode } from '../../utils';
import {
  SideNav,
  SideNavLink
} from '../SideNav';

export const EvidenceNav = React.memo((props: EvidenceNavProps) => {
  const { evidenceTree, searchQuery, selectedEvidencePaths } = props;

  const onLinkClick = (
    link: SideNavLink<EvidenceTreeNodeBase>,
    isCtrlHeld?: boolean,
  ) => {
    props.onToggleSelectedEvidencePath(
      getEvidencePathFromEvidenceTreeNode(link as EvidenceTreeNodeBase),
      isCtrlHeld ?? true,
    );
  };

  const isLinkActive = (link: SideNavLink<EvidenceTreeNodeBase>) => {
    const evidencePath = getEvidencePathFromEvidenceTreeNode(
            link as EvidenceTreeNodeBase,
    );
    return (
      _.findIndex(selectedEvidencePaths, (path: EvidencePath) =>
        _.isEqual(path, evidencePath),
      ) > -1
    );
  };

  return (
    <SideNav<EvidenceTreeNodeBase>
      defaultCollapseLinksMap={constructDefaultCollapseLinksMap(evidenceTree)}
      isLinkActive={isLinkActive}
      links={evidenceTree.children}
      searchQuery={searchQuery}
      onLinkClick={onLinkClick}
    />
  );
});

export const constructDefaultCollapseLinksMap = (evidenceTree: EvidenceTreeRootNode) => {
  const defaultMap: Record<string, boolean> = {};

  evidenceTree.children.forEach(node => {
    if (node instanceof EvidenceTreeInternalNode) {
      node.children.forEach(child => {
        const identifier = child.pathIdentifier;

        if (identifier) {
          defaultMap[identifier] = true;
        }
      });
    }
  });

  return defaultMap;
};
