import './style.less';

import React from 'react';

import {
  Icon,
  Stack
} from '@fluentui/react';
import classNames from 'classnames';

import { LabelColor } from './type';

export interface IconLabelProps {
  text: string;
  color: LabelColor,
  iconName: string,
  bold?: boolean;
}

export const IconLabel: React.FC<IconLabelProps> = ({ text, color, iconName, bold = false }) => {
  const iconClassname = classNames('icon-label__indicator', `icon-label__indicator_${color}`);
  const textClassname = classNames('icon-label__text', {
    'icon-label__text_bold': bold
  });
  return (
    <Stack className='icon-label' horizontal verticalAlign='center'>
      <Icon
        className={iconClassname}
        iconName= {iconName}
      />
      <span className={textClassname}>{ text }</span>
    </Stack>
  );
};
