import React, {
  useEffect, useState
} from 'react';

import { Spinner } from '@fluentui/react';
import _ from 'lodash';
import intl from 'react-intl-universal';
import {
  useNavigate, useParams
} from 'react-router-dom';

import { MAX_RETRY_TO_GET_LATEST_DISCUSSIONS } from './constant';
import { DiscussionPanel } from './DiscussionPanel';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { PublishedEvidence } from '../../models';
import { EvidenceApi } from '../../services';
import { useUserCommentRole } from '../../utils/react-hooks/useUserCommentRole';
import {
  EvidenceCard, SharedPublishedEvidenceCard
} from '../EvidenceCard';

export enum DiscussionMode {
  AuditReview,
  AuditManager,
}

type IProps = {
  mode: DiscussionMode;
}

export const EvidenceDiscussionPanel: React.FC<IProps> = (props) => {
  const { mode } = props;

  const navigate = useNavigate();
  const { auditEventGuid, evidenceGuid } = useParams();

  const role = useUserCommentRole();

  const [evidence, setEvidence] = useState<PublishedEvidence>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchLatestDataWithRetry = async (
    auditEventGuid: string, evidenceGuid: string, maxRetryToEnsureLatest = 1) : Promise<PublishedEvidence> => {
    const response = await EvidenceApi.getSinglePublishedEvidenceRequestAsync(auditEventGuid, evidenceGuid);

    // Either the PublishedEvidenceRequest is new or discussion thread already updated.
    if (!evidence ||
        (response?.ETag !== evidence?.ETag &&
        JSON.stringify(response?.DiscussionThreads) !== JSON.stringify(evidence.DiscussionThreads))) {
      return response;
    }

    // Or we don't want to retry anymore;
    if (maxRetryToEnsureLatest <= 1) {
      return response;
    }

    return await fetchLatestDataWithRetry(auditEventGuid, evidenceGuid, --maxRetryToEnsureLatest);
  };

  const fetchData = (maxRetryToEnsureLatest = 1) => {
    if (auditEventGuid && evidenceGuid) {
      setIsLoading(true);
      fetchLatestDataWithRetry(auditEventGuid, evidenceGuid, maxRetryToEnsureLatest)
        .then(response => {
          setEvidence(response);
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [auditEventGuid, evidenceGuid]);

  const onDismiss = () => {
    navigate('..');
  };

  const onRefresh = () => {
    fetchData(MAX_RETRY_TO_GET_LATEST_DISCUSSIONS);
  };

  const onSendComment = (content: string) => {
    if (auditEventGuid && evidenceGuid && !_.isNil(role)) {
      return EvidenceApi.createPublishedEvidenceComment({
        auditEventGuid,
        content,
        evidenceRequestGuid: evidenceGuid,
        createdByRole: role
      });
    }

    setIsError(true);
  };

  let cardView = <Spinner className='rootSpinner' />;

  if (evidence) {
    if (mode === DiscussionMode.AuditManager) {
      cardView = <SharedPublishedEvidenceCard evidence={evidence}/>;
    } else if (mode === DiscussionMode.AuditReview) {
      cardView = <EvidenceCard evidence={evidence} />;
    }
  }

  // Discussions support single thread only for now.
  const threads = evidence?.DiscussionThreads ?? [];
  const comments = _.flatten(threads.map(t => t.Comments));

  return (
    <DiscussionPanel
      comments={comments.map(c => ({
        primaryText: c.CreatedByDisplayName,
        secondaryText: c.CreatedByRole,
        content: c.Content,
        createdAt: new Date(c.CreatedDate),
      }))}
      isError={isError}
      isLoading={isLoading}
      isOpen={true}
      title={evidence?.Title ?? intl.get(LocIds.Label.EvidenceRequest)}
      onDismiss={onDismiss}
      onRefresh={onRefresh}
      onSendComment={onSendComment}>
      { cardView }
    </DiscussionPanel>
  );
};
