import './style.less';

import React from 'react';

import {
  IDropdownOption,
  IRenderFunction
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { InlineLabel } from './InlineLabel';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  MultiSelectDropdown,
  MultiSelectDropdownProps
} from '../Dropdown';
import {
  IClientCoachmarksProps, TeachingBubbleCoachmark
} from '../TeachingBubbleCoachmark';

type InlineMultiFilterProps<T> = Pick<MultiSelectDropdownProps<T>,
    | 'items'
    | 'selectedItems'
    | 'optionOf'
    | 'keyOf'
    | 'label'
    | 'onChange'
    | 'searchable'
    | 'searchRequired'
    | 'styles'
    | 'onRenderOption'
    | 'calloutClassname'
    | 'calloutProps'> & {
    maxCount?: number;
    selectAll?: boolean;
    coachmark?: IClientCoachmarksProps;
};

export function InlineMultiFilter<T>(
  props: InlineMultiFilterProps<T>,
): ReturnType<React.FC<InlineMultiFilterProps<T>>> {
  const { selectedItems, maxCount, optionOf, selectAll = true, coachmark } = props;
  const targetDiv = React.useRef<HTMLDivElement>(null);

  const onRenderTitle: IRenderFunction<IDropdownOption[]> = (options, defaultRender) => {
    let titleJsx;

    if (maxCount) {
      const items = selectedItems ?? [];
      titleJsx = (maxCount ? items.slice(0, maxCount) : items).map(i => optionOf(i).text).join(', ');

      if (items.length > maxCount) {
        titleJsx += ` +${items.length - maxCount}`;
      }
    } else {
      titleJsx = defaultRender ? defaultRender(options) : undefined;
    }

    return (
      <InlineLabel label={props.label}>
        { titleJsx }
      </InlineLabel>
    );
  };

  const onRenderPlaceholder = () => (
    <InlineLabel label={props.label}>
      { intl.get(LocIds.Label.None) }
    </InlineLabel>
  );

  return (
    <div>
      <div ref={targetDiv}>
        <MultiSelectDropdown<T>
          calloutClassname={props.calloutClassname}
          calloutProps={props.calloutProps}
          items={props.items}
          keyOf={props.keyOf}
          optionOf={props.optionOf}
          searchable={props.searchable}
          searchRequired={props.searchRequired}
          selectAllProps={selectAll ? {
            maxNumOfSelectableItems: props.items.length,
            numOfCurrentlySelected: props.selectedItems?.length ?? 0
          } : undefined}
          selectedItems={props.selectedItems}
          styles={props.styles}
          onChange={props.onChange}
          onRenderOption={props.onRenderOption}
          onRenderPlaceholder={onRenderPlaceholder}
          onRenderTitle={onRenderTitle}
        />
      </div>
      { coachmark && (
        <div>
          <TeachingBubbleCoachmark
            description={coachmark.description}
            identifier={coachmark.identifier}
            target={targetDiv.current}
          />
        </div>
      ) }
    </div>
  );
}
