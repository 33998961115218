/* istanbul ignore file */

interface IBreakpoints {
  s: number;
  m: number;
  l: number;
  xl: number;
  xxl: number;
  xxxl: number;
}

export const breakpoints: IBreakpoints = {
  s: 320,
  m: 480,
  l: 768,
  xl: 1024,
  xxl: 1366,
  xxxl: 1920,
};
