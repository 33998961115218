import './style.less';
import React from 'react';

import { Stack } from '@fluentui/react';
import _ from 'lodash';
import intl from 'react-intl-universal';
import { IEditor } from 'roosterjs';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { uploadToUserTempStorageAndGetDownloadUrlAsync } from '../../services';
import { M365PrimaryButton } from '../M365Button';
import { RichTextEditor } from '../RichText/RichTextEditor';

type IProps = {
  content: string;
  onChangeContent: (content: string) => void;
  isLoading?: boolean;
  onSend: () => void;
  setEditor?: (value: IEditor) => void;
}

export const CommentBox: React.FC<IProps> = (props) => {
  const { content, onChangeContent, onSend, isLoading = false, setEditor } = props;

  return (
    <Stack className={'comment-box'} >
      <RichTextEditor
        content={content}
        disableImageInsertion={true}
        setEditor={setEditor}
        onChange={onChangeContent}
        onImageUpload={uploadToUserTempStorageAndGetDownloadUrlAsync}
      />
      <Stack horizontalAlign={'end'}>
        <M365PrimaryButton
          disabled={_.isEmpty(content) || isLoading}
          loading={isLoading}
          text={intl.get(LocIds.Action.Comment)}
          onClick={onSend}
        />
      </Stack>
    </Stack>
  );
};
