import './style.less';

import React, { useCallback } from 'react';

import {
  PanelType, Separator, Stack, Text
} from '@fluentui/react';
import _ from 'lodash';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { AuditEvent } from '../../models';
import { AuditEventApi } from '../../services';
import {
  useAsync, UseAsyncHookStatus
} from '../../utils';
import { AuditEventAttachmentList } from '../AttachmentGroup';
import { MetaTree } from '../MetaTree';
import { ViewLayout } from '../TrustLayout';
import { TrustPanel } from '../TrustPanel';

export interface AuditEventDetailPanelProps {
  isOpen: boolean;
  auditEventGuid: string;
  onDismiss: () => void;
  getAuditEventDetailsApi?: (auditEventGuid: string) => Promise<AuditEvent>
}

export const AuditEventDetailPanel: React.FC<AuditEventDetailPanelProps> = (
  props,
) => {
  const { auditEventGuid } = props;

  const asyncFn = useCallback(async (): Promise<AuditEvent> => {
    if (_.isFunction(props.getAuditEventDetailsApi)) {
      return await props.getAuditEventDetailsApi(auditEventGuid);
    }

    return await AuditEventApi.getAuditEventByGuidAsync(auditEventGuid);

  }, [auditEventGuid]);

  const { data: auditEvent, status } = useAsync<AuditEvent | undefined>(asyncFn, undefined);

  const dateRangeText =
    auditEvent &&
    `${new Date(auditEvent.StartDate).toLocaleDateString()} - ${new Date(
      auditEvent.EndDate,
    ).toLocaleDateString()}`;

  const bodyJsx = auditEvent && (
    <Stack className='auditEventDetailPanel'
      tokens={{
        childrenGap: 12
      }}>
      <Text className='auditEventDetailPanel-title'>{ auditEvent.Name }</Text>
      <Text>{ dateRangeText }</Text>
      <Text>{ auditEvent.Description }</Text>
      <Stack
        className='auditEventDetailPanel-body'
        horizontal
        tokens={{
          childrenGap: 20
        }}>
        { onRenderAttachments(auditEvent) }
        <Separator vertical />
        { onRenderCertifications(auditEvent) }
      </Stack>
    </Stack>
  );

  return (
    <TrustPanel
      headerText={intl.get(LocIds.Components.AuditEventDetail)}
      isOpen={props.isOpen}
      type={PanelType.large}
      onDismiss={props.onDismiss}>
      <ViewLayout
        isError={status === UseAsyncHookStatus.Fail}
        isLoaded={!_.isNil(auditEvent) && status === UseAsyncHookStatus.Success}>
        { bodyJsx }
      </ViewLayout>
    </TrustPanel>
  );
};

const onRenderAttachments = (auditEvent: AuditEvent) => {
  if (!_.isArray(auditEvent?.Attachments)) {
    return null;
  }

  return (
    <Stack.Item grow={3}>
      <AuditEventAttachmentList Attachments={auditEvent?.Attachments} />
    </Stack.Item>
  );
};

const onRenderCertifications = (auditEvent: AuditEvent) => {
  if (!_.isArray(auditEvent?.Certifications)) {
    return null;
  }

  return (
    <Stack.Item
      className='auditEventDetailPanel-body-metaTree'
      grow={1}>
      <MetaTree
        groups={auditEvent.Certifications.map((cert) => ({
          text: cert.Name,
          children: cert.Environments.map((env) => env.Name),
        }))}
      />
    </Stack.Item>
  );
};
