import { schema } from 'normalizr';

import { getEvidenceId } from '../../utils/evidenceId';
import { Evidence } from '../Evidence';

/**
 * When given a list of Evidence, we want to do three things:
 * 1. Group Evidence by AuditEventGuid
 * 2. Give each evidence a unique identify, which is `${Evidence.AuditEventGuid}:${Evidence.EvidenceGuid}`
 * 3. Normalize data, which means we don't keep nested data structure in redux store, instead we flatten data  @see https://redux-toolkit.js.org/usage/usage-guide#normalizing-with-normalizr
 * For example:
 * Input: [{
 *   EvidenceGuid: "Evidence1",
 *   AuditEventGuid: "AuditEvent1",
 *   Title: "This is Evidence1"
 * }, {
 *   EvidenceGuid: "Evidence2",
 *   AuditEventGuid: "AuditEvent1",
 *   Title: "This is Evidence2"
 * }]
 *
 * Output: {
 *   evidences: {
 *     ids: ["AuditEvent1:Evidence1", "AuditEvent1:Evidence2"],
 *     entities: {
 *       "AuditEvent1:Evidence1": {
 *         EvidenceGuid: "Evidence1",
 *         AuditEventGuid: "AuditEvent1",
 *         Title: "This is Evidence1"
 *       },
 *       "AuditEvent1:Evidence2": {
 *         EvidenceGuid: "Evidence2",
 *         AuditEventGuid: "AuditEvent1",
 *         Title: "This is Evidence2"
 *       }
 *     }
 *   }
 * }
 */
export const evidenceEntity = new schema.Entity(
  'evidences',
  {},
  {
    idAttribute: (value: Evidence) => {
      return getEvidenceId(value);
    },
    processStrategy: (value: Evidence) => {
      return {
        ...value,
        id: getEvidenceId(value),
      };
    },
  },
);
