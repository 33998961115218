import React, {
  useEffect,
  useState
} from 'react';

import { Spinner } from '@fluentui/react';
import {
  Route,
  Routes,
  Navigate
} from 'react-router-dom';


import {
  PowerBiReportMetadata,
  ReportScope
} from '../../../models';
import { PowerBiApi } from '../../../services';
import { showErrorMsg } from '../../../utils';
import { DashboardView } from '../DashboardView';

interface IProps {
  scopes: ReportScope[];
  applicationId: string;
  menuId: string;
  showSearchBox?: boolean;
  groupByScope?: boolean;
  filterSideNav?: boolean;
}

export const DashboardViewWrapper: React.FC<IProps> = ({
  scopes,
  applicationId,
  menuId,
  showSearchBox = false,
  groupByScope = false,
  filterSideNav = false
}: IProps) => {
  const [reportData, setReportData] = useState<PowerBiReportMetadata[]>([]);
  const [reportDataLoaded, setReportDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        setReportDataLoaded(false);
        const result = await PowerBiApi.getPowerBiReportsMetadataAsync(scopes);
        setReportData(result.sort((a, b) => a.Title.localeCompare(b.Title)));
      } catch (e) {
        showErrorMsg(e);
      } finally {
        setReportDataLoaded(true);
      }
    };

    fetchReports();
  }, [scopes]);

  if (!reportDataLoaded) {
    return <Spinner className='rootSpinner'/>;
  }

  return (
    <Routes>
      <Route element={<Navigate to='report'/>} path=''/>
      <Route
        element={(
          <DashboardView
            applicationId={applicationId}
            filterSideNav={filterSideNav}
            groupByScope={groupByScope}
            menuId={menuId}
            reports={reportData}
            showSearchBox={showSearchBox}
          />
        )}
        path='report'
      />
      <Route
        element={(
          <DashboardView
            applicationId={applicationId}
            filterSideNav={filterSideNav}
            groupByScope={groupByScope}
            menuId={menuId}
            reports={reportData}
            showSearchBox={showSearchBox}
          />
        )}
        path='report/:reportPath'
      />
    </Routes>
  );
};
