import React from 'react';

import {
  IStackItemStyles,
  Stack
} from '@fluentui/react';
import intl from 'react-intl-universal';


import { LocIds } from '../../common/Globalization/IntlEnum';
import { FCC } from '../../models';
import { TooltipIconButton } from '../TooltipIconButton';

const mainStackItemStyles: IStackItemStyles = {
  root: {
    overflow: 'hidden'
  },
};

const clearButtonStackItemStyles: IStackItemStyles = {
  root: {
    marginTop: '28.5px'
  }
};

interface FilterFieldWrapperProps {
  isClearActive?: boolean;
  onClear?: () => void;
  ariaLabel?: string;
}

export const FilterFieldWrapper: FCC<FilterFieldWrapperProps> = (props) => {
  const { onClear, isClearActive = false, ariaLabel } = props;

  return (
    <Stack horizontal verticalAlign='center'>
      <Stack.Item grow styles={mainStackItemStyles}>
        { props.children }
      </Stack.Item>
      { isClearActive && onClear && (
        <Stack.Item
          className='filterFieldWrapper-iconContainer'
          styles={clearButtonStackItemStyles}>
          <TooltipIconButton
            ariaLabel={ariaLabel ?
              intl.get(LocIds.Components.RemoveFilter, {
                label: `${ariaLabel}`,
              }) :
              undefined}
            iconProps={{
              iconName: 'Cancel'
            }}
            onClick={onClear}
          />
        </Stack.Item>
      ) }
    </Stack>
  );
};
