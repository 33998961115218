import {
  AccountInfo,
  AuthenticationResult,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

import { applicationInsightsService } from './TelemetryService';

import { IStaticPortalConfiguration } from '../models';

export interface IAcquireTokenRequest {
  scopes: string[];
}

/**
 * If user try to access URL with params(e.g. https://{hostname}/application/{id}/menu/{id}) before login, by default
 * MSAL will redirect the user to the same URL after login, however this would be different from what's registered in Azure Portal
 * So use a variable to store the hostname and used as redirectUri
 */
const domain = window.location.origin;

/**
 * Trust Portal uses MSAL.js 2.x, @see https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications
 *
 */
export namespace OAuthService {
  /**
   * Instance of MSAL PublicClientApplication object.
   */
  let app: PublicClientApplication;

  export function configureService(
    configuration: IStaticPortalConfiguration,
    _applicationInsightsService: typeof applicationInsightsService,
  ): void {
    const config = {
      cache: {
        // TODO: check browser compatibility for IE and Edge
        cacheLocation: 'sessionStorage',
      },
      auth: {
        clientId: configuration.azureAdClientId,
        authority: configuration.azureAdAuthority,
        redirectUri: domain,
      },
      system: {
        loggerOptions: {
          loggerCallback(
            level: LogLevel,
            message: string,
            containsPii: boolean,
          ) {
            if (containsPii) {
              return;
            }

            switch (level) {
            case LogLevel.Error:
              _applicationInsightsService.trackException(new Error(message));
              return;
            case LogLevel.Warning:
              _applicationInsightsService.trackTrace(message);
              break;
            default:
            }
          },
        },
      },
    };
    app = new PublicClientApplication(config);
  }

  /**
   * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-browser-samples/VanillaJSTestApp2.0/app/onPageLoad/auth.js
   */
  export async function signIn(): Promise<void> {
    // TODO: There are cases where MSAL fails to get cached user account from localStorage
    try {
      // Resume already started authentication process and return when the request is finished.
      const tokenResponse = await app.handleRedirectPromise();

      if (tokenResponse !== null) {
        return;
      }

      // If AuthenticationResult of redirect is null, try to get account from cache, local storage or cookie
      const currentAccounts = app.getAllAccounts();

      if (currentAccounts && currentAccounts.length > 0) {
        return;
      }
    } catch (e) {
      applicationInsightsService.trackException(e as Error);
    }

    // If no accounts detected, try to login user.
    // Default scopes are "openid" and "profile"
    await app.loginRedirect();
  }

  export async function signOut(): Promise<void> {
    await app.logout();
  }

  export async function acquireTokenSilent(
    request: IAcquireTokenRequest,
  ): Promise<AuthenticationResult> {
    const accounts = getAccounts();

    if (!accounts || !accounts.length) {
      throw new Error('No accounts detected');
    }

    if (accounts.length > 1) {
      throw new Error(
        'Multiple accounts detected, need to add choose account code.',
      );
    }

    const tokenRequest = {
      ...request,
      account: accounts[0],
    };
    try {
      return await app.acquireTokenSilent(tokenRequest);
    } catch (e) {
      await renewTokenRedirect(tokenRequest);
      return await app.acquireTokenSilent(tokenRequest);
    }
  }

  export function getAccounts(): AccountInfo[] {
    const accounts = app.getAllAccounts();
    return accounts || [];
  }

  /**
   * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2104#issuecomment-671512527
   */
  // eslint-disable-next-line no-inner-declarations
  async function renewTokenRedirect(request: IAcquireTokenRequest) {
    const tokenResponse = await app.handleRedirectPromise();

    if (tokenResponse !== null) {
      return;
    }

    await app.acquireTokenRedirect(request);
  }
}
