import './style.less';

import React, {
  useEffect, useState
} from 'react';

import {
  ChoiceGroup, IChoiceGroupOption, Stack
} from '@fluentui/react';
import classNames from 'classnames';
import _ from 'lodash';
import intl from 'react-intl-universal';


import { ServiceConfigBody } from './ServiceConfigBody';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  AuditEventService, ComplianceAuditWorkload, Service
} from '../../models';
import { EntityApi } from '../../services';
import { TrustPanel } from '../TrustPanel';

export enum SelectMode {
  All = 'All',
  Selected = 'Selected',
}

interface ServiceConfigPanelProps {
  isOpen: boolean;
  onDismiss: () => void;
  onSave: (services: Service[]) => void;
  onResolveServices: () => Promise<Service[]> | Service[];
  onResolveWorkloads?: () => Promise<ComplianceAuditWorkload[]> | ComplianceAuditWorkload[];
  initialSelectedServices?: AuditEventService[];
  // If provided, user is allowed to choose "All services"
  selectProps?: { mode: SelectMode; onChange: (mode: SelectMode) => void };
  allowEmpty?: boolean;
  primaryKey?: 'ResponsiblePartyId' | 'ServiceTreeId';
  hideSelectAll?: boolean;
  showAssignee?: boolean;
}

export const ServiceConfigPanel: React.FC<ServiceConfigPanelProps> = (
  props,
) => {
  const {
    isOpen,
    onDismiss,
    onResolveServices,
    onResolveWorkloads,
    initialSelectedServices,
    selectProps,
    primaryKey = 'ResponsiblePartyId',
    hideSelectAll = false,
    showAssignee
  } = props;

  const [selectedServiceIds, setSelectedServiceIds] = useState(
    new Set<number | string>(),
  );

  const [allWorkloads, setAllWorkloads] = useState<ComplianceAuditWorkload[]>();
  const [allServices, setAllServices] = useState<Service[]>();

  useEffect(() => {
    setSelectedServiceIds(
      new Set(initialSelectedServices?.map((s) => s.Id) ?? []),
    );
  }, [initialSelectedServices]);

  useEffect(() => {
    if (isOpen) {
      // retrieve service options
      const services = onResolveServices();

      const filterServices = (services: Service[]) => {
        return services.filter((s: Service) => primaryKey === 'ServiceTreeId' ? s[primaryKey] !== null : true);
      };

      if (Array.isArray(services)) {
        setAllServices(filterServices(services));
      } else {
        services.then((result: Service[]) => setAllServices(filterServices(result)));
      }

      // retrieve workload options for service grouping
      if (!_.isNil(onResolveWorkloads)) {
        Promise.resolve(onResolveWorkloads()).then(setAllWorkloads);
      } else {
        EntityApi.getWorkloadListAsync().then(setAllWorkloads);
      }

      // refresh selected service IDs per new prop
      setSelectedServiceIds(
        new Set(initialSelectedServices?.map((s) => s.Id) ?? []),
      );
    } else {
      setAllServices(undefined);
      setAllWorkloads(undefined);
      setSelectedServiceIds(new Set());
    }
  }, [isOpen]);

  const selectOptions: IChoiceGroupOption[] = [
    {
      key: SelectMode.All,
      text: intl.get(LocIds.Label.AllServices)
    },
    {
      key: SelectMode.Selected,
      text: intl.get(LocIds.Label.SelectedServices),
    },
  ];

  const serviceConfigBody = (
    <ServiceConfigBody
      allServices={allServices}
      allWorkloads={allWorkloads}
      hideSelectAll={hideSelectAll}
      primaryKey={primaryKey}
      selectedServiceIds={selectedServiceIds}
      showAssignee={showAssignee}
      onChange={setSelectedServiceIds}
    />
  );

  const panelContent = !_.isNil(selectProps) ? (
    <Stack>
      <ChoiceGroup
        options={selectOptions}
        selectedKey={selectProps.mode}
        onChange={(_, option) => {
          if (option) {
            selectProps.onChange(option.key as SelectMode);
          }
        }}
      />
      <div
        className={classNames('serviceConfigBodyWrapper', {
          hidden: selectProps.mode === SelectMode.All,
        })}>
        { serviceConfigBody }
      </div>
    </Stack>
  ) : (
    serviceConfigBody
  );

  const onSave = () => {
    props.onSave(
      allServices
        ?.filter((s) => {
          const id = s[primaryKey] as number | string | null;
          return id ? selectedServiceIds.has(id) : false;
        }) ?? [],
    );
    onDismiss();
  };

  const canSave = props.allowEmpty ?? (selectProps?.mode === SelectMode.All || selectedServiceIds.size > 0);

  return (
    <TrustPanel
      headerText={intl.get(LocIds.Label.SelectServices)}
      isOpen={isOpen}
      primaryActionProps={{
        text: intl.get(LocIds.Action.Save),
        onClick: onSave,
        disabled: !canSave,
      }}
      onDismiss={onDismiss}>
      { panelContent }
    </TrustPanel>
  );
};
