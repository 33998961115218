import { useEffect } from 'react';

import { useBoolean } from '@fluentui/react-hooks';
import { useStore } from 'react-redux';
import { Reducer } from 'redux';


import { DynamicReducerStore } from '../../redux/store/getStore';

/**
 * This file creates a hook to dynamic inject reducer to redux store
 * This is better for code splitting @see https://redux.js.org/recipes/code-splitting
 * There is a known issue in redux-devtools that calling store.injectReducer inside a React.FunctionComponent will cause exception,
 * so only render component if the reducer is injected @see https://github.com/react-boilerplate/redux-injectors/issues/19
 */

/**
 *
 * @param namespace namepsace of business module, should be unique across whole redux store
 * @param reducer reducer of business module
 */
export const useInjectReducer = (
  namespace: string,
  reducer: Reducer,
): boolean => {
  const store = useStore() as DynamicReducerStore;
  const [injected, { setTrue }] = useBoolean(false);
  useEffect(() => {
    if (!injected) {
      store.injectReducer(namespace, reducer);
      setTrue();
    }
  }, [
    injected,
    namespace,
    reducer,
    store,
    setTrue
  ]);
  return injected;
};
