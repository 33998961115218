import { Guid } from 'odata-query';

export interface PowerBiReportMetadata {
  ReportId: Guid;
  Scope: ReportScope;
  Title: string;
  ScopeDisplayName?: string;
}

export enum ReportScope {
  AdminScope = 'AdminScope',
  AuditScope = 'AuditScope',
  CertificationAndAuditScope = 'CertificationAndAuditScope',
  ChampsScope = 'ChampsScope',
  ComplianceReviewsScope = 'ComplianceReviewsScope',
  ConMonScope = 'ConMonScope',
  FedHoundScope = 'FedHoundScope',
  MockAuditScope = 'MockAuditScope',
  ScorecardDetailsScope = 'ScorecardDetailsScope',
  ScorecardScope = 'ScorecardScope',
  UARScope = 'UARScope'
}
