import './style.less';
import React, { useState } from 'react';

import {
  IconButton, IContextualMenuProps, Persona, PersonaSize, Spinner, Stack, Text
} from '@fluentui/react';

import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { UserNotification } from '../../models';
import { NotificationApi } from '../../services';
import { dateToLocalString } from '../../utils';

type IProps = {
  notification: UserNotification;
  onReload: () => void;
}

export const NotificationEntryView: React.FC<IProps> = (props) => {
  const { notification, onReload } = props;

  const [isDismissing, setIsDismissing] = useState<boolean>(false);

  const labelJsx = (
    <Stack horizontal
      tokens={{
        childrenGap: 8
      }}
      verticalAlign='center'>
      <Persona
        hidePersonaDetails
        size={PersonaSize.size32}
        text={notification.CreatedBy}
      />
      <Stack>
        <Text className='title-14'>{ notification.Title }</Text>
        <Stack className='secondaryText-12 notification-entry__footnote' horizontal>
          <span>{ notification.CategoryText }</span>
          <span>{ dateToLocalString(new Date(notification.CreatedDate)) }</span>
        </Stack>
      </Stack>
    </Stack>
  );

  const redirectButton = notification.RedirectUrl && (
    <IconButton
      iconProps={{
        iconName: 'NavigateExternalInline'
      }}
      onClick={() => {
        window.open(notification.RedirectUrl, '_blank');
      }}
    />
  );

  const dismissNotification = (isGroup: boolean) => {
    setIsDismissing(true);

    NotificationApi.markNotificationAsRead({
      notificationId: notification.NotificationId.Value,
      providerGuid: notification.ProviderId.Value,
      isGroupDismissed: isGroup,
    }).then(() => {
      setIsDismissing(false);
    }).finally(() => {
      setIsDismissing(false);
      onReload();
    });
  };

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'dismissForMe',
        text: intl.get(LocIds.Action.DismissOnlyForMe),
        iconProps: {
          iconName: 'RingerRemove'
        },
        onClick: () => dismissNotification(false),
      },
      {
        key: 'dismissForEveryone',
        text: intl.get(LocIds.Action.DismissForEveryone),
        iconProps: {
          iconName: 'RingerRemove'
        },
        onClick: () => dismissNotification(true),
      },
    ],
  };

  const dismissButton = (
    <IconButton
      iconProps={{
        iconName: 'RingerOff'
      }}
      menuProps={menuProps}
    />
  );

  return (
    <Stack
      className='notification-entry'
      horizontal
      horizontalAlign='space-between'
      verticalAlign='center'>
      { labelJsx }
      { isDismissing ? (
        <Spinner />
      ) : (
        <Stack className='notification-entry__actions' horizontal>
          { redirectButton }
          { dismissButton }
        </Stack>
      ) }
    </Stack>
  );
};
