import {
  createAsyncThunk,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import { useSelector } from 'react-redux';

import { RootReduxState } from './reducer';

import {
  AzureAdMember,
  ServiceContact,
  ServiceContactType,
  ServiceContactTypeNotification,
  UpdateServiceNotificationConfigPayload,
} from '../../../../../models';
import { ServiceWorkspaceApiService } from '../../../../../services';
import { UseAsyncHookStatus } from '../../../../../utils';

/**
 * Types
 */

type SliceState = {
  contacts: ServiceContact[] | undefined;
  loadingContacts: UseAsyncHookStatus;

  notifiedRoles: ServiceContactType[];
  editedNotifiedRoles: ServiceContactType[];
  loadingNotifiedRoles: UseAsyncHookStatus;

  customUserObjectIds: string[];
  editedCustomUsers: AzureAdMember[];

  updatingStatus: UseAsyncHookStatus;
};

const initialState: SliceState = {
  contacts: undefined,
  loadingContacts: UseAsyncHookStatus.Success,

  notifiedRoles: [],
  editedNotifiedRoles: [],
  loadingNotifiedRoles: UseAsyncHookStatus.Success,

  customUserObjectIds: [],
  editedCustomUsers: [],

  updatingStatus: UseAsyncHookStatus.Success,
};

/**
 * Thunks
 */

export const fetchServiceContacts = createAsyncThunk<ServiceContact[], { serviceTreeId: string; }>(
  'service/fetchServiceContacts',
  async ({ serviceTreeId }) => {
    return await ServiceWorkspaceApiService.getServiceContactsAsync(serviceTreeId);
  }
);

export const fetchServiceNotifiedRoles = createAsyncThunk<ServiceContactTypeNotification[], { serviceTreeId: string; }>(
  'service/fetchServiceNotifiedRoles',
  async ({ serviceTreeId }) => {
    return await ServiceWorkspaceApiService.getServiceNotifiedRolesAsync(serviceTreeId);
  }
);

export const updateServiceNotificationSettings = createAsyncThunk<void, { serviceTreeId: string }, { state: RootReduxState }>(
  'service/updateServiceNotificationSettings',
  async ({ serviceTreeId }, { getState }) => {
    const { notifiedRoles, editedNotifiedRoles, customUserObjectIds, editedCustomUsers } = getState().serviceBoard.notification;
    const editedUserAccountGuids = editedCustomUsers.map(u => u.Id);

    const payload: UpdateServiceNotificationConfigPayload = {
      serviceTreeId,
      addServiceContactTypeIds: editedNotifiedRoles.filter(r => !notifiedRoles.includes(r)),
      removeServiceContactTypeIds: notifiedRoles.filter(r => !editedNotifiedRoles.includes(r)),
      addCustomObjectIds: editedUserAccountGuids.filter(u => !customUserObjectIds.includes(u)),
      removeCustomObjectIds: customUserObjectIds.filter(u => !editedUserAccountGuids.includes(u)),
    };

    return await ServiceWorkspaceApiService.updateServiceNotificationConfigAsync(payload);
  }
);

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    resetNotification: () => initialState,
    addNotificationRole: (state, action: PayloadAction<ServiceContactType>) => {
      if (!state.editedNotifiedRoles.includes(action.payload)) {
        state.editedNotifiedRoles.push(action.payload);
      }
    },
    removeNotificationRole: (state, action: PayloadAction<ServiceContactType>) => {
      if (state.editedNotifiedRoles.includes(action.payload)) {
        state.editedNotifiedRoles = state.editedNotifiedRoles.filter(r => r !== action.payload);
      }
    },
    setEditedRecipients: (state, action: PayloadAction<AzureAdMember[]>) => {
      state.editedCustomUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceContacts.pending, (state) => {
        state.loadingContacts = UseAsyncHookStatus.Pending;
      })
      .addCase(fetchServiceContacts.fulfilled, (state, action) => {
        state.loadingContacts = UseAsyncHookStatus.Success;

        // "contacts" is for all contacts except "CustomRecipient"
        state.contacts = action.payload
          .filter(c => c.ServiceContactTypeId !== ServiceContactType.CustomRecipient);

        // "customUsers" is for "CustomRecipient"s
        const allCustomUsers = action.payload.filter(c => c.ServiceContactTypeId === ServiceContactType.CustomRecipient);
        state.customUserObjectIds = allCustomUsers.map(c => c.ObjectId);
        state.editedCustomUsers = allCustomUsers.map(u => ({
          Id: u.ObjectId,
          DisplayName: u.DisplayName,
          Mail: '',
          Alias: '',
        }));
      })
      .addCase(fetchServiceContacts.rejected, (state) => {
        state.loadingContacts = UseAsyncHookStatus.Fail;
      });

    builder
      .addCase(fetchServiceNotifiedRoles.pending, (state) => {
        state.loadingNotifiedRoles = UseAsyncHookStatus.Pending;
      })
      .addCase(fetchServiceNotifiedRoles.fulfilled, (state, action) => {
        state.loadingNotifiedRoles = UseAsyncHookStatus.Success;

        const allRoles = action.payload.map(r => r.ServiceContactType);
        state.notifiedRoles = allRoles;
        state.editedNotifiedRoles = allRoles;
      })
      .addCase(fetchServiceNotifiedRoles.rejected, (state) => {
        state.loadingNotifiedRoles = UseAsyncHookStatus.Fail;
      });

    builder
      .addCase(updateServiceNotificationSettings.pending, (state) => {
        state.updatingStatus = UseAsyncHookStatus.Pending;
      })
      .addCase(updateServiceNotificationSettings.fulfilled, (state) => {
        state.updatingStatus = UseAsyncHookStatus.Success;
      })
      .addCase(updateServiceNotificationSettings.rejected, (state) => {
        state.updatingStatus = UseAsyncHookStatus.Fail;
      });
  }
});

export const {
  resetNotification,
  addNotificationRole,
  removeNotificationRole,
  setEditedRecipients
} = notificationSlice.actions;

export const useServiceContacts = (): ServiceContact[] | undefined =>
  useSelector<RootReduxState, ServiceContact[] | undefined >((state) => state.serviceBoard.notification.contacts);

export const useNotifiedRoles = (): ServiceContactType[] =>
  useSelector<RootReduxState, ServiceContactType[]>((state) => state.serviceBoard.notification.editedNotifiedRoles);

export const useCustomUsers = (): AzureAdMember[] | undefined =>
  useSelector<RootReduxState, AzureAdMember[] | undefined >((state) => state.serviceBoard.notification.editedCustomUsers);

export const useLoadingContactsStatus = (): UseAsyncHookStatus =>
  useSelector<RootReduxState, UseAsyncHookStatus>(state => state.serviceBoard.notification.loadingContacts);

export const useUpdatingStatus = (): UseAsyncHookStatus =>
  useSelector<RootReduxState, UseAsyncHookStatus>(state => state.serviceBoard.notification.updatingStatus);

export const useIsNotificationSettingsUpdated = (): boolean =>
  useSelector<RootReduxState, boolean >((state) => {
    const { notifiedRoles, editedNotifiedRoles, customUserObjectIds, editedCustomUsers } = state.serviceBoard.notification;

    if (notifiedRoles.length !== editedNotifiedRoles.length || customUserObjectIds.length !== editedCustomUsers.length) {
      return true;
    }

    const editedNotifiedRolesSet = new Set(editedNotifiedRoles);

    for (const role of notifiedRoles) {
      if (!editedNotifiedRolesSet.has(role)) {
        return true;
      }
    }

    const editedCustomUserIdSet = new Set(editedCustomUsers.map(u => u.Id));

    for (const userId of customUserObjectIds) {
      if (!editedCustomUserIdSet.has(userId)) {
        return true;
      }
    }

    return false;
  });
