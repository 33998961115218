import { createSlice } from '@reduxjs/toolkit';

import { getMyNetworkStatus } from './thunk';

import { MyNetworkStatus } from '../../models';

export interface INetworkStatus {
    MyNetworkStatus: MyNetworkStatus | undefined;
}

export const initialState: INetworkStatus = {
  MyNetworkStatus: undefined
};

export const networkStatusSlice = createSlice({
  name: 'networkStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMyNetworkStatus.fulfilled, (state, action) => {
      state.MyNetworkStatus = action.payload;
    });
  },
});
