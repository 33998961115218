export const sortingOrder = {
  ASCENDING: 'asc',
  DESCENDING: 'desc'
};

export default function setSortArrow(columnName: string, sortBy:string, sortOrder: string): boolean | undefined {
  if (columnName !== sortBy) {
    return undefined;
  }

  if (sortOrder === sortingOrder.DESCENDING) {
    return true;
  }

  return false;
}
