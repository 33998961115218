import { EvidenceTreeRootNode } from './EvidenceTreeRootNode';

import { Evidence } from '../Evidence';
import { EvidencePath } from '../EvidencePath';
import { EvidenceTreeNodeVisitor } from '../EvidenceTreeVisitor/EvidenceTreeNodeVisitor';

export class EvidenceTreeNodeBase {
  /**
   * Whether current node is filtered by user query,
   * For AuditEventNode/TeamNode/Certification nodes, isVisible is the same as isFiltered
   * For EvidenceNode, isVisible is always false
   * Subclass can implement their own getter on whether a node is visible
   */
  public isFiltered = true;
  public get isVisible(): boolean {
    return this.isFiltered;
  }

  /**
   * Display name of current node
   */
  public name = '';

  /**
   * Identifier of current node
   */
  public identifierName: keyof Evidence | null | 'Team' = null;
  public identifierValue = '';

  /**
   * React supports a key attribute. When children have keys,
   * React uses the key to match children in the original tree with children in the subsequent tree.
   * @see https://reactjs.org/docs/reconciliation.html
   * Since nodes on the same level tend to have same identifierName and different identifierValue,
   * we provide identiferValue as key
   *
   */
  public get key(): string {
    return this.identifierValue;
  }

  public get pathIdentifier(): string {
    return JSON.stringify(this.pathToCurrentNode);
  }

  /**
   * EvidencePath from root node to current node (root not included)
   */
  public get pathToCurrentNode(): EvidencePath {
    const result: EvidencePath = {};
    let node: EvidenceTreeNodeBase | null = this;

    while (node && node.identifierName && node.identifierValue) {
      result[node.identifierName] = node.identifierValue;

      if ((node as EvidenceTreeInternalNode).parent) {
        node = (node as EvidenceTreeInternalNode)
          .parent as EvidenceTreeNodeBase;
      } else {
        node = null;
      }
    }

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public accept(visitor: EvidenceTreeNodeVisitor): void {}
}

/**
 * internal node of EvidenceTree that has children and parent
 */
export class EvidenceTreeInternalNode extends EvidenceTreeNodeBase {
  public parent: EvidenceTreeRootNode | EvidenceTreeInternalNode | null = null;
  public get children(): ReadonlyArray<
    EvidenceTreeLeafNode | EvidenceTreeInternalNode
    > {
    return [...this._children];
  }

  // childrens' parent property is set implicitly in the setter
  public set children(
    c: ReadonlyArray<EvidenceTreeLeafNode | EvidenceTreeInternalNode>,
  ) {
    this._children = [...c];
    this._children.forEach((child) => (child.parent = this));
  }

  private _children: (EvidenceTreeLeafNode | EvidenceTreeInternalNode)[] = [];
}

/**
 * leaf node of EvidenceTree that only has parent pointer
 */
export class EvidenceTreeLeafNode extends EvidenceTreeNodeBase {
  public parent: EvidenceTreeRootNode | EvidenceTreeInternalNode | null = null;
}
