import _, {
  difference, isEmpty, isEqual, isNil, sortBy
} from 'lodash';

import {
  compareAttachment, diffAttachments, getAddAttachment, getUpdateAttachment
} from './attachmentFacade';

import {
  AzureAdMember,
  Certification,
  CertificationControl,
  EditOriginalEvidenceRequestPayload,
  Environment,
  EvidenceRequestAttachment,
  EvidenceStateEnum,
  Nullable,
  OriginalEvidence,
  Service,
  SubmitOriginalEvidenceRequestPayload,
  ValueType,
} from '../models';


export type EditOriginalEvidenceEditableFields = {
  title: string;
  description: string;
  attachments: EvidenceRequestAttachment[];
  state: string | undefined;
  dueDate: Date | undefined;
  assignedTo: AzureAdMember | undefined;
  // Only used to define certification control sets on UI. Not used in payload.
  certification: Certification | undefined;
  certificationControls: CertificationControl[] | undefined;
  environment: Environment | undefined;
  services: Service[] | undefined;
  providerService: Service | undefined;
  consumerServices: Service[] | undefined;
};

export type SubmitOriginalEvidenceEditableFields = {
  assignedTo?: AzureAdMember;
  attachments?: EvidenceRequestAttachment[];
}

export const validateOriginalEvidenceRequiredFields = (edited: EditOriginalEvidenceEditableFields): boolean => {
  return (
    !isEmpty(edited.title) &&
    !isNil(edited.state) &&
    !isNil(edited.dueDate) &&
    !isNil(edited.assignedTo) &&
    !isNil(edited.certification) &&
    !isEmpty(edited.certificationControls) &&
    !isNil(edited.environment) &&
    // TODO: Remove the check for single service after we support responsible party and benefiting party editing.
    edited.services?.length === 1
  );
};

export const isOriginalEvidenceUpdated = (original: OriginalEvidence, edited: EditOriginalEvidenceEditableFields): boolean => {
  return (
    !isEqual(original.Title, edited.title) ||
    !isEqual(original.Description, edited.description) ||
    !isEqual(original.State, edited.state) ||
    !isEqual(original.Attachments, edited.attachments) ||
    !isEqual(new Date(original.DueDate), edited.dueDate) ||
    !isEqual(original.AssignedTo, edited.assignedTo?.Alias) ||
    !isEqual(original.Certification, edited.certification?.ShortName) ||
    !isEqual(sortBy(original.CertificationControl), sortBy(edited.certificationControls?.map((c) => c.Code))) ||
    !isEqual(original.EnvironmentName, edited.environment?.Name) ||
    !isEqual(sortBy(original.Teams), sortBy(edited.services?.map((s) => s.ShortName))) ||
    !isEqual(original.ProviderService?.ServiceTreeId, edited.providerService?.ServiceTreeId) ||
    !isEqual(sortBy(original.ConsumerServices.map(s => s.ServiceTreeId)), sortBy(edited.consumerServices?.map((s) => s.ServiceTreeId)))
  );
};

export const constructEditOriginalEvidencePayload = (
  original: OriginalEvidence,
  edited: EditOriginalEvidenceEditableFields,
  enableBenefitingParty: boolean
): EditOriginalEvidenceRequestPayload => {
  const setAssignedToServiceTreeId = enableBenefitingParty ?
    getProviderServicePayload(original.ProviderService?.ServiceTreeId, edited.providerService?.ServiceTreeId) :
    getAssignedToServicePayload(original.Teams, edited.services);

  const newConsumerServiceGuids = sortBy(edited.consumerServices?.map(s => s.ServiceTreeId).filter(s => !isNil(s))) as string[];
  const oldConsumerServiceGuids = sortBy(original.ConsumerServices.map(s => s.ServiceTreeId));

  const payload: EditOriginalEvidenceRequestPayload = {
    auditEventGuid: original.AuditEventGuid,
    evidenceRequestGuid: original.EvidenceGuid,
    setTitle: getValueType(original.Title, edited.title),
    setDescription: getValueType(original.Description, edited.description),
    setAssignedToAccountGuid: getAssignedToAccountGuidPayload(original.AssignedTo, edited.assignedTo),
    setDueDate: getValueType(new Date(original.DueDate), edited.dueDate),
    setEvidenceStateType: getValueType(original.State as EvidenceStateEnum, edited.state as EvidenceStateEnum),
    // addComment: undefined, // TODO: Not implemented.
    setEnvironmentId: getEnvironmentPayload(original.EnvironmentName, edited.environment, !_.isNull(setAssignedToServiceTreeId)),
    setCertificationControlIds: getCertControlPayload(original.CertificationControl, edited.certificationControls),
    setAssignedToServiceTreeId,
    setBenefitingTeamsIds: null,
    updateConsumerServiceGuidsActions: isEqual(newConsumerServiceGuids, oldConsumerServiceGuids) ? null : {
      Add: difference(newConsumerServiceGuids, oldConsumerServiceGuids),
      Remove: difference(oldConsumerServiceGuids, newConsumerServiceGuids),
    },
    addAttachments: null,
    updateAttachments: null,
    removeAttachments: null,
  };

  const attachmentsRequest = diffAttachments(
    original.Attachments,
    edited.attachments,
    compareAttachment,
    getUpdateAttachment,
    getAddAttachment,
  );

  Object.assign(payload, attachmentsRequest);

  return payload;
};

export const constructSubmitOriginalEvidencePayload = (
  original: OriginalEvidence,
  edited: SubmitOriginalEvidenceEditableFields,
  isSubmit: boolean
): SubmitOriginalEvidenceRequestPayload => {
  const payload: SubmitOriginalEvidenceRequestPayload = {
    auditEventGuid: original.AuditEventGuid,
    evidenceRequestGuid: original.EvidenceGuid,
    setAssignedToAccountGuid: getAssignedToAccountGuidPayload(original.AssignedTo, edited.assignedTo),
    setSubmitted: {
      Value: isSubmit
    },
    addAttachments: null,
    updateAttachments: null,
    removeAttachments: null,
  };

  const attachmentsRequest = diffAttachments(
    original.Attachments,
    edited.attachments ?? original.Attachments,
    compareAttachment,
    getUpdateAttachment,
    getAddAttachment,
  );

  Object.assign(payload, attachmentsRequest);

  return payload;
};

const getValueType = <T>(previous: T, current: T | undefined): ValueType<T> | undefined => {
  return !_.isNil(current) && !_.isEqual(previous, current) ? {
    Value: current
  } : undefined;
};

const getAssignedToObjectIdPayload = (
  previousAlias: string,
  currentAzureMember: AzureAdMember | undefined,
): ValueType<string> | undefined => {
  return !_.isNil(currentAzureMember) && !_.isEqual(previousAlias, currentAzureMember.Alias) ? {
    Value: currentAzureMember.Id
  } : undefined;
};

const getAssignedToAccountGuidPayload = (
  previousAlias: string,
  currentAzureMember: AzureAdMember | undefined,
): ValueType<string> | undefined => {
  return !_.isNil(currentAzureMember) && !_.isEqual(previousAlias, currentAzureMember.Alias) && !_.isNil(currentAzureMember.AccountGuid) ? {
    Value: currentAzureMember.AccountGuid
  } : undefined;
};

// If services are changed, this payload is required to indicate the mapping between environment and services to the backend.
const getEnvironmentPayload = (
  previousEnvironmentName: string,
  currentEnvironment: Environment | undefined,
  diffService: boolean
): ValueType<number> | undefined => {
  return !_.isNil(currentEnvironment) && (!_.isEqual(previousEnvironmentName, currentEnvironment.Name) || diffService) ?
    {
      Value: currentEnvironment.EnvironmentId
    } : undefined;
};

const getCertControlPayload = (
  previousCertControls: string[],
  currentCertControls: CertificationControl[] | undefined,
): Nullable<number[]> => {
  return !_.isNil(currentCertControls) && !_.isEqual(previousCertControls, currentCertControls.map((c) => c.Code)) ?
    currentCertControls.map((c) => c.CertificationControlId) : null;
};

const getProviderServicePayload = (
  oldServiceGuid: string | undefined | null,
  newServiceGuid: string | undefined | null
): ValueType<string> | undefined => {
  return !isEqual(oldServiceGuid, newServiceGuid) && !isNil(newServiceGuid) ? {
    Value: newServiceGuid
  } : undefined;
};

// Before we support both responsible party and benefiting party editing, we allow only responsible party editing.
// The payload is valid only when currentServices contain a single service.
const getAssignedToServicePayload = (
  previousServices: string[],
  currentServices: Service[] | undefined,
): ValueType<string> | undefined => {
  return (!_.isEqual(previousServices, currentServices) && !_.isNil(currentServices) &&
    currentServices.length === 1 && !_.isNil(currentServices[0].ServiceTreeId)) ? {
      Value: currentServices[0].ServiceTreeId
    } : undefined;
};

const getServicePayload = (
  previousServices: string[],
  currentServices: Service[] | undefined,
): Nullable<number[]> => {
  return !_.isNil(currentServices) && !_.isEqual(previousServices, currentServices.map((s) => s.ShortName)) ?
    currentServices.map((s) => s.ResponsiblePartyId) : null;
};
