import React, {
  useEffect, useState
} from 'react';

import {
  CommandBar, PanelType
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  isEmpty, isNil
} from 'lodash';
import intl from 'react-intl-universal';

import {
  EvidenceProposalPanelMode, ProposedEvidencePanel
} from './Panel/ProposedEvidencePanel';
import { ProposedEvidenceDetailList } from './ProposedEvidenceDetailList';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  AuditEvent, AuditFlags, Evidence, FCC, ProposedEvidence
} from '../../models';
import {
  EvidenceApi, ProposedEvidenceApi
} from '../../services';
import { UseAsyncHookStatus } from '../../utils';
import { ViewLayout } from '../TrustLayout';
import { TrustPanel } from '../TrustPanel';

type IProps = {
  auditEvent: AuditEvent | undefined;
  isAuditManager: boolean;
  onDismiss: () => void;
}

const MAX_RETRY_TO_GET_LATEST_PROPOSED_EVIDENCE = 5;

export const ProposedEvidenceListPanel: FCC<IProps> = (props) => {
  const { auditEvent, isAuditManager, onDismiss } = props;

  const [items, setItems] = useState<ProposedEvidence[]>();

  const [producedEvidences, setProducedEvidences] = useState<Evidence[]>();

  const [isNewPanelOpen, { setTrue: openNewPanel, setFalse: closeNewPanel }] = useBoolean(false);
  const [apiStatus, setApiStatus] = useState<UseAsyncHookStatus>(UseAsyncHookStatus.Success);

  const [updatingStatus, setUpdatingStatus] = useState<UseAsyncHookStatus>(UseAsyncHookStatus.Success);

  const isProposalDisabledForAudit = !auditEvent?.AuditFlags.includes(AuditFlags.IsAuditorProposalEnabled);

  const reloadData = (auditEventGuid: string) => {
    setApiStatus(UseAsyncHookStatus.Pending);
    setItems(undefined);
    ProposedEvidenceApi.getProposedEvidencesAsync(auditEventGuid)
      .then((response) => {
        setItems(response.sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime()));
        setApiStatus(UseAsyncHookStatus.Success);
      })
      .catch(() => {
        setApiStatus(UseAsyncHookStatus.Fail);
      });
  };

  useEffect(() => {
    if (auditEvent) {
      reloadData(auditEvent.AuditEventGuid);
    }
  }, [auditEvent]);

  useEffect(() => {
    if (!isNil(items) && !isEmpty(items)) {
      const proposalGuids = items.map((item) => item.ProposedEvidenceGuid);

      if (isAuditManager) {
        EvidenceApi.getOriginalEvidenceByProposalGuidAsync(proposalGuids)
          .then(setProducedEvidences);
      } else {
        EvidenceApi.getPublishedEvidenceByProposalGuidAsync(proposalGuids)
          .then(setProducedEvidences);
      }
    }
  }, [items]);

  const reloadEvidenceWithRetry = async (
    auditEventGuid: string,
    proposedEvidence: ProposedEvidence,
    maxRetryCount = MAX_RETRY_TO_GET_LATEST_PROPOSED_EVIDENCE
  ): Promise<ProposedEvidence | undefined> => {
    const response = await ProposedEvidenceApi.getProposedEvidenceAsync(auditEventGuid, proposedEvidence.ProposedEvidenceGuid);

    if (response && (response.ETag !== proposedEvidence.ETag && JSON.stringify(response) !== JSON.stringify(proposedEvidence))) {
      return response;
    }

    if (maxRetryCount <= 1) {
      return response;
    }

    return await reloadEvidenceWithRetry(auditEventGuid, proposedEvidence, --maxRetryCount);
  };

  const updateEvidence = (evidence: ProposedEvidence) => {
    if (auditEvent) {
      reloadEvidenceWithRetry(auditEvent.AuditEventGuid, evidence)
        .then(response => {
          if (response) {
            setItems(items?.map(item => item.ProposedEvidenceGuid === evidence.ProposedEvidenceGuid ? response : item));
          }
        });
    }
  };

  const commandItems = [
    {
      key: 'propose',
      iconProps: {
        iconName: 'Add',
      },
      onClick: openNewPanel,
      text: intl.get(LocIds.Evidence.ProposedEvidence),
    }
  ];

  const commandFarItems = [
    {
      key: 'refresh',
      iconProps: {
        iconName: 'Refresh',
      },
      onClick: () => {
        if (auditEvent) {
          reloadData(auditEvent.AuditEventGuid);
        }
      },
      text: intl.get(LocIds.Action.Refresh),
    }
  ];

  const listJsx = (
    <ViewLayout
      isEmpty={isEmpty(items)}
      isError={false}
      isLoaded={!isNil(items) && !isNil(auditEvent)}
      isNotFound={isProposalDisabledForAudit}>
      { auditEvent && (
        <ProposedEvidenceDetailList
          isAuditManager={isAuditManager}
          items={items ?? []}
          producedEvidences={producedEvidences ?? []}
          setUpdateApiStatus={setUpdatingStatus}
          onReload={updateEvidence}
        />
      ) }
    </ViewLayout>
  );

  const newPanelJsx = auditEvent && (
    <ProposedEvidencePanel
      auditEvent={auditEvent}
      isAuditManager={isAuditManager}
      isOpen={isNewPanelOpen}
      mode={EvidenceProposalPanelMode.Create}
      onDismiss={closeNewPanel}
      onReload={() => reloadData(auditEvent.AuditEventGuid)}
    />
  );

  return (
    <TrustPanel
      closeLabel={intl.get(LocIds.Label.Close)}
      headerText={intl.get(LocIds.Evidence.ProposedEvidences)}
      isOpen={true}
      showErrorMessage={apiStatus === UseAsyncHookStatus.Fail || updatingStatus === UseAsyncHookStatus.Fail}
      showProcessingMessage={updatingStatus === UseAsyncHookStatus.Pending}
      type={PanelType.large}
      onDismiss={onDismiss}>
      { !isProposalDisabledForAudit && (
        <CommandBar farItems={commandFarItems} items={!isAuditManager ? commandItems : []}/>
      ) }
      { listJsx }
      { newPanelJsx }
    </TrustPanel>
  );
};
