import './CompactEvidenceCardMetaInfo.less';

import React, {
  ReactElement,
  useState
} from 'react';

import {
  Link,
  Stack
} from '@fluentui/react';
import _ from 'lodash';
import intl from 'react-intl-universal';

import { LocIds } from '../../../common/Globalization/IntlEnum';
import { Evidence } from '../../../models';
import {
  AuditEventDetailPanel,
  AuditEventDetailPanelProps
} from '../../AuditEventDetailPanel/AuditEventDetailPanel';

import {
  MetadataList,
  MetadataListItemProps
} from '../../MetadataList';

interface IProps extends Pick<AuditEventDetailPanelProps, 'getAuditEventDetailsApi'> {
  evidence: Evidence;
  adoLink?: React.ReactElement;
}

export const CompactEvidenceCardMetaInfo: React.FC<IProps> = ({
  evidence,
  adoLink,
  getAuditEventDetailsApi
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const onDismiss = () => {
    setIsPanelOpen(false);
  };

  const onRenderAuditEventTitle = () => (
    <Link
      className='text-12 compactEvidenceCardMetaInfo_auditEventTitleLink'
      title={evidence.AuditEventTitle}
      onClick={() => setIsPanelOpen(true)}>
      { evidence.AuditEventTitle }
    </Link>
  );

  const metadataItems: MetadataListItemProps[] = [
    {
      label: intl.get(LocIds.Label.AuditEvent),
      onRenderValue: onRenderAuditEventTitle,
    },
    {
      label: intl.get(LocIds.Label.EvidenceId),
      value: evidence.EvidenceId,
    },
  ];

  if (!_.isUndefined(adoLink)) {
    metadataItems.push({
      label: intl.get(LocIds.Label.BugId),
      onRenderValue: () => (adoLink as ReactElement),
    });
  }

  const certificationMetadataItems = [
    {
      label: intl.get(LocIds.Label.Certification),
      value: evidence.Certification,
    },
    {
      label: intl.get(LocIds.Label.CertificationControl),
      value: evidence.CertificationControl.join(','),
    }
  ];

  const environmentMetadataItems = [
    {
      label: intl.get(LocIds.Label.Environment),
      value: evidence.EnvironmentName,
    },
    {
      label: intl.get(LocIds.Label.Services),
      value: evidence.Teams.join(','),
    }
  ];

  return (
    <Stack>
      <MetadataList items={metadataItems}/>
      <MetadataList items={certificationMetadataItems}/>
      <MetadataList items={environmentMetadataItems}/>
      { isPanelOpen && (
        <AuditEventDetailPanel
          auditEventGuid={evidence.AuditEventGuid}
          getAuditEventDetailsApi={getAuditEventDetailsApi}
          isOpen={isPanelOpen}
          onDismiss={onDismiss}
        />
      ) }
    </Stack>
  );
};
