import React, {
  useEffect,
  useState
} from 'react';

import {
  isNil,
  isUndefined
} from 'lodash';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  Environment,
  Nullable
} from '../../models';
import { EntityApi } from '../../services';
import { SingleSelectDropdown } from '../Dropdown';

type EnvironmentDropdownProps = {
  complianceAuditId: number | undefined;
  certificationFamilyId: number | undefined | null;
  onChange: (item: Nullable<Environment>) => void;
  selected?: Nullable<Environment>;
  // FIXME: String comparison? Buggy and should be removed.
  defaultValue?: string;
  disabled?: boolean;
};

export const EnvironmentDropdown: React.FC<EnvironmentDropdownProps> = (
  props,
) => {
  const { complianceAuditId, certificationFamilyId, onChange, selected } =
    props;

  const [allOptions, setAllOptions] = useState<Environment[]>();

  useEffect(() => {
    if (!isNil(complianceAuditId) && !isNil(certificationFamilyId)) {
      EntityApi.getEnvironmentForAuditAsync(
        complianceAuditId,
        certificationFamilyId,
      ).then((envs) => {
        setAllOptions(envs);

        if (isNil(selected)) {
          onChange(envs.find((e) => e.Name === props.defaultValue) ?? null);
        } else {
          onChange(
            envs.find((e) => e.EnvironmentId === selected.EnvironmentId) ??
              null,
          );
        }
      });
    }
  }, [complianceAuditId, certificationFamilyId]);

  const isDisabled = props.disabled || isUndefined(allOptions);
  const isLoadingOptions =
    isUndefined(allOptions) && isUndefined(certificationFamilyId);

  const placeholder = isLoadingOptions ?
    intl.get(LocIds.Placeholder.LoadingOptions) :
    intl.get(LocIds.Placeholder.SelectAnOption);

  const errorMessage =
    !isLoadingOptions && isNil(selected) ?
      intl.get(LocIds.Error.KeyRequired, {
        key: intl.get(LocIds.Label.Environment),
      }) :
      undefined;

  return (
    <SingleSelectDropdown
      disabled={isDisabled}
      errorMessage={errorMessage}
      items={allOptions ?? []}
      keyOf={(item) => `${item.EnvironmentId}`}
      label={intl.get(LocIds.Label.Environment)}
      optionOf={(item) => ({
        key: `${item.EnvironmentId}`,
        text: item.Name
      })}
      placeholder={placeholder}
      required
      selectedItem={selected ?? undefined}
      onChange={onChange}
    />
  );
};
