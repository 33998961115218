import _, {
  difference, isEqual, isNil, sortBy
} from 'lodash';

import {
  compareAttachment, diffAttachments, getAddAttachment, getUpdateAttachment,
} from './attachmentFacade';

import {
  Evidence, UpdateDraftEvidenceRequestDetails,
} from '../models';

const getValueType = (
  originEntity: Evidence,
  updatedEntity: Evidence,
  propName: keyof Pick<Evidence, 'Title' | 'Certification' | 'EnvironmentName' | 'Description'>,
) => {
  return originEntity[propName] !== updatedEntity[propName] ?
    {
      Value: updatedEntity[propName]
    } :
    null;
};

const getComplexType = (
  originEntity: Evidence,
  updatedEntity: Evidence,
  propName: keyof Pick<Evidence, 'CertificationControl' | 'Teams'>,
) => {
  return _.isEqual(originEntity[propName], updatedEntity[propName]) ?
    null :
    updatedEntity[propName];
};

export const getUpdateDraftEvidenceRequest = (
  originEntity: Evidence,
  updatedEntity: Evidence,
): UpdateDraftEvidenceRequestDetails => {
  const setProviderServiceGuid = !isEqual(originEntity.ProviderService?.ServiceTreeId, updatedEntity.ProviderService?.ServiceTreeId) && !isNil(updatedEntity.ProviderService) && !isNil(updatedEntity.ProviderService.ServiceTreeId) ? {
    Value: updatedEntity.ProviderService.ServiceTreeId,
  } : null;

  const newConsumerServiceGuids = sortBy(updatedEntity.ConsumerServices.map(s => s.ServiceTreeId));
  const oldConsumerServiceGuids = sortBy(originEntity.ConsumerServices.map(s => s.ServiceTreeId));

  const updateConsumerServiceGuidsActions = !isEqual(newConsumerServiceGuids, oldConsumerServiceGuids) ? {
    Add: difference(newConsumerServiceGuids, oldConsumerServiceGuids),
    Remove: difference(oldConsumerServiceGuids, newConsumerServiceGuids),
  } : null;

  const request: UpdateDraftEvidenceRequestDetails = {
    auditEventGuid: originEntity.AuditEventGuid,
    evidenceGuid: originEntity.EvidenceGuid,
    setTitle: getValueType(originEntity, updatedEntity, 'Title'),
    setCertificationControl: getComplexType(
      originEntity,
      updatedEntity,
      'CertificationControl',
    ),
    setCertification: getValueType(
      originEntity,
      updatedEntity,
      'Certification',
    ),
    setTeams: getComplexType(originEntity, updatedEntity, 'Teams'),
    setProviderServiceGuid,
    updateConsumerServiceGuidsActions,
    setEnvironmentName: getValueType(
      originEntity,
      updatedEntity,
      'EnvironmentName',
    ),
    setDescription: getValueType(originEntity, updatedEntity, 'Description'),
    addAttachments: null,
    updateAttachments: null,
    removeAttachments: null,
  };

  const attachmentsRequest = diffAttachments(
    originEntity.Attachments,
    updatedEntity.Attachments,
    compareAttachment,
    getUpdateAttachment,
    getAddAttachment,
  );

  Object.assign(request, attachmentsRequest);

  return request;
};
