import React from 'react';

import { IDropdownOption } from '@fluentui/react';
import _ from 'lodash';

import { BaseDropdown } from './BaseDropdown';
import { SharedDropdownProps } from './types';

export interface SingleSelectDropdownProps<T> extends SharedDropdownProps<T> {
  selectedItem?: T;
  onChange?: (item: T) => void;
}

export function SingleSelectDropdown<T>(
  props: SingleSelectDropdownProps<T>,
): ReturnType<React.FC<SingleSelectDropdownProps<T>>> {
  const { items, selectedItem, keyOf, onChange } = props;

  // `selectedItem` should always be one of the element of `items`
  const selectedKey =
    !_.isNil(selectedItem) &&
    items.some((item) => keyOf(item) === keyOf(selectedItem)) ?
      keyOf(selectedItem) :
      null;

  const onDropdownChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
  ): void => {
    if (option && onChange) {
      const selectedItem = items.find((item) => keyOf(item) === option.key);

      if (!_.isNil(selectedItem)) {
        onChange(selectedItem);
      }
    }
  };

  return (
    <BaseDropdown
      {...props}
      selectedKey={selectedKey}
      onChange={onDropdownChange}
    />
  );
}
