import React from 'react';

import { Stack } from '@fluentui/react';

import {
  MetadataListItem,
  MetadataListItemProps
} from './MetadataListItem';


interface IProps {
    items: MetadataListItemProps[]

    /** Column flex basis in px, default to 200 */
    columnMinWidthInPx?: number;
}

export const MetadataList: React.FC<IProps> = (props: IProps) => {
  const { items, columnMinWidthInPx = 200 } = props;
  const columnFlex = `1 1 ${columnMinWidthInPx}px`;
  return (
    <Stack horizontal
      wrap>
      { items.map((item) => (
        <Stack.Item key={item.label}
          styles={{
            root: {
              flex: columnFlex,
            }
          }}>
          <MetadataListItem {...item}/>
        </Stack.Item>
      )) }
    </Stack>
  );
};
