import React, { useState } from 'react';

import { IconButton } from '@fluentui/react';
import intl from 'react-intl-universal';

import { MAX_RETRY_TO_DOWNLOAD_ATTACHMENTS } from './constant';
import { buildDescription } from './utils';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  Evidence, EvidenceRequestAttachment
} from '../../models';
import { AttachmentType } from '../../models/AttachmentType';
import { downloadBlobAsync } from '../../services';
import { notify } from '../../utils/notify';
import { FileTileGroup } from '../FileTile';

type EvidenceAttachmentsProps = Pick<Evidence, 'Attachments'> & {
  onDeleteAttachment?: (attachment: EvidenceRequestAttachment) => void;
  // The number of files limited to be displayed
  itemTruncated?: number;
  auditTeamEmail?: string;
  shouldContactAuditEventPOC?: boolean;
  showAll?: boolean;
  toggleShowAll?: () => void;
};

const getEvidenceRequestAttachmentIdentifier = (attachment: EvidenceRequestAttachment) => attachment.BlobGuid || attachment.AttachmentGuid;

export const EvidenceAttachments: React.FC<EvidenceAttachmentsProps> = (props) => {
  const { onDeleteAttachment, auditTeamEmail, shouldContactAuditEventPOC = false } = props;

  const Attachments = props.Attachments.filter(a => a.AttachmentType !== AttachmentType.RichTextImage);

  const [downloadingAttachmentIds, setDownloadingAttachmentIds] = useState<Set<string>>(new Set([]));

  const downloadBlobAsyncWithRetry = async (attachment: EvidenceRequestAttachment, maxRetry = 1) => {
    try {
      await downloadBlobAsync(attachment.DownloadName, attachment.DisplayName);
    } catch (e) {
      if (maxRetry <= 1) {
        throw e;
      }

      await downloadBlobAsyncWithRetry(attachment, --maxRetry);
    }
  };

  const onDownload = async (
    attachment: EvidenceRequestAttachment,
    e: React.MouseEvent<IconButton>,
  ) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    setDownloadingAttachmentIds(new Set([...downloadingAttachmentIds, getEvidenceRequestAttachmentIdentifier(attachment)]));

    try {
      await downloadBlobAsyncWithRetry(attachment, MAX_RETRY_TO_DOWNLOAD_ATTACHMENTS);
    } catch (e) {
      let error = intl.get(LocIds.Error.ContactOncall);

      if (shouldContactAuditEventPOC) {
        error = intl.get(LocIds.Error.ContactAuditEventPOC, {
          email: !auditTeamEmail ? '' : `(${auditTeamEmail})`
        });
      }

      notify.error(error);
    }

    downloadingAttachmentIds.delete(getEvidenceRequestAttachmentIdentifier(attachment));
    setDownloadingAttachmentIds(new Set([...downloadingAttachmentIds]));
  };

  const onDelete = (
    attachment: EvidenceRequestAttachment,
    e: React.MouseEvent<IconButton>,
  ) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (props.onDeleteAttachment) {
      props.onDeleteAttachment(attachment);
    }
  };

  if (!Array.isArray(Attachments) || !Attachments.length) {
    return null;
  }

  return (
    <FileTileGroup
      items={Attachments.map((a) => ({
        id: getEvidenceRequestAttachmentIdentifier(a),
        name: a.DisplayName,
        description: buildDescription(a.DateAttached, a.RetainedUntil),
        isDownloading: downloadingAttachmentIds.has(getEvidenceRequestAttachmentIdentifier(a)),
        onDownload: onDownload.bind(null, a),
        onDelete: (!a.IsReadOnly && onDeleteAttachment) ? onDelete.bind(null, a) : undefined,
        isProtected: a.IsProtectionEnabled && a.IsFileTypeSupportedByAip,
        isDangerous: a.IsProtectionEnabled && !a.IsFileTypeSupportedByAip
      }))}
      itemTruncated={props.itemTruncated}
      showAll={props.showAll}
      toggleShowAll={props.toggleShowAll}
    />
  );
};
