import React, { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import {
  AuditDiscussionPanel, DiscussionMode, EvidenceDiscussionPanel
} from '../../../components/DiscussionPanel';
import { ProposedEvidenceDetailPanel } from '../../../components/EvidenceProposal/Panel/ProposedEvidenceDetailPanel';
import NotFound from '../../TrustPortal/pages/NotFound';
import {
  Application, AuditReview
} from '../../TrustPortal/registerApplicationMenu';
import { EvidenceProposalPanel } from '../pages/Evidences/containers/EvidenceView/Proposal/EvidenceProposalPanel';

const Home = lazy(() => import('../pages/Home'));
const Evidences = lazy(() => import('../pages/Evidences'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const EvidencesPageView = lazy(() => import('../pages/Evidences/EvidencesPageView'));

export const routes: RouteObject[] = [
  {
    path: `${Application.AuditReview}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${AuditReview.Evidences}/*`,
        element: <Evidences />,
        children: [
          {
            path: 'audit/:auditEventGuid',
            element: <EvidencesPageView />,
            children: [
              {
                path: 'discussions',
                element: <AuditDiscussionPanel />
              },
              {
                path: 'evidence/:evidenceGuid/discussions',
                element: <EvidenceDiscussionPanel mode={DiscussionMode.AuditReview} />,
              },
              {
                path: 'proposals',
                element: <EvidenceProposalPanel />,
                children: [
                  {
                    path: ':proposedEvidenceGuid/discussions',
                    element: <ProposedEvidenceDetailPanel isAdmin={false}/>,
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: `menu/${AuditReview.Dashboard}/*`,
        element: <Dashboard />,
      },
      {
        path: '*',
        element: <NotFound />,
      }
    ],
  }
];
