import React, { lazy } from 'react';

import {
  Navigate,
  RouteObject
} from 'react-router-dom';

import NotFound from '../../TrustPortal/pages/NotFound';
import {
  Application,
  AdminPanel,
} from '../../TrustPortal/registerApplicationMenu';

const Home = lazy(() => import('../pages/Home'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Collection = lazy(() => import('../pages/Collection'));
const Environments = lazy(() => import('../pages/Environments'));
const Pipelines = lazy(() => import('../pages/Pipelines'));
const AccessControl = lazy(() => import('../pages/Rbac'));
const DataEditor = lazy(() => import('../pages/DataEditor'));

const CustomRoles = lazy(
  () => import('../pages/Rbac/components/CustomRoleListPage'),
);
const GuestUserAccess = lazy(
  () => import('../pages/Rbac/components/GuestUserListPage'),
);
const SocWorkloadService = lazy(
  () => import('../pages/SocServices'),
);

export const routes: RouteObject[] = [
  {
    path: `${Application.AdminPanel}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${AdminPanel.AdminDashboard}/*`,
        element: <Dashboard />,
      },
      {
        path: `menu/${AdminPanel.Collection}/*`,
        element: <Collection />,
      },
      {
        path: `menu/${AdminPanel.EnvironmentList}/*`,
        element: <Environments />,
      },
      {
        path: `menu/${AdminPanel.PipelineList}/*`,
        element: <Pipelines />,
      },
      {
        path: `menu/${AdminPanel.DataEditor}/*`,
        element: <DataEditor />,
      },
      {
        path: `menu/${AdminPanel.AccessControl}/*`,
        element: <AccessControl />,
        children: [
          {
            path: '',
            element: <Navigate to='custom-roles' />,
          },
          {
            path: 'custom-roles',
            element: <CustomRoles />,
          },
          {
            path: 'guest-users',
            element: <GuestUserAccess />,
          },
        ],
      },
      {
        path: `menu/${AdminPanel.SocWorkloadService}`,
        element: <SocWorkloadService />,
        children: [
          {
            path: ':workloadId',
            element: <SocWorkloadService />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      }
    ],
  },
];
