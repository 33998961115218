export enum CycleStatus {
  Compliant = 'Compliant',
  CompliantWithGap = 'CompliantWithGap',
  NonCompliant = 'NonCompliant',
  RA = 'RA',
  Retired = 'Retired'
}

export enum CycleStatusCssClass {
  Compliant = 'cycle-status-compliant',
  NonCompliant = 'cycle-status-not-compliant',
  RA = 'cycle-status-ra',
  Retired = 'cycle-status-retired'
}

export enum RenewalStatus {
  Submitted = 'Submitted',
  ValidCurrentCycle = 'ValidCurrentCycle',
  Initiated = 'Initiated',
  InitiatedManually = 'InitiatedManually',
  Expired = 'Expired',
  Historical = 'Historical',
  Completed = 'Completed',
}

export enum RenewalStatusCssClass {
  Initiated = 'renewal-status-initiated',
  NotInitiated = 'renewal-status-not-initiated',
  Renewed = 'renewal-status-renewed',
  Retired = 'renewal-status-retired'
}

export interface FedRampRenewalListingItem extends Record<string, unknown> {
  ServiceTreeId: string;
  ServiceName: string;
  DivisionName: string;
  OrganizationName: string;
  ServiceGroupName: string;
  TeamGroupName?: string;
  KnownGovClouds: string;
  GccMt: boolean;
  GccM: boolean;
  GccHigh: boolean;
  DoD: boolean;
  NumberOfTests: number;
  NumberOfGovCloudTests: number;
  PassingTests: number;
  BcdrChamp: string;
  CurrentCycleStatus: CycleStatus;
  NextRenewalDate: string;
  ProjectedDueDate: string;
  DueDateAging: string;
  ComplianceStatus: string;
  AssessmentStatus: string;
  MaxLifecycle: string;
  RenewalStatus: RenewalStatus;
  CloudName: string;
}
