import './style.less';

import React from 'react';

import { InlineLabel } from './InlineLabel';

import {
  SingleSelectDropdown,
  SingleSelectDropdownProps
} from '../Dropdown';

type InlineSingleFilterProps<T> = Pick<SingleSelectDropdownProps<T>,
    | 'items'
    | 'selectedItem'
    | 'optionOf'
    | 'keyOf'
    | 'label'
    | 'onChange'
    | 'searchable'
    | 'styles'
    | 'onRenderOption'
    | 'calloutProps'
    | 'ariaLabel'>;

export function InlineSingleFilter<T>(
  props: InlineSingleFilterProps<T>,
): ReturnType<React.FC<InlineSingleFilterProps<T>>> {
  return (
    <SingleSelectDropdown<T>
      ariaLabel={props.label}
      calloutProps={props.calloutProps}
      items={props.items}
      keyOf={props.keyOf}
      optionOf={props.optionOf}
      searchable={props.searchable}
      selectedItem={props.selectedItem}
      styles={props.styles}
      onChange={props.onChange}
      onRenderOption={props.onRenderOption}
      onRenderTitle={(options, defaultRenderer) => (
        <InlineLabel label={props.label}>
          { defaultRenderer ? defaultRenderer(options) : undefined }
        </InlineLabel>
      )}
    />
  );
}
