import React from 'react';

import { Separator } from '@fluentui/react';
import {
  isEmpty, isNil
} from 'lodash';
import intl from 'react-intl-universal';

import { NotificationEntryView } from './NotificationEntryView';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { UserNotification } from '../../models';
import {
  ITab, Tabs
} from '../Tabs/Tabs';
import {
  FormSection, ViewLayout
} from '../TrustLayout';
import { TrustPanel } from '../TrustPanel';

type IProps = {
  isOpen: boolean;
  isError: boolean;
  notifications: UserNotification[] | undefined;
  onReload: () => void;
  onDismiss: () => void;
}

export const NotificationPanel: React.FC<IProps> = (props) => {
  const { isOpen, isError, notifications, onReload, onDismiss } = props;

  const messageListView = (entries: UserNotification[]) => (
    <FormSection compact>
      { entries.map((entry, index) => (
        <div key={index}>
          <Separator />
          <NotificationEntryView notification={entry} onReload={onReload} />
        </div>
      )) }
    </FormSection>
  );

  const tabs: ITab[] = [
    {
      key: 'unread',
      label: intl.get(LocIds.Label.Unread),
      itemCount: notifications?.length ?? 0,
      component: messageListView(notifications ?? []),
    },
  ];

  return (
    <TrustPanel
      headerText={intl.get(LocIds.Label.Notifications)}
      isOpen={isOpen}
      onDismiss={onDismiss}>
      <ViewLayout
        errorMessage={intl.get(LocIds.Placeholder.NoMessages)}
        isEmpty={isEmpty(notifications)}
        isError={isError}
        isLoaded={!isNil(notifications)}>
        <Tabs isInNotificationPanel={true} list={tabs}/>
      </ViewLayout>
    </TrustPanel>
  );
};
