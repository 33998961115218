import {
  IStackTokens,
  ITextStyles
} from '@fluentui/react';

import {
  Text14,
  Title14
} from '../../../../../../common/css/fonts';

export const overviewInfoTextStyle: Partial<ITextStyles> = {
  root: {
    ...Title14,
  }
};

export const overviewInfoLabelTextStyle: Partial<ITextStyles> = {
  root: {
    ...Text14,
  }
};

export const containerStackTokens: IStackTokens = {
  childrenGap: 5
};
