import React from 'react';

import {
  DatePicker as FluentDatePicker,
  IDatePickerProps,
} from '@fluentui/react';

import {
  DATE_PICKER_STRINGS,
  onFormatDate
} from './utils';

export const DatePicker: React.FC<IDatePickerProps> = (props) => {
  return (
    <FluentDatePicker
      {...props}
      formatDate={onFormatDate}
      strings={DATE_PICKER_STRINGS}
    />
  );
};
