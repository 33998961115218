import React, { useEffect } from 'react';

import {
  Label, Stack, Toggle
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { useAppDispatch } from '../../redux/reducer';
import {
  useActivateCoachmarksSettingSelector, useSettingsPanelSelector
} from '../../redux/settingsPanelSlice/selector';
import {
  closeSettingsPanel, setPortalCoachmarksSetting
} from '../../redux/settingsPanelSlice/slice';
import { getGlobalCoachmarkSetting } from '../../redux/settingsPanelSlice/thunk';
import { AccountSettingApi } from '../../services/AthenaRestApiService/AccountSettingApiService';
import { TrustPanel } from '../TrustPanel';

export const SettingsPanel: React.FC = () => {
  const showCoachmarksForPortal = useActivateCoachmarksSettingSelector();
  const openPanelFromExternal = useSettingsPanelSelector();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getGlobalCoachmarkSetting());
  }, [dispatch]);

  const updateGlobalCoachmarkSetting = async (checked: boolean) => {
    // eslint-disable-next-line i18next/no-literal-string
    await AccountSettingApi.saveAccountSetting('PortalCoachmarks', checked.toString());
    dispatch(setPortalCoachmarksSetting(checked));
  };

  return (
    <TrustPanel
      headerText={intl.get(LocIds.Label.Settings)}
      isOpen={openPanelFromExternal}
      onDismiss={() => dispatch(closeSettingsPanel())}>
      <Stack className='optionToggle' role='menuitem'>
        <Label>{ intl.get(LocIds.Label.CoachmarkSettings) }</Label>
        <Toggle
          checked={showCoachmarksForPortal}
          offText={intl.get(LocIds.Label.Off)}
          onChange={(_, checked) => updateGlobalCoachmarkSetting(checked ?? false)}
          onText={intl.get(LocIds.Label.On)}
        />
      </Stack>
    </TrustPanel>
  );
};
