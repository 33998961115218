

import { DocTreeNode } from './DocTreeNode';

import { DocTreeVisitor } from '../DocTreeVisitor/DocTreeVisitor';
import { DocumentMenuItem } from '../Document';

export class DocTreeRootNode {
  private _children: DocTreeNode[] = [];
  constructor(children: DocTreeNode[]) {
    this._children = [...children];
  }

  public accept(visitor: DocTreeVisitor): void {
    visitor.visitDocTreeRootNode(this);
  }

  public get children(): DocTreeNode[] {
    return this._children;
  }

  public findPathByUid(docUid: string): DocumentMenuItem[] {
    const res: DocumentMenuItem[] = [];

    this.children.forEach(c => {
      c.findPathByUid(docUid, res);
    });

    return res.reverse();
  }

  public findUidByPath(docPath: string): string | undefined {
    return this.children.map(c => c.findUidByPath(docPath)).filter(item => item)[0];
  }
}
