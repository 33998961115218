import React from 'react';

import {
  Stack, Label, IDropdownProps, Text
} from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

type IProps = Pick<IDropdownProps, 'label' | 'options' | 'selectedKey' | 'selectedKeys'>

export const ReadOnlyDropdown = (props: IProps) => {
  const selectedValues = [];

  if (props.selectedKey) {
    const selectedOption = props.options?.find((option) => option.key === props.selectedKey);

    if (selectedOption) {
      selectedValues.push(selectedOption.text);
    }
  }

  if (props.selectedKeys) {
    props.selectedKeys.forEach((key) => {
      const selectedOption = props.options?.find((option) => option.key === key);

      if (selectedOption) {
        selectedValues.push(selectedOption.text);
      }
    });
  }

  return (
    <Stack tokens={{
      childrenGap: 5
    }}>
      { props.label && <Label>{ props.label }</Label> }
      { selectedValues.length > 0 ? selectedValues.map(v => <Text key={v}>{ v }</Text>) : <Text>{ intl.get(LocIds.Label.Na) }</Text> }
    </Stack>
  );
};
