import React from 'react';

import {
  IButtonProps,
  IconButton,
  ITooltipHostStyles,
  TooltipHost,
} from '@fluentui/react';

const calloutProps = {
  gapSpace: 0
};
const hostStyles: Partial<ITooltipHostStyles> = {
  root: {
    display: 'inline-block'
  },
};

export const TooltipIconButton: React.FC<IButtonProps> = (props) => {
  return (
    <TooltipHost
      calloutProps={calloutProps}
      content={props.title || props.ariaLabel}
      id={props.id}
      styles={hostStyles}>
      <IconButton {...props} />
    </TooltipHost>
  );
};
