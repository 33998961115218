import {
  ClassificationReviewResourceExceptionState,
  ClassificationReviewResourceState,
  UarResourceBase,
  UarReviewServiceBase
} from './UarTypes';

import { UserAccount } from '../Forms/UserAccount';


export interface UarClassificationReviewService extends UarReviewServiceBase{
    ResourceCount: number;
    ReviewedResourceCount: number;
    IsAttestationDone?: boolean;
}

export enum UarClassificationReviewPreviousDecision {
    INCLUDE = 'Include',
    EXCLUDE = 'Exclude',
}

export interface UarClassificationReviewServiceDecision {
    ExceptionApprovalDate: string;
    ExceptionJustification: string;
    IsInScope: boolean;
    PreviousClassificationReviewDecisionGuid: string;
    ReviewDate: string
    ReviewedByAccountGuid: string;
    ReviewedByDisplayName: string;
    ServiceResourceClassificationReviewGuid: string;
}

export interface UarClassificationReviewResource extends UarResourceBase {
    ExceptionState: ClassificationReviewResourceExceptionState | null;
    RequestedIsInScope: boolean | null;
    RequestedIsProdEnvironment: boolean | null;
    State: ClassificationReviewResourceState;
    PreviousClassificationReviewDecisionGuid: string | null;
}

export interface UarGetClassificationReviewServiceRequest {
    IterationGuid: string;
    DirectReports: UserAccount[];
}

export interface UarGetClassificationReviewServiceByServiceTreeIdRequest {
    IterationGuid: string;
    ServiceTreeId: string;
}

export interface UarGetClassificationReviewResourcesRequest {
    ServiceId: string;
    IterationGuid: string;
    ResourceGuid?: string;
    DirectReports: UserAccount[];
}

export interface DelegateResourcesPayload {
    userAccessReviewGuid: string;
    setDelegation: {
        DelegatedItemGuids: string[];
        DelegateeObjectId: string;
    }
}

export interface ClassificationReviewDecisionItem {
    ServiceResourceClassificationReviewGuid: string;
    SetIsProdEnvironment: boolean;
    SetIsInScope: boolean;
    SetJustification: string;
}

export interface MakeClassificationReviewDecisionBody {
    userAccessReviewGuid: string;
    serviceTreeId: string;
    setItems: ClassificationReviewDecisionItem[];
}

export interface UarServicesFilters {
    State?: string;
    Search?: string;
}

export interface MakeClassificationReviewDecisionPayload {
    decisionPayload: MakeClassificationReviewDecisionBody;
    resourcesPayload: UarGetClassificationReviewResourcesRequest[];
}

export interface UarResourcesFilters {
    ResourceType?: string;
    State?: string;
    Search?: string;
    AssignedTo?: string;
    CreationMethod?: string;
    Environment?: string;
    Scope?: string;
    TenantName? : string;
}

export interface AddClassificationAttestBody {
    userAccessReviewGuid: string;
    serviceTreeId: string;
    manualReviewGuid?: string;
    type: string;
}

export interface ClassificationReviewAttachment {
    AttachmentGuid: string;
    DownloadPath: string;
    Title: string;
    Name: string;
    Extension: string;
    ModifiedDate: string;
}
