import { AccountInfo } from '@azure/msal-browser';
import _ from 'lodash';

const guidRegex = /([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/g;

export const isGuestAccount = (account: AccountInfo | undefined) => {
  if (!_.isNil(account) && !_.isNil(account.idTokenClaims)) {
    const { iss, tid } = (account.idTokenClaims as {iss: string, tid:string});
    const guidMatch = iss.match(guidRegex);

    if (!_.isNil(guidMatch)) {
      return !(guidMatch[0] === tid);
    }
  }

  return true;
};
