import React from 'react';

import intl from 'react-intl-universal';
import { useNavigate } from 'react-router-dom';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import './NotFound.less';
import { Banner } from '../../../../components/Banner';

const NotFound: React.FC = (): JSX.Element => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/');
  };

  return (
    <div className='not-found'>
      <Banner heading={intl.get(LocIds.Error.PageNotFound)}
        iconName='not-found'
        iconSize='large'
        primaryText={intl.get(LocIds.Action.ReturnToHome)}
        onPrimaryClick={onClick}
      />
    </div>
  );
};

export default NotFound;
