import React, {
  FC,
  useEffect,
  useRef,
  useState
} from 'react';

import { DEFAULT_DELAY_MS } from './constant';
import { DelayedLoadingWrapperProps } from './types';

/**
 * <DelayedLoadingWrapper/> can be used to avoid loading blink, a fallback component will only be rendered after delayMs milliseconds.
 */
export const DelayedLoadingWrapper: FC<DelayedLoadingWrapperProps> = (props: DelayedLoadingWrapperProps) => {
  const { showFallbackComponent, delayMs, onRenderFallbackComponent } = props;
  const [show, setShow] = useState(false);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (showFallbackComponent) {
      setShow(false);

      if (timer) {
        if (timer.current) {
          clearTimeout(timer.current);
        }

        timer.current = setTimeout(
          () => setShow(true),
          delayMs || DEFAULT_DELAY_MS,
        );
      }
    }

    return () => {
      if (timer?.current) {
        clearTimeout(timer.current);
      }
    };
  }, [showFallbackComponent]);

  return (
    <>
      { show && showFallbackComponent ?
        onRenderFallbackComponent() :
        props.children }
    </>
  );
};
