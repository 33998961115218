import React, { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import {
  Application,
  DataScout,
} from '../../TrustPortal/registerApplicationMenu';

const Home = lazy(() => import('../pages/Home'));
const WelCome = lazy(() => import('../pages/Welcome'));
const DataProfile = lazy(() => import('../pages/DataProfile'));
const Faq = lazy(() => import('../pages/Faq'));
const Contact = lazy(() => import('../pages/Contact'));
const Survey = lazy(() => import('../pages/DataProfile/Survey'));

export const routes: RouteObject[] = [
  {
    path: `${Application.DataScout}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${DataScout.Welcome}/*`,
        element: <WelCome />,
      },
      {
        path: `menu/${DataScout.DataProfile}/*`,
        element: <DataProfile />,
      },
      {
        path: `menu/${DataScout.DataProfile}/:surveyAssignmentId?`,
        element: <Survey />,
      },
      {
        path: `menu/${DataScout.FAQ}/*`,
        element: <Faq />,
      },
      {
        path: `menu/${DataScout.Contact}/*`,
        element: <Contact />,
      },
    ],
  },
];
