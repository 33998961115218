import {
  GetAccountSettingsResponse, GetAccountSettingsRequestPayload, SetAccountSettingRequestPayload
} from '../../models/AccountSetting';
import { post } from '../OdataApiService';

export const GET_ACCOUNT_SETTINGS_API = '/data/GetAccountSettingsRequest';
export const SET_ACCOUNT_SETTINGS_API = '/data/SetAccountSettingsRequest';

export const AccountSettingApi = {
  async getAccountSettings(globalOnly: boolean): Promise<GetAccountSettingsResponse[]> {
    const payload: GetAccountSettingsRequestPayload = {
      GlobalOnly: globalOnly
    };
    return await post<GetAccountSettingsRequestPayload, GetAccountSettingsResponse[]>(GET_ACCOUNT_SETTINGS_API,
      payload);
  },
  async saveAccountSetting(settingKey: string, settingValue: string): Promise<void> {
    const payload: SetAccountSettingRequestPayload = {
      SettingKey: settingKey,
      SettingValue: settingValue,
    };
    return await post<SetAccountSettingRequestPayload, undefined>(SET_ACCOUNT_SETTINGS_API, payload);
  }
};
