/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props}
      fill='none'
      height='171'
      viewBox='0 0 196 171'
      width='196'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M75.4311 97.4743L72.4944 99.1821C73.3442 98.6864 73.8732 97.6367 73.8774 96.1412L76.8141 94.4333C76.81 95.9288 76.2851 96.9785 75.4311 97.4743Z' fill='#D2D2D2'/>
      <path d='M73.9897 57.1598L73.8772 96.1416C73.8689 99.1534 71.7403 100.366 69.1201 98.8534L4.728 61.6711C2.11199 60.159 -0.00830676 56.4933 2.44679e-05 53.4857L0.112495 14.5039L73.9897 57.1598Z' fill='#FAFAFA'/>
      <path d='M73.9899 57.1599L76.9267 55.452L76.8142 94.4338L73.8774 96.1375L73.9899 57.1599Z' fill='#D2D2D2'/>
      <path d='M0.113037 14.5041L3.0498 12.8004L76.927 55.4521L73.9902 57.16L0.113037 14.5041Z' fill='#D2D2D2'/>
      <path d='M1.49536 2.02837L4.43212 0.320471C5.2944 -0.179403 6.48994 -0.112753 7.80627 0.649555L4.86951 2.35746C3.55318 1.59932 2.35764 1.5285 1.49536 2.02837Z' fill='#0078D4'/>
      <path d='M73.9898 47.7254L76.9266 46.0175L76.8974 55.2985L73.9648 57.0022L73.9898 47.7254Z' fill='#005DB7'/>
      <path d='M4.86963 2.36153L7.80639 0.653625L72.1985 37.8318L69.2617 39.5397L4.86963 2.36153Z' fill='#0078D4'/>
      <path d='M69.2617 39.5393C71.8818 41.0515 73.998 44.7172 73.9896 47.7248L73.9646 57.0016L0.083252 14.3499L0.108246 5.07303C0.116577 2.06545 2.24937 0.849091 4.86538 2.36121L69.2617 39.5393Z' fill='#0078D4'/>
      <path d='M69.2617 39.5393C71.8818 41.0515 73.998 44.7172 73.9896 47.7248L73.9646 57.0016L0.083252 14.3499L0.108246 5.07303C0.116577 2.06545 2.24937 0.849091 4.86538 2.36121L69.2617 39.5393Z' fill='url(#paint0_linear_129467_1912)'/>
      <path d='M69.2617 39.5399L72.1985 37.832C74.8186 39.3441 76.9348 43.0098 76.9264 46.0174L73.9897 47.7253C73.998 44.7177 71.8819 41.052 69.2617 39.5399Z' fill='#0078D4'/>
      <path d='M194.504 116.712L191.567 118.419C192.417 117.924 192.946 116.874 192.95 115.379L195.887 113.671C195.883 115.166 195.358 116.216 194.504 116.712Z' fill='#D2D2D2'/>
      <path d='M193.063 76.3971L192.95 115.379C192.942 118.391 190.813 119.603 188.193 118.091L123.801 80.9085C121.185 79.3963 119.065 75.7306 119.073 72.723L119.185 33.7412L193.063 76.3971Z' fill='#FAFAFA'/>
      <path d='M193.063 76.3972L196 74.6893L195.887 113.671L192.95 115.375L193.063 76.3972Z' fill='#D2D2D2'/>
      <path d='M119.186 33.7414L122.123 32.0377L196 74.6894L193.063 76.3973L119.186 33.7414Z' fill='#D2D2D2'/>
      <path d='M120.568 21.2657L123.505 19.5578C124.367 19.0579 125.563 19.1246 126.879 19.8869L123.943 21.5948C122.626 20.8366 121.431 20.7658 120.568 21.2657Z' fill='#0078D4'/>
      <path d='M193.063 66.9627L196 65.2548L195.97 74.5358L193.038 76.2396L193.063 66.9627Z' fill='#005DB7'/>
      <path d='M123.943 21.5989L126.879 19.891L191.271 57.0691L188.335 58.777L123.943 21.5989Z' fill='#0078D4'/>
      <path d='M188.335 58.7766C190.955 60.2888 193.071 63.9545 193.063 66.9621L193.038 76.2389L119.156 33.5872L119.181 24.3103C119.19 21.3028 121.322 20.0864 123.938 21.5985L188.335 58.7766Z' fill='#0078D4'/>
      <path d='M188.335 58.7766C190.955 60.2888 193.071 63.9545 193.063 66.9621L193.038 76.2389L119.156 33.5872L119.181 24.3103C119.19 21.3028 121.322 20.0864 123.938 21.5985L188.335 58.7766Z' fill='url(#paint1_linear_129467_1912)'/>
      <path d='M188.335 58.7772L191.271 57.0693C193.892 58.5814 196.008 62.2472 195.999 65.2547L193.063 66.9626C193.071 63.9551 190.955 60.2893 188.335 58.7772Z' fill='#0078D4'/>
      <path d='M112.248 168.454L109.311 170.161C110.161 169.666 110.69 168.616 110.694 167.121L113.631 165.413C113.626 166.908 113.102 167.958 112.248 168.454Z' fill='#D2D2D2'/>
      <path d='M110.806 128.139L110.694 167.121C110.685 170.133 108.557 171.345 105.937 169.833L41.5444 132.65C38.9284 131.138 36.8081 127.473 36.8164 124.465L36.9289 85.4832L110.806 128.139Z' fill='#FAFAFA'/>
      <path d='M110.806 128.139L113.743 126.431L113.631 165.413L110.694 167.117L110.806 128.139Z' fill='#D2D2D2'/>
      <path d='M36.9294 85.4834L39.8662 83.7797L113.743 126.431L110.807 128.139L36.9294 85.4834Z' fill='#D2D2D2'/>
      <path d='M38.3118 73.0077L41.2485 71.2998C42.1108 70.8 43.3063 70.8666 44.6227 71.6289L41.6859 73.3368C40.3696 72.5787 39.1741 72.5079 38.3118 73.0077Z' fill='#0078D4'/>
      <path d='M110.806 118.705L113.743 116.997L113.714 126.278L110.781 127.982L110.806 118.705Z' fill='#005DB7'/>
      <path d='M41.686 73.3409L44.6228 71.633L109.015 108.811L106.078 110.519L41.686 73.3409Z' fill='#0078D4'/>
      <path d='M106.078 110.519C108.698 112.031 110.814 115.697 110.806 118.704L110.781 127.981L36.8997 85.3292L36.9247 76.0524C36.933 73.0448 39.0658 71.8285 41.6818 73.3406L106.078 110.519Z' fill='#0078D4'/>
      <path d='M106.078 110.519C108.698 112.031 110.814 115.697 110.806 118.704L110.781 127.981L36.8997 85.3292L36.9247 76.0524C36.933 73.0448 39.0658 71.8285 41.6818 73.3406L106.078 110.519Z' fill='url(#paint2_linear_129467_1912)'/>
      <path d='M106.078 110.519L109.015 108.811C111.635 110.323 113.751 113.989 113.743 116.997L110.806 118.705C110.814 115.697 108.698 112.031 106.078 110.519Z' fill='#0078D4'/>
      <defs>
        <linearGradient gradientUnits='userSpaceOnUse'
          id='paint0_linear_129467_1912'
          x1='37.0364'
          x2='37.0364'
          y1='1.71118'
          y2='57.0016'>
          <stop stopColor='#69E2FF'/>
          <stop offset='1' stopColor='white' stopOpacity='0'/>
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse'
          id='paint1_linear_129467_1912'
          x1='156.109'
          x2='156.109'
          y1='20.9485'
          y2='76.2389'>
          <stop stopColor='#69E2FF'/>
          <stop offset='1' stopColor='white' stopOpacity='0'/>
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse'
          id='paint2_linear_129467_1912'
          x1='73.8529'
          x2='73.8529'
          y1='72.6906'
          y2='127.981'>
          <stop stopColor='#69E2FF'/>
          <stop offset='1' stopColor='white' stopOpacity='0'/>
        </linearGradient>
      </defs>
    </svg>
  );
};
