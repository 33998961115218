import React from 'react';

import { SearchBox } from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

interface ServiceSelectionHeaderProps {
  keyword?: string;
  onKeywordChange?: (newValue: string) => void;
  disabled?: boolean;
}

export const ServiceSelectionHeader: React.FC<ServiceSelectionHeaderProps> = (
  props,
) => {
  const { keyword, onKeywordChange, disabled = false } = props;

  return (
    <SearchBox
      disabled={disabled}
      iconProps={{
        iconName: 'Filter'
      }}
      placeholder={intl.get(LocIds.Placeholder.TypeNameIDToFilter)}
      value={keyword}
      onChange={(_, newValue) => {
        if (onKeywordChange) {
          onKeywordChange(newValue ?? '');
        }
      }}
    />
  );
};
