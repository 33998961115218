import React from 'react';

import _ from 'lodash';

import {
  getLinkIdentifier,
  isLinkWithVisibleChildren
} from './helper';
import { Link } from './Link';
import {
  SideNavLink,
  SideNavLinkItemProps
} from './types';

import { CollapsibleMenu } from '../CollapsibleSection';

export const LinkItem = <T extends SideNavLink<T>>(
  props: SideNavLinkItemProps<T>,
): ReturnType<React.FC<SideNavLinkItemProps<T>>> => {
  const {
    link,
    canLinkWithChildrenCollapse = true,
    collapseLinksMap,
    toggleLinkCollapse,
  } = props;
  const identifier = getLinkIdentifier(link);

  if (!identifier) {
    return null;
  }

  if (!link.isVisible || !link.name) {
    return null;
  }

  const linkTitle = <Link {...props} />;

  if (_.isEmpty(link.children) || !isLinkWithVisibleChildren(link)) {
    return <div className='sideNav__linkItem' role='cell'>{ linkTitle }</div>;
  }

  const linkChildren: React.ReactElement[] = _.filter(
    link.children,
    (v: SideNavLink<T>) => v.isVisible && Boolean(v.name),
  ).map((v: SideNavLink<T>) => {
    return <LinkItem {...props} key={v.key} link={v} />;
  });

  const linkItemContent = canLinkWithChildrenCollapse ? (
    <CollapsibleMenu
      isCollapsed={collapseLinksMap[identifier]}
      title={linkTitle}
      onCollapseChange={toggleLinkCollapse.bind(null, link)}>
      { linkChildren }
    </CollapsibleMenu>
  ) : (
    <>
      { linkTitle }
      { linkChildren }
    </>
  );
  return <div className='sideNav__linkItem'>{ linkItemContent }</div>;
};
