import React, {
  useEffect, useRef, useState
} from 'react';

import {
  IObjectWithKey, Selection
} from '@fluentui/react';

import { useBoolean } from '@fluentui/react-hooks';
import _ from 'lodash';
import intl from 'react-intl-universal';


import {
  buildScheduleEnvironmentName, getIdsFromKey
} from './utils';

import { LocIds } from '../../../../../../common/Globalization/IntlEnum';
import {
  TwoColumnLayout, ViewLayout
} from '../../../../../../components';
import { ServiceDetail } from '../../../../../../models';
import { AuditEventApi } from '../../../../../../services';
import {
  notify, showErrorMsg, UseAsyncHookStatus
} from '../../../../../../utils';
import { ContactInfo } from '../../../../models';
import { useAppDispatch } from '../../redux/reducer';
import {
  clearCachedAllAuditEvents,
  fetchOnboardedConMonSchedules,
  useAssignedTos,
  useLoadingOnboardedAuditEventsStatus,
  useOnboardedAuditEvents,
  useServiceOfScheduleBoard,
} from '../../redux/serviceSlice';
import { OnboardPanel } from '../OnboardPanel';
import {
  ContinuousMonitoringTaskEnvironmentWithKey, ScheduleList
} from '../ScheduleList';
import { ScheduleListCommandBar } from '../ScheduleList/ScheduleListCommandBar';

interface IProps {
  serviceTreeId: string;
}

export const ScheduleBoard: React.FC<IProps> = ({ serviceTreeId }) => {
  const dispatch = useAppDispatch();

  const service = useServiceOfScheduleBoard();
  const onboardedAuditEvents = useOnboardedAuditEvents();
  const assignedTos = useAssignedTos();

  const asyncStatus = useLoadingOnboardedAuditEventsStatus();

  const [isOnboardPanelOpen, { setTrue: showOnboardPanel, setFalse: hideOnboardPanel } ] = useBoolean(false);

  useEffect(() => {
    dispatch(fetchOnboardedConMonSchedules({
      serviceTreeId
    }));
  }, []);

  // AuditEventGuid + TaskId + EnvironmentId
  const [selectedKeysToRemove, setSelectedKeysToRemove] = useState<string[]>([]);
  const selection = useRef(
    new Selection<IObjectWithKey | ContinuousMonitoringTaskEnvironmentWithKey>({
      onSelectionChanged: () => {
        setSelectedKeysToRemove((selection.getSelection() as ContinuousMonitoringTaskEnvironmentWithKey[]).map((item) => item.key));
      },
    }),
  ).current;

  const onDismissPanel = () => {
    hideOnboardPanel();
    dispatch(clearCachedAllAuditEvents());
  };

  const onUnselectAll = () => {
    selection.setAllSelected(false);
  };

  const onRemoveAll = async () => {
    if (selectedKeysToRemove.length > 0) {
      notify.info(intl.get(LocIds.Action.StartProcessingRequest));

      for (const uid of selectedKeysToRemove) {
        const ids = getIdsFromKey(uid);

        if (ids) {
          const { taskId, environmentId } = ids;

          try {
            await AuditEventApi.offboardServiceFromConMonScheduleAsync({
              taskId,
              environmentId,
              serviceTreeId,
            });

            notify.success(intl.get(LocIds.AuditManager.ServiceRemovedFromConMonSuccess, {
              name: buildScheduleEnvironmentName(onboardedAuditEvents ?? [], taskId, environmentId)
            }));
          } catch (e) {
            showErrorMsg(e, dispatch);
          }
        }
      }

      // When all requests are processed, sync the data once again to reflect the updates.
      dispatch(fetchOnboardedConMonSchedules({
        serviceTreeId
      }));
    }
  };

  const onAdd = async (keyNames: Record<string, string>) => {
    const allUniqueIds = Object.keys(keyNames);
    const allHaveAssignedTos = allUniqueIds.every((uid) => !_.isNil(assignedTos[uid]));

    if (allUniqueIds.length > 0 && allHaveAssignedTos) {
      notify.info(intl.get(LocIds.Action.StartProcessingRequest));

      for (const [key, name] of Object.entries(keyNames)) {
        const ids = getIdsFromKey(key);
        const assignedTo = assignedTos[key];

        if (ids && assignedTo) {
          const { taskId, environmentId } = ids;

          try {
            await AuditEventApi.onboardServiceToConMonScheduleAsync({
              taskId,
              serviceTreeId,
              environmentId,
              assignedTo: assignedTo.alias,
              assignedToAccountGuid: assignedTo.accountGuid,
            });

            notify.success(intl.get(LocIds.AuditManager.ServiceAddedToConMonSuccess, {
              name
            }));
          } catch (e) {
            showErrorMsg(e, dispatch);
          }
        }
      }

      // When all requests are processed, sync the data once again to reflect the updates.
      dispatch(fetchOnboardedConMonSchedules({
        serviceTreeId
      }));
    }
  };

  const commandbar = (
    <ScheduleListCommandBar
      openOnboardPanel={showOnboardPanel}
      selectedCount={selectedKeysToRemove.length}
      unloadAll={onRemoveAll}
      unselectAll={onUnselectAll}
    />
  );

  const bodyJsx = (
    <ViewLayout
      emptyMessage={intl.get(LocIds.ServiceWorkspace.NoOnboardedSchedules)}
      isEmpty={_.isEmpty(onboardedAuditEvents)}
      isError={asyncStatus === UseAsyncHookStatus.Fail}
      isLoaded={asyncStatus === UseAsyncHookStatus.Success}>
      <ScheduleList
        auditEvents={onboardedAuditEvents ?? []}
        selection={selection}
      />
    </ViewLayout>
  );

  const panelJsx = service && (
    <OnboardPanel
      defaultContact={getDefaultContact(service)}
      isOpen={isOnboardPanelOpen}
      responsiblePartyId={service?.ResponsiblePartyId}
      onConfirm={onAdd}
      onDismiss={onDismissPanel}
      // This coalescing logic is the same with the one for ResponsiblePartyForAudit.sql at backend. Should keep the same.
    />
  );

  return (
    <TwoColumnLayout onRenderHeader={() => commandbar}>
      { bodyJsx }
      { panelJsx }
    </TwoColumnLayout>
  );
};

const getDefaultContact = (service: ServiceDetail): ContactInfo | undefined => {
  if (service.PointOfContact && service.PointOfContactAccountGuid) {
    return {
      alias: service.PointOfContact,
      accountGuid: service.PointOfContactAccountGuid,
    };
  }

  return undefined;
};
