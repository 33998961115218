import React, {
  useRef,
  useState,
  useEffect
} from 'react';

import {
  DefaultButton,
  IButton,
  Icon,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import classnames from 'classnames';
import _ from 'lodash';
import intl from 'react-intl-universal';

import { EventNames } from './constant';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { applicationInsightsService } from '../../services';

import { bytesToSize } from '../../utils';

type FileDropZoneProps = {
  // max file size in bytes
  maxFileSize?: number;
  isFileUploading: boolean;
  fileUploadError: string;
  onUploadFile: (file: File) => Promise<void>;
  setFileUploadError: (error: string) => void;
};

export const FileDropZone: React.FC<FileDropZoneProps> = (
  props: FileDropZoneProps,
) => {
  const { onUploadFile, isFileUploading, fileUploadError, setFileUploadError } =
    props;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const errorMessageRef = useRef<HTMLParagraphElement>(null);
  const [isFocus, setIsFocus] = useState(false);

  const promptBtn = useRef<IButton>(null);
  const focusPromptBtn = () => {
    if (promptBtn && promptBtn.current) {
      promptBtn.current.focus();
    }
  };

  useEffect(() => {
    errorMessageRef.current?.focus();
  }, [fileUploadError]);

  const hasFileUploadError =
    _.isString(fileUploadError) && !_.isEmpty(fileUploadError.trim());

  const onDragOver = (e: React.DragEvent) => {
    focusPromptBtn();

    if (hasFileUploadError) {
      return;
    }

    e.preventDefault();

    if (!isFocus) {
      setIsFocus(true);
    }
  };

  const onDragEnter = (e: React.DragEvent) => {
    focusPromptBtn();

    if (hasFileUploadError) {
      return;
    }

    e.preventDefault();
    setIsFocus(true);
  };

  const onDragLeave = (e: React.DragEvent) => {
    if (hasFileUploadError) {
      return;
    }

    e.preventDefault();
    setIsFocus(false);
  };

  const handleUploadedFiles = (files: FileList | null) => {
    if (!files || files.length === 0) {
      return;
    }

    if (files.length !== 1) {
      setFileUploadError(intl.get(LocIds.Components.OnlyUploadOneFile));
      applicationInsightsService.trackEvent(EventNames.UploadMultipleFilesEventName, {
        fileCount: files.length
      });
      return;
    }

    const file = files[0];

    if (props.maxFileSize && file.size > props.maxFileSize) {
      setFileUploadError(
        intl.get(LocIds.Components.CannotUploadFile, {
          maxFileSize: `${bytesToSize(props.maxFileSize)}`,
        }),
      );
      return;
    }

    onUploadFile(files[0]);
  };

  const onDrop = (e: React.DragEvent) => {
    focusPromptBtn();

    if (hasFileUploadError) {
      return;
    }

    e.preventDefault();

    const files = e.dataTransfer.files;
    handleUploadedFiles(files);

    setIsFocus(false);
  };

  const onFileInputClick = () => {
    focusPromptBtn();

    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onInputFilesChange = () => {
    if (fileInputRef && fileInputRef.current) {
      const inputFiles = fileInputRef.current.files;
      handleUploadedFiles(inputFiles);
    }
  };

  const dropZoneCls = classnames('fileUploader__dropZone', {
    'fileUploader__dropZone--focus': isFocus,
    'fileUploader__dropZone--error': hasFileUploadError,
  });

  const fileUploadingSpinner = (
    <Spinner
      label={intl.get(LocIds.Label.Uploading)}
      size={SpinnerSize.medium}
    />
  );

  const fileUploadPrompt = (
    <>
      <DefaultButton
        className='fileUploader__dropZonePromptBtn'
        componentRef={promptBtn}
        iconProps={{
          iconName: 'Upload'
        }}
        title={intl.get(LocIds.Components.UploadHint)}>
        { intl.get(LocIds.Components.UploadHint) }
      </DefaultButton>
      <input
        className='fileUploader__dropZoneInput'
        multiple
        ref={fileInputRef}
        type='file'
        onChange={onInputFilesChange}
      />
    </>
  );

  const onClickRetryBtn = () => {
    setFileUploadError('');
  };

  const fileUploadErrorContent = (
    <>
      <Icon className='fileUploader__errorIcon' iconName='ErrorBadge' />
      <p aria-label={fileUploadError}
        className='fileUploader__errorText'
        ref={errorMessageRef}
        tabIndex={0}
        title={fileUploadError}>
        { fileUploadError }
      </p>
      <DefaultButton
        aria-label={intl.get(LocIds.Components.RetryUpload)}
        className='fileUploader__errorBtn'
        tabIndex={0}
        title={intl.get(LocIds.Components.RetryUpload)}
        onClick={onClickRetryBtn}>
        { intl.get(LocIds.Components.RetryUpload) }
      </DefaultButton>
    </>
  );

  return (
    <div
      className={dropZoneCls}
      onClick={onFileInputClick}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}>
      { hasFileUploadError ?
        fileUploadErrorContent :
        isFileUploading ?
          fileUploadingSpinner :
          fileUploadPrompt }
    </div>
  );
};
