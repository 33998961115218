import * as lcid from 'lcid';
import { UAParser } from 'ua-parser-js';

import { IFeedbackTelemetry } from './FeedbackTypes';

export const getOcvTelemetryInfo = ():IFeedbackTelemetry => {
  const res: IFeedbackTelemetry = {};
  res.osUserLang = lcid.to(navigator.language);
  res.platform = navigator.platform;
  const userAgent = UAParser(navigator.userAgent);
  res.browser = userAgent.browser.name;
  res.browserVersion = userAgent.browser.version;
  res.deviceType = userAgent.device.type;
  res.osBuild = `${userAgent.os.name} ${userAgent.os.version}`;
  // eslint-disable-next-line i18next/no-literal-string
  res.audience = 'Microsoft';
  // eslint-disable-next-line i18next/no-literal-string
  res.audienceGroup = 'Microsoft';
  return res;
};
