import React from 'react';

import intl from 'react-intl-universal';

import { useNavigate } from 'react-router-dom';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  Banner, IBannerProps
} from '../Banner';

interface INoPermissionProps extends Pick<IBannerProps, 'headingStyle'>{
  message: string;
  subheading?: React.ReactNode;
  showReturnHome?: boolean;
}

export const NoPermission: React.FC<INoPermissionProps> = (props) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/');
  };

  return (
    <Banner
      heading={props.message}
      headingStyle={props.headingStyle}
      iconName='restricted'
      iconSize='large'
      primaryText={props.showReturnHome ? intl.get(LocIds.Action.ReturnToHome) : undefined}
      subheading={props.subheading}
      onPrimaryClick={onClick}
    />
  );
};
