import React from 'react';

import { ActionButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { FCC } from '../../models';
import { Dialog } from '../Dialog';
import { TrustPanel } from '../TrustPanel';

type BaseFilterPanelProps = {
  isOpen: boolean;
  isChanged: boolean;
  hidePanel: () => void;
  onClear: () => void;
  onApply: () => void;
};

export const BaseFilterPanel: FCC<BaseFilterPanelProps> = (props) => {
  const { hidePanel } = props;

  const [isDialogVisible, { setTrue: showDialog, setFalse: hideDialog }] =
    useBoolean(false);

  const onDismissPanel = () => {
    if (props.isChanged) {
      showDialog();
    } else {
      hidePanel();
    }
  };

  const onDiscard = () => {
    hideDialog();
    hidePanel();
  };

  const onApply = () => {
    props.onApply();
    hidePanel();
    hideDialog();
  };

  return (
    <div>
      <TrustPanel
        headerText={intl.get(LocIds.Label.Filters)}
        isOpen={props.isOpen}
        primaryActionProps={{
          text: intl.get(LocIds.Action.Apply),
          onClick: onApply,
        }}
        onDismiss={onDismissPanel}>
        <ActionButton
          iconProps={{
            iconName: 'Cancel'
          }}
          text={intl.get(LocIds.Action.ClearFilters)}
          onClick={props.onClear}
        />
        { props.children }
      </TrustPanel>
      <Dialog
        hidden={!isDialogVisible}
        primaryButtonProps={{
          text: intl.get(LocIds.Action.Discard),
          onClick: onDiscard
        }}
        subText={intl.get(LocIds.Components.ChangesNotAppliedDescription)}
        title={intl.get(LocIds.Components.DiscardChangesTitle)}
        onDismiss={hideDialog}
      />
    </div>
  );
};
