import React, {
  ComponentType,
  ReactNode,
  useCallback,
  useState
} from 'react';

import { IModalProps } from '@fluentui/react/lib/Modal';
import _ from 'lodash';

import {
  CloseModalFn,
  ModalProps,
  ModalState,
  ModalStates,
  ModalContext,
  OpenModalFn,
} from './ModalContext';
import { ModalWrapper } from './ModalWrapper';

/**
 * A React context provider to manage all Modals
 *
 * For child component, use `openModal` function returned from `useModal` hook to open a Modal
 * e.g. function ChildFunctionComponent() {
 *        const { openModal } = useModal();
 *        const onClick = () => {
 *          openModal(UserProfile, { name: "Mike"});
 *        }
 *        return <button onClick={onClick}>open</button>
 *      }
 */
export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [modalStates, setModalStates] = useState<ModalStates>([]);

  const closeModal: CloseModalFn = useCallback((id: string) => {
    setModalStates((prevState: ModalStates) => {
      const index = prevState.findIndex(
        (value: ModalState & { id: string }) => value.id === id,
      );

      if (index === -1) {
        return prevState;
      }

      return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
    });
  }, []);

  const openModal = useCallback(
    (
      component: ComponentType,
      props?: ModalProps,
      modalProps?: Omit<IModalProps, 'isOpen'>,
    ) => {
      const id = _.uniqueId('modal');
      setModalStates((prevState: ModalStates) => {
        const newModal = {
          component,
          modalProps,
          props,
          id,
        };
        return [...prevState, newModal];
      });
      return {
        closeModal: closeModal.bind(null, id)
      };
    },
    [closeModal],
  ) as OpenModalFn;

  const renderModals = () => {
    return modalStates.map((value: ModalState & { id: string }) => {
      const Component = value.component as React.FC;
      const modalProps: IModalProps = {
        ...(value.modalProps || {}),
        onDismiss: () => closeModal(value.id),
      };
      return (
        <ModalWrapper key={value.id} modalProps={modalProps}>
          <Component {...value.props} />
        </ModalWrapper>
      );
    });
  };

  return (
    <ModalContext.Provider
      value={{
        modalStates,
        openModal,
        closeModal,
      }}>
      { children }
      { renderModals() }
    </ModalContext.Provider>
  );
};
