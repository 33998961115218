import { Guid } from 'odata-query';

import {
  MockAuditAdditionalApprovalDetails,
  SOCAdditionalApproverDetails,
  IMockAuditFAQGroupContents,
  IMockAuditFAQViewContents,
  ISOCServiceDetails,
  WorkLoadServiceDetails,
  MockAuditPeriod,
  ServiceContactDetails,
  ExistingServiceQuestionnaire,
  Questionnaire,
  Response,
  AdhocDataEntryDetails,
  CloneServiceDetails,
} from '../../models';

import {
  get, post
} from '../OdataApiService';

import { buildODataQuery } from '../utils/odataQueryBuilder';

export const GET_MOCK_AUDIT_QUESTIONNARIE_API =
  '/data/MockAuditQuestionnaireDetailsView';
export const GET_MOCK_AUDIT_RESPONSES_API = '/data/MockAuditResponses';
export const GET_MOCK_AUDIT_FAQ_API = '/data/MockAuditFAQView';
export const GET_MOCK_AUDIT_ServiceQUESTIONNARIE_API =
  '/data/MockAuditLandingView';
export const GET_MOCK_AUDIT_ADDITIONAL_APPROVER_API =
  '/data/MockAuditAdditionalApproverDetailsView';
export const UPDATE_MOCK_AUDIT_ADDITIONAL_APPROVERS_API =
  '/data/UpdateMockAuditAdditionalApproversRequest';
export const GET_MOCK_AUDIT_YEAR_QUARTER_DETAILS_API = 'data/MockAuditPeriod';
export const GET_MOCK_AUDIT_SERVICE_CONTACT_DETAILS_API =
  'data/ServiceContactView';
export const GET_MOCK_AUDIT_WORKLOADS_SERVICES_API =
  'data/MockAuditWorkloadServiceView';
export const GET_MOCK_AUDIT_SERVICE_QUESTIONNAIRE_API =
  'data/MockAuditServiceQuestionnaireDetailsView';
export const POST_ADHOC_QUESTIONNAIRE_RESPONSE_API =
  '/data/AdhocQuestionnaireResponseRequest';
export const CLONE_MOCK_AUDIT_SERVICE_API =
  '/data/CloneQuestionnaireResponseRequest';

export namespace MockAuditApi {
  export const getSocQuestionnaireAsync = async (): Promise<
    Questionnaire[]
  > => {
    const data = await get<Questionnaire[]>(GET_MOCK_AUDIT_QUESTIONNARIE_API);
    return data;
  };

  export const getSocResponsesAsync = async (): Promise<Response[]> => {
    const data = await get<Response[]>(GET_MOCK_AUDIT_RESPONSES_API);
    return data;
  };

  export const getMockAuditFAQAsync = async (): Promise<
    IMockAuditFAQViewContents[]
  > => {
    const data = await get<IMockAuditFAQViewContents[]>(GET_MOCK_AUDIT_FAQ_API);
    return data;
  };

  export const getMockAuditFAQGroupAsync = async (): Promise<
    IMockAuditFAQGroupContents[]
  > => {
    const data = await get<IMockAuditFAQGroupContents[]>(
      GET_MOCK_AUDIT_FAQ_API
    );
    return data;
  };

  export const getSocServicesQuestionnaireAsync = async (): Promise<
    ISOCServiceDetails[]
  > => {
    const data = await get<ISOCServiceDetails[]>(
      GET_MOCK_AUDIT_ServiceQUESTIONNARIE_API
    );

    return data;
  };

  export const getSocAdditionalApproverDetailsAsync = async (): Promise<
    SOCAdditionalApproverDetails[]
  > => {
    const response = await get<SOCAdditionalApproverDetails[]>(
      GET_MOCK_AUDIT_ADDITIONAL_APPROVER_API
    );
    return response;
  };

  export const getMockAuditServiceApproverDetailsAsync = async (
    serviceId: number
  ): Promise<SOCAdditionalApproverDetails[]> => {
    const odataQuery = buildODataQuery({
      filter: {
        MockAuditServiceId: serviceId,
      },
    });

    const config = {
      odataQuery,
    };

    const response = await get<SOCAdditionalApproverDetails[]>(
      GET_MOCK_AUDIT_ADDITIONAL_APPROVER_API,
      config
    );
    return response;
  };

  export const updateMockAuditAdditionalApproversAsync = async (
    mockAuditAdditionalApprovalDetails: MockAuditAdditionalApprovalDetails
  ): Promise<void> => {
    return await post<MockAuditAdditionalApprovalDetails, void>(
      UPDATE_MOCK_AUDIT_ADDITIONAL_APPROVERS_API,
      mockAuditAdditionalApprovalDetails
    );
  };

  export const getMockAuditYearQuarterDetailsAsync = async (): Promise<
    MockAuditPeriod[]
  > => {
    const data = await get<MockAuditPeriod[]>(
      GET_MOCK_AUDIT_YEAR_QUARTER_DETAILS_API
    );

    return data;
  };

  export const getServiceContactDetails = async (
    serviceTreeId: Guid
  ): Promise<ServiceContactDetails[]> => {
    const odataQuery = buildODataQuery({
      filter: {
        ServiceTreeId: serviceTreeId,
      },
    });

    const config = {
      odataQuery,
    };

    const data = await get<ServiceContactDetails[]>(
      GET_MOCK_AUDIT_SERVICE_CONTACT_DETAILS_API,
      config
    );

    return data;
  };

  export const getWorkloadsServices = async (): Promise<
    WorkLoadServiceDetails[]
  > => {
    const data = await get<WorkLoadServiceDetails[]>(
      GET_MOCK_AUDIT_WORKLOADS_SERVICES_API
    );

    return data;
  };

  export const getExistingServiceQuestionnaireDetails = async (
    serviceIds: number[],
    periodId: number
  ): Promise<ExistingServiceQuestionnaire[]> => {
    const odataQuery = buildODataQuery({
      filter: {
        ServiceId: {
          in: serviceIds,
        },
        PeriodId: periodId,
      },
    });

    const config = {
      odataQuery,
    };

    return await get<ExistingServiceQuestionnaire[]>(
      GET_MOCK_AUDIT_SERVICE_QUESTIONNAIRE_API,
      config
    );
  };

  export const addNewServiceDataEntryAsync = async (
    adhocDataEntryDetails: AdhocDataEntryDetails
  ): Promise<void> => {
    return await post<AdhocDataEntryDetails, void>(
      POST_ADHOC_QUESTIONNAIRE_RESPONSE_API,
      adhocDataEntryDetails
    );
  };

  export const cloneServicesDetails = async (
    cloneServices: CloneServiceDetails
  ): Promise<number[]> => {
    const data = await post<CloneServiceDetails, number[]>(
      CLONE_MOCK_AUDIT_SERVICE_API,
      cloneServices
    );

    return data;
  };
}
