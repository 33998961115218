import {
  DependencyList, useEffect, useState
} from 'react';

export function useLocalStorage<T>(key: string, initialValue?: T, deps: DependencyList | undefined = []): [T | null, (x: T | null) => void] {
  const getValueFromLocalStorage = (): any => {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue || null;
  };

  const [value, setValue] = useState(() => {
    return getValueFromLocalStorage();
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  useEffect(() => {
    setValue(getValueFromLocalStorage());
  }, deps);

  return [value, setValue];
}

export enum LocalStorageKeys {
  AuditReviewAuditEventId = 'filter/auditReview/auditEventId',
  AuditReviewEvidencePageFilters = 'filter/auditReview/evidence',
  AuditReviewEvidencePageStatusFilters = 'filter/auditReview/evidenceStatus',
  AuditReviewEvidenceSortBy = 'filter/auditReview/evidenceRequests/sortBy',
  ServiceWorkspaceEvidenceFilters = 'ServiceWorkspaceEvidenceFilters',
  ServiceWorkspaceEvidenceSortBy = 'ServiceWorkspaceEvidenceSortBy',
  ServiceWorkspaceEvidenceKeyword = 'ServiceWorkspaceEvidenceKeyword',
  ServiceWorkspaceServiceKeyword = 'ServiceWorkspaceServiceKeyword',
  AsideWidthSharedKeyword = 'shared'
}
