import './style.less';

import React, {
  useEffect,
  useState
} from 'react';

import {
  DefaultPalette,
  Icon,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import _ from 'lodash';
import intl from 'react-intl-universal';


import { FileTileItem } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { getExtensionFromFileName } from '../../utils';
import { TooltipIconButton } from '../TooltipIconButton';

const nameIconStyles = {
  root: {
    width: 12,
    height: 12,
    marginLeft: 5
  },
  icon: {
    fontSize: 10
  }
};

export const FileTile: React.FC<FileTileItem> = (props) => {
  const { id, name, description, onDownload, onDelete, isDownloading, isProtected, isDangerous } = props;

  const [style, setStyle] = useState({
    display: 'none'
  });

  useEffect(() => {
    if (isDownloading) {
      setStyle({
        display: 'block'
      });
    }
  }, [isDownloading]);

  const showDownloadIcon = !isDownloading && _.isFunction(onDownload);
  const showDeleteIcon = _.isFunction(onDelete);
  const showLoadingIcon = isDownloading;

  return (
    <Stack
      className='fileTile'
      horizontal
      horizontalAlign='space-between'
      key={id}
      tokens={{
        childrenGap: 6
      }}
      verticalAlign='center'
      onMouseEnter={() => setStyle({
        display: 'block'
      })}
      onMouseLeave={() => {
        if (!isDownloading) {
          setStyle({
            display: 'none'
          });
        }
      }}>
      <Stack.Item className='fileTile-fileIcon' disableShrink>
        <Icon
          {...getFileTypeIconProps({
            size: 32,
            extension: getExtensionFromFileName(name),
          })}
        />
      </Stack.Item>
      <Stack.Item className='fileTile-content' grow>
        <Stack>
          <Text className='fileTile-fileName title-12'>{ name }</Text>
          <Stack horizontal
            style={{
              alignItems: 'center'
            }}>
            <Text className='fileTile-fileDescription text-12'>{ description }</Text>
            { isProtected && (
              <TooltipIconButton
                ariaLabel={intl.get(LocIds.Components.ProtectedFile)}
                iconProps={{
                  iconName: 'Shield'
                }}
                styles={nameIconStyles}
                title={intl.get(LocIds.Components.ProtectedFile)}
              />
            ) }
            { isDangerous && (
              <TooltipIconButton
                ariaLabel={intl.get(LocIds.Components.NotProtectedFile)}
                iconProps={{
                  iconName: 'ShieldAlert'
                }}
                styles={{
                  ...nameIconStyles,
                  icon: {
                    ...nameIconStyles.icon,
                    color: DefaultPalette.red
                  }
                }}
                title={intl.get(LocIds.Components.NotProtectedFile)}
              />
            ) }
          </Stack>
        </Stack>
      </Stack.Item>
      <Stack.Item disableShrink style={style}>
        <Stack horizontal>
          { showLoadingIcon && (
            <Spinner size={SpinnerSize.xSmall}
              style={{
                marginRight: 10
              }}
            />
          ) }
          { showDownloadIcon && (
            <TooltipIconButton
              ariaLabel={intl.get(LocIds.Components.DownloadFile, {
                name: `${name}`,
              })}
              iconProps={{
                iconName: 'Download'
              }}
              title={intl.get(LocIds.Components.DownloadFile, {
                name: `${name}`,
              })}
              onClick={onDownload}
            />
          ) }
          { showDeleteIcon && (
            <TooltipIconButton
              ariaLabel={intl.get(LocIds.Components.RemoveTooltip, {
                title: `${name}`,
              })}
              iconProps={{
                iconName: 'Delete'
              }}
              title={intl.get(LocIds.Components.RemoveTooltip, {
                title: `${name}`,
              })}
              onClick={onDelete}
            />
          ) }
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
