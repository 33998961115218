import React from 'react';


import {
  ContextualMenu,
  DirectionalHint
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { IconButton } from '@fluentui/react/lib/Button';
import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import intl from 'react-intl-universal';


import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { IApplication } from '../../../../models';
import {
  useAppDisplayName, useAppId
} from '../../../../utils';
import { useUserAccount } from '../../../../utils/react-hooks/useUserAccount';
import {
  Application,
  Documentation
} from '../../registerApplicationMenu';
import { OpenFeedbackService } from '../../services/OpenFeedbackService';

import { CurrentVersionModal } from '../CurrentVersionModal/CurrentVersionModal';

interface IProps {
  applications: IApplication[]
}

export const HelpMenu: React.FC<IProps> = ({ applications }) => {
  const linkRef = React.useRef(null);
  const [hideContextualMenu, { toggle: toggleHideContextualMenu }] =
    useBoolean(true);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  const { applicationId } = useAppId();

  const appName = useAppDisplayName(applications, applicationId);

  const menuItems: IContextualMenuItem[] = [
    {
      key: 'policyLink',
      text: intl.get(LocIds.Application.PrivacyNotice),
      iconProps: {
        iconName: 'Redeye',
        style: {
          color: 'gray'
        }
      },
      href: 'http://aka.ms/dpn',
      target: '_blank',
    },
    {
      key: 'documentationLink',
      text: intl.get(LocIds.Application.Documentation),
      href: `/application/${Application.Documentation}/menu/${Documentation.Docs}`,
      iconProps: {
        iconName: 'Documentation',
        style: {
          color: 'gray'
        }
      },
      target: '_blank',
    },
    {
      key: 'version',
      text: intl.get(LocIds.Application.Version),
      iconProps: {
        iconName: 'PC1',
        style: {
          color: 'gray'
        }
      },
      onClick: () => {
        showModal();
      },
    },
  ];
  // don't show feedback button when app is audit review
  const account = useUserAccount();

  if (OpenFeedbackService.shouldShowFeedback(account!)) {
    menuItems.push({
      key: 'feedback',
      text: intl.get(LocIds.Application.Feedback),
      onClick: () => {
        OpenFeedbackService.updateFeedbackData(appName, account!);
      },
      iconProps: {
        iconName: 'Feedback',
        style: {
          color: 'gray'
        }
      },
    });
  }


  return (
    <>
      <div ref={linkRef}>
        <IconButton
          ariaLabel={intl.get(LocIds.Application.Help)}
          className='questionMarkIcon'
          iconProps={{
            iconName: 'Unknown'
          }}
          name={intl.get(LocIds.Application.Question)}
          title={intl.get(LocIds.Application.Help)}
          onClick={toggleHideContextualMenu}
        />
      </div>
      <div>
        <ContextualMenu
          directionalHint={DirectionalHint.bottomRightEdge}
          hidden={hideContextualMenu}
          items={menuItems}
          target={linkRef}
          onDismiss={toggleHideContextualMenu}
        />
      </div>
      <CurrentVersionModal hideModal={hideModal} isOpen={isModalOpen} />
    </>
  );
};
