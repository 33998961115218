import React from 'react';

import {
  FontIcon,
  Link
} from '@fluentui/react';
import _ from 'lodash';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

interface IProps
{
    adoUrl?: string | null;
    bugId?: number | null;
}

export const EvidenceAdoLink: React.FC<IProps> = (props) => {
  const { adoUrl, bugId } = props;

  if (_.isNil(adoUrl) || _.isNil(bugId)) {
    return null;
  }

  return (
    <Link
      aria-label={intl.get(LocIds.AuditManager.OpenAzureDevopsTask)}
      className='evidenceCard__link'
      href={adoUrl}
      target='_blank'>
      <FontIcon iconName='ado-icon' />
      { bugId }
    </Link>
  );
};
