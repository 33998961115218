/* eslint-disable i18next/no-literal-string */
import { M365ThemeKind } from '@ms/centro-hvc-loader';

import {
  FeedbackAgeGroup,
  FeedbackAuthenticationType,
  FeedbackPolicyValue,
  FeedbackUiType,
  IFeedbackInitOptions
} from './FeedbackTypes';


function onDismissPrint(isFeedbackSent?: boolean) {
  console.log('isFeedbackSent : ', isFeedbackSent);
}

function onErrorPrint(errorMessage?: string) {
  console.log('error in sending feedback : ', errorMessage);
}

function onSuccessPrint(clientFeedbackId?: string) {
  console.log('clientFeedbackId : ', clientFeedbackId);
}

export const centroClientName = 'OcvFeedbackDemoHostingApp';

export const InitialFeedbackData: IFeedbackInitOptions = {
  clientName: centroClientName,
  authenticationType: FeedbackAuthenticationType.AAD,
  ageGroup: FeedbackAgeGroup.Adult,
  callbackFunctions: {
    onDismiss: onDismissPrint,
    onSuccess: onSuccessPrint,
    onError: onErrorPrint
  },

  feedbackConfig: {
    feedbackUiType: FeedbackUiType.SidePane,
    isDisplayed: false,
    isEmailCollectionEnabled: true,
    isFileUploadEnabled: true,
    isFeedbackForumEnabled: false,
    isMyFeedbackEnabled: false,
    isScreenshotEnabled: true,
    isScreenRecordingEnabled: true,
    privacyUrl: 'http://go.microsoft.com/fwlink/?LinkId=518021',
    complianceChecks: {
      connectedExperiences: FeedbackPolicyValue.Enabled,
      policyAllowFeedback: FeedbackPolicyValue.Enabled,
      policyAllowSurvey: FeedbackPolicyValue.Enabled,
      policyAllowScreenshot: FeedbackPolicyValue.Enabled,
      policyAllowContact: FeedbackPolicyValue.Enabled,
      policyAllowContent: FeedbackPolicyValue.Enabled
    }
  },

  // sdkVersion: 'Android v 2.4', // Reserved for SDK's of other platforms
  telemetry: {
    audience: 'Microsoft',
    audienceGroup: 'TestAudienceGroup',
    flights: 'flights',
    flightSource: 'flightSource',
    fundamentalArea: 'fundamentalArea',
    installationType: 'installationType',
    isLogIncluded: true,
    isUserSubscriber: true,
    processSessionId: 'processSessionId',
    ringId: 4,
    sku: 'server',
    sourceContext: 'Source',
    diagnosticsEndPoint: 'https://diagnosticsEndPoint.com',
    diagnosticsUploadId: 'd0000000-0000-0000-0000-000000000000'
  },
  // or a predefined theme { baseTheme: M365ThemeKind.OneNoteDarkTheme }}
  themeOptions: {
    baseTheme: M365ThemeKind.M365Light,
  }
};
