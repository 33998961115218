import intl from 'react-intl-universal';
import {
  Action, Middleware
} from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { ProjectIdentifier } from '../../models';
import { applicationInsightsService } from '../../services';
import { getErrorMsg } from '../../utils/errorMsg';
import { notify } from '../../utils/notify';
import { RootReduxState } from '../reducer';

export type CommonThunkDispatch = ThunkDispatch<Action, Action, Action>;

const errorHandler = async (e: Error, productIdentifier?: ProjectIdentifier) => {
  const errorMessage = (await getErrorMsg(e)) || intl.get(LocIds.Error.SomethingWentWrong);
  notify.error(errorMessage);
  applicationInsightsService.trackException(e as Error, productIdentifier);
};

/**
 * This middleware catches errors of thunk action and automatically dispatch a showError action to show error state in UI
 */
export const errorMiddleware: Middleware<unknown, RootReduxState, CommonThunkDispatch> = ({ getState }) => next => action => {
  // Forward plain action object to next middleware
  if (typeof action !== 'function') {
    return next(action);
  }

  const applicationId = window.location.pathname.split('/')[2];
  const applications = getState().portal.config.Applications;

  // Wrap thunk action with a try catch and handle errors together
  try {
    return next(action);
  } catch (e) {
    errorHandler(e as Error, applications.find(app => app.ApplicationId === applicationId)?.ProjectIdentifier);
    throw e;
  }
};
