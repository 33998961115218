/* eslint-disable i18next/no-literal-string */
import React from 'react';
import './NotificationBar.less';

import {
  cssTransition,
  toast,
  ToastContainer
} from 'react-toastify';

export const NotificationBar = () => {

  const bodyStyle: React.CSSProperties = {
    padding: '0',
    margin: '0'
  };

  const style: React.CSSProperties = {
    top: '49px',
    width: '100%',
    padding: '0'
  };

  const toastStyle: React.CSSProperties = {
    width: '100%',
    minHeight: 'auto',
    padding: '0',
    margin: '0',
    boxShadow: 'none',
    borderRadius: '0'
  };

  const transition = cssTransition({
    enter: 'tp__notification-bar--show',
    exit: 'tp__notification-bar--hide',
  });

  return (
    <ToastContainer
      autoClose={8000}
      bodyStyle={bodyStyle}
      closeButton={false}
      closeOnClick={false}
      draggable={false}
      hideProgressBar={true}
      icon={false}
      position={toast.POSITION.TOP_CENTER}
      style={style}
      toastStyle={toastStyle}
      transition={transition}
    />
  );
};
