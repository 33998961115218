import { Location } from 'history';
import { useLocation } from 'react-router';

interface IUseAppId extends Location {
  applicationId: string;
  menuId: string;
}

export const useAppId = (): IUseAppId => {
  const location = useLocation();
  const tmp = location.pathname.split('/');
  return {
    ...location,
    applicationId: tmp[2] || '',
    menuId: tmp[4] || ''
  };
};
