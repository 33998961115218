import './style.less';

import React from 'react';

import { Spinner } from '@fluentui/react';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { FCC } from '../../models';
import { Banner } from '../Banner';

type IProps = {
  isLoaded: boolean;
  isError: boolean;
  isEmpty?: boolean;
  isNotFound?: boolean;
  errorMessage?: string;
  emptyMessage?: string;
  notFoundMessage?: string;
}

export const ViewLayout: FCC<IProps> = (props) => {
  const {
    isLoaded,
    isError,
    isEmpty = false,
    isNotFound = false,
    errorMessage = intl.get(LocIds.Error.ContactOncall),
    emptyMessage = intl.get(LocIds.Placeholder.NoItems),
    notFoundMessage = intl.get(LocIds.Error.NoContentOrPermission),
    children
  } = props;

  let bodyJsx;

  if (!isLoaded) {
    bodyJsx = <Spinner className='rootSpinner' label={intl.get(LocIds.Placeholder.Loading)} />;
  } else if (isError) {
    bodyJsx = <Banner heading={errorMessage} iconName='crash' iconSize='large' />;
  } else if (isNotFound) {
    bodyJsx = <Banner heading={notFoundMessage} iconName='not-found' iconSize='large' />;
  } else if (isEmpty) {
    bodyJsx = <Banner heading={emptyMessage} iconName='empty' iconSize='large' />;
  } else {
    bodyJsx = children;
  }

  return (
    <div className='viewLayout' >
      { bodyJsx }
    </div>
  );
};
