import React from 'react';

import {
  MessageBar,
  MessageBarType
} from '@fluentui/react';

interface IProps {
    showProcessingMessage: boolean;
    processingMessage: string;
    showErrorMessage: boolean;
    errorMessage: string;
    showErrorDismiss?: () => void;
}

export const TrustPanelMessage: React.FC<IProps> = (props) => {
  const { showProcessingMessage, processingMessage, showErrorMessage, errorMessage, showErrorDismiss } = props;

  return (
    <>
      { showErrorMessage && (
        <MessageBar messageBarType={MessageBarType.error}
          onDismiss={showErrorDismiss}>
          { errorMessage }
        </MessageBar>
      ) }
      { showProcessingMessage && (
        <MessageBar
          messageBarType={MessageBarType.info}>
          { processingMessage }
        </MessageBar>
      ) }
    </>
  );
};
