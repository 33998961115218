import React from 'react';

import './Banner.less';

import {
  BannerImage,
  BannerImageIconSize,
  BannerImageIconName
} from './BannerImage';

import { M365PrimaryButton } from '../M365Button';

export interface IProps {
    iconName?: BannerImageIconName;
    iconSize?: BannerImageIconSize;
    heading?: React.ReactNode;
    subheading?: React.ReactNode;
    /** Primary button */
    primaryText?: string;
    onPrimaryClick?: () => void;
    headingStyle?: React.CSSProperties;
}


export const Banner = ({ iconName,
  heading,
  subheading,
  primaryText,
  onPrimaryClick,
  iconSize = 'small',
  headingStyle = {} }: IProps) => {
  return (
    <div className='banner__wrapper'>
      <div className='banner'>
        { !!iconName && (
          <div className='banner__icon' role='presentation'>
            <BannerImage iconName={iconName} iconSize={iconSize}/>
          </div>
        ) }
        { !!heading && (
          <h3 className='banner__heading title-16' style={headingStyle}>
            { heading }
          </h3>
        ) }
        { !!subheading && (
          <p className='banner__subheading text-14'>
            { subheading }
          </p>
        ) }
        { !!primaryText && (
          <div className='banner__actions'>
            { !!primaryText && (
              <M365PrimaryButton text={primaryText} onClick={onPrimaryClick}/>
            ) }
          </div>
        ) }
      </div>
    </div>
  );
};
