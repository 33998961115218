import {
  utcToZonedTime,
  format
} from 'date-fns-tz';

export function dateToLocalString(date:string|Date):string {
  return format(utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone), 'yyyy-MM-dd HH:mm:ss zzz', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });
}
