import React from 'react';

import {
  Shimmer,
  ShimmerElementType,
  Stack
} from '@fluentui/react';

const SHIMMER_COUNT = 2;

const childShimmer = [
  {
    type: ShimmerElementType.gap,
    width: '10%'
  },
  {
    type: ShimmerElementType.line,
    width: '70%',
  },
  {
    type: ShimmerElementType.gap,
    width: '20%'
  },
];

export const EvidenceIndexTreeShimmer: React.FC = () => {
  return (
    <Stack style={{
      marginTop: 5
    }}
    tokens={{
      childrenGap: 28
    }}>
      { Array(SHIMMER_COUNT)
        .fill(1)
        .map((_, index) => {
          return (
            <Stack key={index}
              tokens={{
                childrenGap: 6
              }}>
              <Shimmer width='80%' />
              <Shimmer shimmerElements={childShimmer} />
              <Shimmer shimmerElements={childShimmer} />
            </Stack>
          );
        }) }
    </Stack>
  );
};
