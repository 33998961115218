import React from 'react';

import { buildDescription } from './utils';

import { AuditEventAttachment } from '../../models';
import { downloadBlobAsync } from '../../services';
import { FileTileGroup } from '../FileTile';

export type AuditEventAttachmentListProps = {
  Attachments: AuditEventAttachment[];
};

export const AuditEventAttachmentList: React.FC<AuditEventAttachmentListProps> =
  (props) => {
    const onDownloadAttachment = async (attachment: AuditEventAttachment) => {
      await downloadBlobAsync(
        attachment.DownloadName || attachment.DownloadUrl,
        attachment.DisplayName || attachment.FileName,
      );
    };

    return (
      <div className='attachmentList'>
        <FileTileGroup
          items={props.Attachments.map((a) => ({
            id: a.BlobGuid || a.AttachmentGuid,
            name: a.DisplayName || a.FileName,
            description: buildDescription(a.DateAttached, a.RetainedUntil),
            onDownload: onDownloadAttachment.bind(null, a),
          }))}
        />
      </div>
    );
  };
