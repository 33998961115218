import _ from 'lodash';

const ENCODING = 'utf-8';
const FILENAME_REGEX = new RegExp(`filename\\*?=\\s*"?(${ENCODING}''([^";]*)|([^";]*))"?`, 'i');

/**
 * Parse filename from Content-Disposition header
 * @param header Content-Disposition response header, @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
 * @returns Filename extracted from the header
 */
export const parseFilenameFromContentDispositionHeader = (header: string | null) : string | undefined => {
  if (!header) {
    return undefined;
  }

  const matches = FILENAME_REGEX.exec(header);

  if (_.isNil(matches)) {
    return undefined;
  }

  const filenameEncoded = matches[2] || matches[3];

  let filenameDecoded: string | undefined = undefined;

  try {
    filenameDecoded = decodeURIComponent(filenameEncoded);
  } catch {
    filenameDecoded = undefined;
  }

  return filenameDecoded;
};
