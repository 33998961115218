import './ApplicationHeader.less';
import * as React from 'react';

import intl from 'react-intl-universal';
import { NavLink } from 'react-router-dom';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { IApplication } from '../../../../models';
import { portalConfigurationService } from '../../../../services';
import { useAppId } from '../../../../utils';
import {
  useNetworkRestrictionNotification, useNetworkStatusNotification
} from '../../hooks';
import { ApplicationMenu } from '../ApplicationMenu';
import { FeedbackButton } from '../FeedbackButton';
import { HelpMenu } from '../HelpMenu';
import { Logo } from '../Logo';
import { Menus } from '../Menus';
import { NotificationBell } from '../NotificationBell';
import { SettingsButton } from '../SettingsButton/SettingsButton';
import { UserIndicator } from '../UserIndicator';

interface IProps {
  applications: IApplication[];
}

export const ApplicationHeader: React.FC<IProps> = ({ applications }: IProps) => {
  const { applicationId, menuId } = useAppId();

  const currentApplication = applications.find((app: IApplication) => app.ApplicationId === applicationId);
  const currentApplicationName = currentApplication?.DisplayName ?? intl.get(LocIds.Label.TrustPortal);

  const currentApplicationMenus = currentApplication?.Menus?.filter(m => !m.Hide) ?? [];

  useNetworkRestrictionNotification(currentApplication, menuId);
  useNetworkStatusNotification(currentApplication, menuId);

  return (
    <header className='tp-header'>
      <div className='tp-header__inner'>
        <div className='tp-header__left'>
          <Logo />
          <div className='tp-header__separator' />
          <NavLink
            aria-label={currentApplicationName}
            className='title-18 tp-header__title'
            title={currentApplicationName}
            to={`/application/${currentApplication?.ApplicationId ?? ''}`}>
            <span>{ currentApplicationName }</span>
          </NavLink>
        </div>
        <Menus key={applicationId} menus={currentApplicationMenus} />
        <div className='tp-header__right'>
          <ApplicationMenu applications={applications} />
          { portalConfigurationService.getPortalFlightings().enableMessaging && (
            <NotificationBell />
          ) }
          { portalConfigurationService.getPortalFlightings().enableCoachmarks && (
            <SettingsButton/>
          ) }
          <HelpMenu applications={applications} />
          <FeedbackButton applications={applications} />
          <UserIndicator />
        </div>
      </div>
    </header>
  );
};
