export type FilterGroup<All, Selected> = {
    all: All;
    selected: Selected;
};

export enum DueDateOption {
    Overdue12Months,
    Overdue6Months,
    Overdue3Months,
    Overdue30Days,
    Upcoming30Days,
    Upcoming3Months,
    Upcoming6Months,
    Upcoming12Months,
}

export enum CreatedDateOption {
    Last30Days,
    Last3Months,
    Last6Months,
    Last12Months,
    Last18Months,
    Last2Years,
    Last3Years
}
