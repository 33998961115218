
import React from 'react';

import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { getDefaultCollapseLinkMap } from './helper';


import { useDocPath } from './hooks/useDocPath';
import { useDocUid } from './hooks/useDocUid';

import { SideNav } from '../../../../components/SideNav';
import { SideNavLink } from '../../../../components/SideNav/types';
import { DocTreeNode } from '../../../../models/DocTreeNode/DocTreeNode';
import { DocTreeRootNode } from '../../../../models/DocTreeNode/DocTreeRootNode';
import {
  Application,
  Documentation
} from '../../../TrustPortal/registerApplicationMenu';

interface IProps {
    menuTree: DocTreeRootNode,
    validateDocUid: Set<string>
}
export const DocMenu: React.FC<IProps> = ({ menuTree, validateDocUid }) => {
  const navigate = useNavigate();
  const docUid = useDocUid();
  const docPath = useDocPath();

  if (_.isEmpty(docUid) && _.isEmpty(docPath)) {
    navigate(`/application/${Application.Documentation}/menu/${Documentation.Docs}/uid/welcome`);
  }

  const defaultCollapseLinksMap = getDefaultCollapseLinkMap(menuTree.children);

  const onClick = (link: SideNavLink<DocTreeNode>) => {
    const node = link as DocTreeNode;

    if (!_.isEmpty(node.uid) && validateDocUid.has(node.uid)) {
      const path = `/application/${Application.Documentation}/menu/${Documentation.Docs}/uid/${node.uid}`;
      navigate(path);
    }

  };

  const isLinkActive = (link: SideNavLink<DocTreeNode>) => {
    return (link as DocTreeNode).uid === docUid;
  };

  return (
    <SideNav<DocTreeNode>
      defaultCollapseLinksMap={defaultCollapseLinksMap}
      isLinkActive={isLinkActive}
      links={menuTree.children}
      onLinkClick={onClick}
    />
  );
};
