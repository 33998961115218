import {
  ComponentType,
  createContext
} from 'react';

import { IModalProps } from '@fluentui/react/lib/Modal';

export type ModalProps = {
  [key: string]: unknown;
};

export type ModalState = {
  component: ComponentType;
  props?: ModalProps;
  modalProps?: Omit<IModalProps, 'isOpen'>;
};

export type ModalStates = Array<ModalState & { id: string }>;

export type CloseModalFn = (id: string) => void;

export type OpenModalFn = <T = Record<string, unknown>>(
  component: ComponentType<T>,
  props?: T,
  modalProps?: Omit<IModalProps, 'isOpen'>,
) => { closeModal: () => void };

export type ModalContextState = {
  modalStates: ModalStates;
  closeModal: CloseModalFn;
  openModal: OpenModalFn;
};

export const ModalContext = createContext<ModalContextState>({
  modalStates: [],
  closeModal: () => {},
  openModal: () => ({
    closeModal: () => {}
  }),
});
