export class FilterDocService {
  static getSearchQueryReg(userQuery: string): RegExp | null {
    if (!userQuery || userQuery.trim() === '') {
      return null;
    }

    const regLiteral = userQuery
      .split(/\s/)
      .reduce((acc: string, query: string) => {
        return acc + `(?=.*${query})`;
      }, '');
    return new RegExp(regLiteral, 'i');
  }

  static isSearchTextMatched(text: string, searchReg: RegExp | null): boolean {
    if (!(searchReg instanceof RegExp)) {
      return true;
    }

    if (!text) {
      return true;
    }

    return searchReg.test(text);
  }
}
