import React from 'react';

import {
  Stack, Text
} from '@fluentui/react';
import { isNull } from 'lodash';
import intl from 'react-intl-universal';

import { PublishedEvidenceStatusLabel } from './PublishedEvidenceStatusLabel';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  BaseEvidenceCard, EvidenceBaseCardProps
} from '../BaseEvidenceCard';
import { CardTagProps } from '../CardView';

type IProps = EvidenceBaseCardProps & { showConclusionState?: boolean };

export const SharedPublishedEvidenceCard: React.FC<IProps> = (props) => {
  const { evidence, showConclusionState } = props;

  const tagValue = (
    <PublishedEvidenceStatusLabel
      conclusionState={evidence.ConclusionState}
    />
  );

  const tagProps: CardTagProps | undefined = showConclusionState ? undefined : {
    items: [
      {
        label: intl.get(LocIds.Label.Status),
        value: tagValue,
      },
    ],
  };

  const typeLabel = (
    <Stack horizontal
      tokens={{
        childrenGap: 6
      }}>
      <Text className='evidenceCard__typeLabel--published'>
        { intl.get(LocIds.Label.Published) }
      </Text>
      { !isNull(evidence.SourceProposedEvidenceGuid) && (
        <Text className='evidenceCard__typeLabel--proposed'>
          { intl.get(LocIds.Evidence.AuditorProposed) }
        </Text>
      ) }
    </Stack>
  );

  return (
    <BaseEvidenceCard
      {...props}
      className='evidenceCard--published'
      tagProps={tagProps}
      titleLabel={typeLabel}
    />
  );
};
