/* eslint-disable @typescript-eslint/no-namespace */
/**
 * This file contains Athena Rest Apis
 */

import _ from 'lodash';
import { OdataQuery } from 'odata';
import { Guid } from 'odata-query';


import {
  AddSocService,
  AddSocWorkload,
  AuditEventService,
  AzureAdMember,
  AzureAdMemberType,
  Certification,
  CertificationControl,
  ComplianceAudit,
  ComplianceAuditWorkload,
  DeleteSocService,
  DeleteSocWorkload,
  Environment,
  EvidenceState,
  GetServiceNamesForReusePayload,
  InviteGuestUserPayload,
  MockAuditService,
  Pipeline,
  PipelineFilters,
  PipelinePayload,
  PipelineSchedule,
  RemoveUserAccessPayload,
  ResolveUserGroupObjectIdsPayload,
  SearchGroupsPayload,
  SearchGuestUsersPayload,
  SearchUserAccountDetails,
  Service,
  ServiceTreeItem,
  SocServiceDetailsView,
  SocWorkload,
  TogglePipelineStatePayload,
  UpdateSocService,
  UpdateSocWorkload,
  GetComplianceAuditServicePayload,
} from '../../models';
import { ResponsiblePartyEnvironmentMapping } from '../../models/ResponsiblePartyEnvironmentMapping';

import {
  get, post, put, remove
} from '../OdataApiService';
import {
  buildFilterString, buildODataQuery, buildOrderByString, fieldMatchOnly
} from '../utils/odataQueryBuilder';


export const GET_ENVIRONMENT_LIST_API = '/data/Environment';
export const GET_PIPELINE_LIST_API = '/data/EvidenceAutomation';
export const GET_PIPELINE_SCHEDULE_LIST_API = '/data/GetPipelineSchedules';
export const SET_PIPELINE_STATE_API = '/data/SetPipelineState';
export const REFRESH_PIPELINE_API = '/data/EvidenceRequest';
export const GET_COMPLIANCE_AUDIT_LIST_API = '/data/ComplianceAuditAdo';
export const GET_SERVICE_LIST_API = 'data/Service';
export const GET_WORKLOAD_LIST_API = 'data/ComplianceAuditWorkload';

export const GET_CERTIFICATION_LIST_FOR_AUDIT_API = '/data/CertificationFamilyForAudit';
export const GET_ENVIRONMENT_LIST_FOR_AUDIT_API = '/data/EnvironmentForAudit';
export const GET_SERVICE_LIST_FOR_AUDIT_API = '/data/ComplianceAuditServices';
export const GET_SERVICE_LIST_BY_ENVIRONMENT_ID_API = '/data/ResponsiblePartyByEnvironment';
export const GET_SERVICE_ENVIRONMENT_MAPPINGS_API = 'data/ResponsiblePartyEnvironmentMapping';

export const GET_COMPLIANCE_AUDIT_SERVICES_API = '/data/ComplianceAuditServices';

export const GET_AUDITEVENT_ENVIRONMENT_API = '/data/Environment/AuditEvent';
export const GET_AUDITEVENT_ENVIRONMENT_SERVICE_API = '/data/Service/AuditEvent';

export const GET_AUDITEVENT_AUDITORS_API = '/data/Auditors';

export const GET_CERTIFICATION_LIST_API = '/data/CertificationFamily';
export const GET_CERTIFICATION_CONTROL_LIST_API = '/data/CertificationControl';
export const GET_EVIDENCE_STATE_LIST_API = '/data/EvidenceState';

export const RESOLVE_USER_GROUP_OBJECT_IDS_API =
  '/data/ResolveUserGroupObjectIdsRequest';
export const SEARCH_GROUPS_API = '/data/SearchGroupsRequest';

export const GET_USERS_API = '/data/SearchUsersRequest';

export const INVITE_GUEST_USER_API = '/data/InviteGuestUserRequest';

export const GET_SERVICE_TREE_ITEMS_API = '/data/ServiceTree';

export const SEARCH_GUEST_USERS_API = '/data/SearchGuestUsersRequest';
export const REMOVE_USER_ACCESS_API = '/data/RemoveUserAccessRequest';

export const GET_SOC_WORKLOAD_API = '/data/SocWorkload';
export const GET_SOC_SERVICE_DETAILS_VIEW_API = '/data/SocServiceDetailsView';
export const GET_SOC_SERVICE_API = '/data/SocService';

export const ADD_SOC_SERVICE_API = '/data/AddSocService';
export const UPDATE_SOC_SERVICE_API = '/data/UpdateSocService';
export const DELETE_SOC_SERVICE_API = '/data/DeleteSocService';

export const ADD_SOC_WORKLOAD_API = '/data/AddSocWorkload';
export const UPDATE_SOC_WORKLOAD_API = '/data/UpdateSocWorkload';
export const DELETE_SOC_WORKLOAD_API = '/data/DeleteSocWorkload';

export const GET_SERVICES_FOR_REUSE_API = 'data/GetServicesForReuseRequest';

export namespace EntityApi {
  export const getServiceByServicetreeGuidAsync = async (
    serviceTreeGuid: Guid,
  ): Promise<MockAuditService> => {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(serviceTreeGuid, 'ServiceTreeId'),
    };
    const config = {
      odataQuery,
    };
    const data = await get<MockAuditService[]>(GET_SERVICE_LIST_API, config);
    return data[0];
  };

  export const getEnvironmentListAsync = async (
    orderKey = 'Name',
    isAsc = true,
  ): Promise<Environment[]> => {
    const config = {
      odataQuery: buildODataQuery({
        orderBy: `${orderKey} ${isAsc ? 'asc' : 'desc'}`,
      }),
    };
    return await get<Environment[]>(GET_ENVIRONMENT_LIST_API, config);
  };

  export const createNewEnvironmentAsync = async (
    shortName: string,
    description: string,
  ): Promise<void> => {
    return await post<{ Name: string; Description: string }, void>(
      GET_ENVIRONMENT_LIST_API,
      {
        Name: shortName,
        Description: description
      }
    );
  };

  export const updateEnvironmentAsync = async (
    env: Environment,
  ): Promise<void> => {
    return await put<Partial<Environment>, void>(
      `${GET_ENVIRONMENT_LIST_API}(${env.EnvironmentId})`,
      env
    );
  };

  export const deleteEnvironmentAsync = async (
    env: Environment,
  ): Promise<void> => {
    return await remove<void>(
      `${GET_ENVIRONMENT_LIST_API}(${env.EnvironmentId})`,
    );
  };

  export const getServiceListAsync = async (
    includeWorkload: boolean,
    responsiblePartyId?: number
  ): Promise<Service[]> => {
    const config = {
      odataQuery: buildODataQuery({
        filter: buildFilterString({
          filter: {
            ResponsiblePartyId: responsiblePartyId,
            ParentResponsiblePartyId: includeWorkload ? undefined : {
              ne: null
            },
          },
        }),
        orderBy: 'ShortName asc',
      }),
    };
    return await get<Service[]>(GET_SERVICE_LIST_API, config);
  };

// -----------------------------------------------------------------------------------------------------------------
  /** Pipelines */
  export const getPipelineListAsync = async (
    payload: PipelineFilters
  ): Promise<Pipeline[]> => {
    const config = {
      odataQuery: buildODataQuery({
        orderBy: `${payload.sortKey} ${payload.isAsc ? 'asc' : 'desc'}`,
        filter: `${payload.keyword}`
      }),
    };

    return await get<Pipeline[]>(
      GET_PIPELINE_LIST_API,
      config
    );
  };

  export const getPipelineSchedulesListAsync = async (
  ): Promise<PipelineSchedule[]> => {
    return await post<any, PipelineSchedule[]>(
      GET_PIPELINE_SCHEDULE_LIST_API, {}
    );
  };

  export const refreshPipelineAsync = async ( payload: PipelinePayload ): Promise<void> => {
    return await post<PipelinePayload, void>(
      REFRESH_PIPELINE_API,
      payload
    );
  };

  export const togglePipelineStateAsync = async ( payload: TogglePipelineStatePayload ): Promise<void> => {
    return await post<TogglePipelineStatePayload, void>(
      SET_PIPELINE_STATE_API,
      payload,
    );
  };

export const getWorkloadListAsync = async (): Promise<
  ComplianceAuditWorkload[]
> => {
  const config = {
    odataQuery: buildODataQuery({
      filter: {
        ParentResponsiblePartyId: null
      },
      orderBy: 'ShortName asc',
    }),
  };

  // transform Service to ComplianceAuditWorkload
  const services = await get<Service[]>(GET_SERVICE_LIST_API, config);
  return services.map((s) => ({
    ResponsiblePartyId: s.ResponsiblePartyId,
    ShortName: s.ShortName,
  }));
};

export const getComplianceAuditListAsync = async (
  auditEventGuid?: string,
): Promise<ComplianceAudit[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      AuditEventGuid: {
        value: auditEventGuid,
        type: 'guid'
      },
    },
  });
  return await get<ComplianceAudit[]>(GET_COMPLIANCE_AUDIT_LIST_API, {
    odataQuery,
  });
};

export const getWorkloadByIdAsync = async (
  responsiblePartyId: number,
): Promise<MockAuditService> => {
  const odataQuery: OdataQuery = {
    $filter: fieldMatchOnly(responsiblePartyId, 'ResponsiblePartyId'),
  };
  const config = {
    odataQuery,
  };
  const data = await get<MockAuditService[]>(GET_SERVICE_LIST_API, config);
  return data[0];
};

// TODO: The API is not in use now. We replace the options of the dropdown with the API to fetch full lists (for now).
export const getAuditEventEnvironmentAsync = async (
  auditEventGuid: string,
  certificationFamilyId?: number,
): Promise<Environment[]> => {
  const odataQuery: OdataQuery = {};
  const filter = buildFilterString({
    filter: {
      AuditEventGuid: auditEventGuid,
      certificationFamilyId,
    },
  });

  if (!_.isNull(filter)) {
    odataQuery.$filter = filter;
  }

  const config = {
    odataQuery,
  };

  const data = get<Environment[]>(GET_AUDITEVENT_ENVIRONMENT_API, config);
  return data;
};

// TODO: The API is not in use now. We replace the options of the dropdown with the API to fetch full lists (for now).
export const getAuditEventEnvironmentServiceAsync = async (
  auditEventGuid: string,
  environmentId?: number,
  certificationFamilyId?: number,
): Promise<AuditEventService[]> => {
  const odataQuery: OdataQuery = {};
  const filter = buildFilterString({
    filter: {
      AuditEventGuid: auditEventGuid,
      EnvironmentId: environmentId,
      CertificationFamilyId: certificationFamilyId,
    },
  });

  if (!_.isNull(filter)) {
    odataQuery.$filter = filter;
  }

  const config = {
    odataQuery,
  };

  const data = await get<AuditEventService[]>(
    GET_AUDITEVENT_ENVIRONMENT_SERVICE_API,
    config,
  );
  return data;
};

export const getAuditEventAuditorsAsync = async (
  auditEventGuid: string,
): Promise<AzureAdMember[]> => {
  const odataQuery: OdataQuery = {};
  const filter = buildFilterString({
    filter: {
      AuditEventGuid: auditEventGuid,
    },
  });

  if (!_.isNull(filter)) {
    odataQuery.$filter = filter;
  }

  const config = {
    odataQuery,
  };

  const data = await get<AzureAdMember[]>(
    GET_AUDITEVENT_AUDITORS_API,
    config,
  );
  return data;
};

export const searchUsersAndGroupsAsync = async (
  searchText: string
): Promise<AzureAdMember[]> => {
  const groups = (await searchGroupsAsync(searchText)).map(g => {
    g.type = AzureAdMemberType.Group;
    return g;
  } );
  const users = (await searchUsersStringAsync(searchText)).map(u => {
    u.type = AzureAdMemberType.User;
    return u;
  } );

  return groups.concat(users).sort((a, b) => a.DisplayName > b.DisplayName ? 1 : -1);
};

export const searchGroupsAsync = async (
  searchText: string,
): Promise<AzureAdMember[]> => {
  // const data = await get<AzureAdMember[]>(GET_GROUPS_API, config);
  const payload: SearchGroupsPayload = {
    searchOption: searchText,
    topOption: 5,
    searchOnlySecurityGroups: true
  };

  const result = await post<SearchGroupsPayload, AzureAdMember[]>(
    SEARCH_GROUPS_API,
    payload
  );

  return result;
};

export const resolveUserGroupObjectIdsAsync = async (
  objectIds: string[],
): Promise<AzureAdMember[]> => {
  if (objectIds.length > 0) {
    return await post<ResolveUserGroupObjectIdsPayload, AzureAdMember[]>(
      RESOLVE_USER_GROUP_OBJECT_IDS_API,
      {
        objectIds,
      }
    );
  }

  return [];
};

export const searchUsersAsync = async (
  searchUserAccountDetails: SearchUserAccountDetails,
): Promise<AzureAdMember[]> => {
  const response = await post<SearchUserAccountDetails, AzureAdMember[]>(
    GET_USERS_API,
    searchUserAccountDetails
  );
  return response;
};

export const searchUsersStringAsync = async (
  searchText: string,
  memberOnly = true,
  withAccountGuid = false,
): Promise<AzureAdMember[]> => {
  const payload: SearchUserAccountDetails = {
    searchUserAccountText: searchText,
    topOption: 5,
    memberOnly,
    withAccountGuid
  };

  return await post<SearchUserAccountDetails, AzureAdMember[]>(
    GET_USERS_API,
    payload
  );
};

export const inviteGuestUserAsync = async (
  invitedUserEmailAddress: string,
  customizedMessageBody: string,
): Promise<AzureAdMember> => {
  return await post<InviteGuestUserPayload, AzureAdMember>(
    INVITE_GUEST_USER_API,
    {
      invitedUserEmailAddress,
      customizedMessageBody,
    }
  );
};

export const getCertificationListAsync = async (): Promise<
  Certification[]
> => {
  const config = {
    odataQuery: buildODataQuery({
      orderBy: 'ShortName asc'
    }),
  };
  return await get<Certification[]>(GET_CERTIFICATION_LIST_API, config);
};

export const getCertificationControlListAsync = async (
  certificationFamilyId: number,
): Promise<CertificationControl[]> => {
  const odataQuery: OdataQuery = {};
  const filter = buildFilterString({
    filter: {
      CertificationFamilyId: certificationFamilyId,
    },
  });

  if (!_.isNull(filter)) {
    odataQuery.$filter = filter;
  }

  const config = {
    odataQuery,
  };

  const data = await get<CertificationControl[]>(
    GET_CERTIFICATION_CONTROL_LIST_API,
    config,
  );
  return data;
};

  export const getCertificationControlListByCertIdsAsync = async (
    certificationFamilyIds?: number[]
  ): Promise<CertificationControl[]> => {
    const odataQuery: OdataQuery = {};

    const filter = buildFilterString({
      filter: {
        CertificationFamilyId: {
          in: certificationFamilyIds
        },
      },
    });

    if (!_.isNull(filter)) {
      odataQuery.$filter = filter;
    }

    const config = {
      odataQuery,
    };

    const data = await get<CertificationControl[]>(
      GET_CERTIFICATION_CONTROL_LIST_API,
      config,
    );
    return data;
  };

export const getEvidenceStateListAsync = async (): Promise<
  EvidenceState[]
> => {
  return await get<EvidenceState[]>(GET_EVIDENCE_STATE_LIST_API);
};

export const getCertificationsByComplianceAuditIdAsync = async (
  complianceAuditId: number,
): Promise<Certification[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      ComplianceAuditId: complianceAuditId,
    },
    orderBy: 'ShortName asc',
  });

  const config = {
    odataQuery,
  };
  return await get<Certification[]>(
    GET_CERTIFICATION_LIST_FOR_AUDIT_API,
    config,
  );
};

export const getCertificationsByAuditEventGuidAsync = async (
  auditEventGuid: string,
): Promise<Certification[]> => {
  const odataQuery: OdataQuery = {
    $filter: fieldMatchOnly(
      {
        value: auditEventGuid,
        type: 'guid'
      },
      'AuditEventGuid',
    ),
    $orderby: buildOrderByString('ShortName asc'),
  };

  const config = {
    odataQuery,
  };
  return await get<Certification[]>(
    GET_CERTIFICATION_LIST_FOR_AUDIT_API,
    config,
  );
};

export const getEnvironmentForAuditAsync = async (
  complianceAuditId: number,
  certificationFamilyId?: number,
): Promise<Environment[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      ComplianceAuditId: complianceAuditId,
      CertificationFamilyId: certificationFamilyId,
    },
    orderBy: 'Name asc',
  });

  const config = {
    odataQuery,
  };
  return await get<Environment[]>(GET_ENVIRONMENT_LIST_FOR_AUDIT_API, config);
};

export const getServiceForAuditAsync = async (
  // callers can provide either complianceAuditId or complianceAuditGuid to search
  complianceAuditId?: number,
  complianceAuditGuid?: string,
  certificationFamilyId?: number,
  environmentId?: number,
): Promise<Service[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      ComplianceAuditId: complianceAuditId,
      CertificationFamilyId: certificationFamilyId,
      EnvironmentId: environmentId,
      ComplianceAuditGuid: complianceAuditGuid ? {
        value: complianceAuditGuid,
        type: 'guid'
      } : undefined,
    },
    orderBy: 'ShortName asc',
  });

  const config = {
    odataQuery,
  };
  return await get<Service[]>(GET_SERVICE_LIST_FOR_AUDIT_API, config);
};

export const getServiceForAuditByAuditEventGuidAsync = async (
  auditEventGuid: string,
  certificationFamilyId?: number,
  environmentId?: number,
): Promise<Service[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      ComplianceAuditGuid: {
        value: auditEventGuid,
        type: 'guid'
      },
      CertificationFamilyId: certificationFamilyId,
      EnvironmentId: environmentId,
    },
    orderBy: 'ShortName asc',
  });

  const config = {
    odataQuery,
  };
  return await get<Service[]>(GET_SERVICE_LIST_FOR_AUDIT_API, config);
};

export const getServicesByEnvironmentIdAsync = async (
  environmentId: number,
): Promise<Service[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      EnvironmentId: environmentId,
    },
    orderBy: 'ShortName asc',
  });

  const config = {
    odataQuery,
  };

  return await get<Service[]>(GET_SERVICE_LIST_BY_ENVIRONMENT_ID_API, config);
};

export const getServiceEnvironmentMappingsAsync = async (
  responsiblePartyId: number,
): Promise<ResponsiblePartyEnvironmentMapping[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      ResponsiblePartyId: responsiblePartyId,
    },
  });

  const config = {
    odataQuery,
  };

  return await get<ResponsiblePartyEnvironmentMapping[]>(GET_SERVICE_ENVIRONMENT_MAPPINGS_API, config);
};

export const getServicesByEnvironmentIdsAsync = async (
  environmentIds: number[],
): Promise<Service[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      EnvironmentId: {
        in: environmentIds
      },
    },
    orderBy: 'ShortName asc',
  });

  const config = {
    odataQuery,
  };

  return await get<Service[]>(GET_SERVICE_LIST_BY_ENVIRONMENT_ID_API, config);
};

export const getComplianceAuditServicesByAuditEventGuid = async (
  payload: GetComplianceAuditServicePayload
): Promise<Service[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      ComplianceAuditGuid: {
        value: payload.auditEventGuid,
        type: 'guid'
      },
      EnvironmentId: {
        in: payload.environmentIds
      },
    },
  });
  const config = {
    odataQuery,
  };
  return await get<Service[]>(GET_COMPLIANCE_AUDIT_SERVICES_API, config);
};

export const getComplianceAuditWorkloadByAuditEventGuid = async (
  complianceAuditGuid: string
): Promise<ComplianceAuditWorkload[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      ComplianceAuditGuid: {
        value: complianceAuditGuid,
        type: 'guid'
      },
    },
  });
  const config = {
    odataQuery,
  };
  return await get<ComplianceAuditWorkload[]>(GET_WORKLOAD_LIST_API, config);
};

export const getWorkloadsForAuditByComplianceAuditIdAsync = async (
  complianceAuditId: number,
): Promise<ComplianceAuditWorkload[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      ComplianceAuditId: complianceAuditId,
    },
    orderBy: 'ShortName asc',
  });
  const config = {
    odataQuery,
  };
  return await get<ComplianceAuditWorkload[]>(GET_WORKLOAD_LIST_API, config);
};

export const getWorkloadsForAuditAsync = async (
  auditEventGuid: string,
  certificationFamilyId?: number,
  environmentId?: number,
): Promise<ComplianceAuditWorkload[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      ComplianceAuditGuid: {
        value: auditEventGuid,
        type: 'guid'
      },
      CertificationFamilyId: certificationFamilyId,
      EnvironmentId: environmentId,
    },
    orderBy: 'ShortName asc',
  });
  const config = {
    odataQuery,
  };
  return await get<ComplianceAuditWorkload[]>(GET_WORKLOAD_LIST_API, config);
};

export const getServiceTreeItemAsync = async ({
  resourceGuid,
  accessGuid,
}: {
  resourceGuid?: string;
  accessGuid?: string;
}): Promise<ServiceTreeItem[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      ResourceGuid: resourceGuid ?
        {
          value: resourceGuid,
          type: 'guid'
        } :
        undefined,
      AccessGuid: accessGuid ?
        {
          value: accessGuid,
          type: 'guid'
        } :
        undefined,
    },
  });
  const config = {
    odataQuery,
  };
  return await get<ServiceTreeItem[]>(GET_SERVICE_TREE_ITEMS_API, config);
};

export const removeUserAccessAsync = async (
  userObjectId: string,
) => {
  await post<RemoveUserAccessPayload, void>(
    REMOVE_USER_ACCESS_API,
    {
      userObjectId,
    });
};

export const searchGuestUsersAsync = async (
  searchText: string,
): Promise<AzureAdMember[]> => {
  return await post<SearchGuestUsersPayload, AzureAdMember[]>(
    SEARCH_GUEST_USERS_API,
    {
      searchText,
    });
};

export const getSocWorkloadListAsync = async (
): Promise<SocWorkload[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      IsDeleted: false
    }
  });
  const config = {
    odataQuery
  };
  return await get<SocWorkload[]>(GET_SOC_WORKLOAD_API, config);
};

export const getSocServicesByWorkloadIdAsync = async (socWorkloadId: number
): Promise<SocServiceDetailsView[]> => {
  const odataQuery = buildODataQuery({
    filter: {
      SocWorkloadId: socWorkloadId,
      IsDeleted: false
    },
    orderBy: 'SocServiceName asc',
  });
  const config = {
    odataQuery,
  };
  return await get<SocServiceDetailsView[]>(GET_SOC_SERVICE_DETAILS_VIEW_API, config);
};

export const createNewSocServiceAsync = async (
  addSocService: AddSocService
): Promise<void> => {
  return await post<AddSocService, void>(
    ADD_SOC_SERVICE_API,
    addSocService
  );
};


export const updateSocServiceAsync = async (
  socService: UpdateSocService
): Promise<void> => {
  return await post<UpdateSocService, void>(
    UPDATE_SOC_SERVICE_API,
    socService
  );
};

export const deleteSocServiceAsync = async (
  socService: DeleteSocService
): Promise<void> => {
  return await post<DeleteSocService, void>(
    DELETE_SOC_SERVICE_API,
    socService
  );
};

export const createNewSocWorkloadAsync = async (
  addSocWorkload: AddSocWorkload
): Promise<void> => {
  return await post<AddSocWorkload, void>(
    ADD_SOC_WORKLOAD_API,
    addSocWorkload
  );
};


export const updateSocWorkloadAsync = async (
  socWorkload: UpdateSocWorkload
): Promise<void> => {
  return await post<UpdateSocWorkload, void>(
    UPDATE_SOC_WORKLOAD_API,
    socWorkload
  );
};

export const deleteSocWorkloadAsync = async (
  socWorkload: DeleteSocWorkload
): Promise<void> => {
  return await post<DeleteSocWorkload, void>(
    DELETE_SOC_WORKLOAD_API,
    socWorkload
  );
};

export const getServiceNamesForReuseAsync = async (
  reusingAuditEventGuid: string,
): Promise<string[]> => {
  return await post<GetServiceNamesForReusePayload, string[]>(
    GET_SERVICES_FOR_REUSE_API, {
      reusingAuditEventGuid,
    });
};

}

// #region private methods/utils

// #endregion
