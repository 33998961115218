import { Theme } from '@fluentui/react';
import { M365ThemeKind } from '@ms/centro-hvc-loader';

export interface IFeedbackInitOptions {
  // Default to undefined, Required except when unauth
  ageGroup?: FeedbackAgeGroup,
  // Required
  appId?: number,
  // AAD, MSA, unautheticated, Required
  authenticationType?: FeedbackAuthenticationType,
  // Set of callback funtions
  callbackFunctions?: IFeedbackCallbackFunctions,
  // Name of your product. Mandatory if you want File upload and Screen Recroding feature for your usecase.
  clientName?: string,
  // Settings of feedback
  feedbackConfig?: IFeedbackConfig,
  isProduction?: boolean
  telemetry?: IFeedbackTelemetry
  // Required except when unauth
  userId?: string,
  // Must be provided when AAD,
  tenantId?: string,
  themeOptions?: IThemeOptions
}

export enum FeedbackType {
  Smile = 'Smile',
  Frown = 'Frown',
  Idea = 'Idea'
}

export enum FeedbackAgeGroup {
  Undefined = 'Undefined',
  MinorWithoutParentalConsent = 'MinorWithoutParentalConsent',
  MinorWithParentalConsent = 'MinorWithParentalConsent',
  NotAdult = 'NotAdult',
  Adult = 'Adult',
  MinorNoParentalConsentRequired = 'MinorNoParentalConsentRequired'
}

export enum FeedbackAuthenticationType {
  MSA = 'MSA',
  AAD = 'AAD',
  Unauthenticated = 'Unauthenticated'
}

export enum FeedbackPolicyValue {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  NotConfigured = 'Not Configured'
}

export enum FeedbackScreenshotInfoImageFormat {
  JPEG = 'jpeg',
  PNG = 'png'
}

// All enums should be string
export enum FeedbackScreenshotSourceType {
  // Default: used for normal web integration where the SDK is responsible for taking screenshot.
  AutoCapture = 'AutoCapture',
  // Used when hostapp provides the screeenshot one time when it is initialzed mainly used in mobile scenarios.
  ProvidedAtInitialization = 'ProvidedAtInitialization',
  // When screenshot is provided dynamically using callback, used in Win32 and Mac Scenarios.
  DynamicallyProvided = 'DynamicallyProvided',
}

export enum FeedbackUiType {
  // Default: Used for side pane/detail view
  SidePane = 'SidePane',
  // Used for modal view
  Modal = 'Modal',
  // Used when the surface is provided by the host app
  NoSurface = 'NoSurface',
  // Used for inscreen pop up dialogue
  CallOut = 'CallOut'
}

export enum FeedbackHostPlatformType {
  Windows = 'Windows',
  iOS = 'iOS',
  Android = 'Android',
  WacTaskPane = 'WacTaskPane',
  MacOS = 'MacOS',
  Web = 'Web'
}

export type ThemeType = M365ThemeKind | string | Theme

export interface IThemeOptions {
  // Predfined theme or a custom theme
  baseTheme?: ThemeType,
  // undefined , Predefined theme color or a new color theme.
  colorScheme?: ThemeType
}

export interface IFeedbackScreenshotInfo {
  providedScreenshotType?: FeedbackScreenshotSourceType
  screenshotImageFormat?: FeedbackScreenshotInfoImageFormat
  screenshotBase64?: string
}

export interface IFeedbackCallbackFunctions {
  // when feedback is dismissed true if feedback was sent otherwise false( UI )
  onDismiss?: (isFeedbackSent?: boolean) => void,
  // when feedback is sent
  onSuccess?: (clientFeedbackId: string) => void,
  // when fatal error
  onError?: (errorMessage?: string) => void,
  // called if the screen shot is provided by the host application dynamically on this call
  provideDynamicScreenshot?: () => Promise<IFeedbackScreenshotInfo>,
}

// IFeedbackConfig
export interface IFeedbackConfig {
  appData?: string,
  // used for callout type surface targetting
  calloutTargetID?: string,
  complianceChecks?: IFeedbackComplianceChecks,
  email?: string,
  // Categories to show up in the dropdown part
  featureAreas?: string[],
  // if my feedback fourm url for the product is avialable
  feedbackForumUrl?: string,
  feedbackUiType?: FeedbackUiType,
  hostPlatform?: FeedbackHostPlatformType,
  initialFeedbackType?: FeedbackType,
  isDisplayed?: boolean,
  // to enable email colection
  isEmailCollectionEnabled?: boolean,
  // to enable feedback forum link
  isFeedbackForumEnabled?: boolean,
  // to enable file upload function, pre-release only (unavailable for production use)
  isFileUploadEnabled?: boolean,
  // to enble my feedback link
  isMyFeedbackEnabled?: boolean,
  // to enable screen recording function, pre-release only (unavailable for production use)
  isScreenRecordingEnabled?: boolean,
  // to enable screenshot function
  isScreenshotEnabled?: boolean,
  // to disable thank you page
  isThankYouPageDisabled?: boolean,
  // applicable for modal and callout
  maxHeight?: number,
  // applicable for modal , callout and side panel
  maxWidth?: number,
  // applicable for modal and callout
  minHeight?: number,
  // applicable for modal , callout and side panel
  minWidth?: number,
  // if my feedback url for the product is avialable
  myFeedbackUrl?: string,
  // For setting custom privacy URL
  privacyUrl?: string,
  retentionDurationDays?: number,
  screenshotInfo?: IFeedbackScreenshotInfo,
  // applicable for modal and callout
  width?: number,
}

export interface IFeedbackTelemetry {
  audience?: string,
  audienceGroup?: string,
  // Browser name.
  browser?: string,
  // Browser version number.
  browserVersion?: string,
  channel?: string,
  clientCountryCode?: string,
  // Data center identifier.
  dataCenter?: string,
  deviceId?: string,
  deviceType?: string,
  errorClassification?: string,
  errorCode?: string,
  errorName?: string,
  featureArea?: string,
  flights?: string,
  flightSource?: string,
  fundamentalArea?: string,
  installationType?: string,
  isLogIncluded?: boolean,
  isUserSubscriber?: boolean,
  officeArchitecture?: string,
  officeBuild?: string,
  officeEditingLang?: number,
  officeUILang?: number,
  osBitness?: number,
  osBuild?: string,
  osUserLang?: number,
  platform?: string,
  processSessionId?: string,
  ringId?: number,
  // DO NOT expose to client
  sdkVersion?: string,
  sku?: string,
  sourceContext?: string,
  // Source web page name.
  sourcePageName?: string,
  // Source web page URI.
  sourcePageURI?: string,
  systemManufacturer?: string,
  systemProductName?: string,

  // Do not expose these
  // Relate to extendedManifestData
  cid?: string
  diagnosticsEndPoint?: string
  diagnosticsUploadId?: string
  feedbackUIContext?: string
  licenseCategory?: number
  licenseId?: string
  msoAppId?: number
  msoPlatformId?: number
  osUserLocale?: string
  sqmMachineId?: string
  sqmUserId?: string
  uILocale?: string
}

// Details of the compliance checks https://www.owiki.ms/wiki/Working_with_feedback_policies
export interface IFeedbackComplianceChecks {
  // For MSA users rest of the variables are not valid
  // Allow the use of connected experiences in Office
  connectedExperiences?: FeedbackPolicyValue
  // Allow users to submit feedback to Microsoft
  policyAllowFeedback: FeedbackPolicyValue
  // Allow users to receive and respond to in-product surveys from Microsoft
  policyAllowSurvey?: FeedbackPolicyValue
  // Allow users to include screenshots and attachments when they submit feedback to Microsoft
  policyAllowScreenshot: FeedbackPolicyValue
  // Allow Microsoft to follow up on feedback submitted by users
  policyAllowContact: FeedbackPolicyValue
  // Allow users to include log files and content samples when feedback is submitted to Microsoft
  policyAllowContent?: FeedbackPolicyValue
}

export interface InAppFeedbackFormData {
  comment: string
  email: string
  featureArea?: string
  feedbackType: FeedbackType
  isEmailSelected: boolean
}
