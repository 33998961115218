import React, { lazy } from 'react';

import {
  Navigate,
  RouteObject
} from 'react-router-dom';

import {
  Application,
  FedHound,
} from '../../TrustPortal/registerApplicationMenu';
import { FedHoundURLLabel } from '../constants';

const Home = lazy(() => import('../pages/Home'));
const Renewals = lazy(() => import('../pages/Renewals'));
const Discovery = lazy(() => import('../pages/Discovery'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Admin = lazy(() => import('../pages/Admin'));
const ManualGovSubscriptionMapping = lazy(() => import ('../pages/ManualGovSubscriptionMapping'));
const ManualGccMtTestValidation = lazy(() => import ('../pages/ManualGccMtTestValidation'));
const ManualAuditParticipantMapping = lazy(() => import ('../pages/ManualAuditParticipantMapping'));
const NewOnboarding = lazy(() => import ('../pages/NewOnboarding'));

export const routes: RouteObject[] = [
  {
    path: `${Application.FedHound}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${FedHound.FedRampRenewals}/*`,
        element: <Renewals />,
      },
      {
        path: `menu/${FedHound.FedRampDiscovery}/*`,
        element: <Discovery />,
      },
      {
        path: `menu/${FedHound.Dashboard}/*`,
        element: <Dashboard />,
      },
      {
        path: `menu/${FedHound.FedRampAdmin}/*`,
        element: <Admin />,
        children: [
          {
            path: '*',
            element: <Navigate replace={true} to={FedHoundURLLabel.ManualGovSubscriptionMapping}/>
          },
          {
            path: FedHoundURLLabel.ManualGovSubscriptionMapping,
            element: <ManualGovSubscriptionMapping />
          },
          {
            path: FedHoundURLLabel.ManualGCCMTTestValidation,
            element: <ManualGccMtTestValidation />
          },
          {
            path: FedHoundURLLabel.ManualAuditParticipantMapping,
            element: <ManualAuditParticipantMapping />
          },
        ]
      },
      {
        path: `menu/${FedHound.NewOnboarding}/*`,
        element: <NewOnboarding />,
      },
    ],
  },
];
