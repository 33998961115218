import { AuditEvent } from './AuditEvent';
import { Environment } from './Environment';
import { EvidenceState } from './EvidenceState';
import {
  CreatedDateOption, DueDateOption
} from './FilterTypes';
import { Service } from './Service';

export interface ContinuousMonitoringOnboardedSchedule {
  ContinuousMonitoringTaskAssignedTeamV2Id: number;
  AssignedTo: string;
  HaltMonitoring: boolean;
  ServiceTreeId: string | null;
  EnvironmentId: number;
  EnvironmentName: string;
  TaskId: number;
  TaskName: string;
  AuditEventGuid: string;
  AuditEventName: string;
}

export interface ContinuousMonitoringEnvironment {
  ContinuousMonitoringTaskV2Id: number;
  TaskName: string;
  HaltMonitoring: boolean;
  EnvironmentId: number;
  EnvironmentName: string;
  AuditEventGuid: string;
  AuditEventName: string;
}

export interface ServiceDetail {
  ResponsiblePartyId: number;
  ParentResponsiblePartyId: number;
  ServiceTreeId: string | null;
  ServiceName: string;
  WorkloadName: string;
  Champ: string | null;
  PointOfContact: string | null;
  // TODO: #4524914 - Remove undefined once the API is updated
  PointOfContactAccountGuid?: string;
  Tester: string | null;
  Requested: number;
  Submitted: number;
  Approved: number;
  Rejected: number;
  Closed: number;
  NotApplicable: number;
}

export interface FilterOptionsForServiceWorkspace {
  Services: string[];
  Workloads: string[];
}

export interface ServiceContactTypeNotification {
  ServiceTreeId: string;
  ServiceContactType: number;
}

// From project

export enum TaskSource {
  AuditRequest = 'Audit Request',
  ContinuousMonitoring = 'Continuous Monitoring',
}

export type AssignedToMeFilterSelectedState = {
  state: EvidenceState[];
  source: TaskSource | undefined;
  auditEvent: AuditEvent | undefined;
  environment: Environment[];
  service: Service[];
  dueDate: DueDateOption | undefined;
  createdDate: CreatedDateOption | undefined;
};

export enum ServiceWorkspaceEvidenceSortKey {
  CreatedDate = 'CreatedDate',
  DueDate = 'DueDate',
}

export type OriginalEvidenceAssignedOptions = {
  alias?: string;
  services?: string[];
  workloads?: string[];
};

export type UpdateServiceNotificationConfigPayload = {
  serviceTreeId: string;
  addServiceContactTypeIds: number[];
  removeServiceContactTypeIds: number[];
  addCustomObjectIds: string[];
  removeCustomObjectIds: string[];
};
