import React from 'react';

import classNames from 'classnames';

export enum SizeEnum {
  Small,
  Large,
}

export const UserIcon = ({
  name,
  size,
  id,
  onClick = () => {},
}: {
  name: string;
  size?: SizeEnum;
  id?: string;
  onClick?: () => void;
}): JSX.Element => {
  const shortName = name
    .split(' ')
    .map((s) => (s ? s[0].toUpperCase() : ''))
    .join('');
  const classname = classNames('userIcon', {
    small: size === SizeEnum.Small,
    large: size === SizeEnum.Large,
  });

  const userIconKeyDownEvent = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <div className={classname} id={id} tabIndex={0} onKeyDown={userIconKeyDownEvent}>
      <span onClick={onClick}>{ shortName }</span>
    </div>
  );
};
