import React from 'react';

import {
  Link,
  FontIcon
} from '@fluentui/react';
import './Logo.less';
import intl from 'react-intl-universal';

import { LocIds } from '../../../../common/Globalization/IntlEnum';

export const Logo = (): JSX.Element => {
  return (
    <Link
      aria-label={intl.get(LocIds.Application.MicrosoftIcon)}
      className='tp-header__logo'
      href='https://www.microsoft.com'
      title={intl.get(LocIds.Application.MicrosoftIcon)}>
      <FontIcon className='tp-header__logo-icon' iconName='ms-symbol' />
    </Link>
  );
};
