import React, {
  useEffect, useRef, useState
} from 'react';

import {
  IObjectWithKey, PanelType, Selection
} from '@fluentui/react';
import _ from 'lodash';
import intl from 'react-intl-universal';

import { OnboardPanelCommandBar } from './OnboardPanelCommandBar';

import { LocIds } from '../../../../../../common/Globalization/IntlEnum';
import {
  TrustPanel, ViewLayout
} from '../../../../../../components';
import { EntityApi } from '../../../../../../services';
import {
  showErrorMsg, UseAsyncHookStatus
} from '../../../../../../utils';
import { ContactInfo } from '../../../../models';
import {
  ConMonAuditEvent, ConMonTask
} from '../../../../models/ContinuousMonitoringTypes';
import { useAppDispatch } from '../../redux/reducer';
import {
  fetchAllConMonEnvironments, useAllAuditEvents, useCanOnboard, useLoadingAllAuditEventsStatus,
} from '../../redux/serviceSlice';
import {
  buildScheduleEnvironmentName, getIdsFromKey
} from '../ScheduleBoard/utils';
import {
  ContinuousMonitoringTaskEnvironmentWithKey, ScheduleList
} from '../ScheduleList';

type OnboardPanelProps = {
  responsiblePartyId: number;
  isOpen: boolean;
  onConfirm: (keyNames: Record<string, string>) => void;
  onDismiss: () => void;
  defaultContact?: ContactInfo;
}

export const OnboardPanel: React.FC<OnboardPanelProps> = (props) => {
  const { responsiblePartyId, isOpen, defaultContact } = props;

  const dispatch = useAppDispatch();
  const auditEvents = useAllAuditEvents();

  const asyncStatus = useLoadingAllAuditEventsStatus();

  const [selectedAuditEventGuids, setSelectedAuditEventGuids] = useState<Set<string>>(new Set());
  const [keyword, setKeyword] = useState<string>('');
  const [showEnabledOnly, setShowEnabledOnly] = useState<boolean>(true);

  const [mappingAsyncStatus, setMappingAsyncStatus] = useState<UseAsyncHookStatus>(UseAsyncHookStatus.Success);

  // AuditEventGuid + TaskId + EnvironmentId
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const selection = useRef(
    new Selection<IObjectWithKey | ContinuousMonitoringTaskEnvironmentWithKey>({
      onSelectionChanged: () => {
        setSelectedKeys((selection.getSelection() as ContinuousMonitoringTaskEnvironmentWithKey[]).map((item) => item.key));
      },
    }),
  ).current;

  const canOnboard = useCanOnboard(selectedKeys);

  useEffect(() => {
    if (isOpen) {
      // Fetch ResponsiblePartyEnvironmentMappings to determine which environments are applicable
      setMappingAsyncStatus(UseAsyncHookStatus.Pending);
      EntityApi.getServiceEnvironmentMappingsAsync(responsiblePartyId)
        .then((mappings) => {
          setMappingAsyncStatus(UseAsyncHookStatus.Success);
          const environmentIds = mappings
            .filter(m => !_.isNil(m))
            .map(m => m.EnvironmentId!);

          dispatch(fetchAllConMonEnvironments({
            contact: defaultContact,
            environmentIds
          }));
        })
        .catch(e => {
          setMappingAsyncStatus(UseAsyncHookStatus.Fail);
          showErrorMsg(e, dispatch);
        });
    }
  }, [isOpen]);

  const commandBar = (numTotal: number) => (
    <OnboardPanelCommandBar
      filterProps={{
        selectedGuids: selectedAuditEventGuids,
        setSelectedGuids: setSelectedAuditEventGuids
      }}
      keywordProps={{
        keyword,
        onKeywordChange: setKeyword
      }}
      menuProps={{
        showEnabledOnly,
        setShowEnabledOnly
      }}
      numTotal={numTotal}
    />
  );

  const onDismiss = () => {
    setSelectedKeys([]);
    selection.setAllSelected(false);

    props.onDismiss();
  };

  const onOnboard = () => {
    const keyNames: Record<string, string> = {};

    if (auditEvents) {
      for (const key of selectedKeys) {
        const ids = getIdsFromKey(key);

        if (ids) {
          const { taskId, environmentId } = ids;
          keyNames[key] = buildScheduleEnvironmentName(auditEvents, taskId, environmentId);
        }
      }
    }

    props.onConfirm(keyNames);
    onDismiss();
  };

  const filteredItems = constructAuditEvents(auditEvents ?? [], showEnabledOnly, keyword);
  const scopedItems = _.isEmpty(selectedAuditEventGuids) ?
    filteredItems : filteredItems.filter(e => selectedAuditEventGuids.has(e.guid));

  const bodyJsx = (
    <ViewLayout
      emptyMessage={intl.get(LocIds.ServiceWorkspace.NoAvailableSchedules)}
      isEmpty={_.isEmpty(scopedItems)}
      isError={asyncStatus === UseAsyncHookStatus.Fail || mappingAsyncStatus === UseAsyncHookStatus.Fail}
      isLoaded={asyncStatus === UseAsyncHookStatus.Success && mappingAsyncStatus === UseAsyncHookStatus.Success}>
      { commandBar(_.sum(_.flatten(scopedItems?.map(a => a.tasks.map(t => t.environments.length))))) }
      <ScheduleList auditEvents={scopedItems} editAssignedTo selection={selection} />
    </ViewLayout>
  );

  const numSelected = selectedKeys.length;
  const allowSave = numSelected > 0 && canOnboard;

  return (
    <TrustPanel
      headerText={intl.get(LocIds.AuditManager.AddNewSchedules)}
      isOpen={isOpen}
      preventClose={allowSave}
      primaryActionProps={{
        text: intl.get(LocIds.Action.Add) + (numSelected > 0 ? ` (${numSelected})` : ''),
        onClick: onOnboard,
        disabled: !allowSave
      }}
      type={PanelType.largeFixed}
      onDismiss={onDismiss}>
      { bodyJsx }
    </TrustPanel>
  );
};

const constructAuditEvents = (auditEvents: ConMonAuditEvent[], showEnabledOnly: boolean, keyword: string): ConMonAuditEvent[] => {
  const result: ConMonAuditEvent[] = [];

  for (const auditEvent of auditEvents) {
    const tasks: ConMonTask[] = [];

    for (const task of auditEvent.tasks) {
      const environments = task.environments.filter((environment) => {
        const name = environment.name.toLowerCase();
        return (!showEnabledOnly || !environment.halted) && name.includes(keyword.toLowerCase());
      });

      if (environments.length > 0) {
        tasks.push({
          ...task,
          environments
        });
      }
    }

    if (tasks.length > 0) {
      result.push({
        ...auditEvent,
        tasks
      });
    }
  }

  return result;
};
