import { DocTreeVisitor } from './DocTreeVisitor';

import { FilterDocService } from '../../services/FilterDocService';
import { DocTreeNode } from '../DocTreeNode/DocTreeNode';
import { DocTreeRootNode } from '../DocTreeNode/DocTreeRootNode';

export class FilterVisitor implements DocTreeVisitor {
  constructor(searchQuery: string) {
    this._searchQueryReg = FilterDocService.getSearchQueryReg(searchQuery);
  }

  public visitDocTreeRootNode(node: DocTreeRootNode): void {
    node.children.forEach((n: DocTreeNode) => n.accept(this));
  }

  /**
   * A node is visible if:
   * 1. the node is matched to the search query
   * 2. it has at least one visible child
   */
  public visitDocTreeNode(node: DocTreeNode): void {
    if (node.children.length !== 0) {
      node.children.forEach((n) => n.accept(this));
    }

    node.isFiltered =
      FilterDocService.isSearchTextMatched(node.name, this._searchQueryReg) ||
      node.hasVisibleChildren();
  }

  private _searchQueryReg: RegExp | null;
}
