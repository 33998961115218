import React from 'react';

import { Link } from '@fluentui/react';

import intl from 'react-intl-universal';

import { ipRestrictionDocUid } from './constant';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import {
  Application, Documentation
} from '../../registerApplicationMenu';

export const NetworkRestrictionDocLink = () => {
  const docLink = `/application/${Application.Documentation}/menu/${Documentation.Docs}/uid/${ipRestrictionDocUid}`;

  return <Link href={docLink} target='_blank'>{ intl.get(LocIds.Action.LearnMore) }</Link>;
};
