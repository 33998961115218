import { IStaticPortalConfiguration } from './models';
import {
  ApiService,
  applicationInsightsService,
  IServices,
  OAuthService,
  portalConfigurationService,
  RestApiService
} from './services';

/**
 * Configure services, services work like values of React Context
 */
export const configureServices = (
  configuration: IStaticPortalConfiguration,
): IServices => {
  applicationInsightsService.configureService(configuration);
  OAuthService.configureService(configuration, applicationInsightsService);
  RestApiService.configureService(OAuthService);
  portalConfigurationService.configureService(configuration);
  ApiService.configureService(OAuthService, portalConfigurationService);
  return {
    oAuthService: OAuthService,
    restApiService: RestApiService,
    applicationInsightsService,
  };
};
