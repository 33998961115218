/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      fill='none'
      height='100'
      viewBox='0 0 100 100'
      width='100'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M50.6763 50.6163L50.5211 99.5L8 74.9806L8.15519 26.0968L50.6763 50.6163Z' fill='#0078D4'/>
      <path d='M50.6762 50.6163L92.111 26.0968L91.9558 74.903L50.521 99.5L50.6762 50.6163Z' fill='#243A5E'/>
      <path d='M8.15527 26.0971L49.5901 1.5L92.1112 26.0971L50.6764 50.6165L8.15527 26.0971Z' fill='#185A97'/>
      <path d='M49.5901 50.3837L49.6677 11.4319L83.5759 31.063L83.4208 69.9372L49.5901 50.3837Z' fill='#0078D4'/>
      <path d='M83.4206 69.9372L50.4434 89.5683L16.5352 70.0148L49.5899 50.3837L83.4206 69.9372Z' fill='#69E2FF'/>
      <path d='M49.5901 50.3837L16.5354 70.0148L16.6906 31.063L49.6677 11.4319L49.5901 50.3837Z' fill='#2BA3E0'/>
      <g clipPath='url(#clip0_39841_88360)'>
        <path d='M69.98 70.2861L52.0586 80.2975C50.6711 81.0762 48.4165 81.0762 47.0869 80.2975L28.992 70.2861C28.2983 69.8968 27.9514 69.3962 27.9514 68.8956V70.5086C27.9514 71.0091 28.2983 71.5097 28.992 71.899L47.029 81.9104C48.4165 82.6891 50.6711 82.6891 52.0008 81.9104L69.9222 71.899C70.6159 71.5097 70.9628 71.0091 70.9628 70.5086V68.8956C71.0206 69.3962 70.6737 69.8968 69.98 70.2861Z' fill='#C05300'/>
        <path d='M69.9297 67.5763C71.3155 68.3669 71.3155 69.6092 69.9297 70.3997L52.0297 80.564C50.6439 81.3546 48.392 81.3546 47.0639 80.564L28.9908 70.3997C27.605 69.6092 27.605 68.3669 28.9908 67.5763L46.8907 57.412C48.2765 56.6215 50.5284 56.6215 51.8565 57.412L69.9297 67.5763Z' fill='#FF8222'/>
        <path d='M52.4337 34.2602L62.3075 67.9154C62.3075 67.9154 62.8849 70.6258 60.5175 72.4328C60.5175 72.4328 56.2446 75.4821 49.0846 75.2562C41.9246 75.0304 38.4024 72.4328 38.4024 72.4328C35.9772 70.6823 36.6124 67.9154 36.6124 67.9154L46.4285 34.2602C46.4285 34.2602 46.6017 33.1309 49.4311 33.1309C52.2027 33.0744 52.4337 34.2602 52.4337 34.2602Z' fill='#F56A00'/>
        <path d='M52.3624 34.26C52.3624 34.8811 51.0344 35.3893 49.4753 35.3893C47.8586 35.3893 46.5882 34.8811 46.5882 34.26C46.5305 33.6388 47.8586 33.1306 49.4753 33.1306C51.0344 33.1306 52.3624 33.6388 52.3624 34.26Z' fill='#FF8222'/>
        <path d='M51.6372 34.265C51.6372 34.612 50.6524 34.8722 49.4602 34.8722C48.268 34.8722 47.2832 34.612 47.2832 34.265C47.2832 33.918 48.268 33.6578 49.4602 33.6578C50.7042 33.6144 51.6372 33.8746 51.6372 34.265Z' fill='#C05300'/>
        <path d='M49.4602 57.513H49.5268C49.5268 57.513 57.1226 57.4477 58.2553 54.1168L55.2075 43.6672C54.3367 45.4959 49.5268 45.4959 49.5268 45.4959H49.4602C49.4602 45.4959 44.6707 45.3435 43.7128 43.4277L40.665 54.0298C41.8644 57.3606 49.4602 57.513 49.4602 57.513Z' fill='#FAFAFA'/>
      </g>
      <defs>
        <clipPath id='clip0_39841_88360'>
          <rect fill='white' height='49.6357' transform='translate(27.9514 33.1306)' width='43.0176'/>
        </clipPath>
      </defs>
    </svg>
  );
};
