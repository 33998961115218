import React from 'react';

import './M365CommandBarButton.less';
import {
  CommandBarButton,
  IButtonProps,
  IButtonStyles,
  Spinner
} from '@fluentui/react';

export interface ICommandBarButtonProps extends IButtonProps {
    loading?: boolean;
}

export const M365CommandBarButton: React.FC<ICommandBarButtonProps> = (props: ICommandBarButtonProps) => {

  const buttonStyles: IButtonStyles = {
    textContainer: {
      order: 2
    },
    icon: {
      display: props.loading ? 'none' : 'inline-block'
    }
  };

  const loader = props.loading && (
    <Spinner className='m365-commandBarButton-spinner' size={1} />
  );

  return (
    <CommandBarButton {...props} styles={buttonStyles}>
      { loader }
    </CommandBarButton>
  );
};
