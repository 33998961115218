// NB: This type should be the same with the one defined in backend.
export enum ServiceContactType {
  PrimaryChamp = 1,
  SME = 2,
  ServiceDevOwner = 3,
  ServicePMOwner = 4,
  ServiceAdmin = 5,
  ServicePointOfContact = 6,
  Champ = 7,
  CustomRecipient = 8,
}

export interface ServiceContact {
  ServiceTreeId: string;
  ServiceContactTypeId: ServiceContactType;
  ServiceContactType: string;
  ObjectId: string;
  DisplayName: string;
}
