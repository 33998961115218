import { OAuthService } from './OAuthService';

export interface IRestApiRequestConfig {
  accessToken?: string;
  skipAuthentication?: boolean;
  scopes?: string[];
  baseUrl?: string;
  contentType?: string;
}

export namespace RestApiService {
  let oAuthService: typeof OAuthService;

  export function configureService(_oAuthService: typeof OAuthService): void {
    oAuthService = _oAuthService;
  }

  export async function fetchGet(
    resource: string,
    config: IRestApiRequestConfig,
  ): Promise<Response> {
    const headers = await createHeader(config);

    const response: Response = await fetch(config.baseUrl + resource, {
      method: 'GET',
      headers,
    });

    return handleResponse(response);
  }

  /**
   * Send a http POST request using native fetch api
   * Can be used when Content-Type is not application/json, currently o.js doesn't support other Content-Types, @see https://github.com/janhommes/o.js/issues/127
   */
  export async function fetchPost(
    resource: string,
    data: RequestInit['body'],
    config: IRestApiRequestConfig,
  ): Promise<Response> {
    const headers = await RestApiService.createHeader(config);

    const response = await fetch(config.baseUrl + resource, {
      body: data,
      headers,
      method: 'POST',
    });

    return handleResponse(response);
  }

  export async function createHeader(
    config?: IRestApiRequestConfig,
  ): Promise<Headers> {
    const headers = new Headers();

    if (config && config.contentType) {
      headers.append('Content-Type', config.contentType);
    } else {
      headers.append('Content-Type', 'application/json');
    }

    if (config && !config.skipAuthentication) {
      let accessToken = '';

      // If caller provides accessToken then use it.
      if (config.accessToken) {
        accessToken = config.accessToken;

        // Else acquire accessToken for the caller on required scopes.
      } else if (Array.isArray(config.scopes)) {
        const result = await oAuthService.acquireTokenSilent({
          scopes: config.scopes,
        });
        accessToken = result.accessToken;
      } else {
        throw new Error(
          'Have to provide accessToken or scopes if an Authorization Header is needed',
        );
      }

      headers.append('Authorization', `Bearer ${accessToken}`);
    }

    return headers;
  }

  export async function handleResponse(response: Response): Promise<Response> {
    if (response.ok) {
      return response;
    }

    if (response.status === 400) {
      const error = await response.text();
      throw new Error(error);
    }

    throw new Error('Network response was not ok.');
  }
}
