import './style.less';

import React from 'react';

import {
  DetailsList,
  IColumn,
  IDetailsHeaderProps,
  IGroup,
  IObjectWithKey,
  IRenderFunction,
  ISelection,
  MarqueeSelection,
  TooltipHost,
} from '@fluentui/react';
import { IDetailsGroupDividerProps } from '@fluentui/react/lib/components/DetailsList/DetailsList.types';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { useMouseDragInProgressSelector } from '../../redux/userInteractionSlice/selector';

interface EvidenceListProps<EvidenceType> {
  items: EvidenceType[];
  groups?: IGroup[];
  onRenderItem: (evidence: EvidenceType) => React.ReactElement | null;
  onRenderGroupHeader?: IRenderFunction<IDetailsGroupDividerProps>;
  selection: ISelection<IObjectWithKey>;
}

export const EvidenceList = <EvidenceType, >(
  props: EvidenceListProps<EvidenceType>,
): React.ReactElement => {
  const columns: IColumn[] = [
    {
      key: 'id',
      name: intl.get(LocIds.Label.EvidenceRequestID),
      minWidth: 200,
      isResizable: true,
      onRender: props.onRenderItem,
    },
  ];

  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender,) =>
    props && defaultRender ?
      defaultRender({
        ...props,
        onRenderDetailsCheckbox: (checkboxProps, defaultRenderer) =>
          checkboxProps && defaultRenderer ? (
            <TooltipHost
              content={intl.get(LocIds.Components.SelectAllItemsOnThisPage)}>
              { defaultRenderer(checkboxProps) }
            </TooltipHost>
          ) : null,
      }) :
      null;

  const mouseDragInProgress = useMouseDragInProgressSelector();

  return (
    < MarqueeSelection
      // Since marquee selection prevents other mouse drag events like resizing, we need to temporary disable it
      isEnabled={!mouseDragInProgress}
      selection={props.selection}>
      <DetailsList
        ariaLabelForSelectAllCheckbox={intl.get(LocIds.Action.Detail)}
        checkboxCellClassName='evidenceList__checkboxCell'
        checkButtonAriaLabel={intl.get(LocIds.Action.Detail)}
        columns={columns}
        groupProps={{
          onRenderHeader: props.onRenderGroupHeader,
        }}
        groups={props.groups}
        items={props.items}
        selection={props.selection}
        selectionPreservedOnEmptyClick={true}
        selectionZoneProps={{
          // selectionClearedOnEscapePress: false,
          selectionClearedOnSurfaceClick: false,
          // selectionPreservedOnEmptyClick: true,
          // disableAutoSelectOnInputElements: true,
        }}
        setKey='evidence'
        onRenderDetailsHeader={onRenderDetailsHeader}
        onShouldVirtualize={() => false}
      />
    </MarqueeSelection >
  );
};
