import './style.less';

import React, {
  useEffect,
  useState
} from 'react';

import { usePrevious } from '@fluentui/react-hooks';
import classnames from 'classnames';


import _ from 'lodash';

import { getLinkIdentifier } from './helper';
import { LinkList } from './LinkList';
import { SearchControl } from './SearchControl';
import {
  SideNavLink,
  SideNavProps
} from './types';

import { getRegExpMatchMultipleWord } from '../../utils';

/**
 * Given a list of SideNavLink, renders a navigation tree
 */
export const SideNav = <T extends SideNavLink<T>>(
  props: SideNavProps<T>,
): ReturnType<React.FC<SideNavProps<T>>> => {
  const {
    links,
    onLinkClick,
    isLinkActive,
    getLinkClassName,
    wrapperClassName,
    searchQuery = '',
    onSearch = () => {
    },
    canLinkWithChildrenCollapse,
    linkHeight,
    showSearchBox = false,
    defaultCollapseLinksMap
  } = props;

  const cls = classnames('sideNavWrapper', wrapperClassName, {
    'sideNavWrapper--canLinkWithChildrenCollapse': canLinkWithChildrenCollapse,
  });

  const [collapseLinksMap, setCollapseLinksMap] = useState<Record<string, boolean>>({});

  const prevDefaultCollapseLinksMap = usePrevious(defaultCollapseLinksMap);

  useEffect(() => {
    // defaultCollapseLinksMap can be generated asynchronously
    if (_.isEmpty(prevDefaultCollapseLinksMap) && !_.isEmpty(defaultCollapseLinksMap)) {

      // Avoid overloading current collapseLinksMap with defaultCollapseLinksMap
      setCollapseLinksMap({
        ...defaultCollapseLinksMap,
        ...collapseLinksMap
      });
    }
  }, [defaultCollapseLinksMap, collapseLinksMap]);

  const toggleLinkCollapse = <T extends SideNavLink<T>>(
    link: SideNavLink<T>,
  ) => {
    const identifier = getLinkIdentifier(link);

    if (identifier) {
      if (collapseLinksMap[identifier]) {
        setCollapseLinksMap({
          ...collapseLinksMap,
          [identifier]: false,
        });
      } else {
        setCollapseLinksMap({
          ...collapseLinksMap,
          [identifier]: true,
        });
      }
    }
  };

  return (
    <div className={cls}>
      { showSearchBox && <SearchControl onSearch={onSearch}/> }
      <nav role='navigation'>
        { /* TODO Class Component -> Functional Component */ }
        { /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/ }
        { /* @ts-ignore*/ }
        <LinkList
          canLinkWithChildrenCollapse={canLinkWithChildrenCollapse}
          collapseLinksMap={collapseLinksMap}
          getLinkClassName={getLinkClassName}
          isLinkActive={isLinkActive}
          linkHeight={linkHeight}
          links={links.filter((v: SideNavLink<T>) => v.isVisible && v.name)}
          searchRegExp={getRegExpMatchMultipleWord(searchQuery)}
          toggleLinkCollapse={toggleLinkCollapse}
          onLinkClick={onLinkClick}
        />
      </nav>
    </div>
  );
};
