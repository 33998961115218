import { IDatePickerStrings } from '@fluentui/react';
import _ from 'lodash';


// we need this to enable showing error messages in datepicker in @fluentui/react@7.174.1
// if we upgrade @fluentui/react to 8.x.x, this variable can be removed
export const DATE_PICKER_STRINGS: IDatePickerStrings = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],

  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],

  days: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],

  shortDays: [
    'S',
    'M',
    'T',
    'W',
    'T',
    'F',
    'S'
  ],

  goToToday: 'Go to today',
  prevMonthAriaLabel: 'Go to previous month',
  nextMonthAriaLabel: 'Go to next month',
  prevYearAriaLabel: 'Go to previous year',
  nextYearAriaLabel: 'Go to next year',
  closeButtonAriaLabel: 'Close date picker',
  monthPickerHeaderAriaLabel: '{0}, select to change the year',
  yearPickerHeaderAriaLabel: '{0}, select to change the month',

  isRequiredErrorMessage: 'Field is required.',

  invalidInputErrorMessage: 'Invalid date format.',
  isOutOfBoundsErrorMessage: 'Invalid date range.',
};

export const onFormatDate = (date?: Date): string => {
  return !date || !_.isFunction(date.getMonth) ?
    '' :
    `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const onFormatDateWithDash = (date?: Date): string => {
  let month = '';
  let day = '';

  if (date?.getMonth) {
    month = (date?.getMonth() + 1) < 10 ? `-0${date?.getMonth() + 1}` : `-${date?.getMonth() + 1}`;
  }

  if (date?.getDate) {
    day = (date?.getDate()) < 10 ? `-0${date?.getDate()}` : `-${date?.getDate()}`;
  }

  return !date || !_.isFunction(date.getMonth) ?
    '' : `${date.getFullYear()}${month}${day}`;
};
