import _ from 'lodash';

import { diffAttachments } from './attachmentFacade';

import {
  AddAttachment,
  AddSocIntroPayloadItem,
  AdoConfiguration,
  AipConfiguration,
  AuditEvent,
  AuditEventAttachment,
  AuditEventCertification,
  AuditEventEnvironment,
  AuditEventService,
  AuditFlags,
  AzureAdObject,
  RemoveSocIntroItem,
  ReuseConfiguration,
  ReuseConfigurationPayloadItem,
  ServicePayloadItem,
  SocAuditStatus,
  SocIntro,
  UpdateAuditEventPayload,
  UpdateSocIntroPayloadItem
} from '../models';


/**
 * Type definitions
 */

type BasicInfoDiffResult = Pick<
  UpdateAuditEventPayload,
  | 'setName'
  | 'setDescription'
  | 'setStartDate'
  | 'setEndDate'
  | 'setAuditTeamEmail'
  | 'setAuditorCompany'
>;

type ServicesDiffResult = Pick<
  UpdateAuditEventPayload,
  'addServices' | 'removeServices'
>;

type AuditorsDiffResult = Pick<
  UpdateAuditEventPayload,
  'addAuditors' | 'removeAuditors'
>;

type OwnersDiffResult = Pick<
  UpdateAuditEventPayload,
  'addOwners' | 'removeOwners'
>;

type AttachmentsDiffResult = Pick<
  UpdateAuditEventPayload,
  'addAttachments' | 'removeAttachments' | 'updateAttachments'
>;

type OtherDiffResult = Pick<
  UpdateAuditEventPayload,
  'setIsAutoPublishEnabled' | 'setIsReuseFromAllEnabled' | 'setCanBypassPublishScopeCheck' | 'setIsPublishedEvidenceReusable' | 'setIsEvidenceConclusionDisabled' | 'setIsMessagingEnabled'
>;

type ReuseConfigurationsDiffResult = Pick<
  UpdateAuditEventPayload,
  'addReuseConfigurations' | 'removeReuseConfigurations'
>;

type AdoConfigurationDiffResult = Pick<
  UpdateAuditEventPayload,
  'setAdoConfiguration'
>;

type AipConfigurationDiffResult = Pick<
  UpdateAuditEventPayload,
  'setAipConfiguration'
>;

type AuditFlagsDiffResult = Pick<UpdateAuditEventPayload, 'updateAuditFlags'>;

/**
 * Diff utility functions
 */

export const getInitialDiffResult = (
  auditEventGuid: string,
): UpdateAuditEventPayload => ({
  auditEventGuid,
  addAuditors: null,
  removeAuditors: null,
  addOwners: null,
  removeOwners: null,
  addServices: null,
  removeServices: null,
  addAttachments: null,
  updateAttachments: null,
  removeAttachments: null,
  addReuseConfigurations: null,
  removeReuseConfigurations: null,
  addSocIntros: null,
  updateSocIntros: null,
  removeSocIntros: null
});

export const diffAuditEvent = (
  original: AuditEvent,
  modified: AuditEvent,
): UpdateAuditEventPayload => {
  const res = getInitialDiffResult(original.AuditEventGuid);

  Object.assign(res, diffBasicInfo(original, modified));
  Object.assign(res, diffCertification(original.Certifications, modified.Certifications, false));
  Object.assign(res, diffAuditors(original.Auditors, modified.Auditors));
  Object.assign(res, diffOwners(original.Owners, modified.Owners));
  Object.assign(res, diffAuditEventAttachments(original.Attachments, modified.Attachments));
  Object.assign(res, diffOther(original, modified));
  Object.assign(res, diffReuseConfigurations(original.ReuseConfigurations, modified.ReuseConfigurations));
  Object.assign(res, diffAdoConfiguration(original.AdoConfiguration, modified.AdoConfiguration));
  Object.assign(res, diffSocIntros(original.SocIntros, modified.SocIntros));
  Object.assign(res, diffAipConfiguration(original.AipConfiguration, modified.AipConfiguration));
  Object.assign(res, diffAuditFlags(original.AuditFlags, modified.AuditFlags));

  return res;
};

const diffBasicInfo = (
  original: AuditEvent,
  modified: AuditEvent,
): BasicInfoDiffResult => {
  const res: BasicInfoDiffResult = {};

  if (original.Name !== modified.Name) {
    res.setName = {
      Value: modified.Name
    };
  }

  if (original.Description !== modified.Description) {
    res.setDescription = {
      Value: modified.Description
    };
  }

  if (original.StartDate !== modified.StartDate) {
    res.setStartDate = {
      Value: modified.StartDate
    };
  }

  if (original.EndDate !== modified.EndDate) {
    res.setEndDate = {
      Value: modified.EndDate
    };
  }

  if (original.AuditTeamEmail !== modified.AuditTeamEmail && (!_.isEmpty(original.AuditTeamEmail) || !_.isEmpty(modified.AuditTeamEmail))) {
    res.setAuditTeamEmail = {
      Value: modified.AuditTeamEmail ?? ''
    };
  }

  if (original.AuditorCompany !== modified.AuditorCompany && (!_.isEmpty(original.AuditorCompany) || !_.isEmpty(modified.AuditorCompany))) {
    res.setAuditorCompany = {
      Value: modified.AuditorCompany ?? ''
    };
  }

  return res;
};

const diffCertification = (
  original: AuditEventCertification[],
  modified: AuditEventCertification[],
  allowNullServiceId: boolean,
): ServicesDiffResult => {
  const originalCertIds = original.map((cert) => cert.CertificationFamilyId);
  const modifiedCertIds = modified.map((cert) => cert.CertificationFamilyId);

  const originalCertHash = _.keyBy(
    original,
    (cert) => cert.CertificationFamilyId,
  );
  const modifiedCertHash = _.keyBy(
    modified,
    (cert) => cert.CertificationFamilyId,
  );

  const addedServices: ServicePayloadItem[] = [];
  const removedServices: ServicePayloadItem[] = [];

  const removedCerts = _.difference(originalCertIds, modifiedCertIds);
  const addedCerts = _.difference(modifiedCertIds, originalCertIds);
  const mightModifiedCerts = _.intersection(originalCertIds, modifiedCertIds);

  // find all services under removedCerts
  removedCerts.forEach((certId) => {
    const originalEnv = originalCertHash[certId] ?
      originalCertHash[certId].Environments :
      [];
    const diffRes = diffEnvironment(
      originalEnv,
      [],
      certId,
      allowNullServiceId,
    );

    if (diffRes.removeServices) {
      removedServices.push(...diffRes.removeServices);
    }
  });

  [...addedCerts, ...mightModifiedCerts].forEach((certId) => {
    const originalEnv = originalCertHash[certId] ?
      originalCertHash[certId].Environments :
      [];
    const modifiedEnv = modifiedCertHash[certId].Environments;
    const diffRes = diffEnvironment(
      originalEnv,
      modifiedEnv,
      certId,
      allowNullServiceId,
    );

    if (diffRes.addServices) {
      addedServices.push(...diffRes.addServices);
    }

    if (diffRes.removeServices) {
      removedServices.push(...diffRes.removeServices);
    }
  });

  return {
    addServices: addedServices.length > 0 ? addedServices : null,
    removeServices: removedServices.length > 0 ? removedServices : null,
  };
};

const diffEnvironment = (
  original: AuditEventEnvironment[],
  modified: AuditEventEnvironment[],
  certId: number,
  allowNullServiceId: boolean,
): ServicesDiffResult => {
  const originalEnvIds = original.map((env) => env.EnvironmentId);
  const modifiedEnvIds = modified.map((env) => env.EnvironmentId);

  const originalEnvHash = _.keyBy(original, (cert) => cert.EnvironmentId);
  const modifiedEnvHash = _.keyBy(modified, (cert) => cert.EnvironmentId);

  const addedServices: ServicePayloadItem[] = [];
  const removedServices: ServicePayloadItem[] = [];

  _.union(originalEnvIds, modifiedEnvIds).forEach((envId) => {
    const diffRes = diffService(
      originalEnvHash[envId]?.Services,
      modifiedEnvHash[envId]?.Services,
      certId,
      envId,
      allowNullServiceId,
    );

    if (diffRes.addServices) {
      addedServices.push(...diffRes.addServices);
    }

    if (diffRes.removeServices) {
      removedServices.push(...diffRes.removeServices);
    }
  });

  return {
    addServices: addedServices.length > 0 ? addedServices : null,
    removeServices: removedServices.length > 0 ? removedServices : null,
  };
};

/**
 * Create an object that includes differences of original and modified services.
 * @param original original services (undefined means not existing in original tree-data while empty represents select all)
 * @param modified modified services (undefined means not existing in modified tree-data while empty represents select all)
 * @param certId certification family ID
 * @param envId environment ID
 * @param allowNullServiceId flag of whether to include { serviceId: null } in the payload
 */
const diffService = (
  original: AuditEventService[] | undefined,
  modified: AuditEventService[] | undefined,
  certId: number,
  envId: number,
  allowNullServiceId: boolean,
): ServicesDiffResult => {
  const originalServIds = original?.map((serv) => serv.Id);
  const modifiedServIds = modified?.map((serv) => serv.Id);
  const removedServs = _.difference(originalServIds, modifiedServIds ?? []);
  const addedServs = _.difference(modifiedServIds, originalServIds ?? []);

  const res: ServicesDiffResult = {
    addServices: null,
    removeServices: null
  };

  if (addedServs.length > 0) {
    res.addServices = addedServs.map((serv) => ({
      CertificationFamilyId: certId,
      EnvironmentId: envId,
      ServiceId: serv,
    }));
  } else if (
    allowNullServiceId &&
    (!originalServIds ||
      (modifiedServIds &&
        modifiedServIds.length === 0 &&
        removedServs.length > 0))
  ) {
    // Update to "Select all services" from "Select certain services"
    // `addedServs` is implied to be empty and
    //    1) `originalServIds` is undefined, representing adding a new item, OR
    //    2) `modifiedServIds` is not undefined but empty, representing changing the existing service to "select all"
    res.addServices = [
      {
        CertificationFamilyId: certId,
        EnvironmentId: envId,
        ServiceId: null,
      },
    ];
  }

  if (removedServs.length > 0) {
    res.removeServices = removedServs.map((serv) => ({
      CertificationFamilyId: certId,
      EnvironmentId: envId,
      ServiceId: serv,
    }));
  } else if (
    allowNullServiceId &&
    (!modifiedServIds ||
      (originalServIds &&
        originalServIds.length === 0 &&
        addedServs.length > 0))
  ) {
    // Update from "Select all services" to "Select certain services"
    // `removedServs` is implied to be empty and
    //    1) `modifiedServIds` is undefined, representing removing an item, OR
    //    2) `originalServIds` is not undefined but empty, representing changing "select all" to certain services
    res.removeServices = [
      {
        CertificationFamilyId: certId,
        EnvironmentId: envId,
        ServiceId: null,
      },
    ];
  }

  return res;
};

const diffAuditors = (
  original: AzureAdObject[],
  modified: AzureAdObject[],
): AuditorsDiffResult => {
  const originalIds = original.map((or) => or.ObjectId);
  const modifiedIds = modified.map((mo) => mo.ObjectId);

  const removedAuditors = _.difference(originalIds, modifiedIds);
  const addedAuditors = _.difference(modifiedIds, originalIds);

  return {
    addAuditors: addedAuditors.length > 0 ? addedAuditors : null,
    removeAuditors: removedAuditors.length > 0 ? removedAuditors : null,
  };
};

const diffOwners = (
  original: AzureAdObject[],
  modified: AzureAdObject[],
): OwnersDiffResult => {
  const originalIds = original.map((or) => or.ObjectId);
  const modifiedIds = modified.map((mo) => mo.ObjectId);

  const removedOwners = _.difference(originalIds, modifiedIds);
  const addedOwners = _.difference(modifiedIds, originalIds);

  return {
    addOwners: addedOwners.length > 0 ? addedOwners : null,
    removeOwners: removedOwners.length > 0 ? removedOwners : null,
  };
};

export const diffAuditEventAttachments = (
  original: AuditEventAttachment[],
  modified: AuditEventAttachment[],
): AttachmentsDiffResult => {
  const compareAttachment = (
    originAttachment: AuditEventAttachment,
    editAttachment: AuditEventAttachment,
  ) => {
    return (
      originAttachment.Title !== editAttachment.Title ||
      originAttachment.FileName !== editAttachment.FileName
    );
  };

  const getUpdateAttachment = (
    originAttachment: AuditEventAttachment,
    editAttachment: AuditEventAttachment,
  ) => {
    return {
      AttachmentGuid: originAttachment.AttachmentGuid,
      SetTitle:
        originAttachment.Title !== editAttachment.Title ?
          {
            Value: editAttachment.Title
          } :
          null,
      SetFileName:
        originAttachment.FileName !== editAttachment.FileName ?
          {
            Value: editAttachment.Title
          } :
          null,
      SetBlobGuid: null,
    };
  };

  const getNewAttachment = (
    editAttachment: AuditEventAttachment,
  ): AddAttachment => {
    return {
      BlobGuid: editAttachment.BlobGuid as string,
      FileName: editAttachment.FileName,
      Title: editAttachment.Title,
    };
  };

  return diffAttachments(
    original,
    modified,
    compareAttachment,
    getUpdateAttachment,
    getNewAttachment,
  );
};

const diffOther = (original: AuditEvent, modified: AuditEvent): OtherDiffResult => {
  const res: OtherDiffResult = {};

  if (original.IsAutoPublishEnabled !== modified.IsAutoPublishEnabled) {
    res.setIsAutoPublishEnabled = {
      Value: modified.IsAutoPublishEnabled
    };
  }

  if (original.CanBypassPublishScopeCheck !== modified.CanBypassPublishScopeCheck) {
    res.setCanBypassPublishScopeCheck = {
      Value: modified.CanBypassPublishScopeCheck
    };
  }

  if (original.IsReuseFromAllEnabled !== modified.IsReuseFromAllEnabled) {
    res.setIsReuseFromAllEnabled = {
      Value: modified.IsReuseFromAllEnabled
    };
  }

  if (original.IsPublishedEvidenceReusable !== modified.IsPublishedEvidenceReusable) {
    res.setIsPublishedEvidenceReusable = {
      Value: modified.IsPublishedEvidenceReusable
    };
  }

  if (original.IsEvidenceConclusionDisabled !== modified.IsEvidenceConclusionDisabled) {
    res.setIsEvidenceConclusionDisabled = {
      Value: modified.IsEvidenceConclusionDisabled
    };
  }

  if (original.IsMessagingEnabled !== modified.IsMessagingEnabled) {
    res.setIsMessagingEnabled = {
      Value: modified.IsMessagingEnabled
    };
  }

  return res;
};

export const diffAuditFlags = (original: AuditFlags[], modified: AuditFlags[]): AuditFlagsDiffResult => {
  const res: AuditFlagsDiffResult = {};

  const addedFlags = _.difference(modified, original);
  const removedFlags = _.difference(original, modified);

  if (addedFlags.length > 0 || removedFlags.length > 0) {
    res.updateAuditFlags = {
      ..._.fromPairs(addedFlags.map((flag) => [flag, true])),
      ..._.fromPairs(removedFlags.map((flag) => [flag, false])),
    };
  }

  return res;
};

/**
 * Used to update the reuse configurations. It reuses the UpdateAuditEvent API so must produce payload data with the same schema as Update API.
 */
export const diffReuseConfigurations = (
  original: ReuseConfiguration[],
  modified: ReuseConfiguration[],
): ReuseConfigurationsDiffResult => {
  const addReuseConfigurations: ReuseConfigurationPayloadItem[] = [];
  const removeReuseConfigurations: ReuseConfigurationPayloadItem[] = [];

  const originalAeGuids = original.map((rc) => rc.AuditEventGuid);
  const modifiedAeGuids = modified.map((rc) => rc.AuditEventGuid);

  const removedAeGuids = _.difference(originalAeGuids, modifiedAeGuids);
  const addedAeGuids = _.difference(modifiedAeGuids, originalAeGuids);
  const updatedAeGuids = _.intersection(originalAeGuids, modifiedAeGuids);

  const originalAeDict = _.keyBy(original, (cert) => cert.AuditEventGuid);
  const modifiedAeDict = _.keyBy(modified, (cert) => cert.AuditEventGuid);

  // For those removed ones, create 'removeReuseConfigurations' patching details array.
  removedAeGuids.forEach((aeGuid) => {
    const diffCerts = diffCertification(
      originalAeDict[aeGuid]?.Certifications ?? [],
      [],
      true,
    );
    diffCerts.removeServices?.forEach((svDiffItem) => {
      removeReuseConfigurations?.push({
        AuditEventSourceGuid: aeGuid,
        ...svDiffItem,
      });
    });
  });

  // For those added/updated ones, create 'removeReuseConfigurations'/'addReuseConfigurations' patching details array.
  [...addedAeGuids, ...updatedAeGuids].forEach((aeGuid) => {
    const diffCerts = diffCertification(
      originalAeDict[aeGuid]?.Certifications ?? [],
      modifiedAeDict[aeGuid]?.Certifications ?? [],
      true,
    );
    diffCerts.removeServices?.forEach((svDiffItem) => {
      removeReuseConfigurations?.push({
        AuditEventSourceGuid: aeGuid,
        ...svDiffItem,
      });
    });
    diffCerts.addServices?.forEach((svDiffItem) => {
      addReuseConfigurations?.push({
        AuditEventSourceGuid: aeGuid,
        ...svDiffItem,
      });
    });
  });

  return {
    addReuseConfigurations:
      addReuseConfigurations.length > 0 ? addReuseConfigurations : null,
    removeReuseConfigurations:
      removeReuseConfigurations.length > 0 ? removeReuseConfigurations : null,
  };
};

export const diffAdoConfiguration = (
  original: AdoConfiguration | null,
  modified: AdoConfiguration | null,
): AdoConfigurationDiffResult => {
  return {
    setAdoConfiguration: _.isEqual(original, modified) ?
      undefined :
      {
        Value: modified
      },
  };
};

export const diffAipConfiguration = (
  original: AipConfiguration | null,
  modified: AipConfiguration | null,
): AipConfigurationDiffResult => {
  return {
    setAipConfiguration: _.isEqual(original, modified) ?
      undefined :
      {
        Value: modified
      },
  };
};

const diffSocIntros = (original: SocIntro[], modified: SocIntro[]) => {
  const originalSocIntroIds = original.map((soc) => soc.Id);
  const modifiedSocIntroIds = modified.map((soc) => soc.Id);

  const removedSocIntroIds = _.difference(originalSocIntroIds, modifiedSocIntroIds);
  const removedSocIntros: RemoveSocIntroItem[] = [];
  removedSocIntroIds.map((id) => {
    if (id) {
      removedSocIntros.push({
        SocIntroId: id
      });
    }
  });

  const addedSocIntroIds = _.difference(modifiedSocIntroIds, originalSocIntroIds);
  const addedSocIntros: AddSocIntroPayloadItem[] =
  getAddedSocIntroPayloadItems(modified.filter((soc) => addedSocIntroIds.includes(soc.Id)));

  const updatedSocIntroIds = _.intersection(modifiedSocIntroIds, originalSocIntroIds);
  const updatedSocIntros: UpdateSocIntroPayloadItem[] = [];

  const originalSocIntroMap = new Map();
  original.map((soc) => {
    originalSocIntroMap.set(soc.Id, soc);
  });

  const modifiedSocIntroMap = new Map();
  modified.map((soc) => {
    modifiedSocIntroMap.set(soc.Id, soc);
  });

  updatedSocIntroIds.map((id) => {
    if (!_.isEqual(originalSocIntroMap.get(id), modifiedSocIntroMap.get(id))) {
      const originalSoc:SocIntro = originalSocIntroMap.get(id);
      const modifiedSoc:SocIntro = modifiedSocIntroMap.get(id);


      const originalSocServices = originalSoc.SocWorkload.SocServiceDetails.map((s) => s.Id);
      const modifiedSocServices = modifiedSoc.SocWorkload.SocServiceDetails.map((s) => s.Id);

      updatedSocIntros.push({
        SocIntroId: modifiedSoc.Id ?? -1,
        SetGroupingType: {
          Value: modifiedSoc.GroupingType
        },
        SetSocWorkloadId: {
          Value: modifiedSoc.SocWorkload.Id
        },
        SetNeedsBulkCreation: {
          Value: modifiedSoc.NeedsBulkCreation,
        },
        SetAuditStatus: {
          Value: modifiedSoc.AuditStatus ?? SocAuditStatus.NotStarted
        },
        AddSocServices: _.difference(modifiedSocServices, originalSocServices).map((s) => {
          return {
            Id: s
          };
        }),
        RemoveSocServices: _.difference(originalSocServices, modifiedSocServices).map((s) => {
          return {
            Id: s
          };
        }),
      });
    }
  });
  return {
    addSocIntros: addedSocIntros.length > 0 ? addedSocIntros : null,
    updateSocIntros: updatedSocIntros.length > 0 ? updatedSocIntros : null,
    removeSocIntros: removedSocIntros.length > 0 ? removedSocIntros : null,
  };
};


export const getAddedSocIntroPayloadItems = (
  socIntros: SocIntro[],
): AddSocIntroPayloadItem[] => {
  const addedSocIntros: AddSocIntroPayloadItem[] = [];

  socIntros.map((soc) => {
    addedSocIntros.push({
      GroupingType: soc.GroupingType,
      SocWorkloadId: soc.SocWorkload.Id,
      SetNeedsBulkCreation: {
        Value: soc.NeedsBulkCreation,
      },
      SetAuditStatus: soc.AuditStatus ?? SocAuditStatus.NotStarted,
      AddSocServices: soc.SocWorkload.SocServiceDetails.map((s) => {
        return {
          Id: s.Id
        };
      })
    });
  });

  return addedSocIntros;
};

export const checkAuditEventUpdated = (
  diff: UpdateAuditEventPayload,
): boolean => {
  return Object.entries(diff).some(([key, value]) => key !== 'auditEventGuid' && !_.isNil(value));
};
