
import { OdataQuery } from 'odata';

import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';

import {
  GatingTrace,
  GatingTraceInput,
  GatingTraceResponse
} from '../../models';

import { sortingOrder } from '../../utils/sorting';
import { get } from '../OdataApiService';
import {
  buildODataQuery,
  buildOrderByString,
  fieldMatchOnly
} from '../utils/odataQueryBuilder';


export const HERA_GATINGTRACE = '/GatingTrace';

enum OrderByAllowedValues {
  CreatedTime = 'CreatedTime'
}

const filterCreator = (input: GatingTraceInput) : string => {
  const filter: string[] = [];

  if (input.deployableService) {
    filter.push(fieldMatchOnly(input.deployableService, intl.get(LocIds.Gating.DeployableService)));
  }

  if (input.deployableServiceType) {
    filter.push(fieldMatchOnly(input.deployableServiceType, intl.get(LocIds.Gating.DeployableServiceType)));
  }

  if (input.organization) {
    filter.push(fieldMatchOnly(input.organization, intl.get(LocIds.Gating.Organization)));
  }

  if (input.project) {
    filter.push(fieldMatchOnly(input.project, intl.get(LocIds.Gating.Project)));
  }

  if (input.repository) {
    filter.push(fieldMatchOnly(input.repository, intl.get(LocIds.Gating.Repository)));
  }

  if (input.overallCompliantStatus) {
    filter.push(fieldMatchOnly(input.overallCompliantStatus, intl.get(LocIds.Gating.OverallCompliantStatus)));
  }

  if (input.buildId) {
    filter.push(fieldMatchOnly(input.buildId, intl.get(LocIds.Gating.BuildId)));
  }

  return filter.join(intl.get(LocIds.Gating.And));
};

export const GatingTraceAPI = {
  async getGatingTrace(gatingTraceInput: GatingTraceInput): Promise<GatingTraceResponse> {
    const filter = filterCreator(gatingTraceInput);
    let odataQuery: OdataQuery;

    if (filter.length === 0) {
      odataQuery = {
        $top: gatingTraceInput.top,
        $skip: gatingTraceInput.skip,
        $orderby: buildOrderByString((OrderByAllowedValues.CreatedTime + ' ' + sortingOrder.DESCENDING))
      };
    } else {
      odataQuery = {
        $filter: filter,
        $top: gatingTraceInput.top,
        $skip: gatingTraceInput.skip,
        $orderby: buildOrderByString((OrderByAllowedValues.CreatedTime + ' ' + sortingOrder.DESCENDING))
      };
    }

    const response = await get<GatingTrace[]>(
      HERA_GATINGTRACE,
      {
        odataQuery
      }
    );
    return {
      Items: response
    };
  },
  async getGatingTraceCount(gatingTraceInput: GatingTraceInput): Promise<number> {
    const countUrl = HERA_GATINGTRACE + '/$count';
    const filters = filterCreator(gatingTraceInput);
    const odataQuery: OdataQuery = filters.length > 0 ?
      {
        $filter: filters
      } :
      {};
    return await get<number>(
      countUrl,
      {
        odataQuery
      }
    );
  },
  async getGatingTraceById(correlationId: string): Promise<GatingTrace | null> {
    const odataQuery: OdataQuery = buildODataQuery({
      filter: {
        CorrelationId: {
          eq: correlationId
        }
      }
    });
    const response = await get<GatingTrace[]>(
      HERA_GATINGTRACE,
      {
        odataQuery
      }
    );

    if (response.length > 0) {
      return response[0];
    }

    return null;
  }
};
