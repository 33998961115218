import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { combineReducers } from 'redux';

import {
  authSlice,
  initialState as authInitialState
} from './authSlice/slice';
import {
  coachmarksSlice,
  initialState as coachmarksInitialState
} from './coachmarkSlice/slice';
import {
  configSlice, initialState as configInitialState
} from './configSlice/slice';
import {
  networkStatusSlice, initialState as networkStatusInitialState
} from './networkStatusSlice/slice';
import {
  settingsPanelSlice, initialState as settingsPanelInitialState
} from './settingsPanelSlice/slice';
import {
  userInteractionSlice,
  initialState as userInteractionInitialState
} from './userInteractionSlice/slice';

export const reducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [configSlice.name]: configSlice.reducer,
  [userInteractionSlice.name]: userInteractionSlice.reducer,
  [coachmarksSlice.name]: coachmarksSlice.reducer,
  [settingsPanelSlice.name]: settingsPanelSlice.reducer,
  [networkStatusSlice.name]: networkStatusSlice.reducer
});

export type RootReduxState = {
  portal: ReturnType<typeof reducer>;
};

export const initialRootState: RootReduxState = {
  portal: {
    [authSlice.name]: authInitialState,
    [configSlice.name]: configInitialState,
    [userInteractionSlice.name]: userInteractionInitialState,
    [coachmarksSlice.name]: coachmarksInitialState,
    [settingsPanelSlice.name]: settingsPanelInitialState,
    [networkStatusSlice.name]: networkStatusInitialState
  }
};

// ---------------------------------------------------------------------------------------------------------------------

const store = configureStore({
  reducer: {
    portal: reducer
  }
});

type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
