import './style.less';

import React, { useRef } from 'react';

import {
  FontIcon,
  IBasePickerStyleProps,
  IBasePickerStyles,
  IBasePickerSuggestionsProps,
  IInputProps,
  IPeoplePickerProps,
  IPersonaProps,
  IStyleFunctionOrObject,
  ListPeoplePicker,
  NormalPeoplePicker,
  PeoplePickerItemSuggestion,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import _ from 'lodash';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  AzureAdMember, AzureAdMemberType
} from '../../models';

export enum PeoplePickerViewMode {
  /* inline face tiles */
  normal,
  /* input box always displayed with face tiles put below */
  list,
}

export interface PeoplePickerProps {
  selectedEntities: AzureAdMember[];
  onChange: (items: AzureAdMember[]) => void;
  onSearch: (value: string) => Promise<AzureAdMember[]>;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  /* Used to display exceeding error message and remove extra line in the input box. PeoplePicker.itemLimit is
     unavailable because of unresolved component issues @see https://github.com/microsoft/fluentui/issues/16367 */
  itemLimit?: number;
  viewMode?: PeoplePickerViewMode;
  showError?: boolean;
  suggestionFilterFunc?: (entity: AzureAdMember) => boolean;
  //If true, alias will be displayed after selection, instead of AzureAdMember DisplayName
  displayAlias?: boolean;
  //If true, the selection will contain both user and group, we rerender icon to distinguish them
  showBothUserAndGroup?: boolean;
}

// duration in milliseconds that queries should be throttled
const RESOLVE_DELAY = 500;
const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: intl.get(LocIds.Components.SearchResults),
  noResultsFoundText: intl.get(LocIds.Components.NoResultsFound),
  loadingText: intl.get(LocIds.Label.Loading),
  showRemoveButtons: false,
  suggestionsContainerAriaLabel: intl.get(LocIds.Components.SearchResults),
};

type IPersonaWithAccountGuid = IPersonaProps & { accountGuid?: string };

export const ThrottledPeoplePicker: React.FC<PeoplePickerProps> = (props) => {
  const { viewMode = PeoplePickerViewMode.list, itemLimit, showBothUserAndGroup } = props;

  const [errorVisible, { setTrue: showError }] = useBoolean(props.showError ?? false);

  const picker = useRef(null);
  const selectedPersonas = getPersonasFromEntities(
    props.selectedEntities ?? [],
    showBothUserAndGroup
  );

  const onResolveSuggestions = async (
    text: string,
    currentPersonas?: IPersonaProps[],
  ): Promise<IPersonaProps[]> => {
    if (text) {
      let response = await props.onSearch(text);

      const { suggestionFilterFunc } = props;

      if (suggestionFilterFunc) {
        response = response.filter(suggestionFilterFunc);
      }

      const personas = getPersonasFromEntities(response ?? [], showBothUserAndGroup);

      return removeDuplicates(personas, currentPersonas);
    }

    return [];

  };

  const onChange = (items: IPersonaWithAccountGuid[] | undefined): void => {
    props.onChange(getEntitiesFromPersonas(items ?? [], props.displayAlias));
  };

  const errorMessage = errorVisible ?
    getErrorMessage(selectedPersonas.length, props.required, itemLimit) :
    undefined;

  const inputProps: IInputProps = {
    disabled: props.disabled,
    required: props.required,
    placeholder:
      selectedPersonas.length === 0 ?
        props.placeholder ? props.placeholder :
          intl.get(LocIds.Placeholder.TypeToSearch) :
        undefined,
  };

  // customized styles
  const styles: IStyleFunctionOrObject<
    IBasePickerStyleProps,
    IBasePickerStyles
  > = {};

  if (!_.isNil(errorMessage)) {
    styles.text = {
      borderColor: '#a4262c'
    };
  }

  if (
    viewMode === PeoplePickerViewMode.normal &&
    !_.isNil(itemLimit) &&
    selectedPersonas.length >= itemLimit
  ) {
    // NormalPeoplePicker displays an extra line even when the input is disabled (item number exceeds), use this
    // workaround to remove the extra line.
    styles.input = {
      display: 'none'
    };
  }

  const peoplePickerProps: IPeoplePickerProps = {
    componentRef: picker,
    selectedItems: selectedPersonas,
    onChange,
    onResolveSuggestions,
    resolveDelay: RESOLVE_DELAY,
    getTextFromItem,
    pickerSuggestionsProps: suggestionProps,
    inputProps,
    key: 'peoplePicker',
    removeButtonAriaLabel: intl.get(LocIds.Action.Remove),
    styles,
    onRenderSuggestionsItem: (personaProps, suggestionProps) => (
      <PeoplePickerItemSuggestion
        personaProps={personaProps}
        styles={{
          personaWrapper: {
            width: '100%'
          }
        }}
        suggestionsProps={suggestionProps}
      />
    ),
    onBlur: showError,
    disabled: props.disabled,
    itemLimit: props.itemLimit
  };

  return (
    <div>
      { viewMode === PeoplePickerViewMode.normal && (
        <NormalPeoplePicker {...peoplePickerProps} />
      ) }
      { viewMode === PeoplePickerViewMode.list && (
        <ListPeoplePicker {...peoplePickerProps} />
      ) }
      { errorVisible && errorMessage && (
        <div role='alert'>
          <p className='errorMessage'>
            <span>{ errorMessage }</span>
          </p>
        </div>
      ) }
    </div>
  );
};

const removeDuplicates = (
  personas: IPersonaProps[],
  possibleDupes?: IPersonaProps[],
): IPersonaProps[] => {
  if (possibleDupes) {
    return personas.filter(
      (persona) => !listContainsPersona(persona, possibleDupes),
    );
  }

  return personas;
};

const listContainsPersona = (
  persona: IPersonaProps,
  personas: IPersonaProps[],
): boolean => {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }

  return personas.filter((item) => item.id === persona.id).length > 0;
};

const getTextFromItem = (persona: IPersonaProps): string => {
  return persona.text as string;
};

const getPersonasFromEntities = (
  entities: AzureAdMember[],
  showBothUserAndGroup?: boolean
): IPersonaWithAccountGuid[] => {
  const onRenderUserCoin = () => {
    return (
      <div>
        <FontIcon iconName='Contact'
          style={{
            fontSize: 15,
          }}
        />
      </div>
    );
  };
  const onRenderGroupCoin = () => {
    return (
      <div>
        <FontIcon iconName='People'
          style={{
            fontSize: 18,
          }}
        />
      </div>
    );
  };
  return Array.from(entities).map((entity) => ({
    id: entity.Id,
    text: entity.DisplayName,
    secondaryText: entity.UserPrincipalName ?? entity.Mail,
    tertiaryText: entity.Alias,
    accountGuid: entity.AccountGuid,
    onRenderPersonaCoin: showBothUserAndGroup ? (entity.type === AzureAdMemberType.Group ? onRenderGroupCoin : onRenderUserCoin) : undefined
  }));
};

const getEntitiesFromPersonas = (
  personas: IPersonaWithAccountGuid[], displayAlias?: boolean
): AzureAdMember[] => {
  return Array.from(personas).map((persona) => ({
    Id: persona.id ?? '',
    DisplayName: (displayAlias ? persona.tertiaryText : persona.text) ?? '',
    Mail: persona.secondaryText ?? '',
    Alias: persona.tertiaryText ?? '',
    AccountGuid: persona.accountGuid,
  }));
};

// construct the error message based on the selection items count and the required number limits
const getErrorMessage = (
  length: number,
  isRequired?: boolean,
  itemLimit?: number,
): string | undefined => {
  if (isRequired && length === 0) {
    return intl.get(LocIds.Components.PleaseMakeASelection);
  }

  if (!_.isNil(itemLimit) && length > itemLimit) {
    return intl.get(LocIds.Components.SelectionLimitExceeded);
  }

  return undefined;
};
