import {
  RefObject,
  useCallback,
  useLayoutEffect
} from 'react';


interface IProps {
    hasHeader: boolean;
    asideRef: RefObject<HTMLDivElement>;
    headerRef: RefObject<HTMLDivElement>;
    asideTop?: number
}

export const useLayoutAsideHeight = ({ asideRef, headerRef, hasHeader, asideTop }: IProps) => {

  const calculateAside = useCallback(() => {
    if (!asideRef.current || (!headerRef.current && hasHeader)) {
      return;
    }

    let top = asideTop ?? 0;

    if (headerRef.current) {
      const { top: headerTop, height } = headerRef.current.getBoundingClientRect();
      top = headerTop + height;
    }

    window.requestAnimationFrame(() => {
      if (asideRef.current) {
        asideRef.current.style.top = `${top}px`;
        asideRef.current.style.height = `${window.innerHeight - top}px`;
      }
    });
  }, [asideRef, hasHeader, asideTop]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      calculateAside();
      window.addEventListener('resize', calculateAside);
      window.addEventListener('scroll', calculateAside);
    });

    return () => {
      window.removeEventListener('resize', calculateAside);
      window.removeEventListener('scroll', calculateAside);

      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [calculateAside]);
};
