import React, {
  MouseEvent,
  useCallback
} from 'react';

import {
  ActionButton,
  DefaultButton,
  IButtonStyles,
  ITooltipHostStyles,
  Spinner,
  TooltipHost,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

import { ICommandBarButtonProps } from '../M365Button/M365CommandBarButton/M365CommandBarButton';

export enum CardActionButtonStyle {
  Default,
  Action,
}

type IProps = ICommandBarButtonProps & { buttonStyle?: CardActionButtonStyle };

const calloutProps = {
  gapSpace: 0
};
const hostStyles: Partial<ITooltipHostStyles> = {
  root: {
    display: 'inline-block'
  },
};


export const CardActionButton: React.FC<IProps> = (props) => {
  const { buttonStyle = CardActionButtonStyle.Default } = props;

  const tooltipId = useId('tooltip');

  const handleStopPropagation = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    },
    [],
  );
  const buttonStyles: IButtonStyles = {
    textContainer: {
      order: 2
    },
    icon: {
      display: props.loading ? 'none' : 'inline-block'
    }
  };

  const loader = props.loading && (
    <Spinner className='m365-commandBarButton-spinner' size={1} />
  );

  return (
    <div onClick={handleStopPropagation}>
      <TooltipHost
        calloutProps={calloutProps}
        content={props.title}
        id={tooltipId}
        styles={hostStyles}>
        { buttonStyle === CardActionButtonStyle.Action ? (
          <ActionButton {...props} styles={buttonStyles}>{ loader }</ActionButton>
        ) : (
          <DefaultButton {...props} styles={buttonStyles}>{ loader }</DefaultButton>
        ) }
      </TooltipHost>
    </div>
  );
};
