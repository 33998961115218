import './style.less';

import React from 'react';


import { IconLabel } from './IconLabel';
import { LabelColor } from './type';

export interface StyledLabelProps {
  text: string;
  color: LabelColor,
  ring?: boolean
}

export const IndicatorLabel: React.FC<StyledLabelProps> = ({ text, color, ring = false }) => {
  return (
    <IconLabel color={color} iconName={ring ? 'StatusCircleRing' : 'StatusCircleInner'} text={text} />
  );
};
