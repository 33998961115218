import React, {
  useEffect, useState
} from 'react';

import { Checkbox } from '@fluentui/react';

import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  Nullable, ReferencedService, Service
} from '../../models';
import { ServiceDropdown } from '../EntitySelection';


type IProps = {
  complianceAuditId: number | undefined;
  certFamilyId: number | null | undefined;
  environmentId: number | null | undefined;
  selectedProvider: Service | undefined;
  selectedConsumers: Service[];
  defaultProvider: Nullable<ReferencedService>;
  defaultConsumers: ReferencedService[];
  onChangeProvider: (provider: Service) => void;
  onChangeConsumers: (consumers: Service[]) => void;
  disabled?: boolean;
}

export const BeneficiaryConfigurationGroup: React.FC<IProps> = (props) => {
  const { complianceAuditId, certFamilyId, environmentId, selectedProvider, selectedConsumers, defaultProvider, defaultConsumers, onChangeProvider, onChangeConsumers, disabled } = props;

  const [isSeparateChecked, setIsSeparateChecked] = useState(false);

  useEffect(() => {
    if (defaultConsumers.length > 1 || defaultConsumers[0]?.ServiceTreeId !== defaultProvider?.ServiceTreeId) {
      setIsSeparateChecked(true);
    }
  }, [defaultProvider, defaultConsumers]);

  const providerServiceDropdown = (
    <ServiceDropdown
      certificationFamilyId={certFamilyId}
      complianceAuditId={complianceAuditId}
      defaultValues={defaultProvider ? [
        {
          guid: defaultProvider.ServiceTreeId,
          name: defaultProvider.ServiceName,
        }
      ] : []}
      environmentId={environmentId}
      label={intl.get(LocIds.Label.ProviderService)}
      selected={selectedProvider ? [selectedProvider] : undefined}
      onChange={items => {
        onChangeProvider(items[0]);

        if (!isSeparateChecked) {
          onChangeConsumers(items);
        }
      }}
    />
  );

  const separateServiceCheckbox = (
    <Checkbox
      checked={isSeparateChecked}
      disabled={disabled}
      label={intl.get(LocIds.Evidence.SeparateProviderConsumerServices)}
      onChange={(_, checked) => {
        setIsSeparateChecked(checked ?? false);

        if (!checked && selectedProvider) {
          onChangeConsumers([selectedProvider]);
        }
      }}
    />
  );

  const consumerServicesDropdown = (
    <ServiceDropdown
      certificationFamilyId={certFamilyId}
      complianceAuditId={complianceAuditId}
      defaultValues={defaultConsumers.map(t => ({
        guid: t.ServiceTreeId,
        name: t.ServiceName
      }))}
      disabled={!isSeparateChecked}
      environmentId={environmentId}
      isMulti={true}
      label={intl.get(LocIds.Label.ConsumerServices)}
      selected={selectedConsumers}
      onChange={onChangeConsumers}
    />
  );

  return (
    <>
      { providerServiceDropdown }
      { separateServiceCheckbox }
      { consumerServicesDropdown }
    </>
  );
};
