import _ from 'lodash';

import { ConMonAuditEvent } from '../../../../models/ContinuousMonitoringTypes';

export const getIdsFromKey = (key: string) => {
  const keys = key.split(':');

  // AuditEventGuid:TaskId:EnvironmentId
  if (keys.length === 3) {
    const taskId = parseInt(keys[1]);
    const environmentId = parseInt(keys[2]);

    if (!_.isNaN(taskId) && !_.isNaN(environmentId)) {
      return {
        taskId,
        environmentId,
      };
    }
  }

  return undefined;
};


export const buildScheduleEnvironmentName = (auditEvents: ConMonAuditEvent[], taskId: number, environmentId: number) => {
  for (const auditEvent of auditEvents) {
    const targetTask = auditEvent.tasks.find((task) => task.id === taskId);

    if (targetTask) {
      const targetEnvironment = targetTask.environments.find((env) => env.id === environmentId);

      if (targetEnvironment) {
        return `${auditEvent.name} - ${targetTask.name} - ${targetEnvironment.name}`;
      }
    }
  }

  return '';
};
