import React, {
  useEffect, useState
} from 'react';

import {
  useNavigate, useParams
} from 'react-router-dom';

import {
  EvidenceProposalPanelMode, ProposedEvidencePanel
} from './ProposedEvidencePanel';

import {
  AuditEvent, ProposedEvidence
} from '../../../models';
import {
  AuditEventApi, ProposedEvidenceApi
} from '../../../services';

type IProps = {
  isAdmin: boolean;
}

export const ProposedEvidenceDetailPanel: React.FC<IProps> = (props) => {
  const { isAdmin } = props;

  const navigate = useNavigate();
  const { auditEventGuid, proposedEvidenceGuid } = useParams();

  const [auditEvent, setAuditEvent] = useState<AuditEvent>();
  const [proposedEvidence, setProposedEvidence] = useState<ProposedEvidence>();

  useEffect(() => {
    if (auditEventGuid) {
      AuditEventApi.getPublishedAuditEventByGuidAsync(auditEventGuid)
        .then((response) => {
          setAuditEvent(response);
        });
    }
  }, [auditEventGuid]);

  const reloadEvidence = () => {
    if (auditEventGuid && proposedEvidenceGuid) {
      ProposedEvidenceApi.getProposedEvidenceAsync(auditEventGuid, proposedEvidenceGuid)
        .then((response) => {
          setProposedEvidence(response);
        });
    }
  };

  useEffect(() => {
    reloadEvidence();
  }, [auditEventGuid, proposedEvidenceGuid]);

  const onDismiss = () => {
    navigate('..');
  };

  const onGenerateEvidence = (proposedEvidence: ProposedEvidence) => {
    navigate(`../${proposedEvidence.ProposedEvidenceGuid}/generate`);
  };

  return (
    <ProposedEvidencePanel
      auditEvent={auditEvent}
      isAuditManager={isAdmin}
      isOpen={true}
      mode={EvidenceProposalPanelMode.Detail}
      proposedEvidence={proposedEvidence}
      onCreate={onGenerateEvidence}
      onDismiss={onDismiss}
      // TODO: How to trigger list page to reload the whole list?
      onReload={reloadEvidence}
    />
  );
};
