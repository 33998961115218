import React, { useEffect } from 'react';

import {
  Icon,
  IStackProps,
  Stack
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classnames from 'classnames';

import { FCC } from '../../models';

interface IProps {
  title: string | JSX.Element;
  /** Pass in a boolean value to control collapse/expand behavior. */
  collapsed?: boolean;
  chevronOnLeft?: boolean;
  onToggle?: () => void;
  className?: string;
  bordered?: boolean
}

export const CollapsibleSection: FCC<IProps> = (props) => {
  const { chevronOnLeft = false, onToggle = () => {}, collapsed: inheritedCollapsed, bordered = true } = props;

  const [collapsed, { toggle: toggleCollapsed, setTrue: collapse, setFalse: expand }] = useBoolean(false);

  useEffect(() => {
    if (inheritedCollapsed) {
      collapse();
    } else {
      expand();
    }
  }, [inheritedCollapsed]);

  const wrapperCls = classnames('collapsibleSection', props.className, {
    'collapsibleSection--collapsed': collapsed
  });

  const onClick = () => {
    toggleCollapsed();
    onToggle();
  };

  const stackProps: IStackProps = {
    horizontal: true,
    reversed: chevronOnLeft,
    horizontalAlign: chevronOnLeft ? 'end' : 'space-between',
    onClick,
  };

  return (
    <Stack className={wrapperCls}>
      <Stack
        className={classnames('collapsibleSection__titleRow', {
          'collapsibleSection__titleRow--bordered': bordered
        })}
        {...stackProps}>
        <span className='title-14'>
          { props.title }
        </span>
        <Icon
          className='collapsibleSection__chevronIcon is-right'
          iconName='ChevronUp'
        />
      </Stack>
      <div className='collapsibleSection__childrenWrapper'>{ props.children }</div>
    </Stack>
  );
};
