import './style.less';

import React from 'react';

import {
  Stack,
  Text
} from '@fluentui/react';

import classNames from 'classnames';

import { LabelColor } from './type';

type IProps = {
  color: LabelColor;
  label: string;
  value: string;
}

export const MetricLabel: React.FC<IProps> = (props) => {
  const classname = classNames('metricLabel', `metricLabel_${props.color}`);

  return (
    <Stack className={classname} horizontal>
      <Stack className='metricLabel_inner'>
        <Text className='text-12'>{ props.label }</Text>
        <Text className='title-16'>{ props.value }</Text>
      </Stack>
    </Stack>
  );
};
