/* eslint-disable i18next/no-literal-string */
import React from 'react';

import {
  Breadcrumb,
  IBreadcrumbProps,
  IBreadcrumbStyles
} from '@fluentui/react';
import _ from 'lodash';


import {
  Text16,
  Title16
} from '../../common/css/fonts';

export const Breadcrumbs: React.FC<IBreadcrumbProps> = (props: IBreadcrumbProps) => {

  const outerStyles = props.styles || {};

  const innerStyles: Partial<IBreadcrumbStyles> = {
    root: {
      marginTop: 0
    },
    listItem: {
      ':last-of-type .ms-Breadcrumb-itemLink': {
        ...Title16,
        color: 'var(--gray160)'
      },
      '.ms-Breadcrumb-item': {
        ...Title16
      }
    },
    itemLink: {
      ...Text16,
      color: 'var(--gray130)',
      padding: '6px 8px'
    }
  };

  const styles = _.merge(outerStyles, innerStyles);

  return (
    <Breadcrumb {...props} styles={styles}/>
  );
};
