export interface AzureAdMember {
  DisplayName: string;
  Id: string;
  Alias: string;
  Mail: string;
  Manager?: AzureAdMember;
  type?: AzureAdMemberType
  UserPrincipalName?: string;
  AccountGuid?: string;
}

export enum AzureAdMemberType {
  User,
  Group
}
