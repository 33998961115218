import { DashboardTreeNode } from '../DashboardTreeNode/DashboardTreeNode';
import { PowerBiReportMetadata } from '../PowerBiReportMetadata';

/**
 * Processes an array of reports and returns an array of DashboardTreeNode
 * @param reports an array of PowerBiReportMetadata
 * @param groupByScope If true, groups the reports by their scope, with each report nested under its corresponding scope node.
 * If false, returns a list of DashboardTreeNode without grouping
 * @returns an array of DashboardTreeNode
 */
export const createDashboardTree = function (
  reports: PowerBiReportMetadata[],
  groupByScope: boolean
): DashboardTreeNode[] {
  if (groupByScope) {
    const scopeMap: Map<string, DashboardTreeNode> = new Map();
    reports.forEach(report => {
      const scope = report.ScopeDisplayName ?? report.Scope;

      if (!scopeMap.has(scope)) {
        const scopeNode = new DashboardTreeNode(scope, `/report/${scope}`);
        scopeMap.set(scope, scopeNode);
      }

      scopeMap.get(scope)?.children.push(new DashboardTreeNode(report.Title, `/report/${report.ReportId}`));
    });
    const sortedScopeMapNodes = [...scopeMap.keys()].sort().map(scope => scopeMap.get(scope)!);
    return sortedScopeMapNodes;
  }

  const res: DashboardTreeNode[] = reports.map(
    (report) =>
      new DashboardTreeNode(report.Title, `/report/${report.ReportId}`),
  );
  return res;
};
