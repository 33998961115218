import _ from 'lodash';

import { getLinkIdentifier } from '../../../../components/SideNav/helper';
import { DocTreeNode } from '../../../../models/DocTreeNode/DocTreeNode';
import { DocTreeRootNode } from '../../../../models/DocTreeNode/DocTreeRootNode';
import { DocumentMenuItem } from '../../../../models/Document';

export const buildDocumentMenuTree = (items:DocumentMenuItem[]): DocTreeRootNode => {
  const nodeMap: Record<string, DocTreeNode> = {};
  const root = new DocTreeRootNode([]);
  items.map(item => ({
    ...item,
    pathNodes: item.Path.split('/')
  }))
    .sort((a, b) => a.pathNodes.length - b.pathNodes.length)
    .forEach(item => {
      let path = '';
      let parent: DocTreeRootNode | DocTreeNode = root;

      for (let i = 1; i < item.pathNodes.length; i++) {
        path += '/' + item.pathNodes[i];

        if (nodeMap[path]) {
          parent = nodeMap[path];
        } else {
          const uid = item.Path === path ? item.Uid : path.slice(1);
          const order = item.Path === path ? item.Order : 0;
          const curNode = new DocTreeNode(toShownText(item.pathNodes[i]), path, uid, order, []);
          nodeMap[path] = curNode;
          parent.children.push(curNode);
          parent = curNode;
        }
      }
    });
  root.children.forEach(n => findFirstValidPath(n));
  return root;
};

const findFirstValidPath = (node: DocTreeNode): string => {
  const cPaths = node.children.map(n => findFirstValidPath(n)).filter(p => !_.isNil(p));

  node.firstChildDocPath = cPaths[0] ?? '';
  return node.docPath || node.firstChildDocPath || '';
};

export const toShownText = (text: string): string => text.replaceAll('_', ' ');


export const getDefaultCollapseLinkMap = (nodes: DocTreeNode[]): Record<string, boolean> => {
  const result: Record<string, boolean> = {};
  nodes.forEach(n => {
    _.assign(result, getDefaultCollapseLinkMap(n.children));
    const identifier = getLinkIdentifier(n);

    if (identifier) {
      result[identifier] = n.collapsed;
    }
  });
  return result;
};
