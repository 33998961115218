import React from 'react';

import {
  Stack,
  Text
} from '@fluentui/react';

import intl from 'react-intl-universal';


import {
  containerStackTokens,
  overviewInfoLabelTextStyle,
  overviewInfoTextStyle
} from './styles';

import { LocIds } from '../../../../../../common/Globalization/IntlEnum';
import { CompliantStatus } from '../../../../../../models/Gating';
import { dateToLocalString } from '../../../../../../utils';
import { ComplianceStatusBadge } from '../../../../components/ComplianceStatusBadge';

interface OverviewInformationRibbonProps {
  gatingRecordId: string,
  triggeredAtTime: Date,
  complianceStatus: CompliantStatus
}
export const OverviewInformationRibbon: React.FC<OverviewInformationRibbonProps> = (props: OverviewInformationRibbonProps) => {
  return (
    <Stack
      horizontal
      tokens={containerStackTokens}
      verticalAlign='center'>
      <ComplianceStatusBadge
        complianceStatus={props.complianceStatus}
        inline={false}
      />
      <Text styles={overviewInfoLabelTextStyle} variant={'medium'}>
        { intl.get(LocIds.Gating.GatingRecordId) }
      </Text>
      <Text styles={overviewInfoTextStyle} variant={'medium'}>
        { props.gatingRecordId }
      </Text>
      <Text styles={overviewInfoLabelTextStyle} variant={'medium'}>
        { intl.get(LocIds.Gating.TriggeredAt) }
      </Text>
      <Text styles={overviewInfoTextStyle} variant={'medium'}>
        { dateToLocalString(props.triggeredAtTime) }
      </Text>
    </Stack>
  );
};
