import { useSelector } from 'react-redux';

import { RootReduxState } from '../reducer';

export const useSignInSelector = () => {
  return useSelector<RootReduxState, boolean>((store: RootReduxState) =>
    store.portal.auth.isSignIn);
};

export const useSignInFailedSelector = () => {
  return useSelector<RootReduxState, boolean>((store: RootReduxState) =>
    store.portal.auth.isSignInFailed);
};
