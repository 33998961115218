import React from 'react';

import { Stack } from '@fluentui/react';

import { FCC } from '../../models';

export const FormRow: FCC = (props) => {
  const { children } = props;

  return (
    <Stack horizontal
      tokens={{
        childrenGap: 12
      }}
      verticalAlign='start'
      wrap>
      { Array.isArray(children) ?
        children.map((child, index) => (
          <Stack.Item grow
            key={index}
            style={{
              flex: 1,
              minWidth: 200
            }}>
            { child }
          </Stack.Item>
        )) :
        children }
    </Stack>
  );
};
