import { getExtensionFromFileName } from './file';

import {
  Evidence,
  EvidenceRequestAttachment
} from '../models/Evidence';

export const decorateEvidenceAttachmentsWithProtectionInfo = (
  evidences: Evidence[],
  isProtectionEnabled: boolean,
  protectionFileTypes: Set<string>) => {
  return evidences.map(e => ({
    ...e,
    Attachments: decorateAttachmentsWithProtectionInfo(e.Attachments, isProtectionEnabled, protectionFileTypes)
  }));
};


export const decorateAttachmentsWithProtectionInfo = (
  attachments: EvidenceRequestAttachment[],
  isProtectionEnabled: boolean,
  protectionFileTypes: Set<string>) => {
  return attachments?.map(a => {
    const fileExt = getExtensionFromFileName(a.DisplayName);
    return {
      ...a,
      IsProtectionEnabled: isProtectionEnabled,
      IsFileTypeSupportedByAip: isProtectionEnabled && protectionFileTypes.has(`.${fileExt}`)
    };
  });
};
