/**
 * This file contains Athena Rest Apis
 */

import { OdataQuery } from 'odata';


import {
  PowerBiReportMetadata, ReportScope
} from '../../models';
import {
  get,
  post
} from '../OdataApiService';
import { fieldMatchAny } from '../utils/odataQueryBuilder';

export const GENERATE_POWERBI_EMBED_TOKEN_API = '/data/GeneratePowerBiToken';
export const GET_POWERBI_REPORT_METADATA_API = '/data/PowerBiReport';

export namespace PowerBiApi {
  export interface GeneratePowerBiTokenData {
    reportId: string;
  }

  export const generatePowerBiTokenAsync = async (
    reportId: string,
  ): Promise<string> => {
    const response = await post<GeneratePowerBiTokenData, string>(
      GENERATE_POWERBI_EMBED_TOKEN_API,
      {
        reportId,
      }
    );
    return response;
  };

  export const getPowerBiReportsMetadataAsync = async (
    scopes: ReportScope[],
  ): Promise<PowerBiReportMetadata[]> => {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchAny(scopes, 'Scope')
    };

    const config = {
      odataQuery,
    };
    const data = await get<PowerBiReportMetadata[]>(
      GET_POWERBI_REPORT_METADATA_API,
      config,
    );
    return data;
  };
}
// #endregion
