import './style.less';

import React from 'react';

import {
  DefaultButton,
  IButtonProps,
  IButtonStyles,
  ISpinnerStyles,
  Spinner
} from '@fluentui/react';
import classNames from 'classnames';

export interface ISecondaryButtonProps extends IButtonProps {
  loading?: boolean;
}

export const M365SecondaryButton: React.FC<ISecondaryButtonProps> = (
  props: ISecondaryButtonProps,
) => {
  const className = classNames(
    'm365-button',
    'm365-secondaryButton',
    props.className,
  );

  const spinnerStyles: ISpinnerStyles = {
    root: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  const buttonStyles: IButtonStyles = {
    textContainer: {
      opacity: props.loading ? 0 : 1
    }
  };

  const loader = props.loading && (
    <Spinner size={1} styles={spinnerStyles} />
  );

  return (
    <DefaultButton {...props} className={className} styles={buttonStyles}>
      { loader }
    </DefaultButton>
  );
};
