import React, { KeyboardEvent } from 'react';

import { TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import _ from 'lodash';
import intl from 'react-intl-universal';


import { CommonFilterProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  ComplianceAuditWorkload, Service
} from '../../models';
import { FilterFieldWrapper } from '../FilterFieldWrapper';
import { ServicesPanelForFilter } from '../ServicePanel';
import { ToolTip } from '../ToolTip';


export interface ServiceFilterProps extends CommonFilterProps<Service[], Service[]> {
  allWorkloads?: ComplianceAuditWorkload[];
  label?: string;
}

export function ServiceFilterWithPanel(
  props: ServiceFilterProps,
): ReturnType<React.FC<CommonFilterProps<Service[], Service[]>>> {
  const {
    allOptions,
    selected,
    onChange,
    disabled,
    label = intl.get(LocIds.Label.Service),
  } = props;

  const [isPanelOpen, { toggle: togglePanel }] = useBoolean(false);

  const onSelectServicesKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      togglePanel();
    }
  };

  const selectionText = selected?.map((serv) => serv.ShortName)?.join(', ');

  return (
    <>
      <FilterFieldWrapper
        ariaLabel={label}
        isClearActive={!_.isEmpty(selected)}
        onClear={() => onChange([])}>
        <ToolTip content={selectionText}>
          <TextField
            disabled={disabled}
            iconProps={{
              iconName: 'Edit'
            }}
            label={label}
            placeholder={intl.get(LocIds.Label.SelectServices)}
            readOnly
            value={selectionText}
            onClick={togglePanel}
            onKeyDown={onSelectServicesKeydown}
          />
        </ToolTip>
      </FilterFieldWrapper>
      <ServicesPanelForFilter
        allServices={allOptions}
        allWorkloads={props.allWorkloads}
        initialSelectedServices={selected}
        isOpen={isPanelOpen}
        onDismiss={togglePanel}
        onSave={onChange}
      />
    </>
  );
}
