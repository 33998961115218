import {
  createAsyncThunk,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { RootReduxState } from './reducer';

import {
  AssignedToMeFilterSelectedState,
  AuditEvent,
  CreatedDateOption,
  DueDateOption,
  Environment,
  EvidenceState,
  FilterGroup,
  FilterOptionsForServiceWorkspace,
  OrderByOption,
  Service,
  ServiceWorkspaceEvidenceSortKey,
  SortOption,
  TaskSource,
} from '../../../../../models';
import {
  AuditEventApi,
  EntityApi,
  ServiceWorkspaceApiService
} from '../../../../../services';
import { LocalStorageKeys, } from '../../../../../utils';

/**
 * Types
 */

export enum BoardOption {
  AssignedToMe,
  AssignedToMyServices,
  AssignedToMyWorkloads,
}

export type AssignedToMeFilterAllState = {
  state?: EvidenceState[];
  source: TaskSource[];
  auditEvent?: AuditEvent[];
  environment?: Environment[];
  service?: Service[];
  dueDate: DueDateOption[];
  createdDate: CreatedDateOption[];
};

export type FilterSliceState = {
  board: {
    dropdown: FilterGroup<BoardOption[], BoardOption>;
    options: {
      services: string[];
      workloads: string[];
    };
  };
  assignedToMe: {
    filter: FilterGroup<AssignedToMeFilterAllState, AssignedToMeFilterSelectedState>;
    keyword: string;
  };
  sortBy: SortOption<ServiceWorkspaceEvidenceSortKey>;
};

export const emptyAssignedToMeFilter: AssignedToMeFilterSelectedState = {
  state: [
    {
      EvidenceStateId: 1,
      ShortName: 'Requested'
    }
  ],
  source: undefined,
  auditEvent: undefined,
  environment: [],
  service: [],
  dueDate: undefined,
  createdDate: undefined,
};

const initialState: FilterSliceState = {
  board: {
    dropdown: {
      all: [BoardOption.AssignedToMe],
      selected: BoardOption.AssignedToMe,
    },
    options: {
      services: [],
      workloads: [],
    }
  },
  assignedToMe: {
    filter: {
      all: {
        source: Object.values(TaskSource),
        dueDate: Object.values(DueDateOption).filter((opt) => !isNaN(Number(opt))) as number[],
        createdDate: Object.values(CreatedDateOption).filter((opt) => !isNaN(Number(opt))) as number[],
      },
      selected: emptyAssignedToMeFilter,
    },
    keyword: window.localStorage.getItem(LocalStorageKeys.ServiceWorkspaceEvidenceKeyword) ?? '',
  },
  sortBy: {
    key: ServiceWorkspaceEvidenceSortKey.DueDate,
    order: OrderByOption.Ascending,
  },
};

/**
 * Thunks
 */

export const fetchServiceBoardOptions = createAsyncThunk<FilterOptionsForServiceWorkspace>(
  'filter/fetchServiceBoardOptions',
  async () => {
    return await ServiceWorkspaceApiService.getFilterOptionsForServiceWorkspaceAsync();
  }
);

export const fetchEvidenceStateAllOptions = createAsyncThunk<EvidenceState[]>(
  'filter/fetchEvidenceStateAllOptions',
  async () => {
    return await EntityApi.getEvidenceStateListAsync();
  },
);

export const fetchEnvironmentAllOptions = createAsyncThunk<Environment[]>(
  'filter/fetchEnvironmentAllOptions',
  async () => {
    return await EntityApi.getEnvironmentListAsync();
  });

export const fetchServiceAllOptions = createAsyncThunk<Service[]>(
  'filter/fetchServiceAllOptions',
  async () => {
    return await EntityApi.getServiceListAsync(false);
  },
);

export const fetchAuditEventAllOptions = createAsyncThunk<AuditEvent[]>(
  'filter/fetchAuditEventAllOptions',
  async () => {
    return await AuditEventApi.getPublicComplianceAuditInfoAsync(true);
  }
);

/**
 * Slice
 */

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setAssignedToMeFilters(
      state,
      action: PayloadAction<Partial<AssignedToMeFilterSelectedState>>,
    ) {
      state.assignedToMe.filter.selected = {
        ...state.assignedToMe.filter.selected,
        ...action.payload,
      };
    },
    setAssignedToMeKeyword(state, action: PayloadAction<string>) {
      state.assignedToMe.keyword = action.payload;
    },
    setSelectedBoardOption(state, action: PayloadAction<BoardOption>) {
      state.board.dropdown.selected = action.payload;
    },
    setSortBy(state, action: PayloadAction<Partial<SortOption<ServiceWorkspaceEvidenceSortKey>>>) {
      state.sortBy = {
        ...state.sortBy,
        ...action.payload,
      };
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchServiceBoardOptions.fulfilled, (state, action) => {
        const { Services, Workloads } = action.payload;

        const allOptions = [BoardOption.AssignedToMe];

        if (Services.length > 0) {
          allOptions.push(BoardOption.AssignedToMyServices);
          state.board.options.services = Services;
        }

        if (Workloads.length > 0) {
          allOptions.push(BoardOption.AssignedToMyWorkloads);
          state.board.options.workloads = Workloads;
        }

        state.board.dropdown.all = allOptions;
      })
      .addCase(fetchEnvironmentAllOptions.fulfilled, (state, action) => {
        state.assignedToMe.filter.all.environment = action.payload;
      })
      .addCase(fetchEvidenceStateAllOptions.fulfilled, (state, action) => {
        state.assignedToMe.filter.all.state = action.payload;
      })
      .addCase(fetchServiceAllOptions.fulfilled, (state, action) => {
        state.assignedToMe.filter.all.service = action.payload;
      })
      .addCase(fetchAuditEventAllOptions.fulfilled, (state, action) => {
        state.assignedToMe.filter.all.auditEvent = action.payload;
      })
});

export const {
  setAssignedToMeFilters,
  setAssignedToMeKeyword,
  setSelectedBoardOption,
  setSortBy
} =
  filterSlice.actions;

/**
 * Hooks
 */

export const useAllBoards = (): BoardOption[] =>
  useSelector<RootReduxState, BoardOption[]>(
    (state) => state.taskBoard.filter.board.dropdown.all,
  );

export const useSelectedBoard = (): BoardOption =>
  useSelector<RootReduxState, BoardOption>(
    (state) => state.taskBoard.filter.board.dropdown.selected,
  );

export const useAssignedToMeFilterAllOptions = (): AssignedToMeFilterAllState =>
  useSelector<RootReduxState, AssignedToMeFilterAllState>(
    (state) => state.taskBoard.filter.assignedToMe.filter.all,
  );

export const useAssignedToMeFilterSelectedOptions =
  (): AssignedToMeFilterSelectedState =>
    useSelector<RootReduxState, AssignedToMeFilterSelectedState>(
      (state) => state.taskBoard.filter.assignedToMe.filter.selected,
    );

export const useAssignedToMeKeyword = (): string =>
  useSelector<RootReduxState, string>(
    (state) => state.taskBoard.filter.assignedToMe.keyword,
  );

export const useEvidenceSortBy = (): SortOption<ServiceWorkspaceEvidenceSortKey> =>
  useSelector<RootReduxState, SortOption<ServiceWorkspaceEvidenceSortKey>>(
    (state) => state.taskBoard.filter.sortBy,
  );
