import {
  useEffect,
  useState
} from 'react';

import { AccountInfo } from '@azure/msal-browser';

import { OAuthService } from '../../services/OAuthService';

/**
 * This hook will be deprecated. Please call OAuthService.getAccounts directly.
* */
export const useUserAccount = () => {
  const [account, setAccount] = useState<AccountInfo>();

  useEffect(() => {
    try {
      const accounts: AccountInfo[] = OAuthService.getAccounts();
      setAccount(accounts[0]);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return account;
};
