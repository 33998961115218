import React, { ReactNode } from 'react';

import { useBoolean } from '@fluentui/react-hooks';

import intl from 'react-intl-universal';

import { LocIds } from '../../../common/Globalization/IntlEnum';
import { FCC } from '../../../models';
import { M365SecondaryButton } from '../../M365Button';
import './FilterLayout.less';


interface IProps {
  /** If there are more filters than showCount, these filters will be hide */
  showCount?: number;
  showAddFilterButton?: boolean;
  /** Open edit filters panel */
  openFiltersPanel?: () => void;
  children: ReactNode | ReactNode[];
}

export const TruncatedFilters: FCC<IProps> = ({ openFiltersPanel, showAddFilterButton = true, showCount = 2, children }: IProps) => {
  const [showAll, { toggle }] = useBoolean(false);

  if (!children) {
    return null;
  }

  const count = React.Children.toArray(children).filter(
    (c) => !!c,
  ).length;

  const childrenCount = showAll ? count : Math.min(showCount, count);

  let result: ReactNode[] = React.Children.toArray(children).slice(0, childrenCount);

  const addFilterButton = showAddFilterButton && (
    <M365SecondaryButton
      iconProps={{
        iconName: 'filter'
      }}
      key='tp-filterLayout-addFilterBtn'
      text={intl.get(LocIds.Components.AddFilter)}
      onClick={openFiltersPanel}
    />
  );

  result = result.concat(addFilterButton);

  if (count > showCount) {
    const label = showAll ?
      intl.get(LocIds.Components.ViewLess) :
      `+${count - showCount} more`;
    result = result.concat(
      <span
        className='tp-filterLayout-showMoreBtn'
        key='tp-filterLayout-showMoreBtn'
        onClick={toggle}>
        { label }
      </span>,
    );
  }

  return (
    <>{ result }</>
  );
};
