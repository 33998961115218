import './style.less';
import React from 'react';

import {
  IContextualMenuItem, Text
} from '@fluentui/react';
import _, { isNull } from 'lodash';
import intl from 'react-intl-universal';

import { PublishedEvidenceStatusLabel } from './PublishedEvidenceStatusLabel';

import { useEvidenceFilterSelectedOptions } from '../../applications/AuditReview/pages/Evidences/redux/evidenceFiltersSlice/selectors';
import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  AuditEvent, ConclusionStateEnum, Evidence
} from '../../models';
import { BaseEvidenceCard } from '../BaseEvidenceCard';
import { CardActionBarProps } from '../CardView';


interface IProps {
  evidence: Evidence;
  canUpdate?: boolean;
  isUpdating?: boolean;
  showDiscussions?: boolean;
  auditTeamEmail?: string;
  showActions?: boolean;
  acceptEvidence?: () => void;
  rejectEvidence?: () => void;
  openDiscussions?: () => void;
  getAuditEventDetailsApi?: (auditEventGuid: string) => Promise<AuditEvent>;
}

export const EvidenceCard: React.FC<IProps> = (props) => {
  const {
    evidence,
    canUpdate = false,
    isUpdating = false,
    showDiscussions = false,
    showActions = false,
    acceptEvidence,
    rejectEvidence,
    openDiscussions,
    getAuditEventDetailsApi
  } = props;

  const selectedOptions = useEvidenceFilterSelectedOptions();
  const isEvidenceConclusionDisabled = selectedOptions.auditEvent?.IsEvidenceConclusionDisabled;

  const acceptItem = {
    key: 'accept',
    iconProps: {
      iconName: 'Accept'
    },
    text: intl.get(LocIds.Action.Accept),
    ariaLabel: intl.get(LocIds.Action.Accept),
    onClick: acceptEvidence,
    loading: isUpdating
  };

  const rejectItem = {
    key: 'reject',
    iconProps: {
      iconName: 'Clear'
    },
    text: intl.get(LocIds.Action.Reject),
    ariaLabel: intl.get(LocIds.Action.Reject),
    onClick: rejectEvidence,
    loading: isUpdating
  };

  const discussionItem = {
    key: 'discussion',
    iconProps: {
      iconName: 'OfficeChat'
    },
    text: intl.get(LocIds.Label.Discussions),
    ariaLabel: intl.get(LocIds.Label.Discussions),
    onClick: openDiscussions,
  };

  const tagValue = (
    <PublishedEvidenceStatusLabel conclusionState={evidence.ConclusionState} />
  );

  const tagProps = !isEvidenceConclusionDisabled ? {
    items: [
      {
        label: intl.get(LocIds.Label.Status),
        value: tagValue,
      },
    ],
  } : undefined;

  const accepted = evidence.ConclusionState === ConclusionStateEnum.Accepted;
  const rejected = evidence.ConclusionState === ConclusionStateEnum.Rejected;
  const isReviewed = accepted || rejected;

  let menuItems: IContextualMenuItem[] = [];

  if (isReviewed && canUpdate) {
    menuItems = [rejected ? acceptItem : rejectItem];
  }

  let actionBarProps: CardActionBarProps | undefined;

  if (!isEvidenceConclusionDisabled) {
    // If the evidence conclusion is enabled, we hide discussions button inside the menu.
    if (showDiscussions) {
      menuItems.push(discussionItem);
    }

    actionBarProps = {
      buttonGroupProps: isReviewed ? undefined : {
        items: [acceptItem, rejectItem]
      },
      menuProps: _.isEmpty(menuItems) ? undefined : {
        items: menuItems
      }
    };
  } else if (showDiscussions) {
    // If the evidence conclusion is disabled, we only show the discussions button
    actionBarProps = {
      buttonGroupProps: {
        items: [discussionItem]
      },
    };
  }

  const stateSuffix = {
    [ConclusionStateEnum.InReview]: '--inReview',
    [ConclusionStateEnum.Accepted]: '--accepted',
    [ConclusionStateEnum.Rejected]: '--rejected',
  };

  const className = !isEvidenceConclusionDisabled ? `evidenceCard${stateSuffix[evidence.ConclusionState]}` : undefined;

  const headerLabel = !isNull(evidence.SourceProposedEvidenceGuid) ? (
    <Text className='evidenceCard__typeLabel--proposed'>
      { intl.get(LocIds.Evidence.AuditorProposed) }
    </Text>
  ) : undefined;

  return (
    <BaseEvidenceCard
      actionBarProps={showActions ? actionBarProps : undefined}
      auditTeamEmail={props.auditTeamEmail}
      className={className}
      evidence={evidence}
      getAuditEventDetailsApi={getAuditEventDetailsApi}
      shouldContactAuditEventPOC={true}
      tagProps={tagProps}
      titleLabel={headerLabel}
    />
  );
};
