import React, {
  ReactNode, useCallback, useState
} from 'react';
import './PathTree.less';

import { PathTreeNode } from './PathTreeNode';
import { FlatTreeItem } from './types';


export type OnRenderNode = (node: FlatTreeItem) => ReactNode;

interface IProps {
  nodes: FlatTreeItem[];
  activeNode?: FlatTreeItem | undefined;
  onNodeClick?: (node: FlatTreeItem) => void;
  onRenderNode?: OnRenderNode;
}

export const PathTree: React.FC<IProps> = ({ nodes, activeNode, onNodeClick, onRenderNode }: IProps) => {

  const [collapsedNodes, setCollapsedNodes] = useState<Record<string, boolean>>({});

  const collapseNode = useCallback((node: FlatTreeItem, collapsed: boolean) => {
    let fromIndex = -1;
    let currentIndex = -1;

    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].key === node.key) {
        fromIndex = i;
        currentIndex = i;
      }
    }

    if (fromIndex === -1) {
      return;
    }

    let childrenToProcess = nodes[currentIndex].children;

    while (currentIndex - fromIndex !== childrenToProcess) {
      currentIndex++;
      childrenToProcess += nodes[currentIndex].children;
    }

    setCollapsedNodes((tree: Record<string, boolean>) => {
      const result: Record<string, boolean> = {
        ...tree
      };

      const toIndex = fromIndex + childrenToProcess;

      for (let i = fromIndex + 1; i <= toIndex; i++) {
        result[nodes[i].key] = collapsed;
      }

      return result;
    });
  }, [nodes, setCollapsedNodes]);

  // ---------------------------------------------------------------------------------------------------------------------------------------
  if (nodes.length === 0) {
    return null;
  }

  const listJSX = nodes.map((node: FlatTreeItem) => {
    if (collapsedNodes[node.key]) {
      return null;
    }

    return (
      <PathTreeNode
        activeNode={activeNode}
        collapseNode={collapseNode}
        key={node.key}
        node={node}
        onNodeClick={onNodeClick}
        onRenderNode={onRenderNode}
      />
    );
  });

  return (
    <div className='path-tree'>
      { listJSX }
    </div>
  );
};
