import React, {
  ReactElement,
  useEffect,
  useState
} from 'react';

import intl from 'react-intl-universal';

import { Dialog } from './Dialog';

import { LocIds } from '../../common/Globalization/IntlEnum';
import { MultiSelectDropdown } from '../Dropdown';


interface DialogAddItemsProps<ItemType> {
  itemName: string;
  subText: string;
  allItems: ItemType[];
  selectedItems: ItemType[];
  optionOf: (item: ItemType) => { key: string; text: string };
  keyOf: (item: ItemType) => string;
  onConfirm: (environments: ItemType[]) => void;
  onDismiss: () => void;
  isLoading?: boolean;
}

export const DialogAddItems = <T, >(
  props: DialogAddItemsProps<T>,
): ReactElement => {
  const { isLoading = false } = props;
  const [selectedItems, setSelectedItems] = useState<T[]>(props.selectedItems);

  useEffect(() => {
    setSelectedItems(props.selectedItems);
  }, [props.selectedItems]);

  return (
    <Dialog
      primaryButtonProps={{
        text: intl.get(LocIds.Action.Confirm),
        onClick: () => {
          props.onConfirm(selectedItems);
        },
      }}
      subText={props.subText}
      title={intl.get(LocIds.Action.Add) + ` ${props.itemName}`}
      onDismiss={props.onDismiss}>
      <MultiSelectDropdown<T>
        disabled={isLoading}
        items={props.allItems}
        keyOf={props.keyOf}
        label={props.itemName}
        optionOf={props.optionOf}
        placeholder={isLoading ?
          intl.get(LocIds.Placeholder.Loading) :
          intl.get(LocIds.Placeholder.SelectOptions)}
        searchable
        selectedItems={selectedItems}
        onChange={(items) => {
          setSelectedItems(items);
        }}
      />
    </Dialog>
  );
};
