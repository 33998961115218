import React, { FC } from 'react';

import {
  Dropdown, IDropdownProps
} from '@fluentui/react';
import {
  Controller,
  UseControllerProps,
  useFormContext
} from 'react-hook-form';

import { LabelWithIcon } from '../../LabelWithIcon';
import { ReadOnlyDropdown } from '../../ReadOnlyControls/ReadOnlyDropdown';

interface IProps extends IDropdownProps, Pick<UseControllerProps, 'rules'> {
  name: string;
  description?: string;
  readOnly?: boolean;
  showLabelTooltip?: boolean;
}

export const DropdownControl: FC<IProps> = (props: IProps) => {

  const { control } = useFormContext();
  const onRenderLabel = (props: IProps) => <LabelWithIcon {...props} />;

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        return props.readOnly ? <ReadOnlyDropdown {...props}/> : (
          <Dropdown
            {...props}
            ariaLabel={props.label || props.ariaLabel}
            defaultValue={undefined}
            errorMessage={error && error.message}
            selectedKey={value}
            onBlur={onBlur}
            onChange={(_e, option) => {
              if (option) {
                onChange(option.key);
              }

              if (props.onChange) {
                props.onChange(_e, option);
              }
            }}
            onRenderLabel={props?.showLabelTooltip ? () => {
              return onRenderLabel(props);
            } : undefined}
          />
        );
      }}
      rules={props.rules}
    />
  );
};
